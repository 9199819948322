import { Href } from '@arrived/bricks-common';

import { AbacusLinkDocument, SSTDocument } from './ItemDocument.types';
import { StyledInfoBox, StyledInfoBoxProps } from './StyledInfoBox';

export type LinkItemProps = StyledInfoBoxProps & {
  document: SSTDocument | AbacusLinkDocument;
};

export const LinkItem = ({ children, document, ...rest }: LinkItemProps) => {
  return (
    <Href href={document.link}>
      <StyledInfoBox width="100%" {...rest}>
        {children}
      </StyledInfoBox>
    </Href>
  );
};
