import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postAccountKycAml } from '@arrived/api_v2';
import { UseAwaitedMutationOptions } from '@arrived/queries';

import { adminKycAmlKeyFn } from './adminAccountKycAml.keys';

export function useAdminPostAccountKycAmlMutation(options?: UseAwaitedMutationOptions<typeof postAccountKycAml>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postAccountKycAml,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: adminKycAmlKeyFn(variables.accountId) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
