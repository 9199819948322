import { Divider, Stack, styled, withStaticProperties } from '@arrived/bricks';

export const Breakout = withStaticProperties(
  styled(Stack, {
    gap: '$4',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row',
    $gtXxs: { alignItems: 'flex-start', flexDirection: 'column' },
  }),
  {
    Divider: styled(Divider, {
      display: 'none',
      width: '100%',
      $gtXxs: { display: 'flex' },
    }),
  },
);
