import { useQuery } from '@tanstack/react-query';

import { getEmailVerificationStatus } from '@arrived/api_v2';
import { UserId } from '@arrived/models';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { adminUserEmailVerificationKeyFn } from './users.keys';

export function useAdminGetEmailVerificationQuery(
  userId?: UserId,
  options?: UseAwaitedQueryOptions<typeof getEmailVerificationStatus>,
) {
  return useQuery({
    queryKey: adminUserEmailVerificationKeyFn(userId!),
    queryFn: () => getEmailVerificationStatus(userId!),
    ...options,
    // To ensure switching between email client and admin panel shows up to date info
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? true,
    enabled: (options?.enabled ?? true) && userId != null,
  });
}
