import { ZillowZipCodeAppreciation } from '@arrived/models';

export function getProjectedAppreciationRange(zipCodeAppreciation?: ZillowZipCodeAppreciation) {
  return zipCodeAppreciation
    ? [
        Math.min(zipCodeAppreciation.tenYearAppreciationRate, zipCodeAppreciation.twentyYearAppreciationRate),
        Math.max(zipCodeAppreciation.tenYearAppreciationRate, zipCodeAppreciation.twentyYearAppreciationRate),
      ]
    : [0, 0];
}
