import { FormattedMessage } from 'react-intl';

import _uniq from 'lodash/uniq';

import { Callout } from '@arrived/bricks';
import { ikClient } from '@arrived/imagekit';

import { IntercomChatCalloutButton } from './IntercomChatCalloutButton';

const questionMarkImage = ikClient.url({
  path: '/features/product-details/question-mark.png',
  transformation: [{ width: '64', height: '64' }],
});

export const IntercomChatCallout = () => (
  <Callout hideCloseIcon variant="inverted">
    <Callout.Illustration source={questionMarkImage} />
    <Callout.Heading>
      <FormattedMessage id="product-details.other-questions" />
    </Callout.Heading>
    <Callout.Content>
      <Callout.Content.Text textDecorationLine="underline">
        <IntercomChatCalloutButton>
          <FormattedMessage id="product-details.chat-with-us-1-on-1" />
        </IntercomChatCalloutButton>
      </Callout.Content.Text>
    </Callout.Content>
  </Callout>
);
