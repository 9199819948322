import { useQuery } from '@tanstack/react-query';

import { getQuestionnaireQuestions } from '@arrived/api_v2';
import { QuestionnaireId } from '@arrived/models';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { questionnaireQuestionsKeyFn } from './questionnaires.keys';

export function useGetQuestionnaireQuestionsQuery(
  id: QuestionnaireId,
  options?: UseAwaitedQueryOptions<typeof getQuestionnaireQuestions>,
) {
  return useQuery({
    queryKey: questionnaireQuestionsKeyFn(id),
    queryFn: () => getQuestionnaireQuestions(id),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
