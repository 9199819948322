import { DefaultError } from '@tanstack/react-query';

import { StringUtils } from './stringUtils';

export function getStringFromError(
  error: DefaultError | { message?: string; stack?: string } | string | null | undefined,
): string | undefined {
  if (error == null) {
    return;
  }

  if (typeof error === 'string') {
    return error;
  }

  if ('description' in error && StringUtils.isNotBlank(error.description)) {
    return error.description;
  } else if ('error' in error && StringUtils.isNotBlank(error.error)) {
    return error.error;
  } else if ('message' in error && StringUtils.isNotBlank(error.message)) {
    return error.message;
  }

  return;
}
