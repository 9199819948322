import { Employee, Filings, Homepage, VacationRentalsPageType } from '@arrived/models';

import { strapiApi } from '../api';
import { createQuery } from '../create-query';

export const getHomepage = () =>
  createQuery<Homepage>({
    apiInstance: strapiApi,
    method: 'get',
    url: '/api/homepage',
    config: {
      params: {
        populate: 'deep',
      },
    },
  });

export const getVacationRentalsPage = () =>
  createQuery<VacationRentalsPageType>({
    apiInstance: strapiApi,
    method: 'get',
    url: '/api/vacation-rental',
    config: {
      params: {
        populate: 'deep',
      },
    },
  });

export const getCircularLinks = () =>
  createQuery<Filings>({
    apiInstance: strapiApi,
    method: 'get',
    url: '/api/offering-links',
    config: {
      params: {
        populate: 'deep',
      },
    },
  });

export const getPreviewPostBySlug = (slug: string) =>
  slug === '/'
    ? createQuery<Homepage>({
        apiInstance: strapiApi,
        method: 'get',
        url: '/api/homepage',
        config: {
          params: { populate: '%2A', publicationState: 'preview' },
        },
      })
    : undefined; // TODO: Create other page preview types should be handled as per requirements.

export const getEmployees = () =>
  createQuery<{ data: Employee[] }>({
    apiInstance: strapiApi,
    method: 'get',
    url: '/api/employees',
    config: {
      params: {
        populate: 'deep',
        'pagination[pageSize]': 100,
      },
    },
  });
