import { useCallback } from 'react';

import { useIntercom } from 'react-use-intercom';

import { A } from '@expo/html-elements';

import { GetProps } from '@arrived/bricks';

// TODO: Make `useIntercom` platform agnostic
export const IntercomChatCalloutButton = (props: GetProps<typeof A>) => {
  const intercom = useIntercom();

  const handleIntercomPress = useCallback(() => {
    intercom.showNewMessage();
  }, []);

  return <A onPress={handleIntercomPress} {...props} />;
};
