import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function RentalIncomeIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <Path d="M2 15V3H22V15H2Z" stroke={color} strokeWidth={1.5} />
      <Path d="M4 18.5H20" stroke={color} strokeWidth={1.5} />
      <Path d="M7 22H17" stroke={color} strokeWidth={1.5} />
      <Path
        d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
        stroke={color}
        strokeWidth={1.5}
      />
    </Svg>
  );
}

export const RentalIncomeIcon = memo<IconProps>(withIconTheme(RentalIncomeIconInternal));
