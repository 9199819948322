import { GetProps, Stack, styled } from '@tamagui/core';

export const TooltipInner = styled(Stack, {
  backgroundColor: '$onSurface.neutral.tooltip',
  overflow: 'hidden',
  borderRadius: '$2',
  p: '$4',
  gap: '$4',
});

export type TooltipInnerProps = GetProps<typeof TooltipInner>;
