import { DefaultError } from '@tanstack/react-query';

import { Typography } from '@mui/material';

import { StringUtils, getStringFromError } from '@arrived/common';

interface StatusMessageProps {
  success?: string;
  error?: DefaultError | null;
  errors?: (DefaultError | null)[];
}

export const StaticErrorView = ({ error, errors, success }: StatusMessageProps) => {
  const errorString = getStringFromError(error);
  const isErrorStringBlank = StringUtils.isBlank(errorString);

  return (
    <>
      {!isErrorStringBlank ? (
        <Typography variant="body1" className="error-message">
          {errorString}
        </Typography>
      ) : null}
      {errors
        ? errors
            .map(getStringFromError)
            .filter((errorString): errorString is string => !StringUtils.isBlank(errorString))
            .map((errorString, index) => (
              <Typography key={index} variant="body1" className="error-message">
                {errorString}
              </Typography>
            ))
        : null}
      {success ? (
        <Typography variant="body1" className="success-message">
          {success}
        </Typography>
      ) : null}
    </>
  );
};
