import { FormattedMessage } from 'react-intl';

import { Markdown } from '@arrived/bricks-common';
import { SFRFundData } from '@arrived/models';

import { ProductDetailContent } from '../../../../../content';
import { Video } from '../../../../../video';

export const Markets = ({ markets: { description, video } }: { markets: NonNullable<SFRFundData['markets']> }) => (
  <>
    <ProductDetailContent.SubHeader>
      <FormattedMessage id="product-details.markets" />
    </ProductDetailContent.SubHeader>
    <Markdown Text={ProductDetailContent.Text}>{description}</Markdown>
    {video && (
      <Video youtubeId={video.youtubeId}>
        {video.imageUri && <Video.Image imageUri={video.imageUri} />}
        <Video.Title>{video.title}</Video.Title>
        {video.subTitle && <Video.SubTitle>{video.subTitle}</Video.SubTitle>}
      </Video>
    )}
  </>
);
