import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteQuestion } from '@arrived/api_v2';
import { QuestionnaireQuestion } from '@arrived/models';
import { UseAwaitedMutationOptions } from '@arrived/queries';

import { questionKeyFn, questionnaireQuestionsKeyFn } from './questionnaires.keys';

export function useDeleteQuestionMutation(options?: UseAwaitedMutationOptions<typeof deleteQuestion>) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteQuestion,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<QuestionnaireQuestion[]>(
        questionnaireQuestionsKeyFn(variables.questionnaireId),
        (questionnaireQuestions) => questionnaireQuestions?.filter((qq) => qq.questionUUID !== data.uuid),
      );

      queryClient.invalidateQueries({ queryKey: questionnaireQuestionsKeyFn(variables.questionnaireId) });

      queryClient.removeQueries({ queryKey: questionKeyFn(data.uuid) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
