import { PropsWithChildren, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { Divider as BricksDivider, Count, SegmentedButton, Stack, StackProps, isWeb } from '@arrived/bricks';
import { shouldShowAirDnaHistoricalCharts } from '@arrived/common';
import { NavigatorContext } from '@arrived/contexts';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { useInvestmentProductType } from '@arrived/hooks';
import { InvestmentProductType, Offering, Property, SecurityType, TradeFlow, TransactionStatus } from '@arrived/models';
import {
  useGetAccountOfferingBalancesQuery,
  useGetAirDnaAverageDailyRateByOffering,
  useGetAirDnaMonthlyOccupancyByOffering,
  useGetAirDnaMonthlyRevenueByOffering,
  useGetOfferingSharePricesQuery,
  useGetPrimaryAccountQuery,
  useGetPrivateCreditFundAssetMetadata,
  useGetRegDIPOAssetMetadata,
  useGetSFRFundAssetMetadata,
  useGetSTNAssetMetadata,
  useGetSTRAssetMetadata,
  useOfferingDividendsQuery,
  useTradesQuery,
} from '@arrived/queries';

import { ProductHeaderProps } from '../productHeader';
import { ReturnsCalculatorSectionProps } from '../returns';

import { PrivateCreditFundContent, PrivateCreditFundHeader, PrivateCreditFundHeaderDetails } from './privateCreditFund';
import { ProductDetailsView } from './ProductDetailsView';
import { ProductDetailsViewContext, useProductDetailsViewContext } from './ProductDetailsViewContext';
import { SFRFundContent, SFRFundHeader, SFRFundHeaderDetails } from './sfrFund';
import { ShortTermNoteContent, ShortTermNoteHeader, ShortTermNoteHeaderDetails } from './shortTermNotes';
import {
  SingleFamilyResidentialContent,
  SingleFamilyResidentialHeader,
  SingleFamilyResidentialHeaderDetails,
} from './singleFamilyResidential';
import { useProductViews } from './useProductViews';
import { VacationRentalContent, VacationRentalHeader, VacationRentalHeaderDetails } from './vacationRental';

type ProductDetailProps = {
  offering: Offering;
} & StackProps;

export const Wrapper = ({ children, offering }: PropsWithChildren<ProductDetailProps>) => {
  const { hash } = useContext(NavigatorContext);
  const views = useProductViews(offering.id);

  const { hashView, hashHistoryOpen } = useMemo(() => {
    switch (hash) {
      case 'details':
        return { hashView: ProductDetailsView.DETAILS };
      case 'financials':
        return { hashView: ProductDetailsView.FINANCIALS };
      case 'properties':
        return { hashView: ProductDetailsView.PROPERTIES };
      case 'performance':
        return { hashView: ProductDetailsView.PERFORMANCE };
      case 'history':
        return { hashView: ProductDetailsView.PERFORMANCE, hashHistoryOpen: true };
    }

    return {};
  }, [hash]);

  const defaultView = useMemo(() => {
    if (!views || views.length === 0) {
      return undefined;
    } else if (hashView && views.includes(hashView)) {
      return hashView;
    } else {
      return views[0];
    }
  }, [hashView, views]);

  const [view, setView] = useState(defaultView);
  const [isHistoryOpen, setIsHistoryOpen] = useState(hashHistoryOpen ?? false);
  const [currentlySelectedPropertyId, setCurrentlySelectedPropertyId] = useState<Property['id']>();

  useEffect(() => {
    if (hashView && views && views.includes(hashView)) {
      setView(hashView);
    }
  }, [hashView]);

  useEffect(() => {
    setIsHistoryOpen(hashHistoryOpen ?? false);
  }, [hashHistoryOpen]);

  const value = useMemo(
    () => ({
      view,
      setView,
      isHistoryOpen,
      setIsHistoryOpen,
      currentlySelectedPropertyId,
      setCurrentlySelectedPropertyId,
    }),
    [view, isHistoryOpen, currentlySelectedPropertyId],
  );

  return <ProductDetailsViewContext.Provider value={value}>{children}</ProductDetailsViewContext.Provider>;
};

type HeaderProps = { offering: Offering } & ProductHeaderProps;

export const Header = ({ offering, ...rest }: HeaderProps) => {
  const investmentProductType = useInvestmentProductType(offering);

  const strMetadataState = useGetSTRAssetMetadata(offering.id, {
    enabled: investmentProductType === InvestmentProductType.VACATION_RENTAL_IPO,
  });

  const stnMetadataState = useGetSTNAssetMetadata(offering.id, {
    enabled: investmentProductType === InvestmentProductType.SHORT_TERM_NOTE,
  });

  switch (investmentProductType) {
    case InvestmentProductType.SHORT_TERM_NOTE:
      return <ShortTermNoteHeader offering={offering} stnMetadata={stnMetadataState.data} {...rest} />;
    case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND:
      return <SFRFundHeader offering={offering} {...rest} />;
    case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO:
      return <SingleFamilyResidentialHeader offering={offering} {...rest} />;
    case InvestmentProductType.VACATION_RENTAL_IPO:
      return <VacationRentalHeader offering={offering} strMetadata={strMetadataState.data} {...rest} />;
    case InvestmentProductType.PRIVATE_CREDIT_FUND:
      return <PrivateCreditFundHeader offering={offering} {...rest} />;
    default:
      return null;
  }
};

export type DividerStackProps = Omit<StackProps, 'children'>;

/**
 * This component should only render if the product type has corresponding controls.
 */
export const Divider = ({ offering, ...rest }: { offering: Offering } & DividerStackProps) => {
  const investmentProductType = useInvestmentProductType(offering);

  switch (investmentProductType) {
    case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND:
    case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO:
    case InvestmentProductType.VACATION_RENTAL_IPO:
    case InvestmentProductType.PRIVATE_CREDIT_FUND:
      return (
        <Stack {...rest}>
          <BricksDivider {...rest} />
        </Stack>
      );
    default:
      return null;
  }
};

type ControlsProps = { offering: Offering } & StackProps;

const controlButtonMap: Record<ProductDetailsView, ReactNode> = {
  [ProductDetailsView.DETAILS]: <FormattedMessage id="common.details" />,
  [ProductDetailsView.FINANCIALS]: <FormattedMessage id="common.financials" />,
  [ProductDetailsView.PERFORMANCE]: <FormattedMessage id="common.performance" />,
  [ProductDetailsView.PROPERTIES]: <FormattedMessage id="product-details.properties" />,
  [ProductDetailsView.OFFERING]: <FormattedMessage id="product-details.offering" />,
  [ProductDetailsView.INTRO_TO_DEBT]: <FormattedMessage id="product-details.intro-to-debt" />,
};

export const Controls = ({ children, offering, ...rest }: ControlsProps) => {
  const views = useProductViews(offering.id);
  const { setView, view } = useProductDetailsViewContext();

  const handleOnIndexChange = useCallback(
    (index?: number) => {
      if (index != null && views && index < views.length) {
        setView(views[index]);
        if (isWeb) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    },
    [setView, views],
  );

  const controlledIndex = useMemo(() => {
    if (view && views) {
      return Math.max(views.indexOf(view), 0);
    }
    return 0;
  }, [view, views]);

  if (!views) {
    return null;
  }

  return (
    <Stack {...rest}>
      {children}
      <SegmentedButton controlledIndex={controlledIndex} onIndexChange={handleOnIndexChange} alignItems="stretch">
        {views.map((view, index) => (
          <SegmentedButton.Button key={view} index={index}>
            {controlButtonMap[view]}
            {view === ProductDetailsView.PROPERTIES && (
              <SegmentedButton.Adornment>
                <Count variant="inverted">{offering.properties.length}</Count>
              </SegmentedButton.Adornment>
            )}
          </SegmentedButton.Button>
        ))}
      </SegmentedButton>
    </Stack>
  );
};

export type HeaderDetailsStackProps = Omit<StackProps, 'children'>;
type HeaderDetailsProps = { offering: Offering } & HeaderDetailsStackProps;

export const HeaderDetails = ({ offering, ...rest }: HeaderDetailsProps) => {
  const investmentProductType = useInvestmentProductType(offering);

  const stnMetadataState = useGetSTNAssetMetadata(offering.id, {
    enabled: investmentProductType === InvestmentProductType.SHORT_TERM_NOTE,
  });

  switch (investmentProductType) {
    case InvestmentProductType.PRIVATE_CREDIT_FUND:
      return <PrivateCreditFundHeaderDetails {...rest} />;
    case InvestmentProductType.SHORT_TERM_NOTE:
      return <ShortTermNoteHeaderDetails offering={offering} stnMetadata={stnMetadataState.data} {...rest} />;
    case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND:
      return <SFRFundHeaderDetails offering={offering} {...rest} />;
    case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO:
      return <SingleFamilyResidentialHeaderDetails offering={offering} {...rest} />;
    case InvestmentProductType.VACATION_RENTAL_IPO:
      return <VacationRentalHeaderDetails offering={offering} {...rest} />;
    default:
      return null;
  }
};

type ContentProps = { offering: Offering; Table: ReturnsCalculatorSectionProps['TableComponent'] } & Omit<
  StackProps,
  'children'
>;

export const Content = ({ offering, Table }: ContentProps) => {
  const investmentProductType = useInvestmentProductType(offering);

  const strMetadataState = useGetSTRAssetMetadata(offering.id, {
    enabled: investmentProductType === InvestmentProductType.VACATION_RENTAL_IPO,
  });

  const sfrFundMetadataState = useGetSFRFundAssetMetadata(offering.cid, {
    enabled: investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND,
  });

  const stnMetadataState = useGetSTNAssetMetadata(offering.id, {
    enabled: investmentProductType === InvestmentProductType.SHORT_TERM_NOTE,
  });

  const privateCreditFundMetadataState = useGetPrivateCreditFundAssetMetadata(offering.cid, {
    enabled: investmentProductType === InvestmentProductType.PRIVATE_CREDIT_FUND,
  });

  const regDIPOMetadata = useGetRegDIPOAssetMetadata(offering.cid, {
    enabled:
      investmentProductType &&
      (
        [
          InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO,
          InvestmentProductType.VACATION_RENTAL_IPO,
        ] as InvestmentProductType[]
      ).includes(investmentProductType) &&
      offering.securityType === SecurityType.REG_D,
  });

  const accountState = useGetPrimaryAccountQuery();
  const accountOfferingBalancesState = useGetAccountOfferingBalancesQuery(accountState.data?.id);
  const dividendsState = useOfferingDividendsQuery(offering.id, {
    enabled:
      investmentProductType &&
      (
        [
          InvestmentProductType.PRIVATE_CREDIT_FUND,
          InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND,
        ] as InvestmentProductType[]
      ).includes(investmentProductType),
  });

  const offeringBalance = useMemo(
    () => accountOfferingBalancesState.data?.offerings.find(({ id }) => id === offering.id),
    [accountOfferingBalancesState.data?.offerings, offering.id],
  );

  const airDnaHistoricalChartsEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.AIR_DNA_HISTORICAL_CHARTS);
  const showAirDnaHistoricalCharts = useMemo(
    () =>
      shouldShowAirDnaHistoricalCharts(offering.status) &&
      airDnaHistoricalChartsEnabled &&
      investmentProductType === InvestmentProductType.VACATION_RENTAL_IPO,
    [airDnaHistoricalChartsEnabled, investmentProductType, offering.status],
  );

  const offeringSharePricesState = useGetOfferingSharePricesQuery(offering.id, {
    enabled:
      investmentProductType &&
      (
        [
          InvestmentProductType.PRIVATE_CREDIT_FUND,
          InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND,
        ] as InvestmentProductType[]
      ).includes(investmentProductType),
  });

  const averageDailyRevenueState = useGetAirDnaAverageDailyRateByOffering(offering.id, {
    enabled: showAirDnaHistoricalCharts,
  });
  const monthlyOccupancyState = useGetAirDnaMonthlyOccupancyByOffering(offering.id, {
    enabled: showAirDnaHistoricalCharts,
  });
  const monthlyRevenueState = useGetAirDnaMonthlyRevenueByOffering(offering.id, {
    enabled: showAirDnaHistoricalCharts,
  });

  const transactionsState = useTradesQuery({
    flows: [TradeFlow.CASH_ACCOUNT, TradeFlow.NORTH_CAPITAL],
    offeringId: offering.id,
    statuses: [TransactionStatus.CREATED, TransactionStatus.FUNDED, TransactionStatus.SETTLED],
    // Will we ever need pagination here?
    size: 1_000,
  });

  switch (investmentProductType) {
    case InvestmentProductType.PRIVATE_CREDIT_FUND:
      return (
        <PrivateCreditFundContent
          dividends={dividendsState.data}
          offering={offering}
          pcfMetadata={privateCreditFundMetadataState.data}
          offeringBalance={offeringBalance}
          sharePrices={offeringSharePricesState.data}
        />
      );
    case InvestmentProductType.SHORT_TERM_NOTE:
      return (
        <ShortTermNoteContent
          offering={offering}
          stnMetadata={stnMetadataState.data}
          transactions={transactionsState.data}
        />
      );
    case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND:
      return (
        <SFRFundContent
          dividends={dividendsState.data}
          offering={offering}
          offeringBalance={offeringBalance}
          sfrFundMetadata={sfrFundMetadataState.data}
          sharePrices={offeringSharePricesState.data}
          transactions={transactionsState.data}
        />
      );
    case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO:
      return (
        <SingleFamilyResidentialContent
          offering={offering}
          offeringBalance={offeringBalance}
          regDIPOMetadata={regDIPOMetadata.data}
          transactions={transactionsState.data}
          Table={Table}
        />
      );
    case InvestmentProductType.VACATION_RENTAL_IPO:
      return (
        <VacationRentalContent
          averageDailyRevenueData={averageDailyRevenueState.data}
          monthlyOccupancyData={monthlyOccupancyState.data}
          monthlyRevenueData={monthlyRevenueState.data}
          offering={offering}
          offeringBalance={offeringBalance}
          strMetadata={strMetadataState.data}
          transactions={transactionsState.data}
          Table={Table}
        />
      );
    default:
      return null;
  }
};
