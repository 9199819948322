import { styled } from '@tamagui/core';

import { Stack } from '../../atoms';

export const ModalFooter = styled(Stack, {
  name: 'ModalFooter',
  gap: '$3',
  $gtSm: {
    row: true,
    justifyContent: 'flex-end',
  },
});
