/**
 * Computes the Internal Rate of Return (IRR) given an array of returns where the 0th index is the initial cost of the
 * investment as a negative value and the value at every index after is the cash flow for the year corresponding to that
 * index value. For example the array [-1000, 30, 31, ..., 1250] would be an initial investment of $1000, cash flow of
 * $30 the first year, $31 the second year, and $1,250 in the final year.
 */
export function calculateInternalRateOfReturn(cashFlows: number[]) {
  let min = -1.0;
  let max = 1.0;
  let netPresentValue = 0;

  let iteration = 0;
  const maxIterations = 50;

  let guess = 0;

  do {
    guess = (min + max) / 2;
    netPresentValue = cashFlows.reduce((sum, curr, index) => sum + curr / Math.pow(1 + guess, index), 0);
    if (netPresentValue > 0) {
      min = guess;
    } else {
      max = guess;
    }
  } while (Math.abs(netPresentValue) > 1e-10 && ++iteration < maxIterations);

  return guess;
}
