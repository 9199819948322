import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { stub } from '@arrived/common';
import { Property } from '@arrived/models';

import { ProductDetailsView } from '..';

export interface ProductDetailsViewContextType {
  setView: Dispatch<SetStateAction<ProductDetailsView>>;
  view?: ProductDetailsView;
  setIsHistoryOpen: Dispatch<SetStateAction<boolean>>;
  isHistoryOpen?: boolean;
  setCurrentlySelectedPropertyId: Dispatch<SetStateAction<Property['id'] | undefined>>;
  currentlySelectedPropertyId?: Property['id'];
}

export const ProductDetailsViewContext = createContext<ProductDetailsViewContextType>({
  setView: stub,
  setIsHistoryOpen: stub,
  setCurrentlySelectedPropertyId: stub,
});

export function useProductDetailsViewContext() {
  const context = useContext(ProductDetailsViewContext);

  if (!context) {
    throw new Error('useProductDetailsViewContext must be used within a ProductDetailsViewContextProvider');
  }

  return context;
}
