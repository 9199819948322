import { NAV_TYPE, NavItemProps } from '../../navigation.types';

export const handleApplySubPath = (subPath?: string): ((item: NavItemProps) => NavItemProps) => {
  return (item: NavItemProps) => {
    if (!subPath) return item;
    if (item.type === NAV_TYPE.MULTIPLE) {
      item.subItems.forEach(handleApplySubPath(subPath));
    }
    return {
      ...item,
      path: item.path ? `${subPath}${item.path}` : item.path,
    };
  };
};
