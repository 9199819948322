import { ReactNode, forwardRef } from 'react';

import { Box, BoxProps } from '@mui/material';

interface SocialShareIconProps extends BoxProps {
  Icon: ReactNode;
  onClick?: () => void;
}

export const SocialShareIcon = forwardRef<HTMLDivElement, SocialShareIconProps>(({ Icon: icon, ...rest }, ref) => {
  return (
    <Box sx={{ '&:hover': { color: ({ palette }) => palette.primary.main } }} ref={ref} {...rest}>
      {icon}
    </Box>
  );
});
