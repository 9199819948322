import clsx from 'clsx';

import { useMediaQuery } from '@mui/material';

import { MEDIA_QUERIES } from '@arrived/common';

import { useIsMobile } from './useIsMobile';

export function useMediaQueryClasses() {
  const isTinyScreen = useMediaQuery(MEDIA_QUERIES.isTinyScreen);
  const isMobile = useIsMobile();
  const isSmallScreen = useMediaQuery(MEDIA_QUERIES.isSmallScreen);
  const isLargeScreen = useMediaQuery(MEDIA_QUERIES.isLargeScreen);
  const isExtraLargeScreen = useMediaQuery(MEDIA_QUERIES.isExtraLargeScreen);
  const isSafari = useMediaQuery(MEDIA_QUERIES.isSafari);

  return clsx(
    isTinyScreen && 'tiny-screen',
    isMobile && 'mobile',
    isSmallScreen && 'small-screen',
    isLargeScreen && 'large-screen',
    isExtraLargeScreen && 'extra-large-screen',
    isSafari && 'safari',
  );
}
