import { ReactNode, useContext } from 'react';

import Animated from 'react-native-reanimated';

import { BlurView, Callout, Stack } from '@arrived/bricks';
import { NavigationBarContext } from '@arrived/contexts';

const AnimatedStack = Animated.createAnimatedComponent(Stack);

interface BannerComponentProps {
  children?: ReactNode;
  onClose?: () => void;
}

export const BannerComponent = ({ children, onClose }: BannerComponentProps) => {
  const { offset: navigationBarTopOffset } = useContext(NavigationBarContext);
  return (
    <AnimatedStack
      position={'sticky' as 'absolute'}
      top={navigationBarTopOffset}
      left={0}
      right={0}
      height={0}
      overflow="visible"
      zIndex="$banner"
      role="banner"
    >
      <Stack width="100%" $gtXs={{ px: '$4' }}>
        <BlurView
          overflow="visible"
          flex="unset"
          maxWidth={1440}
          m="auto"
          $gtXs={{ borderRadius: '$2' }}
          $platform-web={{ height: 'fit-content' }}
        >
          <Callout variant="dark" borderRadius={0} $gtXs={{ borderRadius: '$2' }} onClose={onClose}>
            {children}
          </Callout>
        </BlurView>
      </Stack>
    </AnimatedStack>
  );
};
