/**
 * Different Builder models that we use for different parts of the app
 * https://builder.io/models
 *
 * @see https://www.builder.io/c/docs/query-api
 */
export const ArrivedBuilderModels = {
  /**
   * General articles that are not related to a specific category
   * https://builder.io/models/476b6b44d8954eeaa4f701cb7a804741
   */
  BLOG_ARTICLE: 'blog-article',

  /**
   * Entire pages
   * https://builder.io/models/17e82698137246df98d675b6a8890e82?model=17e82698137246df98d675b6a8890e82
   */
  PAGE: 'page',

  /**
   * Categories that are used to group articles
   */
  CATEGORIES: 'categories',

  /**
   * Individual category selection
   */
  CATEGORY: 'category',

  /**
   * Custom symbols that are used to build pages
   * https://builder.io/models/ca48cef4aaa7439e8d79a5d404bad39e
   */
  SYMBOL: 'symbol',

  /**
   * Webinars
   * https://builder.io/models/b7b91406e25040bf8ff122331c9dd619
   */
  WEBINAR: 'webinar',

  /**
   * Banner - used for global banners
   * https://builder.io/models/0584bfa53b31405c9d4024efefff1969?model=0584bfa53b31405c9d4024efefff1969
   */
  BANNER: 'banner',

  /**
   * Nav Items - these define the content of the navigation bar BESIDES the "Invest" section which
   * is controlled by the INVEST_NAV_GROUPS model.
   */
  NAV_ITEMS: 'nav-items',

  /**
   * Controls all of the Groups that live in the "Invest" nav section.
   */
  INVEST_NAV_GROUPS: 'invest-nav-groups',
} as const;

export type ArrivedBuilderModels = (typeof ArrivedBuilderModels)[keyof typeof ArrivedBuilderModels];
