import { HTMLProps, forwardRef, useMemo } from 'react';

import { TamaguiElement, getTokens, useProps, withStaticProperties } from '@tamagui/core';

import { FloatingFocusManager, FloatingOverlay, FloatingPortal, useMergeRefs } from '@floating-ui/react';

import { Stack } from '../../atoms';

import { ModalBackdrop } from './ModalBackdrop';
import { ModalBody } from './ModalBody';
import { ModalContent, ModalContentProps } from './ModalContent';
import { ModalContext, useModal } from './ModalContext';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalOptions } from './ModalProps';

type ModalProps = ModalOptions & ModalContentProps;

const ModalFloatingWrapper = forwardRef<TamaguiElement, ModalProps>(
  ({ children, open, onClose, onToggle, ...rest }, propRef) => {
    const props = useProps(rest);
    const modal = useModal({ open, onClose, onToggle });
    const zIndex = useMemo(() => getTokens().zIndex.popover.variable, []);
    const { context: floatingContext, ...context } = modal;
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!floatingContext.open) return null;

    return (
      <FloatingPortal>
        <FloatingOverlay className="modal-overlay" style={{ zIndex }} lockScroll>
          <FloatingFocusManager context={floatingContext}>
            <ModalContext.Provider value={modal}>
              <Stack fullscreen centered className="modal-inner">
                <ModalContent
                  zIndex={1600}
                  ref={ref}
                  aria-labelledby={context.labelId}
                  aria-describedby={context.descriptionId}
                  {...context.getFloatingProps(props as unknown as HTMLProps<HTMLElement>)}
                >
                  {children}
                </ModalContent>
              </Stack>
              <ModalBackdrop />
            </ModalContext.Provider>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingPortal>
    );
  },
);

export const Modal = withStaticProperties(ModalFloatingWrapper, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
});
