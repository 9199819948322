import * as yup from 'yup';

export const SIGNUP_VALIDATION_KEYS = {
  EMAIL: 'EMAIL',
  PASSWORD_REGEX: 'PASSWORD_REGEX',
  REQUIRED: 'REQUIRED',
} as const;

/**
 * This regex, pulled from https://stackoverflow.com/questions/22204836/regex-to-find-3-out-of-4-conditions, has the
 * structure of checking for 3/4 of the required password features (plus length) by doing each permutation as an option,
 * with the following requirements:
 *  1. lower case letters [a-z]
 *  2. upper case letters [A-Z]
 *  3. numbers [0-9]
 *  4. special characters [^a-zA-Z0-9]
 * The regex validates the password by saying it can match [1,2,3] | [1,2,4] | [1,3,4] | [2,3,4] as well as ensuring
 * that there are at least 8 characters.
 */
const auth0PasswordRegex =
  /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])).{8,}$/g;

export const SignupSchema = yup.object({
  email: yup.string().email(SIGNUP_VALIDATION_KEYS.EMAIL).required(SIGNUP_VALIDATION_KEYS.REQUIRED),
  password: yup
    .string()
    .required(SIGNUP_VALIDATION_KEYS.REQUIRED)
    .matches(auth0PasswordRegex, SIGNUP_VALIDATION_KEYS.PASSWORD_REGEX),
});

export type SignupSchema = yup.InferType<typeof SignupSchema>;
