export const defaultFontSizes = {
  '0': 0,
  '1': 1,
  '2': 2,
  '4': 4,
  '6': 6,
  '8': 8,
  '12': 12,
  '14': 14,
  '16': 16,
  '18': 18,
  '20': 20,
  '22': 22,
  '24': 24,
  '28': 28,
  '32': 32,
  '36': 36,
  '38': 38,
  '40': 40,
  '48': 48,
  '56': 56,
  '72': 72,
  '80': 80,
  true: 16,
} as const;
