import { styled } from '@tamagui/core';

import { UtilityText } from '../text';

const TOOLTIP_TEXT_NAME = 'TooltipText';

export const TooltipText = styled(UtilityText, {
  name: TOOLTIP_TEXT_NAME,
  token: 'utility.helper.medium',
  color: '$onSurface.neutral.defaultInverted',
});
