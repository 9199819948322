import { useMemo } from 'react';

import { ButtonProps } from '@mui/material';

import { FundStatus, OfferingDisplayState } from '@arrived/common';
import { useOfferingDisplayState } from '@arrived/hooks';
import { Offering } from '@arrived/models';

import { ThemeFunction } from '../utils';

interface TypePropertyStatusMapItem {
  barColor?: ThemeFunction<string>; // gradient/color for progress bar
  palette?: ButtonProps['color'];
}

export const PROPERTY_STATUS_MAP: Record<
  keyof typeof OfferingDisplayState | keyof typeof FundStatus,
  TypePropertyStatusMapItem
> = {
  [OfferingDisplayState.SOLD_OUT]: {
    barColor: ({ palette }) => palette.neutrals.gray,
    palette: 'secondary',
  },
  [OfferingDisplayState.PAUSED]: {
    barColor: ({ palette }) => palette.warning.main,
    palette: 'secondary',
  },
  [OfferingDisplayState.CLOSE]: {
    barColor: ({ palette }) => palette.warning.main,
    palette: 'secondary',
  },
  [OfferingDisplayState.NEW]: {
    barColor: ({ palette }) => palette.primary.main,
    palette: 'secondary',
  },
  [OfferingDisplayState.AVAILABLE]: {
    barColor: ({ palette }) => palette.primary.main,
    palette: 'secondary',
  },
  [OfferingDisplayState.COMING_SOON]: {
    barColor: ({ palette }) => palette.warning.main,
    palette: 'secondary',
  },
  [FundStatus.NEW_PROPERTIES]: {
    barColor: ({ palette }) => palette.primary.main,
    palette: 'secondary',
  },
};

export const usePropertyStyles = (offering: Offering) => {
  const propertyStatus = useOfferingDisplayState(offering.id);
  return useMemo(() => propertyStatus && PROPERTY_STATUS_MAP[propertyStatus], [offering, propertyStatus]);
};
