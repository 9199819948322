import { useCallback, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { useInterval } from 'usehooks-ts';

import { notificationService } from '@arrived/features-toasty';
import { UserId } from '@arrived/models';
import { useGetCurrentUserQuery, usePostCreateMFAEnrollmentMutation, usePostResetMFAMutation } from '@arrived/queries';

const BLOCKED_SECONDS = 60;

export const useMFAEnrollment = (userId?: UserId) => {
  const [isResendMFABlocked, setIsResendMFABlocked] = useState(false);
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [blockedCounter, setBlockedCounter] = useState(BLOCKED_SECONDS);
  const currentUserState = useGetCurrentUserQuery({ enabled: !userId });

  const { mutate: enrollMFA, isPending: isEnrollMFALoading } = usePostCreateMFAEnrollmentMutation();

  const { mutate: resetMFA, isPending: isResetMFALoading } = usePostResetMFAMutation();

  const handleSendMFAEmail = useCallback(() => {
    let passedUserId = userId;

    if (!passedUserId && currentUserState.isSuccess) {
      passedUserId = currentUserState.data.id;
    }

    if (passedUserId) {
      setIsResendMFABlocked(true);
      setBlockedCounter(BLOCKED_SECONDS);
      enrollMFA(passedUserId, {
        onSuccess: () => {
          setHasSentEmail(true);
          notificationService.success(
            <FormattedMessage
              id="profile.security.items.mfa.enrollSuccess"
              defaultMessage="An email has been sent to complete MFA enrollment"
            />,
          );
        },
        onError: () => {
          notificationService.error(
            <FormattedMessage
              id="profile.security.items.mfa.enrollError"
              defaultMessage="There was an issue with sending your MFA enrollment email"
            />,
          );
        },
      });
    }
  }, [currentUserState, enrollMFA, userId]);

  const handleResetMFA = useCallback(
    () =>
      userId &&
      resetMFA(userId, {
        onSuccess: () =>
          notificationService.success(
            <FormattedMessage
              id="profile.security.items.mfa.disableSuccess"
              defaultMessage="MFA has been successfully disabled"
            />,
          ),
        onError: () =>
          notificationService.error(
            <FormattedMessage
              id="profile.security.items.mfa.disableError"
              defaultMessage="There was an issue with disabling MFA"
            />,
          ),
      }),
    [userId, resetMFA],
  );

  useInterval(
    () => {
      setBlockedCounter(blockedCounter - 1);
    },
    isResendMFABlocked ? 1000 : null,
  );

  useEffect(() => {
    if (blockedCounter <= 0) {
      setBlockedCounter(BLOCKED_SECONDS);
      setIsResendMFABlocked(false);
    }
  }, [blockedCounter]);

  return {
    handleSendMFAEmail,
    handleResetMFA,
    isEnrollMFALoading,
    isResetMFALoading,
    isBlocked: isResendMFABlocked,
    counter: blockedCounter,
    hasSentEmail,
  };
};
