import { ReactNode, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Typography, TypographyProps, useMediaQuery, useTheme } from '@mui/material';

import { ArrivedTooltip } from '@arrived/features-tooltips';

export enum VERIFICATION_STATUS {
  VERIFIED = 'VERIFIED',
  FAILED = 'FAILED',
  INCOMPLETE = 'INCOMPLETE',
}

interface VerificationIndicatorProps {
  prefix?: ReactNode;
  status: VERIFICATION_STATUS;
  text?: ReactNode;
  disableText?: boolean;
  sx?: TypographyProps['sx'];
}

const VerificationStatusMap: Record<
  VERIFICATION_STATUS,
  { icon: ReactNode; color: TypographyProps['color']; text: ReactNode }
> = {
  [VERIFICATION_STATUS.VERIFIED]: {
    icon: <CheckIcon />,
    color: (theme) => theme.palette.newPrimary[800],
    text: <FormattedMessage id="profile.account.verification.status.verified" defaultMessage="Verified" />,
  },
  [VERIFICATION_STATUS.FAILED]: {
    icon: <ErrorIcon />,
    color: (theme) => theme.palette.newNegative[900],
    text: <FormattedMessage id="profile.account.verification.status.error" defaultMessage="Issue with verification" />,
  },
  [VERIFICATION_STATUS.INCOMPLETE]: {
    icon: <ErrorIcon />,
    color: (theme) => theme.palette.newNegative[900],
    text: <FormattedMessage id="profile.account.verification.status.incomplete" defaultMessage="Needs Verification" />,
  },
};

export const VerificationIndicator = ({ prefix, status, disableText, text, sx }: VerificationIndicatorProps) => {
  const theme = useTheme();
  const isTooltipEnabled = useMediaQuery(theme.breakpoints.down('md'));

  const passedText = useMemo(
    () => (!disableText ? <>{text ?? VerificationStatusMap[status].text}</> : undefined),
    [disableText, text, status],
  );

  const body = (
    <Typography
      variant="body1.medium"
      component="span"
      display="flex"
      alignItems="center"
      gap={0.5}
      color={VerificationStatusMap[status].color}
      sx={sx}
    >
      {VerificationStatusMap[status].icon}

      <Box display={{ xs: 'none', md: 'block' }}>
        {prefix} {passedText}
      </Box>
    </Typography>
  );

  if (isTooltipEnabled) {
    return <ArrivedTooltip tooltipContent={passedText}>{body}</ArrivedTooltip>;
  }

  return body;
};
