import { useQuery } from '@tanstack/react-query';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { adminGetCounterparty } from '../../../api';

import { adminCounterPartyKeyFn } from './adminCounterparty.keys';

export function useAdminCounterParty(
  counterpartyCid?: string,
  options?: UseAwaitedQueryOptions<typeof adminGetCounterparty>,
) {
  return useQuery({
    queryKey: adminCounterPartyKeyFn(counterpartyCid),
    queryFn: () => {
      if (!counterpartyCid) {
        throw Error('No counterparty CID specified');
      }

      return adminGetCounterparty(counterpartyCid);
    },
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
    enabled: (options?.enabled ?? true) && !!counterpartyCid,
  });
}
