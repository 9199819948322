import { useQuery } from '@tanstack/react-query';
import { fromUnixTime, isPast } from 'date-fns';

import { Series, getWebinars } from '@arrived/api_v2';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { webinarKey } from './webinars.keys';

export function useGetWebinarsQuery(series?: Series, options?: UseAwaitedQueryOptions<typeof getWebinars>) {
  return useQuery({
    queryKey: webinarKey(series),
    queryFn: () => getWebinars(series),
    staleTime: MAX_STALE_TIME,
    select: (events) => events.filter((webinar) => !isPast(fromUnixTime(webinar.timestamp))),
    ...options,
  });
}
