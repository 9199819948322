/**
 * Takes a property name and strips the prefix. Makes an assumption that the property name length is at least two words
 * with a space in between, if it's not the function will return the original property name.
 */
export function propertyNameAccessor(propertyName: number | string) {
  const nameSplit = String(propertyName).split(' ');

  if (nameSplit.length > 1) {
    return nameSplit[1];
  } else {
    return propertyName;
  }
}
