import { Stack, styled } from '@tamagui/core';

import { getElevation } from './getElevation';

export const RootLayout = styled(Stack, {
  name: 'RootLayout',

  variants: {
    /**
     * Stack the children horizontally -- `flexDirection: 'row'`
     */
    row: {
      true: {
        flexDirection: 'row',
      },
      false: {
        flexDirection: 'column',
      },
    },

    /**
     * Position the children in the center of the Stack.
     */
    centered: {
      true: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    /**
     * Sets the elevation of the element via the `box-shadow` property.
     */
    elevation: {
      '...size': getElevation,
    },

    /**
     * Position the element to fill the entire screen.
     */
    fullscreen: {
      true: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        w: '100%',
        h: '100%',
      },
    },

    /**
     * Fully round the Stack.
     * @default $true
     */
    rounded: {
      true: {
        borderRadius: '$true',
      },
    },

    /**
     * Turn the Stack into a generic button.
     */
    button: {
      true: {
        tag: 'button',
        backgroundColor: 'transparent',
        borderWidth: 0,
        padding: 0,
      },
    },
  } as const,
});
