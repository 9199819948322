import { BodyText, Stack, ValueText, styled, withStaticProperties } from '@arrived/bricks';

import { SectionHeaderName } from './constants';

export const SectionHeader = withStaticProperties(
  styled(Stack, {
    name: SectionHeaderName,

    px: '$4',
    py: '$4',
    bg: '$onSurface.neutral.zebraAlt',
    gap: '$1',
  }),
  {
    Text: styled(ValueText, { token: 'value.medium' }),
    SubText: styled(BodyText, { token: 'body.default.small' }),
  },
);
