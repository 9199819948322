import { ReactNode } from 'react';

import { IntlShape } from 'react-intl';

import { parse, stringify } from 'qs';

import { Avatar, Box } from '@mui/material';

import { Account } from '@arrived/models';

export interface BuildProfileLabelArgs {
  includeBetaMode?: boolean;
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
  isAdmin?: boolean;
  account?: Account;
  intl: IntlShape;
}

export const buildProfileLabel = ({
  includeBetaMode,
  firstName,
  profilePictureUrl,
  intl,
}: BuildProfileLabelArgs): ReactNode => {
  // Note: If we get back a missing image from Auth0 (Gravatar), we want to change the default image to be the first
  // letter of their name not first two
  const profilePictureParts = profilePictureUrl && profilePictureUrl.split('?');
  // We are asserting here because we know that the query params will be there
  const profilePictureQueryParams = parse(profilePictureParts![1]);

  if (profilePictureQueryParams && profilePictureQueryParams.d) {
    const defaultImgUrl = profilePictureQueryParams.d as string;
    const imageRegex = /([a-zA-Z])([a-zA-Z])(.png)/;

    profilePictureQueryParams.d = defaultImgUrl.replace(imageRegex, '$1$3');
  }

  const pictureSrc =
    profilePictureParts && profilePictureParts[0]
      ? `${profilePictureParts[0]}?${stringify(profilePictureQueryParams)}`
      : 'fakebroken';

  const profilePicture = pictureSrc && (
    <Avatar
      className="profile-gravatar"
      alt={intl.formatMessage({ id: 'account.current-user.name' }, { currentUser: firstName })}
      src={pictureSrc}
    />
  );

  return (
    <Box
      className="profile-label"
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        '.profile-gravatar': {
          width: '33px',
          height: '33px',
        },
      }}
    >
      {includeBetaMode ? (
        <>
          {profilePicture}
          {firstName}
        </>
      ) : (
        <>
          {firstName}
          {profilePicture}
        </>
      )}
    </Box>
  );
};
