import { Account, AccountId } from './account';
import { BuySell } from './buySell';
import { TradeUpdatePaymentsEntry } from './createOfferingTransactionRequest';
import { FundStatus } from './fundStatus';
import { FundType } from './fundType';
import { Offering, OfferingId } from './offerings';
import { SignedStatus } from './signedStatus';
import { TransactionStatus } from './trades';
import { User, UserId } from './users';

export type OfferingTransactionId = number;

export enum TRANSACTION_ERROR {
  NOT_FOUND = 'NotFoundException',
  UNPROCESSABLE_ENTITY = 'UnprocessableEntityException',
  REWARDS_SERVICE = 'RewardsServiceException',
}

export interface OfferingTransactionQueryParams {
  includePayments?: boolean;
}

export interface OfferingTransaction {
  id: OfferingTransactionId;
  cid: string;
  userId: UserId;
  /**
   * @deprecated to reduce the size of the OfferingTransaction model, we should not use this any longer and instead
   * use the userId to get the User data.
   */
  user: User;
  offeringId: OfferingId;
  /**
   * @deprecated to reduce the size of the OfferingTransaction model, we should not use this any longer and instead
   * use the offeringId to get the Offering data.
   */
  offering: Offering;
  accountId: AccountId;
  /**
   * @deprecated to reduce the size of the OfferingTransaction model, we should not use this any longer and instead
   * use the accountId to get the Account data.
   */
  account?: Account;
  ncTradeId?: string; // will be null if failed to sync to north capital
  ncOfferingId: string;
  ncAccountId: string;
  flow: 'NORTH_CAPITAL' | 'CASH_ACCOUNT';
  settledAt?: string;
  type: BuySell;
  fundType: FundType;
  status: TransactionStatus;
  fundStatus: FundStatus;
  documentsSignedStatus: SignedStatus;
  /**
   * Number of shares purchased.
   */
  sharesCount: number;
  /**
   * Number of shares sold. Initially zero.
   *
   * Will be less than the trade’s `sharesCount` if the trade is partially sold, or equal to the
   * trade’s `sharesCount` if fully sold.
   *
   * Will be updated in the same transaction that transitions a Sale to the PROCESSING status.
   */
  soldSharesCount: number;
  sharePrice: number;
  totalAmount: number;
  purchaseAmount: number | null;
  discountAmount: number | null;
  promotionUuid: string | null;
  promotionRedemptionUuid: string | null;
  promotionCode: string | null;
  rewardRedemptionUuid: string | null;
  notes?: string;
  ncErrorStatus?: string;
  ncErrorMessage?: string;
  createdAtIp: string;
  updatedAtIp?: string;
  createdAt: string;
  signedAt?: string;
  updatedAt?: string;
  active: boolean;
  isHoldShares: boolean;
  /**
   * Reg D Offerings must be held for a minimum of one year, investors making these transactions must accept this
   * additional requirement in order to make a Reg D transaction. This needs only be present on Reg D
   * OfferingTransactions.
   */
  acceptMinHoldPeriod?: boolean;
  projections: {
    payments: null | TradeUpdatePaymentsEntry[];
  };
}
