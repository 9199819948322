/**
 * Actions needed to setup a users external account for investing, ordered by precedence (i.e. 0 has the highest precedence).
 */
export const ExternalAccountSetupState = {
  LINK_BANK: 'LINK_BANK',
  VERIFY_BANK: 'VERIFY_BANK',
  // TODO: If we're able to get webhook setup with Plaid, we could move users to READY_TO_VERIFY when we know they've
  //  received deposits
  // READY_TO_VERIFY
  VERIFIED: 'VERIFIED',
} as const;

export type ExternalAccountSetupState = (typeof ExternalAccountSetupState)[keyof typeof ExternalAccountSetupState];

export const EXTERNAL_ACCOUNT_SETUP_ACTION_STATES = [
  ExternalAccountSetupState.LINK_BANK,
  ExternalAccountSetupState.VERIFY_BANK,
];
