import { useQuery } from '@tanstack/react-query';

import { getPendingOfferingHistory } from '@arrived/api_v2';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { offeringHistoryPendingQueryKeyFn } from './offeringHistory.keys';

export function useGetPendingOfferingHistoryQuery(options?: UseAwaitedQueryOptions<typeof getPendingOfferingHistory>) {
  return useQuery({
    queryKey: offeringHistoryPendingQueryKeyFn(),
    queryFn: () => getPendingOfferingHistory(),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
