import { getUsersTrades } from '@arrived/api_v2';
import { TradesQueryFilters } from '@arrived/models';

import { useGetCurrentUserQuery, useGetPrimaryAccountQuery } from '../users';
import { UseAwaitedQueryOptions } from '../utils';

import { useAccountTradesQuery } from './useAccountTradesQuery';
import { useUserTradesQuery } from './useUserTradesQuery';

/**
 * Loads trades for a user. If the user has an account, the account trades are returned, otherwise all of the user's
 * trades are returned. If no userId is specified, the current user ID will be used.
 * If no accountId is specified, the current primary account ID will be used.
 */
export function useTradesQuery(filters?: TradesQueryFilters, options?: UseAwaitedQueryOptions<typeof getUsersTrades>) {
  const currentUserState = useGetCurrentUserQuery();
  const primaryAccountState = useGetPrimaryAccountQuery();

  const userId = currentUserState.data?.id;
  const accountId = primaryAccountState.data?.id;

  // If the user doesn't have a primary account, we assume there is no account for that user and we should instead get
  // their trades by using the user/trades endpoint.

  const accountTradesState = useAccountTradesQuery(accountId, filters);

  const userTradesState = useUserTradesQuery(userId, filters, {
    ...options,
    enabled: (options?.enabled ?? true) && accountTradesState.isSuccess && accountId == null,
  });

  return accountId != null ? accountTradesState : userTradesState;
}
