import { ComponentProps } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import {
  Anchor,
  AppreciationIcon,
  ArrowRightIcon,
  BodyText,
  Button,
  Divider,
  InfoIcon,
  RentalIncomeIcon,
  Stack,
  Tag,
  TitleText,
  Tooltip,
  UtilityText,
  ValueText,
  styled,
} from '@arrived/bricks';
import { ROUTES } from '@arrived/common';
import { AssetType, Offering } from '@arrived/models';

import { TopReturnsPartOne, TopReturnsPercentages } from '../rentalIncome/text';
import { ExpensesTooltipContent } from '../rentalIncome/text/ExpensesTooltipContent';
import { useRentalIncomeCalculationData } from '../rentalIncome/useRentalIncomeCalculationData';

const CurrencyFigure = (props: ComponentProps<typeof FormattedNumber>) => (
  <FormattedNumber style="currency" currency="USD" maximumFractionDigits={0} {...props} />
);

const PercentFigure = (props: ComponentProps<typeof FormattedNumber>) => (
  <FormattedNumber style="percent" maximumFractionDigits={1} minimumFractionDigits={0} {...props} />
);

const DisplayBox = styled(Stack, {
  p: '$2',
  br: '$1',
  bg: '$onSurface.neutral.zebra',
  borderWidth: 1,
  borderColor: '$onSurface.neutral.outlineAlt',
  minHeight: 105,
  height: '100%',
  width: '100%',
  centered: true,

  $gtXxs: {
    width: 165,
  },
});

interface HistoricalReturnsProps {
  offering: Offering;
}

export const HistoricalReturns = ({ offering }: HistoricalReturnsProps) => {
  const { assetType } = offering;
  const { dividends, dividendYield, expenses, rent } = useRentalIncomeCalculationData(offering);

  return (
    // @NOTE This is a hack to adjust this to the `gap` property of the parent, when redesigning this page we should fix this to remove `pt` and `pb`
    <Stack px="$6" pt="$2" pb="$6" gap="$6">
      <TitleText token="title.heading.small">
        {offering.assetType === AssetType.LTR ? (
          <FormattedMessage id="common.single.family.residential" />
        ) : (
          <FormattedMessage id="common.vacation.rental" />
        )}
      </TitleText>

      <Stack gap="$4">
        <Stack alignItems="center" flexDirection="column" gap="$1" $gtXxs={{ flexDirection: 'row' }}>
          <DisplayBox>
            <TitleText token="title.heading.large">
              <TopReturnsPercentages offering={offering} />
            </TitleText>
            <Stack flexDirection="row" gap="$2" centered>
              <UtilityText token="utility.helper.medium">
                <FormattedMessage id="common.per-year" />
              </UtilityText>
              <Stack br="$1">
                <Tag condensed variant="inverted" br="$1" p="$1">
                  <Tag.Label textTransform="uppercase">
                    <FormattedMessage id={offering.debtPercent > 0 ? 'common.levered' : 'common.unlevered'} />
                  </Tag.Label>
                </Tag>
              </Stack>
            </Stack>
          </DisplayBox>

          <Stack gap="$4" flex={1} alignItems="flex-start">
            {/* Fix to adjust the `Button` to be inline with the text. */}
            <Stack gap="$4" $gtXxs={{ pl: '$4' }}>
              <BodyText token="body.default.medium">
                <TopReturnsPartOne offering={offering} />
              </BodyText>
              <UtilityText token="utility.helper.small">
                <FormattedMessage id="property-details.returns-data.part-1.disclaimer" />
              </UtilityText>
            </Stack>
            <Button
              variant="ghost"
              href="https://help.arrived.com/en/articles/4953922-what-returns-can-i-expect-from-arrived-properties"
              target="_blank"
              rel="noopener noreferrer"
              Icon={ArrowRightIcon}
            >
              <FormattedMessage id="common.learn-more" />
            </Button>
          </Stack>
        </Stack>

        <Stack alignItems="center" flexDirection="column" gap="$4" $gtXxs={{ flexDirection: 'row' }}>
          <DisplayBox>
            <Stack flexDirection="row" centered gap="$1">
              <RentalIncomeIcon color="$onSurface.neutral.default" size="$4" />
              <UtilityText token="utility.helper.medium">
                <FormattedMessage id="common.annual-cashflow" />
              </UtilityText>
            </Stack>
            <UtilityText token="utility.helper.medium">+</UtilityText>
            <Stack flexDirection="row" centered gap="$1">
              <AppreciationIcon color="$onSurface.neutral.default" size="$4" />
              <UtilityText token="utility.helper.medium">
                <FormattedMessage id="common.appreciation" />
              </UtilityText>
            </Stack>
          </DisplayBox>

          <Stack flex={1} alignItems="flex-start">
            <BodyText token="body.default.medium">
              <FormattedMessage id="property-details.returns-data.return-split" />
            </BodyText>
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <Stack gap="$6">
        <Stack gap="$4">
          <Stack alignItems="center" gap="$2" flexDirection="row">
            <RentalIncomeIcon color="$onSurface.neutral.default" size="lg" />
            <TitleText token="title.heading.small">
              <FormattedMessage id="common.anticipated-annual-cashflow" />
            </TitleText>
          </Stack>

          <Stack br="$4" overflow="hidden" borderWidth={1} borderColor="$onSurface.neutral.outlineAlt">
            <Stack bg="$onSurface.neutral.zebra" p="$6" gap="$4">
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap="$4">
                <Tooltip>
                  <Tooltip.Trigger>
                    <UtilityText token="utility.label.small.alt">
                      <FormattedMessage id="common.annual-rent" />
                    </UtilityText>
                  </Tooltip.Trigger>
                  <Tooltip.Content tooltipTitle={<FormattedMessage id="common.annual-rent" />}>
                    <Tooltip.Text>
                      <FormattedMessage
                        id={
                          assetType === AssetType.LTR
                            ? 'property-details.annual-rent.description.sfr'
                            : 'property-details.annual-rent.description.vr'
                        }
                      />
                    </Tooltip.Text>
                  </Tooltip.Content>
                </Tooltip>

                <ValueText token="value.medium.alt">
                  <CurrencyFigure value={rent} />
                </ValueText>
              </Stack>

              <Stack centered flexDirection="row" gap="$4" width="100%">
                <Divider flex={1} />

                <Stack bg="$onSurface.neutral.default" centered br="$full" w={20} h={20}>
                  <UtilityText token="utility.label.medium" color="$onSurface.neutral.defaultInverted">
                    &#x2212;
                  </UtilityText>
                </Stack>

                <Divider flex={1} />
              </Stack>

              <Stack flexDirection="row" justifyContent="space-between" gap="$4">
                <Stack
                  flex={1}
                  maxWidth="70%"
                  $gtXxs={{
                    maxWidth: '100%',
                  }}
                >
                  <Tooltip>
                    <Tooltip.Trigger>
                      <Stack
                        flexDirection="row"
                        gap="$1"
                        alignItems="center"
                        justifyContent="flex-start"
                        maxWidth="100%"
                      >
                        <UtilityText token="utility.label.small.alt" ellipse lineHeight={18}>
                          <FormattedMessage id="property-details.returns-data.expenses-line-item" />
                        </UtilityText>
                        <InfoIcon color="$onSurface.neutral.default" size="sm" />
                      </Stack>
                    </Tooltip.Trigger>
                    <Tooltip.Content
                      tooltipTitle={<FormattedMessage id="property-details.returns-data.expenses-line-item" />}
                    >
                      <Tooltip.Text>
                        <ExpensesTooltipContent offering={offering} />
                      </Tooltip.Text>
                    </Tooltip.Content>
                  </Tooltip>
                </Stack>

                <ValueText token="value.medium.alt">
                  <CurrencyFigure value={expenses} />
                </ValueText>
              </Stack>
            </Stack>

            <Stack bg="$onSurface.neutral.zebraAlt" p="$6">
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap="$4">
                <UtilityText token="utility.label.small.alt">
                  <FormattedMessage id="property-details.returns-data.cash-flow-dividends" />
                </UtilityText>

                <ValueText token="value.medium">
                  <CurrencyFigure value={dividends} />
                </ValueText>
              </Stack>
            </Stack>
          </Stack>

          <Stack bg="$onSurface.neutral.default" centered br="$full" w={20} h={20} alignSelf="center">
            <UtilityText token="utility.label.medium" color="$onSurface.neutral.defaultInverted">
              &#xf7;
            </UtilityText>
          </Stack>

          <Stack
            br="$4"
            bg="$onSurface.neutral.zebraAlt"
            p="$6"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <UtilityText token="utility.label.small.alt">
              <FormattedMessage id="common.raise-amount" />
            </UtilityText>

            <ValueText token="value.medium">
              <CurrencyFigure value={offering.targetRaiseAmount} />
            </ValueText>
          </Stack>

          <Stack bg="$onSurface.neutral.default" centered br="$full" w={20} h={20} alignSelf="center">
            <UtilityText token="utility.label.medium" color="$onSurface.neutral.defaultInverted">
              &#x3d;
            </UtilityText>
          </Stack>

          <Stack
            br="$4"
            bg="$surface.positive.default"
            p="$6"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip>
              <Tooltip.Trigger>
                <Stack flexDirection="row" gap="$1" centered>
                  <UtilityText token="utility.label.small.alt">
                    <FormattedMessage id="common.rental-dividend-yield-per-year" />
                  </UtilityText>

                  <InfoIcon color="$onSurface.neutral.default" size="sm" />
                </Stack>
              </Tooltip.Trigger>
              <Tooltip.Content tooltipTitle={<FormattedMessage id="common.rental-dividend-yield-per-year" />}>
                <Tooltip.Text>
                  <FormattedMessage id="property-details.returns-data.rental-dividend-yield.tooltip" />
                </Tooltip.Text>
              </Tooltip.Content>
            </Tooltip>

            <ValueText token="value.large">
              <PercentFigure value={dividendYield} />
            </ValueText>
          </Stack>
        </Stack>

        <Divider />

        <Stack gap="$4">
          <Stack alignItems="center" gap="$2" flexDirection="row">
            <AppreciationIcon color="$onSurface.neutral.default" size="lg" />

            <TitleText token="title.heading.small">
              <FormattedMessage id="common.appreciation" />
            </TitleText>
          </Stack>

          <Stack flexDirection="column" $gtSm={{ flexDirection: 'row' }} gap="$6">
            <BodyText token="body.default.medium">
              <FormattedMessage
                id={
                  offering.assetType === AssetType.LTR
                    ? 'property-details.returns-data.appreciation-description.part-1.sfr'
                    : 'property-details.returns-data.appreciation-description.part-1.vr'
                }
                values={{
                  strong: (parts) => (
                    <BodyText token="body.default.medium" fontWeight="bold">
                      {parts}
                    </BodyText>
                  ),
                }}
              />
            </BodyText>
            <BodyText token="body.default.medium">
              <FormattedMessage
                id="property-details.returns-data.appreciation-description.part-2"
                values={{
                  link: (parts) => (
                    <Anchor underline href={ROUTES.public.offeringCircular}>
                      {parts}
                    </Anchor>
                  ),
                }}
              />
            </BodyText>
            <BodyText token="body.default.medium">
              <FormattedMessage id="property-details.returns-data.appreciation-description.part-3" />
            </BodyText>
          </Stack>
        </Stack>

        <Stack
          br="$4"
          bg="$surface.positive.default"
          p="$6"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tooltip>
            <Tooltip.Trigger>
              <Stack flexDirection="row" gap="$1" centered>
                <UtilityText token="utility.label.small.alt">
                  <FormattedMessage id="common.historic-annual-appreciation" />
                </UtilityText>

                <InfoIcon color="$onSurface.neutral.default" size="sm" />
              </Stack>
            </Tooltip.Trigger>
            <Tooltip.Content tooltipTitle={<FormattedMessage id="common.historic-annual-appreciation" />}>
              <Tooltip.Text>
                <FormattedMessage id="tooltip.historic-annual-appreciation-description" />
              </Tooltip.Text>
            </Tooltip.Content>
          </Tooltip>

          <ValueText token="value.large">3-5%</ValueText>
        </Stack>
      </Stack>
    </Stack>
  );
};
