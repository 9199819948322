import { Rewards } from '@arrived/models';

import { rewardsApi } from '../api';
import { createQuery } from '../create-query';

export const getRewardsRedemption = (rewardUuid: string) =>
  createQuery<Rewards.RewardRedemption>({
    apiInstance: rewardsApi,
    method: 'get',
    url: `/reward-redemptions/${rewardUuid}`,
    config: { params: { includeVouchers: true, includePromotion: true } },
  });
