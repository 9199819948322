import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postQuestion } from '@arrived/api_v2';
import { Question } from '@arrived/models';

import { questionKeyFn, questionnaireQuestionsKeyFn } from './questionnaires.keys';

export function usePostQuestionMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postQuestion,
    onSuccess: (data, variables) => {
      queryClient.setQueryData<Question>(questionKeyFn(data.uuid), data);

      queryClient.invalidateQueries({ queryKey: questionKeyFn(data.uuid) });
      queryClient.invalidateQueries({ queryKey: questionnaireQuestionsKeyFn(variables.questionnaireId) });
    },
  });
}
