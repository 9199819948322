import { PropsWithChildren, forwardRef } from 'react';

import { Path, PathProps, Svg, SvgProps } from 'react-native-svg';

import { GetProps } from '@tamagui/core';

export const SvgFrame = forwardRef<Svg, SvgProps>((props, ref) => (
  <Svg ref={ref} viewBox="0 0 32 32" fill="none" {...props} />
));

export const PathFrame = forwardRef<Path, PropsWithChildren<PathProps>>((props, ref) => (
  <Path ref={ref} strokeWidth="2" strokeLinejoin="round" {...props} />
));

export type SvgFrameProps = GetProps<typeof SvgFrame>;
