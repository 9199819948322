import { ReactNode, createContext, useEffect, useState } from 'react';

import { REFERRAL_CODE_LOCAL_STORAGE_KEYS } from '@arrived/common';

export interface ReferralContextState {
  referralCode: string;
  storeReferralCode: (referralCode: string) => void;
}

export const ReferralContext = createContext({} as ReferralContextState);

export const ReferralCodeLocalStorage = {
  readItem: (): string => {
    if (typeof window !== 'undefined' && window.localStorage) {
      return localStorage.getItem(REFERRAL_CODE_LOCAL_STORAGE_KEYS.referralCode) || '';
    } else {
      return '';
    }
  },
  writeItem: (referralCode: ReferralContextState['referralCode']) => {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem(REFERRAL_CODE_LOCAL_STORAGE_KEYS.referralCode, referralCode || '');
    }
  },
};

interface ReferralContextProviderProps {
  children: ReactNode;
}

export const ReferralContextProvider = ({ children }: ReferralContextProviderProps) => {
  const [referralCode, setReferralCode] = useState<ReferralContextState['referralCode']>(
    ReferralCodeLocalStorage.readItem(),
  );

  const storeReferralCode = (referralCode: ReferralContextState['referralCode']) => {
    setReferralCode(referralCode);
    ReferralCodeLocalStorage.writeItem(referralCode);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get('referralCode');

    if (referralCode) {
      storeReferralCode(referralCode);
    }
  }, []);

  return (
    <ReferralContext.Provider
      value={{
        referralCode,
        storeReferralCode,
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
};
