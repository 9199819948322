import { useMutation, useQueryClient } from '@tanstack/react-query';

import { putPromotion } from '@arrived/api_v2';

import { promotionKeyFn, promotionsKeyFn } from './rewards.keys';

export function usePutPromotionMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putPromotion,
    onSuccess: (data) => {
      const { uuid } = data;

      queryClient.setQueryData(promotionKeyFn(uuid), data);

      queryClient.invalidateQueries({ queryKey: promotionsKeyFn() });
    },
  });
}
