import { useCallback } from 'react';
import { LayoutChangeEvent } from 'react-native';

import Animated, {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withDelay,
  withSequence,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import { Stack, StackProps } from '@arrived/bricks';

const AnimatedStack = Animated.createAnimatedComponent(Stack);

const smoothSpring = {
  damping: 30,
  stiffness: 200,
  mass: 1,
  overshootClamping: false,
  restDisplacementThreshold: 0.001,
  restSpeedThreshold: 0.001,
} as const;

type ProgressBarProps = {
  progress: number;
  gradient?: string[] | string;
  /**
   * @default 300
   */
  delay?: number;
  progressStyle?: StackProps;
} & StackProps;

export const ProgressBar = ({ progress, delay = 300, progressStyle, ...props }: ProgressBarProps) => {
  const containerWidth = useSharedValue(0);
  const mount = useSharedValue(false);
  const hasWidth = useSharedValue(false);

  const getLayoutContainerWidth = useCallback((event: LayoutChangeEvent) => {
    containerWidth.value = event.nativeEvent.layout.width;
    hasWidth.value = true;
  }, []);

  // For some odd reason, and based on the frame rate report this isn't even true,
  // but the `withSpring` happening here and then us interpolating the result
  // makes a much smoother like being drawn. Along with having a `setTimeout` act as the layout
  // this makes absolutely no sense, but from a user experience perspective it's much smoother.
  const layout = useDerivedValue(() => {
    if (!hasWidth.value) {
      return -containerWidth.value;
    }

    const containerPercentWidth = -containerWidth.value + (containerWidth.value * progress) / 100;

    // Wait until we've got the container width
    return !mount.value
      ? withSequence(
          withTiming(-containerWidth.value, { duration: 0 }),
          withDelay(
            delay,
            withSpring(containerPercentWidth, smoothSpring, () => {
              mount.value = true;
            }),
          ),
        )
      : withDelay(delay, withSpring(containerPercentWidth, smoothSpring));
  }, [containerWidth, progress]);

  const progressAnimation = useAnimatedStyle(
    () => ({
      // Width is really expensive to animate, so we are going to use a translateX
      transform: [{ translateX: interpolate(layout.value, [-containerWidth.value, 0], [-containerWidth.value, 0]) }],
    }),
    [containerWidth, layout],
  );

  return (
    <Stack onLayout={getLayoutContainerWidth} {...props}>
      <AnimatedStack bg="white" h="100%" w="100%" {...progressStyle} style={progressAnimation} />
    </Stack>
  );
};
