import { KycStatus } from '@arrived/models';

export const KycStatusTranslations = {
  [KycStatus.PENDING]: 'Pending',
  [KycStatus.AUTO_APPROVED]: 'Auto Approved',
  [KycStatus.MANUALLY_APPROVED]: 'Manually Approved',
  [KycStatus.AUTO_DISAPPROVED]: 'Auto Disapproved',
  [KycStatus.MANUALLY_DISAPPROVED]: 'Manually Disapproved',
  [KycStatus.NEED_MORE_INFO]: 'Need More Info',
  [KycStatus.PENDING_REVIEW]: 'Pending Review',
} as const;

export const formatKycStatus = (status: KycStatus) => KycStatusTranslations[status] ?? 'N/A';
