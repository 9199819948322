import { useQuery } from '@tanstack/react-query';

import { getAccountBlockAudits } from '@arrived/api_v2';
import { AccountCid } from '@arrived/models';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { adminAccountBlockAuditsKeyFn } from './adminAccountBlockAudits.keys';

export function useAdminGetAccountBlockAudits(
  accountCid: AccountCid,
  options?: UseAwaitedQueryOptions<typeof getAccountBlockAudits>,
) {
  return useQuery({
    queryKey: adminAccountBlockAuditsKeyFn(accountCid),
    queryFn: () => getAccountBlockAudits(accountCid),
    ...options,
    enabled: (options?.enabled ?? true) && accountCid != null,
  });
}
