import { Tokenizer } from '../../../utils/tokenizer';
import { RootTextProps } from '../RootText';

type ValueVariantsBase =
  | 'xxlarge'
  | 'xlarge'
  | 'large'
  | 'medium'
  | 'medium.alt'
  | 'small'
  | 'small.alt'
  | 'xsmall'
  | 'xsmall.alt';
export type ValueVariants = Tokenizer<'value', ValueVariantsBase>;

export const VALUE_VARIANTS: Record<ValueVariants, RootTextProps> = {
  'value.xxlarge': {
    fontSize: '$40',
    fontWeight: '$4',
    letterSpacing: '$0.04',
    lineHeight: '$48',
  },
  'value.xlarge': {
    fontSize: '$32',
    fontWeight: '$4',
    letterSpacing: '$0.02',
    lineHeight: '$38',
  },
  'value.large': {
    fontSize: '$20',
    fontWeight: '$4',
    letterSpacing: '$0.8',
    lineHeight: '$24',
  },
  'value.medium': {
    fontSize: '$16',
    fontWeight: '$4',
    letterSpacing: '$0',
    lineHeight: '$20',
  },
  'value.medium.alt': {
    fontSize: '$16',
    fontWeight: '$1',
    letterSpacing: '$0',
    lineHeight: '$20',
  },
  'value.small': {
    fontSize: '$14',
    fontWeight: '$4',
    letterSpacing: '$0',
    lineHeight: '$16',
  },
  'value.small.alt': {
    fontSize: '$14',
    fontWeight: '$1',
    letterSpacing: '$0',
    lineHeight: '$16',
  },
  'value.xsmall': {
    fontSize: '$12',
    fontWeight: '$4',
    letterSpacing: '$0',
    lineHeight: '$14',
  },
  'value.xsmall.alt': {
    fontSize: '$12',
    fontWeight: '$1',
    letterSpacing: '$0',
    lineHeight: '$14',
  },
};
