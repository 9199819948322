import { createContext, useContext } from 'react';
import { LayoutRectangle } from 'react-native';

export type CarouselStateContextType = {
  layout?: Partial<Pick<LayoutRectangle, 'width' | 'height'>>;
};

export const CarouselStateContext = createContext<CarouselStateContextType>({});

export const useCarouselState = () => {
  const context = useContext(CarouselStateContext);

  if (!context) {
    throw new Error('useCarouselState must be used within a CarouselStateContextProvider');
  }

  return context;
};
