import { FC, Ref, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { TamaguiElement, composeRefs, isServer, styled, useProps, useTheme } from '@tamagui/core';

import { setColorAlpha } from '../../utils/setColorAlpha';
import { Stack, StackProps } from '../layout';

const BLUR_VIEW_NAME = 'BlurView';

/**
 * @links
 * https://developer.mozilla.org/en-US/docs/Web/API/CSS/supports
 * https://developer.mozilla.org/en-US/docs/Web/CSS/backdrop-filter#Browser_compatibility
 */
const isBlurSupported = () =>
  !isServer &&
  typeof CSS !== 'undefined' &&
  (CSS?.supports('-webkit-backdrop-filter', 'blur(1px)') || CSS?.supports('backdrop-filter', 'blur(1px)'));

const blurSupported = isBlurSupported();

// https://github.com/expo/expo/blob/main/packages/expo-blur/src/BlurView.web.tsx

export type BlurViewProps = {
  /**
   * A tint mode which will be applied to the view.
   * @default 'default'
   */
  tint?: 'dark' | 'light' | 'default' | 'transparent';
  /**
   * A number from `1` to `100` to control the intensity of the blur effect.
   *
   * On web, you can take this number as high as you want. We cap it at
   * `100` on native though.
   *
   * You can animate this property using `Animated API` from React Native or using `react-native-reanimated`.
   * > Animating this property using `Animated API` from React Native with `setNativeDriver: true` does not work.
   *
   * @default 50
   */
  intensity?: number;

  /**
   * A number from `0` to `1000` to control how saturated the blur effect should be.
   * @web
   */
  saturation?: number;
} & StackProps;

/**
 * This allows us to interpret any reanimated events
 * every frame using `setNativeProps`. However, according
 * to this thread that is deprecated. So this gets around
 * it by setting the state every frame.
 *
 * https://github.com/necolas/react-native-web/discussions/2552
 */
const withNativeBlurStyles = <C extends TamaguiElement, D extends BlurViewProps>(WrappedComponent: FC<D>) => {
  const ComponentWithBlur = (props: D, ref: Ref<C>) => {
    const { tint = 'default', intensity: intensityProp = 50, saturation: saturationProp = 0, style, ...rest } = props;

    const theme = useTheme();
    const blurViewRef = useRef<C>(null);

    const getBackgroundColor = useCallback(
      (updatedIntensity: number) => {
        const opacity = Math.min(updatedIntensity, 100) / 100;

        return {
          dark: setColorAlpha('#1D2D3D', opacity * 0.25), // This just better matches cross platform
          light: setColorAlpha(theme['surface.neutral.default'].val, opacity * 0.78),
          default: setColorAlpha(theme['surface.negative.defaultAlt'].val, opacity * 0.3),
          transparent: 'transparent',
        }[tint];
      },
      [tint, intensityProp],
    );

    const getBlurStyle = useCallback(
      ({
        intensity: updatedIntensity,
        saturation: updatedSaturation,
      }: {
        intensity: number;
        saturation: number;
      }): Record<string, string> => {
        const style: Record<string, string> = {
          backgroundColor: getBackgroundColor(updatedIntensity),
        };

        if (blurSupported) {
          const backdropFilter = `blur(${updatedIntensity * 0.125}px) ${
            updatedSaturation ? `saturate(${Math.min(updatedSaturation, 1000)}%)` : ''
          }`;

          style.backdropFilter = backdropFilter;
          style.WebkitBackdropFilter = backdropFilter;
        }

        return style;
      },
      [getBackgroundColor],
    );

    const initialBlurStyle = getBlurStyle({ intensity: intensityProp, saturation: saturationProp });

    /**
     * Use this to directly manipulate setNativeProps
     * on every frame.
     */
    // @ts-expect-error -- This doesn't know what `setNativeProps` is or how it applies. This is an underlying API to get animations working.
    useImperativeHandle(ref, () => ({
      setNativeProps: (nativeProps: { style: BlurViewProps }) => {
        const intensity = nativeProps.style.intensity ?? intensityProp;
        const saturation = nativeProps.style.saturation ?? saturationProp;

        const blurStyle = getBlurStyle({ intensity, saturation });

        // @ts-expect-error -- technically, its right to complain. These are not assignable according to native standards, but web is a different beast.
        Object.assign(blurViewRef.current.style, blurStyle);
      },
    }));

    // @ts-expect-error -- This also isn't incorrect but it doesn't understand that `style` is a valid prop.
    return <WrappedComponent ref={composeRefs(blurViewRef, ref)} {...rest} style={[style, initialBlurStyle]} />;
  };

  return forwardRef(ComponentWithBlur);
};

const StyledBlurView = styled(Stack, {
  name: BLUR_VIEW_NAME,

  flex: 1,
  w: '100%',
  h: '100%',
  overflow: 'hidden',
});

const BlurViewFrame = forwardRef<TamaguiElement, BlurViewProps>((rest, ref) => {
  const styledProps = useProps(rest);

  return (
    <StyledBlurView
      ref={ref}
      {...rest}
      // For web we need to mimic the border radius of the container
      // as the backdrop filter does not respect border radius
      borderRadius={styledProps.borderRadius}
    />
  );
});

export const BlurView = withNativeBlurStyles(BlurViewFrame);
