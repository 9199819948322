import { Circle, Defs, Line, LinearGradient, Pattern, Rect, Stop } from 'react-native-svg';

import { useTheme } from '@arrived/bricks';

export const CHART_SVG_PATTERNS = {
  gradient: {
    /**
     * Light blue, fading down to white/transparent
     */
    primary: {
      id: 'bricks-charts-pattern-gradient-primary',
      fill: 'url(#bricks-charts-pattern-gradient-primary)',
    },
  },
  polkaDot: {
    /**
     * Light blue background, darker blue dots
     */
    primary: {
      id: 'bricks-charts-pattern-polka-dot-primary',
      fill: 'url(#bricks-charts-pattern-polka-dot-primary)',
    },
  },
  diagonalHatch: {
    /**
     * Light orange background, darker orange diagonal lines
     */
    secondary: {
      id: 'bricks-charts-pattern-diagonal-hatch-secondary',
      fill: 'url(#bricks-charts-pattern-diagonal-hatch-secondary)',
    },
  },
  neutral: {
    dark: {
      id: 'bricks-charts-pattern-neutral-dark',
      fill: 'url(#bricks-charts-pattern-neutral-dark)',
    },
  },
} as const;

/**
 * Add this component for easy reference when styling chart components.
 *
 * Included by default with `BricksCharts`.
 */
export const ChartSvgPatternDefinitions = () => {
  const theme = useTheme();

  return (
    <Defs>
      <Pattern id={CHART_SVG_PATTERNS.neutral.dark.id} width="4" height="4" patternUnits="userSpaceOnUse">
        <Rect fill={theme['neutral.light.900'].val} height="4" width="4" opacity={0.8} />
      </Pattern>

      <LinearGradient id={CHART_SVG_PATTERNS.gradient.primary.id} x1={0} y1={1} x2={0} y2={0}>
        <Stop offset={0} stopColor={theme['primary.light.500'].val} stopOpacity={0} />
        <Stop offset={4} stopColor={theme['primary.light.500'].val} stopOpacity={0.3} />
      </LinearGradient>

      <Pattern id={CHART_SVG_PATTERNS.polkaDot.primary.id} width="4" height="4" patternUnits="userSpaceOnUse">
        <Rect fill={theme['primary.light.200'].val} height="4" width="4" />
        <Circle cx="1" cy="1" r={0.65} fill={theme['primary.light.500'].val} />
        <Circle cx="3" cy="3" r={0.65} fill={theme['primary.light.500'].val} />
      </Pattern>

      <Pattern
        id={CHART_SVG_PATTERNS.diagonalHatch.secondary.id}
        width="4"
        height="4"
        patternTransform="rotate(45 0 0)"
        patternUnits="userSpaceOnUse"
      >
        <Rect fill={theme['secondary.light.300'].val} height="4" width="4" />
        <Line x1="0" y1="0" x2="0" y2="4" stroke={theme['secondary.light.400'].val} strokeWidth="2" />
      </Pattern>
    </Defs>
  );
};
