import { QueryKey } from '@tanstack/react-query';

import { AccountCid, AccountId, PlaidCreateTokenLinkRequest } from '@arrived/models';

export const accountsKeyRoot = 'accounts';

export const accountsKeyFn = (accountId: AccountId | AccountCid): QueryKey => [accountsKeyRoot, accountId];

export const accountsTaxIdKeyFn = (accountId: AccountId | AccountCid): QueryKey =>
  (accountsKeyFn(accountId) as readonly unknown[]).concat(['taxId']);

export const beneficiariesKeyFn = (accountId: AccountId | AccountCid): QueryKey =>
  (accountsKeyFn(accountId) as readonly unknown[]).concat(['beneficiaries']);

// TODO: Change back to 'balance' after old endpoint is deprecated
export const balanceKeyFn = (accountId: AccountId | AccountCid): QueryKey =>
  (accountsKeyFn(accountId) as readonly unknown[]).concat(['balanceV2']);

export const offeringBalancesKeyFn = (accountId: AccountId | AccountCid): QueryKey =>
  (accountsKeyFn(accountId) as readonly unknown[]).concat(['balanceV2', 'offerings']);

export const propertyOccupancyKeyFn = (accountId: AccountId | AccountCid): QueryKey =>
  (accountsKeyFn(accountId) as readonly unknown[]).concat(['balanceV2', 'propertyOccupancy']);

export const plaidTokenLinkKeyFn = ({ userId, ...rest }: PlaidCreateTokenLinkRequest): QueryKey => [
  'users',
  userId,
  'plaid',
  'token',
  rest,
];

export const plaidVerificationTokenLinkKeyFn = (accountId: AccountId | AccountCid): QueryKey => [
  'accounts',
  accountId,
  'plaid',
  'token',
  'verify',
];

export const cashAccountSyncKey = [accountsKeyRoot, 'sync-account-to-cash-account-service'];
