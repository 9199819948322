import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';

import { getAvailableOfferingFunds, isOfferingSoldOut } from '@arrived/common';
import { AssetType, Offering, OfferingStatus } from '@arrived/models';
import { useCanUserTransactEarlyAccess } from '@arrived/queries';

export interface OfferingValueStatusInfoProps extends StackProps {
  monetaryTypographyProps?: TypographyProps;
  offering: Offering;
  labelTypographyProps?: TypographyProps;
}

export const OfferingValueStatusInfo = ({
  offering,
  monetaryTypographyProps,
  labelTypographyProps,
  ...rest
}: OfferingValueStatusInfoProps) => {
  const canUserTransactEarlyAccess = useCanUserTransactEarlyAccess();

  const messageId = useMemo(() => {
    let id = 'common.available';

    if (offering.assetType === AssetType.STN) {
      id = 'common.annualized-yield';
      return id;
    }

    if (isOfferingSoldOut(offering)) {
      id = 'common.invested';
    } else if (
      offering.status === OfferingStatus.COMING_SOON ||
      (offering.status === OfferingStatus.TRANSACT_EARLY_ACCESS && !canUserTransactEarlyAccess)
    ) {
      id = 'common.coming-soon';
    }

    return id;
  }, [canUserTransactEarlyAccess, offering]);

  const fixedRateInvestment = offering.fixedRateInvestments?.[0];

  return (
    <Stack {...rest}>
      {offering.assetType === AssetType.STN && fixedRateInvestment ? (
        <>
          {/* TODO: Place image for STN up here */}
          {fixedRateInvestment.yieldPercent && (
            <Typography color={({ palette }) => palette.neutrals.gray} {...monetaryTypographyProps}>
              <FormattedNumber style="percent" maximumFractionDigits={3} value={fixedRateInvestment.yieldPercent} />
            </Typography>
          )}
          <Typography
            color={({ palette }) => palette.neutrals.gray}
            textTransform="uppercase"
            variant="body2.semibold"
            {...labelTypographyProps}
          >
            <FormattedMessage id={messageId} />
          </Typography>
        </>
      ) : (
        <>
          <Typography color={({ palette }) => palette.neutrals.gray} {...monetaryTypographyProps}>
            <FormattedNumber
              currency="USD"
              style="currency"
              maximumFractionDigits={0}
              value={isOfferingSoldOut(offering) ? offering.targetRaiseAmount : getAvailableOfferingFunds(offering)}
            />
          </Typography>
          <Typography
            color={({ palette }) => palette.neutrals.gray}
            textTransform="uppercase"
            variant="body2.semibold"
            {...labelTypographyProps}
          >
            <FormattedMessage id={messageId} />
          </Typography>
        </>
      )}
    </Stack>
  );
};
