import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { Offering } from '@arrived/models';

import { ProductInfoBox, ProductInfoBoxProps } from '../../../../productInfoBox';

import { DetailDataPoint } from './DetailDataPoint';

export type EquityRaiseProps = ProductInfoBoxProps & { offering: Offering };

export const EquityRaise = ({ offering, ...rest }: EquityRaiseProps) => {
  return (
    <ProductInfoBox {...rest}>
      <DetailDataPoint
        tooltip={{
          content: <FormattedMessage id="product-details.equity-raised-from-investors.tooltip" />,
          title: <FormattedMessage id="product-details.equity-raised-from-investors" />,
        }}
        flex={false}
      >
        <DataPoint.Value>
          <FormattedNumber
            style="currency"
            currency="USD"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            value={offering.targetRaiseAmount}
          />
        </DataPoint.Value>
        <DataPoint.Label>
          <FormattedMessage id="product-details.equity-raised-from-investors" />
        </DataPoint.Label>
      </DetailDataPoint>
    </ProductInfoBox>
  );
};
