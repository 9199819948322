import { ComponentType, MouseEvent } from 'react';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import { CONFIG } from '@arrived/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- will be deprecated
export interface SocialShareButtonsConfig<T = ComponentType<any>, U = ComponentType<any>> {
  ButtonComponent: T;
  buttonProps: Record<string, unknown>;
  IconComponent: U;
  isRendered?: boolean;
}

function getEmailShareButton(title: string, text: string): SocialShareButtonsConfig {
  return {
    ButtonComponent: EmailShareButton,
    buttonProps: {
      onClick: (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => event.preventDefault(),
      body: text,
      openShareDialogOnClick: true,
      subject: title,
    },
    IconComponent: EmailIcon,
  };
}

function getLinkedinShareButton(title: string, text: string): SocialShareButtonsConfig {
  return {
    ButtonComponent: LinkedinShareButton,
    buttonProps: {
      title,
      summary: text,
    },
    IconComponent: LinkedinIcon,
  };
}

function getTwitterShareButton(text: string, mainHashtag: string): SocialShareButtonsConfig {
  const hashtags = [mainHashtag];
  return {
    ButtonComponent: TwitterShareButton,
    buttonProps: {
      hashtags,
      title: text,
    },
    IconComponent: TwitterIcon,
  };
}

function getFacebookMessengerButton(): SocialShareButtonsConfig {
  return {
    ButtonComponent: FacebookMessengerShareButton,
    buttonProps: {
      appId: CONFIG.facebookAppId,
    },
    IconComponent: FacebookMessengerIcon,
  };
}

function getFacebookShareButton(text: string, mainHashtag: string): SocialShareButtonsConfig {
  return {
    ButtonComponent: FacebookShareButton,
    buttonProps: {
      hashtag: `#${mainHashtag}`,
      quote: text,
    },
    IconComponent: FacebookIcon,
  };
}

function getWhatsappShareButton(text: string): SocialShareButtonsConfig {
  return {
    ButtonComponent: WhatsappShareButton,
    buttonProps: {
      separator: ' - ',
      title: text,
    },
    IconComponent: WhatsappIcon,
  };
}

function getRedditShareButton(text: string): SocialShareButtonsConfig {
  return {
    ButtonComponent: RedditShareButton,
    buttonProps: { title: text },
    IconComponent: RedditIcon,
  };
}

function getPinterestShareButton(text: string, imageUrl: string | undefined): SocialShareButtonsConfig {
  return {
    ButtonComponent: PinterestShareButton,
    buttonProps: {
      description: text,
      media: imageUrl,
    },
    IconComponent: PinterestIcon,
    isRendered: typeof imageUrl !== 'undefined',
  };
}

function getWeiboShareButton(text: string): SocialShareButtonsConfig {
  return {
    ButtonComponent: WeiboShareButton,
    buttonProps: {
      title: text,
    },
    IconComponent: WeiboIcon,
  };
}

function getLineShareButton(text: string): SocialShareButtonsConfig {
  return {
    ButtonComponent: LineShareButton,
    buttonProps: {
      title: text,
    },
    IconComponent: LineIcon,
  };
}

export function getPopperButtonConfigs(
  title: string,
  text: string,
  mainHashtag: string,
  imageUrl: string | undefined,
): SocialShareButtonsConfig[] {
  return [
    getLinkedinShareButton(title, text),
    getFacebookShareButton(text, mainHashtag),
    getFacebookMessengerButton(),
    getTwitterShareButton(text, mainHashtag),
    getRedditShareButton(text),
    getPinterestShareButton(text, imageUrl),
    getWeiboShareButton(text),
    getWhatsappShareButton(text),
    getLineShareButton(text),
    getEmailShareButton(title, text),
  ];
}

export function getReferralPageButtonConfigs(
  title: string,
  text: string,
  mainHashtag: string,
): SocialShareButtonsConfig[] {
  return [
    getTwitterShareButton(text, mainHashtag),
    getFacebookShareButton(text, mainHashtag),
    getFacebookMessengerButton(),
    getEmailShareButton(title, text),
  ];
}
