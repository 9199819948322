import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Callout, CalloutProps } from '@arrived/bricks';
import { getCurrentMonthAtNoonUTC } from '@arrived/common';
import { Offering } from '@arrived/models';
import { useSharesAvailableQuery } from '@arrived/queries';

export type MaxInvestmentPerMonthCalloutProps = CalloutProps & { offering: Offering };

export const MaxInvestmentPerMonthCallout = ({ offering, ...rest }: MaxInvestmentPerMonthCalloutProps) => {
  const sharesAvailableState = useSharesAvailableQuery(offering.id);

  const maxInvestment = (
    <FormattedNumber
      value={(offering.maxSharesPerAccountPerMonth ?? 0) * offering.sharePrice}
      maximumFractionDigits={0}
      minimumFractionDigits={0}
      style="currency"
      currency="USD"
    />
  );
  return (
    <Callout hideCloseIcon variant="inverted" {...rest}>
      <Callout.Heading>
        <FormattedMessage
          id="product-details.max-investment-amount-disclaimer.monthly.title"
          values={{
            maxInvestment,
            availableAmount: (
              <FormattedMessage
                id="product-details.max-investment-amount-disclaimer.monthly.title.available-amount"
                values={{
                  sharesAvailable: sharesAvailableState.data?.monthAvailableSharesCount ?? 0,
                  monetaryUsedMonthlyAmount:
                    sharesAvailableState.data?.monthAvailableSharesCount !== undefined ? (
                      <FormattedNumber
                        currency="USD"
                        style="currency"
                        minimumFractionDigits={0}
                        maximumFractionDigits={2}
                        value={offering.sharePrice * sharesAvailableState.data.monthAvailableSharesCount}
                      />
                    ) : undefined,
                }}
              />
            ),
            month: getCurrentMonthAtNoonUTC(),
            hasUsedMonthlyLimit: Boolean(
              offering.maxSharesPerAccountPerMonth &&
                sharesAvailableState.data &&
                sharesAvailableState.data.monthAvailableSharesCount < offering.maxSharesPerAccountPerMonth,
            ),
          }}
        />
      </Callout.Heading>
      <Callout.Content>
        <Callout.Content.Text>
          <FormattedMessage
            id="product-details.max-investment-amount-disclaimer.monthly.description"
            values={{
              offeringName: offering.name,
              maxInvestment,
            }}
          />
        </Callout.Content.Text>
      </Callout.Content>
    </Callout>
  );
};
