import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';

import { TextField, TextFieldProps } from '@mui/material';

export type FormTextFieldProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> = {
  controllerProps: Omit<ControllerProps<TFieldValues, TName>, 'render'>;
} & TextFieldProps;

export const FormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
  controllerProps,
  ...textFieldProps
}: FormTextFieldProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field: { value, ref, ...rest } }) => (
        <TextField {...textFieldProps} inputRef={ref} value={value ?? ''} {...rest} />
      )}
    />
  );
};
