import * as yup from 'yup';

import { LEAD_REFERRAL_OPTIONS, TaxIdRequirement, getTaxIdRequirementMap } from '@arrived/common';
import { AccountType, Domicile, EntityType } from '@arrived/models';

import { sanitizeQuotes } from '../../utils';
import { AddressNoPOBoxSchema, AddressSchema, BirthDateSchema, PhoneNumberSchema } from '../common';

import { SuitabilityQuestionnaireSchema, getIsAccreditedInvestor } from './suitabilityQuestionnaire.schema';

export type ValidAccountType = AccountType.INDIVIDUAL | AccountType.ENTITY | AccountType.CHECKBOOK_IRA;

export const AccountsRequestSchema = yup
  .object({
    firstName: yup.string().trim().max(128).transform(sanitizeQuotes).required(),
    lastName: yup.string().trim().max(128).transform(sanitizeQuotes).required(),
    domicile: yup
      .mixed<Domicile>()
      .oneOf([Domicile.NON_RESIDENT, Domicile.US_PERMANENT_RESIDENT, Domicile.US_CITIZEN])
      .required(),
    accountType: yup
      .mixed<AccountType>()
      .oneOf([AccountType.INDIVIDUAL, AccountType.ENTITY, AccountType.CHECKBOOK_IRA])
      .required(),
    entityType: yup
      .mixed<EntityType>()
      .oneOf(Object.values(EntityType))
      .when(['accountType'], ([accountType]: [ValidAccountType], schema) => {
        switch (accountType) {
          case AccountType.INDIVIDUAL:
            return schema.notRequired().nullable().strip();
          case AccountType.ENTITY:
            return schema.required('account.entityType.required');
          case AccountType.CHECKBOOK_IRA:
            // There's an error with yup where we can't test .equals on an enum value so we have to do a test
            return schema.required('account.entityType.required').test({
              name: 'isLLC',
              test: (value) => value === EntityType.LLC,
            });
        }
      })
      .nullable(),
    entityName: yup
      .string()
      .trim()
      .when('accountType', ([accountType]: [ValidAccountType], schema) => {
        switch (accountType) {
          case AccountType.INDIVIDUAL:
            return schema.notRequired().nullable().strip();
          case AccountType.ENTITY:
          case AccountType.CHECKBOOK_IRA:
            return schema.required('account.entityName.required');
        }
      })
      .nullable()
      .typeError('account.entityName.required'),
    entityTitle: yup
      .string()
      .trim()
      .max(120)
      .when('accountType', ([accountType]: [ValidAccountType], schema) => {
        switch (accountType) {
          case AccountType.INDIVIDUAL:
            return schema.notRequired().nullable().strip();
          case AccountType.ENTITY:
          case AccountType.CHECKBOOK_IRA:
            return schema.required('account.entityTitle.required');
        }
      })
      .typeError('account.entityTitle.required')
      .nullable(),
    residentialAddress: AddressNoPOBoxSchema.required(),
    isMailingAddressSame: yup.boolean().required(),
    mailingAddress: AddressSchema.when('isMailingAddressSame', {
      is: false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().nullable().strip(),
    }),
    isBusinessAddressSame: yup.boolean().when('accountType', ([accountType]: [ValidAccountType], schema) => {
      switch (accountType) {
        case AccountType.INDIVIDUAL:
          return schema.notRequired().nullable().strip();
        default:
          return schema.required();
      }
    }),
    businessAddress: AddressSchema.when('isBusinessAddressSame', {
      is: false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().nullable().strip(),
    }),
    phoneNumber: PhoneNumberSchema.required('account.phoneNumber.required'),
    birthDate: BirthDateSchema.required(),
    ssn: yup
      .string()
      .trim()
      .matches(/^\d{3}-\d{2}-\d{4}$/, 'account.ssn.invalid')
      .required('account.ssn.required'),
    ein: yup
      .string()
      .trim()
      .matches(/^\d{2}-\d{7}$/, 'account.ein.invalid')
      .when(['accountType', 'entityType'], ([accountType, entityType]: [ValidAccountType, EntityType], schema) => {
        const taxIdRequirementMap = getTaxIdRequirementMap(accountType, entityType);
        switch (taxIdRequirementMap?.EIN) {
          case TaxIdRequirement.REQUIRED:
            return schema.required();
          case TaxIdRequirement.OPTIONAL:
            return schema.notRequired().nullable();
          case TaxIdRequirement.NONE:
          default:
            return schema.notRequired().nullable().strip();
        }
      })
      // When the EIN is empty, set it to null to satisfy `nullable`
      .transform((v, o) => (o === '' ? null : v)),
    suitabilityQuestionnaire: SuitabilityQuestionnaireSchema.required(),
    investResponsibly: yup.boolean().when('suitabilityQuestionnaire', {
      is: (suitabilityQuestionnaire: SuitabilityQuestionnaireSchema) =>
        getIsAccreditedInvestor(suitabilityQuestionnaire),
      then: (schema) => schema.notRequired().nullable().strip(),
      otherwise: (schema) => schema.required().oneOf([true]),
    }),

    /**
     * The `how did you hear about us?` field
     */
    leadReferral: yup
      .string()
      .oneOf(LEAD_REFERRAL_OPTIONS.map((option) => option.value))
      .trim(),

    /**
     * The `other` field for the `how did you hear about us?` field
     */
    leadReferralOther: yup.string().when('leadReferral', {
      is: 'other',
      then: (schema) => schema.required('Please provide a value for "Other"'),
    }),
  })
  .defined();

export type AccountsRequestSchema = yup.InferType<typeof AccountsRequestSchema>;

export const TaxIdRequestSchema = AccountsRequestSchema.pick(['ein', 'ssn', 'accountType', 'entityType']);

export type TaxIdRequestSchema = yup.InferType<typeof TaxIdRequestSchema>;
