import { useMemo } from 'react';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { getMinSharesToBuy, getUserOfferingTransactState, shouldUseSharesAvailableEndpoint } from '@arrived/common';
import { Offering } from '@arrived/models';
import {
  useCanUserTransactEarlyAccess,
  useGetPrimaryAccountQuery,
  useRegulationAccessSummaryQuery,
  useSharesAvailableQuery,
  useTradesQuery,
} from '@arrived/queries';

import { useCanUserTransactOpenForSec } from './useCanUserTransactOpenForSec';
import { useIsOfferingBlocked } from './useIsOfferingBlocked';

export interface UseUserOfferingTransactStateArgs {
  isVerified?: boolean;
  offering: Offering;
}

/**
 * Returns a detailed state value that takes into account data about the Offering and the user and derives a status about the User's ability to transact
 * on the specific offering in question.
 */
export function useUserOfferingTransactState({ isVerified = false, offering }: UseUserOfferingTransactStateArgs) {
  const canUserTransactEarlyAccess = useCanUserTransactEarlyAccess();
  const canUserTransactOpenSecTrade = useCanUserTransactOpenForSec();
  const accountState = useGetPrimaryAccountQuery();
  const regDAccessState = useRegulationAccessSummaryQuery();
  const { isAuthenticated } = useArrivedAuth0();

  const isSharesAvailableEnabled = shouldUseSharesAvailableEndpoint(offering.status);
  const sharesAvailableState = useSharesAvailableQuery(offering.id, {
    enabled: isSharesAvailableEnabled,
  });
  const isOfferingBlocked = useIsOfferingBlocked()(offering.shortName);

  // Checks if the total shares available on the Offering is less than the minimum shares a user
  // is required to by. If the number of shares available is less, then we consider the Offering
  // "sold out".
  const isOfferingSoldOut = useMemo(() => offering.totalSharesAvailable < getMinSharesToBuy(offering), [offering]);

  // Checks the authenticated shares available endpoint for the offering to see if shares for the specific user are available.
  // If not, then the offering is maxed out for that user.
  const isOfferingMaxedOut = useMemo(
    () =>
      sharesAvailableState.isSuccess && sharesAvailableState.data.availableSharesCount < getMinSharesToBuy(offering),
    [offering, sharesAvailableState.isSuccess, sharesAvailableState.data],
  );

  const tradesState = useTradesQuery();

  const isLoading = useMemo(() => {
    return (
      (sharesAvailableState.fetchStatus !== 'idle' && sharesAvailableState.isLoading) ||
      (tradesState.fetchStatus !== 'idle' && tradesState.isLoading) ||
      accountState.isLoading
    );
  }, [
    sharesAvailableState.fetchStatus,
    sharesAvailableState.isLoading,
    tradesState.fetchStatus,
    tradesState.isLoading,
    accountState.isLoading,
  ]);

  return useMemo(
    () =>
      getUserOfferingTransactState({
        account: accountState.data,
        canUserTransactOpenSecTrade,
        canTradeRegDOffering: regDAccessState.data?.canTradeRegDOffering,
        canUserTransactEarlyAccess,
        canViewRegDOffering: regDAccessState.data?.canViewRegDOffering,
        isAuthenticated,
        isLoading,
        isOfferingBlocked,
        isOfferingMaxedOut,
        isOfferingSoldOut,
        isVerified,
        offering,
      }),
    [
      accountState.data,
      regDAccessState.data?.canTradeRegDOffering,
      canUserTransactEarlyAccess,
      canUserTransactOpenSecTrade,
      regDAccessState.data?.canViewRegDOffering,
      isAuthenticated,
      isLoading,
      isOfferingBlocked,
      isOfferingMaxedOut,
      isOfferingSoldOut,
      isVerified,
      offering,
    ],
  );
}
