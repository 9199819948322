import { ElementType } from 'react';

import { FormattedMessage } from 'react-intl';

import { ButtonProps } from '@mui/material';

import { ROUTES } from '@arrived/common';

import { NAV_TYPE, NavItemProps } from '../../navigation.types';

export const loginNavItem = (buttonProps: ButtonProps): NavItemProps => ({
  text: <FormattedMessage id="login-nav-item" defaultMessage="Log In" />,
  buttonProps,
});

export const signUpText = <FormattedMessage id="sign-up-nav-item" defaultMessage="Sign Up" />;
export const signUpItem = (buttonProps: ButtonProps): NavItemProps => ({
  text: signUpText,
  buttonProps,
});

export const signUpButtonItem = (buttonProps: ButtonProps): NavItemProps => ({
  type: NAV_TYPE.BUTTON,
  text: signUpText,
  buttonProps,
});

export const myAccountItemFunds: NavItemProps = {
  text: <FormattedMessage id="my-account-nav-item" defaultMessage="My Account" />,
  path: ROUTES.account.settings,
};

export const cashAccountItem: NavItemProps = {
  text: <FormattedMessage id="nav.cash.account" defaultMessage="Account" />,
  path: ROUTES.account.base,
};

export function logoutItem<T extends ElementType, P>(buttonProps: ButtonProps<T, P>): NavItemProps {
  return {
    text: <FormattedMessage id="log-out-nav-item" defaultMessage="Log Out" />,
    buttonProps,
  };
}

export const adminItem: NavItemProps = {
  text: <FormattedMessage id="admin-nav-item" defaultMessage="Admin" />,
  path: ROUTES.admin.base,
};
