import { ReactNode, useEffect, useMemo, useState } from 'react';

import { add } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { stub } from '@arrived/common';

import { createUseContext } from './createUseContext';

export interface TimeBasedPaginationContextState {
  /**
   * Page size
   */
  itemsPerPage: number;
  /**
   * Updates page size.
   *
   * Also resets the timestamp cursor and clears the historical stack, as those previous cursors no
   * longer represent the current page size groupings.
   */
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  /**
   * Cursor from which the queries can scan backwards from.
   *
   * (Most date filters on our backend use ISO timestamps.)
   */
  isoTimestampCursor: string;
  /**
   * List of all previous cursors, in ascending order. Used for navigating back to previous pages.
   */
  pastTimestampsStack: string[];
  /**
   * Consumes a new Date cursor, formats it to an ISO date string, and updates internal state.
   *
   * Consumers of the `isoTimestampCursor` will then receive the updated query param.
   */
  nextPage: (newISOTimestampCursor: string) => void;
  /**
   * "Peels" off the latest cursor from the historical stack.
   *
   * Consumers of the `isoTimestampCursor` will then receive the updated query param.
   */
  previousPage: () => void;
}

interface TimeBasedPaginationContextProviderProps {
  children?: ReactNode;
  itemsPerPage?: TimeBasedPaginationContextState['itemsPerPage'];
  isoTimestampCursor?: TimeBasedPaginationContextState['isoTimestampCursor'];
}

/**
 * Context for managing pagination state for cursor-based applications.
 *
 * Roughly matches the `usePagination` hook, but is to be used alongside your data queries as
 * cursor-based pagination implies you don't know the total size of your data set.
 *
 * This handles the page size, current cursor, and past cursors, and your data queries handle the
 * items to be shown and determining whether there is more data to show.
 */
export const [TimeBasedPaginationContextProvider, useTimeBasePagination, TimeBasedPaginationContext] = createUseContext(
  {
    itemsPerPage: 10,
    setItemsPerPage: stub,
    isoTimestampCursor: '',
    pastTimestampsStack: [],
    nextPage: stub,
    previousPage: stub,
  } as TimeBasedPaginationContextState,
  (Provider) =>
    ({
      children,
      itemsPerPage: initialitemsPerPage = 10,
      isoTimestampCursor: optionalProvidedInitialTimestamp,
    }: TimeBasedPaginationContextProviderProps) => {
      // If a custom start time isn't provided, begin scanning starting at noon of the next day
      const initialISOTimestampCursor = useMemo(() => {
        const defaultISOTimestampCursor = add(new Date().setHours(12, 0, 0, 0), { days: 1 });

        return optionalProvidedInitialTimestamp || zonedTimeToUtc(defaultISOTimestampCursor, 'UTC').toISOString();
      }, [optionalProvidedInitialTimestamp]);

      const [itemsPerPage, setItemsPerPage] = useState<number>(initialitemsPerPage);
      const [isoTimestampCursor, setISOTimestampCursor] = useState<string>(initialISOTimestampCursor);
      const [pastTimestampsStack, setPastTimestampStack] = useState<string[]>([]);

      // If the page size has changed, reset the timestamp stack as those cursors no longer represent the current page size
      useEffect(() => {
        if (pastTimestampsStack.length) {
          setPastTimestampStack([]);
          setISOTimestampCursor(initialISOTimestampCursor);
        }
      }, [itemsPerPage]);

      // Update the stack of previous cursors, then update the current cursor value
      const setNewISOTimestampCursor = (newISOTimestampCursor: string) => {
        setPastTimestampStack([...pastTimestampsStack, isoTimestampCursor]);
        setISOTimestampCursor(newISOTimestampCursor);
      };

      // Update the current cursor value to the top of the historical stack, then update the stack
      const returnToPreviousISOTimestampCursor = () => {
        setISOTimestampCursor(pastTimestampsStack[pastTimestampsStack.length - 1]);
        setPastTimestampStack(pastTimestampsStack.slice(0, -1));
      };

      const value = useMemo(
        () => ({
          itemsPerPage,
          setItemsPerPage,
          isoTimestampCursor,
          pastTimestampsStack,
          nextPage: setNewISOTimestampCursor,
          previousPage: returnToPreviousISOTimestampCursor,
        }),
        [
          itemsPerPage,
          setItemsPerPage,
          isoTimestampCursor,
          pastTimestampsStack,
          setNewISOTimestampCursor,
          returnToPreviousISOTimestampCursor,
        ],
      );

      return <Provider value={value}>{children}</Provider>;
    },
);
