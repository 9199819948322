import { FormattedMessage } from 'react-intl';

import { ROUTES } from '@arrived/common';

import { NAV_TYPE, NavItemProps } from '../../navigation.types';

export const aboutUsNavItem: NavItemProps = {
  text: <FormattedMessage id="about-us-nav-item" defaultMessage="About Us" />,
  path: ROUTES.aboutUs,
  publicPath: ROUTES.public.about,
};

export const vacationRentalsNavItem: NavItemProps = {
  text: <FormattedMessage id="vacation-rentals-nav-item" defaultMessage="Vacation Rentals" />,
  publicPath: ROUTES.public.vacationRentals,
};

export const performanceNavItem: NavItemProps = {
  text: <FormattedMessage id="common.historical.returns" />,
  path: ROUTES.returns,
  publicPath: ROUTES.public.returns,
};

const careersNavItem: NavItemProps = {
  path: ROUTES.careers,
  publicPath: ROUTES.public.careers,
  text: <FormattedMessage id="careers-nav-item" defaultMessage="Careers" />,
};

const propertyPartnerNavItem: NavItemProps = {
  isExternal: true,
  path: ROUTES.static.partnerWithArrivedSurvey,
  text: <FormattedMessage id="partner-with-arrived-nav-item" defaultMessage="Partner with Arrived" />,
};

export const pressNavItem: NavItemProps = {
  path: `${ROUTES.aboutUs}#press`,
  publicPath: ROUTES.public.press,
  text: <FormattedMessage id="common.press" />,
};

export const stakeHolderCommitmentsNavItem: NavItemProps = {
  isExternal: true,
  shouldFollow: true,
  path: ROUTES.static.stakeholderCommitments,
  text: <FormattedMessage id="common.stakeholder.commitments" />,
};

export const aboutNavMultipleItem: NavItemProps = {
  text: <FormattedMessage id="about-nav-item" defaultMessage="About" />,
  type: NAV_TYPE.MULTIPLE,
  subItems: [
    aboutUsNavItem,
    pressNavItem,
    vacationRentalsNavItem,
    performanceNavItem,
    careersNavItem,
    stakeHolderCommitmentsNavItem,
    propertyPartnerNavItem,
  ],
};
