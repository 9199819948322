import { Divider, DividerProps } from '@mui/material';

export const ReturnsDivider = ({ sx, ...rest }: DividerProps) => (
  <Divider
    sx={{
      borderColor: ({ palette }) => palette.neutrals.gray,
      borderStyle: 'dashed',
      ...sx,
    }}
    {...rest}
  />
);
