import { AssetType, InvestmentProductType, Offering, OfferingType } from '@arrived/models';

export function getInvestmentProductType({
  offeringType,
  assetType,
}: {
  /**
   * @alias `type` in the Offering model
   */
  offeringType: Offering['type'];
  assetType: Offering['assetType'];
}): InvestmentProductType | undefined {
  if (offeringType === OfferingType.FUND) {
    if (assetType === AssetType.LTR) {
      return InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND;
    } else if (assetType === AssetType.PRIVATE_CREDIT) {
      return InvestmentProductType.PRIVATE_CREDIT_FUND;
    }
  }

  if (offeringType === OfferingType.EQUITY) {
    if (assetType === AssetType.LTR) {
      return InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO;
    } else if (assetType === AssetType.STR) {
      return InvestmentProductType.VACATION_RENTAL_IPO;
    }
  }

  if (assetType === AssetType.STN) {
    return InvestmentProductType.SHORT_TERM_NOTE;
  }
}
