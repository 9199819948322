import { Rewards } from '@arrived/models';

import { rewardsApi } from '../api';
import { createQuery } from '../create-query';

export const postPromotionRedemptionPreview = (requestData: Rewards.PromotionRedemptionRequest) =>
  createQuery<Rewards.PromotionRedemption>({
    apiInstance: rewardsApi,
    method: 'post',
    url: `/reward-redemptions-preview`,
    requestData,
    config: { params: { includeVouchers: true, includePromotion: true } },
  });

export const postRewardsRedemptionPreview = (requestData: Rewards.RewardRedemptionRequest) =>
  createQuery<Rewards.RewardRedemption>({
    apiInstance: rewardsApi,
    method: 'post',
    url: `/reward-redemptions-preview`,
    requestData,
    config: { params: { includeVouchers: true, includePromotion: true } },
  });
