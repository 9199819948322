export const radius = {
  0.5: 2,
  1: 4,
  2: 8,
  4: 16,
  6: 24,

  full: 999,

  /**
   * @default
   */
  true: 4,
};
