import { FC, useId } from 'react';

import ReactMarkdown, { ExtraProps, Options } from 'react-markdown';
import { Root } from 'react-markdown/lib';

import { visit } from 'unist-util-visit';

import { BodyText, RootTextProps, Stack } from '@arrived/bricks';

import { Href } from './href';

interface MarkdownComponentsProps {
  /**
   * Props that will be passed to the link component and override all the other defaults and
   * TextProps passed to this component.
   */
  LinkPropsOverrides?: RootTextProps;
  /**
   * Text component that will wrap each text element in the rendered markdown.
   */
  Text: FC<RootTextProps>;
  /**
   * Props that will be passed to every rendered instance of the Text component passed.
   */
  TextProps?: RootTextProps & { token?: string };
}

export interface MarkdownProps extends Partial<MarkdownComponentsProps> {
  children: Options['children'];
}

/**
 * In a recent release, `react-markdown` deprecated some properties that added `index` to the AST
 * nodes that it was deriving (see https://github.com/remarkjs/react-markdown/blob/main/changelog.md#remove-extra-props-passed-to-certain-components).
 * This function reverses some of that by adding the `index` back to `li` elements belonging to a
 * parent `ol` such that we can provide them with a numbered marker.
 */
const addIndexRehypePlugin = () => {
  return (tree: Root) => {
    visit(tree, (node) => {
      if (node.type === 'element' && node.tagName === 'ol') {
        let index = 0;

        visit(node, (node) => {
          if (node.type === 'element' && node.tagName === 'li') {
            node.properties.index = index++;
          }
        });
      }
    });
  };
};

/**
 * This function determines what marker we should use for a list item and returns said marker as
 * a `string`. The `addIndexRehypePlugin` function above will add a property to each `li` node in
 * the abstract tree and this function uses those properties to determine what the marker should be
 * for the list item.
 */
const getListItemMarker = (node: ExtraProps['node']) => {
  if (node?.properties.index != null && typeof node.properties.index === 'number') {
    return `${node.properties.index + 1})`;
  } else {
    // `\u2022` is the unicode character for a bullet point https://www.fileformat.info/info/unicode/char/2022/index.htm
    // for nodes that don't have an `index` property we can safely assume that they are a `li`
    // child of an unordered list, thus making a bullet the correct character to annotate the
    // item with.
    return '\u2022';
  }
};

export const Markdown = ({ children, LinkPropsOverrides, Text = BodyText, TextProps }: MarkdownProps) => {
  const id = useId();

  return (
    <ReactMarkdown
      key={id}
      rehypePlugins={[addIndexRehypePlugin]}
      components={{
        p: ({ children }) => <Text {...TextProps}>{children}</Text>,
        em: ({ children }) => (
          <Text {...TextProps} fontStyle="italic">
            {children}
          </Text>
        ),
        strong: ({ children }) => (
          <Text {...TextProps} fontWeight="600">
            {children}
          </Text>
        ),
        a: ({ children, href }) => (
          <Href href={href!}>
            <Text
              {...TextProps}
              color="$onSurface.primary.decorative"
              textDecorationLine="underline"
              {...LinkPropsOverrides}
            >
              {children}
            </Text>
          </Href>
        ),
        ol: ({ children }) => (
          <Stack tag="ol" pl="$2" m={0}>
            {children}
          </Stack>
        ),
        ul: ({ children }) => (
          <Stack tag="ul" pl="$2" m={0}>
            {children}
          </Stack>
        ),
        li: ({ children, node }) => (
          <Stack row gap="$2" tag="li">
            <Text {...TextProps}>{getListItemMarker(node)}</Text>
            <Text style={{ whiteSpaceCollapse: 'collapse' }} {...TextProps}>
              {children}
            </Text>
          </Stack>
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
