import { styled } from '@tamagui/core';

import { Stack } from '../../atoms';

import { SheetStyledContext } from './SheetStyledContext';

export const SHEET_BODY_NAME = 'SheetBody';

/**
 * Wraps all surrounding content, including `ScrollView` and `Footer`.
 */
export const SheetBody = styled(Stack, {
  name: SHEET_BODY_NAME,
  context: SheetStyledContext,

  flex: 1,

  h: '100%',
  bg: '$foundation.neutral.yang',
  position: 'relative',

  '$platform-web': {
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  variants: {
    variant: {
      completion: {
        bg: 'transparent',
      },
    },

    /**
     * Represents a sheet that will have rounded corners on the top,
     * useful for designs that want the body of the sheet to
     * look more separated from the header.
     */
    roundedEdges: {
      true: {
        borderTopLeftRadius: '$6',
        borderTopRightRadius: '$6',
      },
    },
  } as const,
});
