import { Closing, SignClosingQueryParams } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const markClosingAsSigned = (query: SignClosingQueryParams) =>
  createQuery<Closing>({
    apiInstance: api,
    method: 'post',
    url: `/closings/${query.cid}/mark-as-signed`,
    requestData: {
      signedAt: query.signedAt,
    },
  });
