import { stringify } from 'qs';

import {
  MfaDetails,
  OfferingTransaction,
  PropertyRentContract,
  PropertyRentContractRequest,
  TotalAvailableSharesResponse,
  TradesQueryFilters,
  User,
  UserEmailChangeRequest,
  UserId,
  UserNetWorth,
  UserNetWorthPostRequest,
  UserQueryParams,
  UserRequest,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getAllUsers = (params?: UserQueryParams) =>
  createQuery<User[]>({
    apiInstance: api,
    method: 'get',
    url: `/users/all`,
    config: {
      params,
      paramsSerializer: (p) =>
        stringify(p, {
          filter: (_, value) => {
            if (!value) {
              return undefined;
            }

            return value;
          },
          skipNulls: true,
        }),
    },
  });

export const getCurrentUser = () =>
  createQuery<User>({
    apiInstance: api,
    method: 'get',
    url: '/users',
  });

export const getUserMFADetails = (userId: UserId) =>
  createQuery<MfaDetails>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/mfa-details`,
  });

export const postUserResetMFA = (userId: UserId) =>
  createQuery<MfaDetails>({
    apiInstance: api,
    method: 'post',
    url: `/users/${userId}/reset-mfa`,
  });

export const postUserEnrollMFA = (userId: UserId) =>
  createQuery<MfaDetails>({
    apiInstance: api,
    method: 'post',
    url: `/users/${userId}/send-mfa-enrollment-email`,
  });

export const getUserById = (userId: UserId) =>
  createQuery<User>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}`,
  });

export const postUser = (requestData: UserRequest) =>
  createQuery<User>({
    apiInstance: api,
    method: 'post',
    url: '/users',
    requestData,
  });

export const patchUser = (requestData: UserRequest) =>
  createQuery<User>({
    apiInstance: api,
    method: 'patch',
    url: `/users/${requestData.id}`,
    requestData,
  });

export const postChangeEmail = ({ id: userId, ...requestData }: { id: UserId } & UserEmailChangeRequest) =>
  createQuery<string>({
    apiInstance: api,
    method: 'post',
    url: `/users/${userId}/change-email`,
    requestData,
  });

export const postChangePassword = (userId: UserId) =>
  createQuery<string>({
    apiInstance: api,
    method: 'post',
    url: `/users/${userId}/change-password`,
  });

export const getSharesAvailableForUser = (userId: UserId, offeringId: number) =>
  createQuery<TotalAvailableSharesResponse>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/offerings/${offeringId}/shares/available`,
  });

export const getUsersTrades = (userId: UserId, filters?: TradesQueryFilters) =>
  createQuery<OfferingTransaction[]>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/trades`,
    config: {
      params: filters,
      paramsSerializer: (params) => `${stringify(params, { arrayFormat: 'comma' })}`,
    },
  });

export const deleteUser = (userId: UserId) =>
  createQuery<User>({
    apiInstance: api,
    method: 'delete',
    url: `/users/${userId}`,
  });

export const getUsersCurrentRentContract = (userId: UserId) =>
  createQuery<PropertyRentContract>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/rent/contracts/current`,
  });

export const getUsersRentContracts = (userId: UserId) =>
  createQuery<PropertyRentContract>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/rent/contracts`,
  });

export const postUsersRentContract = (requestData: PropertyRentContractRequest) =>
  createQuery<PropertyRentContract>({
    apiInstance: api,
    method: 'post',
    url: `/users/${requestData.userId}/rent/contracts`,
    requestData,
  });

export const deleteUsersRentContract = (userId: UserId) =>
  createQuery<PropertyRentContract>({
    apiInstance: api,
    method: 'delete',
    url: `/users/rent/contracts/${userId}`,
  });

export const getUserSsn = (userId: UserId) =>
  createQuery<string>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/ssn`,
  });

export const postUserNetWorth = ({
  userId,
  ...requestData
}: UserNetWorthPostRequest & {
  userId: UserId;
}) =>
  createQuery<UserNetWorth>({
    apiInstance: api,
    method: 'post',
    url: `/users/${userId}/net-worth`,
    requestData,
  });

export const getUserNetWorth = (userId: UserId) =>
  createQuery<UserNetWorth>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/net-worth`,
  });
