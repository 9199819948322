import { ReactNode } from 'react';

import _invert from 'lodash/invert';

import { Address } from '@arrived/models';

import { StringUtils } from './stringUtils';

const PROVINCE_CODE_TO_STATE_MAP = {
  'US-AL': 'Alabama',
  'US-AK': 'Alaska',
  'US-AZ': 'Arizona',
  'US-AR': 'Arkansas',
  'US-CA': 'California',
  'US-CO': 'Colorado',
  'US-CT': 'Connecticut',
  'US-DE': 'Delaware',
  'US-DC': 'District Of Columbia',
  'US-FL': 'Florida',
  'US-GA': 'Georgia',
  'US-HI': 'Hawaii',
  'US-ID': 'Idaho',
  'US-IL': 'Illinois',
  'US-IN': 'Indiana',
  'US-IA': 'Iowa',
  'US-KS': 'Kansas',
  'US-KY': 'Kentucky',
  'US-LA': 'Louisiana',
  'US-ME': 'Maine',
  'US-MD': 'Maryland',
  'US-MA': 'Massachusetts',
  'US-MI': 'Michigan',
  'US-MN': 'Minnesota',
  'US-MS': 'Mississippi',
  'US-MO': 'Missouri',
  'US-MT': 'Montana',
  'US-NE': 'Nebraska',
  'US-NV': 'Nevada',
  'US-NH': 'New Hampshire',
  'US-NJ': 'New Jersey',
  'US-NM': 'New Mexico',
  'US-NY': 'New York',
  'US-NC': 'North Carolina',
  'US-ND': 'North Dakota',
  'US-OH': 'Ohio',
  'US-OK': 'Oklahoma',
  'US-OR': 'Oregon',
  'US-PA': 'Pennsylvania',
  'US-RI': 'Rhode Island',
  'US-SC': 'South Carolina',
  'US-SD': 'South Dakota',
  'US-TN': 'Tennessee',
  'US-TX': 'Texas',
  'US-UT': 'Utah',
  'US-VT': 'Vermont',
  'US-VA': 'Virginia',
  'US-WA': 'Washington',
  'US-WV': 'West Virginia',
  'US-WI': 'Wisconsin',
  'US-WY': 'Wyoming',
} as const;

type ProvinceCodeToState = typeof PROVINCE_CODE_TO_STATE_MAP;

// @NOTE Invert method as generic is not typing correctly here, to fix later with a new lib potentially
// https://github.com/remeda/remeda
const STATE_TO_PROVINCE_CODE_MAP = _invert(PROVINCE_CODE_TO_STATE_MAP) as {
  [K in keyof ProvinceCodeToState as ProvinceCodeToState[K]]: K;
};

const COUNTRY_CODE_TO_COUNTRY_MAP = {
  US: 'United States of America',
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CD: 'Congo (the Democratic Republic of the)',
  CG: 'Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands [Malvinas]',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (the Democratic People's Republic of)",
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (the Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan (Province of China)',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom of Great Britain and Northern Ireland',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands',
} as const;

type CountryCodeToCountryMap = typeof COUNTRY_CODE_TO_COUNTRY_MAP;

const COUNTRY_TO_COUNTRY_CODE_MAP = _invert(COUNTRY_CODE_TO_COUNTRY_MAP) as {
  [K in keyof CountryCodeToCountryMap as CountryCodeToCountryMap[K]]: K;
};

/**
 * Utility methods for working with locations. We use this to map different location codes to their pretty names,
 * and vice versa.
 *
 * @NOTE Maybe use this? https://www.npmjs.com/package/country-region-data
 */
export const LocationUtils = {
  /**
   * Map of province codes formatted as ISO 3166-2:US to their pretty names.
   */
  provinceCodes: new Map(Object.entries(PROVINCE_CODE_TO_STATE_MAP)),

  /**
   * Dictionary of province codes formatted to `{ provinceCode: provinceName }`
   * @example `{ 'US-WA': 'Washington' }`
   */
  provinceCodeDictionary: PROVINCE_CODE_TO_STATE_MAP,

  /**
   * Map of province codes formatted as ISO 3166-2:US to their codes.
   */
  provinces: new Map(Object.entries(STATE_TO_PROVINCE_CODE_MAP)),

  /**
   * Dictionary of province codes formatted to `{ provinceName: provinceCode }`
   */
  provincesDictionary: STATE_TO_PROVINCE_CODE_MAP,

  /**
   * Map of country codes formatted as ISO 3166-1 alpha-2 to their pretty names.
   */
  countryCodes: new Map(Object.entries(COUNTRY_CODE_TO_COUNTRY_MAP)),

  /**
   * Dictionary of country codes formatted to `{ countryCode: countryName }`
   */
  countryCodeDictionary: COUNTRY_CODE_TO_COUNTRY_MAP,

  /**
   * Map of country codes formatted as ISO 3166-1 alpha-2 to their codes.
   */
  countries: new Map(Object.entries(COUNTRY_TO_COUNTRY_CODE_MAP)),

  /**
   * Dictionary of country codes formatted to `{ countryName: countryCode }`
   */
  countriesDictionary: COUNTRY_TO_COUNTRY_CODE_MAP,

  /**
   * Get the pretty name for a province code formatted as ISO 3166-2:US.
   */
  getProvincePrettyName: (provinceIso3166?: string) => {
    // The silliness that typescript can't use a `str is string` and verify this is a string
    if (provinceIso3166 == null || provinceIso3166.trim().length === 0) {
      return '';
    }

    if (LocationUtils.provinceCodes.has(provinceIso3166)) {
      const prettyName = LocationUtils.provinceCodes.get(provinceIso3166);
      return prettyName || '';
    }

    return '';
  },

  getProvinceShortName: (provinceCode?: string) => {
    if (provinceCode == null || provinceCode.trim().length === 0) {
      return '';
    }

    if (provinceCode.startsWith('US-')) {
      return provinceCode.substr(3);
    }

    return provinceCode;
  },

  getCountryPrettyName: (countryIso3166?: string) => {
    if (countryIso3166 == null || countryIso3166.trim().length === 0) {
      return '';
    }

    if (LocationUtils.countryCodes.has(countryIso3166)) {
      const prettyName = LocationUtils.countryCodes.get(countryIso3166);
      return prettyName || '';
    }

    return '';
  },

  isOFACSanctionedCountry: (countryIso3166?: string) => {
    if (countryIso3166 == null || countryIso3166.trim().length === 0) {
      return false;
    }

    switch (countryIso3166) {
      case 'BY':
      case 'CI':
      case 'CD':
      case 'CG':
      case 'IR':
      case 'IQ':
      case 'LR':
      case 'KP':
      case 'SD':
      case 'SS':
      case 'SY':
      case 'ZW':
        return true;
    }

    return false;
  },

  getAddressPrettyFormat: (address: Address, lineBreakCharacter?: ReactNode) => {
    const { street, street2, city, province, zipCode } = address;

    return (
      <>
        {`${street}${street2 ? ` ${street2}` : ''}`}
        {lineBreakCharacter || <br />}
        {`${city}, ${LocationUtils.getProvinceShortName(province)} ${zipCode}`}
      </>
    );
  },

  getAddressString: (address: Address) => {
    const { street, street2, city, province, zipCode } = address;

    return [
      [street, street2]
        .filter(StringUtils.isNotBlank)
        .map((str) => str.trim())
        .join(' '),
      city?.trim(),
      [LocationUtils.getProvinceShortName(province), zipCode]
        .filter(StringUtils.isNotBlank)
        .map((str) => str.trim())
        .join(' '),
    ].join(', ');
  },
} as const;
