import { ColorTokens } from '@tamagui/core';

import { TagVariant } from '../Tag.constants';

export const getTagLabelPressableColor = (variant?: TagVariant, pressable?: boolean) => {
  let color: ColorTokens | undefined;

  switch (variant) {
    case 'inverted':
      color = '$interactive.neutral.hovered';
      break;
    case 'negative':
      color = '$interactive.negative.hovered';
      break;
    case 'positive':
      color = '$interactive.positive.hovered';
  }

  return pressable ? color : undefined;
};
