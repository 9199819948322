import { useMemo } from 'react';

import { PaddingProps } from 'victory';

import { useMedia } from '@arrived/bricks';

/**
 * Governs how much extra space to leave around the axes; prevents the labels from being
 * displayed outside/on top of the parent container.
 *
 * Primary margin to adjust/override is the `left` value, which protects the y-axis' labels.
 *
 * Adjusts 'manually' because the chart components don't support our media queries.
 */
export const useChartPadding = (chartPaddingProps?: PaddingProps) => {
  const media = useMedia();

  const chartPadding = useMemo(() => {
    const userDefinedPadding =
      typeof chartPaddingProps === 'number'
        ? { top: chartPaddingProps, left: chartPaddingProps, right: chartPaddingProps, bottom: chartPaddingProps }
        : chartPaddingProps ?? {};

    if (media.gtXs) {
      return { top: 16, bottom: 24, left: 44, right: 0, ...userDefinedPadding };
    }

    return { top: 16, bottom: 24, left: 60, right: 0, ...userDefinedPadding };
  }, [media, chartPaddingProps]);

  const chartPaddingBottomValue = useMemo(() => {
    if (chartPadding === undefined) {
      return 0;
    }
    if (typeof chartPadding === 'number') {
      return chartPadding;
    }
    return chartPadding.bottom ?? 0;
  }, [chartPadding]);

  return {
    chartPadding,
    chartPaddingBottomValue,
  };
};
