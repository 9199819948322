import { Sale, OfferingTransaction as Trade } from '@arrived/models';

import { Transaction, TransferCategory } from './cash-accounts';

/**
 * Wrapper object that joins Cash Account Transactions and Trades.
 */
export type CashAccountTransaction = {
  /**
   * Either a CA Transaction CID, a Trade ID, or a Sale CID.
   */
  uniqueId: string;
  type: 'CASH_ACCOUNT' | 'TRADE' | 'SALE';
  timestamp: string;
  category: TransferCategory | null;
  status: CashAccountTransactionStatus;
  totalAmount: string | number | undefined;
  extraData: Transaction | Trade | Sale;
};

export const CashAccountTransactionStatus = {
  /**
   * Primarily represents requested redemptions that have not been reviewed.
   */
  REQUESTED: 'REQUESTED',
  PENDING: 'PENDING',
  /**
   * Primarily represents trades in the fund movement phase.
   */
  PROCESSING: 'PROCESSING',
  POSTED: 'POSTED',
  SIGNATURE_NEEDED: 'SIGNATURE_NEEDED',
  CANCELLED: 'CANCELLED',
  /**
   * Primarily represents redemptions that have been approved by the review committee.
   */
  APPROVED: 'APPROVED',
  /**
   * Primarily represents redemptions that have been disapproved by the review committee.
   */
  REJECTED: 'REJECTED',
} as const;
export type CashAccountTransactionStatus =
  (typeof CashAccountTransactionStatus)[keyof typeof CashAccountTransactionStatus];
