import { isWeb } from '@tamagui/core';

import { SheetStyledContext } from '../SheetStyledContext';

export const SHEET_SCROLL_VIEW_NAME = 'SheetScrollView';

/**
 * Styles used for both web and native
 */
export const genericScrollViewStyles = {
  context: SheetStyledContext,

  keyboardShouldPersistTaps: 'handled',
  contentContainerStyle: {
    flexGrow: 1,
  },

  flexBasis: 0,
  flexGrow: 1,
  flexShrink: 1,

  pt: '$6',
  px: '$4',

  $gtXxs: {
    px: '$6',
  },

  $gtSm: {
    px: '$10',
  },

  variants: {
    centerContent: {
      true: {
        contentContainerStyle: {
          flexGrow: 1,
          justifyContent: 'center',
          paddingBottom: isWeb ? 0 : 24,
        },
        bounces: false,
      },
    },

    bottomPadding: {
      true: {
        pb: '$6',
      },
    },

    /**
     * Enable or disable the scroll fade effect on the bottom of the sheet.
     * @default true
     */
    useScrollFade: {
      true: {},
    },
  } as const,
} as const;
