import { NextURL } from 'next/dist/server/web/next-url';
import { NextRequest, NextResponse } from 'next/server';

import { GrowthBook } from '@growthbook/growthbook';

import { CONFIG } from '@arrived/config';
import { type AppFeatures } from '@arrived/feature-flags';

import { getOrCreateArrivedVisitorId } from './getOrCreateArrivedVisitorId';

// ! NOTE: Do not import anything else from `feature-flags`.
// ! Types are fine but importing anything else will cause
// ! the middleware build to fail, double check this by running `nx build public-app --configuration staging`

// The value of this variable needs to match the variable of the same name within @arrived/analytics. Due to constraints around
// what can be run in middleware, we can't import from @arrived/analytics, so we need to define this separately here. See
// https://nextjs.org/docs/messages/edge-dynamic-code-evaluation
export const arrivedVisitorIdCookie = 'arrived_visitor_id';

/**
 * The configuration object for a public facing page A/B Test.
 */
export interface ABPageTestConfig {
  /**
   * If the flag cannot be found or the fetched variation ID does not have a matching entry in the variations Record,
   * the user will be redirected to this page.
   */
  defaultPath: string;
  /**
   * The name of the feature flag in GB that is controlling the experiment
   */
  flag: keyof AppFeatures;
  /**
   * Optional function that will return true if the provided request should be omitted from the A/B test.
   */
  ignoreRequest?: (req: NextRequest) => boolean;
}

export interface PartnerPageTestConfig {
  defaultPath: string;
  flag: keyof AppFeatures;
  ignoreRequest?: (req: NextRequest) => boolean;
}

/**
 * Commenting out, but saving for when we test solely on page path again

  export const ABPageTests: Record<string, ABPageTestConfig> = {
    '/register': {
      defaultPath: '/register',
      flag: 'abc-register_page', // FEATURE_FLAGS.REGISTER_PAGE_TEST
    },
    '/start': {
      defaultPath: '/start',
      flag: 'ab-benzinga-branded-start',
      ignoreRequest: ({ nextUrl }) => {
        const utmCampaign = nextUrl.searchParams.get('utm_campaign');
        return utmCampaign !== '1006';
      }
    },
  };

*/

export const PartnerPageByUtmCampaignTests: Record<string, PartnerPageTestConfig> = {
  '1006': {
    defaultPath: '/start',
    flag: 'ab-benzinga-branded-start',
  },
  '1216': {
    defaultPath: '/start',
    flag: 'ab-moneywise-branded-start',
  },
};

export const isInAbTest = (req: NextRequest) => {
  const url = new URL(req.url);

  // Get UTM_Campaign from request
  const utmCampaign = url.searchParams.get('utm_campaign');
  if (!utmCampaign) return false;

  // Check if the UTM_Campaign is part of the AB tests
  if (Object.keys(PartnerPageByUtmCampaignTests).includes(utmCampaign)) {
    // Check if a/b test exists for partner by utm_campaign
    const abPageTestConfig = PartnerPageByUtmCampaignTests[utmCampaign];

    // Is path matching the default path for the experiment
    const isRequestPathMatchingExperiment = req.nextUrl.pathname === abPageTestConfig.defaultPath;

    // Check if request should be ignored or if request path doesn't match a/b test path
    return !abPageTestConfig.ignoreRequest?.(req) && isRequestPathMatchingExperiment;
  }
  return false;
};

// Key that we'll use in the redirected URL search params to indicate to the client what the feature flag's name was
// that was used to segment this user into a group.
export const abPageTestSearchParamsKey = 'abptff';

/**
 * This function performs the functionality necessary within our middleware for orchestrating our landing page A/B
 * tests.
 *
 * Provided a flag name it will:
 * - Fetch the value of the flag from Growthbook
 * - Rewrite the response to navigate the user to the path specified under their assigned variation.
 * - Set the value of the arrivedVisitorIdCookie to a newly created UUID if the cookie has not yet been assigned.
 * - Set the value of a cookie under the key corresponding to the key of the feature flag with the value as the string
 *   version of the variation ID the user was assigned to.
 *
 * It's expected then that the pages will use RudderStack's `identify` function to identify the user on the client-side
 * with the arrivedVisitorIdCookie as one of the attributes. This will allow us to track the user and assign them
 * variations even on their very first visit.
 *
 * If the feature or variation does not exist, the user is redirected to the default value specified in the config.
 */
export const getAbPageTestResponse = async (
  { defaultPath, flag }: ABPageTestConfig,
  request: NextRequest,
  reqUrl?: NextURL,
): Promise<NextResponse> => {
  // If the user provides a url, use that, otherwise clone it from the request.
  const url = new URL(reqUrl ?? request.nextUrl.clone());

  const navigateToDefault = () => {
    const url = reqUrl ?? request.nextUrl.clone();
    url.pathname = defaultPath;
    return NextResponse.rewrite(url);
  };

  // If the user already has a value for the arrivedVisitorIdCookie, use that, if not create a new UUID and assign it
  // to the local value. This will be set in the response cookies at the end.
  const arrivedVisitorId = getOrCreateArrivedVisitorId(request);

  // Configure GrowthBook and load the features. We leave the default timeout of '0', this means that the first call will
  // simply load the cache with the feature values, future page loads will have the features loaded in the cache and can
  // immmediately reference them.
  const gb = new GrowthBook<AppFeatures>({
    attributes: { arrivedVisitorId },
    apiHost: 'https://cdn.growthbook.io',
    clientKey: CONFIG.growthbookApiKey,
  });
  await gb.loadFeatures();

  // Set the user's attributes so they get assigned to a specific variation.
  gb.setAttributes({ arrivedVisitorId });

  const featureResult = gb.evalFeature(flag as keyof AppFeatures);

  // If the feature isn't recognize, navigate to the default path.
  if (featureResult.source === 'unknownFeature') {
    return navigateToDefault();
  }

  const pathname = featureResult.value as string;

  // If the pathname doesn't exist in the variations map, then rewrite the user to the default path specified in the
  // configuration.
  if (!pathname) {
    return navigateToDefault();
  }
  url.pathname = pathname;

  // Set the search param key to indicate that:
  // 1. The user is part of the A/B test
  // 2. Which test the user was a part of.
  url.searchParams.set(abPageTestSearchParamsKey, flag);

  // set the arrivedVisitorId cookie and return the response.
  const response = NextResponse.rewrite(url);
  response.cookies.set(arrivedVisitorIdCookie, arrivedVisitorId);

  return response;
};
