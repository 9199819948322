import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postOfferingFixedRateInvestments } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../../utils';
import { offeringKeyFn } from '../offerings.keys';

export function usePostOfferingFixedRateInvestments(
  options?: UseAwaitedMutationOptions<typeof postOfferingFixedRateInvestments>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postOfferingFixedRateInvestments,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: offeringKeyFn(args[0].id!),
      });
      options?.onSuccess?.(...args);
    },
  });
}
