import { useQuery } from '@tanstack/react-query';

import { getProperty } from '@arrived/api_v2';
import { PropertyId } from '@arrived/models';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { propertyKeyFn } from './properties.keys';

export function useGetPropertyQuery(
  /**
   * Can be a property ID number or `-1` to indicate that the query should not be enabled.
   */
  propertyId?: PropertyId,
  options?: UseAwaitedQueryOptions<typeof getProperty>,
) {
  return useQuery({
    queryKey: propertyKeyFn(propertyId!),
    queryFn: () => getProperty(propertyId!, { includeOfferingIds: true }),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
    enabled: options?.enabled ?? !!propertyId ?? true,
  });
}
