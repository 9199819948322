import { useQuery } from '@tanstack/react-query';

import { getLeasesByOfferingCid, getLeasesByPropertyId } from '@arrived/api_v2';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { leasesByOfferingCidQueryKeyFn, leasesByPropertyIdQueryKeyFn } from './leases.keys';

export const useGetLeasesByOfferingCidQuery = (
  offeringCid: string,
  options?: UseAwaitedQueryOptions<typeof getLeasesByOfferingCid>,
) => {
  return useQuery({
    queryKey: leasesByOfferingCidQueryKeyFn(offeringCid),
    queryFn: () => getLeasesByOfferingCid(offeringCid),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
};

export const useGetLeasesByPropertyIdQuery = (
  propertyId?: number,
  options?: UseAwaitedQueryOptions<typeof getLeasesByPropertyId>,
) => {
  return useQuery({
    queryKey: leasesByPropertyIdQueryKeyFn(propertyId!),
    queryFn: () => getLeasesByPropertyId(propertyId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && propertyId != null,
  });
};
