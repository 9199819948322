import { useInfiniteQuery } from '@tanstack/react-query';
import _merge from 'lodash/merge';

import { getOfferingsSearch } from '@arrived/api_v2';
import { OfferingSearchQuery } from '@arrived/models';

import { UseAwaitedInfiniteQueryOptions } from '../utils';

import { offeringsInfiniteKeyFn } from './offerings.keys';

export function useOfferingSearchInfiniteQuery(
  query?: OfferingSearchQuery,
  options?: UseAwaitedInfiniteQueryOptions<typeof getOfferingsSearch>,
) {
  return useInfiniteQuery({
    queryKey: offeringsInfiniteKeyFn(query),
    queryFn: ({ pageParam }) => getOfferingsSearch(_merge({}, query, { page: pageParam })),
    initialPageParam: 1,
    staleTime: 0,
    getNextPageParam: ({ pagination }) => {
      // Seems this could be undefined on native when the query is stale
      if (!pagination) {
        return undefined;
      }

      const { page, size, totalResults } = pagination;

      return page * size >= totalResults ? undefined : page + 1;
    },
    ...options,
    // onSuccess: (data) => {
    // TODO: re-enable this we can't cache for now because there are certain attributes that are missing on the
    //  offering model return from OpenSearch that we need elsewhere.
    // We should migrate this due to v5 as well

    // data.data?.forEach((offering) => {
    //   queryClient.setQueryData(offeringKeyFn(offering.id), offering, { updatedAt: Date.now() });
    //   queryClient.setQueryData(offeringKeyFn(offering.shortName), offering, { updatedAt: Date.now() });
    // });
    //   options?.onSuccess?.(data);
    // },
  });
}
