import { AxiosError, InternalAxiosRequestConfig } from 'axios';

/**
 * A mix of AxiosError and the generic ApiError we get back from
 * Abacus. The types are pulled from the `toJSON` method
 * on AxiosError found here: https://github.com/axios/axios/blob/1542719bc7300f885df202942eff986a3d826372/lib/core/AxiosError.js#L35
 *
 * We are adding the `meta` property to the error to allow for
 * additional information to be passed back from the API.
 */
export type ApiError = {
  /**
   * Generic error string, usually the error
   * code that is thrown from the API
   */
  error?: string;

  /**
   * Additional information about the error
   */
  meta?: Map<string, string>;

  // Generic

  /**
   * The error message, can be an alias for `description` but will
   * usually have a general error message while `description` will
   * have a more detailed error message.
   * @alias description
   */
  message?: string;

  /**
   * The error name, usually just `AxiosError`
   */
  name: 'AxiosError' | string;

  /**
   * The error description, can be an alias for `message`
   * @alias message
   */
  description?: string;

  /**
   * The stacktrace associated with the error
   * @private
   */
  stack?: string;

  /**
   * The internal config object used to create the request
   */
  config?: InternalAxiosRequestConfig;

  /**
   * The code returned from the server, this is an alias for `error`
   * @alias error
   */
  code?: string;

  /**
   * The request status code (e.g. 200, 404, etc.)
   */
  status?: number;
} & AxiosError;

export type ApiResponse<T = unknown> = {
  data?: T;
  error?: ApiError;
};

export const GENERIC_ERROR = {
  UNAUTHORIZED: 'Unauthorized',
} as const;

export type GENERIC_ERROR = (typeof GENERIC_ERROR)[keyof typeof GENERIC_ERROR];

/**
 * Mirror of the BE's set of defined error messages.
 *
 * https://github.com/arrivedhomes/abacus/blob/298b5ea25f75688f5b8a13a42fa738bd9ff57bd5/api/src/main/kotlin/com/arrived/api/model/exception/AbacusServerException.kt
 */
export const AbacusErrorCodes = {
  MFA_REQUIRED: 'mfaRequired',
  EMAIL_VERIFICATION_REQUIRED: 'emailVerificationRequired',

  /**
   * If a user is no longer authorized, this is the error
   * returned from Abacus.
   */
  UNAUTHORIZED: 'Unauthorized',
} as const;

export type AbacusErrorCodes = (typeof AbacusErrorCodes)[keyof typeof AbacusErrorCodes];
