import { useMutation, useQueryClient } from '@tanstack/react-query';

import { putAccountBeneficiaries } from '@arrived/api_v2';

import { beneficiariesKeyFn } from '../accounts.keys';

export function usePutAccountsBeneficiariesMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putAccountBeneficiaries,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: beneficiariesKeyFn(variables.accountId) });
    },
  });
}
