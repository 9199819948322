import { useMutation } from '@tanstack/react-query';

import { getAllUsers } from '@arrived/api_v2';
import { UseAwaitedMutationOptions } from '@arrived/queries';

export function useAdminGetAllUsersQuery(options?: UseAwaitedMutationOptions<typeof getAllUsers>) {
  return useMutation({
    mutationFn: getAllUsers,
    ...options,
  });
}
