import { useContext } from 'react';

import {
  BodyText,
  LinearGradient,
  Stack,
  TitleText,
  createStyledContext,
  gradients,
  styled,
  withStaticProperties,
} from '@arrived/bricks';

import { Blocks, BuilderBlock, BuilderDataProps, GenericBuilderIcons, RegisteredComponent } from '../../sdk';
import { ButtonBlockElement } from '../molecules';

const HeaderStyledContext = createStyledContext({
  variant: 'light',
});

export const useHeaderStyledContext = () => useContext(HeaderStyledContext);

const DetailsFrame = styled(Stack, {
  name: 'HeaderDetails',
  context: HeaderStyledContext,

  width: '100%',
  gap: '$4',
  p: '$6',
  flex: 1,
  justifyContent: 'center',

  $gtSm: {
    minHeight: 400,
    gap: '$6',
    p: '$12',
  },
});

const Details = DetailsFrame.styleable((props) => {
  const { children, ...rest } = props;
  const { variant } = useHeaderStyledContext();

  return (
    <Stack overflow="hidden" flex={1} w="100%">
      {variant === 'yin' && <LinearGradient fullscreen w="100%" h="100%" colors={gradients['gradient.neutral.yin']} />}
      {variant === 'light' && (
        <LinearGradient fullscreen w="100%" h="100%" colors={gradients['gradient.neutral.light']} />
      )}
      <DetailsFrame {...rest}>{children}</DetailsFrame>
    </Stack>
  );
});

const DetailsTitle = styled(TitleText, {
  name: 'HeaderDetailsTitle',
  context: HeaderStyledContext,

  token: 'title.display.small',

  $gtSm: {
    token: 'title.display.medium',
  },

  variants: {
    variant: {
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
  },
});

const DetailsContent = styled(BodyText, {
  name: 'HeaderDetailsTitle',
  context: HeaderStyledContext,

  token: 'body.default.large',

  variants: {
    variant: {
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
  },
});

const Media = styled(Stack, {
  name: 'HeaderMedia',
  context: HeaderStyledContext,

  flex: 1,
  width: '100%',

  p: '$6',
  height: 280,

  $gtSm: {
    p: '$8',
    height: 'auto',
  },
});

const HeaderFrame = styled(Stack, {
  tag: 'header',
  name: 'HeaderFrame',
  context: HeaderStyledContext,

  flex: 1,

  row: false,

  $gtSm: {
    row: true,
  },
});

const HeaderComponent = withStaticProperties(HeaderFrame, {
  Details: withStaticProperties(Details, {
    Title: DetailsTitle,
    Content: DetailsContent,
  }),
  Media,
});

type BuilderHeaderProps = {
  variants: (typeof HeaderStyledContext)['context'];
  content: {
    heading?: string;
    details?: string;
    hideCta?: boolean;
    cta?: BuilderBlock[];
  };
  media: {
    title?: string;
    image?: BuilderBlock[];
  };
} & BuilderDataProps;

export const Header = ({ variants, content, media, builderContext }: BuilderHeaderProps) => {
  return (
    <HeaderStyledContext.Provider {...variants}>
      <HeaderComponent>
        <HeaderComponent.Details>
          {Boolean(content.heading) && <HeaderComponent.Details.Title>{content.heading}</HeaderComponent.Details.Title>}

          {Boolean(content.details) && (
            <HeaderComponent.Details.Content>{content.details}</HeaderComponent.Details.Content>
          )}

          {!content.hideCta && (
            <Stack $gtSm={{ alignSelf: 'baseline' }} alignSelf="stretch">
              <Blocks path="component.options.content.cta" blocks={content.cta} context={builderContext} />
            </Stack>
          )}
        </HeaderComponent.Details>

        <HeaderComponent.Media>
          {/* We just make this a background image, can be customized within builder */}
          {media.image && (
            <Stack fullscreen w="100%" h="100%">
              <Blocks path="component.options.media.image" blocks={media.image} context={builderContext} />
            </Stack>
          )}

          <Stack flex={1}>
            <Stack marginTop="auto">
              {Boolean(media.title) && (
                <Stack flex={1} alignItems="flex-start">
                  <TitleText color="white" token="title.heading.large">
                    {media.title}
                  </TitleText>
                </Stack>
              )}
            </Stack>
          </Stack>
        </HeaderComponent.Media>
      </HeaderComponent>
    </HeaderStyledContext.Provider>
  );
};

Header.registerComponent = {
  component: Header,
  name: 'Bricks:Header',
  description: 'A general header component.',
  image: GenericBuilderIcons.Hero,
  canHaveChildren: false,
  shouldReceiveBuilderProps: {
    builderContext: true,
    builderBlock: true,
    builderComponents: true,
  },
  docsLink: 'https://www.figma.com/file/pOLoKPNXzfRiDTqAeP9YBr/%F0%9F%A7%B1-Builder.io-Blocks?node-id=6201%3A5595',
  inputs: [
    {
      name: 'variants',
      friendlyName: 'Variants',
      type: 'object',
      defaultValue: {
        variant: 'light',
      },
      subFields: [
        {
          name: 'variant',
          type: 'enum',
          defaultValue: 'light',
          enum: [
            {
              label: 'Light',
              value: 'light',
            },
            {
              label: 'Yin',
              value: 'yin',
            },
          ],
        },
      ],
    },

    {
      name: 'content',
      friendlyName: 'Content',
      type: 'object',
      defaultValue: {
        heading: 'Heading',
        details: 'Details',
        cta: [ButtonBlockElement()],
        hideCta: false,
      },
      subFields: [
        {
          name: 'heading',
          type: 'string',
        },
        {
          name: 'details',
          type: 'string',
        },
        {
          name: 'hideCta',
          type: 'boolean',
          friendlyName: 'Hide CTA',
          helperText: 'Hide the CTA button',
        },
        {
          name: 'cta',
          friendlyName: 'Call to action',
          type: 'uiBlocks',
        },
      ],
    },

    {
      name: 'media',
      friendlyName: 'Media',
      type: 'object',
      defaultValue: {
        // TODO: This needs to be a carousel OR a single image, we don't have a carousel component in builder yet
        image: [
          {
            '@type': '@builder.io/sdk:Element',
            component: {
              name: 'Image',
              options: {
                aspectRatio: 1,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                image:
                  'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F8db6e671377c4040af9e9d92b4f7b260',
              },
            },
          },
        ],
      },
      subFields: [
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'image',
          type: 'uiBlocks',
        },
      ],
    },
  ],
} satisfies RegisteredComponent;
