import { FormattedMessage } from 'react-intl';

import { OfferingDocumentType } from '@arrived/models';

export const OfferingDocumentTypeLabels = {
  [OfferingDocumentType.OFFERING_CIRCULAR]: <FormattedMessage id="property-details.documents.offering-circular" />,
  [OfferingDocumentType.OFFERING_CIRCULAR_SUPPLEMENT]: (
    <FormattedMessage id="property-details.documents.offering-circular-supplement" />
  ),
  [OfferingDocumentType.RISK_FACTORS]: <FormattedMessage id="property-details.documents.risk-factors" />,
  [OfferingDocumentType.SERIES_OVERVIEW]: <FormattedMessage id="property-details.documents.series-overview" />,
  [OfferingDocumentType.USE_OF_PROCEEDS]: <FormattedMessage id="property-details.documents.use-of-proceeds" />,
  [OfferingDocumentType.FORM_1K]: <FormattedMessage id="property-details.documents.form-1k" />,
  [OfferingDocumentType.FORM_1SA]: <FormattedMessage id="property-details.documents.form-1sa" />,
  [OfferingDocumentType.PRIVATE_PLACEMENT_MEMORANDUM]: (
    <FormattedMessage id="property-details.documents.private-placement-memorandum" />
  ),
  [OfferingDocumentType.PURCHASE_AGREEMENT]: <FormattedMessage id="property-details.documents.purchase-agreement" />,
  [OfferingDocumentType.PURCHASE_AGREEMENT_SUPPLEMENT]: (
    <FormattedMessage id="property-details.documents.purchase-agreement-supplement" />
  ),
  [OfferingDocumentType.OFFERING_MEMORANDUM_SUPPLEMENT]: (
    <FormattedMessage id="property-details.documents.offering-memorandum-supplement" />
  ),
} as const;
