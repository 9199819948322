export const space = {
  0.25: 1,
  0.5: 2,
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  8: 32,
  10: 40,
  12: 48,
  20: 80,
  30: 120,

  /**
   * @default
   */
  true: 12,
} as const;
