import Image from '@mui/icons-material/Image';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import { SvgIconProps } from '@mui/material';

import { MediaViewerFileType } from '@arrived/models';

export interface DocumentTypeIconProps extends SvgIconProps {
  fileType: MediaViewerFileType;
}

export const DocumentTypeIcon = ({ fileType, ...rest }: DocumentTypeIconProps) => {
  switch (fileType) {
    case MediaViewerFileType.IMAGE:
      return <Image {...rest} />;
    case MediaViewerFileType.PDF:
      return <InsertDriveFile {...rest} />;
  }
};
