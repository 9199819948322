import type React from 'react';

// Borrowed from https://larsmagnus.co/blog/how-to-test-for-accessibility-with-axe-core-in-next-js-and-react
export const accessibilityReport = (App: typeof React, config?: Record<string, unknown>) => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    const axe = require('@axe-core/react');
    const ReactDOM = require('react-dom');

    axe.default(App, ReactDOM, 1000, config);
  }
};
