import * as yup from 'yup';

import { LEAD_REFERRAL_OPTIONS } from '@arrived/common';
import { PlannedInvestmentAmount } from '@arrived/models';

import { sanitizeQuotes } from '../../utils';

export const AccountsBasicInfoSchema = yup
  .object({
    firstName: yup.string().trim().max(128).transform(sanitizeQuotes).required('user.firstName.required'),
    lastName: yup.string().trim().max(128).transform(sanitizeQuotes).required('user.lastName.required'),

    /**
     * The `how did you hear about us?` field
     */
    leadReferral: yup
      .string()
      .oneOf(LEAD_REFERRAL_OPTIONS.map((option) => option.value))
      .trim(),

    /**
     * The `other` field for the `how did you hear about us?` field
     */
    leadReferralOther: yup.string().when('leadReferral', {
      is: 'other',
      then: (schema) => schema.required('Please provide a value for "Other"'),
    }),
    plannedInvestmentAmount: yup
      .mixed<PlannedInvestmentAmount>()
      .oneOf(Object.values(PlannedInvestmentAmount))
      .required('user.plannedInvestmentAmount.required'),
    agreedToTosPrivacy: yup.bool().oneOf([true], 'user.tosPrivacy.required'),
    referralCode: yup
      .string()
      .trim()
      .when('$referralCodeEnabled', {
        is: true,
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.strip(),
      }),
  })
  .defined();

export type AccountsBasicInfoSchema = yup.InferType<typeof AccountsBasicInfoSchema>;
