import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postNewProperty } from '@arrived/api_v2';
import { Property } from '@arrived/models';
import { UseAwaitedMutationOptions, propertiesKey } from '@arrived/queries';

import { propertyKeyFn } from './properties.keys';

export function usePostPropertyMutation(options?: UseAwaitedMutationOptions<typeof postNewProperty>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postNewProperty,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<Property[] | undefined>(propertiesKey, (currentProperties) => {
        const propertiesCopy = ([] as Property[]).concat(currentProperties ?? []);
        propertiesCopy.push(data);
        return propertiesCopy;
      });
      queryClient.setQueryData(propertyKeyFn(data.id), data);

      queryClient.invalidateQueries({ queryKey: propertiesKey });
      queryClient.invalidateQueries({ queryKey: propertyKeyFn(data.id) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
