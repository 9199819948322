import { DetailedHTMLProps, InputHTMLAttributes, useCallback, useRef } from 'react';

import { Button, ButtonProps } from '@mui/material';

export type FileUploadButtonProps = Omit<ButtonProps, 'onClick'> & {
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
};

export const FileUploadButton = ({ children, inputProps, ...rest }: FileUploadButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnClick = useCallback(() => inputRef?.current?.click(), []);

  return (
    <>
      <input hidden {...inputProps} ref={inputRef} type="file" />
      <Button onClick={handleOnClick} {...rest}>
        {children}
      </Button>
    </>
  );
};
