import { useMemo } from 'react';

import { AccountId, TransactionStatus, UserTradesStatus } from '@arrived/models';
import { useAccountTradesQuery } from '@arrived/queries';

export const useUserTradeStatus = (accountId: AccountId) => {
  const createTradesState = useAccountTradesQuery(accountId, { size: 1, status: TransactionStatus.CREATED });
  const settledTradesState = useAccountTradesQuery(
    accountId,
    { size: 1, status: TransactionStatus.SETTLED },
    { enabled: (createTradesState.isSuccess && createTradesState.data.length === 0) || createTradesState.isError },
  );
  const fundedTradesState = useAccountTradesQuery(
    accountId,
    { size: 1, status: TransactionStatus.FUNDED },
    { enabled: (settledTradesState.isSuccess && settledTradesState.data.length === 0) || settledTradesState.isError },
  );

  return useMemo(() => {
    if ((createTradesState.data?.length ?? 0) > 0) {
      return UserTradesStatus.PENDING_TRADES;
    } else if ((settledTradesState.data?.length ?? 0) > 0 || (fundedTradesState.data?.length ?? 0) > 0) {
      return UserTradesStatus.ONLY_SETTLED_TRADES;
    } else {
      return UserTradesStatus.NO_TRADES;
    }
  }, [createTradesState, settledTradesState, fundedTradesState]);
};
