import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getReferralStatuses } from '@arrived/api_v2';
import { UserId } from '@arrived/models';
import { MAX_STALE_TIME } from '@arrived/queries';

import { adminReferralStatusesKeyFn } from './referrals.keys';

export function useAdminGetReferralStatusesQuery(userId: UserId) {
  const queryClient = useQueryClient();

  const referralStatusState = useQuery({
    queryKey: adminReferralStatusesKeyFn(userId),
    queryFn: () => getReferralStatuses(),
    staleTime: MAX_STALE_TIME,
    enabled: userId != null,
  });

  useEffect(() => {
    if (referralStatusState.data) {
      queryClient.setQueryData(adminReferralStatusesKeyFn(userId), referralStatusState.data);
    }
  }, [referralStatusState.data]);

  return referralStatusState;
}
