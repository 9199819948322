import { cashAccountApi } from '@arrived/api_v2';

import { CreateExternalTransferRequest, ExternalTransfer } from '../types/cash-accounts';

export const postExternalTransfer = async (body: CreateExternalTransferRequest) => {
  const response = await cashAccountApi.post<ExternalTransfer>(`/transfers`, body);

  if (!response?.data) {
    console.log('Error posting external transfer, no data returned. This is most likely because MFA is required.', {
      response,
    });
  }

  // If MFA is required, we should throw a proper error
  return response?.data;
};
