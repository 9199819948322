import { Stack, styled } from '@arrived/bricks';

import { VIDEO_FRAME_NAME } from './constants';

export const VideoFrame = styled(Stack, {
  name: VIDEO_FRAME_NAME,

  br: '$2',
  row: true,
  px: '$4',
  overflow: 'hidden',
  alignItems: 'center',
  role: 'button',
  tag: 'button',
  gap: '$4',
  borderWidth: '$0.5',
  borderColor: 'transparent',
  py: 0,
  cursor: 'pointer',
  style: {
    textAlign: 'left',
    textDecoration: 'none',
  },
  focusStyle: {
    borderColor: '$interactive.primary.focus',
  },
  hoverStyle: {
    borderColor: '$interactive.primary.hovered',
  },
});
