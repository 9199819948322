import React, { ReactNode } from 'react';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import { CONFIG } from '@arrived/config';

interface GoogleTagManagerProviderProps {
  children: ReactNode;
}

export const GoogleTagManagerProvider = ({ children }: GoogleTagManagerProviderProps) => {
  const googleTagManagerParams = {
    id: CONFIG.googleTagManagerId,
  };

  return <GTMProvider state={googleTagManagerParams}>{children}</GTMProvider>;
};
