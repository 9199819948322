import { Offering } from '@arrived/models';

export const isRemainingSharesLessThanMinimumActionableShares = (offering: Offering): boolean => {
  const { totalSharesTransacted, totalShares, sharePrice, minTransactionAmount } = offering;

  const remainingSharesCount = Math.max(0, totalShares - totalSharesTransacted);
  const minActionableSharesCount = Math.floor(minTransactionAmount / sharePrice);

  return remainingSharesCount < minActionableSharesCount;
};
