export default {
  'invest.markets': 'Markets',
  'invest.properties': 'Properties',

  'invest.sfr-fund': 'Single Family Residential Fund',
  'invest.sfr': 'Single Family Residential',
  'invest.str': 'Vacation Rental',
  'invest.short-term-note': 'Short Term Note',
  'invest.real-estate-debt': 'Real Estate Debt',
  'invest.private-credit-fund': 'Real Estate Debt Investment Fund',
  'invest.fund': 'Fund',

  'invest.available': 'Available',
  'invest.funded': 'Funded',

  'invest.yield': 'Yield',
  'invest.months': 'Months',
  'invest.units.shares': 'Shares',
  'invest.units.notes': 'Notes',
  'invest.units.loans': 'Loans',

  'invest.more-shares-available': 'More Shares Available',
  'invest.max-monthly-shares': `You've reached the {amount} limit for {month}`,
  'invest.max-monthly-shares.available': '{amount} remaining to invest for {month}',

  'invest.own-x-shares': 'Own {count} Shares',

  'invest.reg-d-confidential':
    '<strong>Confidential:</strong> Due to SEC regulations on the marketing of securities, this Arrived Advanced offering for accredited investors can only be shared with professional advisors.',
  'invest.advanced-investors': 'Arrived Advanced Investors',
};
