import {
  Children,
  ElementType,
  Fragment,
  ReactNode,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  GetProps,
  TamaguiElement,
  createStyledContext,
  styled,
  useProps,
  useTheme,
  withStaticProperties,
} from '@tamagui/core';

import {
  FloatingFocusManager,
  FloatingList,
  FloatingOverlay,
  FloatingPortal,
  FloatingTree,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingParentNodeId,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';

import { zIndex } from '../../tokens/zIndex';
import { BlurView } from '../blur-view';
import { Divider } from '../divider';
import { Stack } from '../layout';
import { UtilityText } from '../text';

const DROPDOWN_ITEM_NAME = 'DropdownItem';
const DROPDOWN_ITEM_TEXT_NAME = `${DROPDOWN_ITEM_NAME}Text`;

const DropdownItemStyledContext = createStyledContext({ active: false });

const DropdownStyledItem = styled(Stack, {
  name: DROPDOWN_ITEM_NAME,
  context: DropdownItemStyledContext,
  group: 'DropdownItem',
  animation: 'quick',
  row: true,
  gap: '$2',
  px: '$4',
  py: '$3',
  outlineWidth: 0,
  style: {
    textAlign: 'left',
  },
  variants: {
    active: {
      true: {
        bg: '$onSurface.primary.decorativeAlt',
      },
      false: {
        backgroundColor: '$transparent',
        hoverStyle: {
          bg: '$interactive.primary.rested',
        },
      },
    },
    disableHoverEffect: {
      true: {
        hoverStyle: {
          bg: '$transparent',
        },
      },
    },
  } as const,
});

const DropdownText = styled(UtilityText, {
  name: DROPDOWN_ITEM_TEXT_NAME,
  context: DropdownItemStyledContext,
  token: 'utility.label.small.alt',
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  color: '$onSurface.neutral.defaultInverted',
  variants: {
    active: {
      true: {
        color: '$onSurface.neutral.default',
        '$group-DropdownItem-hover': {
          color: '$onSurface.neutral.default',
        },
      },
      false: {
        '$group-DropdownItem-hover': {
          color: '$onSurface.neutral.defaultInverted',
        },
      },
    },
  } as const,
});

function useDropdown() {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const elementsRef = useRef<HTMLElement[]>([]);

  const data = useFloating({
    placement: 'bottom-start',
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const context = data.context;

  const click = useClick(context, {
    event: 'mousedown',
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: true,
    focusItemOnHover: false,
  });

  const interactions = useInteractions([listNavigation, click, dismiss, role]);

  const transition = useTransitionStyles(context, {
    // Configure both open and close durations:
    duration: 200,
  });

  return useMemo(
    () => ({
      open: transition.isMounted,
      transition,
      setOpen,
      elementsRef,
      activeIndex,
      ...interactions,
      ...data,
    }),
    [transition, setOpen, activeIndex, elementsRef, interactions, data],
  );
}

type ContextType = ReturnType<typeof useDropdown> | null;

const DropdownContext = createContext<ContextType>(null);

export const useDropdownContext = () => useContext(DropdownContext);

const DropdownTrigger = forwardRef<TamaguiElement, React.HTMLProps<HTMLElement> & { asChild?: boolean }>(
  ({ children, asChild, ...props }, propRef) => {
    const context = useDropdownContext();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO this isn't typed correctly and we should fix it
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context?.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context?.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context?.open ? 'open' : 'closed',
        }),
      );
    }

    // TS mapping here is loose; we are assuming this Stack is a div on web
    return (
      <Stack
        ref={ref}
        tabIndex={0}
        data-state={context?.open ? 'open' : 'closed'}
        {...context?.getReferenceProps(props)}
      >
        {children}
      </Stack>
    );
  },
);

interface MenuItemProps {
  active?: boolean;
  disabled?: boolean;
  tag?: ElementType | string;
}

const DropdownItem = forwardRef<TamaguiElement, MenuItemProps & Omit<GetProps<typeof DropdownStyledItem>, 'tag'>>(
  ({ disabled, children, tag: Tag = 'button', ...rest }, forwardedRef) => {
    const props = useProps(rest);
    const dropdownContext = useDropdownContext();
    const item = useListItem();
    const isActive = item.index === dropdownContext?.activeIndex;
    const theme = useTheme();

    const itemProps = dropdownContext?.getItemProps({
      onClick: () => {
        dropdownContext.setOpen(false);
      },
    });

    return (
      <DropdownStyledItem
        {...props}
        active={Boolean(props.active)}
        disableHoverEffect={disabled}
        focusStyle={{
          // @ts-expect-error `boxShadow` seems to work on web just fine
          boxShadow: `0 0 0 2px ${theme['onSurface.primary.decorative'].variable} inset`,
        }}
        asChild
      >
        <Tag
          tabIndex={0}
          ref={useMergeRefs([item.ref, forwardedRef])}
          className={'is_DropdownStyledItem ' + (isActive ? 'active' : '')}
          role="menuitem"
          {...itemProps}
        >
          {children}
        </Tag>
      </DropdownStyledItem>
    );
  },
);

const DropdownContent = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ children, ...rest }, propRef) => {
    const context = useDropdownContext();
    const ref = useMergeRefs([propRef].concat(context ? [context?.refs.setFloating] : []));

    if (!context || !context.transition.isMounted) return null;

    return (
      <FloatingPortal>
        <Stack
          ref={ref}
          style={{
            ...context.floatingStyles,
            ...context.transition.styles,
            zIndex: zIndex.tooltip,
          }}
          {...context.getFloatingProps(rest)}
          minWidth={250}
          borderRadius="$2"
          overflow="hidden"
          bg="$onSurface.neutral.tooltip"
        >
          <BlurView intensity={10} tint="dark" borderRadius="$2" position="absolute" top={0} zi={-1} />
          <FloatingFocusManager context={context.context}>
            <FloatingList elementsRef={context.elementsRef}>
              {Children.map(children, (child, idx) => (
                <Fragment key={idx}>
                  {idx > 0 && <Divider solid />}
                  {child}
                </Fragment>
              ))}
            </FloatingList>
          </FloatingFocusManager>
        </Stack>
        <FloatingOverlay lockScroll>
          <Stack />
        </FloatingOverlay>
      </FloatingPortal>
    );
  },
);

const DropdownWrapper = ({ children }: { children: ReactNode }) => {
  const parentId = useFloatingParentNodeId();
  const dropdown = useDropdown();

  if (parentId === null) {
    return (
      <FloatingTree>
        <DropdownContext.Provider value={dropdown}>{children}</DropdownContext.Provider>
      </FloatingTree>
    );
  }

  return <DropdownContext.Provider value={dropdown}>{children}</DropdownContext.Provider>;
};

export const Dropdown = withStaticProperties(DropdownWrapper, {
  Item: withStaticProperties(DropdownItem, { Text: DropdownText }),
  Trigger: DropdownTrigger,
  Content: DropdownContent,
  Text: DropdownText,
});
