import { FormattedMessage } from 'react-intl';

import { BodyText, Divider, Image, Loading, Sheet, Stack, UtilityText, styled } from '@arrived/bricks';
import { Constants, getCDNOptimizedImage } from '@arrived/common';
import { useInvestmentProductType } from '@arrived/hooks';
import { InvestmentProductType, Offering, OfferingIdOrShortName } from '@arrived/models';

import { useGetSortedOfferingHistoryEvents } from './hooks';
import { OfferingHistoryEvent } from './OfferingHistoryEvent';

export interface OfferingHistoryProps {
  offeringIdOrShortname?: OfferingIdOrShortName;
  open?: boolean;
  onClose?: () => void;
  backPath?: string;
}

export const OfferingHistoryBodyText = styled(BodyText, {
  name: 'OfferingHistoryBodyText',
  token: 'body.compact.small',
  pt: '$3',
  textAlign: 'center',
  color: '$onSurface.neutral.defaultAlt',
});

export const OfferingHistoryEventWrapper = styled(Stack, {
  name: 'OfferingHistoryEventWrapper',

  hoverStyle: {
    backgroundColor: '$onSurface.neutral.outlineAlt',
  },
});
export const OfferingHistoryEventContainer = styled(Stack, {
  name: 'OfferingHistoryEventContainer',
  px: '$2',

  variants: {
    first: {
      true: {},
    },
  },
});

export const OfferingHistoryFooter = styled(Sheet.Footer, {
  name: 'OfferingHistoryFooter',
  bg: '$onSurface.neutral.defaultInverted',
});

export const isIpoProperty = (investmentProductType?: InvestmentProductType) => {
  return (
    investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO ||
    investmentProductType === InvestmentProductType.VACATION_RENTAL_IPO
  );
};

export const OfferingHistoryEmptyState = ({
  investmentProductType,
}: {
  investmentProductType?: InvestmentProductType;
}) => (
  <Stack flex={1} justifyContent="center" alignItems="center">
    <Stack h={140} w={140}>
      <Image
        source={{
          uri: getCDNOptimizedImage({
            imgUrl: `${Constants.awsCdnUrl}/images/pdp/debt-repayment.png`,
            ratio: '1:1',
            width: '280',
          }),
        }}
        width={140}
        height={140}
      />
    </Stack>
    <BodyText token="body.compact.small">
      {isIpoProperty(investmentProductType) ? (
        <FormattedMessage id="property-history.no-events" />
      ) : (
        <FormattedMessage id="offering-history.no-events" />
      )}
    </BodyText>
  </Stack>
);

export const OfferingHistoryEvents = ({ offering }: { offering: Offering }) => {
  const { sortedEvents, isLoading } = useGetSortedOfferingHistoryEvents(offering);
  const investmentProductType = useInvestmentProductType(offering);

  return (
    <>
      {isLoading ? (
        <Stack flex={1} p={0} justifyContent="center" alignItems="center">
          <Loading centered w="100%" h="100%" flexGrow={1}>
            <Loading.Indicator variant="colored" />
          </Loading>
        </Stack>
      ) : (
        <>
          {sortedEvents?.length > 0 ? (
            sortedEvents?.map((events, i) => {
              return (
                <Stack key={i}>
                  <Divider.Time mb={i === 0 ? '$5' : 0}>
                    <UtilityText token="utility.label.xsmall" color="$onSurface.neutral.muted">
                      {new Date(events[0]?.displayDate).getFullYear()}
                    </UtilityText>
                  </Divider.Time>
                  {events?.map((event, idx) => {
                    return (
                      <OfferingHistoryEventWrapper key={event.cid} px="$6">
                        <OfferingHistoryEventContainer>
                          <OfferingHistoryEvent event={event} key={i} emphasized={idx === 0 && i === 0} />
                        </OfferingHistoryEventContainer>
                      </OfferingHistoryEventWrapper>
                    );
                  })}
                </Stack>
              );
            })
          ) : (
            <>{investmentProductType && <OfferingHistoryEmptyState investmentProductType={investmentProductType} />}</>
          )}
        </>
      )}
    </>
  );
};
