import { useQuery } from '@tanstack/react-query';

import { getOfferingDividendHistory } from '@arrived/api_v2';
import { OfferingId } from '@arrived/models';
import { MAX_STALE_TIME, UseAwaitedQueryOptions, offeringDividendHistoryKeyFn } from '@arrived/queries';

export const useGetOfferingDividendHistoryQuery = (
  offeringId: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getOfferingDividendHistory>,
) => {
  return useQuery({
    queryKey: offeringDividendHistoryKeyFn(offeringId),
    queryFn: () => getOfferingDividendHistory(offeringId),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && offeringId != null,
  });
};
