import { TextStyle } from 'react-native';

import { FontWeightValues, Variable, createFont, getVariableValue, isWeb } from '@tamagui/core';

import defaultFontSizes from './font-size';

const defaultLetterSpacingSizes = {
  '0': 0,
  '0.02': 0.02 * 16, // Handle em
  '0.04': 0.04 * 16,
  '0.8': 0.8,
} as const;

/**
 * Weights need to match size token keys
 * https://tamagui.dev/docs/core/configuration#font-tokens
 */
const calibreFontWeights = {
  0: '300',
  1: '400',
  2: '500',
  4: '600',
  6: '700',
  8: '400',
  true: '400',
} as const;

/**
 * @native
 */
const face = {
  300: { normal: 'Calibre-Light' },
  400: { normal: 'Calibre-Regular' },
  500: { normal: 'Calibre-Medium' },
  600: { normal: 'Calibre-Semibold' },
  700: { normal: 'Calibre-Bold' },
} as const;

/**
 * This overrides the original GenericFont type because we want to customize how we handle font weights
 * and potentially other items.
 */
type GenericFont<
  Key extends number | string = number | string,
  LetterSpacingKey extends number | string = number | string,
> = {
  size: { [key in Key]: number | Variable };
  lineHeight: { [key in Key]: number | Variable };
  letterSpacing: { [key in LetterSpacingKey]: number | Variable };

  // Use our custom font weights
  weight: Partial<{ [key in keyof typeof calibreFontWeights]: number | string | Variable }>;

  family: string | Variable;
  style?: Partial<{ [key in Key]: TextStyle['fontStyle'] | Variable }>;
  transform?: Partial<{ [key in Key]: TextStyle['textTransform'] | Variable }>;
  color?: Partial<{ [key in Key]: string | Variable }>;
  // for native use only, lets you map to alternative fonts
  face?: Partial<{
    [key in FontWeightValues]: { normal?: string; italic?: string };
  }>;
};

export const createCalibreFont = <
  Font extends GenericFont = GenericFont<keyof typeof defaultFontSizes, keyof typeof defaultLetterSpacingSizes>,
>(
  font: {
    [Key in keyof Partial<Font>]?: Partial<Font[Key]>;
  } = {},
  {
    sizeSize = (size) => Math.round(size * 1),
    sizeLineHeight = (size) => size, //Math.round(size * 1.1 + (size > 20 ? 10 : 10)),
  }: {
    sizeLineHeight?: (fontSize: number) => number;
    sizeSize?: (size: number) => number;
  } = {},
): Font => {
  // merge to allow individual overrides
  const size = Object.fromEntries(
    Object.entries({
      ...defaultFontSizes,
      ...font.size,
    }).map(([k, v]) => [k, sizeSize(+v)]),
  );

  return createFont<Font>({
    family: isWeb
      ? 'Calibre, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
      : 'Calibre',

    lineHeight: Object.fromEntries(Object.entries(size).map(([k, v]) => [k, sizeLineHeight(getVariableValue(v))])),

    weight: calibreFontWeights,

    // TODO: See how these translate
    letterSpacing: defaultLetterSpacingSizes,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- will fix later
    ...(font as any),

    size,

    face,
  });
};
