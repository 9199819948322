// arrived models
import { ExternalAccountVerificationStatus } from './externalAccount';

export interface PlaidCreateTokenLinkRequest {
  /**
   * Enables auth type select in Plaid which allows the user to choose if they want to link their bank via credentials
   * or ACH numbers.
   */
  authTypeSelect?: boolean;
  /**
   * Enables the same day deposit flow through linking with this token.
   */
  sameDayDeposit?: boolean;
  userId: number;
}

export interface PlaidCreateTokenLinkResponse {
  linkToken: string;
}

export interface PlaidPublicTokenExchangeRequest {
  publicToken: string;
}

export interface PlaidPublicTokenExchangeResponse extends PlaidCreateTokenLinkResponse {
  accessToken: string;
}

// plaid models

export interface PlaidSuccessResponse {
  institution: Institution;
  account: PlaidAccount;
  // account_id: string; // contained within account object, ignore
  // accounts: PlaidAccount[]; // contained within account object, ignore
  link_session_id: string;
  public_token: string;
}

export interface PlaidAccount {
  id: string;
  name: string;
  type: PlaidAccountType;
  subtype: PlaidAccountSubType;
  mask: string;
  verification_status: string | null;
}

/**
 * These verification statuses come from Plaid and are parsed into Arrived's language for verification status.
 * See https://plaid.com/docs/auth/coverage/same-day/#the-same-day-micro-deposit-flow.
 */
export const parsePlaidVerificationStatus = (verificationStatus?: string) => {
  if (!verificationStatus) {
    return ExternalAccountVerificationStatus.VERIFIED;
  }

  const result = {
    pending_manual_verification: ExternalAccountVerificationStatus.NOT_VERIFIED,
    manually_verified: ExternalAccountVerificationStatus.VERIFIED,
  }[verificationStatus];

  if (!result) {
    throw new Error(`Unknown verification status ${verificationStatus}`);
  }

  return result;
};

interface Institution {
  name: string;
  institution_id: string;
}

type PlaidAccountType = 'investment' | 'credit' | 'depository' | 'loan' | 'other';

// lots-o-options, https://plaid.com/docs/#account-subtypes-investment
type PlaidAccountSubType = 'checking' | 'savings' | string;
