import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Address } from '@arrived/models';

import { FormNumberField } from './FormNumberField';
import { FormTextField } from './FormTextField';

export interface AddressFormFieldsNewProps {
  // Used when the address is part of a nested form, all fields will be prefixed with this value
  // so that it populates the correct part of the form object
  // TODO: Is there a better way to do this? Ideally we'd like to make this form re-usable in lots of places but some
  //  limitations at the moment are that.
  //  1. This is difficult to style
  //  2. It's not easy to customize if necessary
  //  3. The internal functionality is limited due to the nested nature of this in a form
  //  Some thoughts on how to resolve
  //  - Make each field a separate component that will just be placed where/when that field is needed
  //  - Enhance this component itself so that it can be customized more (classNames, additional props, etc.)
  fieldPrefix?: string;
}

export const AddressFormFieldsNew = ({ fieldPrefix }: AddressFormFieldsNewProps) => {
  const { formState, control } = useFormContext();

  const { errors } = formState;

  // @ts-expect-error -- will be deprecated when moving over to bricks
  const getError = (name: keyof Address) => (fieldPrefix ? errors[fieldPrefix]?.[name] : errors[name]);
  const getName = (name: keyof Address) => (fieldPrefix ? `${fieldPrefix}.${name}` : name);

  return (
    <>
      <FormTextField
        error={Boolean(getError('street'))}
        controllerProps={{ name: getName('street'), control }}
        label={<FormattedMessage id="street" defaultMessage="Street" />}
      />
      <FormTextField
        error={Boolean(getError('street2'))}
        controllerProps={{ name: getName('street2'), control }}
        label={<FormattedMessage id="street-continued" defaultMessage="Street (Cont.)" />}
      />
      <FormTextField
        error={Boolean(getError('city'))}
        controllerProps={{ name: getName('city'), control }}
        label={<FormattedMessage id="city" defaultMessage="City" />}
      />
      <FormTextField
        error={Boolean(getError('zipCode'))}
        controllerProps={{ name: getName('zipCode'), control }}
        label={<FormattedMessage id="zip-code" defaultMessage="Zip Code" />}
      />
      <FormTextField
        error={Boolean(getError('province'))}
        controllerProps={{ name: getName('province'), control }}
        label={<FormattedMessage id="state" defaultMessage="State" />}
      />
      <FormTextField
        error={Boolean(getError('country'))}
        controllerProps={{ name: getName('country'), control }}
        label={<FormattedMessage id="country" defaultMessage="Country" />}
      />
      <FormNumberField
        error={Boolean(getError('lat'))}
        controllerProps={{ name: getName('lat'), control }}
        label={<FormattedMessage id="latitude" defaultMessage="Latitude" />}
      />
      <FormNumberField
        error={Boolean(getError('lon'))}
        controllerProps={{ name: getName('lon'), control }}
        label={<FormattedMessage id="longitude" defaultMessage="Longitude" />}
      />
    </>
  );
};
