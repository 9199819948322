import { OfferingStatus } from '@arrived/models';

/**
 * Provided an OfferingStatus, returns whether the AirDNA charts should be shown on the Property Details Page.
 *
 * Currently, we want to show these if the Offering is in a COMING_SOON/PRE_LAUNCH or transactable state.
 */
export const shouldShowAirDnaHistoricalCharts = (offeringStatus: OfferingStatus) => {
  const allowedStatuses: OfferingStatus[] = [
    OfferingStatus.TRANSACT_ALL,
    OfferingStatus.TRANSACT_EARLY_ACCESS,
    OfferingStatus.COMING_SOON,
    OfferingStatus.PRE_LAUNCH,
  ];

  return allowedStatuses.includes(offeringStatus);
};
