import { PlannedInvestmentAmount } from '@arrived/models';

export const PLANNED_INVESTMENT_AMOUNT_OPTIONS = [
  {
    label: { id: 'planned-investment-amount-lowest', defaultMessage: '$100-$2,000' },
    value: PlannedInvestmentAmount.R100_2000,
  },
  {
    label: { id: 'planned-investment-amount-low', defaultMessage: '$2,000-$5,000' },
    value: PlannedInvestmentAmount.R2000_5000,
  },
  {
    label: { id: 'planned-investment-amount-middle', defaultMessage: '$5,000-$25,000' },
    value: PlannedInvestmentAmount.R5000_25000,
  },
  {
    label: { id: 'planned-investment-amount-high', defaultMessage: '$25,000-$100,000' },
    value: PlannedInvestmentAmount.R25000_100000,
  },
  {
    label: { id: 'planned-investment-amount-highest', defaultMessage: '$100,000+' },
    value: PlannedInvestmentAmount.R100000_OR_MORE,
  },
] as const;
