import { Backdrop, BackdropProps } from '@mui/material';
import { alpha } from '@mui/system';

import { Loading } from '../Loading';

export const LoadingBackdrop = ({ sx, children, ...rest }: BackdropProps) => (
  <Backdrop sx={{ backgroundColor: ({ palette }) => alpha(palette.lightened.bare, 0.8), ...sx }} {...rest}>
    <Loading sx={{ '& .img': { backgroundColor: ({ palette }) => palette.lightened.bare, borderRadius: '50%' } }} />
    {children}
  </Backdrop>
);
