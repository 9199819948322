import { ReactElement, ReactNode } from 'react';

import { ButtonProps } from '@mui/material';

export enum NAV_TYPE {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
  BUTTON = 'BUTTON',
  COMPONENT = 'COMPONENT',
}

export interface NavItemType {
  type?: NAV_TYPE;
  path?: string;
  text: string | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- will be deprecated
  buttonProps?: ButtonProps<any>;
  isActive?: boolean;
  isExternal?: boolean;
  shouldFollow?: boolean;
  onClick?: () => void;
  isNewTheme?: boolean;
  publicPath?: string;
  children?: ReactNode;
}

export interface NavItemSingle extends NavItemType {
  type?: NAV_TYPE.SINGLE;
}

export interface NavItemMultiple extends NavItemType {
  type: NAV_TYPE.MULTIPLE;
  subItems: NavItemProps[];
}

export interface NavItemButton extends NavItemType {
  type: NAV_TYPE.BUTTON;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- will be deprecated
  buttonProps?: ButtonProps<any>;
}

export interface NavItemReactFC extends NavItemType {
  type: NAV_TYPE.COMPONENT;
  component?: ReactElement;
}

export type NavItemProps = NavItemSingle | NavItemButton | NavItemMultiple | NavItemReactFC;

export type NavItemComponent = (item: NavItemProps) => JSX.Element;

export type ApplyFn = (item: NavItemProps) => NavItemProps;
