import { styled, withStaticProperties } from '@tamagui/core';

import { Stack } from '../layout';

import { ROW_FRAME_NAME } from './constants';
import { Label } from './Label';
import { Value } from './Value';

const RowFrame = styled(Stack, {
  name: ROW_FRAME_NAME,

  px: '$4',
  py: '$3',
  alignItems: 'center',
  gap: '$2',
  justifyContent: 'space-between',
  row: true,
});

export const Row = withStaticProperties(RowFrame, {
  Label,
  Value,
});
