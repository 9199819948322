import { ReactNode } from 'react';

import { DataPoint, DataPointProps } from '@arrived/bricks';

export interface ProductListItemDetailProps extends DataPointProps<true> {
  productDetailValue?: ReactNode;
  productDetailLabel: ReactNode;
}
export const ProductListItemDetail = ({
  productDetailValue,
  productDetailLabel,
  ...rest
}: DataPointProps<true> & ProductListItemDetailProps) => {
  return (
    <DataPoint colors="dark" inline variant="minimized" flexShrink={1} {...rest}>
      {productDetailValue && <DataPoint.Value>{productDetailValue}</DataPoint.Value>}
      <DataPoint.Label ellipse>{productDetailLabel}</DataPoint.Label>
    </DataPoint>
  );
};
