import { ReactNode, useCallback, useContext, useMemo } from 'react';

import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import _uniq from 'lodash/uniq';

import { OpenSearchOfferingDocument } from '@arrived/api_v2';
import { Divider, TitleText } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { NavigatorContext } from '@arrived/contexts';
import { useGetProductTypeName } from '@arrived/hooks';
import { AvailabilityFilterTerm, Offering, OfferingType } from '@arrived/models';
import { useOfferingSearchQuery } from '@arrived/queries';

import { ProductDetailContent, ProductDetailContentProps } from '../../../content';
import { ProductListItem } from '../../../productListItem';
import { HelpCenterCallout } from '../callouts';

type WhichPropertySectionProps = ProductDetailContentProps & {
  offering: Offering;
};

export const WhichPropertySection = ({ offering, ...rest }: WhichPropertySectionProps) => {
  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.which-property-to-chose" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body>
        {offering.type !== OfferingType.FUND && (
          <WhichPropertyFundsUpsell assetType={offering.assetType}>
            <Divider />
          </WhichPropertyFundsUpsell>
        )}
        <HelpCenterCallout />
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};

const WhichPropertyFundsUpsell = ({
  assetType,
  children,
}: {
  assetType: Offering['assetType'];
  children: ReactNode;
}) => {
  const { data: forSaleFundOfferings } = useOfferingSearchQuery({
    assetType: { eq: [assetType] },
    availability: { eq: [AvailabilityFilterTerm.FOR_SALE] },
    sort: 'propertyRank:desc,id:desc',
    type: { eq: [OfferingType.FUND] },
  });

  return forSaleFundOfferings?.data && (forSaleFundOfferings?.data?.length ?? 0) > 0 ? (
    <>
      <WhichPropertyFundsUpsellContent forSaleFundOfferingsData={forSaleFundOfferings.data} />
      {children}
    </>
  ) : null;
};

const WhichPropertyFundsUpsellContent = ({
  forSaleFundOfferingsData,
}: {
  forSaleFundOfferingsData: OpenSearchOfferingDocument[];
}) => {
  const intl = useIntl();
  const { navigatorGoPropertyDetails } = useContext(NavigatorContext);

  const firstOffering = forSaleFundOfferingsData[0];

  const marketCount = useMemo(() => {
    return _uniq(firstOffering.properties.map(({ marketId }) => marketId).filter((marketId) => marketId != null))
      .length;
  }, [firstOffering.properties]);
  const handleOnPress = useCallback(() => {
    navigatorGoPropertyDetails(firstOffering.shortName);
  }, [firstOffering.shortName]);
  const productListItemSubtitle = useGetProductTypeName(firstOffering);

  return (
    <>
      <ProductDetailContent.SubHeader>
        <TitleText token="title.heading.small">
          <FormattedMessage id="product-details.which-property-to-chose.we-make-it-easy.header" />
        </TitleText>
      </ProductDetailContent.SubHeader>
      <Markdown Text={ProductDetailContent.Text}>
        {intl.formatMessage(
          { id: 'product-details.which-property-to-chose.we-make-it-easy.details' },
          { fundName: firstOffering.name },
        )}
      </Markdown>
      <ProductListItem onPress={handleOnPress} bordered>
        <ProductListItem.Image imageSource={firstOffering.thumbnailPhotoUrl} />
        <ProductListItem.Title>{firstOffering.name}</ProductListItem.Title>
        <ProductListItem.Subtitle>{productListItemSubtitle}</ProductListItem.Subtitle>
        <ProductListItem.Details
          details={[
            {
              productDetailValue: <FormattedNumber value={marketCount} />,
              productDetailLabel: <FormattedMessage id="product-details.markets" />,
            },
            {
              productDetailValue: <FormattedNumber value={firstOffering.properties.length} />,
              productDetailLabel: <FormattedMessage id="product-details.properties" />,
            },
            {
              productDetailValue: <FormattedNumber value={firstOffering.investorsCount} />,
              productDetailLabel: <FormattedMessage id="common.investors" />,
            },
          ]}
        />
      </ProductListItem>
    </>
  );
};
