import {
  assumedDebtInterestPercent,
  assumedDebtPercent,
  calculateCashFlows,
  calculateCumulativeReturns,
  calculateInternalRateOfReturn,
  calculateProjectedYearlyDividends,
} from '@arrived/common';
import { AssetType, Offering } from '@arrived/models';

import { ReturnsCalculatorForm } from './ReturnsCalculatorForm';

/**
 * Use the Offering's debtPercent, unless it's 0 or null, in which case if the offering has flexible financing, and
 * the leverage is applied for the calculation, use the assumed leverage percent, otherwise use 0.
 */
export function getDebtPercent(offering: Offering, leverage?: boolean) {
  if (offering.debtPercent !== 0) {
    return offering.debtPercent / 100;
  } else if (offering.hasFlexFinancing && leverage) {
    return assumedDebtPercent;
  }
  return 0;
}

/**
 * Use the Offering's interest, unless it's 0 or null, in which case if the offering has flexible financing, and the
 * leverage toggle is applied for the calculation, use the assumed interest percent, otherwise use 0.
 */
export function getDebtInterestPercent(offering: Offering, leverage?: boolean) {
  if (offering.debtInterestPercent !== 0) {
    return offering.debtInterestPercent / 100;
  } else if (offering.hasFlexFinancing && leverage) {
    return assumedDebtInterestPercent;
  }
  return 0;
}

/**
 * The loan amount is either on the property, or it's the result of multiplying the purchase price by the debt
 * percent.
 */
export function getLoanAmount(offering: Offering, debtPercent: number) {
  return offering.debtAmount || offering.totalPurchasePrice * debtPercent;
}

// TODO: Why do we increase ownership percent for flex financing properties? That seems wrong.
export function getInvestorOwnershipPercent(
  offering: Offering,
  investmentAmount: number,
  loanAmount: number,
  leverage?: boolean,
) {
  return investmentAmount / (offering.targetRaiseAmount - (offering.hasFlexFinancing && leverage ? loanAmount : 0));
}

export function getInvestmentTerm(offering: Offering) {
  return offering.assetType === AssetType.LTR ? 7 : 10;
}

export function getYearlyRentalIncrease(offering: Offering) {
  return offering.assetType === AssetType.LTR ? 0.03 : 0.05;
}

export interface GetReturnsDataArgs extends Omit<ReturnsCalculatorForm, 'leverage'> {
  /**
   * Leverage is only used in calculating returns data for flexible financing Offerings. If an Offering already has
   * debt, then the values used to calculate the loan amount are the debtPercent and debtInterestPercent already
   * associated with the Offering. If the Offering has no debt and is not available for flexible financing then we run
   * the calculations without leverage regardless of the value of this argument.
   *
   * In the case that leverage is toggled "ON" for a flexible financing property, we used assumed constants for debt
   * and debt interest percent that can be found in the @arrived/common package.
   */
  leverage?: boolean;
  offering: Offering;
}

/**
 * Computes the irr and yearly cumulative returns for an Offering based on the inputs from the returns calculator.
 */
export function getReturnsData({
  annualAppreciation,
  investmentAmount,
  leverage,
  offering,
  rentalDividendYield,
}: GetReturnsDataArgs) {
  const debtPercent = getDebtPercent(offering, leverage);
  const debtInterestPercent = getDebtInterestPercent(offering, leverage);
  const loanAmount = getLoanAmount(offering, debtPercent);
  const investmentTerm = getInvestmentTerm(offering);
  const yearlyRentalIncrease = getYearlyRentalIncrease(offering);

  const projectedYearlyDividends = calculateProjectedYearlyDividends({
    debtInterestPercent,
    // We can use offering.targetRaiseAmount here as the equityValue and not one that reduces the loan amount (in the
    // case of a flex financing property as we do in computing cashFlows below) because we are simply using this as the
    // means of figuring out rental income and the rentalDividendYield is computed to be used with the targetRaiseAmount
    // of the Offering. So regardless of if you're theoretically financing this property, the first year dividends will
    // equal targetRaiseAmount * rentalDividendYield.
    equityValue: offering.targetRaiseAmount,
    investmentTerm,
    isInterestDeductedInDividendYield: offering.debtPercent !== 0,
    loanAmount,
    projectedRentalDividendYield: rentalDividendYield,
    yearlyRentalIncrease,
  });

  const investorOwnershipPercent = getInvestorOwnershipPercent(offering, investmentAmount, loanAmount, leverage);

  const cumulativeReturns = calculateCumulativeReturns({
    additionalEquity: offering.propertyImprovementsAndCashReserves,
    annualAppreciation,
    assetValue: offering.totalPurchasePrice,
    investmentTerm,
    loanAmount,
    // We use this to calculate fees, so in the case of financed properties, we need to add back the loanAmount so
    // that we can correctly compute the fees for that Offering.
    totalEquityValue: offering.targetRaiseAmount + (offering.debtPercent !== 0 ? loanAmount : 0),
    yearlyDividends: projectedYearlyDividends,
  }).map((value) => value * investorOwnershipPercent);

  /**
   * In the case of a flex financing property where we are applying leverage, we need to decrease the target raise
   * amount used in the calculation of cash flows by the amount that will be put on loan.
   */
  const targetRaiseAmount =
    offering.hasFlexFinancing && leverage
      ? offering.targetRaiseAmount - offering.totalPurchasePrice * assumedDebtPercent
      : offering.targetRaiseAmount;

  const cashFlows = calculateCashFlows({
    additionalEquity: offering.propertyImprovementsAndCashReserves,
    annualAppreciation,
    assetValue: offering.totalPurchasePrice,
    equityValue: targetRaiseAmount,
    investmentTerm,
    loanAmount,
    yearlyDividends: projectedYearlyDividends,
  });

  const irr = calculateInternalRateOfReturn(cashFlows);

  return {
    cumulativeReturns,
    irr,
  };
}
