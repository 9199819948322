import { IntercomIdentityToken, IntercomIdentityTokenRequest } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const postIntercomIdentityToken = (request: IntercomIdentityTokenRequest) =>
  createQuery<IntercomIdentityToken>({
    apiInstance: api,
    method: 'post',
    url: '/intercom/create-identity-token',
    requestData: request,
  });
