import { cashAccountApi, createQuery } from '@arrived/api_v2';

import { ExternalBankAccount } from '../types/cash-accounts';

interface ExternalBankAccountParams {
  includeCounterparty?: boolean;
  includeUserAuthorizationLevel?: boolean;
}

export const getExternalBankAccounts = (cid: string, options?: ExternalBankAccountParams) =>
  createQuery<ExternalBankAccount>({
    apiInstance: cashAccountApi,
    method: 'get',
    url: `/external-bank-accounts/${cid}`,
    config: { params: options },
  });
