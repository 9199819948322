import { ReactNode } from 'react';

import { Offering } from './offerings';

export interface OfferingTagFilter {
  displayName: ReactNode;
  offerings: Offering[];
  disabledTooltipText?: ReactNode;
}

export enum OFFERING_TAG {
  TRENDING = 'trending',
  FOR_SALE = 'forsale',
  SOLD_OUT = 'soldout',
  // OWNED = 'owned',
  ALL = 'all',
}
