import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function ArrowLeftInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.393 10.625l3.933 3.933-.884.884-5-5L3 10l.442-.442 5-5 .884.884-3.933 3.933h11.824v1.25H5.393z"
        fill={color}
      />
    </Svg>
  );
}

export const ArrowLeftIcon = memo<IconProps>(withIconTheme(ArrowLeftInternal));
