import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteUser } from '@arrived/api_v2';

import { adminAllUsersKeyFn, adminUserKeyFn } from './users.keys';

export function useAdminDeleteUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUser,
    onSuccess: (data) => {
      queryClient.removeQueries({ queryKey: adminUserKeyFn(data.id) });
      queryClient.invalidateQueries({ queryKey: adminAllUsersKeyFn() });
    },
  });
}
