import _assign from 'lodash/assign';
import { stringify } from 'qs';

import {
  PhotoId,
  PhotoPatch,
  PhotoPost,
  Property,
  PropertyFilters,
  PropertyId,
  PropertyPatchRequest,
  PropertyPostRequest,
  PropertySlug,
  PropertyTourRequest,
  convertSortToString,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';
import { buildMultipartFormData } from './utils';

export const getProperties = (filters?: PropertyFilters) =>
  createQuery<Property[]>({
    apiInstance: api,
    method: 'get',
    url: '/properties',
    config: {
      params: {
        ...filters,
        sort: filters?.sort?.map(convertSortToString),
      },
    },
  });

export const getProperty = (propertyId: PropertyId, params?: { includeOfferingIds?: boolean }) =>
  createQuery<Property>({
    apiInstance: api,
    method: 'get',
    url: `/properties/${propertyId}`,
    config: {
      params,
    },
  });

export const postNewProperty = (requestData: PropertyPostRequest) =>
  createQuery<Property>({
    apiInstance: api,
    method: 'post',
    url: '/properties',
    requestData,
  });
export const postCloneProperty = (propertyId: PropertyId) =>
  createQuery<Property>({
    apiInstance: api,
    method: 'post',
    url: `properties/${propertyId}/clone`,
  });

export const patchProperty = (requestData: PropertyPatchRequest) =>
  createQuery<Property>({
    apiInstance: api,
    method: 'patch',
    url: `/properties/${requestData.id}`,
    requestData,
  });

export const deleteProperty = (propertyId: PropertyId) =>
  createQuery<Property>({
    apiInstance: api,
    method: 'delete',
    url: `/properties/${propertyId}`,
  });

export const getListedProperties = (filters?: PropertyFilters) =>
  createQuery<Property[]>({
    apiInstance: api,
    method: 'get',
    url: '/properties/listed',
    config: {
      params: {
        ...filters,
        sort: filters?.sort?.map(convertSortToString),
      },
    },
  });

export const getListedProperty = (slug: PropertySlug) =>
  createQuery<Property>({
    apiInstance: api,
    method: 'get',
    url: `/properties/listed/${slug}`,
  });

export const postRequestPropertyTour = (requestData: PropertyTourRequest) =>
  createQuery<PropertyTourRequest>({
    apiInstance: api,
    method: 'post',
    url: `/properties/${requestData.propertyId}/tours`,
    requestData,
  });

/**
 * TODO: buildMultipartFormData hasn't been tested
 */
export const postPropertyPhoto = ({
  propertyId,
  data,
  position,
}: {
  propertyId: PropertyId;
  data: PhotoPost;
  position?: number;
}) => {
  const { body, headers } = buildMultipartFormData(data);

  return createQuery<Property>({
    apiInstance: api,
    method: 'post',
    url: `/properties/${propertyId}/photos${position ? `?position=${position}` : ''}`,
    requestData: body,

    config: {
      headers: headers,
      params: {
        position,
      },
      paramsSerializer: (params) => stringify(params),
    },
  });
};

export const patchPropertyPhoto = ({
  propertyId,
  photoId,
  photoPatch,
}: {
  propertyId: PropertyId;
  photoId: PhotoId;
  photoPatch: PhotoPatch;
}) =>
  createQuery<Property>({
    apiInstance: api,
    method: 'patch',
    url: `/properties/${propertyId}/photos/${photoId}`,
    requestData: photoPatch,
  });

export const deletePropertyPhoto = ({ propertyId, photoId }: { propertyId: PropertyId; photoId: PhotoId }) =>
  createQuery<Property>({
    apiInstance: api,
    method: 'delete',
    url: `/properties/${propertyId}/photos/${photoId}`,
  });
