import {
  EmailVerificationOverrideResponse,
  EmailVerificationResendResponse,
  EmailVerificationStatusResponse,
  SubscriptionResponse,
  UserId,
} from '@arrived/models';

import { api, publicApi } from './api';
import { createQuery } from './create-query';

export const postSubscribeToNewPropertyNotifications = (requestData: { email: string }) =>
  createQuery<SubscriptionResponse>({
    apiInstance: publicApi,
    method: 'post',
    url: '/offerings/notifications/new-properties/subscribe',
    requestData,
  });

export const getEmailVerificationStatus = (userId: UserId) =>
  createQuery<EmailVerificationStatusResponse>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/is-email-verified`,
  });

export const postResendEmailVerification = (userId: UserId) =>
  createQuery<EmailVerificationResendResponse>({
    apiInstance: api,
    method: 'post',
    url: `/users/${userId}/send-verification-email`,
  });

export const postOverrideEmailVerification = ({ userId, newValue }: { userId: UserId; newValue: boolean }) =>
  createQuery<EmailVerificationOverrideResponse>({
    apiInstance: api,
    method: 'post',
    url: `/users/${userId}/override-email-verification`,
    requestData: { newValue },
  });
