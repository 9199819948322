import { QueryKey } from '@tanstack/react-query';

import { AccountId, OfferingId, UserId } from '@arrived/models';

export const sharesAvailableKey = ['sharesAvailable'] as readonly unknown[];

export const offeringSharesAvailableKeyFn = (offeringId: OfferingId): QueryKey =>
  sharesAvailableKey.concat(['offerings', offeringId]);

export const accountSharesAvailableKeyFn = (offeringId: OfferingId, accountId: AccountId): QueryKey =>
  offeringSharesAvailableKeyFn(offeringId).concat(['accounts', accountId]);

export const userSharesAvailableKeyFn = (offeringId: OfferingId, userId: UserId): QueryKey =>
  offeringSharesAvailableKeyFn(offeringId).concat(['users', userId]);
