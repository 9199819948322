export interface OfferingQueryParams {
  sort?: (typeof OfferingQuerySort)[keyof typeof OfferingQuerySort];

  /**
   * shows properties that are in PRE_LAUNCH state, must be admin
   * TODO replace with general offering status query
   */
  showPreLaunch?: boolean;

  /**
   * page | [1, max page]
   * size | > 0
   */
  page?: number;
  size?: number;
}

export const OfferingQuerySort = {
  OFFERINGS_SORT_ID_ASC: 'id:asc',
  OFFERINGS_SORT_ID_DESC: 'id:desc',
  OFFERINGS_SORT_PROPERTY_RANK_ASC: 'propertyRank:asc',
  OFFERINGS_SORT_PROPERTY_RANK_DESC: 'propertyRank:desc',
} as const;
