import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postUserNetWorth } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';

import { userNetWorthKeyFn } from './users.keys';

export function usePostUserNetWorthMutation(options?: UseAwaitedMutationOptions<typeof postUserNetWorth>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postUserNetWorth,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(userNetWorthKeyFn(data.userId), data);

      queryClient.invalidateQueries({ queryKey: userNetWorthKeyFn(data.userId) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
