import { createStyledContext } from '@tamagui/core';

import { PillStyledContextType } from '../Pill';

export const TAG_CONTAINER_NAME = 'TagContainer';
export const TAG_ICON_NAME = 'TagIcon';
export const TAG_LABEL_NAME = 'TagLabel';

export type PressableTagVariant = 'default' | 'inverted' | 'positive' | 'negative' | 'category';
export type TagVariant = PressableTagVariant | 'black' | 'white' | 'primary';

export interface TagStyledContextType extends Pick<PillStyledContextType, 'disabled' | 'pressable'> {
  // Unfortunately, we need to maintain state for isKeyDown/isHovered/isPressed until at least Tamagui fixes the group styling for icons https://github.com/tamagui/tamagui/issues/1707
  isKeyDown?: boolean;
  isHovered?: boolean;
  isPressed?: boolean;
  variant: TagVariant;
}

export const TagStyledContext = createStyledContext<TagStyledContextType>({
  variant: 'default' as TagVariant,
});
