import { useMemo, useState } from 'react';

import { GetProps, StackProps, createStyledContext, styled, useProps, withStaticProperties } from '@tamagui/core';

import { ImageProps as ExpoImageProps } from 'expo-image/build/Image.types';

import { Background, BodyText, Divider, Image, Stack, TitleText } from '../../atoms';
import { CloseIcon, IconProps } from '../../icons';
import { parseRootChildren } from '../../utils';
import { Button } from '../button';

export interface CalloutExtraProps {
  onClose?: () => void;
  hideCloseIcon?: boolean;
}

const CALLOUT_CONTAINER_NAME = 'CalloutContainer';
const CALLOUT_CONTENT_CONTAINER_NAME = 'CalloutContentContainer';
const CALLOUT_HEADING_NAME = 'CalloutHeading';
const CALLOUT_CONTENT_NAME = 'CalloutContent';
const CALLOUT_ILLUSTRATION_NAME = 'CalloutIllustration';
const CALLOUT_CLOSE_ICON_NAME = 'CalloutCloseIcon';
const CALLOUT_DIVIDER_NAME = 'CalloutDivider';

const CalloutStyledContext = createStyledContext({
  variant: 'default',
  centered: false,
});

const CalloutContainer = styled(Stack, {
  name: CALLOUT_CONTAINER_NAME,
  context: CalloutStyledContext,
  borderRadius: '$2',
  gap: '$4',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  w: '100%',
  variants: {
    variant: {
      default: {
        backgroundColor: '$onSurface.neutral.muted',
      },
      inverted: {
        backgroundColor: '$surface.primary.default',
      },
      negative: {
        backgroundColor: '$surface.negative.defaultAlt',
      },
      positive: {
        backgroundColor: '$surface.positive.defaultAlt',
      },
      yin: {},
      dark: {
        backgroundColor: '$onSurface.neutral.tooltip',
      },
    },
    banner: {},
  } as const,
  defaultVariants: {
    variant: 'default',
  },
});

const CalloutHeading = styled(TitleText, {
  name: CALLOUT_HEADING_NAME,
  context: CalloutStyledContext,
  token: 'title.heading.small',
  color: '$onSurface.neutral.default',
  variants: {
    variant: {
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
      default: {
        color: '$onSurface.neutral.defaultInverted',
      },
      dark: {
        color: '$interactive.neutral.restedInverted',
      },
    },
    centered: {
      true: {
        textAlign: 'center',
      },
    },
  } as const,
});

const CalloutContentText = styled(BodyText, {
  context: CalloutStyledContext,
  token: 'body.compact.medium',
  color: '$onSurface.neutral.default',
  variants: {
    variant: {
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
      dark: {
        color: '$interactive.neutral.restedInverted',
      },
      default: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
    centered: {
      true: {
        textAlign: 'center',
      },
    },
  } as const,
});

const CalloutContent = withStaticProperties(
  styled(Stack, {
    name: CALLOUT_CONTENT_NAME,
    variants: {
      centered: {
        true: {
          alignItems: 'center',
        },
      },
    } as const,
  }),
  { Text: CalloutContentText },
);

const CalloutDivider = styled(Divider, {
  name: CALLOUT_DIVIDER_NAME,
  context: CalloutStyledContext,

  solid: true,

  variants: {
    variant: {
      default: {
        color: 'onSurface.neutral.muted',
      },
      yin: {
        color: 'onSurface.neutral.muted',
      },
      inverted: {
        color: 'interactive.primary.disabled',
      },
      negative: {
        color: 'interactive.negative.disabled',
      },
      positive: {
        color: 'interactive.positive.disabled',
      },
    },
  } as const,
});

const CalloutCloseIcon = styled(CloseIcon, {
  name: CALLOUT_CLOSE_ICON_NAME,
  context: CalloutStyledContext,
  color: '$onSurface.neutral.default',

  variants: {
    variant: {
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
      default: {
        color: '$onSurface.neutral.defaultInverted',
      },
      dark: {
        color: '$interactive.neutral.restedInverted',
      },
    },
    banner: {
      true: {
        top: '50%',
      },
    },
  } as const,
});

const CalloutCloseIconComponent = ({ color: _, ...rest }: IconProps) => (
  // @ts-expect-error
  <CalloutCloseIcon {...rest} />
);

const CalloutContentContainer = styled(Stack, {
  name: CALLOUT_CONTENT_CONTAINER_NAME,
  context: CalloutStyledContext,
  flexDirection: 'row',
  p: '$4',
  position: 'relative',
  flexShrink: 1,
  gap: '$4',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    variant: {
      dark: {
        // @ts-expect-error
        color: '$interactive.neutral.restedInverted',
      },
    },
    centered: {
      true: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
});

const CalloutIllustration = styled(Stack, {
  name: CALLOUT_ILLUSTRATION_NAME,
});

interface CalloutIllustrationExtraProps {
  source?: string;
  imageProps?: ExpoImageProps & StackProps;
}

export const Callout = withStaticProperties(
  CalloutContainer.styleable<GetProps<typeof CalloutContainer> & CalloutExtraProps>((propsIn, ref) => {
    const { children: childrenIn, hideCloseIcon, onClose, ...rest } = useProps(propsIn);
    const [isOpen, setIsOpen] = useState(true);

    const {
      [CALLOUT_HEADING_NAME]: Heading,
      [CALLOUT_CONTENT_NAME]: Content,
      [CALLOUT_ILLUSTRATION_NAME]: Illustration,
      [CALLOUT_DIVIDER_NAME]: Divider,
    } = useMemo(
      () =>
        parseRootChildren(childrenIn, [
          CALLOUT_HEADING_NAME,
          CALLOUT_CONTENT_NAME,
          CALLOUT_ILLUSTRATION_NAME,
          CALLOUT_DIVIDER_NAME,
        ]),
      [childrenIn],
    );

    const background = useMemo(() => {
      switch (rest.variant) {
        case 'yin':
          return <Background.Noise position="absolute" top={0} left={0} right={0} bottom={0} />;
        default:
          return;
      }
    }, [rest.variant]);

    return (
      <>
        {isOpen && (
          <CalloutContainer ref={ref} {...rest}>
            {background}
            <CalloutContentContainer>
              {Illustration}
              <Stack flex={1} gap="$2">
                {Heading && <Stack pr={!hideCloseIcon ? '$8' : undefined}>{Heading}</Stack>}
                {Divider}
                {Content}
              </Stack>
            </CalloutContentContainer>
            {!hideCloseIcon && (
              <Button
                // TODO: This might actually be something that we need to do on the ghost variant of Button, but the ghost variant actually asserts a color of black which doesn't
                //  work unless the Button is distinctly on a white background (or other light color). In this case we need the CloseIcon to take on the color determined by our
                //  variant so we wrap it in another function that doesn't pass on the color prop that Button passes to the Icon.
                Icon={CalloutCloseIconComponent}
                onPress={() => {
                  setIsOpen(false);
                  onClose?.();
                }}
                condensed
                variant="ghost"
                position="absolute"
                top="$2"
                right="$2"
              />
            )}
          </CalloutContainer>
        )}
      </>
    );
  }),
  {
    Divider: CalloutDivider,
    Heading: CalloutHeading,
    Content: CalloutContent,
    Illustration: CalloutIllustration.styleable<GetProps<typeof CalloutIllustration> & CalloutIllustrationExtraProps>(
      (propsIn, ref) => {
        const { source, imageProps, ...rest } = useProps(propsIn);
        return (
          <>
            {source && source !== '' && (
              <CalloutIllustration ref={ref} {...rest}>
                <Image source={{ uri: source }} width={32} height={32} contentFit="contain" {...imageProps} />
              </CalloutIllustration>
            )}
          </>
        );
      },
    ),
  },
);

export type CalloutProps = GetProps<typeof Callout>;
