import { useEffect, useRef } from 'react';

/**
 * Hook that can be utilized to determine if a component has been unmounted, typically during an async callback to
 * prevent state updates that will cause React errors.
 *
 * The returned value is a ref whose .current value will indicate whether or not the component is currently mounted.
 *
 * Because hook order matters, you'll often want to place this hook at the top of your FC so that it's effect fires
 * first and initializes .current to `true` before other hooks execute.
 */
export function useIsMountedWatcher() {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
}
