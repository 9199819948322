import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Stack, StackProps, Typography } from '@mui/material';

import { ArrivedTooltip } from '@arrived/features-tooltips';

import { LargePercentDisplay } from '../components';

export interface ReturnsDisplayProps extends StackProps {
  irr: number;
  hypotheticalROI: number;
}

export const ReturnsDisplay = ({ hypotheticalROI, irr, ...rest }: ReturnsDisplayProps) => (
  <Stack {...rest}>
    <Stack>
      <LargePercentDisplay
        value={irr}
        signDisplay="exceptZero"
        TypographyProps={{ color: ({ palette }) => palette.tertiary[500] }}
      />
      <ArrivedTooltip
        tooltipContent={<FormattedMessage id="property-details.calculator.irr-tooltip" />}
        referencedTextPropOverrides={{
          variant: 'body2',
          color: ({ palette }) => palette.neutrals.white,
        }}
      >
        <FormattedMessage id="property-details.calculator.annualized-return" />
      </ArrivedTooltip>
    </Stack>
    <Stack alignItems="flex-end">
      <Typography variant="h5.semibold" component="div" color={({ palette }) => palette.neutrals.white}>
        <FormattedNumber
          style="currency"
          currency="USD"
          value={hypotheticalROI}
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          signDisplay="negative"
        />
      </Typography>
      <Typography textAlign="right" variant="body2" color={({ palette }) => palette.neutrals.white}>
        <FormattedMessage id="property-details.calculator.hypothetical-value" />
      </Typography>
    </Stack>
  </Stack>
);
