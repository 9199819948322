import { PropsWithChildren, ReactNode, useCallback, useContext } from 'react';

import { ArrowRightIcon, GetProps, InfoIcon, Stack, Tooltip, UtilityText, styled } from '@arrived/bricks';

import {
  PRODUCT_INFO_BOX_HEADER_FRAME_NAME,
  PRODUCT_INFO_BOX_HEADER_TEXT_NAME,
  PRODUCT_INFO_BOX_HEADER_TOOLTIP_FRAME_NAME,
} from './constants';
import { ProductInfoBoxStyledContext } from './ProductInfoBoxStyledContext';

const HeaderFrame = styled(Stack, {
  name: PRODUCT_INFO_BOX_HEADER_FRAME_NAME,

  row: true,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$2',
  flexShrink: 1,
});

const HeaderText = styled(UtilityText, {
  name: PRODUCT_INFO_BOX_HEADER_TEXT_NAME,

  ellipse: true,
  token: 'utility.label.small.alt',
});

const TooltipFrame = styled(Stack, {
  name: PRODUCT_INFO_BOX_HEADER_TOOLTIP_FRAME_NAME,

  flexDirection: 'row',
  alignItems: 'center',
  gap: '$1',
  width: '100%',
});

export const ProductInfoBoxHeader = HeaderFrame.styleable<GetProps<typeof HeaderFrame> & { tooltip?: ReactNode }>(
  ({ children, tooltip, ...rest }, ref) => {
    const { link } = useContext(ProductInfoBoxStyledContext);

    const Content = useCallback(
      ({ children }: PropsWithChildren) => {
        return tooltip ? (
          <Tooltip>
            <Tooltip.Trigger asChild>
              <TooltipFrame>
                <HeaderText>{children}</HeaderText>
                <InfoIcon size="sm" style={{ flexShrink: 0 }} />
              </TooltipFrame>
            </Tooltip.Trigger>
            <Tooltip.Content tooltipTitle={children}>
              <Tooltip.Text>{tooltip}</Tooltip.Text>
            </Tooltip.Content>
          </Tooltip>
        ) : (
          <HeaderText>{children}</HeaderText>
        );
      },
      [tooltip],
    );

    return (
      <HeaderFrame ref={ref} {...rest}>
        <Content>{children}</Content>
        {link && <ArrowRightIcon />}
      </HeaderFrame>
    );
  },
);
