import { GetProps, createStyledContext, styled, useProps, withStaticProperties } from '@tamagui/core';
import { LinearGradient } from '@tamagui/linear-gradient';

import { BodyText, Stack } from '../../atoms';
import { Button } from '../button';

const EmptyStateFrameName = 'EmptyStateFrame';
const EmptyStateTextName = 'EmptyStateText';
const EmptyStateButtonName = 'EmptyStateButton';

export const EmptyStateStyledContext = createStyledContext({
  isContained: false,
  hideGradient: true,
});

const EmptyStateFrame = styled(Stack, {
  name: EmptyStateFrameName,
  position: 'relative',
  padding: '$4',
  borderRadius: '$2',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$2',
  justifyContent: 'space-between',

  context: EmptyStateStyledContext,

  variants: {
    isContained: {
      true: {},
      false: {},
    },
    hideGradient: {
      true: {},
      false: {},
    },
  } as const,

  defaultVariants: {
    isContained: false,
    hideGradient: true,
  },
});

const EmptyStateText = styled(BodyText, {
  name: EmptyStateTextName,
  token: 'body.compact.small',
  textAlign: 'center',
});

const EmptyStateButton = styled(Button, {
  name: EmptyStateButtonName,
  variant: 'outlined',
  context: EmptyStateStyledContext,

  variants: {
    isContained: {
      true: {
        width: 'auto',
      },
      false: {
        width: '100%',
      },
    },
  } as const,

  defaultVariants: {
    isContained: false,
  },
});

export const EmptyState = withStaticProperties(
  EmptyStateFrame.styleable((propsIn, ref) => {
    const props = useProps(propsIn);

    const { children, isContained, hideGradient, ...rest } = props;

    return (
      <EmptyStateFrame ref={ref} {...rest}>
        <Stack width="100%" zIndex={1} alignItems="center" justifyContent="center" gap="$4">
          {children}
        </Stack>
        {!hideGradient && (
          <LinearGradient
            borderRadius={isContained ? 0 : '$2'}
            zIndex={0}
            start={[0, 1]}
            end={[1, 1]}
            colors={['$surface.primary.default', '$surface.neutral.default', '$surface.primary.default']}
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
          />
        )}
      </EmptyStateFrame>
    );
  }),
  {
    Text: EmptyStateText,
    Button: EmptyStateButton,
  },
);

export type EmptyStateProps = GetProps<typeof EmptyState>;
