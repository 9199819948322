import { ReactNode, useCallback } from 'react';

import { FormattedMessage } from 'react-intl';

import { useLocalStorage } from 'usehooks-ts';

import Close from '@mui/icons-material/Close';
import { IconButton, Link, Stack, Typography } from '@mui/material';

import { CONFIG } from '@arrived/config';

interface UkraineBannerProps {
  imageComponent?: ReactNode;
}
export const UkraineBanner = (props: UkraineBannerProps) => {
  const [isBannerHidden, setIsBannerHidden] = useLocalStorage('isTopBannerHidden', false);

  const handleBannerClose = useCallback(() => {
    setIsBannerHidden(true);
  }, []);

  return (
    <>
      {!isBannerHidden && (
        <Stack
          direction="row"
          justifyContent="center"
          sx={({ palette }) => ({
            backgroundColor: palette.primary.main,
            display: {
              xs: 'none',
              md: 'flex',
            },
          })}
        >
          <Link
            href={`${CONFIG.publicAppRoot}/ukraine`}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 1,
              textAlign: 'center',
            }}
          >
            {props?.imageComponent}
            <Typography variant="body1" ml={2} color="black">
              <FormattedMessage
                id="ukraineBanner.title"
                defaultMessage="We stand with Ukraine. Learn more about how to help Ukraine."
              />
            </Typography>
          </Link>
          <IconButton onClick={handleBannerClose}>
            <Close />
          </IconButton>
        </Stack>
      )}
    </>
  );
};
