import { useMemo } from 'react';

import { Offering } from '@arrived/models';

import { defaultAnnualRentalDividendYield } from '../calculator/constants';

export function useRentalIncomeCalculationData(offering: Offering) {
  const dividendYield = useMemo(
    () => offering.projectedAnnualDividendYield ?? defaultAnnualRentalDividendYield,
    [offering.projectedAnnualDividendYield],
  );
  const dividends = useMemo(
    () => dividendYield * offering.targetRaiseAmount,
    [dividendYield, offering.targetRaiseAmount],
  );
  const firstProperty = useMemo(
    () => (offering.properties?.length ? offering.properties[0] : undefined),
    [offering.properties],
  );
  const rent = useMemo(() => (firstProperty?.rent ?? 0) * 12, [firstProperty]);
  const grossYield = useMemo(() => rent / offering.targetRaiseAmount, [rent, offering.targetRaiseAmount]);
  const expenses = useMemo(() => rent - dividends, [rent, dividends]);

  return useMemo(
    () => ({
      dividends,
      dividendYield,
      expenses,
      grossYield,
      rent,
    }),
    [dividends, dividendYield, expenses, grossYield, rent],
  );
}
