import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postRedeemCode } from '@arrived/api_v2';

import { referralsQueryKeyFn } from './referrals.keys';

export function usePostRedeemCodeMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postRedeemCode,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: referralsQueryKeyFn(variables) });
    },
  });
}
