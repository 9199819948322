import { ReactNode, createContext, useContext, useMemo } from 'react';

import { PortalHost } from '@gorhom/portal';

/**
 * Used as an all-encompassing portal host change for any bricks components that use `Portal`.
 */
export const PortalContext = createContext({ portalHostName: 'root' });

export const usePortalContext = () => useContext(PortalContext);

interface PortalContextProviderProps {
  portalHostName: string;
  children: ReactNode;
}
export const PortalContextProvider = ({ portalHostName, children }: PortalContextProviderProps) => {
  const value = useMemo(() => ({ portalHostName }), [portalHostName]);

  return (
    <PortalContext.Provider value={value}>
      {children}
      <PortalHost name={value.portalHostName} />
    </PortalContext.Provider>
  );
};
