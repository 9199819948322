import { useCallback, useRef, useState } from 'react';

import { Box, BoxProps, ClickAwayListener, Popper } from '@mui/material';

import { BlurView, Button, ShareIcon, TamaguiElement } from '@arrived/bricks';

import { getPopperButtonConfigs } from './SocialShareButtonConfigs';
import { SocialShareButtons } from './SocialShareButtons';

export type SocialSharePopperProps = BoxProps & {
  url: string;
  config: Parameters<typeof getPopperButtonConfigs>;
};

export const SocialSharePopper = ({ url, config, ...rest }: SocialSharePopperProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<TamaguiElement>(null);
  const wrapperRef = useRef(null);

  const handleOnClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    const path = event.composedPath();

    // If trying to click the share button on mobile, this fires... Why? Who knows... this fixes that
    if (wrapperRef.current && !path.includes(wrapperRef.current)) {
      setIsOpen(false);
    }
  };

  const buttonConfig = getPopperButtonConfigs(...config);

  return (
    <Box ref={wrapperRef} {...rest}>
      <Button variant="ghost" onPress={handleOnClick} Icon={ShareIcon} ref={buttonRef} />
      <Popper
        anchorEl={buttonRef.current as unknown as HTMLElement}
        open={isOpen}
        placement="bottom-end"
        sx={{
          lineHeight: 0,
          zIndex: ({ zIndex }) => zIndex.tooltip,
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box borderRadius={2} overflow="hidden" position="relative">
            <BlurView position="absolute" tint="dark" />
            <SocialShareButtons position="relative" buttonConfig={buttonConfig} iconSize={32} url={url} />
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};
