import ImageKit from 'imagekit-javascript';

import { Constants } from '@arrived/common';

// Note: we also remove the imageCdnUrl just in case it's coming from BuilderIO
export const cdnToImageUrl = (cdnUrl: string) =>
  cdnUrl.replace(Constants.awsCdnUrl, '').replace(Constants.imageCdnUrl, '');
export const ikClient = new ImageKit({ urlEndpoint: Constants.imageCdnUrl });

export type ImageKitUrlOptions = Parameters<typeof ikClient.url>[0];

export type ImageKitUploadOptions = Parameters<typeof ikClient.upload>[0];

/**
 * Crop modes that can be used with ImageKit, see link below for an exhaustive list.
 *
 * https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#crop-crop-modes-and-focus
 */
export type ImageKitCrop = 'force' | 'at_max' | 'at_max_enlarge' | 'at_least' | 'maintain_ratio';
