import * as yup from 'yup';

import { AccreditedStatus, Domicile } from '@arrived/models';

export const SuitabilityQuestionnaireSchema = yup.object({
  netWorthOver1m: yup.boolean().required(),
  individualIncomeOver200k: yup.boolean().required(),
  jointIncomeOver300k: yup.boolean().required(),
  organizationAssetsOver5m: yup.boolean().when('$isIndividualAccountType', {
    is: true,
    then: (schema) => schema.strip(),
    otherwise: (schema) => schema.required(),
  }),
  associatedWithFinra: yup.boolean().required(),
  finraInstitutionName: yup
    .string()
    .trim()
    .when('associatedWithFinra', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().nullable().strip(),
    }),
  associatedWithPublicCompany: yup.boolean().required(),
  publicCompanyTickers: yup
    .string()
    .trim()
    .when('associatedWithPublicCompany', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired().nullable().strip(),
    }),
});

export type SuitabilityQuestionnaireSchema = yup.InferType<typeof SuitabilityQuestionnaireSchema>;

export const ACCREDITATION_STATUS_VALIDATION_KEYS = {
  REQUIRED: 'REQUIRED',
  COMMA_SEPARATED: 'COMMA_SEPARATED',
} as const;

const checkForCommas = (value?: string) => {
  if (!value) {
    return true;
  }

  const items = value.split(',');

  return items.length > 1 ? items.every((item) => item.trim().length > 0) : true;
};

export const AccreditationStatusSchema = yup.object({
  netWorthOver1m: yup.boolean(),
  individualIncomeOver200k: yup.boolean(),
  jointIncomeOver300k: yup.boolean(),
  associatedWithFinra: yup.boolean(),
  finraInstitutionName: yup
    .string()
    .trim()
    .when('associatedWithFinra', {
      is: true,
      // TODO error for formatted message?
      then: (schema) =>
        schema
          .required(ACCREDITATION_STATUS_VALIDATION_KEYS.REQUIRED)
          .test('is-comma-delimited', ACCREDITATION_STATUS_VALIDATION_KEYS.COMMA_SEPARATED, checkForCommas),
      otherwise: (schema) => schema.notRequired().nullable().strip(),
    }),
  associatedWithPublicCompany: yup.boolean(),
  publicCompanyTickers: yup
    .string()
    .trim()
    .when('associatedWithPublicCompany', {
      is: true,
      // TODO error for formatted message?
      then: (schema) =>
        schema
          .required(ACCREDITATION_STATUS_VALIDATION_KEYS.REQUIRED)
          .test('is-comma-delimited', ACCREDITATION_STATUS_VALIDATION_KEYS.COMMA_SEPARATED, checkForCommas),
      otherwise: (schema) => schema.notRequired().nullable().strip(),
    }),
  investmentNotOver10percentNetWorthOrIncome: yup
    .boolean()
    .when(['netWorthOver1m', 'individualIncomeOver200k', 'jointIncomeOver300k'], {
      is: (netWorthOver1m: boolean, individualIncomeOver200k: boolean, jointIncomeOver300k: boolean) =>
        !netWorthOver1m && !individualIncomeOver200k && !jointIncomeOver300k,
      // Force trigger validation
      then: (schema) => schema.required().oneOf([true], ACCREDITATION_STATUS_VALIDATION_KEYS.REQUIRED),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export type AccreditationStatusSchema = yup.InferType<typeof AccreditationStatusSchema>;

/**
 * @note Use `useRegulationAccessSummaryQuery` instead, this offers an API endpoint to make determinations
 * about a user's accreditation status.
 */
export const getIsAccreditedInvestor = (suitabilityQuestionnaire?: Partial<SuitabilityQuestionnaireSchema>) => {
  const { netWorthOver1m, individualIncomeOver200k, jointIncomeOver300k, organizationAssetsOver5m } =
    suitabilityQuestionnaire || {};

  return Boolean(netWorthOver1m || individualIncomeOver200k || jointIncomeOver300k || organizationAssetsOver5m);
};

// TODO: Move to common
export const domicileToResidentTypeString = (domicile: Domicile = Domicile.NONE): string => {
  switch (domicile) {
    case Domicile.US_CITIZEN:
    case Domicile.US_PERMANENT_RESIDENT:
      return 'Domestic Account';

    case Domicile.NON_RESIDENT:
      return 'International Account';

    case Domicile.NONE:
    default:
      return 'N/A';
  }
};

export const getAccreditedStatus = (accreditedInvestor?: boolean): AccreditedStatus => {
  if (typeof accreditedInvestor === 'undefined') {
    return AccreditedStatus.PENDING;
  }

  return accreditedInvestor ? AccreditedStatus.SELF_ACCREDITED : AccreditedStatus.NOT_ACCREDITED;
};
