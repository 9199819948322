import { Leaves } from './types';

/**
 * @note all colors `primary` values will be `500`. For example, if you
 * want to use the `tertiary` palette, its value will be `primary.500` or `colors.primary[500]`
 *
 * @reference https://colors.eva.design/
 */
export const colors = {
  /**
   * Design System Colors
   *
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */

  /**
   * @name NewPrimary
   */
  newPrimary: {
    0: '#e8f1fd',
    100: '#e0ecfc',
    200: '#c1daf9',
    300: '#a2c7f5',
    400: '#83b5f2',
    500: '#64a2ef',
    600: '#4590ec',
    700: '#267de9',
    800: '#156cd8', // Default color
    900: '#125db9',
    920: '#0F4D9A',
    940: '#0C3E7B',
    960: '#092E5C',
    980: '#061F3D',
    1000: '#0f4d9a',
  },

  /**
   * @name NewNegative
   */
  newNegative: {
    0: '#FFEBEC',
    100: '#FFE5E6',
    200: '#FFCBCD',
    300: '#FFB0B4',
    400: '#FF969B',
    500: '#FF7C82',
    600: '#FF6169',
    700: '#FF4750',
    800: '#EE3640',
    900: '#CC2E36', // Default color
    920: '#AA272D',
    940: '#881F24',
    960: '#66171B',
    980: '#440F12',
    1000: '#220809',
  },

  /**
   * @name Aqua
   */
  aqua: {
    50: '#e5faff',
    100: '#c7f5ff',
    200: '#94eBff',
    300: '#5ce1ff',
    400: '#29d8ff',
    500: '#00c5f1',
    600: '#009ec2',
    700: '#00758f',
    800: '#004f61',
    900: '#00252e',
  },

  /**
   * @name Mint
   */
  mint: {
    50: '#e9fcfb',
    100: '#d2f9f7',
    200: '#a5f3ef',
    300: '#78ede7',
    400: '#4be7df',
    500: '#1ee0d5',
    600: '#18b4ac',
    700: '#128781',
    800: '#0c5a56',
    900: '#062d2b',
  },

  /**
   * @name Coral
   */
  coral: {
    50: '#fff3f0',
    100: '#ffe4db',
    200: '#ffccbd',
    300: '#ffb199',
    400: '#ff997A',
    500: '#ff7f57',
    600: '#ff4b14',
    700: '#cc3000',
    800: '#8a2000',
    900: '#420f00',
  },

  /**
   * @name Tangerine
   */
  tangerine: {
    50: '#fff9f0',
    100: '#fff3e0',
    200: '#ffe7c2',
    300: '#ffdaa3',
    400: '#ffce85',
    500: '#ffc268',
    600: '#ffa51f',
    700: '#d68100',
    800: '#8f5600',
    900: '#472b00',
  },

  /**
   * @name Text
   */
  darkened: {
    midnight: '#202020',
    steel: '#505050',
    seal: '#737373',
  },

  /**
   * @name Neutrals
   * @description All neutral colors are used for smaller elements like borders, icons, etc.
   */
  neutrals: {
    gray: '#54545c',
    platinum: '#e1e7e7',
    milk: '#f7f7f8',
    white: '#ffffff',
  },

  extras: {
    fourAm: '#8F8F8F',
    fog: '#F0F3F3',
  },

  /**
   * @name Background
   * @description All background colors.
   */
  lightened: {
    // Palette Tokens
    iceBlue: '#f0f9fe',
    iceGreen: '#f2fcfb',
    iceGray: '#f7f7f8',
    bare: '#FAFAFA',
  },

  /**
   * Color Palettes
   */

  /**
   * @reference colors.aqua
   */
  get primary() {
    return colors.aqua;
  },

  /**
   * @reference colors.coral
   */
  get secondary() {
    return colors.coral;
  },

  /**
   * @reference colors.mint
   */
  get tertiary() {
    return colors.mint;
  },

  /**
   * @reference colors.tangerine
   */
  get quaternary() {
    return colors.tangerine;
  },

  get success() {
    return colors.mint[400];
  },
  get danger() {
    return colors.coral[600];
  },
  get warning() {
    return colors.tangerine[400];
  },
} as const;

export type Color = typeof colors;
export type Colors = Leaves<typeof colors>;
export type BackgroundPalette = Color['lightened'];
export type ColorSchemes = keyof Omit<Color, 'contrastThreshold' | 'white' | 'black' | 'lightText' | 'darkText'>;
