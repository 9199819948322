import { useEffect, useMemo, useRef } from 'react';

import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

import { findAccountKycAml, getAccountKycAml } from '@arrived/api_v2';
import { AccountId, AccountKycAml, KycAmlRollupStatus } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../../utils';
import { fetchAccountQuery, useGetAccountQuery } from '../useGetAccountQuery';

import { accountKycAmlKeyFn } from './accountKycAml.keys';

/**
 * Since we don't have a way to push updates from the BE when a users KYC status updates, the asynchronous nature of KYC
 * requires us to poll the BE for KYC AML data when we're in a PENDING_SYNC state. By default this will poll the BE
 * in intervals of 250ms * 2 ^ n where "n" is the number of refetches that have already happened.
 */
export function useGetAccountKycAmlQuery(
  accountId?: AccountId,
  options?: UseAwaitedQueryOptions<typeof getAccountKycAml | typeof findAccountKycAml>,
) {
  // Exponential backoff and refetch counter, will be undefined when we are not refetching and a defined value (0 or
  // greater) when we are.
  const refetchCount = useRef<number>();
  const queryClient = useQueryClient();

  // To optimize our costs with running KYC/AML, we're going to be changing which KYC/AML endpoint
  // we call depending on if the user has linked their bank account or not.
  const accountState = useGetAccountQuery(accountId);
  const hasLinkedBankAccount = useMemo(() => {
    if (!accountState.isSuccess) {
      return undefined;
    }

    return accountState.data?.externalAccount != null;
  }, [accountState.isSuccess, accountState.data?.externalAccount]);

  const kycAmlQueryState = useQuery({
    queryKey: accountKycAmlKeyFn(accountId!, hasLinkedBankAccount ?? false),
    queryFn: () => {
      /**
       * If a user has linked a bank account, we'll call the `getAccountKycAml` endpoint which will
       * either return us the user's existing KYC/AML record, or start an asynchronous request to
       * run KYC/AML for that user.
       *
       * For users who have not linked their bank account, we'll call `findAccountKycAml` which will
       * only retrieve a record if it exists, if one does not exist, we will not create a request
       * to run KYC/AML.
       */
      if (hasLinkedBankAccount) {
        if (refetchCount.current != null) {
          // If we are refetching, increment the counter.
          refetchCount.current += 1;
        }
        return getAccountKycAml(accountId!);
      } else {
        return findAccountKycAml(accountId!);
      }
    },
    refetchInterval: () => {
      return refetchCount.current != null ? 250 * Math.pow(2, refetchCount.current) : false;
    },
    ...options,
    // hasLinkedBankAccount != null has been intentionally omitted from this enabled clause, this
    // is to ensure that we're not delaying a query result here waiting on the account query above
    // to complete. It is understood that this will incur extra API calls upon application load for
    // users who have already linked a bank account.
    enabled: (options?.enabled ?? true) && accountId != null,
  });

  /**
   * This effect keeps the data stored in the query cache under both the "linked" and "unlinked"
   * keys for KYC/AML in sync. This way when the user goes from a linked bank -> unlinked bank
   * or vis versa, the state of ther identity verification remains the same.
   */
  useEffect(() => {
    if (kycAmlQueryState.isSuccess && accountId) {
      const kycAmlQueryUnlinkedState = queryClient.getQueryState<AccountKycAml>(accountKycAmlKeyFn(accountId, false));
      if (
        !(
          kycAmlQueryUnlinkedState?.dataUpdatedAt &&
          kycAmlQueryUnlinkedState.dataUpdatedAt >= kycAmlQueryState.dataUpdatedAt
        )
      ) {
        queryClient.setQueryData<AccountKycAml>(accountKycAmlKeyFn(accountId, false), kycAmlQueryState.data);
      }

      const kycAmlQueryLinkedState = queryClient.getQueryState<AccountKycAml>(accountKycAmlKeyFn(accountId, true));
      if (
        !(
          kycAmlQueryLinkedState?.dataUpdatedAt &&
          kycAmlQueryLinkedState.dataUpdatedAt >= kycAmlQueryState.dataUpdatedAt
        )
      ) {
        queryClient.setQueryData<AccountKycAml>(accountKycAmlKeyFn(accountId, true), kycAmlQueryState.data);
      }
    }
  }, [accountId, kycAmlQueryState.data]);

  useEffect(() => {
    if (!kycAmlQueryState.isSuccess) {
      return;
    }

    // If the rollup status is PENDING_SYNC, we want to start refetching on an exponential backoff driven interval,
    // so we set the refetchCount ref to have a value of 0.
    if (hasLinkedBankAccount && kycAmlQueryState.data?.kycAmlRollupStatus === KycAmlRollupStatus.PENDING_SYNC) {
      if (refetchCount.current == null) {
        refetchCount.current = 0;
      }
    } else {
      refetchCount.current = undefined;
    }
  }, [hasLinkedBankAccount, kycAmlQueryState.data, kycAmlQueryState.isSuccess]);

  return kycAmlQueryState;
}

export async function fetchAccountKycAmlQuery(queryClient: QueryClient, accountId: AccountId) {
  const account = await fetchAccountQuery(queryClient, accountId);
  const hasLinkedBankAccount = account.externalAccount != null;

  return await queryClient.fetchQuery({
    queryKey: accountKycAmlKeyFn(accountId, hasLinkedBankAccount),
    queryFn: () => (hasLinkedBankAccount ? getAccountKycAml(accountId) : findAccountKycAml(accountId)),
  });
}
