import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postPromotion } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';

import { promotionKeyFn, promotionsKeyFn } from './rewards.keys';

export function usePostPromotionMutation(options?: UseAwaitedMutationOptions<typeof postPromotion>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postPromotion,
    ...options,
    onSuccess: (data, variables, context) => {
      const { uuid } = data;

      queryClient.setQueryData(promotionKeyFn(uuid), data);

      queryClient.invalidateQueries({ queryKey: promotionsKeyFn() });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
