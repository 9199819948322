import { AmlStatus } from '@arrived/models';

export const AmlStatusTranslations = {
  [AmlStatus.PENDING]: 'Pending',
  [AmlStatus.AUTO_APPROVED]: 'Auto Approved',
  [AmlStatus.MANUALLY_APPROVED]: 'Manually Approved',
  [AmlStatus.AUTO_DISAPPROVED]: 'Auto Disapproved',
  [AmlStatus.MANUALLY_DISAPPROVED]: 'Manually Disapproved',
  [AmlStatus.NEED_MORE_INFO]: 'Need More Info',
  [AmlStatus.PENDING_REVIEW]: 'Pending Review',
} as const;

export const formatAmlStatus = (status: AmlStatus) => AmlStatusTranslations[status] ?? 'N/A';
