import { createAnimations } from '@tamagui/animations-css';

export const animations = createAnimations({
  bouncy: 'ease-in 200ms',
  smoothSpring: 'ease-in-out 400ms',
  lazy: 'ease-in 600ms',
  slow: 'ease-in 500ms',
  quick: 'ease-in 100ms',
  slideout: 'ease-in 300ms',
  tooltip: 'ease-in 400ms',
});
