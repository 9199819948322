import * as yup from 'yup';

import { sanitizeQuotes } from '../../utils';

export const generateSignDocumentsSchema = ({
  firstName,
  lastName,
  targetSignature,
}: {
  firstName?: string;
  lastName?: string;
  targetSignature?: string;
}) => {
  return yup
    .object({
      signature: yup
        .string()
        .trim()
        .max(128, 'Signature should not exceed 128 characters.')
        .required('Your signature is required to purchase shares')
        .when([], {
          // An empty array means no dependencies for the conditional
          is: () => Boolean(firstName), // Check if firstName is provided
          then: (schema) =>
            schema.test(
              'startsWithFirstName',
              'Signature should start with the first name provided.',
              (val: string | undefined) =>
                !val || sanitizeQuotes(val).toLowerCase().startsWith(sanitizeQuotes(firstName!).toLowerCase()),
            ),
        })
        .when([], {
          is: () => Boolean(lastName), // Check if lastName is provided
          then: (schema) =>
            schema.test(
              'endsWithLastName',
              'Signature should end with the last name provided.',
              (val: string | undefined) =>
                !val || sanitizeQuotes(val).toLowerCase().endsWith(sanitizeQuotes(lastName!).toLowerCase()),
            ),
        })
        .when([], {
          is: () => Boolean(targetSignature), // Check if targetSignature is provided
          then: (schema) =>
            schema.test(
              'matchesTargetSignature',
              'Signature does not match the expected format.',
              (val: string | undefined) =>
                !val || sanitizeQuotes(val).toLowerCase() === sanitizeQuotes(targetSignature!).toLowerCase(),
            ),
        }),
    })
    .defined();
};

export const SignDocumentsSchema = generateSignDocumentsSchema({});

export type SignDocumentsSchema = yup.InferType<typeof SignDocumentsSchema>;
