export class StringUtils {
  static isBlank(s?: string | null) {
    if (!s || typeof s === 'undefined' || s === undefined || s === null || s === '') {
      return true;
    }

    return s.trim().length === 0;
  }

  static isNotBlank(str?: string | null): str is string {
    return !StringUtils.isBlank(str);
  }

  static trimToUndefined(s?: string | null) {
    if (s === undefined) {
      return undefined;
    }
    if (s === null) {
      return undefined;
    }
    const trimmed = s.trim();
    if (trimmed.length === 0) {
      return undefined;
    }
    return trimmed;
  }

  static trimToEmpty(s?: string | null) {
    if (s === undefined) {
      return '';
    }
    if (s === null) {
      return '';
    }
    const trimmed = s.trim();
    if (trimmed.length === 0) {
      return '';
    }
    return trimmed;
  }

  static capitalize(s?: string | null) {
    const trimmed = this.trimToEmpty(s);
    if (trimmed.length === 0) {
      return '';
    }
    return trimmed
      .split(/[ _]+/)
      .map((value: string) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase())
      .join(' ');
  }

  static stringify(obj: unknown) {
    let cache: unknown[] | null = [];
    const jsonString = JSON.stringify(obj, function (_, value) {
      if (typeof value === 'object' && value !== null && cache !== null) {
        if (cache.indexOf(value) !== -1) {
          // Duplicate reference found
          try {
            // If this value does not reference a parent it can be deduped
            return JSON.parse(JSON.stringify(value));
          } catch (error) {
            // discard key if value cannot be deduped
            return;
          }
        }
        // Store value in our collection
        cache.push(value);
      }

      return value;
    });

    cache = null;
    return jsonString;
  }
}
