import { QueryKey } from '@tanstack/react-query';

import { OfferingCid, OfferingDocumentId, OfferingId, OfferingIdOrShortName } from '@arrived/models';

export const offeringsKey = 'offerings';

export const offeringsKeyFn = (params?: object): QueryKey =>
  ([offeringsKey, 'all'] as readonly unknown[]).concat(params ? [params] : []);
export const offeringsInfiniteKeyFn = (params?: object): QueryKey =>
  ([offeringsKey, 'all', 'infinite'] as readonly unknown[]).concat(params ? [params] : []);
export const offeringKeyFn = (offeringId: OfferingIdOrShortName): QueryKey =>
  ([offeringsKey] as readonly unknown[]).concat([offeringId]);

export const offeringDividendKeyFn = (offeringId: OfferingIdOrShortName): QueryKey =>
  (offeringKeyFn(offeringId) as readonly unknown[]).concat(['dividends']);

export const offeringSharePricesKeyFn = (offeringId: OfferingIdOrShortName): QueryKey =>
  (offeringKeyFn(offeringId) as readonly unknown[]).concat(['sharePrices']);

export const offeringDocumentsKeyFn = (offeringId: OfferingId): QueryKey => [offeringsKey, offeringId, 'documents'];
export const offeringDocumentRawKeyFn = (offeringDocumentId: OfferingDocumentId): QueryKey => [
  offeringsKey,
  'documents',
  offeringDocumentId,
  'raw',
];

export const offeringPhotosKeyFn = (
  offeringCid: OfferingCid,
  params: { includeUrls?: boolean; includePositions?: boolean },
): QueryKey => [
  offeringsKey,
  offeringCid,
  'photos',
  { includeUrls: params.includeUrls ?? false, includePositions: params.includePositions ?? false },
];

export const OFFERINGS_SORT_ID_ASC = 'id:asc';
export const OFFERINGS_SORT_ID_DESC = 'id:desc';
export const OFFERINGS_SORT_PROPERTY_RANK_ASC = 'propertyRank:asc';
export const OFFERINGS_SORT_PROPERTY_RANK_DESC = 'propertyRank:desc';
export type OfferingQuerySort =
  | typeof OFFERINGS_SORT_ID_ASC
  | typeof OFFERINGS_SORT_ID_DESC
  | typeof OFFERINGS_SORT_PROPERTY_RANK_ASC
  | typeof OFFERINGS_SORT_PROPERTY_RANK_DESC;
