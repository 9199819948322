import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchOffering } from '@arrived/api_v2';
import { Offering } from '@arrived/models';
import { offeringKeyFn, offeringsKeyFn } from '@arrived/queries';

export function usePatchOfferingMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchOffering,
    onSuccess: (data) => {
      queryClient.setQueryData(offeringKeyFn(data.id), data);
      queryClient.setQueryData(offeringKeyFn(data.shortName), data);
      queryClient.setQueryData(
        offeringsKeyFn(),
        (offerings: Offering[] | undefined) =>
          offerings?.map((offering) => (offering.id === data.id ? data : offering)) ?? [],
      );

      queryClient.invalidateQueries({ queryKey: offeringsKeyFn() });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.id) });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.shortName) });
    },
  });
}
