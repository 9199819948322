import { useMutation, useQueryClient } from '@tanstack/react-query';
import update from 'immutability-helper';

import { patchQuestionnaire } from '@arrived/api_v2';
import { Questionnaire } from '@arrived/models';

import { questionnaireKeyFn, questionnairesKey } from './questionnaires.keys';

export function usePatchQuestionnaireMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchQuestionnaire,
    onSuccess: (data) => {
      queryClient.setQueryData<Questionnaire>(questionnaireKeyFn(data.uuid), data);
      queryClient.setQueryData<Questionnaire[]>(questionnairesKey, (questionnaires) => {
        const index = (questionnaires ?? []).findIndex((questionnaire) => questionnaire.uuid !== data.uuid);

        if (index < 0) {
          return questionnaires;
        }

        // Replaces the previous version of the questionnaire with the new version in the cached array
        return update(questionnaires, {
          $splice: [
            [index, 1],
            [index, 0, data],
          ],
        });
      });

      queryClient.invalidateQueries({ queryKey: questionnaireKeyFn(data.uuid) });
      queryClient.invalidateQueries({ queryKey: questionnairesKey });
    },
  });
}
