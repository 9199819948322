import { useId, useLayoutEffect } from 'react';

import { styled } from '@tamagui/core';
import { ScrollView } from '@tamagui/scroll-view';

import { useModalContext } from './ModalContext';

const ModalBodyMain = styled(ScrollView, {
  name: 'ModalBody',
  '$platform-web': {
    flex: 1,
    pb: 1,
    maxHeight: 300,
    overflow: 'auto' as 'scroll',
  },
});

export const ModalBody = ModalBodyMain.styleable((props, ref) => {
  const { setDescriptionId } = useModalContext();
  const id = useId();

  useLayoutEffect(() => {
    setDescriptionId(id);
    return () => setDescriptionId(undefined);
  }, [id, setDescriptionId]);

  return <ModalBodyMain ref={ref} {...props} id={id} />;
});
