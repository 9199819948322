import { useQuery } from '@tanstack/react-query';

import { getUserById } from '@arrived/api_v2';
import { UserId } from '@arrived/models';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { adminUserKeyFn } from './users.keys';

export function useAdminGetUserQuery(userId?: UserId, options?: UseAwaitedQueryOptions<typeof getUserById>) {
  return useQuery({
    queryKey: adminUserKeyFn(userId!),
    queryFn: () => getUserById(userId!),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
    enabled: (options?.enabled ?? true) && userId != null,
  });
}
