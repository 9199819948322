import { FC } from 'react';

import { Callout, NavItem, NavItemType, Resource, RootTextProps } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';

import { BuilderNavItem } from '../models';

/**
 * Takes a builder path (represented by a string) and converts it to a resource for rendering in
 * the navigation bar.
 *
 * The builder path will either be absolute (i.e. https://arrived.com/...) or relative. If it is
 * relative the platform should uset the path to determine how to handle the link appropriately
 * based on the application.
 */
export type ConvertBuilderPath = (path: string) => Pick<Resource, 'isExternal' | 'link'>;

/**
 * This function should be used by consumers of the builder nav items that are trying to render
 * them in a bricks navigation bar. It converts the shape of the BuilderNavItem into the NavItem
 * object that is rendered in the bricks navigation bar.
 *
 * @param builderNavItem the item coming from Builder that needs to be converted.
 * @param convertBuilderPath converts a path (in the form of a string) from builder into a
 * resource that can be used to render a navigational item in the nav bar.
 * @returns the builder nav item converted into one that can be passed to be rendered in the bricks
 * navigation bar.
 */
export const convertBuilderNavItem = (
  { callout, groups, link, ...rest }: BuilderNavItem,
  convertBuilderPath: ConvertBuilderPath,
): NavItem => ({
  Highlight: callout
    ? () => (
        <Callout hideCloseIcon variant="inverted" mt="auto" $gtXs={{ maxWidth: 450 }}>
          <Callout.Content>
            <Markdown Text={Callout.Content.Text}>{callout}</Markdown>
          </Callout.Content>
        </Callout>
      )
    : undefined,
  type: NavItemType.SECTION,
  groups: groups.map(({ content, label, path, resourceLists }) => ({
    Content: ({ Text }: { Text: FC<RootTextProps> }) => <Markdown Text={Text}>{content}</Markdown>,
    resource: {
      label,
      ...convertBuilderPath(path),
    },
    resourceLists: resourceLists.map(({ header, resources }) => ({
      header,
      resources: resources.map(({ label, path }) => ({
        label,
        ...convertBuilderPath(path),
      })),
    })),
  })),
  ...convertBuilderPath(link),
  ...rest,
});
