import { HistoricalReturnsDataAPIResponse } from '@arrived/models';

import { historicalReturnsApi } from './api';
import { createQuery } from './create-query';

export const getHistoricalReturnsData = () =>
  createQuery<HistoricalReturnsDataAPIResponse>({
    apiInstance: historicalReturnsApi,
    method: 'get',
    url: '/historical-returns',
  });
