import { useCallback, useEffect, useState } from 'react';

import { EVENTS, useIdentify, useTrack, useUserTraits } from '@arrived/analytics';
import { InvestorArchetype, UserId } from '@arrived/models';

export const useTrackArchetype = () => {
  const track = useTrack();
  const userTraits = useUserTraits();
  const identify = useIdentify();
  const [hasArchetypeIdentified, setHasArchetypeIdentified] = useState(false);

  useEffect(() => {
    userTraits().then((traits) => {
      setHasArchetypeIdentified(traits?.archetype !== undefined);
    });
  }, [userTraits]);

  return useCallback(
    (archetype: InvestorArchetype) => {
      if (!hasArchetypeIdentified) {
        switch (archetype) {
          case InvestorArchetype.WSJ:
            track(EVENTS.WSJ_ARCHETYPE, {});
            identify({ archetype: InvestorArchetype.WSJ });
            break;
          case InvestorArchetype.REBEL:
            track(EVENTS.REBEL_ARCHETYPE, {});
            identify({ archetype: InvestorArchetype.REBEL });
            break;
          case InvestorArchetype.NEWBIE:
            track(EVENTS.NEWBIE_ARCHETYPE, {});
            identify({ archetype: InvestorArchetype.NEWBIE });
            break;
          default:
            break;
        }
      }
    },
    [track, identify, hasArchetypeIdentified]
  );
};

export const useTrackAccreditedConversionEvent = () => {
  const track = useTrack();
  const userTraits = useUserTraits();
  const identify = useIdentify();
  const [hasAccreditedIdentified, setHasAccreditedIdentified] = useState(false);

  useEffect(() => {
    userTraits().then((traits) => {
      setHasAccreditedIdentified(traits?.has_accredited_identified !== undefined);
    });
  }, [userTraits]);

  return useCallback(
    ({ userId }: { userId?: UserId }) => {
      if (!hasAccreditedIdentified) {
        track(EVENTS.ACCREDITED_INVESTOR_IDENTIFIED, {
          userId,
        });
        identify({ has_accredited_identified: true });
      }
    },
    [hasAccreditedIdentified, userTraits, track, identify]
  );
};
