import { useMemo } from 'react';

import { Button as BricksButton, ButtonProps as BricksButtonProps, isWeb } from '@arrived/bricks';
import { UseLinkProps, useLink } from '@arrived/router';

import { BuilderBlock, BuilderDataProps, GenericBuilderIcons, RegisteredComponent } from '../../sdk';

type ButtonProps = {
  text: string;
  linkProps: UseLinkProps & {
    openInNewTab?: boolean;
  };
  fullWidth?: boolean;
  hide?: boolean;
  /**
   * Additional attributes to add to the button element from
   * `noWrap` within input options.
   */
  attributes?: Record<string, string>;
} & BricksButtonProps &
  BuilderDataProps;

export const Button = ({
  text,
  linkProps = {
    href: '/',
    replace: false,
    experimental: undefined,
  },
  fullWidth,
  hide,
  attributes,
  variant = 'default',
  builderBlock: _,
  builderContext: __,
  ...rest
}: ButtonProps) => {
  const flattenedHref = useMemo(() => {
    // This is only for native routers, we don't want to flatten the href for web
    if (isWeb) {
      return linkProps.href;
    }

    // We only want to do this for Arrived links, external links will open the external browser
    // so instead of `https://arrived.com/properties?...` we want to just return `/properties?...`
    if (typeof linkProps.href === 'string' && linkProps.href.includes('arrived.com')) {
      const urlObj = new URL(linkProps.href);
      return `${urlObj.pathname}${urlObj.search}`;
    }

    return linkProps.href;
  }, [linkProps.href]);

  const shouldOpenInNewTab = useMemo(() => {
    if (linkProps.openInNewTab !== undefined) {
      return linkProps.openInNewTab;
    }

    // If we are not explicitly setting openInNewTab, we should default to true if the href is not an Arrived.com link
    if (typeof linkProps.href === 'string' && !linkProps.href.includes('arrived.com')) {
      return true;
    }

    return false;
  }, [linkProps]);

  const useLinkProps = useLink({
    ...linkProps,
    href: flattenedHref,
  });

  return (
    <BricksButton
      {...rest}
      {...attributes}
      {...useLinkProps}
      tag="a"
      variant={variant}
      w={fullWidth ? '100%' : 'unset'}
      display={hide ? 'none' : 'flex'} // By default, set it to flex for the `a` tag to center text properly
      aria-label={text}
      aria-hidden={hide}
      target={shouldOpenInNewTab ? '_blank' : undefined}
      rel={shouldOpenInNewTab ? 'noopener noreferrer' : undefined}
    >
      {text}
    </BricksButton>
  );
};

export const ButtonBlockElement = (options?: Partial<ButtonProps>) =>
  ({
    '@type': '@builder.io/sdk:Element',
    component: {
      name: 'Core:Button',
      options: {
        text: 'CTA',
        ...options,
      },
    },
  }) satisfies BuilderBlock;

Button.registerComponent = {
  component: Button,
  // Overwrite the default name of the component
  name: 'Core:Button',
  image: GenericBuilderIcons.Touch,
  friendlyName: 'Button',
  canHaveChildren: false,
  static: true,
  noWrap: true,
  inputs: [
    {
      name: 'text',
      friendlyName: 'Text',
      required: true,
      defaultValue: 'Click me',
      type: 'text',
    },
    {
      name: 'linkProps',
      friendlyName: 'Link properties',
      helperText:
        'Properties related to the link, such as the URL and native navigation behavior. By default, we only need to worry about the URL but you can customize the behavior with the advanced options.',
      type: 'object',
      defaultValue: {
        href: '/',
        replace: false,
        experimental: undefined,
        openInNewTab: false,
      },
      subFields: [
        {
          name: 'href',
          type: 'longText',
          friendlyName: 'Link',
          required: true,
          helperText:
            'You can use relative URLs like "/page" or absolute URLs like "https://builder.io" for external sites.',
        },
        {
          name: 'openInNewTab',
          type: 'boolean',
          friendlyName: 'Open in new tab',
          helperText:
            'If true, the link will open in a new tab for relative URLs (/properties). If you are using an absolute URL (https://builder.io), the link will always open in a new tab.',
        },
        {
          name: 'as',
          type: 'text',
          advanced: true,
          helperText:
            'Optional decorator for the URL. It allows you to use a custom URL format while keeping the actual route path hidden.',
        },
        {
          name: 'shallow',
          type: 'boolean',
          advanced: true,
          helperText:
            'If true, changes the URL without running data fetching methods again, preserving the current state.',
        },
        {
          name: 'scroll',
          type: 'boolean',
          defaultValue: true,
          advanced: true,
          helperText: 'If true, the page will scroll to the top after navigation. Defaults to true.',
        },
        {
          name: 'replace',
          type: 'boolean',
          advanced: true,
          helperText: 'If true, replace the current history state instead of adding a new URL to the stack.',
        },
        {
          name: 'experimental',
          type: 'object',
          advanced: true,
          defaultValue: undefined,
          required: false,
          helperText: 'Experimental options for advanced use cases.',
          subFields: [
            {
              name: 'nativeBehavior',
              type: 'text',
              advanced: true,
              defaultValue: 'stack-replace',
              helperText: 'Native behavior for the link.',
            },
            {
              name: 'isNestedNavigator',
              type: 'boolean',
              advanced: true,
              helperText: 'Whether the link is nested in a navigator.',
            },
          ],
        },
      ],
    },
    {
      name: 'variant',
      friendlyName: 'Variant',
      type: 'text',
      defaultValue: 'default',
      enum: [
        {
          label: 'Default',
          value: 'default',
        },
        {
          label: 'Ghost',
          value: 'ghost',
        },
        {
          label: 'Emphasized',
          value: 'emphasized',
        },
        {
          label: 'Outlined',
          value: 'outlined',
        },
        {
          label: 'Destructive',
          value: 'destructive',
        },
      ],
    },

    {
      name: 'condensed',
      friendlyName: 'Condensed',
      type: 'boolean',
    },
    {
      name: 'fullWidth',
      friendlyName: 'Full Width',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Forces the button to take up the full width of its container.',
    },
    {
      name: 'inverted',
      friendlyName: 'Inverted',
      type: 'boolean',
    },
    {
      name: 'loading',
      friendlyName: 'Loading',
      type: 'boolean',
    },
    {
      name: 'disabled',
      friendlyName: 'Disabled',
      type: 'boolean',
    },
    {
      name: 'hide',
      friendlyName: 'Hide',
      helperText: 'Hides the button by setting the display to none',
      type: 'boolean',
    },
  ],
} satisfies RegisteredComponent;
