import { useQuery } from '@tanstack/react-query';

import { getAirDnaAverageDailyRateByOffering } from '@arrived/api_v2';
import { OfferingId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { airDnaAverageDailyRateKeyFn } from './airDna.keys';

export function useGetAirDnaAverageDailyRateByOffering(
  offeringId: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getAirDnaAverageDailyRateByOffering>,
) {
  return useQuery({
    queryKey: airDnaAverageDailyRateKeyFn(offeringId),
    queryFn: () => getAirDnaAverageDailyRateByOffering({ offeringId }),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
