import { useQuery } from '@tanstack/react-query';

import { getAccountPropertyOccupancy } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../../utils';
import { propertyOccupancyKeyFn } from '../accounts.keys';

export function useGetAccountPropertyOccupancyQuery(
  accountId?: AccountId,
  options?: UseAwaitedQueryOptions<typeof getAccountPropertyOccupancy>,
) {
  return useQuery({
    queryKey: propertyOccupancyKeyFn(accountId!),
    queryFn: () => getAccountPropertyOccupancy(accountId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}
