import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { MAX_STALE_TIME, useGetPrimaryAccountQuery } from '@arrived/queries';

import { useExternalBankAccount } from '../queries';
import { Transaction } from '../types';
import { getShortenedExternalBankName, isCashAccount, isExternalBankAccount } from '../utils';

export const useTransactionSources = (transaction: Transaction) => {
  const intl = useIntl();
  // Stale time avoids aggressive refetching within table rows
  const primaryAccountState = useGetPrimaryAccountQuery({ staleTime: MAX_STALE_TIME });

  const sourceAccount = useMemo(() => {
    let type;
    const entry = transaction.entries.find((entry) => entry.direction === 'DEBIT');
    if (isCashAccount(entry?.targetAccountCid)) type = 'CASH';
    if (isExternalBankAccount(entry?.targetAccountCid)) type = 'EXTERNAL';
    return {
      cid: entry?.targetAccountCid,
      type,
    };
  }, [transaction]);

  const destinationAccount = useMemo(() => {
    let type;
    const entry = transaction.entries.find((entry) => entry.direction === 'CREDIT');
    if (isCashAccount(entry?.targetAccountCid)) type = 'CASH';
    if (isExternalBankAccount(entry?.targetAccountCid)) type = 'EXTERNAL';
    return {
      cid: entry?.targetAccountCid,
      type,
    };
  }, [transaction]);
  const sourceAccountState = useExternalBankAccount(sourceAccount.cid, { enabled: sourceAccount.type === 'EXTERNAL' });
  const destinationAccountState = useExternalBankAccount(destinationAccount.cid, {
    enabled: destinationAccount.type === 'EXTERNAL',
  });

  const sourceAccountName = useMemo(() => {
    if (primaryAccountState.data) {
      if (sourceAccount.type === 'CASH' && primaryAccountState.data.cashAccountCid === sourceAccount.cid) {
        return intl.formatMessage({ id: 'cashAccounts.transaction.wallet' });
      }

      if (sourceAccount.type === 'EXTERNAL' && sourceAccountState.data) {
        return getShortenedExternalBankName(sourceAccountState.data.name);
      }
    }
  }, [sourceAccountState.data, sourceAccount, primaryAccountState, intl]);

  const destinationAccountName = useMemo(() => {
    if (primaryAccountState.data) {
      if (destinationAccount.type === 'CASH' && primaryAccountState.data.cashAccountCid === destinationAccount.cid) {
        return intl.formatMessage({ id: 'cashAccounts.transaction.wallet' });
      }

      if (destinationAccount.type === 'EXTERNAL' && destinationAccountState.data) {
        return getShortenedExternalBankName(destinationAccountState.data.name);
      }
    }
  }, [destinationAccountState.data, destinationAccount, primaryAccountState, intl]);

  return useMemo(
    () => ({
      source: {
        name: sourceAccountName,
        state: sourceAccountState,
        ...sourceAccount,
      },
      destination: {
        name: destinationAccountName,
        state: destinationAccountState,
        ...destinationAccount,
      },
    }),
    [
      sourceAccount,
      sourceAccountName,
      destinationAccount,
      destinationAccountName,
      sourceAccountState,
      destinationAccountState,
    ],
  );
};
