import { useMemo } from 'react';

import { useGetPrimaryAccountQuery } from '@arrived/queries';

export const useInitialsForAgreements = () => {
  const primaryAccountState = useGetPrimaryAccountQuery();
  const userInitials = useMemo(() => {
    if (
      primaryAccountState.data?.primaryUser &&
      primaryAccountState.data?.primaryUser.firstName &&
      primaryAccountState.data?.primaryUser.lastName
    ) {
      return (
        primaryAccountState.data?.primaryUser.firstName.charAt(0).toUpperCase() +
        primaryAccountState.data?.primaryUser.lastName.charAt(0).toUpperCase()
      );
    }
  }, [primaryAccountState.data?.primaryUser?.firstName, primaryAccountState.data?.primaryUser?.lastName]);
  return userInitials;
};
