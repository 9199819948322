import { useCallback } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { FormattedMessage, FormattedNumber } from 'react-intl';
import Animated, { useAnimatedStyle, useSharedValue } from 'react-native-reanimated';

import { LinearGradient, Stack, UtilityText, ValueText, styled, useProgressBarAnimation } from '@arrived/bricks';
import { Offering } from '@arrived/models';

type LeverageAmountBarProps = { offering: Offering };

const AnimatedStack = Animated.createAnimatedComponent(Stack);

const LabelText = styled(UtilityText, { token: 'utility.helper.small', color: '$onSurface.neutral.muted' });

export const LeverageAmountBar = ({ offering }: LeverageAmountBarProps) => {
  const valueWidth = useSharedValue(0);
  const indicatorWidth = useSharedValue(0);

  const [_, setProgressContainerWidth, progressValue] = useProgressBarAnimation(offering.debtPercent);

  const valueStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateX: progressValue.value + valueWidth.value / 2,
        },
      ],
    }),
    [progressValue, valueWidth],
  );

  const indicatorStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateX: progressValue.value + indicatorWidth.value / 2,
        },
      ],
    }),
    [indicatorWidth, progressValue],
  );

  const onValueLayout = useCallback((event: LayoutChangeEvent) => {
    valueWidth.value = event.nativeEvent.layout.width;
  }, []);

  const onIndicatorLayout = useCallback((event: LayoutChangeEvent) => {
    indicatorWidth.value = event.nativeEvent.layout.width;
  }, []);

  return (
    <Stack gap="$1">
      <Stack mx="$4" onLayout={setProgressContainerWidth}>
        <AnimatedStack centered alignSelf="flex-end" onLayout={onValueLayout} style={valueStyle}>
          <ValueText token="value.large">
            <FormattedNumber
              value={offering.debtPercent / 100}
              maximumFractionDigits={1}
              minimumFractionDigits={0}
              style="percent"
            />
          </ValueText>
        </AnimatedStack>
      </Stack>
      <Stack h="$3" justifyContent="center" position="relative">
        <Stack width="100%" h="$2" br="$full" overflow="hidden">
          <LinearGradient
            width="100%"
            height="100%"
            colors={['$interactive.positive.rested', '$onSurface.primary.decorative']}
            start={[1, 0.5]}
            end={[0, 0.5]}
          />
        </Stack>
        <Stack position="absolute" h="100%" left={0} right={0} mx="$4">
          <AnimatedStack centered alignSelf="flex-end" onLayout={onIndicatorLayout} style={indicatorStyle}>
            <Stack bg="$onSurface.neutral.default" h="$3" w="$1" br="$full" />
          </AnimatedStack>
        </Stack>
      </Stack>
      <Stack row justifyContent="space-between">
        <LabelText>
          <FormattedMessage id="product-details.property-equity-volatility.lower" />
        </LabelText>
        <LabelText>
          <FormattedMessage id="product-details.property-equity-volatility.higher" />
        </LabelText>
      </Stack>
    </Stack>
  );
};
