import { forwardRef } from 'react';

import { FormattedMessage } from 'react-intl';

import { LEASE_STATUS_MESSAGES_STRINGS, LeaseStatus } from '@arrived/models';

export interface LeaseStatusMessageProps {
  className?: string;
  leaseStatus?: LeaseStatus;
  showTooltip?: boolean;
}

export const LeaseStatusMessage = forwardRef<HTMLSpanElement, LeaseStatusMessageProps>(
  ({ className, leaseStatus, ...rest }, ref) => {
    return (
      <span className={className} ref={ref} {...rest}>
        {getLeaseStatusMessage(leaseStatus)}
      </span>
    );
  },
);

const getLeaseStatusMessage = (status: LeaseStatus | undefined) => {
  if (!status) {
    return <>N/A</>;
  }
  const displayStrings = LEASE_STATUS_MESSAGES_STRINGS[status].display;
  return <FormattedMessage id={displayStrings.id} />;
};
