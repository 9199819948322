import { useMutation } from '@tanstack/react-query';

import { postSubscribeToNewPropertyNotifications } from '@arrived/api_v2';
import { UserEmail } from '@arrived/models';

export interface EmailSubscribeMutationRequest {
  email: UserEmail;
}

export function useEmailSubscribeMutation() {
  return useMutation({
    mutationFn: postSubscribeToNewPropertyNotifications,
  });
}
