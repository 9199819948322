import { FormattedMessage } from 'react-intl';

import { BodyText, Image, Stack } from '@arrived/bricks';
import { Constants, getCDNOptimizedImage } from '@arrived/common';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { ProductDetailContent } from '@arrived/features-product-details';
import { Offering, PropertyStatusHistory } from '@arrived/models';

import { OfferingHistoryButton } from '../../../offeringHistory/OfferingHistoryButton';
import { useProductDetailsViewContext } from '../../ProductDetailsViewContext';

interface OfferingHistorySectionProps {
  offering: Offering;
  events?: PropertyStatusHistory[][];
  isDefaultOpen?: boolean;
}
export const OfferingHistorySection = ({ offering, events }: OfferingHistorySectionProps) => {
  const isOfferingHistoryEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.OFFERING_HISTORY);
  const { isHistoryOpen } = useProductDetailsViewContext();

  return (
    <>
      {isOfferingHistoryEnabled && (
        <ProductDetailContent>
          <ProductDetailContent.Header>
            <FormattedMessage id="common.latest-updates" />
          </ProductDetailContent.Header>
          <ProductDetailContent.Body>
            {events?.[0]?.[0] ? (
              <OfferingHistoryButton offering={offering} defaultIsOpen={isHistoryOpen} />
            ) : (
              <Stack justifyContent="center" alignItems="center">
                <Image
                  source={{
                    uri: getCDNOptimizedImage({
                      imgUrl: `${Constants.awsCdnUrl}/images/pdp/debt-repayment.png`,
                      ratio: '1:1',
                      width: '280',
                    }),
                  }}
                  width={140}
                  height={140}
                />
                <BodyText token="body.compact.small">
                  <FormattedMessage id="offering-history.no-events" />
                </BodyText>
              </Stack>
            )}
          </ProductDetailContent.Body>
        </ProductDetailContent>
      )}
    </>
  );
};
