export enum FundErrorStatus {
  R01_INSUFFICIENT_FUNDS = 'R01_INSUFFICIENT_FUNDS',
  R02_BANK_ACCOUNT_CLOSED = 'R02_BANK_ACCOUNT_CLOSED',
  R03_BANK_NOTE_FOUND = 'R03_BANK_NOTE_FOUND',
  R04_INVALID_BANK_ACCOUNT = 'R04_INVALID_BANK_ACCOUNT',
  R05_UNAUTHORIZED_DEBIT_ENTRY = 'R05_UNAUTHORIZED_DEBIT_ENTRY',
  R06_RETURNED_PER_ODFI_REQUEST = 'R06_RETURNED_PER_ODFI_REQUEST',
  R07_AUTHORIZATION_REVOKED_BY_CUSTOMER = 'R07_AUTHORIZATION_REVOKED_BY_CUSTOMER',
  R08_PAYMENT_STOPPED = 'R08_PAYMENT_STOPPED',
  R09_UNCOLLECTED_FUNDS = 'R09_UNCOLLECTED_FUNDS',
  R10_CUSTOMER_ADVISES_NOT_AUTHORIZED = 'R10_CUSTOMER_ADVISES_NOT_AUTHORIZED',
  R11_CHECK_TRUNCATION_ENTRY_RETURN = 'R11_CHECK_TRUNCATION_ENTRY_RETURN',
  R12_BRANCH_SOLD_TO_ANOTHER_RDFI = 'R12_BRANCH_SOLD_TO_ANOTHER_RDFI',
  R13_RDFI_NOT_QUALIFIED_TO_PARTICIPATE = 'R13_RDFI_NOT_QUALIFIED_TO_PARTICIPATE',
  R14_REPRESENTATIVE_PAYEE_DECEASED_OR_UNABLE_TO_CONTINUE_IN_THE_CAPACITY = 'R14_REPRESENTATIVE_PAYEE_DECEASED_OR_UNABLE_TO_CONTINUE_IN_THE_CAPACITY',
  R15_BENEFICIARY_OR_BANK_ACCOUNT_HOLDER = 'R15_BENEFICIARY_OR_BANK_ACCOUNT_HOLDER',
  R16_BANK_ACCOUNT_FROZEN = 'R16_BANK_ACCOUNT_FROZEN',
  R17_FILE_RECORD_EDIT_CRITERIA = 'R17_FILE_RECORD_EDIT_CRITERIA',
  R18_IMPROPER_EFFECTIVE_ENTRY_DATE = 'R18_IMPROPER_EFFECTIVE_ENTRY_DATE',
  R19_AMOUNT_FIELD_ERROR = 'R19_AMOUNT_FIELD_ERROR',
  R20_NON_PAYMENT_BANK_ACCOUNT = 'R20_NON_PAYMENT_BANK_ACCOUNT',
  R21_INVALID_COMPANY_ID_NUMBER = 'R21_INVALID_COMPANY_ID_NUMBER',
  R22_INVALID_INDIVIDUAL_ID_NUMBER = 'R22_INVALID_INDIVIDUAL_ID_NUMBER',
  R23_CREDIT_ENTRY_REFUSED_BY_RECEIVER = 'R23_CREDIT_ENTRY_REFUSED_BY_RECEIVER',
  R24_DUPLICATED_ENTRY = 'R24_DUPLICATED_ENTRY',
  R25_ADDENDA_ERROR = 'R25_ADDENDA_ERROR',
  R26_MANDATORY_FIELD_ERROR = 'R26_MANDATORY_FIELD_ERROR',
  R27_TRACE_NUMBER_ERROR = 'R27_TRACE_NUMBER_ERROR',
  R28_TRANSIT_ROUTING_NUMBER_CHECK_DIGIT_ERROR = 'R28_TRANSIT_ROUTING_NUMBER_CHECK_DIGIT_ERROR',
  R29_CORPORATE_CUSTOMER_ADVISES_NOT_AUTHORIZED = 'R29_CORPORATE_CUSTOMER_ADVISES_NOT_AUTHORIZED',
  R30_RDFI_NOT_PARTICIPANT_IN_CHECK_TRUNCATION_PROGRAM = 'R30_RDFI_NOT_PARTICIPANT_IN_CHECK_TRUNCATION_PROGRAM',
  R31_PERMISSIBLE_RETURN_ENTRY_CCD_AND_CTX_ONLY = 'R31_PERMISSIBLE_RETURN_ENTRY_CCD_AND_CTX_ONLY',
  R32_RDFI_NOT_SETTLEMENT = 'R32_RDFI_NOT_SETTLEMENT',
  R33_RETURN_OF_XCK_ENTRY = 'R33_RETURN_OF_XCK_ENTRY',
  R34_LIMITED_PARTICIPATION_RDFI = 'R34_LIMITED_PARTICIPATION_RDFI',
  R35_RETURN_OF_IMPROPER_DEBIT_ENTRY = 'R35_RETURN_OF_IMPROPER_DEBIT_ENTRY',
}
