import { PropsWithChildren } from 'react';

import { FormattedMessage } from 'react-intl';

import { ProductDetailContent, ProductDetailContentProps } from '../../../content';
import { CommonQuestions, CommonQuestionsProps } from '../commonQuestions';

export type CommonQuestionsSectionProps = ProductDetailContentProps & { questions: CommonQuestionsProps['questions'] };

export const CommonQuestionsSection = ({
  children,
  questions,
  ...rest
}: PropsWithChildren<CommonQuestionsSectionProps>) => (
  <ProductDetailContent {...rest}>
    <ProductDetailContent.Header>
      <FormattedMessage id="product-details.common-questions" />
    </ProductDetailContent.Header>
    <ProductDetailContent.Body>
      <CommonQuestions questions={questions} />
      {children}
    </ProductDetailContent.Body>
  </ProductDetailContent>
);
