import { ReactNode, useMemo } from 'react';

import { CssBaseline, ThemeProvider as DefaultMuiThemeProvider, createTheme } from '@mui/material';

import { MuiTheme } from './theme/MuiTheme';

interface ThemeProps {
  mode?: 'light' | 'dark';
  children: ReactNode;
  overrides?: Record<string, unknown>;
}

/**
 * Use this to overwrite any MUI styles to match our theme
 * Add type overrides to mui.d.ts
 */
export const MuiThemeProvider = ({ children, mode = 'light' }: ThemeProps) => {
  const theme = useMemo(
    () =>
      createTheme(MuiTheme, {
        palette: {
          mode,
          ...(mode === 'light'
            ? {
                text: {
                  primary: MuiTheme.palette.grey[900],
                  secondary: MuiTheme.palette.grey[800],
                },
              }
            : {
                background: {
                  default: MuiTheme.palette.neutrals.gray,
                  paper: MuiTheme.palette.neutrals.gray,
                },
                text: {
                  primary: '#fff',
                  secondary: MuiTheme.palette.grey[500],
                },
              }),
        },
      }),
    [mode],
  );

  return (
    <DefaultMuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </DefaultMuiThemeProvider>
  );
};
