import { borderWidths } from './borders';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { gradientTokens, nativeGradients, webGradients } from './gradients';
import { opacity } from './opacity';
import { radii } from './radius';
import { sizes } from './sizes';
import { space } from './space';
import { DeepWriteable } from './types';
import { typography } from './typography';
import { zIndices } from './zIndices';

export const theme = {
  borderWidths,
  breakpoints,
  colors, // General color palette
  gradients: gradientTokens as DeepWriteable<typeof gradientTokens>, // Gradients using colors
  nativeGradients, // For NativeBase
  webGradients, // For MUI
  radii,
  ...typography,
  sizes,
  space,
  opacity,
  zIndices,
} as const;

export const themePropertyMap = {
  borderRadius: 'radii',
  color: 'colors',
  gradients: 'gradients',
  palette: 'palette',
  letterSpacing: 'letterSpacings',
  lineHeight: 'lineHeights',
  fontFamily: 'fonts',
  fontSize: 'fontSizes',
  fontWeight: 'fontWeights',
  sizes: 'sizes',
  space: 'space',
  border: 'borders',
  shadow: 'shadows',
  zIndex: 'zIndex',
} as const;

export { createTypographyVariants, typographyHeadings } from './typography';

export type ThemeTokens = typeof theme;

export default theme;
