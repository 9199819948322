import { useEffect } from 'react';

import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

import { getOffering } from '@arrived/api_v2';
import { OfferingIdOrShortName } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';

import { offeringKeyFn } from './offerings.keys';

export function useOfferingQuery(
  offeringId?: OfferingIdOrShortName,
  options?: UseAwaitedQueryOptions<typeof getOffering>,
) {
  const queryClient = useQueryClient();

  const offeringState = useQuery({
    queryKey: offeringKeyFn(offeringId!),
    queryFn: () => getOffering(offeringId!),
    staleTime: 10_000,
    ...options,
    enabled: (options?.enabled ?? true) && offeringId != null,
    // TODO: UPDATE! The problem described here is fixed with react-query v4
    //  Unfortunately, we can't take this data and insert it into the query cache for the GET /offerings call. The
    //  specific reason is that onSettled and onSuccess both get invoked when the cached value under a key is updated.
    //  Since useOfferingsQuery also inserts data in the query cache for each offering, this means that we get an
    //  infinite cycle of each onSuccess updating the other queries cached value.
  });

  useEffect(() => {
    if (!offeringState.isSuccess) {
      return;
    }

    // If the offering is in a state where it is not yet available, we want to start refetching on an exponential
    // backoff driven interval, so we set the refetchCount ref to have a value of 0.
    if (typeof offeringId === 'string') {
      queryClient.setQueryData(offeringKeyFn(offeringState.data.id), offeringState.data);
    }

    if (typeof offeringId === 'number') {
      queryClient.setQueryData(offeringKeyFn(offeringState.data.shortName), offeringState.data);
    }
  }, [offeringState.isSuccess, offeringState.data]);

  return offeringState;
}

export const fetchOfferingQuery = (queryClient: QueryClient, offeringId: OfferingIdOrShortName) => {
  return queryClient.fetchQuery({
    queryKey: offeringKeyFn(offeringId),
    queryFn: () => getOffering(offeringId),
    staleTime: 10_000,
  });
};
