import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postUserExternalAccount } from '@arrived/api_v2';
import { Account } from '@arrived/models';
import { UseAwaitedMutationOptions } from '@arrived/queries';

import { accountsQueryKeyFn } from '../accounts.keys';

export function useAdminPostExternalAccountMutation(
  options?: UseAwaitedMutationOptions<typeof postUserExternalAccount>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postUserExternalAccount,
    ...options,
    onSuccess: (data, variables, context) => {
      const account = queryClient.getQueryData<Account>(accountsQueryKeyFn(variables.accountId));

      if (account) {
        queryClient.setQueryData(accountsQueryKeyFn(variables.accountId), { ...account, externalAccount: data });
      }

      queryClient.invalidateQueries({ queryKey: accountsQueryKeyFn(variables.accountId) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
