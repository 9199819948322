import { GetProps, VariantSpreadFunction, createStyledContext, isWeb, styled } from '@tamagui/core';

import { Stack } from '../layout';

export interface PillStyledContextType {
  condensed?: boolean;
  /**
   * Only used for pressable implementations of the Pill
   */
  disabled?: boolean;
  iconOnly?: boolean;
  /**
   * Only used for pressable implementation of the Pill
   */
  pressable?: boolean;
  square?: boolean;
}

export const PillStyledContext = createStyledContext<PillStyledContextType>({
  condensed: false,
  disabled: false,
  iconOnly: false,
  pressable: false,
  square: false,
});

const getPillCondensedContainerProps: VariantSpreadFunction<
  GetProps<typeof Stack> & PillStyledContextType,
  boolean | undefined
> = (condensed, { props: { iconOnly, square } }) => {
  let px = '$3';
  if (condensed) {
    px = iconOnly ? '$1' : '$2';
  } else if (iconOnly || square) {
    px = '$2';
  }
  return {
    px,
    py: condensed ? (iconOnly ? '$1' : isWeb ? 'unset' : 0) : undefined,
    minHeight: condensed ? '$5' : undefined,
  };
};

/**
 * Used as the base component for multiple chips. Note that `condensed` needs to be explicitly passed as true or false
 * otherwise some of the styles will not be applied correctly.
 */
export const PillContainer = styled(Stack, {
  name: 'PillContainer',
  context: PillStyledContext,

  px: '$3',
  py: '$2',
  borderRadius: '$full',
  centered: true,
  flexDirection: 'row',
  gap: '$1',

  variants: {
    condensed: getPillCondensedContainerProps,
    disabled: { true: {} },
    iconOnly: {
      true: {
        '$platform-web': {
          height: 'unset',
        },
      },
    },
    pressable: { true: {} },
    square: {
      true: {
        borderRadius: '$1',
      },
    },
  } as const,
});

export type PillContainerProps = GetProps<typeof PillContainer>;
