import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postQuestionnaire } from '@arrived/api_v2';
import { Questionnaire } from '@arrived/models';

import { questionnaireKeyFn, questionnairesKey } from './questionnaires.keys';

export function usePostQuestionnaireMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postQuestionnaire,
    onSuccess: (data) => {
      queryClient.setQueryData<Questionnaire>(questionnaireKeyFn(data.uuid), data);
      queryClient.setQueryData<Questionnaire[]>(questionnairesKey, (questionnaires) => [
        ...(questionnaires ?? []),
        data,
      ]);

      queryClient.invalidateQueries({ queryKey: questionnaireKeyFn(data.uuid) });
      queryClient.invalidateQueries({ queryKey: questionnairesKey });
    },
  });
}
