import { FormattedMessage } from 'react-intl';

import { ProductDetailContent, ProductDetailContentProps } from '../../../content';

export const RentalDataSection = ({ children, ...rest }: ProductDetailContentProps) => (
  <ProductDetailContent {...rest}>
    <ProductDetailContent.Header>
      <FormattedMessage id="product-details.rental-data" />
    </ProductDetailContent.Header>
    <ProductDetailContent.Body>{children}</ProductDetailContent.Body>
  </ProductDetailContent>
);
