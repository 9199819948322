import { ApplyFn, NAV_TYPE, NavItemProps } from '../../navigation.types';

export const handleApplySubItems = (apply: ApplyFn) => {
  return (item: NavItemProps) => {
    if (item.type === NAV_TYPE.MULTIPLE) {
      item.subItems.forEach((item) => apply(item));
    }
    return apply(item);
  };
};
