import { ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

import { Box, BoxProps, Grid, Link, Stack, StackProps, Typography } from '@mui/material';

import { AppreciationIcon, RentalIncomeIcon } from '@arrived/bricks';
import { ROUTES } from '@arrived/common';
import { ArrivedTooltip } from '@arrived/features-tooltips';
import { AssetType, Offering } from '@arrived/models';

import { ReturnsDivider, ReturnsSectionHeaderTypography } from './components';
import { RentalIncomeCalculation } from './rentalIncome';

export interface ReturnsDataSectionProps extends StackProps {
  offering: Offering;
  /**
   * Required for mobile.
   */
  openBrowser?: (url: string) => void;
}

const TopLevelDataSection = (props: StackProps) => (
  <Stack
    alignItems="center"
    direction={{ xs: 'column', md: 'row' }}
    gap={{ xs: 2, md: 3.5 }}
    position="relative"
    {...props}
  />
);

const TopLevelDataBox = (props: BoxProps) => (
  <Box
    bgcolor={({ palette }) => palette.neutrals.platinum}
    borderRadius={1}
    display="flex"
    alignItems="center"
    justifyContent="center"
    flex={{ xs: 'none', md: '0 0 250px' }}
    width={{ xs: '100%', md: 'auto' }}
    minHeight="85px"
    p={2}
    {...props}
  />
);

export const ReturnsDataSection = ({ offering, ...rest }: ReturnsDataSectionProps) => (
  <Stack divider={<ReturnsDivider />} gap={2} {...rest}>
    <Stack gap={2}>
      <ReturnsSectionHeaderTypography>
        {offering.assetType === AssetType.LTR ? (
          <FormattedMessage id="common.single.family.residential" />
        ) : (
          <FormattedMessage id="common.vacation.rental" />
        )}
      </ReturnsSectionHeaderTypography>
      <TopLevelDataSection>
        <TopLevelDataBox gap={0.5}>
          <Stack alignItems="center">
            <RentalIncomeIcon color="$onSurface.neutral.default" size="$10" />
            <Typography variant="subtitle1" fontSize="small" component="div">
              <FormattedMessage id="common.annual-cashflow" />
            </Typography>
          </Stack>
          <Typography variant="h4.semibold" component="div">
            +
          </Typography>
          <Stack alignItems="center">
            <AppreciationIcon color="$onSurface.neutral.default" size="$10" />
            <Typography variant="subtitle1" fontSize="small" component="div">
              <FormattedMessage id="common.appreciation" />
            </Typography>
          </Stack>
        </TopLevelDataBox>
        <Box>
          <Typography variant="body2" component="div">
            <FormattedMessage
              id="property-details.returns-data.return-split"
              values={{
                strong: (parts: ReactNode) => (
                  <Typography component="span" variant="body2" fontWeight="bold">
                    {parts}
                  </Typography>
                ),
                ol: (parts: ReactNode) => <ol>{parts}</ol>,
                li: (parts: ReactNode) => <li>{parts}</li>,
              }}
            />
          </Typography>
        </Box>
      </TopLevelDataSection>
    </Stack>
    <Stack pb={2} gap={2}>
      <Typography variant="h6.semibold" component="h4" sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
        <RentalIncomeIcon color="$onSurface.neutral.default" size="lg" />
        <FormattedMessage id="common.anticipated-annual-cashflow" />
      </Typography>
      <RentalIncomeCalculation offering={offering} />
    </Stack>
    <Box>
      <Typography variant="h6.semibold" component="h4" sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
        <AppreciationIcon color="$onSurface.neutral.default" size="lg" />
        <FormattedMessage id="common.annual-appreciation" />
      </Typography>
      <Grid container columnSpacing={4} rowSpacing={2} pt={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="body2">
            <FormattedMessage
              id={
                offering.assetType === AssetType.LTR
                  ? 'property-details.returns-data.appreciation-description.part-1.sfr'
                  : 'property-details.returns-data.appreciation-description.part-1.vr'
              }
              values={{
                strong: (parts: ReactNode) => (
                  <Typography component="span" variant="body2" fontWeight="bold">
                    {parts}
                  </Typography>
                ),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                yearTooltip: (parts: ReactNode) => (
                  <ArrivedTooltip
                    referencedTextContainerPropOverrides={{ display: 'inline' }}
                    referencedTextPropOverrides={{ variant: 'body2' }}
                    tooltipContent={
                      <FormattedMessage id="property-details.returns-data.appreciation-description.date-range" />
                    }
                  >
                    {parts}
                  </ArrivedTooltip>
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2">
            <FormattedMessage
              id="property-details.returns-data.appreciation-description.part-2"
              values={{
                link: (parts: ReactNode) => (
                  <Link variant="body2" target="_blank" href={ROUTES.public.offeringCircular}>
                    {parts}
                  </Link>
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2">
            <FormattedMessage id="property-details.returns-data.appreciation-description.part-3" />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Stack>
);
