import { UserDocument, UserDocumentId, UserDocumentUploadRequest, UserId } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';
import { buildMultipartFormData, getBlob } from './utils';

// TODO: buildMultipartFormData hasn't been tested
export const postUploadUserDocument = (requestData: UserDocumentUploadRequest) => {
  const { body, headers } = buildMultipartFormData(requestData);

  return createQuery<UserDocument>({
    apiInstance: api,
    method: 'post',
    url: `/users/${requestData.userId}/documents`,
    requestData: body,
    config: {
      headers,
    },
  });
};

export const getUserDocuments = (userId: UserId) =>
  createQuery<UserDocument[]>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/documents`,
  });

export const getUserDocument = (documentId: UserDocumentId) =>
  createQuery<UserDocument>({
    apiInstance: api,
    method: 'get',
    url: `/users/documents/${documentId}`,
  });

export const getUserDocumentRaw = (documentId: UserDocumentId) => getBlob(`/users/documents/${documentId}/raw`); // Assuming getBlob is another utility function

export const deleteUserDocument = (documentId: UserDocumentId) =>
  createQuery<UserDocument>({
    apiInstance: api,
    method: 'delete',
    url: `/users/documents/${documentId}`,
  });
