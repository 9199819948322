import { ReactNode } from 'react';

import { api, cashAccountApi, cashAccountInternalApi, rewardsApi } from './api';
import { useAuthenticationInterceptor } from './hooks';

interface ApiAuthenticationInterceptorProps {
  children: ReactNode;
  headers?: Record<string, string>;
}

const apis = [api, rewardsApi, cashAccountApi, cashAccountInternalApi];

export const ApiAuthenticationInterceptor = ({ children, headers }: ApiAuthenticationInterceptorProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  apis.forEach((api) => useAuthenticationInterceptor(api, headers));

  return <>{children}</>;
};
