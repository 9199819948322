import { useArrivedAuth0 } from '@arrived/arrived-auth0';

import { useIsOfferingInSet } from './useIsOfferingInSet';

// TODO: Revisit when a user could possibly own more than 1000 Offerings?
const query = {
  isOwned: { eq: [true] },
  size: 1000,
};

// TODO: This hook really shouldn't live in /queries, it just made the most sense for this refactor to place it here
//  for now.
export function useIsOfferingOwned() {
  const { isAuthenticated } = useArrivedAuth0();

  return useIsOfferingInSet(query, { enabled: isAuthenticated });
}
