import { Lease } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getLeasesByOfferingCid = (offeringCid: string) =>
  createQuery<Lease[]>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringCid}/leases`,
  });

export const getLeasesByPropertyId = (propertyId: number) =>
  createQuery<Lease[]>({
    apiInstance: api,
    method: 'get',
    url: `/properties/${propertyId}/leases`,
  });

export const getLeaseByLeaseCid = (leaseCid: string) =>
  createQuery<Lease[]>({
    apiInstance: api,
    method: 'get',
    url: `/leases/${leaseCid}`,
  });
