/**
 * Since an investment products "type" is actually a synthesis of the Offering's 'type' and 'assetType'
 * properties, this enum serves as the product of those two and can be used as a single definitive "type"
 * to distinguish the investment product types of an offering.
 */
export const InvestmentProductType = {
  SINGLE_FAMILY_RESIDENTIAL_IPO: 'SINGLE_FAMILY_RESIDENTIAL_IPO',
  SINGLE_FAMILY_RESIDENTIAL_FUND: 'SINGLE_FAMILY_RESIDENTIAL_FUND',
  VACATION_RENTAL_IPO: 'VACATION_RENTAL_IPO',
  SHORT_TERM_NOTE: 'SHORT_TERM_NOTE',
  // VACATION_RENTAL_FUND: 'VACATION_RENTAL_FUND',
  PRIVATE_CREDIT_FUND: 'PRIVATE_CREDIT_FUND',

  /**
   * Used for any "Owner Equity" items, while not a product we sell, these
   * are items that our investors have previously purchased to support
   * Arrived's mission.
   *
   * For example, we sourced through `WeFunder` and have a number of
   * investors curious about their investment status.
   *
   * The are not included alongside our general offering queries, but the `useGetAccountBalanceQuery`
   * will return them. If you do not see them returned from the query, you can use the `useArrivedOwnersEquityStats`
   * hook to get them without calling the `useGetAccountBalanceQuery` directly.
   *
   * @see `useGetAccountBalanceQuery` and `useArrivedOwnersEquityStats`
   */
  ARRIVED_OWNER_EQUITY: 'ARRIVED_OWNER_EQUITY',
} as const;

export type InvestmentProductType = (typeof InvestmentProductType)[keyof typeof InvestmentProductType];
