import { useContext } from 'react';

import { createStyledContext } from '@tamagui/core';

export interface ProductHeaderStyledContextType {
  condensed?: boolean;
}

export const ProductHeaderStyledContext = createStyledContext<ProductHeaderStyledContextType>({
  condensed: false,
});

export function useProductHeaderStyledContext() {
  return useContext(ProductHeaderStyledContext);
}
