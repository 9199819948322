import { useQuery } from '@tanstack/react-query';

import { getTradeFundStatuses } from '@arrived/api_v2';
import { OfferingTransactionId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { tradeFundStatusesQueryKeyFn } from './trades.keys';

export function useGetTradeFundStatusesQuery(
  tradeId: OfferingTransactionId,
  options?: UseAwaitedQueryOptions<typeof getTradeFundStatuses>,
) {
  return useQuery({
    queryKey: tradeFundStatusesQueryKeyFn(tradeId),
    queryFn: () => getTradeFundStatuses(tradeId),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
