import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteUserExternalAccount } from '@arrived/api_v2';
import { User } from '@arrived/models';

import { accountsPrimaryKeyFn, currentUserKeyFn } from '../../users';
import { accountsKeyFn } from '../accounts.keys';

export function useDeleteExternalAccountMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUserExternalAccount,
    onSuccess: (__, variables) => {
      // TODO: Track delete event.
      const user = queryClient.getQueryData<User>(currentUserKeyFn());
      if (user) {
        queryClient.invalidateQueries({ queryKey: accountsPrimaryKeyFn(user.id) });
        // TODO: This should really only be necessary in the admin portals, but we have some legacy routes that reference
        //  the specific account ID that are exposed to the user.
        queryClient.invalidateQueries({ queryKey: accountsKeyFn(variables) });
      }
    },
  });
}
