export const breakpoints = {
  base: 0,
  xs: 0,
  sm: 425,
  md: 800,
  lg: 1024,
  xl: 1440,
  '2xl': 1536,
} as const;

export type Breakpoint = keyof typeof breakpoints;
