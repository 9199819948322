import { Divider as BricksDivider, styled, withStaticProperties } from '@arrived/bricks';

import { DividerName } from './constants';

const ProductDetailContentDividerMain = styled(BricksDivider, {
  name: DividerName,
});

export const ProductDetailContentDivider = withStaticProperties(ProductDetailContentDividerMain, {
  Vertical: BricksDivider.Vertical,
});
