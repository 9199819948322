import { VariantSpreadFunction } from '@tamagui/core';

import { RootTextProps } from '../../atoms';

import { ButtonVariant } from './getButtonVariant';

// TODO: Add emphasize and destructive variants here 06/18/2024
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO we should better understand `VariantSpreadFunction` and how it works
export const getButtonTextVariant: VariantSpreadFunction<any, ButtonVariant> = (variant, config): RootTextProps => {
  const isInverted = config.props.inverted;
  const isDisabled = config.props.disabled;
  const isLoading = config.props.loading;

  if (isInverted) {
    if (variant === 'ghost') {
      if (isLoading) {
        return {
          color: '$interactive.neutral.focusInverted',
        };
      }

      if (isDisabled) {
        return {
          color: '$interactive.neutral.disabledInverted',
        };
      }

      return {
        color: '$interactive.neutral.restedInverted',
      };
    }

    if (variant === 'outlined') {
      if (isLoading) {
        return {
          color: '$interactive.neutral.focusInverted',
        };
      }

      if (isDisabled) {
        return {
          color: '$interactive.neutral.disabledInverted',
        };
      }
      return {
        color: '$interactive.neutral.restedInverted',
      };
    }

    return {
      color: '$interactive.neutral.rested',
    };
  }

  if (variant === 'ghost') {
    if (isLoading) {
      return {
        color: '$interactive.neutral.focus',
      };
    }

    if (isDisabled) {
      return {
        color: '$interactive.neutral.disabled',
      };
    }

    return {
      color: config.props.emphasized ? '$interactive.primary.rested' : '$interactive.neutral.rested',
      focusStyle: {
        color: '$interactive.neutral.focus',
      },
      hoverStyle: {
        color: '$interactive.neutral.hovered',
      },
    };
  }

  if (variant === 'outlined') {
    if (isLoading) {
      return {
        color: '$interactive.neutral.focus',
      };
    }

    if (isDisabled) {
      return {
        color: '$interactive.neutral.disabled',
      };
    }

    return {
      color: '$interactive.neutral.rested',
      focusStyle: {
        color: '$interactive.neutral.focus',
      },
      hoverStyle: {
        color: '$interactive.neutral.hovered',
      },
    };
  }

  return {
    color: '$onSurface.neutral.defaultInverted',
  };
};
