import { Offering, OfferingStatus } from '@arrived/models';

type TransactForOfferingParam = Pick<Offering, 'totalSharesTransacted' | 'totalShares' | 'status'>;

export const canTransactForOffering = (offering: TransactForOfferingParam): boolean => {
  return (
    offering.totalSharesTransacted < offering.totalShares &&
    (offering.status === OfferingStatus.TRANSACT_ALL || offering.status === OfferingStatus.TRANSACT_EARLY_ACCESS)
  );
};
