import { useMemo } from 'react';

import capitalize from 'lodash/capitalize';

import {
  BodyText,
  BodyVariants,
  RootTextProps,
  TitleText,
  TitleVariants,
  UtilityText,
  UtilityVariants,
  ValueText,
  ValueVariants,
} from '@arrived/bricks';
import { htmlParser } from '@arrived/bricks-common';

import { BuilderBlock, GenericBuilderIcons, RegisteredComponent } from '../../sdk';

const TextTypes = ['TitleText', 'BodyText', 'UtilityText', 'ValueText'] as const;

type TextTypes = (typeof TextTypes)[number];

const Tags = ['p', 'span', 'label', 'strong', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

type Tags = (typeof Tags)[number];

type VariantMap = {
  TitleText: TitleVariants;
  BodyText: BodyVariants;
  UtilityText: UtilityVariants;
  ValueText: ValueVariants;
};

const formatKeyName = (key: string) => key.split('.').map(capitalize).join(' ');

const buildTextMap = <T extends TextTypes>(tokens: object = {}) =>
  Object.keys(tokens).map((key) => ({
    label: `${formatKeyName(key)} (${key})`,
    value: key,
  })) as {
    label: string;
    value: VariantMap[T];
  }[];

const TextMap = {
  TitleText: buildTextMap<'TitleText'>(TitleText.staticConfig.variants?.token),
  BodyText: buildTextMap<'BodyText'>(BodyText.staticConfig.variants?.token),
  UtilityText: buildTextMap<'UtilityText'>(UtilityText.staticConfig.variants?.token),
  ValueText: buildTextMap<'ValueText'>(ValueText.staticConfig.variants?.token),
} as const;

type TextProps<T extends TextTypes> = {
  /**
   * The content to display
   * @type longText
   */
  text?: string;

  /**
   * The type of text to display
   */
  type: T;

  /**
   * The HTML tag to render
   * @default p
   */
  tag?: Tags;

  /**
   * Depending on the `type` of text, the token will be different
   * Reference the figma for a list of all token types
   *
   * https://www.figma.com/file/ARoO77IlEcG8qv91YUQpXY/%F0%9F%94%A9-Typography?node-id=317%3A1775
   */
  token?: VariantMap[T];

  /**
   * Toggle the text to return null or children
   */
  hide?: boolean;
} & RootTextProps;

export const Text = <T extends TextTypes = 'BodyText'>({
  text = '',
  hide,
  tag,
  token,
  type,
  ...props
}: TextProps<T>) => {
  const TextComponent = useMemo(
    () =>
      ({
        TitleText,
        BodyText,
        UtilityText,
        ValueText,
      })[type ?? 'BodyText'],
    [type],
  );

  return (
    // @ts-expect-error -- This is just to avoid having super descriptive types for no benefit, the component handles the correct prop layout
    <TextComponent tag={tag as never} token={token as never} display={hide ? 'none' : null} {...props}>
      {htmlParser(text)}
    </TextComponent>
  );
};

/**
 * Used to create a composable Text element when adding a `uiBlocks` to an
 * input field.
 *
 * The options object acts as a filler for all `inputs` listed below.
 */
export const TextBlockElement = <T extends TextTypes = 'BodyText'>(options?: TextProps<T>) =>
  ({
    '@type': '@builder.io/sdk:Element',
    component: {
      name: 'Text',
      options,
    },
  }) satisfies BuilderBlock;

Text.registerComponent = {
  component: Text,
  static: true,
  // Overwrite the default name of the component, this is the name that is used in the Builder.io editor
  name: 'Bricks:Text',
  friendlyName: 'Bricks Text',
  description:
    'Base text component for all text styles. Fully supports rich text. If you cannot see the option to edit, check `Advanced Options`. These allow for double-clicking to edit the text directly.',
  image: GenericBuilderIcons.Text,
  docsLink: 'https://www.figma.com/file/ARoO77IlEcG8qv91YUQpXY/%F0%9F%94%A9-Typography',
  defaultStyles: {
    lineHeight: 'normal',
    height: 'auto',
  },
  inputs: [
    {
      name: 'text',
      type: 'longText',
      defaultValue: 'Hello, World!',
      autoFocus: true,
      bubble: true,
    },
    {
      name: 'type',
      type: 'enum',
      helperText: 'The type of text to display, reference the Figma for all the different type of tokens.',
      defaultValue: 'BodyText',
      enum: [
        {
          label: 'Title',
          value: 'TitleText',
        },
        {
          label: 'Body',
          value: 'BodyText',
        },
        {
          label: 'Utility',
          value: 'UtilityText',
        },
        {
          label: 'Value',
          value: 'ValueText',
        },
      ],
    },
    {
      name: 'tag',
      type: 'enum',
      helperText: 'HTML tag to render, defaults to `p`',
      defaultValue: 'p',
      enum: ['p', 'span', 'label', 'strong', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    },
    {
      name: 'token',
      type: 'enum',
      helperText: 'Title Text tokens to use, reference the Figma for all the different type of tokens.',
      enum: TextMap.TitleText,
      defaultValue: 'title.heading.medium',
      showIf: (options) => options.get('type') === 'TitleText',
    },
    {
      name: 'token',
      type: 'enum',
      helperText: 'Utility Text tokens to use, reference the Figma for all the different type of tokens.',
      enum: TextMap.UtilityText,
      defaultValue: 'utility.helper.medium',
      showIf: (options) => options.get('type') === 'UtilityText',
    },
    {
      name: 'token',
      type: 'enum',
      helperText: 'Value Text tokens to use, reference the Figma for all the different type of tokens.',
      enum: TextMap.ValueText,
      defaultValue: 'value.medium',
      showIf: (options) => options.get('type') === 'ValueText',
    },
    {
      name: 'token',
      type: 'enum',
      helperText: 'Body Text tokens to use, reference the Figma for all the different type of tokens.',
      enum: TextMap.BodyText,
      defaultValue: 'body.default.medium',
      showIf: (options) => options.get('type') === 'BodyText',
    },
    {
      name: 'hide',
      type: 'boolean',
      helperText:
        'Toggle to return either the children, or set the style to `display: none`. This will not remove the element, just hide it.',
      defaultValue: false,
    },
  ],
} satisfies RegisteredComponent;
