/**
 * This function takes a date string like `2020-03-20` and converts it into a Date object that
 * represents that Date at 12am regardless of the users timezone.
 *
 * This does so by parsing the date string as a Date and then creating a new Date from that
 * original Date object that has the timezone offset added to it (converted to milliseconds).
 */
export function getTimezoneAgnosticDate(date: Date | string) {
  const parsedDate = new Date(date);
  return new Date(parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1_000);
}
