import { NAV_TYPE, NavItemProps } from '../navigation.types';

import { ButtonNavItem } from './ButtonNavItem';
import { MultiLevelNavItem } from './MultiLevelNavItem';
import { SingleLevelNavItem } from './SingleLevelNavItem';

export const NavItem = (props: NavItemProps) => {
  if (props.type === NAV_TYPE.MULTIPLE) {
    return <MultiLevelNavItem {...props} />;
  }

  if (props.type === NAV_TYPE.BUTTON) {
    return <ButtonNavItem {...props} />;
  }

  if (props.type === NAV_TYPE.COMPONENT && props.component) {
    return props.component;
  }

  return <SingleLevelNavItem {...props} />;
};
