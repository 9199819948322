export type UserDocumentId = number;

export interface UserDocument {
  id: UserDocumentId;
  ncId: string;
  userId: number;
  ncPartyId: string;
  ncEntityId: string;
  type: UserDocumentType;
  fileName: string;
  ncFileName: string;
  ncFileReferenceCode: string;
  createdAt: Date;
  active: boolean;
}

export enum UserDocumentType {
  ID = 'ID',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  PASSPORT = 'PASSPORT',
  GREEN_CARD = 'GREEN_CARD',

  // entity docs
  CERTIFICATE_OF_FORMATION = 'CERTIFICATE_OF_FORMATION',

  BANK_VERIFICATION = 'BANK_VERIFICATION',

  OTHER = 'OTHER',
}

export enum DocumentFileFormat {
  PNG = 'png',
  JPG = 'jpg',
  PDF = 'pdf',
  JPEG = 'jpeg',
}

export const ACCEPTED_USER_DOCUMENT_FILE_FORMATS = [
  DocumentFileFormat.PNG,
  DocumentFileFormat.JPG,
  DocumentFileFormat.JPEG,
  DocumentFileFormat.PDF,
];

export interface UserDocumentUploadRequest {
  userId: number;
  file: unknown;
  type: UserDocumentType;
}

export interface UserRawDocumentRequest {
  documentId: UserDocumentId;
  fileName: UserDocument['fileName'];
}
