export const IntercomClientType = {
  WEB: 'WEB',
  IOS: 'IOS',
  ANDROID: 'ANDROID',
} as const;
export type IntercomClientType = (typeof IntercomClientType)[keyof typeof IntercomClientType];

export interface IntercomIdentityToken {
  token: string;
}

export interface IntercomIdentityTokenRequest {
  clientType: IntercomClientType;
}
