import {
  PatchReferralCodeRequest,
  RedeemCodeResponse,
  ReferralCode,
  ReferralCodeEntity,
  ReferralStatus,
  UserId,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getReferralCode = () =>
  createQuery<ReferralCode>({
    apiInstance: api,
    method: 'get',
    url: '/referral/get-referral-code',
  });

export const postRefereeSignup = (referralCode: ReferralCode) =>
  createQuery<boolean>({
    apiInstance: api,
    method: 'post',
    url: `/referral/referee-signup/${referralCode}`,
  });

export const postRedeemCode = (referralCode: ReferralCode) =>
  createQuery<RedeemCodeResponse>({
    apiInstance: api,
    method: 'post',
    url: `/referral/redeem-code/${referralCode}`,
  });

export const getReferralStatuses = () =>
  createQuery<ReferralStatus[]>({
    apiInstance: api,
    method: 'get',
    url: '/referral/get-my-referral-statuses',
  });

export const getReferralCodeEntityByCode = (referralCode: ReferralCode) =>
  createQuery<ReferralCodeEntity>({
    apiInstance: api,
    method: 'get',
    url: `/referral/get-referral-code/${referralCode}`,
  });

export const getReferralCodeEntityByUserId = (userId: UserId) =>
  createQuery<ReferralCodeEntity>({
    apiInstance: api,
    method: 'get',
    url: `/referral/${userId}/get-referral-code`,
  });

export const patchReferralCodeEntity = (requestData: PatchReferralCodeRequest) =>
  createQuery<ReferralCodeEntity>({
    apiInstance: api,
    method: 'patch',
    url: `/referral/referralCode/${requestData.id}/`,
    requestData,
  });
