import { useMemo } from 'react';

import { IntlShape, useIntl } from 'react-intl';

import {
  sfrLeveredReturnLower,
  sfrLeveredReturnUpper,
  sfrUnleveredReturnLower,
  sfrUnleveredReturnUpper,
  vrLeveredReturnLower,
  vrLeveredReturnUpper,
  vrUnleveredReturnLower,
  vrUnleveredReturnUpper,
} from '@arrived/common';
import { AssetType, Offering } from '@arrived/models';

export interface TopReturnsPartOneProps {
  offering: Offering;
}

const formattedMessageIdRoot = 'property-details.returns-data.part-1';

const formatPercent = (percent: number, intl: IntlShape) =>
  intl.formatNumber(percent, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 1 });

export const TopReturnsPartOne = ({ offering }: TopReturnsPartOneProps) => {
  const intl = useIntl();

  const formattedMessageId = useMemo(() => {
    const assetClass = offering.assetType === AssetType.LTR ? 'ltr' : 'str';
    let suffix;

    if (offering.hasFlexFinancing) {
      suffix = 'refi';
    } else if (offering.debtPercent > 0) {
      suffix = 'levered';
    } else {
      suffix = 'unlevered';
    }

    return `${formattedMessageIdRoot}.${assetClass}-${suffix}`;
  }, [offering]);

  return (
    <>
      {intl.formatMessage(
        { id: formattedMessageId },
        {
          sfrUnleveredReturnLower: formatPercent(sfrUnleveredReturnLower, intl),
          sfrUnleveredReturnUpper: formatPercent(sfrUnleveredReturnUpper, intl),
          sfrLeveredReturnLower: formatPercent(sfrLeveredReturnLower, intl),
          sfrLeveredReturnUpper: formatPercent(sfrLeveredReturnUpper, intl),
          vrUnleveredReturnLower: formatPercent(vrUnleveredReturnLower, intl),
          vrUnleveredReturnUpper: formatPercent(vrUnleveredReturnUpper, intl),
          vrLeveredReturnLower: formatPercent(vrLeveredReturnLower, intl),
          vrLeveredReturnUpper: formatPercent(vrLeveredReturnUpper, intl),
        },
      )}
    </>
  );
};
