import { PropsWithChildren, createContext, useMemo } from 'react';

import { stub } from '@arrived/common';
import { OfferingIdOrOfferingShortName } from '@arrived/models';

export interface NavigatorContext {
  hash?: string;
  navigatorBack: (options?: unknown) => void;
  navigatorGo: (location: string, options?: unknown) => void;
  navigatorGoPropertyDetails: (location: OfferingIdOrOfferingShortName, options?: unknown) => void;
  navigatorReplace: (location: string, options?: unknown) => void;
}
export const NavigatorContext = createContext<NavigatorContext>({
  navigatorBack: stub,
  navigatorGo: stub,
  navigatorGoPropertyDetails: stub,
  navigatorReplace: stub,
});

export const NavigatorContextProvider = ({
  children,
  hash,
  navigatorBack,
  navigatorGo,
  navigatorGoPropertyDetails,
  navigatorReplace,
}: PropsWithChildren<NavigatorContext>) => {
  const value = useMemo(
    () => ({
      hash,
      navigatorBack,
      navigatorGo,
      navigatorGoPropertyDetails,
      navigatorReplace,
    }),
    [hash, navigatorBack, navigatorGo, navigatorGoPropertyDetails, navigatorReplace],
  );

  return <NavigatorContext.Provider value={value}>{children}</NavigatorContext.Provider>;
};
