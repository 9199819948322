import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function MenuIconInternal({ size, color, fill = 'none', ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} {...rest}>
      <Path
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18 7H6M18 17H6M18 12H6"
      />
    </Svg>
  );
}

export const MenuIcon = memo<IconProps>(withIconTheme(MenuIconInternal));
