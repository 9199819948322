import { StackProps, styled } from '@tamagui/core';

import { Image as ExpoImage, ImageProps as ExpoImageProps } from 'expo-image';

export type ImageProps = ExpoImageProps & StackProps;

export const Image = styled(ExpoImage, {
  name: 'BricksImage',

  source: { uri: '' },

  // @ts-ignore -- This is not a real prop for native, but is for web
  draggable: false,

  responsivePolicy: 'live',

  flex: 1,
});

// TODO: By wrapping Image in `styled`, this typing is wrong.
export type Image = ExpoImage;
