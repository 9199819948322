globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"21e7a1ca75e0f56e83eb0a350ec908208c579801"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/next/production-arrived/d27ad5c8-1652-5296-9298-5c69e9a2a20d";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { CONFIG } from '@arrived/config';

import Package from './package.json';

const SAMPLE_RATES_BY_ENV: Record<string, number> = {
  development: 0.1,
  staging: 0.01,
  preview: 0.005,
  production: 0.03,
};

Sentry.init({
  dsn: CONFIG.sentry.nextJsDsn,

  environment: CONFIG.sentry.env,
  release: `arrived-public@${Package.version}`,

  // Controls the % of transactions to send to Sentry; helps usage quotas
  tracesSampleRate: SAMPLE_RATES_BY_ENV[CONFIG.sentry.env] ?? 1,

  tracePropagationTargets: [/^(?!.cdn.builder.io).$/],

  // debug: CONFIG.sentry.env === 'development' ? true : false,

  replaysSessionSampleRate: 0.005,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.5,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.httpClientIntegration({
      // This array can contain tuples of `[begin, end]` (both inclusive),
      // single status codes, or a combination of both.
      // default: [[500, 599]]
      failedRequestStatusCodes: [[500, 599], 400, 422],

      // This array can contain Regexes, strings, or a combination of both.
      // default: [/.*/]
      failedRequestTargets: [
        new RegExp(`${CONFIG.abacusApiRoot}.*`),
        new RegExp(`${CONFIG.cashAccountsApiRoot}.*`),
        new RegExp(`${CONFIG.rewardsApiRoot}.*`),
      ],
    }),
  ],
});
