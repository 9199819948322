import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function CaretRightInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path d="M7.91668 5.91666L12.0833 10.0833L7.91668 14.25" stroke={color} strokeWidth={1.25} />
    </Svg>
  );
}

export const CaretRightIcon = memo<IconProps>(withIconTheme(CaretRightInternal));
