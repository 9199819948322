import { FormattedMessage } from 'react-intl';

import { Loading, Sheet, SheetProps, Stack, isWeb, withStaticProperties } from '@arrived/bricks';
import { useInvestmentProductType } from '@arrived/hooks';
import { useOfferingQuery } from '@arrived/queries';

import {
  OfferingHistoryBodyText,
  OfferingHistoryEmptyState,
  OfferingHistoryEvents,
  OfferingHistoryFooter,
  OfferingHistoryProps,
  isIpoProperty,
} from './OfferingHistoryContent';

const OfferingHistorySheetInternal = (props: OfferingHistoryProps & SheetProps) => {
  const { offeringIdOrShortname, onClose, open, children, ...rest } = props;
  const offeringState = useOfferingQuery(offeringIdOrShortname);
  const investmentProductType = useInvestmentProductType(offeringState.data);

  return (
    <Sheet
      onClose={onClose}
      open={open}
      onBackdropPress={onClose}
      isNotBottomSheet={!isWeb}
      disablePortal={!isWeb}
      {...rest}
    >
      <Sheet.Header>
        <Sheet.Header.Title>
          <Sheet.Header.Title.Text>
            {isIpoProperty(investmentProductType) ? (
              <FormattedMessage id="property-history-title" />
            ) : (
              <FormattedMessage id="offering-history-title" />
            )}
          </Sheet.Header.Title.Text>
          <Sheet.Header.CloseIcon />
        </Sheet.Header.Title>
        <OfferingHistoryBodyText>{offeringState.data?.name}</OfferingHistoryBodyText>
      </Sheet.Header>
      <Sheet.Body>
        <Sheet.ScrollView flex={1} p={0}>
          {investmentProductType && isIpoProperty(investmentProductType) && offeringState.data ? (
            <OfferingHistoryEvents offering={offeringState.data} />
          ) : (
            <>
              {offeringState.isLoading ? (
                <Stack flex={1} p={0} justifyContent="center" alignItems="center">
                  <Loading centered w="100%" h="100%" flexGrow={1}>
                    <Loading.Indicator variant="colored" />
                  </Loading>
                </Stack>
              ) : (
                <OfferingHistoryEmptyState investmentProductType={investmentProductType} />
              )}
            </>
          )}
        </Sheet.ScrollView>
        {children}
      </Sheet.Body>
    </Sheet>
  );
};

export const OfferingHistorySheet = withStaticProperties(OfferingHistorySheetInternal, {
  Footer: OfferingHistoryFooter,
});
