import { useQuery } from '@tanstack/react-query';

import { getRegDAccessSummary } from '@arrived/api_v2';

import { useGetPrimaryAccountQuery } from '../../users';
import { UseAwaitedQueryOptions } from '../../utils';

import { regDStatusKeyFn } from './regDStatus.keys';

/**
 * Hook for encapsulating whether a given Account can access reg-d offerings.
 *
 * Note: In a future world where a user has multiple accounts, we need to resolve which account they're using
 * instead of using `useGetPrimaryAccountQuery`
 */
export function useRegulationAccessSummaryQuery(options?: UseAwaitedQueryOptions<typeof getRegDAccessSummary>) {
  const accountState = useGetPrimaryAccountQuery();

  return useQuery({
    // TODO: We should really cache this under the account's CID, however, we need to invalidate
    // the cache of Reg D access information when we post a new suitability questionnaire which we
    // key with an account ID (not a CID), so we need to store it under the account ID.
    //
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: regDStatusKeyFn(accountState.data?.id!),
    queryFn: () => getRegDAccessSummary(accountState.data?.cid!),
    ...options,
    enabled: (options?.enabled ?? true) && accountState.data?.cid != null && accountState.data?.id != null,
  });
}
