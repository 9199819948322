import { useMemo } from 'react';

import { Auth0UserPermissions } from '@arrived/arrived-auth0';

import { useGetCurrentUserQuery } from './useGetCurrentUserQuery';
import { usePermissionsQuery } from './usePermissionsQuery';

/**
 * Right now, early access permissions are stored in two places for the user, Auth0 permissions encoded in the JWT and
 * in the DB on the `isEarlyAccess` flag. We'll be moving to the Auth0 permissions model and deprecating the DB model
 * flag, but for now we need to support both and assume that if either is present the user has early access permissions.
 */
export function useCanUserTransactEarlyAccess() {
  const currentUserState = useGetCurrentUserQuery();
  const permissions = usePermissionsQuery();

  return useMemo(() => {
    if (!currentUserState.isSuccess || !permissions.isSuccess) {
      return undefined;
    }

    return (
      currentUserState.data.admin ||
      permissions.data.includes(Auth0UserPermissions.ALL) ||
      permissions.data.includes(Auth0UserPermissions.EARLY_ACCESS_TRADE)
    );
  }, [currentUserState.data, currentUserState.isSuccess, permissions.data, permissions.isSuccess]);
}
