import { GetProps, Text, isWeb, styled } from '@tamagui/core';
import { getFontSized } from '@tamagui/get-font-sized';

/**
 * The core for all text components, anything edited here will affect all text components.
 */
export const RootText = styled(Text, {
  name: 'RootText',
  fontFamily: '$body',
  color: '$onSurface.neutral.default',

  ...(!isWeb && { maxFontSizeMultiplier: 1.1 }),

  variants: {
    size: getFontSized,
  } as const,

  defaultVariants: {
    size: '$true',
  },
});

export type RootTextProps = GetProps<typeof RootText>;
