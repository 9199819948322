import { ToastContainer } from 'react-toastify';

// We should only have one .css file in the package, so we should be able to just import it here
import 'react-toastify/dist/ReactToastify.css';

/**
 * Web wrapper around notification container implementation
 *
 * To be refactored to use [burnt](https://github.com/nandorojo/burnt)
 */
export const ToastyContainer = () => (
  <ToastContainer
    newestOnTop
    pauseOnHover
    hideProgressBar
    autoClose={5000}
    progressClassName="notification-progress-bar"
  />
);
