import { useMemo } from 'react';

import { LinkAltIcon, UtilityText, styled } from '@arrived/bricks';
import { OfferingDocumentTypeLabels, SSTDocumentTypeLabels } from '@arrived/common';
import { OfferingDocumentFormat } from '@arrived/models';

import { ProductDocumentType } from './ItemDocument.types';
import { LinkItem } from './LinkItem';
import { PDFItem } from './PDFItem';
import { StyledInfoBoxProps } from './StyledInfoBox';

const OfferingDocumentContainer = ({ document, ...rest }: StyledInfoBoxProps & { document: ProductDocumentType }) =>
  document.format === OfferingDocumentFormat.PDF ? (
    <PDFItem document={document} {...rest} />
  ) : (
    <LinkItem document={document} {...rest} />
  );

const OfferingDocumentText = styled(UtilityText, {
  ellipse: true,
  token: 'utility.label.small.alt',
});

export interface OfferingDocumentProps {
  document: ProductDocumentType;
}

export const OfferingDocument = ({ document }: OfferingDocumentProps) => {
  const documentTypeLabel = useMemo(() => {
    if (document.source === 'ABACUS') {
      return OfferingDocumentTypeLabels[document.type];
    } else {
      return SSTDocumentTypeLabels[document.type];
    }
  }, [document.source, document.type]);

  return (
    <OfferingDocumentContainer document={document}>
      <OfferingDocumentText>{documentTypeLabel}</OfferingDocumentText>
      <LinkAltIcon style={{ flexShrink: 0, marginLeft: 'auto' }} />
    </OfferingDocumentContainer>
  );
};
