import { FormattedMessage } from 'react-intl';

import { Markdown } from '@arrived/bricks-common';
import { Market, Offering, STRMetadata } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../content';
import { Location, usePropertyCoordinates } from '../../../location';

export type MarketSectionProps = ProductDetailContentProps & {
  description: STRMetadata['market']['descriptionMarkdown'] | Market['description'];
  offering: Offering;
  name: Market['title'];
};

export const MarketSection = ({ description, offering, name, ...rest }: MarketSectionProps) => {
  const coordinates = usePropertyCoordinates(offering.properties[0]);

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.the-market" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body>
        <ProductDetailContent.SubHeader>{name}</ProductDetailContent.SubHeader>
        {coordinates && (
          <Location h={250}>
            <Location.Map coordinates={coordinates} zoom={7} />
          </Location>
        )}
        {/* TODO: We were previously allowing marketing to add {br} elements as line breaks but those won't work anymore so just replace with newline characters */}
        <Markdown Text={ProductDetailContent.Text}>{description.replaceAll('{br}', '\n')}</Markdown>
        {/* TODO: Market grade image and 3 horsemen graphs? */}
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};
