import { useCallback } from 'react';

import { BlockedOfferingsFlag, FEATURE_FLAGS, useFeature } from '@arrived/feature-flags';
import { OfferingShortName } from '@arrived/models';

export function useIsOfferingBlocked() {
  const blockedOfferingsFlag = useFeature<BlockedOfferingsFlag>(FEATURE_FLAGS.BLOCKED_OFFERINGS);

  return useCallback(
    (offeringShortName: OfferingShortName) => blockedOfferingsFlag.value?.properties.includes(offeringShortName),
    [blockedOfferingsFlag.value?.properties],
  );
}
