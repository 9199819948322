import { FormattedMessage, FormattedNumber } from 'react-intl';

import { InfoBox, Row } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { AumFeeFrequency, SFRFundData } from '@arrived/models';

import { ProductDetailContent } from '../../../../../content';

export const AssetManagementFees = ({
  assetManagementFees: { aumFee, description },
}: {
  assetManagementFees: NonNullable<SFRFundData['assetManagementFees']>;
}) => (
  <>
    <ProductDetailContent.SubHeader>
      <FormattedMessage id="product-details.asset-management-fees" />
    </ProductDetailContent.SubHeader>
    <Markdown Text={ProductDetailContent.Text}>{description}</Markdown>
    <InfoBox p={0}>
      <Row>
        <Row.Label tooltip={aumFee.tooltip}>
          <FormattedMessage id="product-details.aum-fee" />
        </Row.Label>
        <Row.Value flexShrink={1}>
          {aumFee.frequency === AumFeeFrequency.QUARTERLY && (
            <FormattedMessage
              id="product-details.aum-fee.label"
              values={{
                percent: (
                  <FormattedNumber
                    value={aumFee.value}
                    style="percent"
                    maximumFractionDigits={2}
                    minimumFractionDigits={0}
                  />
                ),
              }}
            />
          )}
          {aumFee.frequency === AumFeeFrequency.MONTHLY && (
            <FormattedMessage
              id="product-details.aum-fee.label-monthly"
              values={{
                percent: (
                  <FormattedNumber
                    value={aumFee.value}
                    style="percent"
                    maximumFractionDigits={2}
                    minimumFractionDigits={0}
                  />
                ),
              }}
            />
          )}
        </Row.Value>
      </Row>
    </InfoBox>
  </>
);
