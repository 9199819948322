// Quora Pixel is Installed via Google Tag Manager.

import { MARKETING_CONVERSION_EVENTS } from '@arrived/models';

export interface QuoraPixelArgs {
  type: MARKETING_CONVERSION_EVENTS;
  email?: string;
}

export const trackQuoraPixelEvent = (args: QuoraPixelArgs) => {
  if (window && window['qp']) {
    switch (args.type) {
      case MARKETING_CONVERSION_EVENTS.EMAIL_LEAD:
        window['qp']('track', 'GenerateLead', { email: args.email || '' });
        break;
      case MARKETING_CONVERSION_EVENTS.USER_CREATED:
        window['qp']('track', 'CompleteRegistration', { email: args.email || '' });
        break;
      case MARKETING_CONVERSION_EVENTS.LINKED_BANK:
        window['qp']('track', 'AddPaymentInfo', { email: args.email || '' });
        break;
      case MARKETING_CONVERSION_EVENTS.TRADE_DOCS_SIGNED:
        window['qp']('track', 'Purchase', { email: args.email || '' });
        break;
      default:
        break;
    }
  }
};
