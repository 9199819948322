import { A } from '@expo/html-elements';

import { BodyText, Stack, StackProps, styled } from '@arrived/bricks';

import { AddressName } from './constants';

const LocationAddressFrame = styled(Stack, {
  name: AddressName,

  flexGrow: 0.75,
  flexShrink: 1,
  flexBasis: 0,
  bg: '$onSurface.neutral.zebraAlt',
  centered: true,
  px: '$4',
  py: '$4',
});

export type AddressProps = Omit<StackProps, 'children'> & {
  address: string;
};

export const Address = LocationAddressFrame.styleable<AddressProps>(({ address, ...rest }, ref) => (
  <LocationAddressFrame ref={ref} {...rest}>
    <A
      rel="noopener noreferrer"
      target="_blank"
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`}
    >
      <BodyText color="$onSurface.primary.decorative" textDecorationLine="underline">
        {address}
      </BodyText>
    </A>
  </LocationAddressFrame>
));
