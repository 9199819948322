import { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { Property } from '@arrived/models';

export const usePropertyLabel = (property: Property) =>
  useMemo(() => {
    return property.description || property.address?.street || <FormattedMessage id="product-details.property" />;
  }, [property.address]);
