import { SizeTokens, Stack, VariantSpreadFunction, styled, withStaticProperties } from '@tamagui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO we should better understand `VariantSpreadFunction` and how it works
export const getSkeletonSize: VariantSpreadFunction<any, SizeTokens> = (size) => {
  const dimension = size;

  return {
    h: dimension,
    w: dimension,
  };
};

const SkeletonRoot = styled(Stack, {
  backgroundColor: '$onSurface.neutral.container',

  variants: {
    alt: {
      true: {
        backgroundColor: '$onSurface.neutral.zebraAlt',
      },
    },
  } as const,
});

export const Skeleton = withStaticProperties(SkeletonRoot, {
  Rect: styled(SkeletonRoot, {
    borderRadius: '$2',
  }),
  Circle: styled(SkeletonRoot, {
    borderRadius: '$full',
    variants: {
      size: getSkeletonSize,
    },
  }),
});
