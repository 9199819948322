import { useCallback } from 'react';

import { FormattedMessage } from 'react-intl';

import { A } from '@expo/html-elements';
import _uniq from 'lodash/uniq';

import { Callout, isWeb } from '@arrived/bricks';
import { CONFIG } from '@arrived/config';
import { ikClient } from '@arrived/imagekit';

const questionMarkImage = ikClient.url({
  path: '/features/product-details/question-mark.png',
  transformation: [{ width: '64', height: '64' }],
});

export const HelpCenterCallout = () => {
  const handleIntercomPress = useCallback(async () => {
    if (!isWeb) {
      const { default: Intercom, Space } = await import('@intercom/intercom-react-native');
      Intercom.presentSpace(Space.helpCenter);
    }
  }, []);

  return (
    <Callout hideCloseIcon variant="inverted">
      <Callout.Illustration source={questionMarkImage} />
      <Callout.Heading>
        <FormattedMessage id="product-details.other-questions" />
      </Callout.Heading>
      <Callout.Content>
        <Callout.Content.Text textDecorationLine="underline">
          {isWeb ? (
            <A href={CONFIG.helpUrl}>
              <FormattedMessage id="product-details.visit-our-help-center" />
            </A>
          ) : (
            <A onPress={handleIntercomPress}>
              <FormattedMessage id="product-details.visit-our-help-center" />
            </A>
          )}
        </Callout.Content.Text>
      </Callout.Content>
    </Callout>
  );
};
