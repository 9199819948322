import { useQuery } from '@tanstack/react-query';

import { getAllQuestionnaires } from '@arrived/api_v2';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { questionnairesKey } from './questionnaires.keys';

export function useGetQuestionnairesQuery(options?: UseAwaitedQueryOptions<typeof getAllQuestionnaires>) {
  return useQuery({
    queryKey: questionnairesKey,
    queryFn: () => getAllQuestionnaires(),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
