import { useMemo } from 'react';

import { Stack, getVariable, isWeb } from '@arrived/bricks';
import { htmlParser } from '@arrived/bricks-common';

import { BuilderBlock, GenericBuilderIcons, RegisteredComponent } from '../../sdk';

type RichTextContentProps = {
  text: string;
  fullWidth: boolean;
  /**
   * Additional attributes to add to the button element from
   * `noWrap` within input options.
   */
  attributes?: Record<string, string>;
};

export const RichTextContent = ({ text, attributes, fullWidth = false }: RichTextContentProps) => {
  const parsedBody = useMemo(() => htmlParser(text, { pProps: { pb: 0 } }), [text]);

  return (
    <>
      {isWeb && (
        <style jsx global>{`
          :target {
            scroll-margin-top: 120px;
          }

          .article-display-image {
            width: 100%;
            height: auto;
            object-fit: cover;
          }

          ol.blog-list,
          ul.blog-list {
            list-style-position: inside;
          }

          ol.blog-list li.blog-list-entry,
          ul.blog-list li.blog-list-entry {
            display: list-item;
            padding-left: ${getVariable('$2', 'space')};
          }

          ul.blog-list li.blog-list-entry::before {
            content: '';
            margin-left: calc(-1 * ${getVariable('$2', 'space')});
          }
        `}</style>
      )}
      <Stack className="bricks_richText" width={fullWidth ? '100%' : undefined} {...attributes}>
        {parsedBody}
      </Stack>
    </>
  );
};

export const RichTextBlockContent = (options?: Partial<RichTextContentProps>) =>
  ({
    '@type': '@builder.io/sdk:Element',
    component: {
      name: 'RichText',
      options: {
        text: '<b>Some example styled text</b>',
        ...options,
      },
    },
  }) satisfies BuilderBlock;

RichTextContent.registerComponent = {
  component: RichTextContent,
  name: 'RichText',
  image: GenericBuilderIcons.Text,
  description:
    'Rich text container for any content. This wraps everything within a `BodyText` component but is fully customizable via the editor.',
  canHaveChildren: false,
  shouldReceiveBuilderProps: {
    builderComponents: true,
    builderContext: true,
  },
  static: true,
  noWrap: true,
  inputs: [
    {
      name: 'text',
      type: 'richText',
      defaultValue: 'Enter some text here...',
      helperText: `Content to display. Some notes: You cannot use B, I, U, etc. when using Headings 1-6. You'll notice when you try to add formatting you lose some spacing. Adding twice the number of spaces between lines will fix the issue.`,
      autoFocus: true,
      bubble: true,
    },
    {
      name: 'fullWidth',
      type: 'boolean',
      defaultValue: false,
    },
  ],
} satisfies RegisteredComponent;
