import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Divider, Stack, StackProps } from '@arrived/bricks';
import { Offering } from '@arrived/models';

import { Row } from '../../../../../row';

import { Group } from './Group';
import { Operator } from './Operator';
import { useRentalIncomeCalculationData } from './useRentalIncomeCalculationData';

export type RentalIncomeSectionProps = StackProps & { offering: Offering };

export const RentalIncomeSection = ({ offering, ...rest }: RentalIncomeSectionProps) => {
  const { dividends, dividendYield, expenses, grossYield: _, rent } = useRentalIncomeCalculationData(offering);

  return (
    <Stack gap="$4" {...rest}>
      <Group bg="$onSurface.neutral.zebra" borderWidth="$0.25" borderColor="$onSurface.neutral.outlineAlt">
        <Row>
          <Row.Label tooltip={<FormattedMessage id="product-details.investment-strategy.annual-rent.description" />}>
            <FormattedMessage id="product-details.investment-strategy.annual-rent" />
          </Row.Label>
          {/* TODO: Include Gross Yield text? */}
          {/* <UtilityText token="utility.label.xxsmall" pl="$1">
            <FormattedMessage
              id="product-details.investment-strategy.gross-yield"
              values={{ percent: <FormattedNumber value={grossYield} style="percent" maximumFractionDigits={0} /> }}
            />
          </UtilityText> */}
          <Row.Value>
            <FormattedNumber value={rent} currency="USD" style="currency" maximumFractionDigits={0} />
          </Row.Value>
        </Row>
        <Stack>
          <Divider px="$6" />
          <Stack
            centered
            position="absolute"
            left="50%"
            transform={[{ translateY: -22 }, { translateX: -22 }]}
            p="$3"
            bg="$onSurface.neutral.zebra"
          >
            <Operator>-</Operator>
          </Stack>
        </Stack>
        <Row>
          <Row.Label
            tooltip={
              <FormattedMessage id="product-details.investment-strategy.operating-finance-legal-fees.description" />
            }
          >
            <FormattedMessage id="product-details.investment-strategy.operating-finance-legal-fees" />
          </Row.Label>
          <Row.Value>
            <FormattedNumber value={expenses} currency="USD" style="currency" maximumFractionDigits={0} />
          </Row.Value>
        </Row>
        <Row bg="$onSurface.neutral.zebraAlt">
          <Row.Label>
            <FormattedMessage id="product-details.investment-strategy.cash-flow-dividend" />
          </Row.Label>
          <Row.Value variant="bold">
            <FormattedNumber value={dividends} currency="USD" style="currency" maximumFractionDigits={0} />
          </Row.Value>
        </Row>
      </Group>
      <Stack centered>
        <Operator>{'\u00F7'}</Operator>
      </Stack>
      <Group bg="$onSurface.neutral.zebraAlt" borderWidth="$0.25" borderColor="$onSurface.neutral.outlineAlt">
        <Row>
          <Row.Label>
            <FormattedMessage id="product-details.investment-strategy.raise-amount" />
          </Row.Label>
          <Row.Value variant="bold">
            <FormattedNumber
              value={offering.targetRaiseAmount}
              currency="USD"
              style="currency"
              maximumFractionDigits={0}
            />
          </Row.Value>
        </Row>
      </Group>
      <Stack centered>
        <Operator>=</Operator>
      </Stack>
      <Group variant="highlight">
        <Row>
          <Row.Label
            tooltip={
              <FormattedMessage id="product-details.investment-strategy.anticipated-annual-cash-flow.description" />
            }
          >
            <FormattedMessage id="product-details.investment-strategy.anticipated-annual-cash-flow" />
          </Row.Label>
          {/* <Row.Tooltip>
            <Row.Label>
              <FormattedMessage id="product-details.investment-strategy.anticipated-annual-cash-flow" />
            </Row.Label>
            <Tooltip.Content
              tooltipTitle={<FormattedMessage id="product-details.investment-strategy.anticipated-annual-cash-flow" />}
            >
              <Tooltip.Text>
                <FormattedMessage id="product-details.investment-strategy.anticipated-annual-cash-flow.description" />
              </Tooltip.Text>
            </Tooltip.Content>
          </Row.Tooltip> */}
          <Row.Value variant="large">
            <FormattedNumber
              value={dividendYield}
              style="percent"
              maximumFractionDigits={1}
              minimumFractionDigits={1}
            />
          </Row.Value>
        </Row>
      </Group>
    </Stack>
  );
};
