import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function ChartTrendingUpIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path d="M1.66699 14.1668L7.50033 8.3335L11.667 12.5002L18.3337 5.8335" stroke={color} strokeWidth={1.25} />
      <Path d="M13.75 5.8335H18.3333V10.4168" stroke={color} strokeWidth={1.25} />
    </Svg>
  );
}

export const ChartTrendingUpIcon = memo<IconProps>(withIconTheme(ChartTrendingUpIconInternal));
