import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function CloseIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path d="M4 4l12 12M16 4L4 16" stroke={color} strokeWidth={1.25} />
    </Svg>
  );
}

export const CloseIcon = memo<IconProps>(withIconTheme(CloseIconInternal));
