import { useEffect } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postInvestorResponse } from '@arrived/api_v2';
import { useTrackArchetype } from '@arrived/marketing';

import { useGetCurrentUserQuery, useGetInvestorArchetype } from '../users';
import { UseAwaitedMutationOptions } from '../utils';

import { questionnaireInvestorResponsesKeyFn } from './questionnaire.keys';

export function usePostInvestorResponseMutation(options?: UseAwaitedMutationOptions<typeof postInvestorResponse>) {
  const currentUserState = useGetCurrentUserQuery();
  const queryClient = useQueryClient();
  const trackArchetype = useTrackArchetype();
  const currentArchetype = useGetInvestorArchetype();

  useEffect(() => {
    if (currentArchetype) {
      trackArchetype(currentArchetype);
    }
  }, [currentArchetype]);

  return useMutation({
    mutationFn: postInvestorResponse,
    ...options,
    onSuccess(data, variables, context) {
      if (currentUserState.isSuccess) {
        queryClient.invalidateQueries({
          queryKey: questionnaireInvestorResponsesKeyFn(variables.questionnaireId, currentUserState.data?.cid),
        });
      }

      options?.onSuccess?.(data, variables, context);
    },
  });
}
