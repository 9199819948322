import { createContext, useContext } from 'react';

import { Offering } from '@arrived/models';

export interface ProductWidgetContextType {
  offering: Offering;
}

export const ProductWidgetContext = createContext<ProductWidgetContextType>({
  offering: {} as Offering,
});

export const useProductWidgetContext = () => useContext(ProductWidgetContext);
