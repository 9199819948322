import { CONFIG } from '@arrived/config';

import { SheetRoutes } from './SheetRoutes';

/**
 * Root path for "account" nav item
 */
const ACCOUNT_ROUTES = {
  base: '/account',
  activity: '/account/activity',
  /**
   * @deprecated Deprecated Route due to portfolio page rework. Kept to keep redirects as of 06/20/2024
   */
  wallet: '/account/wallet',
  /**
   * @deprecated Deprecated Route due to portfolio page rework. Kept to keep redirects as of 06/20/2024
   */
  walletMoveMoney: '/account/wallet/move-money',
  /**
   * @deprecated Deprecated Route due to portfolio page rework. Kept to keep redirects as of 06/20/2024
   */
  walletTransaction: '/account/wallet/transaction/:transactionCid',
  /**
   * @deprecated Deprecated Route due to portfolio page rework. Kept to keep redirects as of 06/20/2024
   */
  walletCheckoutBase: '/account/wallet/offering/:idOrShortName',
  /**
   * @deprecated Deprecated Route due to portfolio page rework. Kept to keep redirects as of 06/20/2024
   */
  walletCheckout: '/account/wallet/offering/:idOrShortName/trade/*',
  portfolio: '/account/portfolio',
  portfolioOverview: '/account/portfolio/overview',
  portfolioPerformance: {
    dividends: '/account/portfolio/dividends',
    netReturns: '/account/portfolio/net-returns',
    balance: '/account/portfolio/balance',
  },
  portfolioActivity: {
    base: '/account/portfolio/activity',
    transaction: '/account/portfolio/activity/:transactionCid',
    checkout: {
      base: '/account/portfolio/activity/:idOrShortName',
      flow: '/account/portfolio/activity/:idOrShortName/trade/*',
    },
  },
  portfolioDocuments: {
    base: '/account/portfolio/documents',
    tradeDocuments: '/account/portfolio/documents/trade',
    taxDocuments: '/account/portfolio/documents/tax',
    otherDocuments: '/account/portfolio/documents/other',
    // profileUploadDocuments: '/profile/documents/upload',
  },
  singleFamilyIpo: '/account/single-family-residential',
  vacationRental: '/account/vacation-rental',
  singleFamilyFund: '/account/single-family-fund',
  creditFund: '/account/credit-fund',
  shortTermNotes: '/account/short-term-notes',
  arrivedOwnerEquity: '/account/arrived-owner-equity',
  settings: '/account/settings',
} as const;

/**
 * Paths for user account screens.
 */
const ACCOUNTS_ROUTES = {
  accountDetails: '/accounts/:id',
  accountEdit: '/accounts/:id/edit',
  accountSuitability: '/accounts/suitability-questionnaire',
  base: '/accounts',
  createAccount: '/accounts/create',
  newAccountSuccess: '/accounts/:id/new/success',
  addBank: '/accounts/bank/link',
  newBank: '/accounts/:id/bank/new',
  verifyBank: '/accounts/bank/verify',
  newBankOnboarding: '/accounts/:id/bank/new/onboarding',
} as const;

const TEST_ROUTES = {
  base: '/admin/test',
  buttons: '/admin/test/buttons',
} as const;

const ADMIN_USER_BASE = '/admin/user';
const ADMIN_OFFERING_BASE = '/admin/offerings';
const ADMIN_PROPERTIES_BASE = '/admin/properties';
const ADMIN_TRANSACTIONS_BASE = '/admin/transactions';

const ADMIN_ROUTES = {
  base: '/admin',
  dev: '/admin/dev',
  markets: '/admin/markets',
  FIXED_RATE_INVESTMENTS: {
    base: '/admin/fixed-rate-investments',
    details: '/admin/fixed-rate-investments/:id',
  },
  promotions: '/admin/promotions',
  offeringHistory: '/admin/offering-history',
  closings: '/admin/closings',
  OFFERINGS: {
    base: ADMIN_OFFERING_BASE,
    details: `${ADMIN_OFFERING_BASE}/:id`,
  },
  profile: '/admin/profile',
  PROPERTIES: {
    base: ADMIN_PROPERTIES_BASE,
    details: `${ADMIN_PROPERTIES_BASE}/:id`,
    features: `${ADMIN_PROPERTIES_BASE}/features`,
  },
  TRANSACTIONS: {
    base: ADMIN_TRANSACTIONS_BASE,
    create: `${ADMIN_TRANSACTIONS_BASE}/create`,
    // trades: `${ADMIN_TRANSACTIONS_BASE}/trades`,
  },
  USERS: {
    base: ADMIN_USER_BASE,
    details: `${ADMIN_USER_BASE}/:id`,
    transactionDetails: `${ADMIN_USER_BASE}/:id/transactions/:transactionCid`,
  },
  QUESTIONNAIRES: {
    base: '/admin/questionnaires',
    details: '/admin/questionnaires/:id',
    questionDetails: '/admin/questionnaires/questions/:id',
  },
  test: TEST_ROUTES,
} as const;

const PORTFOLIO_ROUTES = {
  base: '/portfolio',
} as const;

const DOCUMENT_ROUTES = {
  base: '/documents',
  documentREITLetter: '/documents/reit-demand-letter',
} as const;

const PROFILE_ROUTES = {
  base: '/profile',
  profileSecurity: '/profile/security',
  profileBasicInfo: '/profile/basic-info',
  profileOnboardingWelcome: '/profile/welcome',
  profileOnboarding: '/profile/onboarding',
  profileAbout: '/profile/about',
  profileAccount: '/profile/account',
  profileName: '/profile/account/name',
  profilePhoneNumber: '/profile/account/phone-number',
  profileBeneficiaries: '/profile/account/beneficiaries',
  profileBirthDate: '/profile/account/birth-date',
  profileTaxId: '/profile/account/tax-id',
  profileEntityInfo: '/profile/account/entity-info',
  profilePrimaryResidenceAddress: '/profile/account/primary-residence-address',
  profileBusinessAddress: '/profile/account/business-address',
  profileMailingAddress: '/profile/account/mailing-address',
  profilePrimaryResidence: '/profile/account/primary-residence',
  profileDocuments: '/profile/documents',
  profileTradeDocuments: '/profile/documents/trade',
  profileTaxDocuments: '/profile/documents/tax',
  profileOtherDocuments: '/profile/documents/other',
  profileUploadDocuments: '/profile/documents/upload',
  profileUploadIdentityDocuments: '/profile/documents/identity/upload',
  profileTransactions: '/profile/transactions',
  profileScheduleMeeting: '/profile/meeting',
  profileScheduleWebinar: '/profile/webinar',
  profileNetWorth: '/profile/net-worth',
} as const;

const REFERRAL_ROUTES = {
  base: '/refer',
  earn: '/earn',
  redeem: '/profile/redeem-code',
} as const;

const INLINE_PROPERTY_TRADE_ROOT = '/properties/:idOrShortName';

const PROPERTIES_ROUTES = {
  base: '/properties',
  propertyDetails: INLINE_PROPERTY_TRADE_ROOT,
  newTrades: `${INLINE_PROPERTY_TRADE_ROOT}/trade/*`,
} as const;

const PUBLIC_ROUTES = {
  base: '/',
  blog: {
    base: '/blog',
    categories: {
      base: '/blog/categories',
      category: '/blog/categories/[categorySlug]',
    },
    posts: '/blog/[postSlug]',
  },
  properties: {
    base: '/properties',
    propertyDetails: {
      base: '/properties/[shortName]',
    },
  },
  about: '/about',
  press: '/about#press',
  careers: '/careers',
  returns: '/returns',
  vacationRentals: '/vacation-rentals',
  offeringCircular: '/circulars',
  register: '/register',
} as const;

const STATIC_ROUTES = {
  learning: `${CONFIG.learnBlogUrl}/`,
  blog: `${CONFIG.learnBlogUrl}/`,
  howItWorks: `${CONFIG.learnBlogUrl}/how-it-works/`,
  whyInvest: `${CONFIG.learnBlogUrl}/why-invest-in-arrived-homes/`,
  glossary: `${CONFIG.learnBlogUrl}/glossary/`,
  partnerWithArrivedSurvey: 'https://www.surveymonkey.com/r/collabwitharrived',
  realEstateInvestingGuide: `${CONFIG.learnBlogUrl}/real-estate-investing-guide/`,
  help: CONFIG.helpUrl,
  careers: CONFIG.careersUrl,
  base: CONFIG.publicAppRoot,
  webinar: `${CONFIG.publicAppRoot}/webinar`,

  checkbookIRAVacationRentalFAQ: `${CONFIG.helpUrl}articles/6524055-can-i-invest-in-a-vacation-rentals-with-my-checkbook-ira`,
  stakeholderCommitments: `${CONFIG.learnBlogUrl}/arrived-commitments`,
} as const;

export const ROUTES = {
  verifyEmail: '/verify-email',
  aboutUs: '/about',
  accounts: ACCOUNTS_ROUTES,
  account: ACCOUNT_ROUTES,
  cashAccounts: '/cash-account',
  admin: ADMIN_ROUTES,
  authCallback: '/auth/callback',
  base: '/',
  careers: '/careers',
  communicationsDisclaimer: '/communications-disclaimer',
  liveHelp: '/live-help',
  logIn: '/login',
  logOut: '/logout',
  logOutCleanUp: '/logout/callback',
  marketing: '/marketing',
  portfolio: PORTFOLIO_ROUTES,
  privacyPolicy: '/privacy-policy',
  privacy: '/privacy',
  profile: PROFILE_ROUTES,
  properties: PROPERTIES_ROUTES,
  referral: REFERRAL_ROUTES,
  returns: '/returns',
  signUp: '/signup',
  register: '/register',
  referralTermsAndConditions: '/referrals-terms-and-conditions',
  termsOfService: '/terms-of-service',
  tos: '/tos',
  static: STATIC_ROUTES,
  fourOhThree: '/403',
  fourOhFour: '/404',
  fiveHundred: '/500',
  maintenance: '/maintenance',
  documents: DOCUMENT_ROUTES,
  public: PUBLIC_ROUTES,
  questionnaire: '/questionnaire/:id',

  sheet: SheetRoutes,
} as const;

// accounts/documents/trades
export interface MatchIdParams {
  id: string;
}

// Typically, used for properties/invest
export interface MatchIdOrShortNameParams {
  idOrShortName: string;
}
