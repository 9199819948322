import { FormattedMessage } from 'react-intl';

import { AppreciationIcon, InfoBox, RentalIncomeIcon, styled } from '@arrived/bricks';

import { GraphicItem, GraphicText } from './GraphicItem';

const GraphicFrame = styled(InfoBox, {
  gap: '$2',
  alignItems: 'center',
  $gtMd: { height: '100%', justifyContent: 'center' },
});

export const Graphic = GraphicFrame.styleable((props, ref) => (
  <GraphicFrame ref={ref} {...props}>
    <GraphicItem Icon={RentalIncomeIcon}>
      <FormattedMessage id="product-details.investment-strategy.annual-cash-flow" />
    </GraphicItem>
    <GraphicText>+</GraphicText>
    <GraphicItem Icon={AppreciationIcon}>
      <FormattedMessage id="product-details.investment-strategy.appreciation" />
    </GraphicItem>
  </GraphicFrame>
));
