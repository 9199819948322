import { ReactNode } from 'react';

import { Stack, TagPropsNonPressable, styled } from '@arrived/bricks';

import { PRODUCT_LIST_ITEM_TAGS_NAME } from './constants';

export interface ProductListItemTagProps extends TagPropsNonPressable {
  tagContents: ReactNode;
}

export const ProductListItemTags = styled(Stack, {
  name: PRODUCT_LIST_ITEM_TAGS_NAME,

  row: true,
  gap: '$1',
  alignItems: 'flex-start',
});
