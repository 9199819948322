import { Account, Offering, OfferingStatus, SecurityType } from '@arrived/models';

import { UserOfferingTransactState } from '../constants';

export interface GetUserOfferingTransactStateArgs {
  account?: Account;
  canTradeRegDOffering?: boolean;
  canUserTransactOpenSecTrade?: boolean;
  canUserTransactEarlyAccess?: boolean;
  canViewRegDOffering?: boolean;
  isAuthenticated?: boolean;
  isLoading?: boolean;
  isOfferingBlocked?: boolean;
  isOfferingMaxedOut?: boolean;
  isOfferingSoldOut?: boolean;
  isVerified?: boolean;
  offering: Offering;
}

export interface GetTransactableStateArgs {
  canTradeRegDOffering?: boolean;
  canTradeOpenForSecOffering?: boolean;
  canViewRegDOffering?: boolean;
  securityType: SecurityType;
}

/**
 * Returns the UserOfferingTransactState when we know that the Offering is in a transactable state
 * with respect to the user's early access status and the OfferingStatus.
 */
export const getTransactableState = ({
  canTradeRegDOffering,
  canViewRegDOffering,
  securityType,
}: GetTransactableStateArgs) => {
  // All signed up users can transact on Reg A Offerings
  if (securityType === SecurityType.REG_A) {
    return UserOfferingTransactState.TRANSACT;
    // We only have 2 security types (Reg A and D) so at this point we know the Offering is Reg D
  } else {
    if (canTradeRegDOffering) {
      return UserOfferingTransactState.TRANSACT;
      // The only case in which we should get to here (the Offering shouldn't load for the user if
      // they don't have Reg D access), is if the user is an admin, in which case we'll just show
      // them as unaccredited in the UI
    } else if (!canViewRegDOffering) {
      return UserOfferingTransactState.UNACCREDITED;
      // Otherwise if we know the user _can view_ the Reg D Offering but _cannot trade_ the Reg D
      // Offering, we need to prompt them to update their accreditation status
    } else {
      return UserOfferingTransactState.ACCREDITATION_STATUS_UPDATE_REQUIRED;
    }
  }
};

export function getUserOfferingTransactState({
  account,
  canTradeRegDOffering,
  canUserTransactOpenSecTrade,
  canUserTransactEarlyAccess,
  canViewRegDOffering,
  isAuthenticated,
  isLoading,
  isOfferingBlocked,
  isOfferingMaxedOut,
  isOfferingSoldOut,
  isVerified,
  offering,
}: GetUserOfferingTransactStateArgs) {
  if (isOfferingBlocked) {
    return UserOfferingTransactState.BLOCKED;
  }

  if (!isAuthenticated) {
    return UserOfferingTransactState.NEEDS_LOGIN;
  }

  if (isLoading) {
    return UserOfferingTransactState.LOADING;
  }

  if (!account) {
    return UserOfferingTransactState.SETUP_ACCOUNT;
  }

  if (!isVerified) {
    return UserOfferingTransactState.VERIFICATION_REQUIRED;
  }

  if (isOfferingSoldOut) {
    return UserOfferingTransactState.FUNDED;
  }

  if (isOfferingMaxedOut) {
    return UserOfferingTransactState.MAXED_OUT;
  }

  switch (offering.status) {
    case OfferingStatus.PRE_LAUNCH:
      return UserOfferingTransactState.PRE_LAUNCH;
    case OfferingStatus.COMING_SOON:
      return UserOfferingTransactState.COMING_SOON;
    case OfferingStatus.TRANSACT_EARLY_ACCESS:
      return canUserTransactEarlyAccess
        ? getTransactableState({ canTradeRegDOffering, canViewRegDOffering, securityType: offering.securityType })
        : UserOfferingTransactState.COMING_SOON;
    case OfferingStatus.TRANSACT_ALL:
      return getTransactableState({ canTradeRegDOffering, canViewRegDOffering, securityType: offering.securityType });
    case OfferingStatus.FUNDED:
      /**
       * If the offering is funded and the offering's isOpenForSec is true,
       * and the user is allowed to transact on the open for SEC trade offering,
       * then we set the status to TRANSACT. Otherwise, we set the status to FUNDED.
       */
      if (offering.isOpenForSec && canUserTransactOpenSecTrade) {
        return UserOfferingTransactState.TRANSACT;
      }
      return UserOfferingTransactState.FUNDED;

    case OfferingStatus.PAUSED:
      return UserOfferingTransactState.PAUSED;
    default:
      // There are other states that we don't currently handle so we'll return a catch all for those.
      return UserOfferingTransactState.UNKNOWN;
  }
}
