import { useMutation, useQueryClient } from '@tanstack/react-query';
import _merge from 'lodash/merge';

import { postUserAccountSuitabilityQuestionnaire } from '@arrived/api_v2';
import { useTrackAccreditedConversionEvent } from '@arrived/marketing';
import { Account, User } from '@arrived/models';

import { offeringsKey } from '../../offerings';
import { accountsPrimaryKeyFn, currentUserKeyFn, userKeyFn } from '../../users';
import { UseAwaitedMutationOptions } from '../../utils';
import { accountsKeyFn } from '../accounts.keys';
import { regDStatusKeyFn } from '../regDStatus/regDStatus.keys';

export function usePostSuitabilityQuestionnaireMutation(
  options?: UseAwaitedMutationOptions<typeof postUserAccountSuitabilityQuestionnaire>,
) {
  const queryClient = useQueryClient();
  const trackAccreditedConversionEvent = useTrackAccreditedConversionEvent();

  return useMutation({
    mutationFn: (request) => postUserAccountSuitabilityQuestionnaire(request),
    ...options,
    onSuccess: (data, variables, context) => {
      const account = queryClient.getQueryData<Account>(accountsKeyFn(data.accountId));
      const user = queryClient.getQueryData<User>(currentUserKeyFn());

      /**
       * When the suitability questionnaire is updated we need to invalidate a number of queries:
       * 1. The account query
       * 2. The current user query
       * 3. The offerings query
       * 4. The regDStatus query
       * 5. The primary account query
       */

      // NOTE: We would also ideally be invalidating the queries with the key from adminAccountsPrimaryKeyFn, however,
      // we shouldn't be important admin specific query functions into the regular queries package, so we'll leave consumers
      // of this hook to do that invalidation where necessary.
      queryClient.invalidateQueries({ queryKey: accountsKeyFn(data.accountId) });
      queryClient.invalidateQueries({ queryKey: currentUserKeyFn() });
      // Invalidate _all_ Offerings, including individual offerings, this will cause the data to
      // be refetched and in the case of being on the IDP for a now inaccessible Offering (in the
      // case an investor updates their status to non-accreditted), the user will be kicked off
      // the page due to a 404.
      queryClient.invalidateQueries({ queryKey: userKeyFn(data.userId) });
      queryClient.invalidateQueries({ queryKey: [offeringsKey] });

      // TODO: Since we can only fetch the Reg D Summary Access information with a CID, it would be
      // preferable if we got back a CID from the suitability questionnaire, but currently we do
      // not so we'll have to use the regular ID instead.
      queryClient.invalidateQueries({ queryKey: regDStatusKeyFn(data.accountId) });

      if (account) {
        // Update the query data with the updated suitability questionnaire
        queryClient.setQueryData<Account>(
          accountsKeyFn(data.accountId),
          _merge({}, account, { suitabilityQuestionnaire: data }),
        );
      }

      if (user) {
        const primaryAccount = queryClient.getQueryData<Account>(accountsPrimaryKeyFn(user.id));

        // Same here, update the query data with the updated suitability questionnaire
        if (primaryAccount?.id === data.accountId) {
          queryClient.setQueryData<Account>(
            accountsPrimaryKeyFn(user.id),
            _merge({}, primaryAccount, { suitabilityQuestionnaire: data }),
          );

          queryClient.invalidateQueries({ queryKey: accountsPrimaryKeyFn(user.id) });
        }

        if (user.accreditedInvestor) {
          trackAccreditedConversionEvent({
            userId: data.userId,
          });
        }
      }

      options?.onSuccess?.(data, variables, context);
    },
  });
}
