import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postRevokeVoucher } from '@arrived/api_v2';
import { UserId } from '@arrived/models';

import { adminVoucherKeyFn } from './rewards.keys';

export function useAdminPostVoucherRevokeMutation(userId: UserId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postRevokeVoucher,
    onSuccess: (data) => {
      queryClient.setQueryData(adminVoucherKeyFn(userId), data);

      queryClient.invalidateQueries({ queryKey: adminVoucherKeyFn(userId) });
    },
  });
}
