import { useEffect } from 'react';

import { useCallbackRef } from '@tamagui/use-callback-ref';

type UseEscapeKeydownProps = {
  onLeftKeydown?: (event: KeyboardEvent) => void;
  onRightKeydown?: (event: KeyboardEvent) => void;
  onDownKeydown?: (event: KeyboardEvent) => void;
  onUpKeydown?: (event: KeyboardEvent) => void;
  ownerDocument?: Document;
};

/**
 * Listens for when the escape key is down
 */
export function useArrowKeydown({
  onLeftKeydown: onLeftKeyDownProp,
  onRightKeydown: onRightKeyDownProp,
  onDownKeydown: onDownKeyDownProp,
  onUpKeydown: onUpKeyDownProp,
  ownerDocument = globalThis?.document,
}: UseEscapeKeydownProps) {
  const onLeftKeyDown = useCallbackRef(onLeftKeyDownProp);
  const onRightKeyDown = useCallbackRef(onRightKeyDownProp);
  const onDownKeyDown = useCallbackRef(onDownKeyDownProp);
  const onUpKeyDown = useCallbackRef(onUpKeyDownProp);

  useEffect(() => {
    if (!ownerDocument) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          onLeftKeyDown?.(event);
          break;
        case 'ArrowRight':
          onRightKeyDown?.(event);
          break;
        case 'ArrowDown':
          onDownKeyDown?.(event);
          break;
        case 'ArrowUp':
          onUpKeyDown?.(event);
          break;
      }
    };

    ownerDocument.addEventListener('keydown', handleKeyDown);

    return () => {
      ownerDocument.removeEventListener('keydown', handleKeyDown);
    };
  }, [onLeftKeyDown, onRightKeyDown, onDownKeyDown, onUpKeyDown, ownerDocument]);
}
