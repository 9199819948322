export enum TransactionStatus {
  /**
   * Investment has been created and funds have not yet been received.
   */
  CREATED = 'CREATED',

  /**
   * Funds have been received in escrow.
   */
  FUNDED = 'FUNDED',

  /**
   * The investment has been accepted by the Issuer and funds have been released from escrow to the Issuer.
   */
  SETTLED = 'SETTLED',

  /**
   * Funds were never received in escrow and/or the investor has cancelled their intention to invest (this status is
   * done through the deleteTrade method)
   */
  CANCELLED = 'CANCELLED',
  CANCELLED_PENDING = 'CANCELLED_PENDING',

  /**
   * The funds have been received in escrow and is pending to be returned to the investor.
   */
  UNWIND_PENDING = 'UNWIND_PENDING',

  /**
   * The funds have been received in escrow then returned back to the investor.
   */
  UNWIND_SETTLED = 'UNWIND_SETTLED',
}
