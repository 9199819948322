import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { TRANSACTION_STATUS_INTL_MAP } from '@arrived/common';
import { ArrivedTooltip, ArrivedTooltipProps } from '@arrived/features-tooltips';
import { LEASE_STATUS_MESSAGES_STRINGS, LeaseStatus, TransactionStatus } from '@arrived/models';

import { LeaseStatusMessage } from '../PropertyPage';

export const LeaseStatusMessageWithTooltip = (props: { leaseStatus?: LeaseStatus }) => {
  const { leaseStatus } = props;

  if (!leaseStatus) return <FormattedMessage id="common.not-applicable" />;
  const tooltipStrings = LEASE_STATUS_MESSAGES_STRINGS[leaseStatus].tooltip;
  if (!tooltipStrings) {
    return <LeaseStatusMessage leaseStatus={leaseStatus} />;
  }

  return (
    <ArrivedTooltip
      {...props}
      tooltipContent={<FormattedMessage id={tooltipStrings.id} defaultMessage={tooltipStrings.text} />}
    >
      <LeaseStatusMessage leaseStatus={leaseStatus} />
    </ArrivedTooltip>
  );
};

interface TradeStatusTooltipProps extends Omit<ArrivedTooltipProps, 'tooltipContent' | 'children'> {
  status: TransactionStatus;
}

export const TradeStatusTooltip = (props: TradeStatusTooltipProps) => {
  const { status } = props;
  const transactionStatusList = Object.values(TransactionStatus);

  return (
    <ArrivedTooltip
      {...props}
      tooltipContent={
        <>
          <Box
            sx={{
              width: '100%',
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: ({ palette }) => palette.primary.main,
              color: ({ palette }) => palette.neutrals.white,
              fontSize: 20,
              paddingTop: '4px',
            }}
          >
            <FormattedMessage
              id="trade-status-definitions-tooltip-text-title"
              defaultMessage="TRADE STATUS DEFINITIONS"
            />
          </Box>
          <div className="trade-status-table">
            {transactionStatusList.map((transactionStatus, index) => {
              const { description, label } = TRANSACTION_STATUS_INTL_MAP[transactionStatus];

              return (
                <div key={index} className="key-value-row">
                  <div className="key">{label}</div>
                  <div className="value">{description}</div>
                </div>
              );
            })}
          </div>
        </>
      }
    >
      <Typography>{TRANSACTION_STATUS_INTL_MAP[status].label}</Typography>
    </ArrivedTooltip>
  );
};
