export default {
  'blog.category.featured': 'Featured',
  'blog.category.latest': 'Latest',
  'blog.learn': 'Learn',
  'blog.read-more': 'Read more',
  'blog.blog-page.disclaimers.title': 'Disclaimers',
  'blog.blog-page.disclaimers.link.text': "View Arrived's disclaimers",
  'blog.blog-page.recent-articles.title': 'Recent Articles',
  'blog.blog-page.sections.title': 'Sections',
  'blog.blog-page.error': 'An error occurred while fetching the blog post, please reload the page.',
  'blog.faq.section.title': 'FAQ',
  'blog.faq.more-questions':
    'Have more questions? Visit our <faqLink>Frequently Asked Questions</faqLink> for in-depth answers.',
  'blog.search.no-results':
    'No results found for "{query}". You may want to try different keywords or check for any possible typos.',
  'blog.search.explore': 'Enter a keyword to learn more about Arrived',
  'blog.article-navigation.aria-label': 'Article Navigation - Use this to navigate to different header sections',
} as const;
