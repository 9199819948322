import { TamaguiProvider, TamaguiProviderProps, isServer } from '@tamagui/core';

import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { PortalProvider } from '@gorhom/portal';

import { TooltipControllerProvider } from './atoms/tooltip/TooltipController';
import { bricksThemeConfig } from './config';
import { GestureHandlerRootView, PortalContextProvider, SafeAreaProviderCompat } from './utils';

interface BricksProviderProps extends Omit<TamaguiProviderProps, 'config'> {
  overrides?: Partial<TamaguiProviderProps['config']>;
}

export const BricksProvider = ({ children, ...rest }: BricksProviderProps) => (
  <SafeAreaProviderCompat>
    <GestureHandlerRootView>
      <TamaguiProvider {...rest} config={bricksThemeConfig} defaultTheme="light">
        <BottomSheetModalProvider>
          <PortalProvider shouldAddRootHost={false}>
            <PortalContextProvider portalHostName="root">
              {!isServer ? <TooltipControllerProvider>{children}</TooltipControllerProvider> : children}
            </PortalContextProvider>
          </PortalProvider>
        </BottomSheetModalProvider>
      </TamaguiProvider>
    </GestureHandlerRootView>
  </SafeAreaProviderCompat>
);
