import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function TagInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M1.667 8.333V1.667h6.666l10 10-6.666 6.666-10-10Zm2.916-3.75L6.25 6.25"
        stroke={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const TagIcon = memo<IconProps>(withIconTheme(TagInternal));
