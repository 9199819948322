import { useMutation, useQueryClient } from '@tanstack/react-query';

import { putNCSyncOfferingTransaction } from '@arrived/api_v2';
import { tradeQueryKeyFn, userTradesQueryKeyFn } from '@arrived/queries';

import { offeringTradesQueryKeyFn } from './trades.keys';

export function useAdminPostNCSyncMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putNCSyncOfferingTransaction,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: userTradesQueryKeyFn(data.userId) });
      queryClient.invalidateQueries({ queryKey: offeringTradesQueryKeyFn(data.offeringId) });
      queryClient.invalidateQueries({ queryKey: tradeQueryKeyFn(data.id) });
    },
  });
}
