import { UtilityText, styled } from '@arrived/bricks';

import { PRODUCT_LIST_ITEM_TITLE_NAME } from './constants';
import { ProductListItemStyledContext } from './ProductListItemStyledContext';

export const ProductListItemTitle = styled(UtilityText, {
  name: PRODUCT_LIST_ITEM_TITLE_NAME,

  context: ProductListItemStyledContext,

  token: 'utility.label.small',
  ellipse: true,

  variants: {
    size: {
      small: {},
      large: {
        token: 'utility.label.medium',
      },
    },
  } as const,
});
