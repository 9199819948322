import { createStyledContext } from '@tamagui/core';

/**
 * The context for handling `variants` on the properties,
 * if you are interacting with the `Sheet` component directly please use
 * the `SheetImperativeContext` instead. This should only be used for `styled` variants.
 *
 * Honestly, the `SheetImperativeContext` should work for this. However, it isn't
 * applying the right values to the `Sheet` component. So, we are using this
 * context to handle the `variants` for now.
 */
export const GalleryStyledContext = createStyledContext({
  isHovered: false,
});
