import { Stack, styled } from '@arrived/bricks';

import { ProductHeaderContentName } from './constants';

export const Content = styled(Stack, {
  name: ProductHeaderContentName,

  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  columnGap: '$6',
  rowGap: '$2',
});
