import { FormattedMessage } from 'react-intl';

import { EntityType } from '@arrived/models';

export const EntityTypeTranslations = {
  [EntityType.REVOCABLE_TRUST]: (
    <FormattedMessage id="common.entity-type.REVOCABLE_TRUST" defaultMessage="Revocable Trust" />
  ),
  [EntityType.IRREVOCABLE_TRUST]: (
    <FormattedMessage id="common.entity-type.IRREVOCABLE_TRUST" defaultMessage="Irrevocable Trust" />
  ),
  [EntityType.LIMITED_PARTNERSHIP]: (
    <FormattedMessage id="common.entity-type.LIMITED_PARTNERSHIP" defaultMessage="Limited Partnership" />
  ),
  [EntityType.LLC]: <FormattedMessage id="common.entity-type.LLC" defaultMessage="LLC" />,
  [EntityType.CORPORATION]: <FormattedMessage id="common.entity-type.CORPORATION" defaultMessage="Corporation" />,
} as const;

export const formatEntityType = (type?: EntityType) => (type && EntityTypeTranslations[type]) ?? 'N/A';
