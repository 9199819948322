import { Markdown } from '@arrived/bricks-common';
import { SFRFundData } from '@arrived/models';

import { ProductDetailContent } from '../../../../../content';
import { Video } from '../../../../../video';

export const InvestmentStrategy = ({
  investmentStrategy: { attributes, video },
}: {
  investmentStrategy: NonNullable<SFRFundData['investmentStrategy']>;
}) => (
  <>
    <Markdown Text={ProductDetailContent.Text}>{attributes}</Markdown>
    {video && (
      <Video youtubeId={video.youtubeId}>
        {video.imageUri && <Video.Image imageUri={video.imageUri} />}
        <Video.Title>{video.title}</Video.Title>
        {video.subTitle && <Video.SubTitle>{video.subTitle}</Video.SubTitle>}
      </Video>
    )}
  </>
);
