import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function KeyInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <Path
        d="M5 10h4.167v4.167L6.25 17.083l-4.167-4.166L5 10Zm4.167 0 6.666-6.667L18.75 6.25m-5.417-.417L16.25 8.75"
        stroke={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const KeyIcon = memo<IconProps>(withIconTheme(KeyInternal));
