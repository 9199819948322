import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function EditIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M6.66669 18.3333L18.3333 6.66663L13.3333 1.66663L1.66667 13.3333L1.66669 18.3333H6.66669Z"
        stroke={color}
        strokeWidth="1.25"
      />
      <Path d="M10.4167 4.58337L15.4167 9.58337" stroke={color} strokeWidth="1.25" />
    </Svg>
  );
}

export const EditIcon = memo<IconProps>(withIconTheme(EditIconInternal));
