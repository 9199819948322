import { PlannedInvestmentAmount } from '@arrived/models';

// Because we map the PlannedInvestmentAmount to a string, we cannot order it inherently so instead we have a defined
// mapping of the planned investment amount to it's hierarchical order in terms of money amount.
const plannedInvestmentAmountOrder: Record<PlannedInvestmentAmount, number> = {
  [PlannedInvestmentAmount.R100_2000]: 0,
  [PlannedInvestmentAmount.R2000_5000]: 1,
  [PlannedInvestmentAmount.R5000_25000]: 2,
  [PlannedInvestmentAmount.R25000_100000]: 3,
  [PlannedInvestmentAmount.R100000_OR_MORE]: 4,
};

/**
 * Fallback threshold for allowing a user to schedule an intro meeting with the Arrived team.
 * This is controlled via Growthbook but if for whatever reason Growthbook fails, this is a fallback
 */
const FALLBACK_INTRO_MEETING_INVESTMENT_AMOUNT_MINIMUM: PlannedInvestmentAmount = PlannedInvestmentAmount.R2000_5000;

export const isGreaterThanOrEqualToIntroMeetingMinimum = (
  plannedInvestmentAmount: PlannedInvestmentAmount | string | undefined,
  investmentMinimum: PlannedInvestmentAmount = FALLBACK_INTRO_MEETING_INVESTMENT_AMOUNT_MINIMUM,
): boolean => {
  if (!plannedInvestmentAmount) {
    return false;
  }

  if (plannedInvestmentAmount in PlannedInvestmentAmount) {
    return (
      plannedInvestmentAmountOrder[plannedInvestmentAmount as PlannedInvestmentAmount] >=
      plannedInvestmentAmountOrder[investmentMinimum]
    );
  }

  return false;
};
