import { Offering, TotalAvailableSharesResponse } from '@arrived/models';

export const getMinSharesToBuy = (offering: Offering): number => {
  return Math.ceil(offering.minTransactionAmount / offering.sharePrice);
};

/**
 * The maximum number of shares a user can purchase
 * is 9.8% of the total shares available for the offering.
 */
export const MAX_SHARE_PERCENTAGE = 0.098;

interface GetMaxSharesArgs {
  totalShares: Offering['totalShares'];
  sharesAvailable: TotalAvailableSharesResponse;
}

/**
 * This determines the maximum number of shares a user can purchase using
 * the total shares available for the offering.
 */
export const getMaxShares = ({ totalShares, sharesAvailable }: GetMaxSharesArgs): number => {
  if (sharesAvailable) {
    return sharesAvailable.availableSharesCount;
  }

  return Math.floor(totalShares * MAX_SHARE_PERCENTAGE);
};
