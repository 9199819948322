import { forwardRef } from 'react';

import { DataPoint, DataPointProps, TamaguiElement } from '@arrived/bricks';

export type DetailDataPointProps = Omit<DataPointProps<false>, 'flex'> & { flex?: boolean };

export const DetailDataPoint = forwardRef<TamaguiElement, DetailDataPointProps>(
  ({ flex = true, $gtXxs, ...rest }, ref) => (
    <DataPoint
      colors="dark"
      variant="minimized"
      $gtXxs={flex ? { flexShrink: 1, flexGrow: 1, flexBasis: 0, ...$gtXxs } : $gtXxs}
      ref={ref}
      {...rest}
    />
  ),
);
