import { Fragment, useId, useMemo } from 'react';

import { Stack, TitleText, getTokens, styled, useMedia } from '@arrived/bricks';

import { Blocks, BuilderBlock, BuilderDataProps, GenericBuilderIcons, RegisteredComponent } from '../../sdk';
import { TextBlockElement } from '../atoms';
import { ButtonBlockElement } from '../molecules';

const InfoGridTitleText = styled(TitleText, {
  textAlign: 'center',
  token: 'title.heading.xlarge',

  $gtSm: {
    token: 'title.display.small',
  },
});

const InfoGridContainer = styled(Stack, {
  flex: 1,
  gap: '$4',
  p: '$4',

  $gtXs: {
    gap: '$8',
    p: '$12',
  },
});

const InfoGridRow = styled(Stack, {
  flex: 1,
  flexWrap: 'wrap',
  gap: '$6',

  row: false,

  $gtXs: {
    gap: '$8',
    row: true,
  },
});

type InfoGridProps = {
  heading?: string;
  content?: string;
  cta?: BuilderBlock[];
  numberOfColumns?: number;
  columns?: {
    heading?: string;
    content?: string;
  }[];
  hideCta?: boolean;
} & BuilderDataProps;

export const InfoGrid = ({
  builderContext,
  heading,
  content,
  hideCta,
  columns,
  cta,
  numberOfColumns: numberOfColumnsProp = 3,
}: InfoGridProps) => {
  const id = useId();
  const media = useMedia();
  const tokens = getTokens();

  const numberOfColumns = useMemo(() => Math.min(4, numberOfColumnsProp ?? 1), [numberOfColumnsProp]);

  return (
    <InfoGridContainer>
      {Boolean(heading) && <InfoGridTitleText>{heading}</InfoGridTitleText>}
      {Boolean(content) && (
        <Blocks
          path="component.options.content"
          blocks={[
            TextBlockElement({
              type: 'BodyText',
              text: content,
            }),
          ]}
        />
      )}
      <InfoGridRow>
        {columns?.map((column, index) => (
          // Ensure unique keys for each column and then for each InfoGrid element
          <Fragment key={`columns-${id}-${index}`}>
            <Stack
              flexBasis="100%"
              gap="$4"
              flexGrow={0}
              flexShrink={1}
              $gtXs={{
                '$platform-web': {
                  // @ts-expect-error calc function is a web-only property
                  flexBasis: `calc((100% / ${numberOfColumns}) -  (${tokens.space['$8'].variable} / ${numberOfColumns} * (${numberOfColumns} - 1)))`,
                },
              }}
            >
              {Boolean(column.heading) && <TitleText token="title.heading.large">{column.heading}</TitleText>}
              {Boolean(column.content) && (
                <Blocks
                  context={builderContext}
                  path="component.options.columns"
                  blocks={[
                    TextBlockElement({
                      type: 'BodyText',
                      text: column.content,
                    }),
                  ]}
                />
              )}
            </Stack>
            {/* Add a spacer only when gtSm and we are divisible by the column number (1-4) */}
            {media.gtXs &&
              // Ignore if we only have 1 column, then `gap` takes over
              numberOfColumns !== 1 &&
              // If we are divisible by the number of columns, add a spacer
              (index + 1) % numberOfColumns === 0 &&
              // Ignore if we are at the last column
              index + 1 !== columns.length && <Stack py="$10" width="100%" />}
          </Fragment>
        ))}
      </InfoGridRow>
      <Stack centered>
        {!hideCta && <Blocks path="component.options.content.cta" blocks={cta} context={builderContext} />}
      </Stack>
    </InfoGridContainer>
  );
};

InfoGrid.registerComponent = {
  component: InfoGrid,
  shouldReceiveBuilderProps: {
    builderContext: true,
    builderBlock: true,
  },
  name: 'Bricks:InfoGrid',
  friendlyName: 'Info Grid',
  description: 'A layout that takes individual text items and puts them in a row or column depending on screen size.',
  docsLink: 'https://www.figma.com/design/pOLoKPNXzfRiDTqAeP9YBr/%F0%9F%A7%B1-Builder.io-Blocks?node-id=6205-304707',
  image: GenericBuilderIcons.TripleColumns,
  canHaveChildren: false,
  inputs: [
    {
      name: 'heading',
      friendlyName: 'Heading',
      helperText: 'The heading of the grid',
      type: 'longText',
      defaultValue: 'Heading',
    },
    {
      name: 'content',
      friendlyName: 'Content',
      helperText: 'The content of the grid',
      type: 'richText',
      defaultValue: 'Content',
    },
    {
      name: 'numberOfColumns',
      friendlyName: 'Number of Columns',
      helperText:
        'The number of columns to display - max of 4. When 1 is selected, the columns will stack vertically. Otherwise on desktop, they will have a large space divider between each row.',
      type: 'number',
      defaultValue: 3,
      max: 4,
      min: 1,
    },
    {
      name: 'columns',
      type: 'array',
      broadcast: true,
      subFields: [
        {
          name: 'heading',
          helperText: 'The heading of the column',
          type: 'longText',
          defaultValue: 'Heading',
        },
        {
          name: 'content',
          helperText: 'The content of the column',
          type: 'richText',
          defaultValue: 'Content',
        },
      ],
      defaultValue: [
        {
          heading: 'Column 1',
          content: 'Content 1',
        },
        {
          heading: 'Column 2',
          content: 'Content 2',
        },
        {
          heading: 'Column 3',
          content: 'Content 3',
        },
      ],
    },
    {
      name: 'cta',
      friendlyName: 'Call to Action',
      helperText: 'The call to action of the grid',
      type: 'uiBlocks',
      broadcast: true,
      defaultValue: [ButtonBlockElement()],
    },
    {
      name: 'hideCta',
      friendlyName: 'Hide Call to Action',
      helperText: 'Hide the call to action button',
      type: 'boolean',
      defaultValue: false,
    },
  ],
} satisfies RegisteredComponent;
