import { Fragment, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { PrivateCreditFundMetadata } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../../content';

import { AnnualizedReturns } from './AnnualizedReturns';
import { AssetManagementFees } from './AssetManagementFees';
import { Liquidity } from './Liquidity';
import { OfferingFees } from './OfferingFees';

export type FundLiquidityAndFeesSectionProps = ProductDetailContentProps & {
  annualizedReturnScenarios?: PrivateCreditFundMetadata['annualizedReturnScenarios'];
  assetManagementFees?: PrivateCreditFundMetadata['assetManagementFees'];
  liquidityAndRedemptions?: PrivateCreditFundMetadata['liquidityAndRedemptions'];
  offeringFees?: PrivateCreditFundMetadata['offeringFees'];
};

export const FundLiquidityAndFeesSection = ({
  annualizedReturnScenarios,
  assetManagementFees,
  liquidityAndRedemptions,
  offeringFees,
  ...rest
}: FundLiquidityAndFeesSectionProps) => {
  const sections = useMemo(
    () =>
      [
        liquidityAndRedemptions && <Liquidity liquidityAndRedemptions={liquidityAndRedemptions} />,
        annualizedReturnScenarios && annualizedReturnScenarios.returns.length > 0 && (
          <AnnualizedReturns annualizedReturnScenarios={annualizedReturnScenarios} />
        ),
        assetManagementFees && <AssetManagementFees assetManagementFees={assetManagementFees} />,
        offeringFees && <OfferingFees offeringFees={offeringFees} />,
      ].filter((value) => value != null),
    [liquidityAndRedemptions, offeringFees, assetManagementFees],
  );

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.liquidity-and-fees" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body>
        {sections.map((content, idx) => (
          <Fragment key={idx}>
            {idx >= 1 && <ProductDetailContent.Divider />}
            {content}
          </Fragment>
        ))}
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};
