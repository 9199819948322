import { Variable } from '@tamagui/core';

import { Leaves } from '../utils';

export const colorTokens = {
  transparent: 'transparent',

  neutral: {
    light: {
      0: '#FFFFFF',
      20: '#FBFBFB',
      40: '#F2F2F4',
      60: '#EDEEF0',
      80: '#E9EAEC',
      100: '#E1E3E6',
      200: '#C3C8CC',
      300: '#A5ACB3',
      400: '#87909A',
      500: '#6A7580',
      600: '#4C5967',
      700: '#2E3E4E',
      800: '#1D2D3D',
      900: '#192634',
      920: '#15202B',
      940: '#111A23',
      960: '#0C131A',
      980: '#080D11',
      1000: '#040609',
    },
    dark: {
      0: '#030507',
      20: '#040609',
      40: '#080D11',
      60: '#0C131A',
      80: '#111A23',
      100: '#15202B',
      200: '#192634',
      300: '#1D2D3D',
      400: '#2E3E4E',
      500: '#4C5967',
      600: '#6A7580',
      700: '#87909A',
      800: '#A5ACB3',
      900: '#C3C8CC',
      920: '#E1E3E6',
      940: '#F1F3F5',
      960: '#F5F6F8',
      980: '#F8F9FA',
      1000: '#FCFCFD',
    },
  },

  primary: {
    light: {
      0: '#E8F1FD',
      100: '#E0ECFC',
      200: '#C1DAF9',
      300: '#A2C7F5',
      400: '#83B5F2',
      500: '#64A2EF',
      600: '#4590EC',
      700: '#267DE9',
      800: '#156CD8',
      900: '#125DB9',
      920: '#0F4D9A',
      940: '#0C3E7B',
      960: '#092E5C',
      980: '#061F3D',
      1000: '#03101F',
    },
    dark: {
      0: '#020C17',
      100: '#03101F',
      200: '#061F3D',
      300: '#092E5C',
      400: '#0C3E7B',
      500: '#0F4D9A',
      600: '#125DB9',
      700: '#156CD8',
      800: '#267DE9',
      900: '#4590EC',
      920: '#64A2EF',
      940: '#83B5F2',
      960: '#83B5F2',
      980: '#C1DAF9',
      1000: '#E0ECFC',
    },
  },

  secondary: {
    light: {
      0: '#FFEFD0',
      100: '#FFEAC0',
      200: '#FFD37D',
      300: '#FFBA33',
      400: '#F6A300',
      500: '#DD9300',
      600: '#C58300',
      700: '#AE7300',
      800: '#976400',
      900: '#815600',
      920: '#6B4700',
      940: '#573900',
      960: '#432C00',
      980: '#2F1F00',
      1000: '#1D1300',
    },
    dark: {
      0: '#181000',
      100: '#1D1300',
      200: '#2F1F00',
      300: '#432C00',
      400: '#573900',
      500: '#6B4700',
      600: '#815600',
      700: '#976400',
      800: '#AE7300',
      900: '#C58300',
      920: '#DD9300',
      940: '#F6A300',
      960: '#F8BD47',
      980: '#FBD488',
      1000: '#FDEAC4',
    },
  },

  positive: {
    light: {
      0: '#EBFAF8',
      100: '#E4F9F5',
      200: '#C9F2EC',
      300: '#AEECE2',
      400: '#93E5D8',
      500: '#78DFCE',
      600: '#5DD9C5',
      700: '#42D2BB',
      800: '#32C1AA',
      900: '#2AA692',
      920: '#238A79',
      940: '#1C6F61',
      960: '#155349',
      980: '#0E3730',
      1000: '#071C18',
    },
    dark: {
      0: '#051512',
      100: '#071C18',
      200: '#0E3730',
      300: '#155349',
      400: '#1C6F61',
      500: '#238A79',
      600: '#2AA692',
      700: '#32C1AA',
      800: '#42D2BB',
      900: '#5DD9C5',
      920: '#78DFCE',
      940: '#93E5D8',
      960: '#AEECE2',
      980: '#C9F2EC',
      1000: '#E4F9F5',
    },
  },

  negative: {
    light: {
      0: '#FFEBEC',
      100: '#FFE5E6',
      200: '#FFCBCD',
      300: '#FFB0B4',
      400: '#FF969B',
      500: '#FF7C82',
      600: '#FF6169',
      700: '#FF4750',
      800: '#EE3640',
      900: '#CC2E36',
      920: '#AA272D',
      940: '#881F24',
      960: '#66171B',
      980: '#440F12',
      1000: '#220809',
    },
    dark: {
      0: '#1A0607',
      100: '#220809',
      200: '#440F12',
      300: '#66171B',
      400: '#881F24',
      500: '#AA272D',
      600: '#CC2E36',
      700: '#EE3640',
      800: '#FF4750',
      900: '#FF6169',
      920: '#FF7C82',
      940: '#FF969B',
      960: '#FFB0B4',
      980: '#FFCBCD',
      1000: '#FFE5E6',
    },
  },

  artifact: {
    inlet: {
      light: {
        100: '#F2F7FB',
        200: '#C0D8ED',
        300: '#80B1DA',
        400: '#40596D',
      },
      dark: {
        100: '#0D1216',
        200: '#FF4750',
        300: '#80B1DA',
        400: '#C0D8ED',
      },
    },
    lilac: {
      light: {
        100: '#F6F3F8',
        200: '#D1C2DB',
        300: '#A384B7',
        400: '#52425C',
      },
      dark: {
        100: '#100D12',
        200: '#52425C',
        300: '#A384B7',
        400: '#D1C2DB',
      },
    },
    musty: {
      light: {
        100: '#FEF7F2',
        200: '#F8D9BD',
        300: '#F0B27A',
        400: '#78593D',
      },
      dark: {
        100: '#18120C',
        200: '#78593D',
        300: '#F0B27A',
        400: '#F8D9BD',
      },
    },
  },
} as const;

export type Color = typeof colorTokens;
export type Colors = Leaves<Color>;

type FlattenedColors = { [K in Colors]: Variable<string> | string };

const flattenColors = <T extends object>(obj: T, key?: string): FlattenedColors =>
  Object.entries(obj).reduce((acc, [cumulativeKey, value]) => {
    const newKey = `${key ? key + '.' : ''}${cumulativeKey}`;

    if (value && typeof value === 'object') {
      return {
        ...acc,
        ...flattenColors(value, newKey),
      };
    }

    return {
      ...acc,
      [newKey]: value,
    };
  }, {} as FlattenedColors);

export const colors = flattenColors(colorTokens);
