import { useIntl } from 'react-intl';

import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { IconButton, IconButtonProps } from '@mui/material';

export enum ArrowNavButtonDirection {
  FORWARD,
  BACKWARD,
}

export interface ArrowNavButtonProps extends IconButtonProps {
  arrowNavButtonDirection: ArrowNavButtonDirection;
  titleAccess?: string;
}

const ArrowNavButton = ({ arrowNavButtonDirection, titleAccess, ...props }: ArrowNavButtonProps) => {
  const intl = useIntl();

  return (
    <IconButton
      size="large"
      aria-label={intl.formatMessage({
        id: arrowNavButtonDirection === ArrowNavButtonDirection.BACKWARD ? 'common.back' : 'common.next',
      })}
      {...props}
    >
      {arrowNavButtonDirection === ArrowNavButtonDirection.BACKWARD ? (
        <ArrowBackIosNew titleAccess={titleAccess} />
      ) : (
        <ArrowForwardIos titleAccess={titleAccess} />
      )}
    </IconButton>
  );
};

export const ForwardButton = (props: Omit<ArrowNavButtonProps, 'arrowNavButtonDirection'>) => (
  <ArrowNavButton arrowNavButtonDirection={ArrowNavButtonDirection.FORWARD} {...props} />
);

export const BackButton = (props: Omit<ArrowNavButtonProps, 'arrowNavButtonDirection'>) => (
  <ArrowNavButton arrowNavButtonDirection={ArrowNavButtonDirection.BACKWARD} {...props} />
);
