import { ComponentProps, ComponentType, useEffect, useState } from 'react';

import { UseFormWatch } from 'react-hook-form';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Table, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { Box, Stack, TableHeadProps, Theme, Typography, TypographyProps, alpha, useMediaQuery } from '@mui/material';

import { ArrivedTooltip } from '@arrived/features-tooltips';
import { ReturnsCalculatorForm } from '@arrived/forms';
import { Offering } from '@arrived/models';

import { AppreciationInputType } from './constants';
import { useHypotheticalReturnsData } from './useHypotheticalReturnsData';

export interface HypotheticalReturnsTableProps {
  offering: Offering;
  TableComponent: ComponentType<{ table: Table<HypotheticalReturnsTableData>; TableHeadProps?: TableHeadProps }>;
  watch: UseFormWatch<ReturnsCalculatorForm>;
}

interface HypotheticalReturnsTableData {
  irr: number;
  label: string;
  return: number;
  type: AppreciationInputType;
}

const CellTypography = (props: ComponentProps<typeof Typography>) => (
  <Typography variant="body2.semibold" color={({ palette }) => palette.neutrals.white} {...props} />
);

const HeaderTypography = (props: TypographyProps) => (
  <Typography
    variant="body2.medium"
    color={({ palette }) => palette.neutrals.white}
    textTransform="uppercase"
    {...props}
  />
);

const PercentDisplay = (props: ComponentProps<typeof FormattedNumber>) => (
  <FormattedNumber
    style="percent"
    signDisplay="exceptZero"
    maximumFractionDigits={1}
    minimumFractionDigits={1}
    {...props}
  />
);

const CurrencyDisplay = (props: ComponentProps<typeof FormattedNumber>) => (
  <FormattedNumber style="currency" currency="USD" maximumFractionDigits={0} {...props} />
);

const columnHelper = createColumnHelper<HypotheticalReturnsTableData>();

const columns = [
  columnHelper.display({
    id: 'all',
    header: () => (
      <ArrivedTooltip
        referencedTextPropOverrides={{
          variant: 'body2.medium',
          color: ({ palette }) => palette.neutrals.white,
          textTransform: 'uppercase',
        }}
        tooltipContent={<FormattedMessage id="property-details.calculator.returns-table.zillow-source" />}
      >
        <FormattedMessage id="property-details.calculator.returns-table.label-header" />
      </ArrivedTooltip>
    ),
    cell: ({
      row: {
        original: { label, irr, return: returnValue },
      },
    }) => (
      <Stack>
        <CellTypography>{label}</CellTypography>
        <Stack direction="row" gap={1}>
          <CellTypography color={({ palette }) => palette.tertiary[500]}>
            <PercentDisplay value={irr} />
          </CellTypography>
          <Typography variant="body2" color={({ palette }) => palette.neutrals.white} textTransform="lowercase">
            <FormattedMessage id="property-details.calculator.returns-table.annual-return-percent" />
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <CellTypography>
            <CurrencyDisplay value={returnValue} />
          </CellTypography>
          <Typography variant="body2" color={({ palette }) => palette.neutrals.white} textTransform="lowercase">
            <FormattedMessage id="property-details.calculator.returns-table.return" />
          </Typography>
        </Stack>
      </Stack>
    ),
  }),
  columnHelper.accessor('label', {
    header: () => (
      <ArrivedTooltip
        referencedTextPropOverrides={{
          variant: 'body2.medium',
          color: ({ palette }) => palette.neutrals.white,
          textTransform: 'uppercase',
        }}
        tooltipContent={<FormattedMessage id="property-details.calculator.returns-table.zillow-source" />}
      >
        <FormattedMessage id="property-details.calculator.returns-table.label-header" />
      </ArrivedTooltip>
    ),
    cell: ({ getValue }) => <CellTypography>{getValue() as string}</CellTypography>,
    size: 250,
  }),
  columnHelper.accessor('irr', {
    header: () => (
      <HeaderTypography>
        <FormattedMessage id="property-details.calculator.returns-table.annual-return-percent" />
      </HeaderTypography>
    ),
    cell: ({ getValue }) => {
      const value = getValue() as number;

      return (
        <CellTypography color={({ palette }) => palette.tertiary[500]}>
          <PercentDisplay value={value} />
        </CellTypography>
      );
    },
    size: 100,
  }),
  columnHelper.accessor('return', {
    header: () => (
      <HeaderTypography>
        <FormattedMessage id="property-details.calculator.returns-table.return" />
      </HeaderTypography>
    ),
    cell: ({ getValue }) => (
      <CellTypography>
        <CurrencyDisplay value={getValue() as number} />
      </CellTypography>
    ),
    size: 100,
  }),
];

export const HypotheticalReturnsTable = ({ offering, TableComponent, watch }: HypotheticalReturnsTableProps) => {
  const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('md'));

  const [columnVisibility, setColumnVisibility] = useState({
    all: isMobile,
    label: !isMobile,
    irr: !isMobile,
    return: !isMobile,
  });

  useEffect(() => {
    setColumnVisibility({
      all: isMobile,
      label: !isMobile,
      irr: !isMobile,
      return: !isMobile,
    });
  }, [isMobile]);

  const [investmentAmount, leverage, rentalDividendYield] = watch([
    'investmentAmount',
    'leverage',
    'rentalDividendYield',
  ]);

  const data = useHypotheticalReturnsData({ investmentAmount, leverage, offering, rentalDividendYield });

  const table = useReactTable<HypotheticalReturnsTableData>({
    columns,
    data,
    state: { columnVisibility },
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  return (
    <Box px={2} pb={2}>
      <Box
        bgcolor={({ palette }) => palette.darkened.midnight}
        borderRadius={2}
        overflow="hidden"
        pt={1}
        sx={{
          '.MuiTableCell-root, .MuiTableRow-root': {
            borderBottom: 'none',
          },
          '.MuiTableCell-root:first-of-type': {
            pl: 2,
          },
          '.MuiTableCell-root:last-of-type': {
            pr: 2,
          },
          '.MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)': {
            bgcolor: ({ palette }) => alpha(palette.darkened.steel, 0.3),
          },
          '.MuiTableBody-root .MuiTableRow-root:last-of-type .MuiTableCell-root': {
            pb: 2,
          },
        }}
      >
        <TableComponent table={table} TableHeadProps={{ sx: { borderBottom: 'none' } }} />
      </Box>
    </Box>
  );
};
