const rootName = 'Gallery';

export const GALLERY_FRAME_NAME = `${rootName}Frame`;

const sidebarRootName = `${rootName}Sidebar`;
export const SIDEBAR_FRAME_NAME = `${sidebarRootName}Frame`;
export const SIDEBAR_ITEM_NAME = `${sidebarRootName}Item`;
export const SIDEBAR_ITEM_CONTENT_NAME = `${sidebarRootName}ItemContent`;

const carouselRootName = `${rootName}Carousel`;
export const CAROUSEL_FRAME_NAME = `${carouselRootName}Frame`;
export const CAROUSEL_ITEM_NAME = `${carouselRootName}Item`;

export const PAGINATION_FRAME_NAME = `${rootName}PaginationFrame`;

const arrowsRootName = `${rootName}Arrows`;
export const ARROWS_FRAME_NAME = `${arrowsRootName}Frame`;
export const ARROWS_BUTTON_FRAME_NAME = `${arrowsRootName}ButtonFrame`;
export const ARROWS_BUTTON_NAME = `${arrowsRootName}Button`;
