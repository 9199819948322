import { useQuery } from '@tanstack/react-query';

import { GetSaleQueryParams, getSale } from '@arrived/api_v2';
import { SaleCid } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';

import { saleQueryKeyFn } from './sales.keys';

export function useGetSaleQuery(
  saleCid?: SaleCid,
  queryParams?: GetSaleQueryParams,
  options?: UseAwaitedQueryOptions<typeof getSale>,
) {
  return useQuery({
    queryKey: saleQueryKeyFn(saleCid, queryParams),
    queryFn: () => getSale(saleCid!, queryParams),
    ...options,
    enabled: (options?.enabled ?? true) && Boolean(saleCid),
  });
}
