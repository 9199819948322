import * as yup from 'yup';

import { AccountsRequestSchema } from './accountsRequest.schema';

export const TransactFlowSuitabilitySchema = AccountsRequestSchema.pick([
  'suitabilityQuestionnaire',
  'investResponsibly',
]);

export type TransactFlowSuitabilitySchema = yup.InferType<typeof TransactFlowSuitabilitySchema>;
