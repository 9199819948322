import * as yup from 'yup';

import { AccountType, EntityType, RegDStatus } from '@arrived/models';

export const typeAccountTypeSchema = yup
  .mixed<AccountType>()
  .oneOf([AccountType.INDIVIDUAL, AccountType.ENTITY, AccountType.CHECKBOOK_IRA]);

export const entityNameSchema = yup.string().trim();

export const entityTitleSchema = yup.string().trim().max(120, 'account.entityTitle.length');

export const entityTypeSchema = yup.mixed<EntityType>().oneOf(Object.values(EntityType));

export const regDStatusSchema = yup.mixed<RegDStatus>().oneOf(Object.values(RegDStatus));
