import * as React from 'react';
import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function NotificationInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 24 24" {...rest}>
      <Path
        d="M5 9V14L3 18H21L19 14V9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9Z"
        stroke={color}
        stroke-width="1.5"
      />
      <Path d="M10 22H14" stroke={color} stroke-width="1.5" />
    </Svg>
  );
}

export const NotificationIcon = memo<IconProps>(withIconTheme(NotificationInternal));
