import { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import ExpandCircleDownOutlined from '@mui/icons-material/ExpandCircleDownOutlined';
import { Box, Button, Dialog, Grid, Link, Typography } from '@mui/material';

import { UserAgentUtils } from '@arrived/common';
import { useIsMountedWatcher } from '@arrived/hooks';
import { ModalBlocksLight } from '@arrived/images';

const BROWSER_MODAL_SESSION_STORAGE_KEY = 'userHasDismissedBrowserWarning';

export const UnsupportedBrowserWarning = () => {
  const [isUnsupportedBrowserWarningModalOpen, setIsUnsupportedBrowserWarningModalOpen] = useState(false);
  const isMounted = useIsMountedWatcher();

  const handleUnsupportedBrowserWarningModalClose = () => {
    sessionStorage.setItem(BROWSER_MODAL_SESSION_STORAGE_KEY, 'true');
    setIsUnsupportedBrowserWarningModalOpen(false);
  };

  const hasDismissedUnsupportedBrowserModal = () => !!sessionStorage.getItem(BROWSER_MODAL_SESSION_STORAGE_KEY);

  useEffect(() => {
    if (!hasDismissedUnsupportedBrowserModal() && !UserAgentUtils.isUsingSupportedBrowser()) {
      setTimeout(() => isMounted.current && setIsUnsupportedBrowserWarningModalOpen(true), 2000);
    }
  }, []);

  return (
    <Dialog
      className="unsupported-browser-modal"
      onClose={handleUnsupportedBrowserWarningModalClose}
      onBackdropClick={handleUnsupportedBrowserWarningModalClose}
      open={isUnsupportedBrowserWarningModalOpen}
      maxWidth="md"
      fullWidth={true}
    >
      <Box className="unsupported-browser-modal-container" display="flex" flexDirection="column" p={6} gap={2}>
        <Typography variant="h3.semibold" zIndex={1}>
          <FormattedMessage id="unsupported-browser-modal-title" defaultMessage="It's not you. It's your browser." />
        </Typography>
        <Typography variant="body1" className="unsupported-browser-modal-body-text" zIndex={1}>
          <FormattedMessage
            id="unsupported-browser-modal-body"
            defaultMessage="You seem to be using an unsupported browser. We recommend you update your browser or download one of these
                alternatives to improve your experience. Continuing may result in unexpected behavior."
          />
        </Typography>
        <Grid container spacing={4} zIndex={1}>
          {UserAgentUtils.supportedBrowsers.map(
            (browserInfo) =>
              browserInfo.isVisible !== false && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  className="supported-browser-item"
                  key={`supported-browser-${browserInfo.name}`}
                  display="flex"
                  gap={2}
                  alignItems="center"
                >
                  {browserInfo.logoSvgComponent}
                  <div className="supported-browser-text">
                    <Typography variant="h6">{browserInfo.name}</Typography>
                    {browserInfo.subtext ? (
                      <Typography variant="h6">{browserInfo.subtext}</Typography>
                    ) : (
                      <Link href={browserInfo.downloadLink} className="supported-browser-subtext">
                        <FormattedMessage
                          id="unsupported-browser-modal-download-link-text"
                          defaultMessage="Download latest"
                        />
                      </Link>
                    )}
                  </div>
                </Grid>
              ),
          )}
        </Grid>
        <Box display="flex" justifyContent="flex-end" zIndex={1}>
          <Button
            className="unsupported-browser-modal-footer-button"
            variant="outlined"
            fullWidth={false}
            onClick={handleUnsupportedBrowserWarningModalClose}
          >
            <FormattedMessage id="unsupported-browser-modal-dismiss-CTA" defaultMessage="Continue anyway" />
            <Box component={ExpandCircleDownOutlined} transform="rotate(270)" ml={1} />
          </Button>
        </Box>
        <Box component={ModalBlocksLight} position="absolute" bottom={0} left={0} />
      </Box>
    </Dialog>
  );
};
