import { useCallback, useMemo } from 'react';

import { Auth0UserPermissions } from '@arrived/arrived-auth0';
import { usePermissionsQuery } from '@arrived/queries';

/**
 * Shared hook for accessing user permissions stored in the query cache.
 */
export function usePermissions() {
  const permissionsState = usePermissionsQuery();
  const { data: userPermissions = [] } = permissionsState;

  const hasPermission = useCallback(
    (permission: Auth0UserPermissions) => {
      return userPermissions.indexOf(permission) >= 0;
    },
    [userPermissions],
  );

  const hasAllPermissions = useCallback(
    (permissionsToCheck: Auth0UserPermissions[]) => {
      return permissionsToCheck.every((permission) => hasPermission(permission));
    },
    [userPermissions, hasPermission],
  );

  const hasAnyPermissions = useCallback(
    (permissionsToCheck: Auth0UserPermissions[]) => {
      return permissionsToCheck.some((permission) => hasPermission(permission));
    },
    [userPermissions, hasPermission],
  );

  return useMemo(
    () => ({
      permissions: userPermissions,
      hasPermission,
      hasAllPermissions,
      hasAnyPermissions,
      permissionsState,
    }),
    [userPermissions, hasPermission, hasAllPermissions, hasAnyPermissions, permissionsState],
  );
}
