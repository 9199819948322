import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteUserAccount } from '@arrived/api_v2';
import { Account, User } from '@arrived/models';

import { accountsPrimaryKeyFn, currentUserKeyFn, userKeyFn } from '../users';

import { accountsKeyFn } from './accounts.keys';

export function useDeleteAccountMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUserAccount,
    onSuccess: (...args) => {
      const data = args[0];
      const user = queryClient.getQueryData<User>(currentUserKeyFn());

      if (user) {
        const primaryAccount = queryClient.getQueryData<Account>(accountsPrimaryKeyFn(user.id));

        if (primaryAccount?.id === data.id) {
          queryClient.removeQueries({ queryKey: accountsPrimaryKeyFn(user.id) });
        }
      }

      queryClient.setQueryData(currentUserKeyFn(), data.primaryUser);

      queryClient.invalidateQueries({ queryKey: currentUserKeyFn() });

      queryClient.removeQueries({ queryKey: accountsKeyFn(data.id) });

      queryClient.setQueryData(userKeyFn(data.primaryUser.id), data.primaryUser);

      queryClient.invalidateQueries({ queryKey: userKeyFn(data.primaryUser.id) });
    },
  });
}
