import { useCallback, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { format, parseISO } from 'date-fns';

import { FormLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { API_DATE_FORMAT, getBreakdownFromDate, getDateFromBreakdown, isOverEighteen } from '@arrived/common';
import { BirthDateSchema } from '@arrived/forms';

interface FormBirthDateFieldProps {
  name: string;
}

export const FormBirthDateField = ({ name }: FormBirthDateFieldProps) => {
  const { getValues, setValue: setter } = useFormContext();
  const birthDate = getValues(name);
  const parsedBirthDate = useMemo(() => (birthDate ? parseISO(birthDate) : undefined), [birthDate]);
  const birthDateBreakdown = useMemo(
    () => (parsedBirthDate ? getBreakdownFromDate(parsedBirthDate) : undefined),
    [parsedBirthDate],
  );

  // Validate that the birthday is older than 18, we don't want to do this in the yup schema because the 18 years age
  // restriction may not be applicable to other birthday inputs in the application
  const isOlderThanEighteen = useIsOlderThanEighteen(
    birthDateBreakdown?.year,
    birthDateBreakdown?.month,
    birthDateBreakdown?.day,
  );

  const handleDateChange = useCallback((date: Date | null) => {
    if (date && BirthDateSchema.isValidSync(getBreakdownFromDate(date))) {
      setter(name, format(date, API_DATE_FORMAT), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, []);

  return (
    <>
      <DatePicker
        label={<FormattedMessage id="birth-date" defaultMessage="Birth Date" />}
        disableFuture
        value={parsedBirthDate}
        onChange={handleDateChange}
      />
      <div>
        {isOlderThanEighteen === false && (
          <FormLabel error>
            <FormattedMessage id="must-be-older-than-eighteen" defaultMessage="You must be older than 18" />
          </FormLabel>
        )}
      </div>
    </>
  );
};

export const useIsOlderThanEighteen = (year?: string, month?: string, day?: string) => {
  return useMemo(() => {
    if (!year || !month || !day) return false;
    if (BirthDateSchema.isValidSync({ year, month, day })) {
      const birthDate = getDateFromBreakdown(year, month, day);

      return isOverEighteen(birthDate);
    }
    return undefined;
  }, [year, month, day]);
};
