import { createStyledContext } from '@tamagui/core';

import { ButtonVariant } from './getButtonVariant';

export const ButtonStyledContext = createStyledContext({
  variant: 'default' as ButtonVariant,
  inverted: false,
  loading: false,
  disabled: false,
  emphasized: false,
});
