import { FormattedMessage } from 'react-intl';

export const OfferingStatusTitle = {
  IPO_DATE: <FormattedMessage id="offering-history.title.ipo_date" />,
  OCCUPIED_SELLER_PCO: <FormattedMessage id="offering-history.title.occupied_seller_pco" />,
  MARKET_PREPARATION: <FormattedMessage id="offering-history.title.market_preparation" />,
  MARKETED_FOR_RENT: <FormattedMessage id="offering-history.title.marketed_for_rent" />,
  CHANGE_IN_MARKETING_RENT: <FormattedMessage id="offering-history.title.change_in_marketing_rent" />,
  APPROVED_APPLICATION: <FormattedMessage id="offering-history.title.approved_application" />,
  FIRST_LEASE_STARTS: <FormattedMessage id="offering-history.title.first_lease_starts" />,
  FULL_LEASE_RENEWAL: <FormattedMessage id="offering-history.title.full_lease_renewal" />,
  M2M_LEASE_RENEWAL: <FormattedMessage id="offering-history.title.m2m_lease_renewal" />,
  LEASE_CANCELLED: <FormattedMessage id="offering-history.title.lease_cancelled" />,
  LEASE_ENDS: <FormattedMessage id="offering-history.title.lease_ends" />,
  LEASE_STARTS: <FormattedMessage id="offering-history.title.lease_starts" />,
  EARLY_LEASE_BREAK: <FormattedMessage id="offering-history.title.early_lease_break" />,
  NOTICE: <FormattedMessage id="offering-history.title.notice" />,
  PROPERTY_ISSUE: <FormattedMessage id="offering-history.title.property_issue" />,
  PROPERTY_ISSUE_RESOLVED: <FormattedMessage id="offering-history.title.property_issue_resolved" />,
  EVICTION: <FormattedMessage id="offering-history.title.eviction" />,
  EVICTION_RESOLVED: <FormattedMessage id="offering-history.title.eviction_resolved" />,
  VACANT_OFFLINE: <FormattedMessage id="offering-history.title.vacant_offline" />,
  VACANT_OFFLINE_RESOLVED: <FormattedMessage id="offering-history.title.vacant_offline_resolved" />,
  MANUAL_STATUS_ENTRY: <FormattedMessage id="offering-history.title.manual_status_entry" />,
  DIVIDENDS_PAID: <FormattedMessage id="offering-history.title.dividends_paid" />,
  DIVIDENDS_PAUSED: <FormattedMessage id="offering-history.title.dividends_paused" />,
  SHARE_PRICE_UPDATED: <FormattedMessage id="offering-history.title.share_price_updated" />,
  PROPERTY_MANAGEMENT_UPDATE: <FormattedMessage id="offering-history.title.property_management_update" />,
  CURRENTLY_LIVE_ACCEPTING_BOOKINGS: <FormattedMessage id="offering-history.title.currently_live_accepting_bookings" />,
  TEMPORARILY_UNAVAILABLE: <FormattedMessage id="offering-history.title.temporarily_unavailable" />,
  ONBOARDING_UPDATE: <FormattedMessage id="offering-history.title.onboarding_update" />,
  STATUS_UPDATE: <FormattedMessage id="offering-history.title.status_update" />,
  DISPOSITION_VACANT_LISTED: <FormattedMessage id="offering-history.title.disposition_vacant_listed" />,
  DISPOSITION_UNDER_CONTRACT: <FormattedMessage id="offering-history.title.disposition_under_contract" />,
  DISPOSITION_OCCUPIED_NOT_READY: <FormattedMessage id="offering-history.title.disposition_occupied_not_ready" />,
  DISPOSITION_OCCUPIED_LISTED: <FormattedMessage id="offering-history.title.disposition_occupied_listed" />,
} as const;
