import { useMemo } from 'react';

import { Stack } from '@arrived/bricks';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import {
  AccountOfferingBalance,
  Offering,
  OfferingDividend,
  OfferingSharePrices,
  OfferingStatus,
  OfferingTransaction,
  SFRFundData,
} from '@arrived/models';

import { AssetDividendSection, ProductDetailsView } from '../..';
import { ProductHeader, ProductHeaderProps } from '../../productHeader';
import {
  AboutSection,
  CommonQuestionsSection,
  DocumentsSection,
  FundDetailsSection,
  FundIPOSection,
  FundInvestmentStrategySection,
  FundLiquidityAndFeesSection,
  FundPerformanceSection,
  FundPropertiesMap,
  FundPropertyList,
  MaxInvestmentPerMonthCallout,
  TransactionHistorySection,
  YourPositionSection,
} from '../components';
import { getMetadataCommonQuestions } from '../components/commonQuestions/getMetadataCommonQuestions';
import { HeaderDetailsStackProps } from '../ProductDetailParts';
import { useProductDetailsViewContext } from '../ProductDetailsViewContext';

type SFRFundHeaderProps = ProductHeaderProps & {
  offering: Offering;
};

export const SFRFundHeader = ({ children, offering, ...rest }: SFRFundHeaderProps) => (
  <ProductHeader {...rest}>
    <ProductHeader.Title>{offering.name}</ProductHeader.Title>
    <ProductHeader.Tag offering={offering} />
    {children}
  </ProductHeader>
);

type SFRFundHeaderDetailsProps = HeaderDetailsStackProps & {
  offering: Offering;
};

export const SFRFundHeaderDetails = ({ offering, ...rest }: SFRFundHeaderDetailsProps) => {
  const { view } = useProductDetailsViewContext();

  switch (view) {
    case ProductDetailsView.PROPERTIES:
      return (
        <Stack {...rest}>
          <FundPropertiesMap properties={offering.properties} />
        </Stack>
      );
  }
};

interface SFRFundContentProps {
  dividends?: OfferingDividend[];
  offering: Offering;
  offeringBalance?: AccountOfferingBalance;
  sfrFundMetadata?: SFRFundData;
  sharePrices?: OfferingSharePrices[];
  transactions?: OfferingTransaction[];
}

export const SFRFundContent = ({
  dividends,
  offering,
  offeringBalance,
  sfrFundMetadata,
  sharePrices,
  transactions,
}: SFRFundContentProps) => {
  const { view } = useProductDetailsViewContext();
  const isSFRDividendHistoryEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.SFR_DIVIDEND_HISTORY);

  const commonQuestions = useMemo(
    () => getMetadataCommonQuestions(sfrFundMetadata?.commonQuestions ?? []),
    [sfrFundMetadata?.commonQuestions],
  );

  const showMaxInvesmentPerMonthCallout = useMemo(
    () => offering.maxSharesPerAccountPerMonth != null && offering.status !== OfferingStatus.FUNDED,
    [offering.maxSharesPerAccountPerMonth, offering.status],
  );

  switch (view) {
    case ProductDetailsView.DETAILS:
      return (
        <>
          {showMaxInvesmentPerMonthCallout && <MaxInvestmentPerMonthCallout offering={offering} />}
          <AboutSection offering={offering} />
          <FundDetailsSection
            dividends={dividends}
            offering={offering}
            sfrFundMetadata={sfrFundMetadata}
            sharePrices={sharePrices}
          />
          {(sfrFundMetadata?.investmentStrategy || sfrFundMetadata?.markets) && (
            <FundInvestmentStrategySection
              investmentStrategy={sfrFundMetadata.investmentStrategy}
              markets={sfrFundMetadata.markets}
            />
          )}
          {isSFRDividendHistoryEnabled &&
            (sfrFundMetadata?.fundPerformance || (dividends && dividends.length >= 1)) && (
              <FundPerformanceSection
                dividends={dividends}
                fundPerformance={sfrFundMetadata?.fundPerformance}
                sharePrices={sharePrices}
              />
            )}
          {!isSFRDividendHistoryEnabled && sfrFundMetadata?.ipoPerformance && (
            <FundIPOSection ipoPerformance={sfrFundMetadata.ipoPerformance} />
          )}
          {(sfrFundMetadata?.liquidityAndRedemptions ||
            sfrFundMetadata?.assetManagementFees ||
            sfrFundMetadata?.offeringFees) && (
            <FundLiquidityAndFeesSection
              assetManagementFees={sfrFundMetadata.assetManagementFees}
              liquidityAndRedemptions={sfrFundMetadata.liquidityAndRedemptions}
              offeringFees={sfrFundMetadata.offeringFees}
            />
          )}
          <DocumentsSection offering={offering} />
          {commonQuestions.length > 0 && <CommonQuestionsSection questions={commonQuestions} />}
        </>
      );
    case ProductDetailsView.PROPERTIES:
      return <FundPropertyList properties={offering.properties} />;
    case ProductDetailsView.PERFORMANCE:
      return (
        <>
          {offeringBalance && <YourPositionSection offering={offering} offeringBalance={offeringBalance} />}
          {offeringBalance && <AssetDividendSection offering={offering} offeringBalance={offeringBalance} />}
          {transactions && Boolean(transactions.length) && <TransactionHistorySection transactions={transactions} />}
        </>
      );
  }
};
