export enum ACCOUNTS_LOCAL_STORAGE_KEYS {
  accountState = 'accountState',
}

export enum COMMON_LOCAL_STORAGE_KEYS {
  betaMode = 'betaMode',
  learningMode = 'learningMode',
}

export enum REFERRAL_CODE_LOCAL_STORAGE_KEYS {
  referralCode = 'referralCode',
}

export enum SIGNUP_POPUP_LOCAL_STORAGE_KEYS {
  viewedOfferings = 'viewedOfferings',
}

export const LOCAL_STORAGE_KEYS = {
  ARCHETYPE_IDENTIFIED: 'ARCHETYPE_IDENTIFIED',
  ACCREDITED_IDENTIFIED: 'ACCREDITED_IDENTIFIED',
  ...ACCOUNTS_LOCAL_STORAGE_KEYS,
  ...COMMON_LOCAL_STORAGE_KEYS,
  ...REFERRAL_CODE_LOCAL_STORAGE_KEYS,
  ...SIGNUP_POPUP_LOCAL_STORAGE_KEYS,
} as const;
