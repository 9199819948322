import { useCallback, useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import Lock from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';

import { Constants } from '@arrived/common';
import { TaxIdType } from '@arrived/models';

import { CustomInputProps, TaxIdInput, TaxIdInputProps } from './TaxIdInput';

export const ArrivedTaxIdInput = ({
  isOptional,
  taxIdType,
  TextFieldProps,
  ...rest
}: Omit<TaxIdInputProps, 'customInput' | 'hiddenCharacter' | 'show'> & {
  isOptional?: boolean;
  TextFieldProps?: TextFieldProps;
}) => {
  const intl = useIntl();
  const [isShowing, setIsShowing] = useState(false);

  const toggleIsShowing = useCallback(() => setIsShowing((isShowing) => !isShowing), [isShowing]);

  const taxIdLabel = useMemo(() => {
    return taxIdType === TaxIdType.SSN ? (
      <FormattedMessage id="ssn" defaultMessage="SSN" />
    ) : (
      <FormattedMessage id="ein" defaultMessage="EIN" />
    );
  }, [taxIdType]);

  const CustomInput = useCallback(
    (props: CustomInputProps) => {
      return (
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Lock />
                <IconButton
                  onClick={toggleIsShowing}
                  aria-label={
                    isShowing
                      ? intl.formatMessage({ id: 'onboarding.tax-id.hide-tax-id.label' })
                      : intl.formatMessage({ id: 'onboarding.tax-id.show-tax-id.label' })
                  }
                >
                  {isShowing ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={
            isOptional ? (
              <FormattedMessage
                id="tax-id-optional-label"
                defaultMessage="{taxIdLabel} (optional)"
                values={{ taxIdLabel }}
              />
            ) : (
              taxIdLabel
            )
          }
          helperText={
            isOptional ? (
              <FormattedMessage
                id="tax-id-requirement-optional-helper-text"
                defaultMessage="If you have a {taxIdLabel}, enter it here"
                values={{ taxIdLabel }}
              />
            ) : null
          }
          type="tel"
          {...props}
        />
      );
    },
    [taxIdType, toggleIsShowing],
  );

  return (
    <TaxIdInput
      {...rest}
      customInput={CustomInput}
      CustomInputProps={TextFieldProps}
      hiddenCharacter={Constants.bullet}
      show={isShowing}
      taxIdType={taxIdType}
    />
  );
};
