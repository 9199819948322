import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function FundsAltIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <Path
        d="M2 9.423V17.5a.5.5 0 0 0 .5.5h2.2a.5.5 0 0 0 .5-.5V7.443a.5.5 0 0 0-.834-.372l-2.2 1.98A.5.5 0 0 0 2 9.423ZM18 17.5V9.423a.5.5 0 0 0-.166-.372l-2.2-1.98a.5.5 0 0 0-.834.372V17.5a.5.5 0 0 0 .5.5h2.2a.5.5 0 0 0 .5-.5ZM9.666 2.301l-1.9 1.71a.5.5 0 0 0-.166.372V17.5a.5.5 0 0 0 .5.5h3.8a.5.5 0 0 0 .5-.5V4.383a.5.5 0 0 0-.165-.372l-1.9-1.71a.5.5 0 0 0-.67 0Z"
        fill={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const FundsAltIcon = memo<IconProps>(withIconTheme(FundsAltIconInternal));
