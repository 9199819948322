import {
  Account,
  AccountKycAml,
  AmlStatus,
  KycAmlFailureResolution,
  KycAmlRollupStatus,
  KycAmlStatusDetail,
} from '@arrived/models';

import { BrokerageAccountSetupState } from '../../constants';

export function getBrokerageAccountSetupState(account?: Account, accountKycAml?: AccountKycAml) {
  if (!account) {
    return BrokerageAccountSetupState.CREATE_ACCOUNT;
  }

  if (!accountKycAml) {
    return BrokerageAccountSetupState.KYC_NOT_RUN;
  }

  const { kycAmlRollupStatus, kycAmlFailureResolution, accountEntityAml, userKycAml } = accountKycAml;

  /**
   * This function was updated to fix an issue ops was having with users who get into a state
   * where they were confusingly uploading excessive #'s of documents to handle their ID
   * verification. In these cases, when the status is "Need More Info" and the detail is "Patriot
   * Act" we are actually in a review state for the user and so we should be changing the stepper
   * to indicate to the user that they do not need to upload additional docs and so instead
   * should be waiting for their account to be reviewed.
   */
  const isPatriotAct = () =>
    (userKycAml?.amlStatus === AmlStatus.NEED_MORE_INFO &&
      userKycAml?.amlStatusDetail === KycAmlStatusDetail.PATRIOT_ACT) ||
    (accountEntityAml?.amlStatus === AmlStatus.NEED_MORE_INFO &&
      accountEntityAml.entityAmlStatusDetail === KycAmlStatusDetail.PATRIOT_ACT);

  const failureResolution = () => {
    const find = kycAmlFailureResolution.find(
      (r) =>
        r === KycAmlFailureResolution.PENDING_REVIEW ||
        r === KycAmlFailureResolution.NEED_DOC_UPLOAD ||
        r === KycAmlFailureResolution.NEED_SSN_UPDATE,
    );

    if (!find) {
      return BrokerageAccountSetupState.REJECTED;
    }

    if (find === KycAmlFailureResolution.NEED_DOC_UPLOAD && isPatriotAct()) {
      return BrokerageAccountSetupState.PENDING_COMPLIANCE_VERIFICATION;
    }

    return {
      [KycAmlFailureResolution.PENDING_REVIEW]: BrokerageAccountSetupState.PENDING_VERIFICATION,
      [KycAmlFailureResolution.NEED_DOC_UPLOAD]: BrokerageAccountSetupState.UPLOAD_DOCS,
      [KycAmlFailureResolution.NEED_SSN_UPDATE]: BrokerageAccountSetupState.UPDATE_SSN,
    }[find];
  };

  return {
    [KycAmlRollupStatus.PENDING]: BrokerageAccountSetupState.KYC_NOT_RUN,
    [KycAmlRollupStatus.PENDING_SYNC]: BrokerageAccountSetupState.KYC_PENDING_SYNC,
    [KycAmlRollupStatus.PASS]: BrokerageAccountSetupState.COMPLETED,
    [KycAmlRollupStatus.FAIL]: failureResolution(),
  }[kycAmlRollupStatus];
}
