import { useEffect, useMemo, useState } from 'react';

import { StringUtils } from '@arrived/common';
import { FEATURE_FLAGS, useFeature, useGrowthBook } from '@arrived/feature-flags';
import { QuestionnaireId } from '@arrived/models';
import { useGetHydratedQuestionnaire } from '@arrived/queries';

export interface UsePersonaQuestionnaireType {
  /**
   * Indicates if the persona questionnaire or the feature flag controlling it is loading.
   */
  isLoading: boolean;
  /**
   * Indicates if we can/should push the user to onboard with the persona questionnaire. This will be false if the
   * feature is not enabled OR if the questionnaire failed to load.
   */
  isPersonaQuestionnaireEnabled?: boolean;
  /**
   * The query result of the persona questionnaire.
   */
  personaQuestionnaireState: ReturnType<typeof useGetHydratedQuestionnaire>;
}

/**
 * Fetches the Persona Questionnaire based on the value of the feature flag. Also supplies some helpful attributes to
 * indicate whether the questionnaire is enabled in the onboarding flow and if it is in the process of loading.
 */
export function usePersonaQuestionnaire(): UsePersonaQuestionnaireType {
  const personaQuestionnaireFlag = useFeature<QuestionnaireId>(FEATURE_FLAGS.PERSONA_QUESTIONNAIRE);
  const gb = useGrowthBook();

  // Store the questionnaireId in state, so that if the feature flag value changes out from under us we don't switch
  // the user's experience.
  const [questionnaireId, setQuestionnaireId] = useState<QuestionnaireId>();

  useEffect(() => {
    const { on, value: questionnaireId } = personaQuestionnaireFlag;

    if (on && StringUtils.isNotBlank(questionnaireId)) {
      // If there is currently a questionnaireId cached in state, don't update it to the new value.
      setQuestionnaireId((currentQuestionnaireId) => currentQuestionnaireId ?? questionnaireId);
    }
  }, [personaQuestionnaireFlag.on, personaQuestionnaireFlag.value]);

  const personaQuestionnaireState = useGetHydratedQuestionnaire(questionnaireId!, {
    enabled: questionnaireId != null,
  });

  return useMemo(
    () => ({
      isLoading: !gb?.ready || (personaQuestionnaireFlag.on && personaQuestionnaireState.isPending),
      isPersonaQuestionnaireEnabled:
        personaQuestionnaireFlag.on &&
        StringUtils.isNotBlank(personaQuestionnaireFlag.value) &&
        !personaQuestionnaireState.isError,
      personaQuestionnaireState,
    }),
    [gb?.ready, personaQuestionnaireState, personaQuestionnaireFlag],
  );
}
