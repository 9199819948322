import { parsePhoneNumber } from 'awesome-phonenumber';

const sanitize = (phoneNumber: string): string => {
  const sanitized = phoneNumber.trim().replace(/[.|-]+/g, '');

  if (sanitized.substring(0, 1) !== '+') {
    return '+1' + sanitized;
  }

  return sanitized;
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return '';
  }

  const sanitizedPhoneNumber = sanitize(phoneNumber);

  const parsed = parsePhoneNumber(sanitizedPhoneNumber, {
    regionCode: 'US',
  });

  if (parsed.valid) {
    return parsed.number?.national;
  }

  return phoneNumber;
};
