import { useQuery } from '@tanstack/react-query';

import { getOfferingSharePricesHistory } from '@arrived/api_v2';
import { OfferingCid } from '@arrived/models';
import { MAX_STALE_TIME, UseAwaitedQueryOptions, offeringSharePricesHistoryKeyFn } from '@arrived/queries';

export const useGetOfferingSharePricesHistoryQuery = (
  offeringCid: OfferingCid,
  options?: UseAwaitedQueryOptions<typeof getOfferingSharePricesHistory>,
) => {
  return useQuery({
    queryKey: offeringSharePricesHistoryKeyFn(offeringCid),
    queryFn: () => getOfferingSharePricesHistory(offeringCid),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && offeringCid != null,
  });
};
