import { useMemo } from 'react';

import { OfferingId } from '@arrived/models';
import { useOfferingDividendsQuery } from '@arrived/queries';

export function useHasOfferingPaidDividend(offeringId: OfferingId) {
  const offeringDividendsState = useOfferingDividendsQuery(offeringId);

  return useMemo(() => {
    if (offeringDividendsState.isLoading) {
      return undefined;
    }

    return (offeringDividendsState.data?.findIndex(({ postedAt }) => new Date(postedAt) < new Date()) ?? -1) >= 0;
  }, [offeringDividendsState.data, offeringDividendsState.isInitialLoading]);
}
