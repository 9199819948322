import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getReferralCodeEntityByCode } from '@arrived/api_v2';
import { ReferralCode } from '@arrived/models';

import { adminReferralCodeKeyFn } from './referrals.keys';

export function useAdminGetReferralCodeByCodeQuery(referralCode: ReferralCode) {
  const queryClient = useQueryClient();

  const referralCodeState = useQuery({
    queryKey: adminReferralCodeKeyFn(referralCode),
    queryFn: () => getReferralCodeEntityByCode(referralCode),
  });

  useEffect(() => {
    if (referralCodeState.data) {
      queryClient.setQueryData(adminReferralCodeKeyFn(referralCode), referralCodeState.data);
    }
  }, [referralCodeState.data]);

  return referralCodeState;
}
