/* Data Types */

import { UserId } from './users';

export type QuestionnaireId = Questionnaire['uuid'];

export interface Questionnaire {
  uuid: string;
  questionnaireName: string;
  createdAt: Date;
  updatedAt: Date;
  isActive: boolean;
}

export type QuestionnaireQuestionId = QuestionnaireQuestion['uuid'];

/**
 * Relation between a question and a questionnaire.
 */
export interface QuestionnaireQuestion {
  uuid: string;
  questionnaireUUID: QuestionnaireId;
  questionUUID: QuestionId;
  questionOrder: number | null;
  createdAt: Date;
  updatedAt: Date;
  isActive: boolean;
}

export enum QuestionType {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  SINGLE_VALUE = 'SINGLE_VALUE',
}

export type QuestionId = Question['uuid'];

export interface Question {
  uuid: string;
  text: string;
  description: string | null;
  questionType: QuestionType;
  createdAt: Date;
  updatedAt: Date;
  isActive: boolean;
}

export enum ResponseOptionType {
  SELECTION = 'SELECTION',
  FREEFORM = 'FREEFORM',
}

export type ResponseOptionId = ResponseOption['uuid'];

export interface ResponseOption {
  uuid: string;
  text: string;
  description: string | null;
  questionUUID: QuestionId;
  responseOptionType: ResponseOptionType;
  responseOptionOrder: number | null;
  createdAt: Date;
  updatedAt: Date;
  isActive: boolean;
}

export interface HydratedResponseOption {
  responseOptionUUID: ResponseOptionId;
  responseOptionText: string;
  responseOptionDescription: string | null;
  responseOptionType: ResponseOptionType;
  responseOptionOrder: number;
}

export interface HydratedQuestion {
  questionUUID: QuestionId;
  questionText: string;
  questionDescription: string;
  questionOrder: number;
  questionType: QuestionType;
  responseOptions: HydratedResponseOption[];
}

export interface HydratedQuestionnaire {
  questionnaireName: string;
  questionnaireUUID: QuestionnaireId;
  hydratedQuestions: HydratedQuestion[];
}

export interface InvestorResponse {
  userId: UserId;
  questionUUID: QuestionId;
  skipped?: boolean;
  responses: InvestorResponseData[];
}

/* Request Types */

export interface CreateQuestionnaireRequest {
  questionnaireName: string;
}

export interface PatchQuestionnaireRequest {
  questionnaireName: string;
}

export interface QuestionOrder {
  questionUUID: QuestionId;
  order: number;
}

export interface PatchQuestionnaireQuestionsRequest {
  personaQuestionOrder: QuestionOrder[];
}

export interface PatchQuestionnaireQuestionsResponse {
  questionnaireUUID: string;
  /**
   * TODO: Make sure that the response actually has the uuid as the QuestionId not the QuestionnaireQuestionId
   */
  personaQuestionOrder: QuestionOrder[];
}

export interface PostQuestionRequest {
  text: string;
  description: string;
  questionType: QuestionType;
  order: number;
}

export interface PatchQuestionRequest {
  text?: string;
  questionType?: QuestionType;
  description?: string;
}

export interface ResponseOptionOrder {
  questionResponseOptionUUID: ResponseOptionId;
  order: number;
}

export interface PatchQuestionResponseOptionsRequest {
  questionResponseOptionOrder: ResponseOptionOrder[];
}

export interface PatchQuestionResponseOptionsResponse {
  questionUUID: QuestionId;
  questionResponseOptionOrder: ResponseOptionOrder[];
}

export interface PostResponseOptionRequest {
  text: string;
  responseOptionType: ResponseOptionType;
  responseOptionOrder: number;
  description?: string;
}

export interface PatchResponseOptionRequest {
  text?: string;
  responseType?: ResponseOptionType;
}

export interface InvestorResponseData {
  questionResponseOptionUUID: ResponseOptionId;
  text?: string;
}

export interface PostInvestorResponseRequest {
  userId: UserId;
  questionUUID: QuestionId;
  skipped: boolean;
  responses: InvestorResponseData[];
}
