import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function BathTubInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M2.5 10V1.667h4.583v2.5M5 4.167h4.167M17.5 10h-15v2.5a5 5 0 0 0 5 5h5a5 5 0 0 0 5-5V10Zm-2.083 9.167v-2.605M4.583 19.167v-2.605"
        stroke={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const BathTubIcon = memo<IconProps>(withIconTheme(BathTubInternal));
