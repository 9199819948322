import { Linking } from 'react-native';

import { GetProps, isWeb, styled } from '@tamagui/core';

import { RootText } from '../text';

export type AnchorProps = {
  href?: string;
  target?: string;
  rel?: string;
};

const AnchorFrame = styled(RootText, {
  name: 'Anchor',

  tag: 'a',
  role: 'link',

  variants: {
    underline: {
      true: {
        textDecorationLine: 'underline',
      },
    },
  } as const,
});

export const Anchor = AnchorFrame.styleable<AnchorProps & GetProps<typeof AnchorFrame>>(
  ({ href, target, ...props }, ref) => {
    return (
      <AnchorFrame
        {...props}
        {...(isWeb
          ? {
              href,
              target,
            }
          : {
              onPress: (event) => {
                props.onPress?.(event);
                if (href !== undefined) {
                  Linking.openURL(href);
                }
              },
            })}
        ref={ref}
      />
    );
  },
);
