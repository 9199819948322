import {
  FC,
  PropsWithChildren,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Stack, TamaguiElement, withStaticProperties } from '@tamagui/core';

import {
  FloatingPortal,
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';

import { zIndex } from '../../tokens/zIndex';
import { BlurView } from '../blur-view';

import { useTooltipSync } from './TooltipController';
import { TooltipInner } from './TooltipInner';
import { TooltipSharedProps } from './TooltipProps';
import { TooltipText } from './TooltipText';

interface TooltipProps {
  openDelay?: number;
  closeDelay?: number;
}

interface TooltipOptions extends TooltipProps {
  placement?: Placement;
  disabled?: boolean;
}

function useTooltip({
  placement = 'bottom',
  openDelay = 500,
  closeDelay = 800,
  disabled = false,
}: TooltipOptions = {}) {
  const state = useState(false);
  useTooltipSync(state);
  const [open, setOpen] = state;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: !disabled,
    delay: {
      open: openDelay,
      close: closeDelay,
    },
  });
  const focus = useFocus(context, { enabled: !disabled });
  const dismiss = useDismiss(context, { enabled: !disabled });
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  const transition = useTransitionStyles(context, {
    // Configure both open and close durations:
    duration: 200,
  });

  return useMemo(
    () => ({
      open: transition.isMounted,
      transition,
      setOpen,
      ...interactions,
      ...data,
    }),
    [transition, setOpen, interactions, data],
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

const useTooltipContext = () => useContext(TooltipContext);

const TooltipTrigger = forwardRef<TamaguiElement, React.HTMLProps<HTMLElement> & { asChild?: boolean }>(
  ({ children, asChild, ...props }, propRef) => {
    const context = useTooltipContext();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO this isn't typed correctly and we should fix it
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context?.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context?.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context?.open ? 'open' : 'closed',
        }),
      );
    }

    // TS mapping here is loose; we are assuming this Stack is a div on web
    return (
      <Stack
        ref={ref}
        // The user can style the trigger based on the state
        data-state={context?.open ? 'open' : 'closed'}
        {...context?.getReferenceProps(props)}
      >
        {children}
      </Stack>
    );
  },
);

const TooltipContent = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & TooltipSharedProps>(
  (props, propRef) => {
    const context = useTooltipContext();
    const ref = useMergeRefs([propRef].concat(context ? [context?.refs.setFloating] : []));
    const { children, tooltipTitle, ...rest } = props;

    if (!context || !context.transition.isMounted) return null;

    return (
      <FloatingPortal>
        <div
          ref={ref}
          title={typeof tooltipTitle === 'string' ? tooltipTitle : undefined}
          style={{
            ...context.floatingStyles,
            ...context.transition.styles,
            zIndex: zIndex.tooltip,
          }}
          {...context.getFloatingProps(rest)}
        >
          <BlurView intensity={10} tint="dark" borderRadius="$2" position="absolute" top={0} />
          <TooltipInner maxWidth={300}>{children}</TooltipInner>
        </div>
      </FloatingPortal>
    );
  },
);

const TooltipContainer: FC<PropsWithChildren<TooltipOptions>> = ({ children, ...options }) => {
  const tooltip = useTooltip(options);
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
};

export const Tooltip = withStaticProperties(TooltipContainer, {
  Trigger: TooltipTrigger,
  Content: TooltipContent,
  Text: TooltipText,
});
