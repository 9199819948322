import { ikClient } from '@arrived/imagekit';

import { BuilderBannerIcons } from '../models';

export const IconSources = {
  [BuilderBannerIcons.BELL]: {
    path: '/images/icons/bell-3.png',
    transformation: [{ height: '60', ratio: '1:1' }],
  },
  [BuilderBannerIcons.DIVIDEND_PAYMENT]: {
    path: '/bricks/dividend.payment.png',
    transformation: [{ height: '60', ratio: '1:1' }],
  },
  [BuilderBannerIcons.MOVE_MONEY_SUCCESS]: {
    path: '/bricks/move.money.success.png',
    transformation: [{ height: '60', ratio: '1:1' }],
  },
  [BuilderBannerIcons.TRADE_PURCHASE_SUCCESS]: {
    path: '/bricks/trade.purchase.success.png',
    transformation: [{ height: '60', ratio: '1:1' }],
  },
  [BuilderBannerIcons.KORIN]: {
    path: '/features/banner/korin.jpg',
    transformation: [{ height: '60', width: '60', ratio: '1:1' }],
  },
};

/**
 * Gets the ikClient source from a BuilderBannerIcons enum, if provided
 * otherwise returns undefined and does not render an image
 * @param icon
 * @returns
 */
export const getIllustrationSource = (icon?: BuilderBannerIcons) =>
  icon ? ikClient.url(IconSources[icon]) : undefined;
