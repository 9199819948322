import { useMemo } from 'react';

import { Stack, StackProps, UtilityText } from '@arrived/bricks';
import { StandardDisclaimerContent, getStandardDisclaimerProps } from '@arrived/common';
import { Offering } from '@arrived/models';

interface StandardDisclaimerProps extends StackProps {
  offering?: Offering;
}

// Note: We have textDecoration on the links below for accessibility reasons
export const StandardDisclaimer = ({ offering, ...rest }: StandardDisclaimerProps) => {
  const disclaimerProps = useMemo(() => getStandardDisclaimerProps(offering), [offering]);

  return (
    <Stack display="block" {...rest}>
      <StandardDisclaimerContent
        {...disclaimerProps}
        LinkElement={({ href, text }) => (
          <UtilityText
            $platform-web={{ fontSize: 'inherit !important', lineHeight: 'inherit !important' }}
            token="utility.hyperlink.medium"
            tag="a"
            href={href}
          >
            {text}
          </UtilityText>
        )}
        lineBreakElement={<br />}
      />
    </Stack>
  );
};
