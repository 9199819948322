import { useContext } from 'react';

import { createStyledContext } from '@tamagui/core';

export interface DataPointStyledContextType {
  alignment?: 'left' | 'right' | 'center';
  colors?: 'light' | 'dark' | 'muted';
  inline?: boolean;
  loading?: boolean;
  variant?: 'default' | 'minimized' | 'elevate' | 'elevateAlt';
}

export const DataPointStyledContext = createStyledContext<DataPointStyledContextType>({
  alignment: 'left',
  colors: 'light',
  inline: false,
  loading: false,
  variant: 'default',
});

export const useDataPointStyledContext = () => {
  const context = useContext<DataPointStyledContextType>(DataPointStyledContext);

  if (!context) {
    throw new Error('DataPointStyledContext is not provided');
  }

  return context;
};
