import { ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

import { DefaultError } from '@tanstack/react-query';

import { getStringFromError } from '@arrived/common';

export interface FormattedErrorMessageProps extends FormattedMessageProps<Record<string, ReactNode>> {
  error?: DefaultError | null;
}

/**
 * Default FormattedMessage component for showing error notifications.
 */
export const FormattedErrorMessage = (props: FormattedErrorMessageProps) => {
  const { defaultMessage, values, error } = props;

  return (
    <FormattedMessage
      {...props}
      defaultMessage={`${defaultMessage}: "{error}"`}
      values={{
        ...values,
        error: getStringFromError(error),
      }}
    />
  );
};
