import { Fragment, useContext } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { format } from 'date-fns';

import { DataPoint, Divider, KeyIcon, Stack, isWeb } from '@arrived/bricks';
import { ROUTES } from '@arrived/common';
import { NavigatorContext } from '@arrived/contexts';
import { OfferingTransaction } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../content';

export type TransactionHistorySectionProps = ProductDetailContentProps & { transactions: OfferingTransaction[] };

export const TransactionHistorySection = ({ transactions, ...rest }: TransactionHistorySectionProps) => {
  const { navigatorGo } = useContext(NavigatorContext);

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.transaction-history" />
      </ProductDetailContent.Header>
      {/* Disable the padding and spacing on the body so that each of the transaction items can have the padding and have a hover/press effect on the whole section */}
      <ProductDetailContent.Body gap={0} px={0} py={0} $gtXxs={{ px: 0 }}>
        {transactions.map(({ cid, sharesCount, signedAt, totalAmount }, idx) => (
          <Fragment key={cid}>
            <Stack
              py="$6"
              px="$4"
              $gtXxs={{ px: '$6' }}
              tag="button"
              borderWidth={0}
              bg="transparent"
              onPress={
                isWeb
                  ? () =>
                      navigatorGo(ROUTES.account.portfolioActivity.transaction, {
                        pathOptions: { transactionCid: cid },
                      })
                  : undefined
              }
              cursor="pointer"
              hoverStyle={isWeb ? { bg: '$onSurface.neutral.zebra' } : undefined}
              pressStyle={isWeb ? { bg: '$onSurface.neutral.zebraAlt' } : undefined}
              alignItems="center"
              justifyContent="space-between"
              row
            >
              <Stack alignItems="center" gap="$4" row>
                <Stack centered br="$full" w="$8" h="$8" bg="$onSurface.neutral.zebraAlt">
                  <KeyIcon size="md" />
                </Stack>
                <DataPoint colors="dark" variant="minimized">
                  <DataPoint.Value>
                    <FormattedMessage id="product-details.purchase" />
                  </DataPoint.Value>
                  {signedAt && <DataPoint.Label>{format(new Date(signedAt), 'MMM dd, yyyy')}</DataPoint.Label>}
                </DataPoint>
              </Stack>
              <DataPoint colors="dark" variant="minimized">
                <DataPoint.Value>
                  <FormattedNumber
                    value={totalAmount}
                    currency="USD"
                    style="currency"
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                  />
                </DataPoint.Value>
                <DataPoint.Label>
                  <FormattedMessage id="product-details.x-shares" values={{ count: sharesCount }} />
                </DataPoint.Label>
              </DataPoint>
            </Stack>
            {idx < transactions.length - 1 && <Divider px="$4" $gtXxs={{ px: '$6' }} />}
          </Fragment>
        ))}
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};
