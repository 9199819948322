import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchResponseOption } from '@arrived/api_v2';
import { ResponseOption } from '@arrived/models';

import { responseOptionKeyFn } from './questionnaires.keys';

export function usePatchResponseOptionMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchResponseOption,
    onSuccess: (data) => {
      queryClient.setQueryData<ResponseOption>(responseOptionKeyFn(data.uuid), data);

      queryClient.invalidateQueries({ queryKey: responseOptionKeyFn(data.uuid) });
    },
  });
}
