import { createStyledContext } from '@arrived/bricks';

interface ProductInfoBoxStyledContextType {
  link?: boolean;
  variant: 'highlight' | 'highlightAlt' | 'default';
}

export const ProductInfoBoxStyledContext = createStyledContext<ProductInfoBoxStyledContextType>({
  variant: 'default',
  link: false,
});
