import { ElementType } from 'react';

import { A } from '@expo/html-elements';

import { HrefProps } from './types';

export const Href = <T extends ElementType = typeof A>({
  href,
  as: Component = A,
  onPress,
  target = '_blank',
  rel = 'noopener noreferrer',
  hrefAttrs = { target, rel },
  style,
  ...rest
}: HrefProps<T>) => (
  <Component
    href={href}
    hrefAttrs={hrefAttrs}
    onPress={onPress}
    rel={rel}
    target={target}
    // ! NOTE: This is just defaults for the web version of this component
    style={[{ color: 'inherit', font: 'inherit' }, style]}
    {...rest}
  />
);
