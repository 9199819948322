import { useCallback, useEffect, useMemo, useState } from 'react';

import { Freeze } from 'react-freeze';

import { differenceInDays, differenceInWeeks } from 'date-fns';

import { Loading, Stack, useDisclosure } from '@arrived/bricks';
import { Property } from '@arrived/models';

import { useProductDetailsViewContext } from '../../../../ProductDetailsViewContext';

import { FundPropertyListImpl } from './FundPropertyListImpl';
import { FundPropertySheet } from './FundPropertySheet';

export type FundPropertyListProps = { properties: Property[] };

export const FundPropertyList = ({ properties }: FundPropertyListProps) => {
  const [currentSelectedProperty, setCurrentSelectedProperty] = useState<Property>();
  const { currentlySelectedPropertyId, setCurrentlySelectedPropertyId } = useProductDetailsViewContext();

  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: false,
    onClose: () => {
      setCurrentSelectedProperty(undefined);
      setCurrentlySelectedPropertyId(undefined);
    },
  });

  const { otherProperties, recentlyAcquiredProperties } = useMemo(
    () =>
      properties
        .sort((prop1, prop2) => {
          // Sort by descending date, "largest" (most recent date) first. In the case that one doesn't have a goLiveDate, put the other first, otherwise default to inherent ordering.
          if (prop1.goLiveDate != null && prop2.goLiveDate != null) {
            return differenceInDays(new Date(prop2.goLiveDate), new Date(prop1.goLiveDate));
          } else if (prop2.goLiveDate != null) {
            return -1;
          } else {
            return 1;
          }
        })
        .reduce(
          (acc, property) => {
            if (property.goLiveDate && differenceInWeeks(new Date(), new Date(property.goLiveDate)) <= 2) {
              acc.recentlyAcquiredProperties.push(property);
            } else {
              acc.otherProperties.push(property);
            }

            return acc;
          },
          { otherProperties: [] as Property[], recentlyAcquiredProperties: [] as Property[] },
        ),
    [properties],
  );

  const handlePropertyPress = useCallback((property: Property) => {
    setCurrentSelectedProperty(property);
    setCurrentlySelectedPropertyId(property.id);
  }, []);

  useEffect(() => {
    if (currentlySelectedPropertyId != null) {
      onOpen();
    }
  }, [currentlySelectedPropertyId]);

  return (
    <>
      <Freeze
        freeze={!properties.length}
        placeholder={
          <Stack flexGrow={1} centered py="$6">
            <Loading.Indicator variant="colored" />
          </Stack>
        }
      >
        <FundPropertyListImpl
          recentlyAcquiredProperties={recentlyAcquiredProperties}
          otherProperties={otherProperties}
          onPropertyPress={handlePropertyPress}
        />
      </Freeze>
      <FundPropertySheet
        enablePanDownToClose
        property={currentSelectedProperty}
        propertyId={currentlySelectedPropertyId}
        open={isOpen}
        onClose={onClose}
      />
    </>
  );
};
