import { forwardRef, memo, useMemo } from 'react';

import { Circle, Ellipse, Rect, Svg, SvgProps } from 'react-native-svg';

import { GetProps, TamaguiElement, styled, withStaticProperties } from '@tamagui/core';
import { LinearGradient } from '@tamagui/linear-gradient';

import { gradients } from '../../tokens';
import { BlurView, BlurViewProps } from '../blur-view';
import { Stack } from '../layout';

import { NoiseBackground } from './NoiseBackground';

const NoiseFrame = styled(LinearGradient, {
  name: 'Noise',

  flex: 1,
  start: [0, 0],
  end: [1, 1],
  zIndex: -1,

  variants: {
    /**
     * Emphasized background, meaning a dark background with noise.
     *
     * TODO: Get a better description for what emphasized means @kaceycleveland
     */
    emphasized: {
      true: {},
    },
  } as const,
});

export type BackgroundProps = GetProps<typeof NoiseFrame>;

const Noise = NoiseFrame.styleable<GetProps<typeof NoiseFrame> & { passedKey?: string }>(
  ({ emphasized, passedKey, children, ...rest }, ref) => {
    const gradientColors = useMemo(() => {
      if (emphasized) {
        return gradients['gradient.primary.default'];
      }

      return gradients['gradient.neutral.yin'];
    }, [emphasized]);

    return (
      <LinearGradient
        ref={ref}
        zIndex={-1}
        // @ts-ignore -- this type is actually fine...?
        start={[0, 0]}
        // @ts-ignore -- this type is actually fine...?
        end={[1, 1]}
        colors={gradientColors}
        h="100%"
        w="100%"
        {...rest}
      >
        <NoiseBackground passedKey={passedKey} />
        {children}
      </LinearGradient>
    );
  },
);

const ColorFrame = styled(Stack, {
  name: 'ColorFrame',
  w: '100%',
  h: '100%',
  position: 'relative',
});

const BlurFrame = forwardRef<TamaguiElement, BlurViewProps>((props, ref) => (
  <Stack width="100%" height="100%">
    <BlurView ref={ref} width="100%" height="100%" intensity={300} saturation={300} {...props} />
  </Stack>
));

const SvgFrame = memo((props: SvgProps) => (
  <Svg
    viewBox="0 0 600 600"
    preserveAspectRatio="xMinYMin slice"
    style={{
      flex: 1,
      zIndex: -2,
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
    }}
    {...props}
  />
));

const CottonCandy = ColorFrame.styleable(({ children, ...rest }, ref) => (
  <ColorFrame ref={ref} {...rest}>
    <SvgFrame>
      <Circle cx="373.558" cy="357.754" r="253.589" fill="#7DCBEB" />
      <Circle cx="-24.7839" cy="357.395" r="223.776" fill="#A67DAC" />
      <Circle cx="352.726" cy="88.3608" r="173.13" fill="#7DCBEB" />
      <Ellipse cx="436.417" cy="-10.7757" rx="190.73" ry="186.061" fill="#BADCF0" />
      <Circle cx="81.5364" cy="112.427" r="197.196" fill="#FFEBF9" />
      <Circle cx="218.388" cy="183.187" r="78.3035" fill="#FFEBF9" />
    </SvgFrame>
    <BlurFrame>{children}</BlurFrame>
  </ColorFrame>
));

const Blueberry = ColorFrame.styleable(({ children, ...rest }, ref) => (
  <ColorFrame ref={ref} {...rest}>
    <SvgFrame>
      <Circle cx="191.848" cy="184.156" r="107.535" fill="#EEF4EE" />
      <Circle cx="170.992" cy="306.483" r="75.4375" fill="#D9D9D9" />
      <Circle cx="-82.6852" cy="472.002" r="288.881" fill="#5F7BA0" />
      <Circle cx="386.21" cy="193.623" r="98.0688" fill="#A4BFE4" />
      <Circle cx="377.632" cy="370.235" r="154.277" fill="#628CD1" />
      <Circle cx="177.5" cy="458.837" r="88.4542" fill="#A0B2CA" />
      <Circle cx="345.238" cy="22.1874" r="139.042" fill="#A2C2F8" />
      <Circle cx="89.0456" cy="81.6499" r="134.308" fill="#F5F9F6" />
      <Circle cx="191.7" cy="281.042" r="102.654" fill="#E1EAFC" />
    </SvgFrame>
    <BlurFrame>{children}</BlurFrame>
  </ColorFrame>
));

const Kiwi = ColorFrame.styleable(({ children, ...rest }, ref) => (
  <ColorFrame ref={ref} {...rest}>
    <SvgFrame>
      <Rect width="426" height="426" fill="white" />
      <Rect x="-1.77441" y="-1.7749" width="427.775" height="427.775" />
      <Circle cx="224.094" cy="212.852" r="371.419" fill="#8BD1CA" />
      <Circle cx="2.51439" cy="-8.72707" r="149.84" fill="#F7EBF7" />
      <Circle cx="426.001" cy="426" r="188.15" fill="#9AA1B8" />
      <Circle cx="426.001" cy="4.57764e-05" r="116.262" fill="#A6DDE2" />
      <Circle cx="43.6361" cy="370.827" r="138.302" fill="#80D1DF" />
    </SvgFrame>
    <BlurFrame>{children}</BlurFrame>
  </ColorFrame>
));

const Strawberry = ColorFrame.styleable(({ children, ...rest }, ref) => (
  <ColorFrame ref={ref} {...rest}>
    <SvgFrame>
      <Rect width="426" height="426" fill="#FFE0E2" />
      <Circle cx="169.5" cy="121.5" r="153.5" fill="#FFFDFC" />
      <Circle cx="290" cy="117" r="96" fill="#FFF1F3" />
      <Circle cx="119" cy="375" r="113" fill="#FFDEE1" />
      <Circle cx="386" cy="225" r="81" fill="#FFDDDD" />
      <Circle cx="371" cy="416" r="81" fill="#FFE6EB" />
    </SvgFrame>
    <BlurFrame>{children}</BlurFrame>
  </ColorFrame>
));

const Success = ColorFrame.styleable(({ children, ...rest }, ref) => (
  <ColorFrame ref={ref} {...rest}>
    <SvgFrame>
      <Rect width="100%" height="100%" fill="#80c2c2" />
      <Circle cx="-10%" cy="20%" r="200" fill="#FDEEFA" />
      <Circle cx="60%" cy="90%" r="200" fill="#96ABBB" />
    </SvgFrame>
    <BlurFrame
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      style={{
        // Overwrite the original blur
        backgroundColor: 'unset',
      }}
    >
      {children}
    </BlurFrame>
  </ColorFrame>
));

const Color = ColorFrame.styleable(({ children, ...rest }, ref) => (
  <ColorFrame ref={ref} {...rest}>
    {children}
  </ColorFrame>
));

const BackgroundFrame = styled(Stack, {
  name: 'Background',
});

export const Background = withStaticProperties(BackgroundFrame, {
  Noise,

  CottonCandy,
  Blueberry,
  Strawberry,
  Kiwi,

  Success,

  Color,
});
