import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchOfferingFixedRateInvestments } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../../utils';
import { offeringKeyFn } from '../offerings.keys';

export function usePatchOfferingFixedRateInvestments(
  options?: UseAwaitedMutationOptions<typeof patchOfferingFixedRateInvestments>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchOfferingFixedRateInvestments,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: offeringKeyFn(args[0].id!),
      });
      options?.onSuccess?.(...args);
    },
  });
}
