import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import {
  sfrLeveredReturnLower,
  sfrLeveredReturnUpper,
  sfrUnleveredReturnLower,
  sfrUnleveredReturnUpper,
  vrLeveredReturnLower,
  vrLeveredReturnUpper,
  vrUnleveredReturnLower,
  vrUnleveredReturnUpper,
} from '@arrived/common';
import { AssetType, Offering } from '@arrived/models';

export interface TopReturnsPercentages {
  offering: Offering;
}

export const TopReturnsPercentages = ({ offering: { debtPercent, assetType } }: TopReturnsPercentages) => {
  const intl = useIntl();

  const [lower, upper] = useMemo(() => {
    if (debtPercent > 0) {
      if (assetType === AssetType.LTR) {
        return [sfrLeveredReturnLower, sfrLeveredReturnUpper];
      } else {
        return [vrLeveredReturnLower, vrLeveredReturnUpper];
      }
    } else {
      if (assetType === AssetType.LTR) {
        return [sfrUnleveredReturnLower, sfrUnleveredReturnUpper];
      } else {
        return [vrUnleveredReturnLower, vrUnleveredReturnUpper];
      }
    }
  }, [assetType, debtPercent]);

  return (
    <>
      {intl.formatNumber(lower * 100, { maximumFractionDigits: 1, minimumFractionDigits: 0 })}-
      {intl.formatNumber(upper, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 0,
        style: 'percent',
      })}
    </>
  );
};
