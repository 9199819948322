import { FormattedMessage } from 'react-intl';

import { styled } from '@arrived/bricks';
import { AccordionList, AccordionListProps, Href } from '@arrived/bricks-common';

import { LinkText as DefaultLinkText } from './LinkText';
import { CommonQuestionType } from './types';

export type CommonQuestionsProps = {
  LinkText?: CommonQuestionType['LinkText'];
  questions: Omit<CommonQuestionType, 'LinkText'>[];
} & Omit<AccordionListProps, 'divider'>;

const CustomWrapper = styled(AccordionList.Item.Wrapper, {
  // @ts-expect-error We've intentionally decided to not add 'CommonQuestion' as a group name in
  // `bricks` since this is a specific use case and not one that lives in `bricks`.
  group: 'CommonQuestion',
});

export const CommonQuestions = ({ LinkText = DefaultLinkText, questions, ...rest }: CommonQuestionsProps) => (
  <AccordionList {...rest}>
    {questions.map(({ Answer, link, Question }, idx) => {
      return (
        <AccordionList.Item Wrapper={CustomWrapper} Content={() => <Question fontWeight="bold" />} key={idx}>
          <AccordionList.Item.Children>
            <Answer />
            {link && (
              <Href href={link}>
                <LinkText color="$onSurface.primary.decorative" textDecorationLine="underline">
                  <FormattedMessage id="common.learn-more" />
                </LinkText>
              </Href>
            )}
          </AccordionList.Item.Children>
        </AccordionList.Item>
      );
    })}
  </AccordionList>
);
