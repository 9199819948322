import { Offering, OfferingDividend } from '@arrived/models';

export const getHistoricalReturnRange = (
  offering: Offering,
  equity: { lower?: number; upper?: number },
  offeringDividends?: OfferingDividend[],
) => {
  const lowerEquity = !equity.lower || isNaN(equity.lower) ? 0 : equity.lower;
  const upperEquity = !equity.upper || isNaN(equity.upper) ? 0 : equity.upper;

  const minDividend =
    offering.projectedAnnualDividendYield && offeringDividends?.[0]
      ? Math.min(offering.projectedAnnualDividendYield, offeringDividends[0].annualizedDividendPercent)
      : offeringDividends?.[0]?.annualizedDividendPercent ?? offering.projectedAnnualDividendYield ?? 0;

  const maxDividend =
    offering.projectedAnnualDividendYield && offeringDividends?.[0]
      ? Math.max(offering.projectedAnnualDividendYield, offeringDividends[0].annualizedDividendPercent)
      : offeringDividends?.[0]?.annualizedDividendPercent ?? offering.projectedAnnualDividendYield ?? 0;

  const total_historical_return_min = minDividend + lowerEquity;
  const total_historical_return_max = maxDividend + upperEquity;

  return {
    total_historical_return_min,
    total_historical_return_max,
  };
};
