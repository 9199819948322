import { useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

import _max from 'lodash/max';
import _min from 'lodash/min';
import {
  CallbackArgs,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';

import { Stack, useMedia, useTheme } from '@arrived/bricks';

export interface ReturnsChartProps {
  investmentValues: number[];
}

export const ReturnsChart = ({ investmentValues }: ReturnsChartProps) => {
  const theme = useTheme();
  const intl = useIntl();

  const media = useMedia();

  const { ref, width } = useResizeDetector();

  const chartDisplayValues = useMemo(
    () => investmentValues.slice(1).map((value, index) => ({ x: index + 1, y: value })),
    [investmentValues],
  );

  const xTickValues = useMemo(
    () => chartDisplayValues.map(({ x }) => x).filter((x) => x % 2 === 1),
    [chartDisplayValues],
  );

  const xAxisDomain = useMemo(() => {
    const xValues = chartDisplayValues.map(({ x }) => x);

    const min = _min(xValues) ?? 0;
    const max = _max(xValues) ?? 10;

    return [min - 1, max + 1] as [number, number];
  }, [chartDisplayValues]);

  const yAxisDomain = useMemo(() => {
    const yValues = chartDisplayValues.map(({ y }) => y);

    const min = _min(yValues) ?? 1000;
    const max = _max(yValues) ?? 1400;

    const diff = Math.abs(min - max);

    return [min - diff / 5, max + diff / 5] as [number, number];
  }, [chartDisplayValues]);

  const axisStyle = useMemo(
    () => ({
      axis: {
        fill: theme['onSurface.neutral.defaultInverted'].val,
        stroke: theme['onSurface.neutral.defaultInverted'].val,
      },
      ticks: {
        fill: theme['onSurface.neutral.defaultInverted'].val,
        stroke: theme['onSurface.neutral.defaultInverted'].val,
      },
      tickLabels: {
        fill: theme['onSurface.neutral.defaultInverted'].val,
        stroke: theme['onSurface.neutral.defaultInverted'].val,
        fontWeight: 100,
      },
      axisLabel: {
        fill: theme['onSurface.neutral.defaultInverted'].val,
        stroke: theme['onSurface.neutral.defaultInverted'].val,
        fontWeight: 400,
        padding: 35,
      },
    }),
    [theme],
  );

  return (
    <Stack ref={ref} key={media.gtXs.toString()}>
      <VictoryChart
        animate={{ duration: 500, onLoad: { duration: 500 } }}
        height={300}
        width={width}
        padding={{ top: 10, bottom: 50, left: 50, right: 10 }}
        aria-label={intl.formatMessage({ id: 'property-details.calculator.returns-chart.label' })}
      >
        <VictoryAxis
          tickFormat={(value: number) =>
            media.gtXs ? intl.formatMessage({ id: 'common.year-count' }, { range: value }) : Math.floor(value)
          }
          label={media.gtXs ? undefined : 'Years'}
          domain={xAxisDomain}
          tickValues={xTickValues}
          style={axisStyle}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(value: number) =>
            intl.formatNumber(value, { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })
          }
          domain={yAxisDomain}
          style={axisStyle}
        />
        <VictoryLine
          data={chartDisplayValues}
          style={{
            data: { stroke: theme['interactive.primary.rested'].val, strokeWidth: 1 },
          }}
        />
        <VictoryScatter
          data={chartDisplayValues}
          size={3}
          style={{
            data: {
              fill: theme['onSurface.neutral.defaultInverted'].val,
              stroke: theme['interactive.primary.rested'].val,
              strokeWidth: 1,
            },
          }}
          labels={({ datum }: CallbackArgs) => [
            intl.formatMessage({ id: 'common.year-count' }, { range: datum.x }),
            intl.formatMessage(
              { id: 'common.hypothetical-return' },
              {
                returnAmount: intl.formatNumber(datum.y, {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0,
                }),
              },
            ),
          ]}
          labelComponent={
            <VictoryTooltip
              flyoutPadding={15}
              flyoutStyle={{
                fill: theme['onSurface.neutral.zebra'].val,
                stroke: theme['interactive.primary.rested'].val,
                strokeWidth: 2,
              }}
              labelComponent={
                <VictoryLabel
                  lineHeight={2}
                  style={[{ fontWeight: 'bold' }, { fill: theme['interactive.primary.rested'].val }]}
                />
              }
              constrainToVisibleArea
            />
          }
        />
      </VictoryChart>
    </Stack>
  );
};
