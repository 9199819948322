import { ZillowPriceZipCode, ZillowZipCodeAppreciation, ZipCode } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getZillowPriceByZipCode = (zipCode: ZipCode, from: Date) =>
  createQuery<ZillowPriceZipCode[]>({
    apiInstance: api,
    method: 'get',
    url: `/zillow/price/zip-code/${zipCode}`,
    config: { params: { from: from.toISOString().split('T')[0] } },
  });

export const getZillowZipCodeAppreciation = (zipCode: ZipCode) =>
  createQuery<ZillowZipCodeAppreciation>({
    apiInstance: api,
    method: 'get',
    url: `/zillow/appreciation/zip-code/${zipCode}`,
  });
