import { useMemo } from 'react';

import { InvestorArchetype, PlannedInvestmentAmount } from '@arrived/models';

const investmentAmountMap: Record<
  InvestorArchetype,
  Record<PlannedInvestmentAmount | 'DEFAULT', { low: number; high: number }>
> = {
  [InvestorArchetype.UNKNOWN]: {
    [PlannedInvestmentAmount.R100_2000]: { low: 400, high: 800 },
    [PlannedInvestmentAmount.R2000_5000]: { low: 900, high: 1_800 },
    [PlannedInvestmentAmount.R5000_25000]: { low: 2_500, high: 5_000 },
    [PlannedInvestmentAmount.R25000_100000]: { low: 3_000, high: 6_000 },
    [PlannedInvestmentAmount.R100000_OR_MORE]: { low: 3_000, high: 6_000 },
    DEFAULT: { low: 600, high: 1_200 },
  },
  [InvestorArchetype.NEWBIE]: {
    [PlannedInvestmentAmount.R100_2000]: { low: 250, high: 500 },
    [PlannedInvestmentAmount.R2000_5000]: { low: 900, high: 1_800 },
    [PlannedInvestmentAmount.R5000_25000]: { low: 1_500, high: 3_000 },
    [PlannedInvestmentAmount.R25000_100000]: { low: 8_000, high: 16_000 },
    [PlannedInvestmentAmount.R100000_OR_MORE]: { low: 8_000, high: 16_000 },
    DEFAULT: { low: 400, high: 800 },
  },
  [InvestorArchetype.REBEL]: {
    [PlannedInvestmentAmount.R100_2000]: { low: 400, high: 800 },
    [PlannedInvestmentAmount.R2000_5000]: { low: 1_000, high: 2_000 },
    [PlannedInvestmentAmount.R5000_25000]: { low: 2_000, high: 4_000 },
    [PlannedInvestmentAmount.R25000_100000]: { low: 5_000, high: 10_000 },
    [PlannedInvestmentAmount.R100000_OR_MORE]: { low: 5_000, high: 10_000 },
    DEFAULT: { low: 900, high: 1_800 },
  },
  [InvestorArchetype.WSJ]: {
    [PlannedInvestmentAmount.R100_2000]: { low: 350, high: 700 },
    [PlannedInvestmentAmount.R2000_5000]: { low: 750, high: 1_500 },
    [PlannedInvestmentAmount.R5000_25000]: { low: 2_000, high: 4_000 },
    [PlannedInvestmentAmount.R25000_100000]: { low: 4_000, high: 8_000 },
    [PlannedInvestmentAmount.R100000_OR_MORE]: { low: 4_000, high: 8_000 },
    DEFAULT: { low: 1_000, high: 2_000 },
  },
};

/**
 * Returns the average investment amount for a user depending on the archetype and plannedInvestmentAmount.
 */
export function useAverageInvestmentAmounts(
  archetype: InvestorArchetype,
  plannedInvestmentAmount?: PlannedInvestmentAmount,
) {
  return useMemo(() => {
    return investmentAmountMap[archetype][plannedInvestmentAmount ?? 'DEFAULT'];
  }, [archetype, plannedInvestmentAmount]);
}
