import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function LinkAltIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0013 6.22341H7.43908L7.43908 4.97341H14.5102L15.1351 4.97341L15.1352 5.59841L15.1351 12.6695L13.8851 12.6695V7.10731L5.52401 15.4685L4.64013 14.5846L13.0013 6.22341Z"
        fill={color}
      />
    </Svg>
  );
}

export const LinkAltIcon = memo<IconProps>(withIconTheme(LinkAltIconInternal));
