import { enUS } from '@arrived/common';
import { LeaseStatus, Property } from '@arrived/models';

export const RentalStatus = {
  PROPERTY_TENANT_ISSUE: 'PROPERTY_TENANT_ISSUE',
  MARKET_PREPARATION: 'MARKET_PREPARATION',
  APPROVED_APPLICATION: 'APPROVED_APPLICATION',
  MARKETED_FOR_RENT: 'MARKETED_FOR_RENT',
  RENTED: 'RENTED',
} as const;

export const RentalStatusOrder: Record<RentalStatus, number> = {
  [RentalStatus.RENTED]: 0,
  [RentalStatus.APPROVED_APPLICATION]: 1,
  [RentalStatus.MARKETED_FOR_RENT]: 2,
  [RentalStatus.MARKET_PREPARATION]: 3,
  [RentalStatus.PROPERTY_TENANT_ISSUE]: 4,
};

export type RentalStatus = (typeof RentalStatus)[keyof typeof RentalStatus];

export const RentalStatusText: Record<RentalStatus, { title: keyof typeof enUS; subtitle: keyof typeof enUS }> = {
  [RentalStatus.APPROVED_APPLICATION]: {
    title: 'product-details.rental-status.approved-application.title',
    subtitle: 'product-details.rental-status.approved-application.subtitle',
  },
  [RentalStatus.MARKETED_FOR_RENT]: {
    title: 'product-details.rental-status.marketed-for-rent.title',
    subtitle: 'product-details.rental-status.marketed-for-rent.subtitle',
  },
  [RentalStatus.MARKET_PREPARATION]: {
    title: 'product-details.rental-status.market-preparation.title',
    subtitle: 'product-details.rental-status.market-preparation.subtitle',
  },
  [RentalStatus.PROPERTY_TENANT_ISSUE]: {
    title: 'product-details.rental-status.property-tenant-issue.title',
    subtitle: 'product-details.rental-status.property-tenant-issue.subtitle',
  },
  [RentalStatus.RENTED]: {
    title: 'product-details.rental-status.rented.title',
    subtitle: 'product-details.rental-status.rented.subtitle',
  },
};

export const getPropertyRentalStatus = (leaseStatus: LeaseStatus) => {
  switch (leaseStatus) {
    case LeaseStatus.VACANT_OFFLINE:
    case LeaseStatus.SQUATTER:
    case LeaseStatus.EVICTION:
      return RentalStatus.PROPERTY_TENANT_ISSUE;
    case LeaseStatus.VACANT_UNRENTED_NOT_READY:
    case LeaseStatus.UNDER_CONTRACT:
      return RentalStatus.MARKET_PREPARATION;
    case LeaseStatus.VACANT_RENTED_READY:
    case LeaseStatus.VACANT_RENTED_NOT_READY:
      return RentalStatus.APPROVED_APPLICATION;
    case LeaseStatus.VACANT_UNRENTED_READY:
      return RentalStatus.MARKETED_FOR_RENT;
    case LeaseStatus.OCCUPIED:
    case LeaseStatus.NOTICE:
    case LeaseStatus.OCCUPIED_PCO:
      return RentalStatus.RENTED;
    // All other lease statuses do not have a corresponding RentalStatus to display on the
    // client-side
    default:
      return undefined;
  }
};

export const getPropertiesByRentalStatus = (properties: Property[]) =>
  properties.reduce(
    (map, property) => {
      const rentalStatus = getPropertyRentalStatus(property.leaseStatus);

      if (!rentalStatus) {
        return map;
      }

      if (!Object.keys(map).includes(rentalStatus.toString())) {
        map[rentalStatus] = [];
      }

      map[rentalStatus].push(property);
      return map;
    },
    {} as Record<RentalStatus, Property[]>,
  );
