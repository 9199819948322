import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

import { styled } from '@tamagui/core';
import { ScrollView } from '@tamagui/scroll-view';

import { SHEET_SCROLL_VIEW_NAME, genericScrollViewStyles } from './utils';

/**
 * This is the frame that wraps the `SheetScrollView` and is responsible for
 * the fading effect on the bottom of the sheet on web. We use a standard `ScrollView` for web,
 * and the `BottomSheetScrollView` for native. This enables the dragging effect at any part of the sheet when
 * interacting with it. This does not work the same on web.
 */
const ScrollViewFrame = styled(ScrollView, {
  name: SHEET_SCROLL_VIEW_NAME,

  '$platform-web': {
    transform: [
      // @ts-ignore -- enabled for web to allow smooth scrolling
      { translateZ: 0 },
    ],
  },

  ...genericScrollViewStyles,
});

const AnimatedScrollViewComponent = Animated.createAnimatedComponent(ScrollViewFrame);

export const SheetScrollViewImpl = ScrollViewFrame.styleable(({ useScrollFade = false, ...props }, ref) => {
  const isAtBottom = useSharedValue(0);
  const scrollY = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      const { contentOffset, layoutMeasurement, contentSize } = event;

      scrollY.value = contentOffset.y;

      const distanceFromBottom = contentSize.height - layoutMeasurement.height - contentOffset.y;

      isAtBottom.value = interpolate(
        distanceFromBottom,
        // You can adjust the range here depending on how "close" to the bottom should count as "at bottom"
        [0, 15],
        [1, 0],
        Extrapolate.CLAMP,
      );
    },
  });

  const animatedStyle = useAnimatedStyle(
    // @ts-expect-error -- will be fixed by tamagui 1.75+
    () => ({
      WebkitMaskSize: `100% ${interpolate(isAtBottom.value, [0, 1], [100, 140])}%`,
    }),
    [isAtBottom],
  );

  return (
    <>
      <AnimatedScrollViewComponent
        ref={ref}
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        style={
          useScrollFade && [
            {
              // @ts-expect-error -- will be fixed by tamagui 1.75+
              WebkitMaskImage: `
                linear-gradient(
                  to bottom,
                  white calc(100% - 85px),
                  transparent
                );
              `,
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskComposite: 'source-in', // chrome
              maskComposite: 'intersect', // firefox
              transition: 'mask-size 180ms ease-in-out',
            },
            animatedStyle,
          ]
        }
        {...props}
      />
    </>
  );
});
