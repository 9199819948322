import {
  Children,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FieldValues, Resolver, UnpackNestedValue, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import startCase from 'lodash/startCase';
import * as yup from 'yup';

export type FormStepSchema = yup.AnyObjectSchema | yup.Lazy<never>; // i wish

export type FormStep<B, T = FormStepSchema> = {
  /**
   * Title for the step, displays in the step header.
   * Defaults to the parent step header
   */
  title?: string;

  /**
   * Internal name for the step, used for tracking analytics mainly
   * @default Start Case of the slug
   */
  name?: string;

  /**
   * @web
   * HREF URL for the step, will be used to navigate to the step
   * via the browser.
   */
  slug?: string;

  /**
   * Component to render, generated from the
   * `getSteps` function
   */
  component: ReactNode;

  /**
   * Disable a step
   */
  disabled?: boolean;

  /**
   * If a step is skippable, render a button to skip the step
   */
  skippable?: boolean;

  /**
   * The schema for the step that is passed in via props
   * @default FormStepSchema
   */
  schema?: T;

  /**
   * Next button text
   */
  nextButtonProps?: B;
};

export interface UseFormStepperControllerProps<U extends FieldValues, B, T = FormStepSchema> {
  steps: FormStep<B, T>[];

  /**
   * The default title for the step header
   */
  title?: string;

  /**
   * The initial step to start on
   * @default 0
   */
  initialStep?: number;

  /**
   * If you need to pass in context to the resolver, you can do so here. This
   * will be interpolated every step into the resolver. This contains
   * all the possible values in the form so there will be no wrong answer in terms
   * of what you can pass in here.
   */
  context?: (data: UnpackNestedValue<U>) => Record<string, unknown>;
}

/**
 * Grab the steps of the stepper and return them as an array. There
 * is a catch here to ensure that there is only a single component marked
 * `OnboardingSteps` and that it has children. You can pass in a
 * Function as a child or a ReactNode.
 *
 * Q: How do I set default values for the form?
 * A: Call `reset` with the default values on mount of your step
 *
 * ^ should we do this automatically?
 */
export const getSteps = <B>(children: ReactNode, parentNodeName: string, childNodeName: string) => {
  const steps: FormStep<B>[] = [];
  let instances = 0;

  Children.forEach(children, (child) => {
    if (isValidElement(child) && typeof child.type !== 'string') {
      instances = child.type.name === parentNodeName ? instances + 1 : instances;

      if (instances > 1) {
        throw new Error(`Stepper must only have a single component of type OnboardingSteps`);
      }

      if (instances === 1 && child.props.children) {
        const childSteps = child.props.children as ReactElement[];
        steps.push(...getChildSteps<B>(childSteps, childNodeName));
      }
    }
  });

  return steps;
};

export const getChildSteps = <B>(children: ReactNode, childNodeName: string) => {
  const steps: FormStep<B>[] = [];

  Children.forEach(children, (child) => {
    if (isValidElement(child) && typeof child.type !== 'string') {
      if (child.type.name === childNodeName) {
        const { title, slug, schema, name, ...rest } = child.props as PropsWithChildren<Omit<FormStep<B>, 'component'>>;

        steps.push({
          component: child,
          title,
          slug,
          schema,
          name: name ?? startCase(slug),
          ...rest,
        });
      } else if (child.props.children) {
        steps.push(...getChildSteps<B>(child.props.children, childNodeName));
      }
    }
  });

  return steps;
};

/**
 * This instantiates the logic behind the form stepper, controlling the form instance and
 * the current step.
 *
 * How to use:
 * - You should start by create a new context for this controller to be consumed, you
 * can follow `OnboardingStepper` as an example.
 * - You should then create components for your platform that consumes these controls or
 * a super set of the controls that handle specific logic
 *
 * @NOTE If you need to get the context of the stepper use `UseFormStepperContext` instead.
 */
export const useFormStepperController = <U extends FieldValues, B, T = FormStepSchema>({
  steps,
  title,
  context,
  initialStep = 0,
}: UseFormStepperControllerProps<U, B, T>) => {
  // Quick save for the timeout used in the useEffect
  const formTriggerTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [currentStepIndex, setCurrentStepIndex] = useState(initialStep);
  const [isReviewMode, setIsReviewMode] = useState(false);

  const currentStep = useMemo(() => steps[currentStepIndex], [currentStepIndex, steps]);
  const currentStepTitle = useMemo(() => currentStep?.title ?? title, [currentStep, title]);
  const currentStepName = useMemo(() => currentStep?.name, [currentStep?.name]);
  const currentStepSlug = useMemo(() => currentStep?.slug, [currentStep?.slug]);
  const currentSchema = useMemo(() => currentStep?.schema as yup.AnyObjectSchema, [currentStep?.schema]);

  const lastStepIndex = useMemo(() => steps.length - 1, [steps.length]);
  const isLastStep = useMemo(() => currentStepIndex === lastStepIndex, [currentStepIndex, lastStepIndex]);
  const isFirstStep = useMemo(() => currentStepIndex === 0, [currentStepIndex]);

  // TODO: Add typing for nullish coalescing
  const resolverSchema = useCallback<Resolver<U>>(
    // @ts-expect-error -- this was working fine previously, and think the updates to RHF broke it. still functions the same.
    (data, _context, options) => yupResolver(currentSchema)(data, { ..._context, ...(context?.(data) ?? {}) }, options),
    [context, currentSchema],
  );

  /**
   * The form instance that uses the current step's schema,
   * this is re-triggered in the useEffect below when a
   * new step is selected.
   */
  const formMethods = useForm<U>({
    mode: 'all',
    resolver: currentSchema ? resolverSchema : undefined,
  });

  /**
   * Validate the step is within range and set the current step.
   * Can take a slug or a number.
   */
  const jumpToStep = useCallback(
    (stepIndexOrSlug: string | number, reviewMode?: boolean) => {
      if (reviewMode != null) {
        setIsReviewMode(reviewMode);
      }

      const argType = typeof stepIndexOrSlug;

      if (argType === 'string' || argType === 'number') {
        const nextStep =
          {
            string: steps.findIndex((step) => step.slug === (stepIndexOrSlug as string)),
            number: Math.min(stepIndexOrSlug as number, lastStepIndex),
          }[argType] ?? currentStepIndex;

        if (nextStep !== currentStepIndex && nextStep >= 0 && nextStep <= lastStepIndex) {
          setCurrentStepIndex(nextStep);
        }
      }
    },
    [lastStepIndex, currentStepIndex],
  );

  const nextStep = useCallback(() => {
    if (currentStepIndex < lastStepIndex) {
      jumpToStep(currentStepIndex + 1);
    }
  }, [currentStepIndex, lastStepIndex]);

  /**
   * Go to the previous step if possible
   */
  const prevStep = useCallback(() => {
    if (currentStepIndex > 0) {
      jumpToStep(currentStepIndex - 1);
    }
  }, [currentStepIndex]);

  useEffect(() => {
    // Revalidate on mount, since we are unmounting forms for performance
    // We need to wait a bit for the form to be mounted
    // NOTE: tried using `requestAnimationFrame` but it didn't work as
    // quickly as `setTimeout`
    formTriggerTimeoutRef.current = setTimeout(async () => {
      if (formMethods.formState.isDirty) {
        // Enable the new schema by re-validating the form
        await formMethods.trigger();
      }

      // Clear errors on every step change since the schema
      // can be different and we don't want to show errors on
      // fields that aren't edited yet
      formMethods.clearErrors();
    });

    return () => {
      formTriggerTimeoutRef.current && clearTimeout(formTriggerTimeoutRef.current);
    };
  }, [currentStepIndex]);

  return {
    /**
     * General State
     */

    steps,
    currentStep,
    currentSchema,
    currentStepName,
    currentStepTitle,
    currentStepIndex,
    currentStepSlug,
    isReviewMode,
    setIsReviewMode,

    /**
     * React Hook Form
     */

    formMethods,

    /**
     * Navigation
     */

    jumpToStep,
    nextStep,
    prevStep,

    /**
     * Helpers
     */

    isFirstStep,
    isLastStep,
    lastStepIndex,
  };
};

export type UseFormStepperControllerReturn<
  FormValues extends FieldValues = Record<string, unknown>,
  ButtonProps = Record<string, never>,
  FormSchema = FormStepSchema,
> = ReturnType<typeof useFormStepperController<FormValues, ButtonProps, FormSchema>>;
