import { DefaultError, UseQueryOptions, useQuery } from '@tanstack/react-query';

import { OfferingPhotosResponse, getOfferingPhotos } from '@arrived/api_v2';
import { OfferingCid } from '@arrived/models';

import { MAX_STALE_TIME } from '../utils';

import { offeringPhotosKeyFn } from './offerings.keys';

type UseGetOfferingPhotosQueryOptions<T, IncludeUrls extends boolean, IncludePositions extends boolean> = Omit<
  UseQueryOptions<OfferingPhotosResponse<IncludeUrls, IncludePositions>, DefaultError, T>,
  'queryKey' | 'queryFn'
> & {
  includeUrls?: IncludeUrls;
  includePositions?: IncludePositions;
};

export function useGetOfferingPhotosQuery<
  T,
  IncludeUrls extends boolean = false,
  IncludePositions extends boolean = false,
>(offeringCid?: OfferingCid, options?: UseGetOfferingPhotosQueryOptions<T, IncludeUrls, IncludePositions>) {
  const {
    includeUrls = false,
    includePositions = false,
    ...rest
  } = options ?? ({} as Partial<UseGetOfferingPhotosQueryOptions<T, IncludeUrls, IncludePositions>>);

  return useQuery({
    queryKey: offeringPhotosKeyFn(offeringCid!, {
      includeUrls,
      includePositions,
    }),
    queryFn: () => getOfferingPhotos(offeringCid!, { includeUrls, includePositions }),
    staleTime: MAX_STALE_TIME,
    ...rest,
    enabled: (rest?.enabled ?? true) && offeringCid != null,
  });
}
