import { Stack, styled } from '@arrived/bricks';

import { ProductHeaderTitleWrapperName } from './constants';
import { ProductHeaderStyledContext } from './ProductHeaderStyledContext';

export const ProductHeaderTitleFrame = styled(Stack, {
  name: ProductHeaderTitleWrapperName,
  context: ProductHeaderStyledContext,

  alignItems: 'center',
  flexDirection: 'row',
  gap: '$6',
});
