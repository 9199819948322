import { MediaQueryObject } from '@tamagui/core';
import { createMedia } from '@tamagui/react-native-media-driver';

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const Breakpoints = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'] as const;
export type BreakpointOptions = (typeof Breakpoints)[number];

export const baseMediaBreakpoints: Record<BreakpointOptions, MediaQueryObject> = {
  /**
   * Mobile sizes
   *
   * @min 0
   * @max 600
   */
  xxs: {
    minWidth: 0,
    maxWidth: 600,
  },
  /**
   * In-Between sizes for 601 - 800, representing the smallest tablet size
   *
   * @min 601
   * @max 800
   */
  xs: {
    minWidth: 601,
    maxWidth: 800,
  },
  /**
   * Tablet sizes
   *
   * @min 801
   * @max 1024
   */
  sm: {
    minWidth: 801,
    maxWidth: 1024,
  },
  /**
   * Low definition desktop sizes
   *
   * @min 1025
   * @max 1280
   */
  md: {
    minWidth: 1025,
    maxWidth: 1280,
  },
  /**
   * High definition desktop sizes
   *
   * @min 1281
   * @max 1440
   */
  lg: {
    minWidth: 1281,
    maxWidth: 1440,
  },
  /**
   * Ultra HD sizes
   *
   * @min 1441
   */
  xl: {
    minWidth: 1441,
  },
} as const;

const greaterThanMediaBreakpoints = (Object.keys(baseMediaBreakpoints) as BreakpointOptions[]).reduce(
  (obj, breakpoint) => {
    const maxWidth = baseMediaBreakpoints[breakpoint].maxWidth;
    if (maxWidth && typeof maxWidth === 'number') {
      obj[`gt${capitalize(breakpoint) as Capitalize<BreakpointOptions>}`] = {
        minWidth: maxWidth + 1,
      };
    }
    return obj;
  },
  {} as Record<`gt${Capitalize<BreakpointOptions>}`, MediaQueryObject>,
);

export const media = createMedia({
  ...baseMediaBreakpoints,
  ...greaterThanMediaBreakpoints,
  short: {
    maxHeight: 820,
  },
  tall: {
    minHeight: 820,
  },
  hoverNone: {
    hover: 'none',
  },
  pointerCoarse: {
    pointer: 'coarse',
  },
});
