import { MARKETING_CONVERSION_EVENTS } from '@arrived/models';

interface GoogleConversionArgs {
  type: MARKETING_CONVERSION_EVENTS;
  value?: number;
}

interface GoogleConversionTypes {
  [key: string]: {
    conversionId: string;
    conversionLabel: string;
  };
}

// Placeholder's for hard-coded Google Conversions
const CONVERSION_DATA: GoogleConversionTypes = {
  [MARKETING_CONVERSION_EVENTS.EMAIL_LEAD]: {
    conversionId: '',
    conversionLabel: '',
  },
  [MARKETING_CONVERSION_EVENTS.USER_CREATED]: {
    conversionId: '',
    conversionLabel: '',
  },
  [MARKETING_CONVERSION_EVENTS.BROKERAGE_ACCOUNT_CREATED]: {
    conversionId: '',
    conversionLabel: '',
  },
  [MARKETING_CONVERSION_EVENTS.HIGH_VALUE_USER]: {
    conversionId: '',
    conversionLabel: '',
  },
  [MARKETING_CONVERSION_EVENTS.LINKED_BANK]: {
    conversionId: '',
    conversionLabel: '',
  },
  [MARKETING_CONVERSION_EVENTS.TRADE_DOCS_SIGNED]: {
    conversionId: '',
    conversionLabel: '',
  },
};

// Google tracking SDK is loaded via Google Tag Manager. We check that it has loaded prior to firing the event.
export const trackGoogleConversion = (args: GoogleConversionArgs) => {
  const { type, value } = args;

  if (typeof window !== 'undefined') {
    const { conversionId, conversionLabel } = CONVERSION_DATA[type];

    window['gtag']('event', 'conversion', {
      send_to: `${conversionId}/${conversionLabel}`,
      value: value || 0,
      currency: 'USD',
    });
  }
};
