import { compareVersions } from 'compare-versions';
import UAParser from 'ua-parser-js';

import { ChromeLogo, EdgeLogo, FirefoxLogo, SafariLogo } from '@arrived/images';

const uaParser = new UAParser();

interface SupportedBrowserItem {
  name: string;
  minVersion: string;
  logoSvgComponent: JSX.Element;
  downloadLink?: string;
  subtext?: string;
  isVisible?: boolean;
}

const SUPPORTED_BROWSERS: Array<SupportedBrowserItem> = [
  {
    name: 'Chrome',
    minVersion: '84',
    downloadLink: 'https://www.google.com/chrome/downloads/',
    logoSvgComponent: <ChromeLogo />,
  },
  {
    name: 'Electron',
    minVersion: '20',
    downloadLink: 'https://www.google.com/chrome/downloads/',
    logoSvgComponent: <ChromeLogo />,
    isVisible: false,
  },
  {
    name: 'Edge',
    minVersion: '84',
    downloadLink: 'https://www.microsoft.com/en-us/edge',
    logoSvgComponent: <EdgeLogo />,
  },
  {
    name: 'Firefox',
    minVersion: '63',
    downloadLink: 'https://www.mozilla.org/en-US/firefox/new/',
    logoSvgComponent: <FirefoxLogo />,
  },
  {
    name: 'Safari',
    minVersion: '14.1',
    subtext: 'macOS 10.14+ or iOS 14+',
    logoSvgComponent: <SafariLogo />,
  },
];

export const UserAgentUtils = {
  supportedBrowsers: SUPPORTED_BROWSERS,
  isUsingSupportedBrowser: (): boolean => {
    const currentBrowserName = uaParser.getBrowser().name;
    const currentBrowserVersion = uaParser.getBrowser().version;

    if (!currentBrowserName || !currentBrowserVersion) {
      return true;
    }

    return UserAgentUtils.supportedBrowsers.some((browser) => {
      const nameMatch = currentBrowserName.indexOf(browser.name) > -1;
      let versionMatch = true;
      try {
        versionMatch = compareVersions(currentBrowserVersion, browser.minVersion) >= 0; // 0 means equality, minimum version should be accepted
      } catch (error) {
        console.error(error);
      }

      return nameMatch && versionMatch;
    });
  },
};
