import * as yup from 'yup';

import { Beneficiary } from '@arrived/models';

import { PhoneNumberSchema } from '../common';

export const BENEFICIARY_VALIDATION_KEYS = {
  REQUIRED: 'REQUIRED',
  IS_POSSIBLE_PHONE_NUMBER: 'account.phoneNumber.invalid',
  ALLOCATION_IS_100_PERCENT: 'ALLOCATION_IS_100_PERCENT',
} as const;

export const getBeneficiaryAllocationTotal = (beneficiaries: Partial<Beneficiary>[]) =>
  beneficiaries.reduce((total, value) => {
    if (value.percentage) {
      total += parseFloat(value.percentage.toString());
    }

    return total;
  }, 0);

export const BeneficiarySchema = yup.object({
  uuid: yup.string().optional(),
  accountId: yup.number().optional(),
  createdAt: yup.string().optional(),
  firstName: yup.string().trim().max(128).required(BENEFICIARY_VALIDATION_KEYS.REQUIRED),
  lastName: yup.string().trim().max(128).required(BENEFICIARY_VALIDATION_KEYS.REQUIRED),
  relationship: yup.string().trim().max(64).required(BENEFICIARY_VALIDATION_KEYS.REQUIRED),
  percentage: yup
    .number()
    .transform((_, originalValue) => {
      // Attempt to parse the originalValue as a number
      const parsed = parseFloat(originalValue);
      // Return the parsed number if it's a valid number, otherwise return NaN
      return isNaN(parsed) ? 0 : parsed;
    })
    .integer()
    .min(1)
    .max(100)
    .required(BENEFICIARY_VALIDATION_KEYS.REQUIRED),
  phoneNumber: PhoneNumberSchema.max(32).optional().nullable(),
});

export type BeneficiarySchema = yup.InferType<typeof BeneficiarySchema>;

export const BeneficiaryRequestSchema = yup.object({
  beneficiaries: yup
    .array(BeneficiarySchema)
    .default([])
    // Filter out any beneficiaries that don't have a percentage
    .transform((_, originalValue) => originalValue.filter((b: BeneficiarySchema) => 'percentage' in b))
    .test(
      BENEFICIARY_VALIDATION_KEYS.ALLOCATION_IS_100_PERCENT,
      BENEFICIARY_VALIDATION_KEYS.ALLOCATION_IS_100_PERCENT,
      (beneficiaries) => {
        const total = getBeneficiaryAllocationTotal(beneficiaries);

        return total === 100;
      },
    ),
});

export type BeneficiaryRequestSchema = yup.InferType<typeof BeneficiaryRequestSchema>;
