import { AccountType } from './accountType';
import { AccreditedStatus } from './accreditedStatus';
import { Address } from './addresses';
import { EntityType } from './entityType';
import { ExternalAccount } from './externalAccount';
import { RegDStatus } from './regDStatus';
import { SuitabilityQuestionnaire } from './suitabilityQuestionnaire';
import { User } from './users';

/**
 * @deprecated In favor of using `AccountCid` instead. Reference a BE developer for more information.
 */
export type AccountId = number;
export type AccountCid = string;
export type AccountBlockAuditCid = string;

export enum ACCOUNT_ERROR {
  NOT_FOUND = 'NotFoundException',
}

export interface Account {
  id: AccountId;
  cid: AccountCid;
  ncId?: string;
  ncAccountOwnerLinkId?: number;
  primaryUser: User;
  name: string;
  cashAccountCid: null | string;
  cashCounterpartyCid: null | string;
  type: AccountType;
  entityType?: EntityType;
  entityName?: string;
  entityTitle?: string;
  address: Address;
  businessAddress?: Address | null;
  externalAccount?: ExternalAccount;
  suitabilityQuestionnaire: SuitabilityQuestionnaire | null;
  phoneNumber: string;
  accreditedStatus: AccreditedStatus;
  createdAtIp: string;
  createdAt: Date;
  updatedAt?: Date;
  active: boolean;
  hasEin?: boolean;
  regDStatus?: RegDStatus;
  regDCheckExempt?: boolean;
  rewardsAccountUuid: string | null;

  // Admin-controlled, account level locks
  holdDividends: boolean;
  isTradeCreationBlocked: boolean;
  isExternalAccountUpdatingBlocked: boolean;
  isWithdrawingBlocked: boolean;
  isDepositingBlocked: boolean;
  isRedemptionRequestingBlocked: boolean;
}

/**
 * The shape of an account request made to the API.
 */
export interface AccountCreateRequest {
  primaryUserId: number;
  type: AccountType;
  address: Address;
  businessAddress?: Address | null;
  accreditedStatus: AccreditedStatus;
  entityType: EntityType | null;
  entityName: string | null;
  entityTitle: string | null;
  suitabilityQuestionnaire: {
    accreditedInvestor: boolean;
    netWorthOver1m: boolean;
    individualIncomeOver200k: boolean;
    jointIncomeOver300k: boolean;
    organizationAssetsOver5m?: boolean;
    investmentNotOver10percentNetWorthOrIncome?: boolean;
    associatedWithFinra: boolean;
    finraInstitutionName?: string;
    associatedWithPublicCompany: boolean;
    publicCompanyTickers?: string;
  };
  ssn: string;
  ein: string | null;
  phoneNumber: string;
}

export interface AccountBlocks {
  holdDividends: boolean;
  isTradeCreationBlocked: boolean;
  isExternalAccountUpdatingBlocked: boolean;
  isWithdrawingBlocked: boolean;
  isDepositingBlocked: boolean;
  isRedemptionRequestingBlocked: boolean;
  internalNotes: string;
}

export interface AccountBlocksRequest {
  accountCid: AccountCid;
  accountBlocks: AccountBlocks;
}

export interface AccountBlocksAuditBlocks {
  holdDividends: boolean;
  blockTrades: boolean;
  blockExternalAccountUpdates: boolean;
  blockWithdrawals: boolean;
  blockDeposits: boolean;
  blockRedemptionRequests: boolean;
  internalNotes: string;
}

export interface AccountBlockAudit {
  cid: AccountBlockAuditCid;
  accountAcid: AccountCid;
  blockSource: string;
  suspiciousBehavior: string | null;
  internalNotes: string | null;
  blocks: AccountBlocksAuditBlocks;
  createdAt: Date;
}
