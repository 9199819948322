import { useCallback, useState } from 'react';
import {
  RefreshControl as OriginalRefreshControl,
  RefreshControlProps as OriginalRefreshControlProps,
} from 'react-native';

import { isWeb, useTheme } from '@arrived/bricks';
import { notificationService } from '@arrived/features-toasty';

type RefreshControlProps = Omit<OriginalRefreshControlProps, 'refreshing' | 'onRefresh'> & {
  onRefresh: () => Promise<unknown>;
};

/**
 * An internal controlled version of the RefreshControl component, this
 * just handles displaying the loading state and calls the onRefresh
 * function when the user pulls to refresh. Allowing for a consistent
 * experience when using the RefreshControl that doesn't require the
 * consumer to manage the refreshing state.
 *
 * We are doing this to make the experience a bit smoother, as when you're not managing the state,
 * the control tends to be a bit unhelpful by snapping either
 * too quickly or not at all.
 */
export const RefreshControl = ({ onRefresh, ...props }: RefreshControlProps) => {
  const [refreshing, setRefreshing] = useState(false);

  const theme = useTheme();

  const handleRefresh = useCallback(async () => {
    let timeout: ReturnType<typeof setTimeout>;

    setRefreshing(true);

    onRefresh()
      .catch((error) => {
        notificationService.error(error.message);
        setRefreshing(false);
      })
      .finally(() => {
        timeout = setTimeout(() => {
          setRefreshing(false);
        }, 300);
      });

    return () => {
      clearTimeout(timeout);
    };
  }, [onRefresh]);

  return (
    <OriginalRefreshControl
      tintColor={theme['interactive.primary.focus'].val}
      colors={[theme['interactive.primary.focus'].val]}
      {...props}
      refreshing={refreshing}
      onRefresh={handleRefresh}
      // Needed to prevent the refresh control from being hidden by the sticky header
      // see the ProductDetails feature package for an example of this. It can
      // also be seen on the native app IDP page, pulling to refresh
      // without the `10001` zIndex will cause the PtR to be hidden behind
      // the carousel.
      // On web, it is not necessary to even have this component render. But it
      // will wrap the scroll view in an extra `div`, which takes the `zIndex` from native.
      // Resetting it to `0` fixes the issue.
      style={{ zIndex: isWeb ? 0 : 10001 }}
    />
  );
};
