import { AccountCid } from './account';

export interface RegulationDAccessSummary {
  accountCid: AccountCid;

  /**
   * The date when a user first completed a trade.
   * This helps us determine if a user is qualified to
   * see any Reg D offerings.
   *
   * If this is null, then the user has not completed a trade
   */
  firstSettledTradeCreatedAt: string | null;

  /**
   * The date that the active suitability questionnaire for
   * the current account was created
   *
   * If this is null, then the user has not completed the questionnaire
   */
  suitabilityQuestionnaireCreatedAt: string | null;

  /**
   * Determines if a user is qualified to create a trade on a Reg D Offering. In the case in which
   * a user has not updated their accredited status within the last year, but are still considered
   * an accredited investor, the investor will be allowed to view, but not trade, on Reg D
   * Offerings.
   */
  canTradeRegDOffering: boolean;

  /**
   * Determines if a user is qualified to view a Reg D Offering. In the case in which a user has
   * not updated their accredited status within the last year, but are still considered an
   * accredited investor, the investor will be allowed to view, but not trade, on Reg D Offerings.
   */
  canViewRegDOffering: boolean;

  /**
   * If a user is accredited or not.
   * This can be updated in the users account settings.
   *
   * null = user needs to create their accreditation status
   * true = user is accredited
   * false = user is not accredited
   */
  isSelfAccredited: boolean | null;

  needAccreditationStatusUpdate: boolean;
}

export const RegDStatus = {
  /**
   * User is not accredited || user is accredited, but hasn't expressed interest in RegD properties
   */
  UNDEFINED: 'UNDEFINED',
  /**
   * User is accredited && has expressed interest in RegD properties
   */
  PENDING: 'PENDING',
  /**
   * User is NOT qualified for Reg D offerings after reviewing the user's account
   */
  REJECTED: 'REJECTED',
  /**
   * User IS qualified for Reg D offerings after reviewing the user's account && user want access
   */
  QUALIFIED: 'QUALIFIED',
  /**
   * User IS qualified for Reg D offerings after reviewing the user's account; however, user doesn't want access
   */
  NOT_INTERESTED: 'NOT_INTERESTED',
  /**
   * User's account is being reviewed & more action needs to be done to make a qualification decision
   */
  FOLLOW_UP: 'FOLLOW_UP',
} as const;

export type RegDStatus = (typeof RegDStatus)[keyof typeof RegDStatus];
