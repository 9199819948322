import { getTokens } from '@arrived/bricks';

type StripTokenPrefix<T> = T extends `$${infer stem}` ? `$${stem}` : never;

type GetSpacingTokensReturnType = `${Exclude<keyof ReturnType<typeof getTokens>['space'], 'true' | '$true'>}`;

/**
 * All spacing options supported with prefix
 */
type SpacingWithOnlyPrefix = StripTokenPrefix<GetSpacingTokensReturnType>;

const getSpacingOptions = () => {
  const tokens = getTokens().space;

  const options = Object.entries(tokens)
    .filter(([key]) => {
      return !(key === 'true' || key === '$true') && key.charAt(0) === '$';
    }) // Exclude unwanted keys directly
    .map(([key, token]) => ({
      value: key as SpacingWithOnlyPrefix | undefined,
      label: `${key as 'None' | SpacingWithOnlyPrefix} (${token.val}px)` as const,
    }));

  // Add the "None" option
  options.unshift({
    value: undefined,
    label: 'None (0px)',
  } as const);

  return options;
};

/**
 * All possible spacing options formatted as
 * ```typescript
 * [
 *  { value: undefined, label: 'None (0px)' },
 *  { value: '$4', label: '$4 (16px)' },
 * ]
 * ```
 */
const spacingOptions = getSpacingOptions();

/**
 * All generic spacing inputs when creating a component. If you need
 * to add very specific items to a component, for example the `<Stack />` component,
 * then you would use these. Otherwise you can just grab what you need
 */
const SpacingInputs = (
  [
    {
      name: 'gap',
      friendlyName: 'Gap',
      defaultValue: '$4',
      helperText: 'The space between each child',
    },
    {
      name: 'padding',
      friendlyName: 'Padding',
      helperText: 'The space between the content and the border of an element',
    },
    {
      name: 'margin',
      friendlyName: 'Margin',
      helperText: 'The space outside the border that separates an element from others',
    },
    {
      name: 'marginLeft',
      advanced: true,
      friendlyName: 'Margin Left',
      helperText: 'The space on the left side of the element',
    },
    {
      name: 'marginRight',
      advanced: true,
      friendlyName: 'Margin Right',
      helperText: 'The space on the right side of the element',
    },
    {
      name: 'marginBottom',
      advanced: true,
      friendlyName: 'Margin Bottom',
      helperText: 'The space on the bottom of the element',
    },
    {
      name: 'marginTop',
      advanced: true,
      friendlyName: 'Margin Top',
      helperText: 'The space on the top of the element',
    },
    {
      name: 'paddingLeft',
      advanced: true,
      friendlyName: 'Padding Left',
      helperText: 'The padding on the left side of the element',
    },
    {
      name: 'paddingRight',
      advanced: true,
      friendlyName: 'Padding Right',
      helperText: 'The padding on the right side of the element',
    },
    {
      name: 'paddingBottom',
      advanced: true,
      friendlyName: 'Padding Bottom',
      helperText: 'The padding on the bottom of the element',
    },
    {
      name: 'paddingTop',
      advanced: true,
      friendlyName: 'Padding Top',
      helperText: 'The padding on the top of the element',
    },
    {
      name: 'alignItems',
      friendlyName: 'Align Items',
      type: 'enum',
      advanced: true,
      enum: ['center', 'flex-start', 'flex-end', 'baseline', 'stretch'] as string[],
    },
    {
      name: 'justifyContent',
      friendlyName: 'Justify Content',
      type: 'enum',
      advanced: true,
      enum: ['center', 'space-between', 'space-around', 'space-evenly', 'flex-start'] as string[],
    },
  ] as const
).map((input) => ({
  ...input,
  type: 'type' in input ? input.type : ('enum' as const),
  enum: 'enum' in input ? input.enum : spacingOptions,
}));

type SpacingInputs = typeof SpacingInputs;

/**
 * An individual spacing input
 */
type SpacingInput = SpacingInputs[number];

/**
 * The type of spacing inputs that can be used, undefined means all spacing inputs
 */
type SpacingTypes = SpacingInput['name'] | undefined;

/**
 * An object of spacing inputs, where the key is the name of the input
 * and the value is the input itself
 *
 * @example
 * ```typescript
 * // When using this type, you can get the input by the key
 * ObjectSpacingInputs<'gap'> = {
 *   gap: {
 *    type: 'enum',
 *    enum: Spacing,
 *    name: 'gap',
 *    friendlyName: 'Gap',
 *    defaultValue: '$4',
 *    helperText: 'The space between each child',
 *   }
 * }
 * ```
 */
type ObjectSpacingInputs<T> = T extends SpacingInput['name']
  ? {
      [K in T]: Extract<SpacingInput, { name: K }>;
    }
  : never;

// This is a utility type that gets the value of a type
type ValueOf<T> = T[keyof T];

/**
 * Get the spacing inputs based on the fields provided, if no fields are provided
 * then all spacing inputs are returned.
 *
 * This is a bit complex because we need to make sure that the type is correct when using this function
 */
type GetSpacingInputs<F extends SpacingTypes> = F extends SpacingInput['name']
  ? ValueOf<ObjectSpacingInputs<F>>[]
  : F extends undefined
    ? SpacingInputs
    : never;

/**
 * Get an array of spacing inputs based on the fields provided, if
 * no fields are provided, then all spacing inputs are returned.
 *
 * This is typed to return the correct spacing inputs based on the fields provided.
 *
 * @example
 * ```typescript
 * // Get all spacing inputs
 * const allSpacingInputs = getSpacingInputs();
 * console.log(allSpacingInputs); // { name: 'gap', friendlyName: 'Gap', ... }[] (all spacing inputs, justifyContent, alignItems, etc.)
 *
 *
 * // Get only the gap input
 * const gapInput = getSpacingInputs(['gap']);
 * console.log(gapInput); // { name: 'gap', friendlyName: 'Gap', ... }[]
 *```
 * @param fields The fields to return
 * @returns The spacing inputs based on the fields provided
 */
export const getSpacingInputs = <F extends SpacingTypes = undefined>(fields?: F[] | undefined) => {
  if (!fields) {
    // To help with type discrimination when using this function, we need to cast to the correct type like above
    return SpacingInputs as GetSpacingInputs<F>;
  }

  return SpacingInputs.filter((option): option is SpacingInput =>
    fields.includes(option.name as F),
  ) as GetSpacingInputs<F>;
};
