import { stringify } from 'qs';

import { Rewards } from '@arrived/models';

import { rewardsApi } from '../api';
import { createQuery } from '../create-query';

export const getRewardsAccount = (accountId: string) =>
  createQuery<Rewards.Account>({
    apiInstance: rewardsApi,
    method: 'get',
    url: `/accounts/${accountId}`,
  });

export const getRewardsAccounts = (filters?: Rewards.AccountFilters) =>
  createQuery<Rewards.AccountSearchResult>({
    apiInstance: rewardsApi,
    method: 'get',
    url: `/accounts`,
    config: {
      params: filters,
      paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
    },
  });

export const postRewardsAccount = (requestData: Rewards.AccountCreationRequest) =>
  createQuery<Rewards.Account>({
    apiInstance: rewardsApi,
    method: 'post',
    url: `/accounts`,
    requestData,
  });
