import { Tokenizer } from '../../../utils/tokenizer';
import { RootTextProps } from '../RootText';

type BodyDefaultVariantsBase = 'large' | 'medium' | 'small';
type BodyCompactVariantsBase = 'large' | 'medium' | 'small';
type BodyDefaultVariants = Tokenizer<'body.default', BodyDefaultVariantsBase>;
type BodyCompactVariants = Tokenizer<'body.compact', BodyCompactVariantsBase>;

export type BodyVariants = BodyDefaultVariants | BodyCompactVariants;

export const BODY_VARIANTS: Record<BodyVariants, RootTextProps> = {
  'body.default.large': {
    fontSize: '$18',
    lineHeight: '$24',
    fontWeight: '$1',
  },
  'body.default.medium': {
    fontSize: '$16',
    lineHeight: '$22',
    fontWeight: '$1',
  },
  'body.default.small': {
    fontSize: '$14',
    lineHeight: '$20',
    fontWeight: '$1',
  },
  'body.compact.large': {
    fontSize: '$18',
    lineHeight: '$22',
    fontWeight: '$1',
  },
  'body.compact.medium': {
    fontSize: '$16',
    lineHeight: '$20',
    fontWeight: '$1',
  },
  'body.compact.small': {
    fontSize: '$14',
    lineHeight: '$18',
    fontWeight: '$1',
  },
};
