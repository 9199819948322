// 12/08/2022 NextJS/public-app complains when this is bundled
// Requires boolean to be aliased for some reason
import * as yup from 'yup';

import { AddressSchema } from '../common/address.schema';
import { PhoneNumberSchema } from '../common/phoneNumber.schema';

/**
 * @reference User from models/users.ts
 */
export const UserSchema = yup.object({
  id: yup.number().required(),
  email: yup.string().email().required(),
  auth0UserId: yup.string().required(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  domicile: yup.mixed().nullable(), // Adjust this based on Domicile type
  profilePictureUrl: yup.string().url().nullable(),
  admin: yup.bool(),
  phoneNumber: PhoneNumberSchema.nullable(),
  birthDate: yup.string().nullable(),
  primaryResidenceAddress: AddressSchema,
  mailingAddress: AddressSchema,
  plannedInvestmentAmount: yup.mixed().nullable(), // Adjust based on PlannedInvestmentAmount type
  createdAt: yup.string().required(),
  updatedAt: yup.string().nullable(),
  accreditedInvestor: yup.boolean().required(),
  tosAgreed: yup.boolean().required(),
  privacyAgreed: yup.boolean().required(),
  active: yup.boolean().required(),
});

export type UserSchema = yup.InferType<typeof UserSchema>;
