export const openPdf = (blob: Blob) => {
  const url = window.URL.createObjectURL(blob.slice(0, blob.size, 'application/pdf'));
  window.open(url, '_blank');
  // We intentionally are not revoking the object URL here because doing so prevents users from being able to download
  // the resource from Chrome.
};

export const openImage = (blob: Blob, contentType = 'application/octet-stream') => {
  const url = window.URL.createObjectURL(blob.slice(0, blob.size, contentType));
  window.open(url, '_blank');
  // We intentionally are not revoking the object URL here because doing so prevents users from being able to download
  // the resource from Chrome.
};
