export const InAppArticleType = {
  INTERCOM: 'INTERCOM',
  BLOG: 'BLOG',
} as const;

export type InAppArticle = {
  article: string;
  type: (typeof InAppArticleType)[keyof typeof InAppArticleType];
  url: URL;
};

/**
 * Returns an object indicating if a given link can be opened as route within the mobile app. Note that this does not guarantee the existence
 * of the resource, that must be undertaken by the consumer of this function.
 */
export function getInAppLink(url: string): InAppArticle | undefined {
  const urlDetails = new URL(url);

  if (url.includes('arrived.com') || url.includes('arrivedhomes.com')) {
    if (url.includes('help.arrived')) {
      const articleId = /\/articles\/(\d+)/.exec(url)?.[1] ?? '';

      return {
        article: articleId,
        type: InAppArticleType.INTERCOM,
        url: urlDetails,
      };
    } else {
      return {
        article: url.split('/').pop() ?? '',
        type: InAppArticleType.BLOG,
        url: urlDetails,
      };
    }
  }
}
