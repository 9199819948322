import { useQuery } from '@tanstack/react-query';

import { postPlaidCreateTokenLink } from '@arrived/api_v2';
import { PlaidCreateTokenLinkRequest } from '@arrived/models';

import { useGetCurrentUserQuery } from '../../users';
import { UseAwaitedQueryOptions } from '../../utils';
import { plaidTokenLinkKeyFn } from '../accounts.keys';

export function usePlaidTokenLinkQuery(
  request?: Omit<PlaidCreateTokenLinkRequest, 'userId'>,
  options?: UseAwaitedQueryOptions<typeof postPlaidCreateTokenLink>,
) {
  const currentUser = useGetCurrentUserQuery();

  const userId = currentUser.data?.id!;

  return useQuery({
    queryKey: plaidTokenLinkKeyFn({ userId, ...request }),
    queryFn: () => postPlaidCreateTokenLink({ userId, ...request }),
    staleTime: 0,
    ...options,
    enabled: (options?.enabled ?? true) && userId != null,
  });
}
