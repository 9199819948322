import { Children, ReactNode } from 'react';

import { isTamaguiElement } from '@tamagui/core';

/**
 * Consumes an array of child components, and "slices" out the named items from the rest.
 *
 * @param children raw list of child elements to parse
 * @param componentNames list of Bricks component names to search for in the root-level children
 * @returns an organized version of the child components, with the provided named components as separate key/value pairs from the catch-all "children" key.
 */
export const parseRootChildren = (
  children: ReactNode[] | undefined,
  componentNames: string[],
): { children: ReactNode[] } & Record<string, ReactNode | ReactNode[]> => {
  const namedChildren: Record<string, ReactNode> = {};
  const unnamedChildren: ReactNode[] = [];

  Children.forEach(children, (child, _) => {
    const namedComponent = componentNames.find((name) => isTamaguiElement(child, name));

    if (namedComponent) {
      namedChildren[namedComponent] = child;
    } else if (child != null) {
      unnamedChildren.push(child);
    }
  });

  return {
    ...namedChildren,
    children: unnamedChildren,
  };
};
