import { useQuery } from '@tanstack/react-query';

import { getAccountBeneficiaries } from '@arrived/api_v2';

import { useGetPrimaryAccountQuery } from '../../users';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../../utils';
import { beneficiariesKeyFn } from '../accounts.keys';

export function useGetAccountsBeneficiaries(options?: UseAwaitedQueryOptions<typeof getAccountBeneficiaries>) {
  const accountState = useGetPrimaryAccountQuery();
  const { id: accountId } = accountState.data || {};

  return useQuery({
    queryKey: beneficiariesKeyFn(accountId!),
    queryFn: () => getAccountBeneficiaries(accountId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}
