import { MouseEventHandler, useCallback, useMemo, useState } from 'react';

import clsx from 'clsx';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ListItemButton } from '@mui/material';

import { useMedia } from '@arrived/bricks';

import { NavItemMultiple } from '../../navigation.types';
import { NavText } from '../NavText';

import { DesktopNavInner } from './DesktopNavInner';
import { MobileNavInner } from './MobileNavInner';

export const MultiLevelNavItem = ({ subItems, text, isActive, buttonProps, onClick }: NavItemMultiple) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const media = useMedia();

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (media.gtXs) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      }
    },
    [media.gtXs, anchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSubItemClick = useCallback(() => {
    onClick?.();
    handleClose();
  }, [handleClose, onClick]);

  return (
    <>
      <ListItemButton
        className={clsx('nav-item', { 'selected-nav': isActive, open })}
        onClick={handleClick}
        selected={isActive}
        aria-expanded={isActive}
        {...buttonProps}
      >
        <NavText>{text}</NavText>
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </ListItemButton>

      {!media.gtXs ? (
        <MobileNavInner open={open} subItems={subItems} onSubItemClick={handleSubItemClick} />
      ) : (
        <DesktopNavInner
          open={open}
          subItems={subItems}
          onSubItemClick={handleSubItemClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
