import { useQuery } from '@tanstack/react-query';

import { getRegDAccessSummary } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';
import { UseAwaitedQueryOptions, regDStatusKeyFn, useGetAccountQuery } from '@arrived/queries';

/**
 * Hook for encapsulating whether a given Account can access reg-d offerings.
 */
export function useAdminRegulationAccessSummaryQuery(
  accountId?: AccountId,
  options?: UseAwaitedQueryOptions<typeof getRegDAccessSummary>,
) {
  const accountState = useGetAccountQuery(accountId);

  return useQuery({
    // TODO: We should really cache this under the account's CID, however, we need to invalidate
    // the cache of Reg D access information when we post a new suitability questionnaire which we
    // key with an account ID (not a CID), so we need to store it under the account ID.
    //
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: regDStatusKeyFn(accountId!),
    queryFn: () => getRegDAccessSummary(accountState.data?.cid!),
    ...options,
    enabled: (options?.enabled ?? true) && accountState.data?.cid != null && accountId != null,
  });
}
