import type { LocalAuthenticationStrategy, User } from 'react-native-auth0';

/**
 * **Used for Android only:** The level of local authentication required to access
 * the credentials. Defaults to LocalAuthenticationLevel.strong.
 */
export enum LocalAuthenticationLevel {
  /**
   * Any biometric (e.g. fingerprint, iris, or face) on the device that
   * meets or exceeds the requirements for Class 3 (formerly Strong), as defined by the Android CDD.
   */
  strong = 0,

  /**
   * Any biometric (e.g. fingerprint, iris, or face) on the device that
   * meets or exceeds the requirements for Class 2 (formerly Weak), as
   * defined by the Android CDD.
   */
  weak,

  /**
   * The non-biometric credential used to secure the device
   * (i. e. PIN, pattern, or password).
   */
  deviceCredential,
}

export type ArrivedAuth0User = User & {
  /**
   * Specifies whether this is the users first time logging in
   * in the history of Arrived.
   */
  isFirstLogin: boolean;

  /**
   * Specifies whether we have used MFA in the history of the users
   * current session
   */
  usedMfa: boolean;
};

export type ArrivedAuth0State<TUser extends ArrivedAuth0User = ArrivedAuth0User> = {
  /**
   * An object representing the last exception
   */
  error: ArrivedAuth0Error | null;

  /**
   * The user profile as decoded from the ID token after authentication
   */
  user: TUser | null;

  /**
   * A flag that is true until the state knows that a user is either logged in or not
   */
  isLoading: boolean;

  /**
   * The refresh token that is used to refresh the user's access token
   */
  refreshToken?: string;
};

export type ArrivedAuth0Error = Error & {
  name: string;
  message: string;
  json: {
    error: string;
    error_description: string;
    mfa_token?: string;
  };
  status: number;
};

export type CustomAuthorizationParameters = {
  /**
   * Used to determine the type of login flow to use. If you pass
   * `none` it will attempt to do a Silent Login, which will fail if the users
   * access token is already expired.
   *
   * @note If you pass `signup`, we will redirect to the onboarding flow by
   * changing the parameter to `screen_hint`. Setting it to `null` will
   * remove the prompt parameter.
   */
  prompt: 'login' | 'none' | 'consent' | 'select_account' | 'signup' | null;

  /**
   * This can either by the `CONFIG` value, or `step-up` if you want to use
   * the step up login flow.
   *
   * @example 'openid profile email offline_access' | 'step-up'
   *
   * @default CONFIG.auth0.scope
   */
  scope?: string;
};

/**
 * The options for configuring the display of local authentication prompt,
 * authentication level (Android only) and evaluation policy (iOS only).
 */
export type LocalAuthenticationOptions = {
  /**
   * The title of the authentication prompt.
   * **Applicable for both Android and iOS**.
   */
  title: String;

  /**
   * The subtitle of the authentication prompt.
   * **Applicable for Android only.**
   */
  subtitle?: String;

  /**
   * The description of the authentication prompt.
   * **Applicable for Android only.**
   */
  description?: String;

  /**
   * The cancel button title of the authentication prompt.
   * **Applicable for both Android and iOS.**
   */
  cancelTitle?: String;

  /**
   * The evaluation policy to use when prompting the user for authentication.
   * Defaults to LocalAuthenticationStrategy.deviceOwnerWithBiometrics.
   * **Applicable for iOS only.**
   */
  evaluationPolicy?: LocalAuthenticationStrategy;

  /**
   * The fallback button title of the authentication prompt.
   * **Applicable for iOS only.**
   */
  fallbackTitle?: String;

  /**
   * The authentication level to use when prompting the user for authentication.
   * **Applicable for Android only.**
   *
   * @default LocalAuthenticationLevel.strong
   */
  authenticationLevel?: LocalAuthenticationLevel;

  /**
   * Should the user be given the option to authenticate with their device
   * PIN, pattern, or password instead of a biometric.
   * **Applicable for Android only.**
   */
  deviceCredentialFallback?: Boolean;
};
