import { stringify } from 'qs';

import {
  Account,
  AccountBalance,
  AccountCid,
  AccountCreateRequest,
  AccountId,
  OfferingId,
  OfferingMonthlyBalance,
  OfferingTransaction,
  PatchAccountRequest,
  TotalAvailableSharesResponse,
  TradesQueryFilters,
  UserId,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getUserAccounts = (userId: UserId) =>
  createQuery<Account[]>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/accounts`,
  });

export const getUserAccount = (accountId: AccountId | AccountCid) =>
  createQuery<Account>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}`,
  });

export const postUserAccount = (requestData: AccountCreateRequest) =>
  createQuery<Account>({
    apiInstance: api,
    method: 'post',
    url: '/users/accounts',
    requestData,
  });

export const patchUserAccount = (requestData: PatchAccountRequest) =>
  createQuery<Account>({
    apiInstance: api,
    method: 'patch',
    url: `/users/accounts/${requestData.id}`,
    requestData,
  });

export const deleteUserAccount = (accountId: AccountId) =>
  createQuery<Account>({
    apiInstance: api,
    method: 'delete',
    url: `/users/accounts/${accountId}`,
  });

export const getAccountTaxId = (accountId: AccountId) =>
  createQuery<string>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/tax-id`,
  });

export const getUserAccountBalance = (accountId: AccountId) =>
  createQuery<AccountBalance>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/balance`,
  });

export const getUserAccountMonthlyBalance = (accountId: AccountId) =>
  createQuery<OfferingMonthlyBalance[]>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/balance/monthly`,
  });

export const getSharesAvailableForAccount = (accountId: AccountId, offeringId: OfferingId) =>
  createQuery<TotalAvailableSharesResponse>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/offerings/${offeringId}/shares/available`,
  });

export const getAccountTrades = (accountId: AccountId, filters?: TradesQueryFilters) =>
  createQuery<OfferingTransaction[]>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/trades`,
    config: {
      params: {
        ...filters,
      },
      paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
    },
  });
