import { differenceInYears, format, getMonth, getYear, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { OfferingTransaction } from '@arrived/models';

const monthMapEntries: [number, string][] | null = [
  [1, 'January'],
  [2, 'February'],
  [3, 'March'],
  [4, 'April'],
  [5, 'May'],
  [6, 'June'],
  [7, 'July'],
  [8, 'August'],
  [9, 'September'],
  [10, 'October'],
  [11, 'November'],
  [12, 'December'],
];
export const shortMonthMap = new Map<number, string>(
  monthMapEntries.map(([key, value]) => [key, value.substring(0, 3)]),
);

export const formatUserDateFromString = (date: string, structure = 'PP') => {
  return format(parseISO(date), structure);
};

export const getDateFromBreakdown = (year: string, month: string, day: string) => {
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const getBreakdownFromDate = (date: Date) => {
  try {
    return { day: format(date, 'dd'), year: format(date, 'yyyy'), month: format(date, 'MM') };
  } catch (e) {
    return undefined;
  }
};

/**
 * Returns a common truncated datestring format - 'yyyy-MM-dd'.
 */
export const getTruncatedDateFromString = (dateString: string) => formatUserDateFromString(dateString, 'yyyy-MM-dd');
/**
 * Returns the common/standard "MMM dd, yyyy" format.
 */
export const getStandardFormattedDateFromString = (dateString: string, options?: { includeTime?: boolean }) => {
  return formatUserDateFromString(dateString, 'MMM dd, yyyy' + (options?.includeTime ? ' h:mma zzz' : ''));
};

/**
 * Checks whether a given date is over 18 years ago.
 */
export const isOverEighteen = (birthDate: Date) => {
  const diff = differenceInYears(new Date(), birthDate);
  return diff >= 18;
};

export const setTo12UTC = (dateString: string) => {
  const date = parseISO(dateString);
  date.setUTCHours(12, 0, 0, 0);
  return date.toISOString();
};
export const getFormattedMonthYear = (date: Date) => {
  return `${shortMonthMap.get(getMonth(date) + 1)} ${getYear(date)}`;
};

export const getClientTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * Function to determine the date 2 months from the current date in UTC+12 timezone
 */
export const getEstimatedFirstDividendDateOnClientTimeZone = (offeringTransaction: OfferingTransaction) => {
  if (offeringTransaction.signedAt) {
    // Get the client's local timezone
    const clientTimeZone = getClientTimeZone();

    // Parse the signedAt date
    const signedDate = new Date(offeringTransaction.signedAt);

    // Add 2 months and set the date to the 25th
    const futureDate = new Date(signedDate);
    futureDate.setMonth(futureDate.getMonth() + 2, 25);

    // Convert the future date to the client's local timezone
    const futureDateInLocalTimeZone = utcToZonedTime(futureDate, clientTimeZone);

    // Format the date as "Month Day, Year"
    const formattedDate = format(futureDateInLocalTimeZone, 'MMMM dd, yyyy');

    return formattedDate;
  } else {
    return null;
  }
};
