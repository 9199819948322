import { useQuery } from '@tanstack/react-query';

import { getFixedRateInvestment } from '@arrived/api_v2';

import { UseAwaitedQueryOptions } from '../utils';

import { fixedRateInvestmentKey } from './fixedRateInvestments.keys';

export function useGetFixedRateInvestmentQuery(
  cid?: string,
  options?: UseAwaitedQueryOptions<typeof getFixedRateInvestment>,
) {
  return useQuery({
    queryKey: fixedRateInvestmentKey(cid!),
    queryFn: () => getFixedRateInvestment(cid!),
    ...options,
    enabled: (options?.enabled ?? true) && !!cid,
  });
}
