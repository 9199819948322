import { cashAccountApi, createQuery } from '@arrived/api_v2';

import { Counterparty, CounterpartyType } from '../types/cash-accounts';

interface CounterPartiesParams {
  entityId: string;
  counterpartyType: CounterpartyType;
  includeCounterpartyDetails?: boolean;
  includeUserAuthorizationLevel?: boolean;
  includeExternalBankAccounts?: boolean;
}

export const getCounterParties = (options?: CounterPartiesParams) =>
  createQuery<Counterparty[]>({
    apiInstance: cashAccountApi,
    method: 'get',
    url: `/counterparties`,
    config: { params: options },
  });
