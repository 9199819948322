import { PixelRatio } from 'react-native';

import { getImageKitSource } from '@arrived/bricks-common';
import { Constants } from '@arrived/common';

type BuilderImageParams = {
  /**
   * Compression quality ranging from 1 (worst quality, smallest file size) to
   * 100 (best quality, largest file size). If no value is provided,
   * 85 is chosen by default.
   *
   * @default 85
   */
  quality?: number;

  /**
   * Width in pixels. If no value is provided, defaults to the image's intrinsic width.
   * **Note** that the Image API doesn't upscale. Values greater than the image's intrinsic
   * width are equivalent to the intrinsic width.
   */
  width?: number;

  /**
   * Height in pixels. If no value is provided, defaults to the image's intrinsic height.
   * **Note** that the Image API doesn't upscale. Values greater than the image's intrinsic
   * height are equivalent to the intrinsic height.
   */
  height?: number;

  /**
   * The image's file format. The only supported value is currently webp. If no value is provided,
   * defaults to JPEG.
   *
   * @default 'jpeg'
   */
  format?: 'webp' | 'jpeg';

  /**
   * When height or width are set in the query, fit determines how to handle the
   * image's aspect ratio. Valid values are cover, contain, fill, inside, and outside.
   * If no value is provided, cover is chosen by default. This param only has an effect
   * if format is set to webp.
   *
   * You can learn more at Sharp's documentation on image resizing.
   * https://sharp.pixelplumbing.com/api-resize
   *
   * @default 'cover'
   */
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside';
};

/**
 * Used to transform a Builder.io image URL to include the specified parameters. We
 * use this to optimize images for the web and mobile. For more information,
 * see: https://www.builder.io/c/docs/image-api
 */
export const transformBuilderImage = (url: string, options?: BuilderImageParams) => {
  if (url.includes('arrived.com') || url.includes('arrivedhomes.com') || url.includes(Constants.awsCdnUrl)) {
    return getImageKitSource({
      uri: url,
      ...options,
    });
  }

  const { quality, width, height, format, fit } = options || {};
  const parsedUrl = new URL(url);

  const transformation = [
    Object.fromEntries(
      [
        ['height', height ? `${PixelRatio.getPixelSizeForLayoutSize(height)}px` : undefined],
        ['width', width ? `${PixelRatio.getPixelSizeForLayoutSize(width)}px` : undefined],
        ['format', format],
        ['fit', fit],
        ['quality', quality ? quality.toString() : undefined],
      ].filter(([_, value]) => value !== '0' && value !== undefined),
    ),
  ].reduce((acc, val) => {
    Object.entries(val).forEach(([key, value]) => {
      if (value) {
        acc.append(key, value);
      }
    });

    return acc;
  }, new URLSearchParams());

  if (transformation) {
    parsedUrl.search = transformation?.toString();
  }

  return parsedUrl.toString();
};
