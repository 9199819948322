import { useEffect } from 'react';

import { QueryClient, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getCurrentUser } from '@arrived/api_v2';
import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { isWeb } from '@arrived/bricks';
import { GENERIC_ERROR } from '@arrived/models';
import { Sentry } from '@arrived/sentry';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { currentUserKeyFn } from './users.keys';

/**
 * Get the current user from Abacus `/users` endpoint.
 *
 * @returns {User} The current user that is logged into the app
 *
 * @sideEffect On web, using this query will potentially clear the user session
 * if the user is not authenticated via Abacus. This is a legacy behavior that
 * we are preserving as a safety measure for when a user refocuses the window
 * or refreshes the page.
 */
export function useGetCurrentUserQuery(options?: UseAwaitedQueryOptions<typeof getCurrentUser>) {
  const { isAuthenticated, clearSession } = useArrivedAuth0();

  const currentUserState = useQuery({
    queryKey: currentUserKeyFn(),
    queryFn: getCurrentUser,
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && isAuthenticated,
  });

  useEffect(() => {
    const logoutOnError = async () => {
      if (currentUserState.error) {
        const usedError = currentUserState.error as AxiosError;

        // If the user is not authenticated according to abacus, logout
        if (usedError.response?.status === 401 && usedError.response?.statusText === GENERIC_ERROR.UNAUTHORIZED) {
          Sentry.addBreadcrumb({
            category: Sentry.BreadcrumbCategories.Auth,
            level: 'info',
            message: 'User is not authenticated from `useGetCurrentUserQuery`',
            data: {
              error: usedError,
            },
          });

          /**
           * ! Note: this is a legacy behavior that we need to preserve for web !
           *
           * If we are on web, we can assume that the user needs to be logged out
           * when they are not authenticated via Abacus. On native, we are handling
           * this via the UI and the user is not logged out from this response.
           */

          if (isWeb) {
            clearSession();
          }
        }
      }
    };

    logoutOnError();
  }, [currentUserState.error, clearSession]);

  return currentUserState;
}

export async function fetchCurrentUserQuery(queryClient: QueryClient) {
  return await queryClient.fetchQuery({
    queryKey: currentUserKeyFn(),
    queryFn: () => getCurrentUser(),
    staleTime: MAX_STALE_TIME,
  });
}
