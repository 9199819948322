/// <reference types="vite/client" />

/**
 * These are any environment variables that are required for the app to run,
 * they are a mix between native and web environment variables.
 *
 * This formatting is required for NextJS, so we are just accomplishing
 * types and declarations here.
 */
export const ArrivedEnvironmentVariables = {
  /**
   * @platform all
   */
  NX_BASE_HREF: process.env.NX_BASE_HREF,

  /**
   * @platform all
   */
  NX_PUBLIC_APP_ROOT: process.env.NX_PUBLIC_APP_ROOT,

  /**
   * @platform all
   */
  NX_WEB_APP_ROOT: process.env.NX_WEB_APP_ROOT,

  /**
   * @platform all
   */
  NX_ABACUS_ROOT: process.env.NX_ABACUS_ROOT,

  /**
   * @platform all
   */
  NX_REWARDS_ROOT: process.env.NX_REWARDS_ROOT,

  /**
   * @platform all
   */
  NX_CASH_ACCOUNTS_ROOT: process.env.NX_CASH_ACCOUNTS_ROOT,

  /**
   * @platform all
   */
  NX_CASH_ACCOUNTS_INTERNAL_ROOT: process.env.NX_CASH_ACCOUNTS_INTERNAL_ROOT,

  /**
   * @platform all
   */
  NX_ARRIVED_CMS_ROOT: process.env.NX_ARRIVED_CMS_ROOT,

  /**
   * @platform all
   */
  NX_HELP_PAGES_ROOT: process.env.NX_HELP_PAGES_ROOT,

  /**
   * @platform all
   */
  NX_MARKETING_API_ROOT: process.env.NX_MARKETING_API_ROOT,

  /**
   * @platform all
   */
  NX_MARKETING_API_ARRIVEDHOMES_ROOT: process.env.NX_MARKETING_API_ARRIVEDHOMES_ROOT,

  /**
   * @platform all
   */
  NX_BLOG_ROOT: process.env.NX_BLOG_ROOT,

  /**
   * @platform all
   */
  NX_DEMIO_API_ROOT: process.env.NX_DEMIO_API_ROOT,

  /**
   * @platform all
   */
  NX_ARRIVED_DATA_API_ROOT: process.env.NX_ARRIVED_DATA_API_ROOT,

  /**
   * @platform all
   */
  NX_ASSET_METADATA_API_ROOT: process.env.NX_ASSET_METADATA_API_ROOT,

  /**
   * @platform all
   */
  NX_HISTORICAL_RETURNS_API_ROOT: process.env.NX_HISTORICAL_RETURNS_API_ROOT,

  /**
   * @platform all
   */
  NX_AIR_DNA_API_ROOT: process.env.NX_AIR_DNA_API_ROOT,

  /**
   * @platform native
   */
  NX_MOBILE_BUNDLE_ID: process.env.NX_MOBILE_BUNDLE_ID,

  /**
   * @platform native
   */
  NX_MOBILE_APP_ID: process.env.NX_MOBILE_APP_ID,

  /**
   * @platform all
   */
  NX_AUTH0_AUDIENCE: process.env.NX_AUTH0_AUDIENCE,

  /**
   * @platform all
   */
  NX_AUTH0_CONNECTION: process.env.NX_AUTH0_CONNECTION,

  /**
   * @platform all
   */
  NX_AUTH0_DOMAIN: process.env.NX_AUTH0_DOMAIN,

  /**
   * @platform web
   */
  NX_AUTH0_WEB_CLIENT_ID: process.env.NX_AUTH0_WEB_CLIENT_ID,

  /**
   * @platform web
   */
  NX_AUTH0_WEB_SCOPE: process.env.NX_AUTH0_WEB_SCOPE,

  /**
   * @platform native
   */
  NX_AUTH0_MOBILE_CLIENT_ID: process.env.NX_AUTH0_MOBILE_CLIENT_ID,

  /**
   * @platform native
   */
  NX_AUTH0_MOBILE_SCOPE: process.env.NX_AUTH0_MOBILE_SCOPE,

  /**
   * @platform android
   */
  NX_AUTH0_MOBILE_ANDROID_CALLBACK: process.env.NX_AUTH0_MOBILE_ANDROID_CALLBACK,

  /**
   * @platform ios
   */
  NX_AUTH0_MOBILE_IOS_CALLBACK: process.env.NX_AUTH0_MOBILE_IOS_CALLBACK,

  /**
   * @platform all
   */
  NX_FACEBOOK_APP_ID: process.env.NX_FACEBOOK_APP_ID,

  /**
   * @platform all
   */
  NX_GOOGLE_API_KEY: process.env.NX_GOOGLE_API_KEY,

  /**
   * @platform all
   */
  NX_GOOGLE_TAG_MANAGER_ID: process.env.NX_GOOGLE_TAG_MANAGER_ID,

  /**
   * @platform all
   */
  NX_GROWTHBOOK_API_KEY: process.env.NX_GROWTHBOOK_API_KEY,

  /**
   * @platform all
   */
  NX_INTERCOM_ID: process.env.NX_INTERCOM_ID,

  /**
   * @platform native
   */
  NX_MAPBOX_ACCESS_KEY_MOBILE: process.env.NX_MAPBOX_ACCESS_KEY_MOBILE,

  /**
   * @platform native
   */
  NX_RUDDER_WRITE_KEY_MOBILE: process.env.NX_RUDDER_WRITE_KEY_MOBILE,

  /**
   * @platform web
   */
  NX_RUDDER_WRITE_KEY_WEB: process.env.NX_RUDDER_WRITE_KEY_WEB,

  /**
   * @platform web
   */
  NX_RUDDER_COOKIE_DOMAIN: process.env.NX_RUDDER_COOKIE_DOMAIN,

  /**
   * @platform all
   */
  NX_SENTRY_ENVIRONMENT: process.env.NX_SENTRY_ENVIRONMENT,

  /**
   * @platform native
   */
  NX_SENTRY_DSN_MOBILE: process.env.NX_SENTRY_DSN_MOBILE,

  /**
   * @platform web
   */
  NX_SENTRY_DSN_REACT: process.env.NX_SENTRY_DSN_REACT,

  /**
   * @platform web
   */
  NX_SENTRY_DSN_NEXT: process.env.NX_SENTRY_DSN_NEXT,

  /**
   * @platform all
   */
  NX_PLAID_ENVIRONMENT_NAME: process.env.NX_PLAID_ENVIRONMENT_NAME,

  /**
   * @platform all
   */
  NX_DEMIO_API_KEY: process.env.NX_DEMIO_API_KEY,

  /**
   * @platform all
   */
  NX_DEMIO_API_SECRET: process.env.NX_DEMIO_API_SECRET,

  /**
   * @platform native
   */
  NX_APPSFLYER_DEV_KEY: process.env.NX_APPSFLYER_DEV_KEY,

  /**
   * @platform all
   */
  NX_BUILDER_IO_PUBLIC_KEY: process.env.NX_BUILDER_IO_PUBLIC_KEY,

  /**
   * @platform all
   */
  NX_SARDINE_ENVIRONMENT: process.env.NX_SARDINE_ENVIRONMENT,

  /**
   * @platform all
   */
  NX_SARDINE_CLIENT_ID: process.env.NX_SARDINE_CLIENT_ID,

  /**
   * @platform all
   */
  NX_SARDINE_API_ENDPOINT: process.env.NX_SARDINE_API_ENDPOINT,

  /**
   * @platform all
   */
  NX_SARDINE_PIXEL_ENDPOINT: process.env.NX_SARDINE_PIXEL_ENDPOINT,
} as const;

export type ArrivedEnvironmentVariables = typeof ArrivedEnvironmentVariables;
export type ArrivedEnvironmentVariableKeys = keyof ArrivedEnvironmentVariables;

declare global {
  interface ImportMetaEnv extends ArrivedEnvironmentVariables {}

  interface ImportMeta {
    readonly env: ImportMetaEnv;
  }
}
