import { LocationUtils } from '@arrived/common';
import { Address } from '@arrived/models';

interface AddressViewProps {
  address: Address;
}

export const AddressView = (props: AddressViewProps) => {
  const address = props.address;

  if (!address) {
    return <></>;
  }

  const deriveStreet2DisplayValue = (street2?: string | null): string => {
    if (!street2) {
      return '';
    }

    if (!isNaN(Number(street2))) {
      return '#' + street2;
    }
    return street2;
  };

  const street2 = deriveStreet2DisplayValue(address.street2).toUpperCase();

  return (
    <div className="address-view">
      <div className="street">
        {address.street?.toUpperCase()} {street2}
      </div>
      <div className="city-province-zip">
        {address.city?.toUpperCase()}, {LocationUtils.getProvinceShortName(address.province)?.toUpperCase()}{' '}
        {address.zipCode}
      </div>
    </div>
  );
};
