import { FormattedMessage } from 'react-intl';

import { Stack } from '@arrived/bricks';
import { Offering, STRMetadata } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';

import { AdditionalDetails } from './AdditionalDetails';
import { EquityRaise } from './EquityRaise';
import { LoanDetails } from './LoanDetails';
import { PropertyAmountBreakdown } from './PropertyAmountBreakdown';
import { TotalPropertyAmount } from './TotalPropertyAmount';

export type OfferingDetailsSectionProps = ProductDetailContentProps & { strMetadata?: STRMetadata; offering: Offering };

export const OfferingDetailsSection = ({ offering, strMetadata, ...rest }: OfferingDetailsSectionProps) => {
  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.offering-details" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body gap="$3">
        <PropertyAmountBreakdown offering={offering} />
        <TotalPropertyAmount offering={offering} />
        <Stack gap="$3" $gtSm={{ row: true }}>
          <LoanDetails offering={offering} $gtSm={{ flexBasis: 0, flexGrow: 1, flexShrink: 1 }} />
          <EquityRaise offering={offering} $gtSm={{ flexBasis: 0, flexGrow: 1, flexShrink: 1 }} />
        </Stack>
        <AdditionalDetails offering={offering} strMetadata={strMetadata} />
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};
