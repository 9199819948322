/**
 * Reusable symbols for Builder.io
 * https://builder.io/content?model=ca48cef4aaa7439e8d79a5d404bad39e
 *
 * @see https://www.builder.io/c/docs/make-a-symbol
 */
export const ArrivedBuilderSymbols = {
  /**
   * https://builder.io/content/b69c00800d01462c9f0f0da8614b9511
   */
  WEBINAR: 'b69c00800d01462c9f0f0da8614b9511',

  /**
   * https://builder.io/content/742260be713e45b6a045d7a8e33ada2b
   */
  RELATED_ARTICLES: '742260be713e45b6a045d7a8e33ada2b',
} as const;

export type ArrivedBuilderSymbols = (typeof ArrivedBuilderSymbols)[keyof typeof ArrivedBuilderSymbols];
