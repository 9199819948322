import { useCallback, useMemo } from 'react';

import { useGetAccountBalanceQuery, useGetAccountOfferingBalancesQuery } from '../accounts';
import { useGetPrimaryAccountQuery } from '../users';

/**
 * This is a dependent query that needs the primary account ID to be available.
 */
export const useGetPortfolioQuery = () => {
  const {
    data: primaryAccount,
    isLoading: isPrimaryAccountLoading,
    error: primaryAccountError,
  } = useGetPrimaryAccountQuery();

  const enabled = useMemo(
    () => Boolean(!primaryAccountError && !!primaryAccount?.id),
    [primaryAccount, primaryAccountError],
  );

  const {
    data: accountBalance,
    isLoading: isAccountBalanceLoading,
    error: accountBalanceError,
    refetch: refetchAccountBalance,
  } = useGetAccountBalanceQuery(primaryAccount?.id, {
    refetchInterval: 10_000_000,
    enabled,
  });

  const {
    data: offeringBalance,
    isLoading: isOfferingBalanceLoading,
    error: offeringBalanceError,
    refetch: refetchOfferingBalance,
  } = useGetAccountOfferingBalancesQuery(primaryAccount?.id, {
    refetchInterval: 10_000_000,
    enabled,
  });

  const error = useMemo(
    () => (primaryAccountError ?? accountBalanceError ?? offeringBalanceError)?.description,
    [primaryAccountError, accountBalanceError, offeringBalanceError],
  );

  const isLoading = useMemo(
    () => !error && (isPrimaryAccountLoading || isAccountBalanceLoading || isOfferingBalanceLoading),
    [isPrimaryAccountLoading, isAccountBalanceLoading, isOfferingBalanceLoading, error],
  );

  const refetch = useCallback(() => {
    refetchAccountBalance();
    refetchOfferingBalance();
  }, []);

  return {
    isLoading,
    error,
    refetch,
    data: {
      primaryAccount,
      accountBalance,
      offeringBalance,
    },
  };
};
