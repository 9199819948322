import { GetProps, styled } from '@tamagui/core';

import { BODY_VARIANTS } from './mappings/body';
import { RootText } from './RootText';

export const BodyText = styled(RootText, {
  name: 'BodyText',
  userSelect: 'auto',

  variants: {
    token: BODY_VARIANTS,
  } as const,

  defaultVariants: {
    token: 'body.default.medium',
  },
});

export type BodyTextProps = GetProps<typeof BodyText>;
