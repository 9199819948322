import { useCallback, useState } from 'react';
import { LayoutAnimation, LayoutChangeEvent } from 'react-native';

import Animated, {
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

import { Stack, StackProps } from '../../atoms/layout';

import { smoothSpring } from './smoothSpring';

const AnimatedStack = Animated.createAnimatedComponent(Stack);

export const AnimateHeight = ({ children, expand, ...rest }: StackProps & { expand?: boolean }) => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const maxHeight = useSharedValue(0);
  const height = useSharedValue(0);

  const handleLayoutEvent = useCallback(
    (e: LayoutChangeEvent) => {
      maxHeight.value = e.nativeEvent.layout.height;
    },
    [maxHeight],
  );

  const animatedStyle = useAnimatedStyle(
    () => ({
      height: withSpring(expand ? maxHeight.value : 0, smoothSpring),
    }),
    [expand, height, maxHeight],
  );

  const handleContentToggle = useCallback((isExpanded: boolean) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut, () => setIsContentVisible(isExpanded));
  }, []);

  // If we are expanding, we need to set the content visible,
  // we do this to control the internal state of the content.
  useAnimatedReaction(
    () => height.value > 0.5,
    (isContentVisible, previous) => {
      if (expand === true) {
        runOnJS(handleContentToggle)(expand);
        return;
      }

      if (isContentVisible === previous) {
        return;
      }

      runOnJS(handleContentToggle)(isContentVisible);
    },
    [expand, height],
  );

  return (
    <AnimatedStack aria-hidden={!isContentVisible} overflow="hidden" position="relative" style={animatedStyle}>
      {isContentVisible && (
        <Stack position="absolute" bottom={0} left={0} right={0} onLayout={handleLayoutEvent} {...rest}>
          {children}
        </Stack>
      )}
    </AnimatedStack>
  );
};
