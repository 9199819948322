export default {
  // New portfolio feature
  'portfolio.summary.header': 'Overview',

  'portfolio.greeting.morning': 'Good Morning',
  'portfolio.greeting.afternoon': 'Good Afternoon',

  'portfolio.section.header': 'Portfolio',
  'portfolio.nav.overview': 'Overview',
  'portfolio.nav.activity': 'Activity',
  'portfolio.nav.documents': 'Documents',
  'portfolio.nav.open': 'Open Positions',
  'portfolio.nav.closed': 'Closed Positions',

  'portfolio.summary-card.title': 'Breakdown',
  'portfolio.summary-card.investments.label': 'Investments',
  'portfolio.summary-card.total-balance.label': 'Total Account Balance',
  'portfolio.summary-card.total-balance.tooltip':
    'This is the combination of your current investment balance and Arrived cash balance. Please note that equity will fluctuate with changes in share prices.',
  'portfolio.summary-card.investment-balance.label': 'Investment Balance',
  'portfolio.summary-card.investment-balance.tooltip':
    'This represents the total value of your assets on Arrived, including any of your unrealized gains.',
  'portfolio.summary-card.cash.label': 'Arrived Cash Balance',
  'portfolio.summary-card.cash.tooltip':
    'Your Arrived cash balance represents the amount of cash available for purchase or withdrawal. This amount can be used in addition to your bank account or on its own if the available amount suffices.',

  'portfolio.dividend-card.title': 'Dividends',
  'portfolio.dividend-card.title-with-interest': 'Dividend & Interest',
  'portfolio.dividend-card.aria-label': 'View more details on the historical dividend and interest',
  'portfolio.dividend-card.total-payment.label': 'Total',
  'portfolio.dividend-card.total-payment.tooltip':
    'The cumulative amount of dividends paid out across all of your income-producing investments.',
  'portfolio.dividend-card.total-payment.tooltip-reg-d':
    'The cumulative amount of dividends and interest earned across all of your income-producing investments. This may include accrued interest that has not been paid out yet.',
  'portfolio.dividend-card.annualized-yield.label': 'Annualized Yield',
  'portfolio.dividend-card.annualized-yield.tooltip':
    'The annualized dividend yield of your eligible income-producing investments, based on your previous dividend payments and ownership duration.',
  'portfolio.dividend-card.annualized-yield.tooltip-reg-d':
    'The cumulative amount of dividends and interest earned across all of your income-producing investments. This may include accrued interest that has not been paid out yet.',

  'portfolio.appreciation-card.title': 'Appreciation',
  'portfolio.appreciation-card.total-appreciation.label': 'Total Appreciation',
  'portfolio.appreciation-card.total-appreciation.tooltip':
    'The total appreciation of your equity-based offerings. All appreciation will be unrealized until the related offerings are sold.',

  'portfolio.occupancy-rate-card.title': 'Occupancy',
  'portfolio.occupancy-rate-card.stabilized-occupancy-rate.label': 'Single-Family Occupancy Rate',
  'portfolio.occupancy-rate-card.stabilized-occupancy-rate.tooltip':
    'The percentage of stabilized Single-Ramily Residences in your portfolio that are currently rented.',

  'portfolio.cash-balance-sheet.title': 'Cash Balance',
  'portfolio.cash-balance-sheet.actions-title': 'What would you like to do with your money?',
  'portfolio.cash-balance-sheet.actions.withdraw.title': 'Withdraw',
  'portfolio.cash-balance-sheet.actions.withdraw.description': 'Move funds from my Arrived account to my bank.',
  'portfolio.cash-balance-sheet.actions.invest.title': 'Invest',
  'portfolio.cash-balance-sheet.actions.invest.description':
    'Invest funds from my Arrived account into Arrived products. ',

  'portfolio.dividend-sheet.title': 'Dividend Payments',
  'portfolio.dividend-sheet.title-with-interest': 'Dividend & Interest',
  'portfolio.dividend-sheet.realized': 'Realized',
  'portfolio.dividend-sheet.dividend-payments': 'Dividend Payments',
  'portfolio.dividend-sheet.dividend-payments.tooltip':
    'These are distributions of the cash flow generated from your property and fund investments. Those are paid monthly to your Arrived Cash Balance.',
  'portfolio.dividend-sheet.interest-payments': 'Interest Payments',
  'portfolio.dividend-sheet.interest-payments.tooltip':
    'This refers to interest paid for matured Short Term Notes. Interest accrues during the note period and is paid out on the maturity date. Those are paid to your Arrived Cash Balance.',
  'portfolio.dividend-sheet.unrealized': 'Unrealized',
  'portfolio.dividend-sheet.accrued-interest': 'Accrued Interest',
  'portfolio.dividend-sheet.accrued-interest.tooltip':
    'Interest earned on Short Term Notes that has not yet been paid out because the note(s) are still maturing.',
  'portfolio.dividend-sheet.total-earnings': 'Total Earnings',
  'portfolio.dividend-sheet.total-earnings.tooltip':
    'The sum of all realized dividend payments from all your investments.',
  'portfolio.dividend-sheet.total-earnings-with-interest.tooltip':
    'The sum of all realized and unrealized dividend and interest payments from all your investments.',
  'portfolio.dividend-sheet.last-dividend': 'Last Dividend ({lastDividendMonth})',
  'portfolio.dividend-sheet.all-previous-dividends': 'All Previous Dividends',
  'portfolio.dividend-sheet.total-dividends': 'Rental Income (all time)',
  'portfolio.dividend-sheet.total-dividends.tooltip':
    "We pay dividends monthly based on the % of your shares owned and the income generated by the offering each month. These are realized gains and are deposited directly into your Arrived Cash Balance, and are reflected under your 'Cash' value.",
  'portfolio.dividend-sheet.total-dividends.description':
    'Dividends are paid out from Arrived and sent to your Arrived Cash Balance or linked bank account.',
  'portfolio.dividend-sheet.total-dividends-with-interest.description':
    'This is how we calculate your total dividend & interest earnings.',

  'portfolio.appreciation-sheet.title': 'Appreciation Returns',
  'portfolio.appreciation-sheet.total-share-value': 'Total Share Value',
  'portfolio.appreciation-sheet.initial-investment': 'Initial Investment',
  'portfolio.appreciation-sheet.all-time-appreciation': 'Appreciation Returns (all time)',
  'portfolio.appreciation-sheet.all-time-appreciation.description':
    "We use House Canary, Redfin, and CoreLogic data to estimate the share value of the equity-based offerings you've invested in. All appreciation returns will be unrealized until the related offerings are sold.",

  'portfolio.net-return-card.title': 'Net Return',
  'portfolio.net-return-card.label': 'Total',
  'portfolio.net-return-card.tooltip':
    'Net return is calculated by adding the total dividends paid by an investment to its realized and unrealized gain or loss. Dividends are realized upon payment, while appreciation remains unrealized until the related offerings are sold.',

  'portfolio.net-return-sheet.title': 'Net Return',
  'portfolio.net-return-sheet.description': 'This is how we calculate your net return.',
  'portfolio.net-return-sheet.realized': 'Realized',
  'portfolio.net-return-sheet.dividend-payments': 'Dividend Payments',
  'portfolio.net-return-sheet.dividend-payments.tooltip':
    'These are distributions from the cash flow generated from your property and fund investments.',
  'portfolio.net-return-sheet.interest-payments': 'Interest Payments',
  'portfolio.net-return-sheet.interest-payments.tooltip': 'This refers to interest paid for matured Short Term Notes.',
  'portfolio.net-return-sheet.realized-gain-loss': 'Realized Gain/Loss',
  'portfolio.net-return-sheet.realized-gain-loss.tooltip':
    'This is the final appreciation gain from your initial investment for your closed positions. The gain from initial investment value may differ from your taxable gain. Gain/loss is inclusive of any applicable fees.',
  'portfolio.net-return-sheet.unrealized': 'Unrealized',
  'portfolio.net-return-sheet.accrued-interest': 'Accrued Interest',
  'portfolio.net-return-sheet.accrued-interest.tooltip':
    'This represents interest that has been earned but not yet paid out for a short-term note.',
  'portfolio.net-return-sheet.unrealized-gain-loss': 'Unrealized Gain/Loss',
  'portfolio.net-return-sheet.unrealized-gain-loss.tooltip':
    'This represents the current estimated change in value from your initial investment for your currently open positions.',
  'portfolio.net-return-sheet.net-return': 'Net Return',
  'portfolio.net-return-sheet.total-net-return': 'Total Net Return',
  'portfolio.net-return-sheet.total-net-return.tooltip':
    'This includes the realized and unrealized returns of all your Arrived investments.',
  'portfolio.net-return-sheet.total-earnings': 'Total Earnings',

  'portfolio.net-return-sheet.current-share-value': 'Current Share Value',
  'portfolio.net-return-sheet.initial-investment': '- Initial Investment',
  'portfolio.net-return-sheet.unrealized-gain': 'Unrealized Gain/Loss',
  'portfolio.net-return-sheet.realized-gain': 'Realized Gain/Loss',
  'portfolio.net-return-sheet.total-dividends-paid': '+ Total Dividends Paid',

  'portfolio.occupancy-rate-sheet.title': 'Long Term Occupancy Rate',
  'portfolio.occupancy-rate-sheet.occupied-count': '# of Occupied Single-Family Residences',
  'portfolio.occupancy-rate-sheet.stabilized-count': '# of Stabilized Single-Family Residences',
  'portfolio.occupancy-rate-sheet.occupancy-rate': 'Occupancy Rate',
  'portfolio.occupancy-rate-sheet.description':
    'Your cumulative Occupancy Rate represents the percentage of Single-Family Residences in your portfolio that are currently rented or are at least 90 days from the completion of their initial improvements. That 90 day waiting period ensures that they\'ve had a fair chance to be rented; these are considered "Stabilized" residences.',

  'portfolio.investments-table.section-header': 'Your Investments',
  'portfolio.investments-table.generate-csv-export.error': 'Error generating CSV file: {error}',
  'portfolio.investments-table.generate-csv-export-button.download': 'CSV',
  'portfolio.investments-table.investment-type-select.single-family-rentals': 'Single Family',
  'portfolio.investments-table.investment-type-select.single-family-rentals-fund': 'Single Family Fund',
  'portfolio.investments-table.investment-type-select.private-credit-fund': 'Private Credit Fund',
  'portfolio.investments-table.investment-type-select.vacation-rentals': 'Vacation Rental',
  'portfolio.investments-table.investment-type-select.vacation-rentals-fund': 'Vacation Rental Fund',
  'portfolio.investments-table.investment-type-select.short-term-notes': 'Short Term Notes',
  'portfolio.investments-table.investment-type-select.arrived-owner': 'Arrived Owner',
  'portfolio.investments-table.investment-type-select.arrived-owner.tooltip.title': 'Arrived Owner Equity',
  'portfolio.investments-table.investment-type-select.arrived-owner.tooltip.content':
    'This represents your interest in Arrived Holdings resulting from your investment into an Arrived SPV through WeFunder. Details of your investment can be found on WeFunder but are summarized here for your information.',
  'portfolio.investments-table.investment-type-select.aria-label': 'Investment type select for details table',
  'portfolio.investments-table.investment-type-select.item.aria-label': '{investmentType}, count {numberOfInvestments}',
  'portfolio.investments-table.header.offering-name.property': 'Property Name',
  'portfolio.investments-table.header.offering-name.investment-info': 'Investment Info',
  'portfolio.investments-table.header.offering-name.property-info': 'Property Info',
  'portfolio.investments-table.header.offering-name.fund': 'Fund Info',
  'portfolio.investments-table.header.offering-name.debt': 'Debt Info',
  'portfolio.investments-table.header.period': 'Period',
  'portfolio.investments-table.header.notes': 'Notes',
  'portfolio.investments-table.header.location': 'Location',
  'portfolio.investments-table.header.type': 'Type',
  'portfolio.investments-table.header.duration': 'Duration',
  'portfolio.investments-table.header.cost-basis': 'Cost Basis',
  'portfolio.investments-table.header.status': 'Status',
  'portfolio.investments-table.header.owner-since': 'Owned',
  'portfolio.investments-table.header.equity': 'Equity',
  'portfolio.investments-table.header.number-properties': 'Properties',
  'portfolio.investments-table.header.loans': 'Loans',
  'portfolio.investments-table.header.date-purchased': 'Date Purchased',
  'portfolio.investments-table.header.date-of-purchase': 'Date of Purchase',
  'portfolio.investments-table.header.proceeds': 'Proceeds',
  'portfolio.investments-table.header.share-count': 'Shares',
  'portfolio.investments-table.header.share-price': 'Price',
  'portfolio.investments-table.header.date': 'Date',
  'portfolio.investments-table.header.share-price.tooltip':
    "Equity is the total value of all shares owned at the current share price. Share prices are determined by taking the current property value estimate and factoring in the LLC's assets and liabilities, such as cash reserves and loan balance. Reduction in cash reserves from starting operations and servicing loans can negatively impact the share valuation initially. We also take into account the pro-rated upfront fees involved in the investment, including the Closing, Offering, Holding costs, & Arrived sourcing fee. The Share Price value may go up or down from quarter to quarter, and the actual investment returns will largely depend on the property’s eventual sale price at the end of the investment period. Learn more about how share prices are calculated <a>in our Learn blog</a>.",
  'portfolio.investments-table.header.share-price-range': 'Low & High Est.',
  'portfolio.investments-table.header.share-price-range.tooltip':
    'The Share Price of each property is based on an estimated sales price of the underlying property. The range provided is intended to give an idea of the potential current value based on third-party data. The range is calculated by either increasing or decreasing the projected sale price based on the higher of the two figures: the 20-year annual historical appreciation for the zip code or the national 20-year annual historical appreciation rate of 4.5%',
  'portfolio.investments-table.header.investment-amount': 'Investment Amount',
  'portfolio.investments-table.header.initial-investment': 'Initial Investment',
  'portfolio.investments-table.header.total-interest': 'Total Interest',
  'portfolio.investments-table.header.current-share-value': 'Current Share Value',
  'portfolio.investments-table.header.current-value': 'Current Value',
  'portfolio.investments-table.header.current-amount': 'Current Amount',
  'portfolio.investments-table.header.initial-amount': 'Initial Amount',
  'portfolio.investments-table.header.gain-loss': 'Gain/Loss',
  'portfolio.investments-table.header.unrealized-gain-loss': 'Unrealized Gain/Loss',
  'portfolio.investments-table.header.realized-gain-loss': 'Realized Gain/Loss',
  'portfolio.investments-table.header.realized-gain-loss-percent': 'Realized Gain/Loss %',
  'portfolio.investments-table.header.unrealized-gain-loss.tooltip':
    'The unrealized gain or loss of an investment reflects the difference between the initial investment and current equity based on share value. All appreciation returns remain unrealized until the related offerings are sold.',

  'portfolio.closed-positions-table.empty-state':
    'You don’t have any closed positions yet. When you redeem shares or reach the end of an investment’s hold period, you’ll see that investment listed here.',
  'portfolio.closed-positions-sheet.header': 'Closed Position',

  'portfolio.investments-table.header.appreciation': 'Appreciation',
  'portfolio.investments-table.header.total-appreciation': 'Total Appreciation',
  'portfolio.investments-table.header.latest-payment': 'Latest Payment',
  'portfolio.investments-table.header.latest-dividend': 'Latest Dividend',
  'portfolio.investments-table.header.latest-payment.tooltip':
    "The latest dividend represents the most recent dividend payment received for your share ownership. Properties represent cash flow after expenses and reserves. The monthly dividends may vary month to month and are determined by analyzing several factors, including rental status, the amount of cash in the property's reserves, last quarter's cash flow, projected expenses, and lease renewal timing. For more information on why dividends may be increased, decreased or paused, please see <a>our detailed FAQ</a>. The rental history and dividend payments for each property can be found on the performance tab.",
  'portfolio.investments-table.header.total': 'Total',
  'portfolio.investments-table.header.total-payment': 'Total Payment',
  'portfolio.investments-table.header.total-dividends': 'Total Dividends',
  'portfolio.investments-table.header.all-time-dividends': 'All Time Dividends',
  'portfolio.investments-table.header.all-time-dividends-tooltip':
    'All dividends earned on shares of this offering, regardless of whether or not you currently own those shares.',
  'portfolio.investments-table.header.total-return': 'Total Return',
  'portfolio.investments-table.header.net-return': 'Net Return',
  'portfolio.investments-table.header.net-return.tooltip':
    'Net return is calculated by adding the total dividends paid by an investment to its realized and unrealized gain or loss. Dividends are realized upon payment, while appreciation remains unrealized until the related offerings are sold.',
  'portfolio.investments-table.header.annualized-yield': 'Annualized Yield',
  'portfolio.investments-table.header.annualized-yield-tooltip':
    'Calculated based on your All Time Dividends, regardless of whether or not you currently own those shares.',
  'portfolio.investments-table.header.expected-annualized-yield': 'Expected Annualized Yield',
  'portfolio.investments-table.header.actual-annualized-yield': 'Actual Annualized Yield',

  'portfolio.investments-table.header.expected-interest': 'Expected Interest',
  'portfolio.investments-table.header.accrued-interest': 'Accrued Interest',

  'portfolio.investments-table.header.rental-status': 'Rental Status',
  'portfolio.investments-table.header.lease-end-date': 'Lease End Date',
  'portfolio.investments-table.header.monthly-rent': 'Monthly Rent',
  'portfolio.investments-table.header.purchase-date': 'Purchase Date',
  'portfolio.investments-table.header.maturity-date': 'Maturity Date',
  'portfolio.investments-table.header.date-redeemed': 'Date Redeemed',
  'portfolio.investments-table.header.date-sold': 'Date Sold',
  'portfolio.investments-table.header.remaining-terms': 'Remaining Terms',
  'portfolio.investments-table.header.external-rental-link': 'Rental Link',
  'portfolio.investments-table.header.city': 'City',
  'portfolio.investments-table.header.state': 'State',
  'portfolio.investments-table.offering.type.real-estate-debt': 'Real Estate Debt',
  'portfolio.investments-table.offering.type.single-family': 'Single-Family Residential',
  'portfolio.investments-table.offering.view-external-listing': 'View Listing',
  'portfolio.investments-table.offering.view-details': 'View offering details page',
  'portfolio.investments-table.empty-state.button': 'Browse offerings',
  'portfolio.investments-table.empty-state.button.explore-the-fund': 'Explore the fund',
  'portfolio.investments-table.empty-state.single-family':
    'You don’t have any single-family residence investments yet. Browse our available properties and take your first step toward investing.',
  'portfolio.investments-table.empty-state.single-family-fund':
    'You don’t have any fund investments yet. Browse our available funds and take your first step toward investing.',
  'portfolio.investments-table.empty-state.vacation-rental':
    'You don’t have any vacation rental investments yet. Browse our available properties and take your first step toward investing.',
  'portfolio.investments-table.empty-state.debt':
    'You don’t currently have any open Short Term Notes. Ready for another real estate debt investment? Check out the always-available Private Credit Fund.',
  'portfolio.investments-table.empty-state.private-credit-fund':
    'You don’t have any private credit fund investments yet. Browse our available offerings and take your first step toward investing.',
  'portfolio.investments-table.empty-state.arrived-owner-equity':
    'You don’t have any Arrived Owner Equity investments yet. These are available to our WeFunder program members!',

  'portfolio.maturity-date-label': 'Maturity Date: {maturityDate}',
  'portfolio.awaiting-valuation': 'Awaiting Valuation',
  'portfolio.number-of-months': '{months} {months, plural, one {month} other {months}}',
  'portfolio.purchase-date': 'Purchased on {purchaseDate}',
  'portfolio.percent-per-year': '{percent} / Year',
  'portfolio.first-dividend-date': 'First Dividend',
  'portfolio.equity': 'Equity',
  'portfolio.reg-d.no-longer-accessible.label': 'Reg D offering - no longer accessible',
  'portfolio.reg-d.no-longer-accessible.description':
    "Based on your accreditation status, you no longer have access to view this offering's details. Please update your status in the Investment Account settings if this is incorrect.",

  'portfolio.performance.back-to-overview': 'Back to Overview',
  'portfolio.performance.timeframe.aria-label': 'Timeframe of selected historical performance data',
  'portfolio.performance.timeframe.all-time': 'All Time',

  'portfolio.performance.chart.reset-timeframe': 'Reset',
  'portfolio.performance.chart.reset-timeframe.aria-label': 'Reset selected timeframe',
  'portfolio.performance.chart.loading': 'Loading historical data',

  'portfolio.performance.dividend.title': 'Dividend Payments',
  'portfolio.performance.dividend-interest.title': 'Dividend & Interest',
  'portfolio.performance.dividend-interest.open-dividend-details.aria-label': 'Open dividend details sheet',
  'portfolio.performance.dividend-interest.total': 'Total Dividend + Interest',
  'portfolio.performance.dividend.total': 'Total Dividends',
  'portfolio.performance.dividend-interest.annualized-yield': 'Annualized Yield',
  'portfolio.performance.dividend-interest.interest-paid': 'Total Interest Paid',
  'portfolio.performance.dividend-interest.interest-accrued': 'Total Accruing Interest',
  'portfolio.performance.dividend-interest.dividends': 'Total Dividends Paid',

  'portfolio.performance.dividend-interest.chart.title':
    'A chart displaying the monthly dividend and interest performance since the creation of the account',
  'portfolio.performance.dividend-interest.chart.empty':
    "You haven't received any dividends yet. Your dividend history will appear here once you do.",

  'portfolio.performance.net-return.title': 'Net Return',
  'portfolio.performance.net-return.open-net-return-details.aria-label': 'Open net return details sheet',
  'portfolio.performance.net-return.total': '{isTotal, select, true {Total } other {}}Net Return',
  'portfolio.performance.net-return.dividend.total': '{isTotal, select, true {Total } other {}}Dividends',
  'portfolio.performance.net-return.dividend-interest.total':
    '{isTotal, select, true {Total } other {}}Dividend + Interest',
  'portfolio.performance.net-return.appreciation.total':
    '{isTotal, select, true {Total } other {}}Share Price Gain/Loss',
  'portfolio.performance.net-return.appreciation.tooltip':
    'Includes both the realized and unrealized gains of your owned offerings.',

  'portfolio.performance.net-return.chart.title':
    'A chart displaying the monthly net return values since the creation of the account',
  'portfolio.performance.net-return.chart.empty':
    "You haven't received any returns yet. Your dividend and share price appreciation history will appear here once you do.",

  // Existing portfolio components
  'portfolio.header': 'My Portfolio',
  'portfolio.balance': 'Balance',
  'portfolio.appreciationReturns': 'Appreciation Returns',
  'portfolio.mobile.investmentPerformance': 'Investment Performance',
  'portfolio.summary-cards.rental-income.description': 'Withdrawn from Arrived and sent to your Arrived Cash Balance',
  'portfolio.summary-cards.rental-income.tooltip':
    "We pay dividends monthly based on the % of your shares owned and the income generated by the offering each month. These are realized gains and are deposited directly into your Arrived Cash Balance, and therefore aren't included in your portfolio balance.",
  'portfolio.income.header': 'Monthly Rent',
  'portfolio.status.open': 'Open',
  'portfolio.status.inProgress': 'In progress',
  'portfolio.status.completed': 'Completed',
  'portfolio.status.matured': 'Matured',
  'portfolio.rentalType.na': 'N/A',
  'portfolio.rentalType.longTerm': 'Long Term',
  'portfolio.rentalType.shortTerm': 'Vacation',
  'portfolio.rentalIncome': 'Rental Income',
  'portfolio.rentalIncome.forecast': ' {value} From Forecast',
  'portfolio.rentalIncome.metForecast': 'Met Forecast',
  'portfolio.rentalIncome.variable': 'Variable',
  'portfolio.rentalIncome.targetRent': 'Target Rent',
  'portfolio.sharePriceRange': '{min} | {max}',
  'portfolio.nextDividend': '(Next Dividend {date})',
  'portfolio.firstValuation': '(First Valuation {date})',
  'portfolio.latestValuation': '(Latest Valuation {date})',
  'portfolio.nextValuation': '(Next Valuation {date})',
  'portfolio.valuationTBD': '(First Valuation TBD)',
  'portfolio.offeringDialog.rentalIncomeBreakdown.title': 'Rental Income Return Breakdown',
  'portfolio.offeringDialog.rentalIncomeBreakdown.entryTitle': '{date} Dividend Payment',
  'portfolio.offeringDialog.rentalIncomeBreakdown.otherTitle': 'Other Dividends',
  'portfolio.offeringDialog.rentalIncomeBreakdown.otherTitle.tooltip':
    'The accumulation of past dividends paid out to your account.',
  'portfolio.property-name.title': 'Property Name',
  'portfolio.lease-end-date.title': '(Lease Ends {date})',
  'portfolio.your-dividends.title': 'Your Dividends',
  'portfolio.first-dividend.title': 'First Dividend',
  'portfolio.first-dividend.awaiting-eligibility': 'Awaiting Eligibility',
  'portfolio.first-dividend.description.single-family-residential-IPO':
    'Dividends for Single Family Residential offerings are expected to be paid out monthly, beginning when the property is leased.',
  'portfolio.first-dividend.description.vacation-rental-IPO':
    'Dividends for Vacation Rental offerings are expected to be paid out monthly, beginning when the property is ready for booking.',
  'portfolio.first-dividend.description.single-family-residential-fund':
    'Dividends for the Single Family Residential Fund are expected to be paid out monthly. Please note that depending on the timing of your investment, it may take up to 60 days to receive your first dividend.',
  'portfolio.first-dividend.description.private-debt-fund':
    'Dividends for the Private Debt Fund are expected to be paid out monthly. Please note that depending on the timing of your investment, it may take up to 60 days to receive your first dividend.',
  'portfolio.first-dividend.description.short-term-note-IPO':
    'Short Term Note offerings distribute funds at the end of their respective maturity dates.',
  'portfolio.stabilizedOccupancy.title': 'Long Term Occupancy Rate',
  'portfolio.stabilizedOccupancy.ownedPropertyCount': '# of Stabilized Single-Family Residences {infoIcon}',
  'portfolio.stabilizedOccupancy.occupiedPropertyCount': '# of Occupied Single-Family Residences',
  'portfolio.stabilizedOccupancy.occupiedPropertyCount.tooltip':
    "Stabilized Single-Family Residences are properties that are currently rented or are at least 90 days from the completion of their initial improvements. That 90 day waiting period ensures that they've had a fair chance to be rented.",
  'portfolio.stabilizedOccupancy.occupancyRate': 'Occupancy Rate',

  'portfolio.invest': 'Invest',
  'portfolio.invest.title': 'Where do you want to invest?',
  'portfolio.invest.description': 'Choose one of our available options for investment.',
} as const;
