import { stringify } from 'qs';

import { OfferingPatchSchema, OfferingPostSchema } from '@arrived/forms';
import {
  ApiError,
  FundsPropertyOccupancy,
  Offering,
  OfferingCid,
  OfferingDividendHistory,
  OfferingId,
  OfferingIdOrOfferingShortName,
  OfferingPostFixedRateInvestmentSchema,
  OfferingPropertyRequest,
  OfferingPropertyValuation,
  OfferingQueryParams,
  OfferingSearchQuery,
  OfferingSharePrices,
  OfferingSharePricesHistory,
  OfferingTradesFilters,
  OfferingTransaction,
} from '@arrived/models';

import { api, publicApi } from './api';
import { createQuery } from './create-query';

/**
 * @deprecated - Recommend using offerings via search which is using "Open Search"
 */
export const getOfferings = (query?: OfferingQueryParams, isPublic = false) =>
  createQuery<Offering[]>({
    apiInstance: isPublic ? publicApi : api,
    method: 'get',
    url: `/offerings`,
    config: {
      params: query,
      paramsSerializer: (params) => stringify(params),
    },
  });

export type OpenSearchOfferingDocument = Offering & { thumbnailPhotoUrl?: string | null; photoUrls?: string[] };

/**
 * This regex matches any sort query where a sort on propertyRank is present and a sort on id or name is not present.
 * It's important (as of the writing of this comment) that the sort includes at least one guaranteed ordering (like id
 * or name) because many Offerings have the same propertyRank and when indexed by OpenSearch won't always return in the
 * same order. This can lead to issues where different pages have the same Offering and if we render both copies of
 * the Offering we can get duplicate key issues.
 */
const sortRegex = /^(?!.*(id|name):)(?=.*propertyRank:).*$/;

export const getOfferingsSearch = (query?: OfferingSearchQuery) => {
  if (query?.sort?.match(sortRegex)) {
    throw new Error(
      'Query sorts by propertyRank but not id or name, sorts by propertyRank must include a sort on an attribute with guaranteed ordering',
    );
  }

  const params = stringify(query, { allowDots: true, arrayFormat: 'comma' });

  return createQuery<{
    data: OpenSearchOfferingDocument[];
    error?: ApiError;
    pagination: {
      page: number;
      size: number;
      totalResults: number;
    };
  }>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/search${params.length > 0 ? `?${params}` : ''}`,
  });
};

export const getOffering = (offeringIdOrOfferingShortName: OfferingIdOrOfferingShortName) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringIdOrOfferingShortName}`,
  });

export const getOfferingValuations = (offeringId: OfferingId) =>
  createQuery<OfferingPropertyValuation[]>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringId}/valuations`,
  });

export const postOffering = (request: OfferingPostSchema) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'post',
    url: '/offerings',
    requestData: request,
  });

export const deletePropertyFromOffering = (request: OfferingPropertyRequest) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'delete',
    url: `/offerings/${request.id}/properties`,
    requestData: request,
  });

export const deleteOffering = (offeringId: OfferingId) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'delete',
    url: `/offerings/${offeringId}`,
  });

export const patchOffering = ({ id, request }: { id: OfferingId; request: OfferingPatchSchema }) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'patch',
    url: `/offerings/${id}`,
    requestData: request,
  });

export const addPropertyToOffering = (request: OfferingPropertyRequest) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'post',
    url: `/offerings/${request.id}/properties`,
    requestData: request,
  });

export const patchPropertiesForOffering = (request: OfferingPropertyRequest) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'patch',
    url: `/offerings/${request.id}/properties`,
    requestData: request,
  });

export const removePropertyFromOffering = (offeringId: OfferingId) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'delete',
    url: `/offerings/${offeringId}/properties`,
  });

export const getOfferingTrades = (offeringId: OfferingId, query?: OfferingTradesFilters) =>
  createQuery<OfferingTransaction[]>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringId}/trades`,
    config: { params: query },
  });

export const getFundsPropertyOccupancy = (offeringCid: OfferingCid) =>
  createQuery<FundsPropertyOccupancy>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringCid}/funds-property-occupancy`,
  });

export const getOfferingSharePrices = (offeringId: OfferingId) =>
  createQuery<OfferingSharePrices[]>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringId}/share-prices`,
  });

export const getOfferingSharePricesHistory = (offeringCid: OfferingCid) =>
  createQuery<OfferingSharePricesHistory[]>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringCid}/share-prices/history`,
  });

export const getOfferingDividendHistory = (offeringId: OfferingId) =>
  createQuery<OfferingDividendHistory[]>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringId}/dividends/history`,
  });

export const postOfferingFixedRateInvestments = (request: OfferingPostFixedRateInvestmentSchema) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'post',
    url: `/offerings/${request.cid}/fixed-rate-investments`,
    requestData: request,
  });

export const deleteOfferingFixedRateInvestments = (request: OfferingPostFixedRateInvestmentSchema) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'delete',
    url: `/offerings/${request.cid}/fixed-rate-investments`,
    requestData: request,
  });

export const patchOfferingFixedRateInvestments = (request: OfferingPostFixedRateInvestmentSchema) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'patch',
    url: `/offerings/${request.cid}/fixed-rate-investments`,
    requestData: request,
  });
