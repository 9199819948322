import { useCallback, useEffect, useLayoutEffect } from 'react';

import { AxiosError, AxiosInstance, InternalAxiosRequestConfig, isAxiosError } from 'axios';

import { useArrivedAuth0, useStepUpLogin } from '@arrived/arrived-auth0';
import { AbacusErrorCodes } from '@arrived/models';

const useSSRSafeLayoutEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;

/**
 * When the Arrived Auth0 Context indicates that the user is authenticated, this hook will add an interceptor
 * that gets invoked on all API requests that appends an Authorization header with the user's
 * access token.
 */
export function useAuthenticationInterceptor(instance: AxiosInstance, headers?: Record<string, string>) {
  const { isAuthenticated, getCredentials } = useArrivedAuth0();
  const stepUpLogin = useStepUpLogin();

  const handleRequestInterceptor = useCallback(
    async (config: InternalAxiosRequestConfig) => {
      // Awaiting outside of the string template to avoid undefined issues on mobile
      const credentials = await getCredentials();

      // Note: Our BE Team says it's completely OK to always send JWT regardless of need
      if (credentials?.accessToken) {
        config.headers['Authorization'] = `Bearer ${credentials.accessToken}`;
      }

      if (headers) {
        Object.entries(headers).forEach(([header, value]) => {
          config.headers[header] = value;
        });
      }

      return config;
    },
    [getCredentials, headers],
  );

  const handleResponseInterceptor = useCallback(
    (error: unknown) => {
      const e = error as AxiosError<{
        config?: InternalAxiosRequestConfig;
        applicationErrorCode?: string;
        error?: {
          applicationErrorCode?: string;
          error?: string;
        };
      }>;

      if (
        isAxiosError(e) &&
        (e.response?.data?.applicationErrorCode || e.response?.data?.error?.applicationErrorCode)
      ) {
        // Abacus wraps errors in an `error` field, hence the extra check.
        const errorCode = e.response.data.applicationErrorCode ?? e.response?.data?.error?.applicationErrorCode;

        switch (errorCode) {
          case AbacusErrorCodes.MFA_REQUIRED:
            stepUpLogin();
            throw new Error('Multi-factor authentication required');

          case AbacusErrorCodes.EMAIL_VERIFICATION_REQUIRED:
            throw new Error('Email verification required');
        }
      }

      // This is here as a catch all for us to debug errors that are not caught by the above switch statement
      console.error(
        'Authentication Interceptor Error',
        e,
        JSON.stringify(
          {
            data: e.response?.data,
            url: `${e.config?.baseURL}${e.config?.url}`,
          },
          null,
          2,
        ),
      );

      throw e;
    },
    [stepUpLogin],
  );
  /**
   * We choose to add the interceptor in a useLayoutEffect so that it gets added when the component calling
   * this hook gets rendered. Doing so in a regular useEffect means that the interceptor doesn't get added
   * until after the render lifecycle which may be too late for components down the application
   * which are trying to invoke API calls that need authentication.
   */
  useSSRSafeLayoutEffect(() => {
    /**
     * If not authenticated, only attach headers
     */
    if (!isAuthenticated) {
      const requestInterceptorId = instance.interceptors.request.use((config) => {
        if (headers) {
          Object.entries(headers).forEach(([header, value]) => {
            config.headers[header] = value;
          });
        }

        return config;
      });

      return () => {
        instance.interceptors.request.eject(requestInterceptorId);
      };
    }

    const requestInterceptorId = instance.interceptors.request.use(handleRequestInterceptor);

    // Parses all exceptions to trigger specific global error handling behaviors for common error
    // codes, like step-up auth workflows.
    // Thrown errors will be caught by `createQuery` and be passed along to the UI.
    const responseInterceptorId = instance.interceptors.response.use(undefined, handleResponseInterceptor);

    return () => {
      instance.interceptors.request.eject(requestInterceptorId);
      instance.interceptors.response.eject(responseInterceptorId);
    };
  }, [instance, isAuthenticated, handleResponseInterceptor, handleRequestInterceptor]);
}
