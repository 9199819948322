import { forwardRef, useMemo } from 'react';

import { GetProps, TamaguiElement } from '@arrived/bricks';
import { LocationUtils } from '@arrived/common';
import { Property } from '@arrived/models';

import { Location, LocationProps } from './Location';
import { usePropertyCoordinates } from './usePropertyCoordinates';

export type PropertyLocationProps = LocationProps & { property: Property } & Pick<
    GetProps<typeof Location.Marker>,
    'icon'
  >;

export const PropertyLocation = forwardRef<TamaguiElement, PropertyLocationProps>(
  ({ property, icon, ...rest }, ref) => {
    const coordinates = usePropertyCoordinates(property);

    const address = useMemo(
      () => property.address && LocationUtils.getAddressString(property.address),
      [property.address],
    );

    return (
      <Location ref={ref} {...rest}>
        {coordinates && (
          <Location.Map gradient={address != null} coordinates={coordinates} zoom={10}>
            <Location.Marker coordinates={coordinates} icon={icon} />
          </Location.Map>
        )}
        {address && <Location.Address address={address} />}
      </Location>
    );
  },
);
