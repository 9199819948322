import { GetProps, styled } from '@tamagui/core';

import { TITLE_VARIANTS } from './mappings/title';
import { RootText } from './RootText';

/**
 * When using `TitleText`, be cognizant of needing to specify
 * the `tag` prop as otherwise, it will default to `span` on the web.
 */
export const TitleText = styled(RootText, {
  name: 'TitleText',
  userSelect: 'auto',
  role: 'heading',
  fontFamily: '$heading',

  variants: {
    token: TITLE_VARIANTS,
  } as const,

  defaultVariants: {
    token: 'title.heading.medium',
  },
});

export type TitleTextProps = GetProps<typeof TitleText>;
