import { Stack, UtilityText, styled } from '@arrived/bricks';

import { OperatorFrameName, OperatorTextName } from './constants';

export const OperatorFrame = styled(Stack, {
  name: OperatorFrameName,

  bg: '$surface.neutral.yin',
  br: '$full',
  h: '$5',
  w: '$5',
  centered: true,
});

const OperatorText = styled(UtilityText, {
  name: OperatorTextName,

  token: 'utility.label.small.alt',
  color: '$onSurface.neutral.defaultInverted',
  transform: [{ translateY: -1 }],
});

export const Operator = OperatorFrame.styleable(({ children, ...rest }, ref) => {
  return (
    <OperatorFrame ref={ref} {...rest}>
      <OperatorText>{children}</OperatorText>
    </OperatorFrame>
  );
});
