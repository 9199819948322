import { cashAccountApi, createQuery } from '@arrived/api_v2';

import { CashAccount } from '../types/cash-accounts';

interface GetCashAccountQueryParams {
  includeUserAuthorizationLevel?: boolean;
  includeBalances?: boolean;
}

export const getCashAccount = (cashAccountId: string, params?: GetCashAccountQueryParams) =>
  createQuery<CashAccount>({
    apiInstance: cashAccountApi,
    method: 'get',
    url: `/cash-accounts/${cashAccountId}`,
    config: { params },
  });
