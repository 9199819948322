import { isWeb, styled } from '@tamagui/core';

import { Stack } from '../../atoms';
import { BlurView, BlurViewProps } from '../../atoms/blur-view';

import { useSheetImperativeContext } from './SheetImperativeContext';

export const SHEET_BACKDROP_NAME = 'SheetBackdrop';

const SheetBackdropFrame = styled(Stack, {
  name: SHEET_BACKDROP_NAME,

  backgroundColor: 'transparent',
  height: '100%',
  width: '100%',
  zIndex: '$overlay',

  '$platform-web': {
    position: 'fixed' as 'absolute',
    top: 0,
    left: 0,
  },
});

export const SheetBackdrop = SheetBackdropFrame.styleable<BlurViewProps>(({ children, ...props }, ref) => {
  const { onBackdropPress } = useSheetImperativeContext();

  return (
    <SheetBackdropFrame>
      {/*
        @NOTE BlurView will not animate if wrapped in a `styled`,
        this is due to conflicting `style` properties when rendering
        the component
      */}
      <BlurView
        ref={ref}
        onPress={onBackdropPress}
        tint="dark"
        intensity={30}
        animation="quick"
        enterStyle={{
          opacity: 0,
        }}
        exitStyle={{
          opacity: 0,
        }}
        opacity={1}
        position={isWeb ? ('fixed' as 'absolute') : 'absolute'}
        top={0}
        left={0}
        pointerEvents="auto"
        alignItems="center"
        justifyContent="center"
        zIndex="$overlay"
        {...props}
      >
        {!isWeb ? (
          // Native needs a quick little wrapper around the content to keep things consistent
          <Stack flex={1} centered>
            {children}
          </Stack>
        ) : (
          children
        )}
      </BlurView>
    </SheetBackdropFrame>
  );
});
