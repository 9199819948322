import { PropsWithChildren, ReactNode } from 'react';

import { useIntl } from 'react-intl';

import {
  Button,
  Card,
  CaretDownIcon,
  CloseIcon,
  Divider,
  EditIcon,
  FormAlertContainer,
  FormAlertText,
  GetProps,
  LegalIcon,
  Sheet,
  Stack,
  TitleText,
  UtilityText,
  ValueText,
  WarningIcon,
  styled,
  withStaticProperties,
} from '@arrived/bricks';

import { ProgressBar } from './ProgressBar';

const ExpandableFooterMain = styled(Stack, {
  '$platform-web': { position: 'sticky' as 'absolute', bottom: 0 },
  zIndex: '$docked',
});

interface ExpandableFooterAlertProps {
  alertText: ReactNode;
  handleOnClosePress: () => void;
}

/**
 * Pre-filled FormAlertContainer to be placed as a child inside the `ExpandableSheetFooter`.
 *
 * Defaults to error styling, but an `info` prop can be added, along with any other FormAlertContainer props.
 */
const ExpandableFooterAlert = FormAlertContainer.styleable<
  GetProps<typeof FormAlertContainer> & ExpandableFooterAlertProps
>((propsIn, ref) => {
  const { alertText, handleOnClosePress, ...rest } = propsIn;

  return (
    <FormAlertContainer ref={ref} {...rest}>
      <WarningIcon size="sm" />
      <FormAlertText flex={1}>{alertText}</FormAlertText>
      <Button condensed variant="ghost" alignSelf="flex-end" onPress={handleOnClosePress} Icon={CloseIcon} />
    </FormAlertContainer>
  );
});

interface ExpandableFooterBodyProps {
  footerHeight: number;
  progressPercent?: number;
  isFullScreen: boolean;
}

/**
 * Primary container within the `ExpandableSheetFooter`; adjacent child to alerts, parent to all other content.
 *
 * If provided a `progressPercent` prop, it will show (and animate) a progress bar at the top of the body.
 */
const ExpandableFooterBody = ({
  footerHeight,
  progressPercent,
  isFullScreen,
  children,
}: PropsWithChildren<ExpandableFooterBodyProps>) => {
  return (
    <Card
      justifyContent="flex-end"
      br={0}
      className="modal-footer"
      height={footerHeight}
      $platform-web={{
        animation: 'smoothSpring',
      }}
    >
      {progressPercent && (
        <ProgressBar
          progress={progressPercent}
          h="$1"
          bg="$onSurface.neutral.containerAlt"
          w="100%"
          progressStyle={{
            bg: '$surface.positive.default',
          }}
        />
      )}
      <Sheet.Footer
        className="expandable-footer-container"
        flex={1}
        containerProps={{ flex: 1 }}
        backgroundColor="transparent" // overrides a default grey background applied from the style context
      >
        <Stack
          flex={1}
          animation="quick"
          gap={isFullScreen ? '$4' : 0}
          position="relative"
          justifyContent={isFullScreen ? 'space-between' : 'flex-end'}
        >
          {children}
        </Stack>
      </Sheet.Footer>
    </Card>
  );
};

interface ExpandableFooterInnerSummaryProps {
  isFooterToggleable?: boolean;
  handleOnClosePress: () => void;
  innerSummaryTitle: ReactNode;
  useStandardScrollView?: boolean;
}

/**
 * Wrapper for the expandable inner content of the footer. Child of the Body component.
 */
const ExpandableFooterInnerSummary = ({
  isFooterToggleable,
  handleOnClosePress,
  innerSummaryTitle,
  useStandardScrollView,
  children,
}: PropsWithChildren<ExpandableFooterInnerSummaryProps>) => {
  const intl = useIntl();

  return (
    <Stack flex={1} className="expandable-footer-inner-summary-container">
      <Stack className="expandable-footer-inner-summary-content" gap="$6" flex={1}>
        <Stack row justifyContent="space-between" alignItems="center">
          <TitleText
            data-e2e="checkout-summary-title"
            tag="h2"
            token="title.heading.small"
            color="$onSurface.neutral.defaultInverted"
          >
            {innerSummaryTitle}
          </TitleText>
          {!isFooterToggleable && (
            <Button
              inverted
              condensed
              variant="ghost"
              aria-label={intl.formatMessage({ id: 'checkout.summary.edit-button.label' })}
              Icon={EditIcon}
              onPress={handleOnClosePress}
            />
          )}
        </Stack>
        <Sheet.ScrollView
          indicatorStyle="white"
          p={0}
          $gtXs={{ p: 0 }} // need to override specific default media queries
          $gtSm={{ p: 0 }} // need to override specific default media queries
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'space-between',
          }}
          useStandardScrollView={useStandardScrollView}
        >
          <Stack flex={1} gap="$6">
            {children}
            <Divider solid />
          </Stack>
        </Sheet.ScrollView>
      </Stack>
    </Stack>
  );
};

interface ExpandableFooterTakeoverProps {
  handleTakeoverToggle: () => void;
  takeoverLabel: ReactNode;
  useStandardScrollView?: boolean;
}

/**
 * Separate from the expandable inner body, an optional full-sheet takeover wrapper.
 *
 * Primarily used for showing legal disclaimer content.
 */
const ExpandableFooterTakeover = ({
  handleTakeoverToggle,
  takeoverLabel,
  useStandardScrollView,
  children,
}: PropsWithChildren<ExpandableFooterTakeoverProps>) => {
  const intl = useIntl();

  return (
    <Stack flex={1} gap="$2" className="expandable-footer-takeover-container">
      <Stack row justifyContent="space-between" alignItems="center">
        <UtilityText token="utility.label.small" color="$interactive.neutral.restedInverted">
          {takeoverLabel}
        </UtilityText>
        <Button
          condensed
          inverted
          isIconOnly
          variant="ghost"
          aria-label={intl.formatMessage({ id: 'checkout.summary.legalDisclaimerCloseButtonDescription' })}
          Icon={CloseIcon}
          onPress={handleTakeoverToggle}
        />
      </Stack>
      <Stack flex={1}>
        <Sheet.ScrollView
          indicatorStyle="white"
          p={0}
          $gtXs={{ p: 0 }} // need to override specific default media queries
          $gtSm={{ p: 0 }} // need to override specific default media queries
          contentContainerStyle={{
            flexGrow: 1,
          }}
          useStandardScrollView={useStandardScrollView}
        >
          {children}
        </Sheet.ScrollView>
      </Stack>
    </Stack>
  );
};

interface ExpandableFooterSummaryProps {
  isFooterToggleable: boolean;
  isSummaryExpanded: boolean;
  handleSummaryToggle: () => void;
  handleTakeoverToggle: () => void;
  footerSummaryLabel: ReactNode;
  valueText: ReactNode;
  hasTakeover?: boolean;
}

/**
 * The always-visible lower summary section.
 *
 * Contains the controls for expanding either the inner body or the full takeover, if applicable.
 */
const ExpandableFooterSummary = ({
  isFooterToggleable,
  isSummaryExpanded,
  handleSummaryToggle,
  handleTakeoverToggle,
  footerSummaryLabel,
  valueText,
  hasTakeover,
}: ExpandableFooterSummaryProps) => {
  const intl = useIntl();

  return (
    <Stack row className="expandable-footer-summary-container" justifyContent="space-between" alignItems="center">
      {isFooterToggleable ? (
        <Button
          variant="ghost"
          condensed
          alignItems="center"
          aria-label={intl.formatMessage({ id: 'checkout.summary.investmentSummaryButtonDescription' })}
          onPress={handleSummaryToggle}
        >
          <Stack row alignItems="center" gap="$1" h="$8">
            {footerSummaryLabel}
            <Stack row animation="quick" transform={[{ rotateX: isSummaryExpanded ? '0deg' : '180deg' }]}>
              <CaretDownIcon color="$onSurface.neutral.defaultInverted" />
            </Stack>
          </Stack>
        </Button>
      ) : (
        footerSummaryLabel
      )}
      <Stack row centered gap="$1">
        <ValueText
          data-e2e="expandable-footer-summary-value"
          token="value.large"
          color="$onSurface.neutral.defaultInverted"
        >
          {valueText}
        </ValueText>
        {hasTakeover && (
          <Button
            variant="ghost"
            aria-label={intl.formatMessage({ id: 'checkout.summary.legalDisclaimerButtonDescription' })}
            onPress={handleTakeoverToggle}
            Icon={() => <LegalIcon color="$onSurface.neutral.defaultInverted" />}
            isIconOnly
          />
        )}
      </Stack>
    </Stack>
  );
};

interface ExpandableFooterButtonProps {
  activeIndex: string | number;
}

/**
 * Simple Button wrapper to be used below the footer summary.
 */
const ExpandableFooterButton = Button.styleable<GetProps<typeof Button> & ExpandableFooterButtonProps>(
  (propsIn, ref) => {
    const { activeIndex, children, ...rest } = propsIn;

    return (
      <Stack
        className="expandable-footer-button-container"
        data-e2e={`expandable-footer-button-container-index-${activeIndex}`}
        gap="$4"
      >
        <Button ref={ref} data-e2e="expandable-footer-continue-button" {...rest}>
          {children}
        </Button>
      </Stack>
    );
  },
);

/**
 * Expandable footer to be used in multi-screen sheet experiences (e.g. checkout, redemptions).
 *
 * Wraps the `Sheet.Footer` in a `Card` and offers some optional expandable sections and a progress bar.
 *
 * See implementations for more details, but below is the basic expected architecture for these.
 * The Alert and Body are siblings, the InnerSummary and Takeover will expand to fill the inside
 * of the Body, and the Summary and Button sit at the bottom & can be wrapped in a Stack for
 * additional styling/layout preferences.
 *
 * ```
 * <ExpandableSheetFooter>
 *   <ExpandableSheetFooter.Alert />
 *
 *   <ExpandableSheetFooter.Body>
 *     <ExpandableSheetFooter.InnerSummary />
 *     <ExpandableSheetFooter.Takeover />
 *
 *     <Stack>
 *       <ExpandableSheetFooter.Summary />
 *       <ExpandableSheetFooter.Button />
 *     </Stack>
 *   </ExpandableSheetFooter.Body>
 * </ExpandableSheetFooter>
 * ```
 */
export const ExpandableSheetFooter = withStaticProperties(ExpandableFooterMain, {
  Alert: ExpandableFooterAlert,
  Body: ExpandableFooterBody,
  Summary: ExpandableFooterSummary,
  InnerSummary: ExpandableFooterInnerSummary,
  Takeover: ExpandableFooterTakeover,
  Button: ExpandableFooterButton,
});
