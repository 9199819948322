import { useMutation, useQueryClient } from '@tanstack/react-query';
import _assign from 'lodash/assign';

import { deletePropertyPhoto } from '@arrived/api_v2';
import { Photo, Property, PropertyId } from '@arrived/models';

import { propertyKeyFn } from './properties.keys';

export interface PropertyPhotoDeleteRequest {
  photoId: number;
  propertyId: PropertyId;
}

export function useDeletePropertyPhotoMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePropertyPhoto,
    onError: (_, context) => {
      const propertyKey = propertyKeyFn(context.propertyId);

      const property = queryClient.getQueryData(propertyKey) as Property;

      queryClient.setQueryData(propertyKey, _assign({}, property, { photos: context }));
    },
    onMutate: (data) => {
      const propertyKey = propertyKeyFn(data.propertyId);

      const property = queryClient.getQueryData(propertyKey) as Property;
      const newPhotos = property.photos?.filter((photo) => photo.id !== data.photoId);

      // Since merge does array merging as well for array properties, we need to omit the photos from the source
      // property object so that we only get the new photo array.
      queryClient.setQueryData(propertyKey, _assign({}, property, { photos: newPhotos }));

      return ([] as Photo[]).concat(property.photos ?? []);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: propertyKeyFn(data.id) });
    },
  });
}
