import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export interface SectionOverlayContext {
  openedId: string | null;
  setOpenedId: Dispatch<SetStateAction<string | null>>;
}

export const SectionOverlayContext = createContext<SectionOverlayContext>({ openedId: null, setOpenedId: () => {} });

export const useSectionOverlayContext = () => {
  const context = useContext(SectionOverlayContext);

  if (!context) {
    throw new Error('useSectionOverlayContext must be used within an SectionOverlayContext.Provider');
  }

  return context;
};
