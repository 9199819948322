import { useQuery } from '@tanstack/react-query';

import { getAccountBalance } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../../utils';
import { balanceKeyFn } from '../accounts.keys';

export function useGetAccountBalanceQuery(
  accountId?: AccountId,
  options?: UseAwaitedQueryOptions<typeof getAccountBalance>,
) {
  // This example assumes `getAccountBalance` does not need to be defined within the hook.
  // If it does, you might need to use `useCallback` to memoize it.

  return useQuery({
    queryKey: balanceKeyFn(accountId!),
    queryFn: () => getAccountBalance(accountId!),
    // Set default stale time and spread the rest of the options.
    // `enabled` will default to true if not provided.
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}
