import { memo } from 'react';

import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function EqualsIconInternal({ size, color, fill = 'none', ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" {...rest}>
      <G clipPath="url(#clip0_1520_5092)">
        <Path
          d="M9.99988 20.0001C15.5229 20.0001 19.9999 15.5231 19.9999 10.0001C19.9999 4.47712 15.5229 0.00012207 9.99988 0.00012207C4.47688 0.00012207 -0.00012207 4.47712 -0.00012207 10.0001C-0.00012207 15.5231 4.47688 20.0001 9.99988 20.0001Z"
          fill={fill}
        />
        <Path d="M5.83337 7.91675H14.1667" stroke={color} />
        <Path d="M5.83337 12.0834H14.1667" stroke={color} />
      </G>
      <Defs>
        <ClipPath id="clip0_1520_5092">
          <Rect width="20" height="20" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export const EqualsIcon = memo<IconProps>(withIconTheme(EqualsIconInternal));
