import { assumedDispositionCostsPercent } from './constants';

export interface CalculateCumulativeReturnsArguments {
  /**
   * If there is additional equity to add back into the asset value when it's sold, include it here. For example, money
   * set aside for property improvements and cash reserves.
   */
  additionalEquity?: number;
  /**
   * The yearly appreciation of the asset.
   */
  annualAppreciation: number;
  /**
   * The value of the underlying asset in the Offering at the beginning of the investment.
   */
  assetValue: number;
  /**
   * The costs associated with the sale of the asset as a percentage of the value of the asset. If omitted the default
   * value of `assumedDispositionCostsPercent` is used.
   */
  dispositionCostsPercent?: number;
  /**
   * The investment term of the Offering in years.
   */
  investmentTerm: number;
  /**
   * The value of the loan taken out on the asset, this will be deducted from the total value of equity at the time of
   * sale.
   */
  loanAmount?: number;
  /**
   * The total equity of the Offering. This would incorporate both the funds raised from investors and the loan on the
   * Offering if one exists.
   */
  totalEquityValue: number;
  /**
   * The yearly dividends that the asset produces, can be computed using projectedYearlyDividends. Should be an array of
   * length `investmentTerm`.
   */
  yearlyDividends: number[];
}

/**
 * Calculates the cumulative ROI on an investment for each year of the investment term starting at the beginning of the
 * investment period. The returned array will be of length `investmentTerm + 1` so that the value at index 0 is the
 * value of the investment immediately after it is made.
 */
export function calculateCumulativeReturns({
  additionalEquity = 0,
  annualAppreciation,
  assetValue,
  dispositionCostsPercent = assumedDispositionCostsPercent,
  investmentTerm,
  loanAmount = 0,
  totalEquityValue,
  yearlyDividends,
}: CalculateCumulativeReturnsArguments) {
  const fees = totalEquityValue - assetValue - additionalEquity;
  // Get the cumulative dividends array where year X is the sum of all the dividends from years 0..X
  const cumulativeDividends = [0, ...yearlyDividends].reduce((sums, curr, index) => {
    return index === 0 ? [curr] : [...sums, sums[sums.length - 1] + curr];
  }, [] as number[]);

  return [...Array(investmentTerm + 1)].map((_, year) => {
    const propertyValue = assetValue * Math.pow(1 + annualAppreciation, year) * (1 - dispositionCostsPercent);
    // For computing the cumulative returns at each year, we prorate the fees of the investment. This value will be
    // added back to the equity value of the investment for the year being computed.
    const proratedFees = fees * ((investmentTerm - year) / investmentTerm);
    const equityValue = propertyValue + additionalEquity + proratedFees - loanAmount;

    return equityValue + cumulativeDividends[year];
  });
}
