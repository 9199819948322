import { useMemo } from 'react';

import { Box, Stack, StackProps, alpha } from '@mui/material';

import { getProjectedAppreciationRange } from '@arrived/common';
import { useReturnsCalculatorForm } from '@arrived/forms';
import { Offering } from '@arrived/models';
import { useGetZillowZipCodeAppreciationQuery } from '@arrived/queries';

import { CalculatorInputForm } from './CalculatorInputForm';
import { defaultAnnualRentalDividendYield, nationalTwentyYearAppreciation } from './constants';
import { HypotheticalReturnsTable, HypotheticalReturnsTableProps } from './HypotheticalReturnsTable';
import { ReturnsChart } from './ReturnsChart';
import { ReturnsDisplay } from './ReturnsDisplay';

export interface CalculatorProps extends StackProps {
  offering: Offering;
  TableComponent: HypotheticalReturnsTableProps['TableComponent'];
}

export const Calculator = ({ offering, TableComponent, ...rest }: CalculatorProps) => {
  const { cumulativeReturns, irr, form } = useReturnsCalculatorForm(offering, {
    defaultAnnualAppreciation: nationalTwentyYearAppreciation,
    defaultRentalDividendYield: defaultAnnualRentalDividendYield,
  });
  const zipCodeAppreciationState = useGetZillowZipCodeAppreciationQuery(offering.properties?.[0]?.address?.zipCode);
  const [projectedAppreciationLower, projectedAppreciationUpper] = useMemo(
    () => getProjectedAppreciationRange(zipCodeAppreciationState.data),
    [zipCodeAppreciationState.data],
  );

  const { watch } = form;

  return (
    <Stack
      bgcolor={({ palette }) => alpha(palette.darkened.midnight, 0.5)}
      borderRadius={1}
      overflow="hidden"
      {...rest}
    >
      <Stack px={2} py={2} direction={{ xs: 'column', xl: 'row' }} gap={2}>
        <CalculatorInputForm
          flex={0}
          minWidth="274px"
          pt={2}
          gap={2}
          form={form}
          offering={offering}
          zipCodeInfo={{
            zipCode: offering.properties[0]?.address?.zipCode,
            tenYearHistoricalAppreciation: projectedAppreciationUpper || undefined,
            twentyYearHistoricalAppreciation: projectedAppreciationLower || undefined,
          }}
        />
        <Box flex={1}>
          <Stack
            bgcolor={({ palette }) => palette.darkened.midnight}
            position="relative"
            px={2}
            py={3}
            gap={2}
            borderRadius={2}
            flexDirection={{ xs: 'column-reverse', xl: 'column' }}
          >
            <ReturnsChart investmentValues={cumulativeReturns} />

            <ReturnsDisplay
              direction="row"
              irr={irr}
              hypotheticalROI={cumulativeReturns[cumulativeReturns.length - 1]}
              justifyContent="space-between"
              gap={2}
            />
          </Stack>
        </Box>
      </Stack>
      <HypotheticalReturnsTable offering={offering} watch={watch} TableComponent={TableComponent} />
    </Stack>
  );
};
