import { AccountId, SuitabilityQuestionnaire, SuitabilityQuestionnaireRequest } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getUserAccountSuitabilityQuestionnaire = (accountId: AccountId) =>
  createQuery<SuitabilityQuestionnaire>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/suitability-questionnaire`,
  });

export const postUserAccountSuitabilityQuestionnaire = (requestData: SuitabilityQuestionnaireRequest) =>
  createQuery<SuitabilityQuestionnaire>({
    apiInstance: api,
    method: 'post',
    url: `/users/accounts/${requestData.accountId}/suitability-questionnaire`,
    requestData,
  });

export const putUserAccountSuitabilityQuestionnaire = (requestData: SuitabilityQuestionnaireRequest) =>
  createQuery<SuitabilityQuestionnaire>({
    apiInstance: api,
    method: 'put',
    url: `/users/accounts/${requestData.accountId}/suitability-questionnaire`,
    requestData,
  });

export const deleteUserAccountSuitabilityQuestionnaire = (accountId: AccountId) =>
  createQuery<SuitabilityQuestionnaire>({
    apiInstance: api,
    method: 'delete',
    url: `/users/accounts/${accountId}/suitability-questionnaire`,
  });
