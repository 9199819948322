import { OfferingFilter } from '@arrived/models';

export const offeringFilterToSearchParams = (filter: OfferingFilter) =>
  new URLSearchParams(
    Object.entries(filter).reduce(
      <K extends keyof OfferingFilter>(params: Record<string, string>, [key, value]: [K, OfferingFilter[K]]) => {
        if (Array.isArray(value) && value.length > 0) {
          return { ...params, [key]: value.map((value) => value.toString()) };
        } else if (value != null) {
          return { ...params, [key]: value.toString() };
        }
        return { ...params };
      },
      {},
    ),
  );
