export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Sort {
  field: string;
  direction: SortDirection;
}

export const convertSortToString = ({ direction, field }: Sort) => `${field}:${direction}`;
