import { useMemo } from 'react';

import {
  GetProps,
  Stack,
  StackProps,
  parseRootChildren,
  styled,
  useMedia,
  withStaticProperties,
} from '@arrived/bricks';

import { Booking } from './Booking';
import { ProductHeaderName, ProductHeaderTagName, ProductHeaderTitleName } from './constants';
import { Content } from './Content';
import { Details } from './Details';
import { ProductHeaderStyledContext } from './ProductHeaderStyledContext';
import { Tag } from './Tag';
import { Title } from './Title';
import { ProductHeaderTitleFrame } from './TitleWrapper';

const ProductHeaderFrame = styled(Stack, {
  name: ProductHeaderName,
  context: ProductHeaderStyledContext,

  gap: '$4',

  variants: {
    condensed: {
      true: {
        gap: '$3',
      },
    },
  } as const,
});

export const ProductHeader = withStaticProperties(
  ProductHeaderFrame.styleable<Omit<StackProps, 'children'>>(({ children: childrenIn, ...rest }, ref) => {
    const media = useMedia();

    const {
      [ProductHeaderTitleName]: Title,
      [ProductHeaderTagName]: Tag,
      children,
    } = useMemo(() => parseRootChildren(childrenIn, [ProductHeaderTitleName, ProductHeaderTagName]), [childrenIn]);

    return (
      <ProductHeaderFrame ref={ref} condensed={!media.gtXs} {...rest}>
        {(Title || Tag) && (
          <ProductHeaderTitleFrame>
            {Title}
            {Tag}
          </ProductHeaderTitleFrame>
        )}
        {!!children.length && <Content>{children}</Content>}
      </ProductHeaderFrame>
    );
  }),
  {
    Booking,
    Details,
    Tag,
    Title,
  },
);

export type ProductHeaderProps = GetProps<typeof ProductHeader>;
