import { StackProps } from '../../atoms';

export const getEmphasizedStyles = (isLoading?: boolean, isDisabled?: boolean): StackProps => {
  if (isLoading) {
    return {
      disabled: true,
      pointerEvents: 'none',
      bg: '$interactive.primary.focus',
    };
  }

  if (isDisabled) {
    return {
      disabled: true,
      pointerEvents: 'none',
      bg: '$interactive.primary.disabled',
    };
  }

  return {
    bg: '$interactive.primary.rested',
    focusStyle: {
      bg: '$interactive.primary.rested',
    },
    hoverStyle: {
      bg: '$interactive.primary.hovered',
    },
    pressStyle: {
      bg: '$interactive.primary.focus',
    },
  };
};
