import { CONFIG } from '@arrived/config';

import { AnswerBodyTextProps, FormattedAnswer } from './Answer';
import { Question, QuestionBodyTextProps } from './Question';
import { CommonQuestionType } from './types';

export const CommonQuestionsKeys = {
  HOW_ARRIVED_WORKS: 'HOW_ARRIVED_WORKS',
  EXPECTED_RETURNS: 'EXPECTED_RETURNS',
  PREPPING_VRS: 'PREPPING_VRS',
  DIVERSIFICATION: 'DIVERSIFICATION',
  INVESTING_HORIZON: 'INVESTING_HORIZON',
  SEEKING_TENANT: 'SEEKING_TENANT',
} as const;

export const CommonQuestionsConfig: Record<keyof typeof CommonQuestionsKeys, CommonQuestionType> = {
  HOW_ARRIVED_WORKS: {
    Answer: (props: AnswerBodyTextProps) => (
      <FormattedAnswer id="product-details.common-questions.how-arrived-works.description" {...props} />
    ),
    Question: (props: QuestionBodyTextProps) => (
      <Question id="product-details.common-questions.how-arrived-works.header" {...props} />
    ),
  },
  EXPECTED_RETURNS: {
    Answer: (props: AnswerBodyTextProps) => (
      <FormattedAnswer id="product-details.common-questions.expected-returns.description" {...props} />
    ),
    Question: (props: QuestionBodyTextProps) => (
      <Question id="product-details.common-questions.expected-returns.header" {...props} />
    ),
    link: `${CONFIG.helpUrl}articles/4953922-what-returns-can-i-expect-from-arrived-properties`,
  },
  PREPPING_VRS: {
    Answer: (props: AnswerBodyTextProps) => (
      <FormattedAnswer id="product-details.common-questions.preparing-vacation-rental.description" {...props} />
    ),
    Question: (props: QuestionBodyTextProps) => (
      <Question id="product-details.common-questions.preparing-vacation-rental.header" {...props} />
    ),
    link: `${CONFIG.helpUrl}articles/6531820-what-is-the-process-to-prepare-a-vacation-rental-for-bookings`,
  },
  DIVERSIFICATION: {
    Answer: (props: AnswerBodyTextProps) => (
      <FormattedAnswer id="product-details.common-questions.diversification.description" {...props} />
    ),
    Question: (props: QuestionBodyTextProps) => (
      <Question id="product-details.common-questions.diversification.header" {...props} />
    ),
  },
  INVESTING_HORIZON: {
    Answer: (props: AnswerBodyTextProps) => (
      <FormattedAnswer id="product-details.common-questions.investing-horizon.description" {...props} />
    ),
    Question: (props: QuestionBodyTextProps) => (
      <Question id="product-details.common-questions.investing-horizon.header" {...props} />
    ),
  },
  SEEKING_TENANT: {
    Answer: (props: AnswerBodyTextProps) => (
      <FormattedAnswer id="product-details.common-questions.seeking-tenant.description" {...props} />
    ),
    Question: (props: QuestionBodyTextProps) => (
      <Question id="product-details.common-questions.seeking-tenant.header" {...props} />
    ),
    link: `${CONFIG.helpUrl}articles/5935265-what-to-expect-if-my-property-is-still-seeking-a-tenant`,
  },
};
