import { Address } from '@arrived/models';

import { LocationUtils } from './LocationUtils';
import { StringUtils } from './stringUtils';

export const parseFullAddress = (address: Address) => {
  return [
    StringUtils.trimToEmpty(address.street),
    StringUtils.trimToEmpty(address.city),
    LocationUtils.getProvinceShortName(address.province),
    StringUtils.trimToEmpty(address.country),
    StringUtils.trimToEmpty(address.zipCode),
  ]
    .filter((s) => StringUtils.isNotBlank(s))
    .join(' ');
};
