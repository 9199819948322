import { useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { format } from 'date-fns';

import { Button, ButtonProps, styled } from '@arrived/bricks';
import { Constants, OfferingTransactState, UserOfferingTransactState, getTimezoneAgnosticDate } from '@arrived/common';
import { Offering, SecurityType } from '@arrived/models';

import { PRODUCT_WIDGET_BUTTON_NAME } from './constants';
import { useProductWidgetContext } from './ProductWidgetContext';
import { useProductWidgetStyledContext } from './ProductWidgetStyledContext';

type ProductWidgetButtonProps = Omit<ButtonProps, 'onPress'> & {
  onPress: (userOfferingTransactState: UserOfferingTransactState) => void;
  userOfferingTransactState: UserOfferingTransactState;
  arePushNotificationsEnabled?: boolean;
};

const StyledButton = styled(Button, {
  name: PRODUCT_WIDGET_BUTTON_NAME,
});

interface IsNotifyMeDisabledForOfferingArgs {
  arePushNotificationsEnabled?: boolean;
  offering: Offering;
}

/**
 * Indicates if the "Notify Me" option is disabled for an Offering. Not a complicated function but
 * the same logic is being repeated in multiple places and it's important that they all return the
 * same value.
 */
const isNotifyMeDisabledForOffering = ({ arePushNotificationsEnabled, offering }: IsNotifyMeDisabledForOfferingArgs) =>
  offering.securityType === SecurityType.REG_D || arePushNotificationsEnabled;

interface UseIsProductWidgetButtonDisabledProps {
  arePushNotificationsEnabled?: boolean;
  offering: Offering;
  userOfferingTransactState: UserOfferingTransactState;
}

const useIsProductWidgetButtonDisabled = ({
  arePushNotificationsEnabled,
  offering,
  userOfferingTransactState,
}: UseIsProductWidgetButtonDisabledProps) => {
  const isDisabledTransactionState = useMemo(() => {
    return !(
      [
        UserOfferingTransactState.NEEDS_LOGIN,
        UserOfferingTransactState.SETUP_ACCOUNT,
        UserOfferingTransactState.VERIFICATION_REQUIRED,
        UserOfferingTransactState.COMING_SOON,
        UserOfferingTransactState.PRE_LAUNCH,
        UserOfferingTransactState.TRANSACT,
        UserOfferingTransactState.ACCREDITATION_STATUS_UPDATE_REQUIRED,
      ] as UserOfferingTransactState[]
    ).includes(userOfferingTransactState);
  }, [userOfferingTransactState]);

  const isDisabledSpecialConditions = useMemo(() => {
    // In the pre-funding conditions in which we'd normally have a Notify Me button, we want to
    // disable the button for Reg D Offerings as they do not have the Notify Me ability due to the
    // nature of the confidentiality of these Offerings.
    //
    // Note that if you're changing the constraints here, you should make sure the content of the
    // button is also appropriate for the disabled state.
    return (
      (
        [UserOfferingTransactState.COMING_SOON, UserOfferingTransactState.PRE_LAUNCH] as UserOfferingTransactState[]
      ).includes(userOfferingTransactState) && isNotifyMeDisabledForOffering({ arePushNotificationsEnabled, offering })
    );
  }, [arePushNotificationsEnabled, offering, userOfferingTransactState]);

  return useMemo(
    () => isDisabledSpecialConditions || isDisabledTransactionState,
    [isDisabledSpecialConditions, isDisabledTransactionState],
  );
};

const OfferingTransactStatusText = ({
  userOfferingTransactState,
  arePushNotificationsEnabled,
}: {
  userOfferingTransactState: UserOfferingTransactState;
  arePushNotificationsEnabled?: boolean;
}) => {
  const { offering } = useProductWidgetContext();

  switch (userOfferingTransactState) {
    case UserOfferingTransactState.NEEDS_LOGIN:
      return <FormattedMessage id="product-details.cta.signup-to-invest" />;
    case UserOfferingTransactState.SETUP_ACCOUNT:
    case UserOfferingTransactState.VERIFICATION_REQUIRED:
      return <FormattedMessage id="product-details.cta.complete-account-setup" />;
    case UserOfferingTransactState.COMING_SOON:
    case UserOfferingTransactState.PRE_LAUNCH:
      if (isNotifyMeDisabledForOffering({ arePushNotificationsEnabled, offering })) {
        if (offering.ipoDate != null) {
          return (
            <FormattedMessage
              id="product-details.cta.coming-date"
              values={{
                date: format(getTimezoneAgnosticDate(offering.ipoDate.toString()), 'MMMM d'),
              }}
            />
          );
        } else {
          return <FormattedMessage id="common.coming-soon" />;
        }
      }

      if (arePushNotificationsEnabled === true) {
        return <FormattedMessage id="common.coming-soon" />;
      }

      return <FormattedMessage id="product-details.cta.notify-me" />;
    case UserOfferingTransactState.MAXED_OUT:
      return <FormattedMessage id="product-details.cta.maxed-out" />;
    case UserOfferingTransactState.TRANSACT:
      return <FormattedMessage id="product-details.cta.invest" />;
    case UserOfferingTransactState.PAUSED:
    case UserOfferingTransactState.BLOCKED:
      return <FormattedMessage id="product-details.cta.trading-paused" />;
    case UserOfferingTransactState.UNACCREDITED:
      return <FormattedMessage id="product-details.cta.unaccredited" />;
    case UserOfferingTransactState.ACCREDITATION_STATUS_UPDATE_REQUIRED:
      return <FormattedMessage id="product-details.cta.update-status" />;
    case UserOfferingTransactState.UNKNOWN:
    case UserOfferingTransactState.FUNDED:
    case UserOfferingTransactState.LOADING:
    default:
      return null;
  }
};

export const ProductWidgetButton = StyledButton.styleable<ProductWidgetButtonProps>(
  ({ userOfferingTransactState, onPress, arePushNotificationsEnabled, ...rest }, ref) => {
    const { condensed, status } = useProductWidgetStyledContext();
    const { offering } = useProductWidgetContext();

    const isDisabled = useIsProductWidgetButtonDisabled({
      arePushNotificationsEnabled,
      offering,
      userOfferingTransactState,
    });

    const buttonClassName = useMemo(() => {
      // In the pre-funding conditions in which we'd normally have a Notify Me button, we want to
      // disable the button for STNs as they do not have the Notify Me ability due to the nature of
      // their sell-out timeframes (which are very fast).
      if (isNotifyMeDisabledForOffering({ arePushNotificationsEnabled, offering })) {
        return;
      }

      return (
        [UserOfferingTransactState.COMING_SOON, UserOfferingTransactState.PRE_LAUNCH] as UserOfferingTransactState[]
      ).includes(userOfferingTransactState)
        ? Constants.notifyMeButtonClassName
        : undefined;
    }, [offering, userOfferingTransactState]);

    const display = useMemo(
      () =>
        status === OfferingTransactState.CLOSED ||
        ([UserOfferingTransactState.UNKNOWN, UserOfferingTransactState.FUNDED] as UserOfferingTransactState[]).includes(
          userOfferingTransactState,
        )
          ? 'none'
          : undefined,
      [status, userOfferingTransactState],
    );

    const handleOnPress = useCallback(() => onPress(userOfferingTransactState), [onPress, userOfferingTransactState]);

    return (
      <Button
        ref={ref}
        disabled={isDisabled}
        loading={userOfferingTransactState === UserOfferingTransactState.LOADING}
        className={buttonClassName}
        onPress={handleOnPress}
        variant={status === OfferingTransactState.IN_PROGRESS ? 'emphasized' : undefined}
        display={display}
        width={condensed ? undefined : '100%'}
        {...rest}
      >
        <OfferingTransactStatusText
          userOfferingTransactState={userOfferingTransactState}
          arePushNotificationsEnabled={arePushNotificationsEnabled}
        />
      </Button>
    );
  },
);
