import { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import _merge from 'lodash/merge';

import { getOfferingsSearch } from '@arrived/api_v2';
import { OfferingId, OfferingSearchQuery } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../../utils';
import { offeringsKeyFn } from '../offerings.keys';

/**
 * Given a query, this hook will return a function that indicates if a provided Offering (by id) is in the set of
 * Offerings returned by the provided query. If the query is still loading, the function returns `undefined`, otherwise
 * it returns a boolean indicating if the Offering is in the queried set.
 */
export function useIsOfferingInSet(
  query: Omit<OfferingSearchQuery, 'fields'>,
  options?: UseAwaitedQueryOptions<typeof getOfferingsSearch>,
) {
  const modifiedQuery = useMemo(() => _merge({}, query, { fields: ['id'] }), [query]);

  const { data: offeringIds } = useQuery({
    queryKey: offeringsKeyFn(modifiedQuery),
    queryFn: () => getOfferingsSearch(modifiedQuery),
    staleTime: MAX_STALE_TIME,
    ...options,
    select: (offeringData) => offeringData?.data.map((offering) => offering.id!),
  });

  return useCallback(
    (offeringId: OfferingId) => (offeringIds != null ? offeringIds.includes(offeringId) : undefined),
    [offeringIds],
  );
}
