import { PropsWithChildren, useContext } from 'react';
import { Dimensions, Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { SafeAreaInsetsContext, SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context';

import { isWeb } from '@tamagui/core';

const { width = 0, height = 0 } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  // @ts-expect-error -- technically display block isn't real. but it is!
  web: {
    ...Platform.select({
      web: {
        display: 'contents',
      },
    }),
  },
});

// To support SSR on web, we need to have empty insets for initial values
// Otherwise there can be mismatch between SSR and client output
// We also need to specify empty values to support tests environments
const initialMetrics =
  isWeb || initialWindowMetrics == null
    ? {
        frame: { x: 0, y: 0, width, height },
        insets: { top: 0, left: 0, right: 0, bottom: 0 },
      }
    : initialWindowMetrics;

export function SafeAreaProviderCompat({ children, style }: PropsWithChildren<{ style?: StyleProp<ViewStyle> }>) {
  const insets = useContext(SafeAreaInsetsContext);

  if (insets) {
    // If we already have insets, don't wrap the stack in another safe area provider
    // This avoids an issue with updates at the cost of potentially incorrect values
    // https://github.com/react-navigation/react-navigation/issues/174
    return <View style={[styles.container, style]}>{children}</View>;
  }

  return (
    <SafeAreaProvider initialMetrics={initialMetrics} style={[styles.web, style]}>
      {children}
    </SafeAreaProvider>
  );
}

SafeAreaProviderCompat.initialMetrics = initialMetrics;
