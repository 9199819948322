/**
 * The OfferingDisplayState is how the Offering should be advertised to users on the invest page
 * and includes more specificity to the state of the Offering than the OfferingTransactState.
 */
export const OfferingDisplayState = {
  AVAILABLE: 'AVAILABLE',
  SOLD_OUT: 'SOLD_OUT',
  CLOSE: 'CLOSE',
  COMING_SOON: 'COMING_SOON',
  NEW: 'NEW',
  PAUSED: 'PAUSED',
} as const;

export type OfferingDisplayState = (typeof OfferingDisplayState)[keyof typeof OfferingDisplayState];
