import { Children, useCallback, useMemo } from 'react';

import { FormattedMessage, FormattedNumber, FormattedPlural, useIntl } from 'react-intl';

import { differenceInMonths, format } from 'date-fns';

import { DataPoint, DataPointProps, GetProps, InfoBox, RootTextProps, Row, Stack, useMedia } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { Offering, OfferingTransaction, STNMetadata } from '@arrived/models';

import { ProductDetailContent } from '../../content';
import { ProductHeader, ProductHeaderProps } from '../../productHeader';
import {
  AnswerText,
  CommonQuestionsProps,
  CommonQuestionsSection,
  DocumentsSection,
  QuestionTitle,
  TransactionHistorySection,
} from '../components';
import { IntercomChatCallout } from '../components/callouts';
import { HeaderDetailsStackProps } from '../ProductDetailParts';

export type ShortTermNoteProps = {
  offering: Offering;
  stnMetadata?: STNMetadata;
  transactions?: OfferingTransaction[];
};

export const ShortTermNoteHeader = ({
  children,
  offering,
  stnMetadata: _1,
  transactions: _2,
  ...rest
}: ShortTermNoteProps & ProductHeaderProps) => (
  <ProductHeader {...rest}>
    <ProductHeader.Title>{offering.name}</ProductHeader.Title>
    <ProductHeader.Tag offering={offering} />
    {children}
  </ProductHeader>
);

const HeaderDetailsDataPoint = <T extends boolean>({
  $gtSm,
  ...rest
}: Omit<DataPointProps<T>, 'colors' | 'variant'>) => (
  <DataPoint colors="dark" variant="minimized" $gtSm={{ flexBasis: 'auto', ...$gtSm }} {...rest} />
);

export const ShortTermNoteHeaderDetails = ({
  offering,
  stnMetadata: _1,
  transactions: _2,
  ...rest
}: ShortTermNoteProps & HeaderDetailsStackProps) => {
  const media = useMedia();

  // This is a temporary thing since we only have a single fixed rate investment for now
  const fixedRateInvestment = useMemo(() => {
    const firstValue = offering.fixedRateInvestments?.[0];

    if (!firstValue) {
      return null;
    }

    const matureAt = new Date(firstValue.matureAt);
    const interestAccrualStartAt = new Date(firstValue.interestAccrualStartAt);

    const noteDuration = differenceInMonths(matureAt, interestAccrualStartAt);

    return {
      ...firstValue,
      noteDuration,
      interestAccrualStartAt,
      matureAt,
    };
  }, [offering]);

  if (!fixedRateInvestment) {
    return null;
  }

  return (
    <Stack {...rest}>
      <ProductDetailContent border="always">
        <ProductDetailContent.Body row flexWrap="wrap">
          <HeaderDetailsDataPoint
            flexShrink={1}
            // We are just doing this for the first as this one has the potential to make the table look funky on smaller screens
            $gtXs={{ flexGrow: 1 }}
            tooltip={{
              content: <FormattedMessage id="product-details.annualized-yield.tooltip" />,
              title: <FormattedMessage id="product-details.annualized-yield" />,
            }}
          >
            {fixedRateInvestment.yieldPercent && (
              <DataPoint.Value>
                <FormattedNumber style="percent" maximumFractionDigits={3} value={fixedRateInvestment.yieldPercent} />
              </DataPoint.Value>
            )}
            <DataPoint.Label>
              <FormattedMessage id="product-details.annualized-yield" />
            </DataPoint.Label>
          </HeaderDetailsDataPoint>

          <ProductDetailContent.Divider.Vertical solid alt height={36} />

          <HeaderDetailsDataPoint
            flexGrow={1}
            tooltip={{
              content: <FormattedMessage id="product-details.term.tooltip" />,
              title: <FormattedMessage id="product-details.term" />,
            }}
          >
            <DataPoint.Value>
              <FormattedMessage
                id="product-details.term-label"
                values={{
                  noteDuration: fixedRateInvestment.noteDuration,
                  suffix: <FormattedPlural value={fixedRateInvestment.noteDuration} one="month" other="months" />,
                }}
              />
            </DataPoint.Value>
            <DataPoint.Label>
              <FormattedMessage id="product-details.term" />
            </DataPoint.Label>
          </HeaderDetailsDataPoint>

          {media.gtXxs && <ProductDetailContent.Divider.Vertical solid alt height={36} />}

          <HeaderDetailsDataPoint
            flexGrow={1}
            tooltip={{
              content: <FormattedMessage id="product-details.term.tooltip" />,
              title: <FormattedMessage id="product-details.term" />,
            }}
          >
            <DataPoint.Value>{format(fixedRateInvestment.matureAt, 'MMMM d, yyyy')}</DataPoint.Value>
            <DataPoint.Label>
              <FormattedMessage id="product-details.maturity-date" />
            </DataPoint.Label>
          </HeaderDetailsDataPoint>

          <ProductDetailContent.Divider.Vertical solid alt height={36} />

          <HeaderDetailsDataPoint flexGrow={1}>
            <DataPoint.Value>
              <FormattedNumber
                currency="USD"
                style="currency"
                maximumFractionDigits={2}
                minimumFractionDigits={0}
                value={offering.minTransactionAmount}
              />
            </DataPoint.Value>
            <DataPoint.Label>
              <FormattedMessage id="product-details.minimum-investment" />
            </DataPoint.Label>
          </HeaderDetailsDataPoint>
        </ProductDetailContent.Body>
      </ProductDetailContent>
    </Stack>
  );
};

const STNCommonQuestionAnswer = ({ offering, children, ...rest }: { offering: Offering } & RootTextProps) => {
  const intl = useIntl();

  const maxInvestmentValue = useMemo(() => {
    if (offering.maxSharesPerAccount != null) {
      return offering.maxSharesPerAccount * offering.sharePrice;
    }

    // 9.8% (or 0.098) is the default value for how much an investor can own of an STN
    return Math.floor(offering.totalShares * 0.098) * offering.sharePrice;
  }, [offering]);

  const Text = useCallback(
    ({ children, ...passedRest }: GetProps<typeof ProductDetailContent.Text>) => (
      // The props passed from Markdown should override the ones passed to Answer directly.
      <AnswerText {...rest} {...passedRest}>
        {Children.map(children, (child) =>
          typeof child === 'string'
            ? // This is a bit of a hack since we can't use `FormattedMessage` here
              // just go through the string and replace the placeholders with the correct values.
              // We should have a better way of doing this.
              child
                .replace(
                  '{minInvestmentValue}',
                  intl.formatNumber(offering.sharePrice, {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }),
                )
                .replace(
                  '{maxInvestmentValue}',
                  intl.formatNumber(maxInvestmentValue, {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }),
                )
            : child,
        )}
      </AnswerText>
    ),
    [rest],
  );

  return <Markdown Text={Text}>{children}</Markdown>;
};

const STNCommonQuestions = ({
  offering,
  commonQuestions,
}: {
  offering: Offering;
  commonQuestions: STNMetadata['commonQuestions'];
}) => {
  const questions = useMemo<CommonQuestionsProps['questions']>(
    () =>
      commonQuestions.map(({ question, answer }) => ({
        Question: (props) => <QuestionTitle {...props}>{question}</QuestionTitle>,
        Answer: (props) => (
          <STNCommonQuestionAnswer offering={offering} {...props}>
            {answer}
          </STNCommonQuestionAnswer>
        ),
      })),
    [commonQuestions],
  );

  return (
    <CommonQuestionsSection questions={questions}>
      <IntercomChatCallout />
    </CommonQuestionsSection>
  );
};

export const ShortTermNoteContent = ({ offering, stnMetadata, transactions }: ShortTermNoteProps) => {
  const stnContentDescription = useMemo(() => {
    if (offering.description) {
      return offering.description.replaceAll('{br}', '\n');
    }

    if (stnMetadata?.about) {
      return stnMetadata.about;
    }

    return '';
  }, [offering.description, stnMetadata?.about]);

  return (
    <>
      <ProductDetailContent>
        <ProductDetailContent.Header>
          <FormattedMessage id="common.about" />
        </ProductDetailContent.Header>
        <ProductDetailContent.Body>
          <Markdown Text={ProductDetailContent.Text}>{stnContentDescription}</Markdown>
        </ProductDetailContent.Body>
      </ProductDetailContent>
      {transactions && Boolean(transactions?.length) && <TransactionHistorySection transactions={transactions} />}
      {stnMetadata?.programThesis && (
        <ProductDetailContent>
          <ProductDetailContent.Header>
            <FormattedMessage id="product-details.program-thesis" />
          </ProductDetailContent.Header>
          <ProductDetailContent.Body>
            <Markdown>{stnMetadata.programThesis}</Markdown>
          </ProductDetailContent.Body>
        </ProductDetailContent>
      )}
      {/* Manually show this as its a sorta one-off */}
      {stnMetadata?.assetManagementFees && (
        <ProductDetailContent>
          <ProductDetailContent.Header>
            <FormattedMessage id="product-details.asset-management-fees" />
          </ProductDetailContent.Header>
          <ProductDetailContent.Body>
            <Markdown Text={ProductDetailContent.Text}>{stnMetadata.assetManagementFees.description}</Markdown>
            <InfoBox p={0}>
              <Row>
                <Row.Label>
                  <FormattedMessage id="product-details.aum-fee" />
                </Row.Label>
                <Row.Value flexShrink={1}>
                  <FormattedNumber
                    value={stnMetadata.assetManagementFees.aumFee.value}
                    style="percent"
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                  />
                </Row.Value>
              </Row>
            </InfoBox>
          </ProductDetailContent.Body>
        </ProductDetailContent>
      )}
      <DocumentsSection offering={offering} assetMetadataDocuments={stnMetadata?.documents} />
      <STNCommonQuestions offering={offering} commonQuestions={stnMetadata?.commonQuestions ?? []} />
    </>
  );
};
