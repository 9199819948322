import { getDaysInMonth, getYear } from 'date-fns';
import * as yup from 'yup';

const getValueFromString = (val: string | undefined): number | undefined => {
  if (typeof val === 'string') {
    const parsedVal = parseInt(val);
    return !isNaN(parsedVal) ? parsedVal : undefined;
  }
  return undefined;
};
export const BirthDateSchema = yup
  .object({
    day: yup
      .string()
      .trim()
      .required()
      .test({
        name: 'isDayGreaterThanZero',
        test: (val) => {
          const parsedVal = getValueFromString(val);
          return parsedVal !== undefined && parsedVal > 0;
        },
      })

      .when(['month', 'year'], ([month, year]: [string, string], schema) => {
        const parsedMonth = getValueFromString(month);
        const parsedYear = getValueFromString(year);
        if (parsedMonth !== undefined && parsedYear !== undefined) {
          return schema.test({
            name: 'isDayInMonth',
            test: (val: string) => {
              const parsedVal = getValueFromString(val);
              return parsedVal != null && parsedVal <= getDaysInMonth(new Date(parsedYear, parsedMonth - 1));
            },
          });
        }

        return schema.max(31);
      }),
    month: yup
      .string()
      .trim()
      .required()
      .test({
        name: 'isMonthGreaterThanOrEqualToOne',
        test: (val) => {
          const parsedVal = getValueFromString(val);
          return parsedVal != null && parsedVal >= 1;
        },
      })
      .test({
        name: 'isMonthLessThanOrEqualToTwelve',
        test: (val) => {
          const parsedVal = getValueFromString(val);
          return parsedVal != null && parsedVal <= 12;
        },
      }),
    year: yup
      .string()
      .trim()
      .required()
      .test({
        name: 'isMonthGreaterThanOrEqualTo1900',
        test: (val) => {
          const parsedVal = getValueFromString(val);
          return parsedVal != null && parsedVal >= 1900;
        },
      })
      .test({
        name: 'isMonthLessThanOrEqualToCurrentYear',
        test: (val) => {
          const parsedVal = getValueFromString(val);
          return parsedVal != null && parsedVal <= getYear(new Date());
        },
      }),
  })
  .defined();

export type BirthDateSchema = yup.InferType<typeof BirthDateSchema>;
