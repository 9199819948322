import { useQuery } from '@tanstack/react-query';

import { getCustomerCount } from '@arrived/api_v2';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { customerCountKey } from './kpis.keys';

export function useGetCustomerCountQuery(options?: Pick<UseAwaitedQueryOptions<typeof getCustomerCount>, 'staleTime'>) {
  return useQuery({
    queryKey: customerCountKey,
    queryFn: getCustomerCount,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}
