import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { AssetType, Offering, OfferingType } from '@arrived/models';

export const useGetProductTypeName = (offering?: Offering) => {
  const intl = useIntl();

  return useMemo(() => {
    const labels: string[] = [];

    if (offering == null) {
      return labels;
    }

    switch (offering.assetType) {
      case AssetType.LTR:
        labels.push(intl.formatMessage({ id: 'invest.sfr' }));
        break;
      case AssetType.PRIVATE_CREDIT:
      case AssetType.STN:
        labels.push(intl.formatMessage({ id: 'invest.real-estate-debt' }));
        break;
      case AssetType.STR:
        labels.push(intl.formatMessage({ id: 'invest.str' }));
        break;
    }

    if (offering.type === OfferingType.FUND) {
      labels.push(intl.formatMessage({ id: 'invest.fund' }));
    }

    return labels;
  }, [offering, offering?.assetType, offering?.type]);
};
