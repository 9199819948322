import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

export enum NcStatusCodes {
  OK = 101,
  BAD_REQUEST = 102,
  INVALID_CREDENTIALS = 103,
  REQUESTED_CLASS_NOT_FOUND = 104,
  INVALID_REQUEST = 105,
  DATA_OR_PARAMETER_MISSING = 106,
  TOO_MANY_REQUESTS = 108,
  REQUEST_TIMED_OUT = 109,
  PERMISSION_DENIED = 110,
  EXTERNAL_ACCOUNT_ALREADY_EXISTS = 116,
  INVESTOR_ACCOUNT_NOT_FOUND = 135,
  ISSUER_ACCOUNT_NOT_FOUND = 136,
  OFFERING_ESCROW_ACCOUNT_NOT_FOUND = 137,
  DOCUMENT_OFFERING_NOT_FOUND = 138,
  UPDATE_KYC_AML_ERROR = 145,
  ACCOUNT_NOT_FOUND = 148,
  EXTERNAL_ACCOUNT_NOT_FOUND_OR_NICKNAME_MISMATCH = 149,
  OFFERING_NOT_FOUND = 162,
  FUND_RETURN_ALREADY_REQUESTED = 189,
  TRADE_STATUS_MUST_BE_CREATED = 190,
  TRADE_FUNDS_ALREADY_INVOKED = 191,

  LINK_ALREADY_EXISTS = 206,
  ACH_REQUEST_ALREADY_SUBMITTED = 210,
  EXTERNAL_ACCOUNT_NOT_FOUND = 212,
  INVALID_ROUTING_NUMBER = 215,
  TRADE_NOT_IN_PENDING_STATE = 224,
  PARTY_DOCUMENT_NOT_FOUND = 226,
  ACCOUNT_DOCUMENT_NOT_FOUND = 231,

  LINKS_NOT_FOUND = 404,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values -- This is just to help clarify the difference between the two
  PARTY_KYC_AML_NOT_FOUND = 404,

  PARTY_OR_ENTITY_DOCUMENT_NOT_FOUND = 709,
  EXTERNAL_ACCOUNT_ALREADY_LINKED = 715,

  NO_DOCUMENT_FOUND_FOR_THIS_TRADE = 719,
}

export function getStatusCodeString(statusCode: NcStatusCodes): FormattedMessageProps {
  switch (statusCode) {
    case NcStatusCodes.OK:
      return { id: 'ok', defaultMessage: 'OK' };
    case NcStatusCodes.INVALID_CREDENTIALS:
      return { id: 'invalid-credentials', defaultMessage: 'Invalid Credentials' };
    case NcStatusCodes.EXTERNAL_ACCOUNT_ALREADY_EXISTS:
      return { id: 'external-account-already-exists', defaultMessage: 'External Account Already Exists' };
    case NcStatusCodes.INVESTOR_ACCOUNT_NOT_FOUND:
      return { id: 'investor-account-not-found', defaultMessage: 'Investor Account Not Found' };
    case NcStatusCodes.EXTERNAL_ACCOUNT_NOT_FOUND_OR_NICKNAME_MISMATCH:
      return {
        id: 'external-account-not-found-nickname',
        defaultMessage: 'External Account Not Found / Nickname Mismatch',
      };
    case NcStatusCodes.ACCOUNT_NOT_FOUND:
      return { id: 'account-not-found', defaultMessage: 'Account Not Found' };
    case NcStatusCodes.EXTERNAL_ACCOUNT_NOT_FOUND:
      return { id: 'external-account-not-found', defaultMessage: 'External Account Not Found' };
    case NcStatusCodes.INVALID_ROUTING_NUMBER:
      return { id: 'invalid-routing-number', defaultMessage: 'Invalid Routing Number' };
    default:
      return { id: 'missing-entry', defaultMessage: '{statusCode}', values: { statusCode } };
  }
}
