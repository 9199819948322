import isEqual from 'lodash/isEqual';

import { ArrivedAuth0Error, ArrivedAuth0State, ArrivedAuth0User } from './types';

type ArrivedAuth0ReducerAction =
  | {
      // This just toggles the loading state to true when interactions begin
      type: 'TOGGLE_LOADING';
      isLoading?: boolean;
    }
  | {
      // After a login is successful with no errors
      type: 'LOGIN_COMPLETE';
      user: ArrivedAuth0User;
    }
  | {
      // After a logout is completed
      type: 'LOGOUT_COMPLETE';
    }
  | {
      type: 'ERROR';
      error: ArrivedAuth0Error;
    }
  | {
      // When the initial call is made to authenticate a user potentially
      type: 'INITIALIZED';
      user: ArrivedAuth0User | null;
    }
  | {
      type: 'SET_USER';
      user: ArrivedAuth0User;
    };

export const arrivedAuth0Reducer = (state: ArrivedAuth0State, action: ArrivedAuth0ReducerAction) => {
  switch (action.type) {
    case 'TOGGLE_LOADING':
      return {
        ...state,
        isLoading: action.isLoading ?? true,
      };

    case 'LOGIN_COMPLETE':
      return {
        ...state,
        isLoading: false,
        error: null,
        user: action.user,
      };

    case 'LOGOUT_COMPLETE':
      return {
        ...state,
        isLoading: false,
        error: null,
        user: null,
      };

    case 'ERROR':
      return {
        ...state,
        error: action.error,
      };

    case 'INITIALIZED':
      return {
        ...state,
        isLoading: false,
        user: action.user,
      };

    case 'SET_USER':
      if (isEqual(state.user, action.user)) {
        return state;
      }

      return {
        ...state,
        user: action.user,
      };
  }
};
