import { FormattedMessage } from 'react-intl';

import { SSTDocumentType } from '@arrived/models';

export const SSTDocumentTypeLabels = {
  [SSTDocumentType.NOTE_PURCHASE_AGREEMENT]: (
    <FormattedMessage id="property-details.documents.note-purchase-agreement" />
  ),
  [SSTDocumentType.NOTE_SUPPLEMENT]: <FormattedMessage id="property-details.documents.npa-note-supplement" />,
  [SSTDocumentType.NOTE_SUPPLEMENT_TERMS]: <FormattedMessage id="property-details.documents.om-note-supplement" />,
  [SSTDocumentType.PRIVATE_PLACEMENT_MEMORANDUM]: (
    <FormattedMessage id="property-details.documents.private-placement-memorandum" />
  ),
} as const;
