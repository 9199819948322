import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface PromotionHandlerProps {
  children: ReactNode;
}

// Leaving this as a function just in case we ever want this linked to a specific user or account
export const getPromotionSessionStorageKey = () => `arrived.promotion.code`;
export const PROMOTION_QUERY_PARAM_KEY = 'arrived_promo';

interface PromotionContextValue {
  code?: string;
  loaded: boolean;
  clear: () => void;
}
export const PromotionCodeContext = createContext<PromotionContextValue>({
  loaded: false,
  clear: () => null,
});

export const usePromotionCodeContext = () => useContext(PromotionCodeContext);

export const PromotionHandler = ({ children }: PromotionHandlerProps) => {
  const [promotionCode, setPromotionCode] = useState<string | undefined>();
  const [promotionCodeLoaded, setPromotionCodeLoaded] = useState(false);

  useEffect(() => {
    const sessionPromotionCode = sessionStorage.getItem(getPromotionSessionStorageKey()) ?? undefined;
    setPromotionCode(sessionPromotionCode);
    setPromotionCodeLoaded(true);
  }, []);

  useEffect(() => {
    setPromotionCodeLoaded(false);
    const searchParams = new URL(window.location.href).searchParams;
    const promoQueryParam = searchParams.get(PROMOTION_QUERY_PARAM_KEY) ?? undefined;
    if (!promotionCode && promoQueryParam) {
      sessionStorage.setItem(getPromotionSessionStorageKey(), promoQueryParam);
      setPromotionCode(promoQueryParam);
    }
    setPromotionCodeLoaded(true);
  }, []);

  const clearPromotionCode = useCallback(() => {
    const promotionSessionStorageKey = getPromotionSessionStorageKey();
    sessionStorage.removeItem(promotionSessionStorageKey);
    setPromotionCode(sessionStorage.getItem(promotionSessionStorageKey) ?? undefined);
  }, [setPromotionCode]);

  const promotionContextValue = useMemo(() => {
    return { code: promotionCode, loaded: promotionCodeLoaded, clear: clearPromotionCode };
  }, [promotionCode, promotionCodeLoaded, clearPromotionCode]);

  return <PromotionCodeContext.Provider value={promotionContextValue}>{children}</PromotionCodeContext.Provider>;
};
