import { useQuery } from '@tanstack/react-query';

import { getPromotions } from '@arrived/api_v2';
import { Rewards } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';

import { promotionsKeyFn } from './rewards.keys';

export function useGetPromotionsQuery(
  filters?: Rewards.PromotionFilters,
  options?: UseAwaitedQueryOptions<typeof getPromotions>,
) {
  return useQuery({
    queryKey: promotionsKeyFn(filters),
    queryFn: () => getPromotions(filters),
    ...options,
  });
}
