import { useMemo } from 'react';

import { SrcSetDefinition, stringifySrcset } from 'srcset';

import { Stack, baseMediaBreakpoints } from '@arrived/bricks';

import { ImageComponentProps } from './image';

// Used to remove the `readonly` modifier on the type of `SrcSetDefinition`
type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export const ImageComponent = ({ entries, alt, defaultImage, attributes }: ImageComponentProps) => {
  const imageEntries = useMemo(() => {
    return entries
      ?.map((entry) => {
        const { minWidth } = baseMediaBreakpoints[entry.targetSize ?? 'xxs'];
        const parsedMinWidth = typeof minWidth === 'number' ? minWidth.toString() : minWidth;
        const minWidthString = `(min-width: ${parsedMinWidth}px)`;
        if (!entry.images) return undefined;
        return {
          media: minWidthString,
          srcSet: stringifySrcset(
            entry.images?.map((image) => {
              const newImage: Writeable<SrcSetDefinition> = { url: image.url };
              if (image.width) newImage.width = image.width;
              if (image.density) newImage.density = image.density;
              return newImage;
            }) as SrcSetDefinition[],
          ),
        };
      })
      .filter(Boolean)
      .reverse();
  }, [entries]);

  return (
    <Stack tag="picture" {...attributes}>
      {imageEntries?.map((entry, idx) => {
        return <source key={idx} {...entry} />;
      })}
      {/* @ts-ignore alt tag works on web */}
      <Stack tag="img" alt={alt} src={defaultImage} $platform-web={{ objectFit: 'contain' }} />
    </Stack>
  );
};
