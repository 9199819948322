import { CreateOfferingBankAccount, Offering } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const postOfferingBankAccount = ({ offeringCid, ...rest }: CreateOfferingBankAccount) =>
  createQuery<Offering>({
    apiInstance: api,
    method: 'post',
    url: `/offerings/${offeringCid}/configure-bank-account`,
    requestData: rest,
  });
