import { StackProps } from '../../atoms';

export const getGhostStyles = (isInverted?: boolean, isLoading?: boolean, isDisabled?: boolean): StackProps => {
  if (isLoading) {
    return {
      disabled: true,
      pointerEvents: 'none',
      bg: '$transparent',
    };
  }

  if (isDisabled) {
    return {
      disabled: true,
      pointerEvents: 'none',
      bg: '$transparent',
    };
  }

  return {
    bg: '$transparent',

    focusStyle: {
      bg: '$transparent',
    },
    hoverStyle: {
      bg: isInverted ? '$interactive.neutral.hoveredInvertedAlt' : '$interactive.neutral.hoveredAlt',
    },
    pressStyle: {
      bg: '$interactive.neutral.hoveredAlt',
    },
  };
};
