import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function HotelBedInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M1.25 18.75V5.833m17.5 12.917v-7.5H1.25m0 4.583h17.5M3.333 8.75h3.334m2.083 7.083V11.25"
        stroke={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const HotelBedIcon = memo<IconProps>(withIconTheme(HotelBedInternal));
