import { FundStatus, TransactionStatus } from '@arrived/models';

export const CANCELLED_TRANSACTION_STATUSES = [
  TransactionStatus.CANCELLED,
  TransactionStatus.UNWIND_SETTLED,
  TransactionStatus.UNWIND_PENDING,
];

/**
 * Transaction statuses that indicate that a transaction is complete. A transaction in one of these states may require
 * additional user action depending on the documentsSignedStatus and the fundStatus of the transaction.
 */
export const COMPLETE_TRANSACTION_STATUSES = [
  TransactionStatus.CREATED,
  TransactionStatus.FUNDED,
  TransactionStatus.SETTLED,
];

/**
 * Fund statuses that indicate the transaction did not go through and may require user or admin action to resolve.
 */
export const INCOMPLETE_FUND_STATUSES = [FundStatus.DECLINED, FundStatus.RETURNED, FundStatus.VOIDED];

/**
 * Fund statuses that indicate the transaction is complete.
 */
export const COMPLETE_FUND_STATUSES = [FundStatus.PENDING, FundStatus.SETTLED, FundStatus.SUBMITTED];
