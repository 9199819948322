import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';

import { useProductWidgetContext } from './ProductWidgetContext';
import { useProductWidgetStyledContext } from './ProductWidgetStyledContext';

const Label = () => <FormattedMessage id="common.investors" />;

export const Investors = () => {
  const { offering } = useProductWidgetContext();
  const { condensed } = useProductWidgetStyledContext();

  const value = useMemo(() => <FormattedNumber value={offering.investorsCount} />, [offering.investorsCount]);

  return (
    <DataPoint variant="minimized" colors="muted" alignment={condensed ? 'right' : 'left'}>
      <DataPoint.Value>{value}</DataPoint.Value>
      <DataPoint.Label>
        <Label />
      </DataPoint.Label>
    </DataPoint>
  );
};
