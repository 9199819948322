import { Stack, ValueText, styled, withStaticProperties } from '@arrived/bricks';

import { ProductInfoBoxHeader } from '../productInfoBox';

const RowFrame = styled(Stack, { gap: '$8', p: '$6', alignItems: 'center', row: true });

const RowValue = styled(ValueText, {
  token: 'value.medium.alt',
  ml: 'auto',
  variants: { variant: { bold: { token: 'value.medium' }, large: { token: 'value.large' } } } as const,
});

export const Row = withStaticProperties(
  RowFrame.styleable(({ children, ...rest }, ref) => {
    return (
      <RowFrame ref={ref} {...rest}>
        {children}
      </RowFrame>
    );
  }),
  {
    Label: ProductInfoBoxHeader,
    Value: RowValue,
  },
);
