import { KeyboardEvent, ReactNode, useEffect, useMemo, useState } from 'react';

import { isTamaguiElement, useProps, withStaticProperties } from '@tamagui/core';

import { BlurView } from '../../blur-view';

import { TagIcon, TagLabel } from './label';
import { PressableTagVariant, TAG_ICON_NAME, TAG_LABEL_NAME, TagStyledContext, TagVariant } from './Tag.constants';
import { TagContainer, TagContainerProps } from './TagContainer';

const isSquareVariant = (variant?: TagVariant) => variant === 'category' || variant === 'primary';

const findComponents = (children: ReactNode[]) => {
  let iconIndex = -1,
    labelIndex = -1;

  children.forEach((child, idx) => {
    if (isTamaguiElement(child, TAG_ICON_NAME)) {
      iconIndex = idx;
    } else if (isTamaguiElement(child, TAG_LABEL_NAME)) {
      labelIndex = idx;
    }
  });

  // Get all indices for the named components which were found and sort them in reverse order so that the largest index
  // is first. When we remove these from the child array then we can preserve the inherent order and remove the correct
  // components.
  const indicesToRemove = [iconIndex, labelIndex].filter((index) => index >= 0).sort((a, b) => b - a);

  const rest = [...children];
  indicesToRemove.forEach((index) => rest.splice(index, 1));

  return {
    Icon: iconIndex >= 0 ? children[iconIndex] : undefined,
    Label: labelIndex >= 0 ? children[labelIndex] : undefined,
    children: rest,
  };
};

export type TagPropsPressable = {
  pressable: true;
  disabled?: boolean;
  variant?: PressableTagVariant;
  onKeyDown?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
} & Omit<TagContainerProps, 'pressable'>;

export type TagPropsNonPressable = {
  pressable?: false;
  variant?: TagVariant;
  disabled?: never;
  onKeyDown?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
} & Omit<TagContainerProps, 'pressable'>;

export type TagProps = TagPropsPressable | TagPropsNonPressable;

const TagStatics = {
  Icon: TagIcon,
  Label: TagLabel,
};

export const Tag = withStaticProperties(
  TagContainer.styleable<TagProps>(
    (
      {
        pressable: pressableIn,
        onPressIn,
        onPressOut,
        onHoverIn,
        onHoverOut,
        onKeyDown,
        onKeyUp,
        'aria-label': ariaLabel,
        children,
        condensed,
        ...propsInRest
      },
      ref,
    ) => {
      const {
        disabled,
        pressable = false,
        variant,
        ...rest
      } = useProps({
        pressable: pressableIn,
        ...propsInRest,
      });

      // Hack to fix blur view for cross platform, we are struggling with position
      // absolute on native. Lets just fill out the box with a blur view and then
      // overlay the children on top of it.
      const [parentWidth, setParentWidth] = useState<number>();
      const [parentHeight, setParentHeight] = useState<number>();

      const [isHovered, setIsHovered] = useState(false);
      const [isPressed, setIsPressed] = useState(false);
      const [isKeyDown, setIsKeyDown] = useState(false);

      const { Icon, Label } = useMemo(
        () => findComponents(Array.isArray(children) ? children : [children]),
        [children],
      );

      useEffect(() => {
        if (!Label && !ariaLabel) {
          console.warn(
            'Your Tag is inaccessible to screen readers, if a Label cannot be provided, please provided an aria-label',
          );
        }
      }, [ariaLabel, Label]);

      return (
        <TagStyledContext.Provider
          disabled={disabled}
          isHovered={isHovered}
          isKeyDown={isKeyDown}
          isPressed={isPressed}
          variant={variant}
          pressable={pressable}
        >
          <TagContainer
            aria-label={ariaLabel}
            // We need to explicitly pass a boolean for condensed so that it evaluates the condensed style variant which
            // dictates the correct padding/height for the Pill
            condensed={!!condensed}
            disabled={disabled}
            iconOnly={Boolean(Icon) && !Label}
            onHoverIn={(event) => {
              setIsHovered(true);
              onHoverIn?.(event);
            }}
            onHoverOut={(event) => {
              setIsHovered(false);
              onHoverOut?.(event);
            }}
            // @ts-expect-error onKeyDown not recognized as a property of a Stack
            onKeyDown={(event: KeyboardEvent) => {
              if (event.key === 'Enter' || event.key === ' ') {
                setIsKeyDown(true);
              }

              onKeyDown?.(event);
            }}
            onKeyUp={(event: KeyboardEvent) => {
              setIsKeyDown(false);
              onKeyUp?.(event);
            }}
            onPressIn={(event) => {
              setIsPressed(true);
              onPressIn?.(event);
            }}
            onPressOut={(event) => {
              setIsPressed(false);
              onPressOut?.(event);
            }}
            onLayout={(event) => {
              setParentWidth(event.nativeEvent.layout.width);
              setParentHeight(event.nativeEvent.layout.height);
            }}
            pressable={pressable}
            square={isSquareVariant(variant)}
            variant={variant}
            ref={ref}
            tag={pressable ? 'button' : undefined}
            {...rest}
          >
            {/* Blur view does not adhere to overflow hidden, it is a known chrome bug. Set the br to full to avoid */}
            {(!variant || variant === 'default') && (
              <BlurView fullscreen br="$full" w={parentWidth ?? '100%'} h={parentHeight ?? '100%'} />
            )}
            {children}
          </TagContainer>
        </TagStyledContext.Provider>
      );
    },
  ),
  TagStatics,
);
