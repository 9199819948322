import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function CaretDownInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path d="M5.833 8L10 12.167 14.167 8" stroke={color} strokeWidth={1.25} />
    </Svg>
  );
}

export const CaretDownIcon = memo<IconProps>(withIconTheme(CaretDownInternal));
