import { useQuery } from '@tanstack/react-query';

import { getAirDnaMonthlyRevenueByOffering } from '@arrived/api_v2';
import { OfferingId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { airDnaMonthlyRevenueKeyFn } from './airDna.keys';

export function useGetAirDnaMonthlyRevenueByOffering(
  offeringId: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getAirDnaMonthlyRevenueByOffering>,
) {
  return useQuery({
    queryKey: airDnaMonthlyRevenueKeyFn(offeringId),
    queryFn: () => getAirDnaMonthlyRevenueByOffering({ offeringId }),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
