import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { Offering } from '@arrived/models';

import { PreFormattedDataPointProps } from './types';

export type LeverageDataPointProps = PreFormattedDataPointProps & { offering: Offering };

export const LeverageDataPoint = ({ offering, ...rest }: LeverageDataPointProps) => (
  <DataPoint
    colors="dark"
    variant="minimized"
    tooltip={{
      content: <FormattedMessage id="product-details.property-leverage.tooltip" />,
      title: <FormattedMessage id="product-details.property-leverage" />,
    }}
    {...rest}
  >
    <DataPoint.Value>
      <FormattedNumber
        style="percent"
        maximumFractionDigits={2}
        minimumFractionDigits={0}
        value={offering.debtPercent / 100}
      />
    </DataPoint.Value>
    <DataPoint.Label>
      <FormattedMessage id="product-details.property-leverage" />
    </DataPoint.Label>
    {offering.hasFlexFinancing && (
      <DataPoint.Tag variant="category">
        <FormattedMessage id="product-details.refinance-candidate" />
      </DataPoint.Tag>
    )}
  </DataPoint>
);
