import ReactMarkdown, { Components, Options as ReactMarkdownOptions } from 'react-markdown';

import { Link, Typography } from '@mui/material';

const markdownComponents: Components = {
  p: ({ children, ref: _, ...rest }) => (
    <Typography component="p" {...rest}>
      {children}
    </Typography>
  ),
  strong: ({ children, ref: _, ...rest }) => (
    <Typography component="span" fontWeight="semibold" {...rest}>
      {children}
    </Typography>
  ),
  a: ({ children, ref: _, ...rest }) => (
    <Link
      sx={{ '&:visited': { color: ({ palette }) => palette.aqua[500] } }}
      underline="always"
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </Link>
  ),
};

interface MarkdownTextProps extends Omit<ReactMarkdownOptions, 'children'> {
  markdownString: string;
}

/**
 * Custom wrapper around `react-markdown` that applies rendering rules for how to
 * map markdown elements to MUI components.
 *
 * Consumes a markdown string, and optional `react-markdown` props.
 */
export const MarkdownText = ({ markdownString, ...rest }: MarkdownTextProps) => {
  return (
    <ReactMarkdown components={markdownComponents} {...rest}>
      {markdownString}
    </ReactMarkdown>
  );
};
