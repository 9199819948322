import { BodyText, styled } from '@arrived/bricks';

import { PRODUCT_LIST_ITEM_SUBTITLE_NAME } from './constants';

export const ProductListItemSubtitle = styled(BodyText, {
  name: PRODUCT_LIST_ITEM_SUBTITLE_NAME,

  token: 'body.compact.small',
  color: '$onSurface.neutral.muted',
});
