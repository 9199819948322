import { Stack, isWeb, styled } from '@arrived/bricks';

export const Row = styled(Stack, {
  row: true,
  gap: '$1',
  px: '$4',
  py: '$3',
  boc: '$onSurface.neutral.outlineAlt',

  variants: {
    variant: {
      top: {
        py: '$2',
        // It's challenging to see the background on mobile, so make it slightly darker with
        // zebraAlt
        bg: isWeb ? '$onSurface.neutral.zebra' : '$onSurface.neutral.zebraAlt',
        bw: '$0.25',
        btlr: '$1',
        btrr: '$1',
      },
      content: {
        blw: '$0.25',
        brw: '$0.25',
      },
      bottom: {
        bg: '$surface.positive.default',
        bblr: '$1',
        bbrr: '$1',
      },
    },
  } as const,
});
