import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postOfferingBankAccount } from '@arrived/api_v2';
import { UseAwaitedMutationOptions, offeringKeyFn } from '@arrived/queries';

export function usePostOfferingBankAccount(options?: UseAwaitedMutationOptions<typeof postOfferingBankAccount>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postOfferingBankAccount,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.cid) });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.id) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
