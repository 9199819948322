import { useMutation, useQueryClient } from '@tanstack/react-query';
import _difference from 'lodash/difference';

import { deletePropertyFromOffering } from '@arrived/api_v2';
import { UseAwaitedMutationOptions, offeringKeyFn, offeringsKeyFn } from '@arrived/queries';

import { propertyKeyFn } from '../properties';

export function useDeletePropertyFromOfferingMutation(
  options?: UseAwaitedMutationOptions<typeof deletePropertyFromOffering>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePropertyFromOffering,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: offeringsKeyFn() });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.id) });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.shortName) });

      const newPropertyIds = data.properties.map(({ id }) => id);
      [
        ..._difference(newPropertyIds, variables.propertyIds),
        ..._difference(variables.propertyIds, newPropertyIds),
      ].forEach((propertyId) => queryClient.invalidateQueries({ queryKey: propertyKeyFn(propertyId) }));

      options?.onSuccess?.(data, variables, context);
    },
  });
}
