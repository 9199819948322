import { KycAmlRollupStatus } from '@arrived/models';

import { useGetAccountKycAmlQuery } from '../accounts/kycAml/useGetAccountKycAmlQuery';
import { useGetPrimaryAccountQuery } from '../users/useGetPrimaryAccountQuery';

export function useGetUserReferralEligibility(isReferralEnabled: boolean, isUserReferralBeta: boolean): boolean {
  const accountData = useGetPrimaryAccountQuery();
  const kycAmlStatus = useGetAccountKycAmlQuery(accountData.data?.id);

  return (
    isReferralEnabled &&
    isUserReferralBeta &&
    kycAmlStatus &&
    kycAmlStatus.data?.kycAmlRollupStatus === KycAmlRollupStatus.PASS
  );
}
