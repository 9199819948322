import { styled } from '@tamagui/core';

import { Stack } from '../../../atoms';

export const SecondarySpotDropdownWrapper = styled(Stack, {
  bg: '$onSurface.neutral.zebraAlt',
  row: false,
  alignItems: 'stretch',

  $gtXs: {
    bg: '$transparent',
    row: true,
    gap: '$3',
    alignItems: 'center',
  },
});
