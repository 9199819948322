import { useMemo } from 'react';

import { getOfferingDisplayState, isOfferingSoldOut } from '@arrived/common';
import { OfferingId } from '@arrived/models';
import { useCanUserTransactEarlyAccess, useIsOfferingForSale, useOfferingQuery } from '@arrived/queries';

import { useIsOfferingBlocked } from './useIsOfferingBlocked';

export function useOfferingDisplayState(offeringId?: OfferingId) {
  const offeringState = useOfferingQuery(offeringId);

  const isOfferingForSale = useIsOfferingForSale();
  const isOfferingBlocked = useIsOfferingBlocked();

  const canUserTransactEarlyAccess = useCanUserTransactEarlyAccess();

  return useMemo(() => {
    if (!offeringState.isSuccess) {
      return;
    }

    return getOfferingDisplayState({
      canUserTransactEarlyAccess,
      isOfferingBlocked: isOfferingBlocked(offeringState.data.shortName),
      isOfferingForSale: isOfferingForSale(offeringState.data),
      isOfferingSoldOut: isOfferingSoldOut(offeringState.data),
      offering: offeringState.data,
    });
  }, [canUserTransactEarlyAccess, isOfferingBlocked, isOfferingForSale, offeringState.isSuccess, offeringState.data]);
}
