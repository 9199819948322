import { useQuery } from '@tanstack/react-query';

import { getUserDocument } from '@arrived/api_v2';
import { UserDocumentId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { userDocumentKeyFn } from './userDocuments.keys';

export function useUserDocumentQuery(
  userDocumentId: UserDocumentId,
  options?: UseAwaitedQueryOptions<typeof getUserDocument>,
) {
  return useQuery({
    queryKey: userDocumentKeyFn(userDocumentId),
    queryFn: () => getUserDocument(userDocumentId),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}
