import { DataPoint, DataPointProps } from '@arrived/bricks';

import { useProductWidgetStyledContext } from '../ProductWidgetStyledContext';

export type StyledDataPointProps = Omit<DataPointProps<false>, 'colors' | 'variant'>;

export const StyledDataPoint = (props: StyledDataPointProps) => {
  const { condensed } = useProductWidgetStyledContext();

  return <DataPoint colors="dark" alignment="left" variant={condensed ? undefined : 'elevateAlt'} {...props} />;
};
