import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getReferralCodeEntityByUserId } from '@arrived/api_v2';
import { UserId } from '@arrived/models';

import { adminReferralCodeByUserIdFn } from './referrals.keys';

export function useAdminGetReferralCodeByUserIdQuery(userId: UserId) {
  const queryClient = useQueryClient();

  const referralCodeState = useQuery({
    queryKey: adminReferralCodeByUserIdFn(userId),
    queryFn: () => getReferralCodeEntityByUserId(userId),
  });

  useEffect(() => {
    if (referralCodeState.isSuccess) {
      queryClient.setQueryData(adminReferralCodeByUserIdFn(userId), referralCodeState.data);
    }
  }, [referralCodeState.isSuccess]);

  return referralCodeState;
}
