export interface CalculateProjectedYearlyDividendsArguments {
  /**
   * The interest percentage on the loan taken out for financing the Offering, should be in the range of [0, 1].
   */
  debtInterestPercent: number;
  /**
   * The total equity of the Offering.
   */
  equityValue: number;
  /**
   * The investment term of the Offering in years.
   */
  investmentTerm: number;
  /**
   * Indicates if interest is incorporated in the existing dividend yield.
   */
  isInterestDeductedInDividendYield?: boolean;
  /**
   * The amount of equity in the Offering that was financed.
   */
  loanAmount: number;
  /**
   * The projected rental yield of the property.
   */
  projectedRentalDividendYield: number;
  /**
   * The yearly rental increase assumed. If omitted, the default value `assumedYearlyRentalIncrease` will be used.
   */
  yearlyRentalIncrease: number;
}

/**
 * Computes the projected yearly dividends for an investment. The return value will be an array of length investmentTerm
 * where the value at index X is the rental dividend yield for year X + 1. For example a return value of
 * [100, 103, 107, ...] means that the rental income from year 1 was $100, year 2 was $103, year 3 was $107, etc.
 */
export function calculateProjectedYearlyDividends({
  debtInterestPercent,
  equityValue,
  investmentTerm,
  isInterestDeductedInDividendYield = false,
  loanAmount,
  projectedRentalDividendYield,
  yearlyRentalIncrease,
}: CalculateProjectedYearlyDividendsArguments) {
  // We get the interest payment by multiplying the loan amount by the debtInterestPercent, this gives us the amount
  // to deduct from the gross revenue each year.
  const interest = loanAmount * debtInterestPercent;
  // Each year, the dividend is assumed to go up by some specified percentage, so we compound the rental increase by the
  // year of investment - 1 (since year in this case is actually year - 1 we don't modify it at all in the calculation).
  return [...Array(investmentTerm)].map((_, year) => {
    if (isInterestDeductedInDividendYield) {
      return (
        (projectedRentalDividendYield * equityValue + interest) * Math.pow(1 + yearlyRentalIncrease, year) - interest
      );
    } else {
      return projectedRentalDividendYield * equityValue * Math.pow(1 + yearlyRentalIncrease, year) - interest;
    }
  });
}
