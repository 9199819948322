import { useQuery } from '@tanstack/react-query';
import _merge from 'lodash/merge';

import { getAccountTrades } from '@arrived/api_v2';
import { AccountId, TradesQueryFilters } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { accountTradesQueryKeyFn } from './trades.keys';

const defaultFilters = {
  size: 1000,
};

export function useAccountTradesQuery(
  accountId?: AccountId,
  filters?: TradesQueryFilters,
  options?: UseAwaitedQueryOptions<typeof getAccountTrades>,
) {
  const passedFilters = _merge({}, defaultFilters, filters);

  return useQuery({
    queryKey: accountTradesQueryKeyFn(accountId!, passedFilters),
    queryFn: () => getAccountTrades(accountId!, passedFilters),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}
