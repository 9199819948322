import { getEnvVariable } from './get-env-variable';

// @ts-ignore -- this is only available in native
const isNative = !!global.nativeCallSyncHook;

/**
 * Our configuration object, which contains all of our environment variables.
 * These will default to `ERROR` if they are not defined in the environment.
 *
 * Be sure to update `ArrivedEnvironmentVariables` in `packages/config/src/environment-variables.ts` when adding new variables.
 */
export const CONFIG = {
  /**
   * Base paths for internal websites & services
   */
  baseHref: getEnvVariable('NX_BASE_HREF'),

  /**
   * The application root for `web-app` that uses `react-router`
   */
  appRoot: `${getEnvVariable('NX_PUBLIC_APP_ROOT')}${getEnvVariable('NX_BASE_HREF')}`,

  /**
   * Our social share url root
   */
  socialShareUrlRoot: getEnvVariable('NX_WEB_APP_ROOT'),

  /**
   * Our careers url
   */
  careersUrl: 'https://arrived.breezy.hr/',

  /**
   * Our help url
   * @example https://help.arrived.com/
   */
  helpUrl: getEnvVariable('NX_HELP_PAGES_ROOT'),

  /**
   * Our blog root
   */
  learnBlogUrl: getEnvVariable('NX_BLOG_ROOT'),

  /**
   * Our `public-app` root, which is used for our public facing website.
   */
  publicAppRoot: getEnvVariable('NX_PUBLIC_APP_ROOT'),

  /**
   * Our root Demio API url, uses SST
   */
  demioApiRoot: getEnvVariable('NX_DEMIO_API_ROOT'),

  /**
   * Our root CMS API url
   */
  arrivedCmsApiUrl: getEnvVariable('NX_ARRIVED_CMS_ROOT'),

  /**
   * Our SST API root that hosts our meta data service
   */
  assetMetadataApiRoot: getEnvVariable('NX_ASSET_METADATA_API_ROOT'),

  /**
   * The AirDNA API root
   */
  airDnaApiRoot: getEnvVariable('NX_AIR_DNA_API_ROOT'),

  /**
   * The data API root for our internal data service
   * @example https://data.arrived.com/v1
   */
  dataApiRoot: getEnvVariable('NX_ARRIVED_DATA_API_ROOT'),

  /**
   * Our general API for any marketing related information
   * @example https://marketing.arrived.com
   */
  marketingApiUrl: getEnvVariable('NX_MARKETING_API_ROOT'),

  /**
   * The original marketing API that would be `https://marketing.arrivedhomes.com`.
   * This was used for general KPIs and should be migrated to the new marketing API.
   */
  marketingApiArrivedHomesUrl: getEnvVariable('NX_MARKETING_API_ARRIVEDHOMES_ROOT'),

  /**
   * The root of the Abacus API
   */
  abacusApiRoot: getEnvVariable('NX_ABACUS_ROOT'),

  /**
   * The historical returns Abacus API
   */
  historicalReturnsApiRoot: getEnvVariable('NX_HISTORICAL_RETURNS_API_ROOT'),

  /**
   * The root of the Abacus Rewards API
   */
  rewardsApiRoot: getEnvVariable('NX_REWARDS_ROOT'),

  /**
   * The root of the public/external Cash Accounts API
   */
  cashAccountsApiRoot: getEnvVariable('NX_CASH_ACCOUNTS_ROOT'),

  /**
   * The root of the internal Cash Accounts API
   */
  cashAccountsInternalApiRoot: getEnvVariable('NX_CASH_ACCOUNTS_INTERNAL_ROOT'),

  // ... other environment variables ...

  /**
   * The app bundle ID for native
   * @example `com.arrivedhomes.development`
   */
  mobileBundleId: getEnvVariable('NX_MOBILE_BUNDLE_ID'),

  /**
   * The Mapbox Access Key, see https://docs.mapbox.com/help/getting-started/access-tokens/
   */
  mapboxAccessKey: getEnvVariable('NX_MAPBOX_ACCESS_KEY_MOBILE'),

  /**
   * Our Intercom ID
   */
  intercomId: getEnvVariable('NX_INTERCOM_ID'),

  /**
   * Auth0 Configuration
   */
  auth0: {
    audience: getEnvVariable('NX_AUTH0_AUDIENCE'),
    callbackUrlAndroid: getEnvVariable('NX_AUTH0_MOBILE_ANDROID_CALLBACK'),
    callbackUrliOS: getEnvVariable('NX_AUTH0_MOBILE_IOS_CALLBACK'),
    connection: getEnvVariable('NX_AUTH0_CONNECTION'),
    domain: getEnvVariable('NX_AUTH0_DOMAIN'),

    /**
     * The client ID for Auth0, which is different for web and mobile.
     */
    clientId: isNative ? getEnvVariable('NX_AUTH0_MOBILE_CLIENT_ID') : getEnvVariable('NX_AUTH0_WEB_CLIENT_ID'),

    /**
     * The scope for Auth0, which is different for web and mobile.
     */
    scope: isNative ? getEnvVariable('NX_AUTH0_MOBILE_SCOPE') : getEnvVariable('NX_AUTH0_WEB_SCOPE'),
  },

  /**
   * Facebook App ID
   */
  facebookAppId: getEnvVariable('NX_FACEBOOK_APP_ID'),

  /**
   * Google API Key
   */
  googleApiKey: getEnvVariable('NX_GOOGLE_API_KEY'),

  /**
   * Google Tag Manager ID
   */
  googleTagManagerId: getEnvVariable('NX_GOOGLE_TAG_MANAGER_ID'),

  /**
   * GrowthBook API Key
   */
  growthbookApiKey: getEnvVariable('NX_GROWTHBOOK_API_KEY'),

  /**
   * Affiliate Marketing Configuration
   */
  marketing: {
    tuneAffiliateBaseUrl: 'https://arrivedhomes.go2cloud.org',
  },

  /**
   * Plaid configuration
   */
  plaid: {
    clientName: 'Arrived Homes',
    environment: getEnvVariable('NX_PLAID_ENVIRONMENT_NAME'),
  },

  /**
   * Arrived App Store URL
   */
  appStoreUrl: 'https://apps.apple.com/us/app/arrived-real-estate-investing/id1603728735',

  /**
   * General RudderStack configuration
   */
  rudderStack: {
    cdnUrl: 'https://cdn-rudderstack.arrived.com',
    configUrl: 'https://api-rudderstack.arrived.com',
    dataPlaneUrl: 'https://events-rudderstack.arrived.com',
    rudderWriteKey: isNative ? getEnvVariable('NX_RUDDER_WRITE_KEY_MOBILE') : getEnvVariable('NX_RUDDER_WRITE_KEY_WEB'),
    cookieDomain: getEnvVariable('NX_RUDDER_COOKIE_DOMAIN') || 'arrived.com',
  },

  /**
   * Sentry configuration
   */
  sentry: {
    env: getEnvVariable('NX_SENTRY_ENVIRONMENT'),
    mobileDsn: getEnvVariable('NX_SENTRY_DSN_MOBILE'),
    reactDsn: getEnvVariable('NX_SENTRY_DSN_REACT'),
    nextJsDsn: getEnvVariable('NX_SENTRY_DSN_NEXT'),
  },

  /**
   * Demio API key and secret, a temporary solution till we get a service.
   * @NOTE This is updated using our SST repository, [see here](https://github.com/arrivedhomes/arrived-sst)
   */
  demio: {
    apiKey: getEnvVariable('NX_DEMIO_API_KEY'),
    apiSecret: getEnvVariable('NX_DEMIO_API_SECRET'),
  },

  /**
   * AppsFlyer Information for tracking UTM parameters
   */
  appsFlyer: {
    devKey: getEnvVariable('NX_APPSFLYER_DEV_KEY'),

    /**
     * The related Application ID from Apple
     *
     * @example 1603728735 (from Arrived Production)
     */
    appId: getEnvVariable('NX_MOBILE_APP_ID'),
  },

  /**
   * The Builder.io API key, which is used for our CMS
   */
  builderApiKey: getEnvVariable('NX_BUILDER_IO_PUBLIC_KEY'),

  sardine: {
    environment: getEnvVariable('NX_SARDINE_ENVIRONMENT') as 'sandbox' | 'production',
    clientId: getEnvVariable('NX_SARDINE_CLIENT_ID'),
    apiEndpoint: getEnvVariable('NX_SARDINE_API_ENDPOINT'),
    pixelEndpoint: getEnvVariable('NX_SARDINE_PIXEL_ENDPOINT'),
  },
} as const;

/**
 * Our configuration object, which contains all of our environment variables.
 * @alias CONFIG
 */
export const ArrivedConfig = CONFIG;

export type ArrivedConfig = typeof CONFIG;
