import { FormattedMessage } from 'react-intl';

import { UserDocumentType } from '@arrived/models';

export const getUserDocumentDisplayName = (documentType: UserDocumentType) => {
  switch (documentType) {
    case UserDocumentType.BANK_VERIFICATION:
      return <FormattedMessage id="bank-verification" defaultMessage="Bank Verification" />;
    case UserDocumentType.CERTIFICATE_OF_FORMATION:
      return <FormattedMessage id="certificate-of-formation" defaultMessage="Certificate of Formation" />;
    case UserDocumentType.DRIVERS_LICENSE:
      return <FormattedMessage id="drivers-license" defaultMessage="Driver's License (Real ID Compliant)" />;
    case UserDocumentType.GREEN_CARD:
      return <FormattedMessage id="green-card" defaultMessage="Green Card" />;
    case UserDocumentType.PASSPORT:
      return <FormattedMessage id="passport" defaultMessage="Passport" />;
    case UserDocumentType.ID:
      return <FormattedMessage id="id" defaultMessage="ID" />;
    case UserDocumentType.OTHER:
      return <FormattedMessage id="other-identification" defaultMessage="Other Identification" />;
  }
};
