import { memo } from 'react';

import { Defs, LinearGradient, Path, Stop, Svg } from 'react-native-svg';

import { useStyle } from '@tamagui/core';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function ArrivedIconWithTextInternal(props: IconProps) {
  const style = useStyle({ width: 98, height: 20, ...props });

  return (
    <Svg fill="none" viewBox="0 0 98 20" {...props} {...style}>
      <Path
        d="m11.17 15.132 2.453-2.474a.5.5 0 0 0 0-.704l-2.454-2.473a.5.5 0 0 0-.71 0l-2.453 2.473a.5.5 0 0 0 0 .704l2.453 2.474a.5.5 0 0 0 .71 0Z"
        fill="url(#a)"
      />
      <Path
        d="m12.558 7.364 2.454-2.473a.5.5 0 0 1 .71 0l2.453 2.473a.5.5 0 0 1 0 .705l-2.454 2.473a.5.5 0 0 1-.71 0L12.559 8.07a.5.5 0 0 1 0-.705Z"
        fill="url(#b)"
      />
      <Path
        d="m12.558 16.544 2.453-2.473a.5.5 0 0 1 .71 0l5.017 5.078a.5.5 0 0 1-.355.851h-4.887a.5.5 0 0 1-.355-.148l-2.583-2.604a.5.5 0 0 1 0-.704Z"
        fill="url(#c)"
      />
      <Path
        d="m17.11 11.954 2.45-2.47a.5.5 0 0 1 .713.005l1.215 1.255a.5.5 0 0 1 .14.348V16a.5.5 0 0 1-.854.352l-3.664-3.694a.5.5 0 0 1 0-.704Z"
        fill="url(#d)"
      />
      <Path
        d="M13.62 3.482.855 16.352A.5.5 0 0 1 0 16v-4.896a.5.5 0 0 1 .145-.352L10.463.355a.5.5 0 0 1 .706-.004l2.447 2.424a.5.5 0 0 1 .004.707Z"
        fill="url(#e)"
      />
      <Path
        d="m.895 19.121 5.013-5.052a.5.5 0 0 1 .71 0l5.031 5.052a.5.5 0 0 1-.354.853H1.25a.5.5 0 0 1-.355-.853Z"
        fill="url(#f)"
      />
      <Path
        d="M40.547 17.869h3.022L38.436 2.552h-3.674l-5.133 15.317h3.022l1.069-3.39h5.732l1.095 3.39Zm-1.902-5.99H34.58l1.772-5.491h.52l1.773 5.49ZM63.138 6.965H60.48V17.87h2.658V6.965ZM61.809 2c-.99 0-1.824.814-1.824 1.813 0 .972.834 1.786 1.824 1.786.964 0 1.772-.814 1.772-1.786C63.58 2.788 62.799 2 61.809 2ZM67.724 17.869h4.064L75.28 6.965h-2.788l-2.528 8.119h-.442l-.026-.053-2.528-8.066h-2.762l3.518 10.904ZM80.57 6.86c-2.684 0-5.394 1.892-5.394 5.465 0 3.232 2.345 5.649 5.472 5.649 2.215 0 4.065-1.261 4.873-3.284h-2.632c-.13.157-.782.92-2.163.92-1.198 0-2.606-.605-2.944-2.34l-.026-.078h7.999c.13-1.97-.339-3.6-1.381-4.729-.886-1.025-2.293-1.603-3.804-1.603Zm2.501 4.23h-5.107l.026-.079c.443-1.182 1.433-1.865 2.658-1.865.86 0 1.98.315 2.397 1.892l.026.052ZM92.009 6.86c-2.866 0-5.029 2.391-5.029 5.57 0 3.18 2.267 5.57 5.133 5.57 1.12 0 2.033-.394 2.736-1.156l.026-.026h.313l.313 1.05h2.032V2.343h-2.606v5.832l-.104-.131c-.73-.788-1.667-1.183-2.814-1.183Zm.417 8.539c-1.329 0-2.736-.946-2.736-2.969 0-2.023 1.407-2.969 2.736-2.969 1.511 0 2.475 1.183 2.475 2.97 0 1.786-.964 2.968-2.475 2.968ZM58.734 6.965c-1.745 0-2.814.815-3.309 1.314l-.026.026h-.339l-.416-1.313h-2.085v10.903h2.788v-5.412c0-2.128 1.72-2.89 3.205-2.89.339 0 .678.026.886.079V6.965h-.704ZM50.813 6.965c-1.746 0-2.814.815-3.31 1.314l-.025.026h-.34l-.416-1.313h-2.084v10.903h2.788v-5.412c0-2.128 1.72-2.89 3.204-2.89.34 0 .678.026.886.079V6.965h-.703Z"
        fill={props.color} // we set this to the "color" prop as the default fill is 'none'
      />
      <Defs>
        <LinearGradient id="a" x1="-6.212" y1="-7.454" x2="20.292" y2="19.878" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient id="b" x1="-6.212" y1="-7.454" x2="20.292" y2="19.878" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient id="c" x1="-6.212" y1="-7.454" x2="20.292" y2="19.878" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient id="d" x1="-6.212" y1="-7.454" x2="20.292" y2="19.878" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient id="e" x1="-6.212" y1="-7.454" x2="20.292" y2="19.878" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient id="f" x1="-6.212" y1="-7.454" x2="20.292" y2="19.878" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#C0C9D4" />
          <Stop offset={1} stopColor="#156CD8" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export const ArrivedIconWithText = memo<IconProps>(withIconTheme(ArrivedIconWithTextInternal));
