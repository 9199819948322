import { useMemo } from 'react';

import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Upload from '@mui/icons-material/Upload';
import { Box, BoxProps, Typography, alpha } from '@mui/material';

import { DocumentFileFormat } from '@arrived/models';

export interface DragAndDropInputProps extends BoxProps {
  dropzoneOptions: DropzoneOptions;
}

export const IMAGE_PDF_DROPZONE_ACCEPT: DropzoneOptions['accept'] = {
  'image/png': [`.${DocumentFileFormat.PNG}`],
  'image/jpeg': [`.${DocumentFileFormat.JPEG}`, `.${DocumentFileFormat.JPG}`],
  'application/pdf': [`.${DocumentFileFormat.PDF}`],
};

export const DragAndDropInput = ({ dropzoneOptions, sx, ...rest }: DragAndDropInputProps) => {
  const { disabled, maxSize } = dropzoneOptions;
  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions);

  const maxSizeMb = useMemo(() => (maxSize != null ? maxSize / Math.pow(10, 6) : undefined), [maxSize]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
      p={2}
      border={({ palette }) => `1px dashed ${alpha(palette.darkened.steel, 0.4)}`}
      sx={{ cursor: disabled ? 'not-allowed' : 'pointer', ...sx }}
      {...rest}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Upload sx={{ color: ({ palette }) => palette.neutrals.platinum }} />
      <Typography variant="body2.medium">
        {isDragActive ? (
          <FormattedMessage id="drop-files-here" defaultMessage="Drop your files here" />
        ) : (
          <FormattedMessage
            id="drag-drop-instructions"
            defaultMessage="Drag and drop or <link>browse</link> your files"
            values={{
              link: (parts) => (
                <Typography sx={{ color: ({ palette }) => palette.primary.main, textDecoration: 'underline' }}>
                  {parts}
                </Typography>
              ),
            }}
          />
        )}
      </Typography>
      {maxSizeMb != null && (
        <Typography color={({ palette }) => palette.neutrals.gray} variant="body1">
          <FormattedMessage
            id="max-file-size"
            defaultMessage="(max size: {value}mb)"
            values={{ value: <FormattedNumber value={maxSizeMb} maximumFractionDigits={2} /> }}
          />
        </Typography>
      )}
      <Box
        display={disabled ? 'initial' : 'none'}
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        sx={{ backgroundColor: ({ palette }) => alpha(palette.neutrals.white, 0.7) }}
      />
    </Box>
  );
};
