import { FormattedMessage } from 'react-intl';

import { Markdown } from '@arrived/bricks-common';
import { Offering } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../content';

export type AboutSectionProps = ProductDetailContentProps & { offering: Offering };

export const AboutSection = ({ children, offering, ...rest }: AboutSectionProps) => (
  <ProductDetailContent {...rest}>
    <ProductDetailContent.Header>
      <FormattedMessage id="common.about" />
    </ProductDetailContent.Header>
    <ProductDetailContent.Body>
      {/* TODO: We were previously allowing marketing to add {br} elements as line breaks but those won't work anymore so just replace with newline characters */}
      <Markdown Text={ProductDetailContent.Text}>{offering.description.replaceAll('{br}', '\n')}</Markdown>
      {children}
    </ProductDetailContent.Body>
  </ProductDetailContent>
);
