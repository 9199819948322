import {
  Account,
  AccountBlockAudit,
  AccountBlocksRequest,
  AccountCid,
  AccountId,
  Beneficiary,
  UserId,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getPrimaryAccount = (userId?: UserId) =>
  createQuery<Account>({
    apiInstance: api,
    method: 'get',
    url: `/users/${userId}/accounts/primary`,
    config: {
      validateStatus: (status) => status === 404 || status < 300,
    },
  });

export const getAccountBeneficiaries = (accountId: AccountId) =>
  createQuery<Beneficiary[]>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/beneficiaries`,
  });

export const putAccountBeneficiaries = ({
  accountId,
  beneficiaries,
}: {
  accountId: AccountId;
  beneficiaries: Beneficiary[];
}) =>
  createQuery<Beneficiary[]>({
    apiInstance: api,
    method: 'put',
    url: `/users/accounts/${accountId}/beneficiaries`,
    requestData: beneficiaries,
  });

export const updateAccountBlocks = (requestData: AccountBlocksRequest) =>
  createQuery<Account>({
    apiInstance: api,
    method: 'post',
    url: `/accounts/${requestData.accountCid}/update-blocks`,
    requestData: requestData.accountBlocks,
  });

export const getAccountBlockAudits = (accountCid: AccountCid) =>
  createQuery<AccountBlockAudit[]>({
    apiInstance: api,
    method: 'get',
    url: `/accounts/${accountCid}/block-audits`,
  });
