import { useQuery } from '@tanstack/react-query';

import { getQuestionnaire } from '@arrived/api_v2';
import { QuestionnaireId } from '@arrived/models';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { questionnaireKeyFn } from './questionnaires.keys';

export function useGetQuestionnaireQuery(
  id: QuestionnaireId,
  options?: UseAwaitedQueryOptions<typeof getQuestionnaire>,
) {
  return useQuery({
    queryKey: questionnaireKeyFn(id),
    queryFn: () => getQuestionnaire(id),
    staleTime: MAX_STALE_TIME,
    enabled: options?.enabled ?? true,
  });
}
