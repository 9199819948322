import { Fragment, useCallback, useMemo } from 'react';

import { AnimatePresence } from '@tamagui/animate-presence';
import { GetProps, styled } from '@tamagui/core';

import { Stack } from '../../atoms';
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons';
import { useArrowKeydown } from '../../utils';

import { useCarouselState } from './CarouselStateContext';
import { ARROWS_BUTTON_FRAME_NAME, ARROWS_BUTTON_NAME, ARROWS_FRAME_NAME } from './consants';
import { useGalleryState } from './GalleryStateContext';
import { GalleryStyledContext } from './GalleryStyledContext';

const ArrowsFrame = styled(AnimatePresence, {
  name: ARROWS_FRAME_NAME,
});

const ArrowsButtonFrame = styled(Stack, {
  name: ARROWS_BUTTON_FRAME_NAME,

  animation: 'quick',

  position: 'absolute',
  zIndex: '$docked',
  alignItems: 'center',
  opacity: 1,

  enterStyle: {
    opacity: 0,
  },

  exitStyle: {
    opacity: 0,
  },

  variants: {
    edge: {
      left: {
        left: '$4',
      },

      right: {
        right: '$4',
      },
    },
  } as const,
});

const ArrowsButton = styled(Stack, {
  name: ARROWS_BUTTON_NAME,

  button: true,
  tag: 'button',
  cursor: 'pointer',
  borderRadius: '$2',
  overflow: 'hidden',
});

export type ArrowsProps = Omit<GetProps<typeof ArrowsButtonFrame>, 'edge' | 'top'> & {
  /**
   * If you want to control when the arrows are shown, you can pass a boolean here.
   */
  disableHover?: boolean;
};

export const Arrows = ({ disableHover, ...props }: ArrowsProps) => {
  const { carouselRef } = useGalleryState();
  const { isHovered } = GalleryStyledContext.useStyledContext();
  const { layout } = useCarouselState();

  const handleOnArrowPress = useCallback(
    (direction: 'left' | 'right') => {
      carouselRef.current?.[direction === 'left' ? 'prev' : 'next']?.();
    },
    [carouselRef],
  );

  const showArrows = useMemo(() => {
    if (typeof disableHover !== 'undefined') {
      return disableHover;
    }

    return isHovered;
  }, [disableHover, isHovered]);

  const top = useMemo(() => (layout?.height ?? 0) / 2 - 12, [layout?.height]);

  useArrowKeydown({
    onLeftKeydown: () => handleOnArrowPress('left'),
    onRightKeydown: () => handleOnArrowPress('right'),
  });

  return (
    <ArrowsFrame>
      {showArrows && (
        <Fragment key="arrow-navigation">
          <ArrowsButtonFrame edge="left" top={top} {...props}>
            <ArrowsButton onPress={() => handleOnArrowPress('left')}>
              <Stack bg="$onSurface.neutral.containerAlt" fullscreen />
              <Stack zIndex="$docked" p="$2">
                <ArrowLeftIcon size="$5" color="$onSurface.neutral.zebra" />
              </Stack>
            </ArrowsButton>
          </ArrowsButtonFrame>

          <ArrowsButtonFrame edge="right" top={top} {...props}>
            <ArrowsButton onPress={() => handleOnArrowPress('right')}>
              <Stack bg="$onSurface.neutral.containerAlt" fullscreen />
              <Stack zIndex="$docked" p="$2">
                <ArrowRightIcon size="$5" color="$onSurface.neutral.zebra" />
              </Stack>
            </ArrowsButton>
          </ArrowsButtonFrame>
        </Fragment>
      )}
    </ArrowsFrame>
  );
};
