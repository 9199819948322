import _filter from 'lodash/filter';
import _last from 'lodash/last';

import {
  FundErrorStatus,
  FundErrorStatusCode,
  FundStatus,
  OfferingTransaction,
  OfferingTransactionFundStatus,
} from '@arrived/models';

import { getFundErrorStatusDisplayTest } from './getFundErrorStatusDisplayText';
import { parseErrorCodeFromFundErrorStatus } from './parseErrorCodeFromFundErrorStatus';

const fundStatusMatcher = (fundStatusRecord: OfferingTransactionFundStatus, fundStatus: FundStatus): boolean => {
  return fundStatusRecord.status === fundStatus;
};

export interface TradeFundStatus {
  status: FundStatus;
  error?: FundErrorStatus;
  errorCode?: FundErrorStatusCode;
  errorMessage?: string;
}

export const getTradeFundStatus = (
  transaction: OfferingTransaction,
  fundStatuses: OfferingTransactionFundStatus[] = [],
): TradeFundStatus => {
  const status: FundStatus = transaction.fundStatus;

  switch (status) {
    // no errors to return for these states.
    case FundStatus.PENDING:
    case FundStatus.SUBMITTED:
    case FundStatus.SETTLED: {
      return {
        status: status,
      };
    }

    // potential errors
    case FundStatus.RETURNED:
    case FundStatus.VOIDED:
    case FundStatus.DECLINED: {
      const latestStatusRecord: OfferingTransactionFundStatus | undefined = _last(
        _filter(fundStatuses, (record) => fundStatusMatcher(record, status)),
      );
      return {
        status: status,
        error: latestStatusRecord?.errorStatus,
        errorCode: parseErrorCodeFromFundErrorStatus(latestStatusRecord?.errorStatus),
        errorMessage: getFundErrorStatusDisplayTest(latestStatusRecord?.errorStatus),
      };
    }
  }
};
