import { Box, BoxProps } from '@mui/material';

import { Constants } from '@arrived/common';

export const Loading = (props: BoxProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%" {...props}>
      <img src={Constants.arrivedLoadingLogoTransparentDataUri} width="64px" height="64px" alt="Loading..." />
    </Box>
  );
};
