export type OfferingDocumentId = OfferingDocument['id'];

export const OfferingDocumentFormat = {
  PDF: 'PDF',
  URL: 'URL',
} as const;

export type OfferingDocumentFormat = (typeof OfferingDocumentFormat)[keyof typeof OfferingDocumentFormat];

export interface OfferingDocumentBase {
  id: number;
  ncId: string;
  offeringId: number;
  ncOfferingId: string;
  type: OfferingDocumentType;
  createdAt: string;
  active: boolean;
  format: OfferingDocumentFormat;
  year: number | null;
}

export interface OfferingFileDocument extends OfferingDocumentBase {
  fileName?: string;
  format: 'PDF';
}

export interface OfferingUrlDocument extends OfferingDocumentBase {
  fileUrl: string;
  format: 'URL';
}

export type OfferingDocument = OfferingFileDocument | OfferingUrlDocument;

export const OfferingDocumentType = {
  OFFERING_CIRCULAR: 'OFFERING_CIRCULAR',
  OFFERING_CIRCULAR_SUPPLEMENT: 'OFFERING_CIRCULAR_SUPPLEMENT',
  RISK_FACTORS: 'RISK_FACTORS',
  SERIES_OVERVIEW: 'SERIES_OVERVIEW',
  USE_OF_PROCEEDS: 'USE_OF_PROCEEDS',
  PRIVATE_PLACEMENT_MEMORANDUM: 'PRIVATE_PLACEMENT_MEMORANDUM',
  FORM_1SA: 'FORM_1SA',
  FORM_1K: 'FORM_1K',
  PURCHASE_AGREEMENT: 'PURCHASE_AGREEMENT',
  PURCHASE_AGREEMENT_SUPPLEMENT: 'PURCHASE_AGREEMENT_SUPPLEMENT',
  OFFERING_MEMORANDUM_SUPPLEMENT: 'OFFERING_MEMORANDUM_SUPPLEMENT',
} as const;

export type OfferingDocumentType = (typeof OfferingDocumentType)[keyof typeof OfferingDocumentType];

export interface OfferingDocumentUploadRequest {
  offeringId: number;
  type: OfferingDocumentType;
  format: OfferingDocumentFormat;
  file?: File;
  fileUrl?: string;
}
