import { Skeleton } from '../../../atoms';

import { SecondarySpotButtonsWrapper } from './SecondarySpotButtonsWrapper';

export const SecondarySpotSkeletonLoader = () => (
  <SecondarySpotButtonsWrapper>
    <Skeleton.Rect
      key="skeleton"
      animation="quick"
      enterStyle={{ opacity: 0 }}
      exitStyle={{ opacity: 0 }}
      opacity={1}
      width="100%"
      height={40}
      $gtXs={{ width: 150 }}
    />
  </SecondarySpotButtonsWrapper>
);
