import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchQuestionResponseOptions } from '@arrived/api_v2';

import { questionResponseOptionsKeyFn } from './questionnaires.keys';

export function usePatchQuestionResponseOptionOrderMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchQuestionResponseOptions,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: questionResponseOptionsKeyFn(data.questionUUID) });
    },
  });
}
