import { Fragment, useMemo } from 'react';

import {
  BathTubIcon,
  BuildingIcon,
  DataPoint,
  Divider,
  GetProps,
  HotelBedIcon,
  RulerIcon,
  Stack,
  styled,
} from '@arrived/bricks';
import { Property } from '@arrived/models';

import { ProductHeaderDetailsName } from './constants';

const ProductHeaderDetailsFrame = styled(Stack, {
  name: ProductHeaderDetailsName,

  flexDirection: 'row',
  gap: '$4',
  alignItems: 'center',
  // Super minor optimization to make the layout exactly the same whether or not the Booking link is present (which is just slightly taller than this component)
  py: '$0.5',
});

export const Details = ProductHeaderDetailsFrame.styleable<
  Omit<GetProps<typeof ProductHeaderDetailsFrame>, 'children'> & { property: Property }
>(({ property, ...rest }, ref) => {
  const details = useMemo(
    () => [
      {
        icon: HotelBedIcon,
        value: property.bedrooms,
      },
      {
        icon: BathTubIcon,
        value: (property.fullBathrooms ?? 0) + (property.halfBathrooms ?? 0) * 0.5,
      },
      {
        icon: RulerIcon,
        value: property.squareFootage,
        label: 'Sqft',
      },
      {
        icon: BuildingIcon,
        value: property.yearBuilt,
      },
    ],
    [property],
  );

  return (
    <ProductHeaderDetailsFrame ref={ref} {...rest}>
      {details.map(({ icon, label, value }, idx) => (
        <Fragment key={idx}>
          {idx > 0 && <Divider.Vertical h={12} solid />}
          <DataPoint colors="dark" variant="minimized" inline>
            <DataPoint.Icon Icon={icon} />
            <DataPoint.Value>{value}</DataPoint.Value>
            {label && <DataPoint.Label>{label}</DataPoint.Label>}
          </DataPoint>
        </Fragment>
      ))}
    </ProductHeaderDetailsFrame>
  );
});
