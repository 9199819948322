import { Chip, ChipProps } from '@mui/material';

export interface AppreciationSelectionChipProps extends Omit<ChipProps, 'onChange'> {
  onChange: (appreciationPercentage: number) => void;
  selected?: boolean;
  value: number;
}

export const AppreciationSelectionChip = ({
  onChange,
  selected,
  sx,
  value,
  ...rest
}: AppreciationSelectionChipProps) => (
  <Chip
    clickable
    variant="outlined"
    onClick={() => onChange(value)}
    sx={{
      bgcolor: 'transparent',
      borderColor: ({ palette }) => (selected ? palette.primary.main : palette.neutrals.milk),
      borderRadius: 1,
      color: ({ palette }) => palette.neutrals.milk,
      fontWeight: ({ fontWeights }) => fontWeights.normal,
      textTransform: 'none',
      '&:hover': {
        bgcolor: 'transparent',
        borderWidth: '2px',
      },
      '& .MuiChip-label': {
        px: 0,
      },
      ...sx,
    }}
    {...rest}
  />
);
