import { useQuery } from '@tanstack/react-query';

import { getQuestion } from '@arrived/api_v2';
import { QuestionId } from '@arrived/models';
import { MAX_STALE_TIME } from '@arrived/queries';

import { questionKeyFn } from './questionnaires.keys';

export function useGetQuestionQuery(
  questionId: QuestionId,
  options?: {
    enabled?: boolean;
  },
) {
  return useQuery({
    queryKey: questionKeyFn(questionId),
    queryFn: () => getQuestion(questionId),
    staleTime: MAX_STALE_TIME,
    enabled: options?.enabled ?? true,
  });
}
