import { GetProps, Stack, styled, withStaticProperties } from '@arrived/bricks';

import { Address } from './Address';
import { LocationName } from './constants';
import { Map, Marker } from './map';

const LocationFrame = styled(Stack, {
  name: LocationName,

  borderRadius: '$2',
  overflow: 'hidden',
  flexDirection: 'row',
});

export const Location = withStaticProperties(LocationFrame, { Address, Map, Marker });

export type LocationProps = GetProps<typeof Location>;
