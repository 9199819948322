export default {
  'navigation.invest': 'Invest',
  'navigation.account': 'Account',
  'navigation.learn': 'Learn',
  'navigation.learn.about': 'About Us',
  'navigation.learn.press': 'Press',
  'navigation.learn.returns': 'Historical Returns',
  'navigation.learn.blog': 'Blog',
  'navigation.learn.help': 'Help & FAQ',
  'navigation.learn.how-it-works': 'How It Works',
  'navigation.learn.webinars': 'Webinars',
  'navigation.learn.market-spotlights': 'Market Spotlights',
  'navigation.about': 'About',
  'navigation.about.about': 'About Us',
  'navigation.about.press': 'Press',
  'navigation.about.returns': 'Historical Returns',
  'navigation.user.account': 'Account Settings',
  'navigation.user.logout': 'Log Out',
  'navigation.user.admin': 'Admin',
} as const;
