import { StackProps } from '../../atoms';

export const getDefaultStyles = (isInverted?: boolean, isLoading?: boolean, isDisabled?: boolean): StackProps => {
  if (isLoading) {
    return {
      disabled: true,
      pointerEvents: 'none',
      bg: isInverted ? '$interactive.neutral.focusInverted' : '$interactive.neutral.focus',
    };
  }

  if (isDisabled) {
    return {
      disabled: true,
      pointerEvents: 'none',
      bg: isInverted ? '$interactive.neutral.disabledInverted' : '$interactive.neutral.disabled',
    };
  }

  return {
    bg: isInverted ? '$interactive.neutral.restedInverted' : '$interactive.neutral.rested',
    focusStyle: {},
    hoverStyle: {
      bg: isInverted ? '$interactive.neutral.hoveredInverted' : '$interactive.neutral.hovered',
    },
    pressStyle: {
      bg: isInverted ? '$interactive.neutral.focusInverted' : '$interactive.neutral.focus',
    },
  };
};
