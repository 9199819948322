import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';

import {
  AvailabilityFilter,
  InvestmentCategory,
  LeverageFilter,
  OfferingFilter,
  OwnershipFilter,
  PropertyStatusFilter,
} from '@arrived/models';

export const searchParamsToOfferingFilter = (params: URLSearchParams): OfferingFilter => {
  const availability = params
    .get('availability')
    ?.split(',')
    ?.reduce(
      (availability, filter) =>
        (Object.values(AvailabilityFilter) as string[]).includes(filter)
          ? [...availability, filter as AvailabilityFilter]
          : availability,
      [],
    );
  const investmentCategory = params.get('investmentCategory');
  const leverage = params
    .get('leverage')
    ?.split(',')
    ?.reduce(
      (leverage, filter) =>
        (Object.values(LeverageFilter) as string[]).includes(filter)
          ? [...leverage, filter as LeverageFilter]
          : leverage,
      [],
    );
  const markets = params
    .get('markets')
    ?.split(',')
    ?.reduce((markets, filter) => {
      const parsed = parseInt(filter);
      return isNaN(parsed) ? markets : [...markets, parsed];
    }, []);
  const ownership = params
    .get('ownership')
    ?.split(',')
    ?.reduce(
      (ownership, filter) =>
        (Object.values(OwnershipFilter) as string[]).includes(filter)
          ? [...ownership, filter as OwnershipFilter]
          : ownership,
      [],
    );
  const rentStatus = params
    .get('rentStatus')
    ?.split(',')
    ?.reduce(
      (rentStatus, filter) =>
        (Object.values(PropertyStatusFilter) as string[]).includes(filter)
          ? [...rentStatus, filter as PropertyStatusFilter]
          : rentStatus,
      [],
    );

  return _omitBy(
    {
      availability: availability?.length ? availability : undefined,
      investmentCategory:
        investmentCategory && (Object.values(InvestmentCategory) as string[]).includes(investmentCategory)
          ? (investmentCategory as InvestmentCategory)
          : undefined,
      leverage: leverage?.length ? leverage : undefined,
      markets: markets?.length ? markets : undefined,
      ownership: ownership?.length ? ownership : undefined,
      rentStatus: rentStatus?.length ? rentStatus : undefined,
    },
    _isNil,
  );
};
