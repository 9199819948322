import { useQuery } from '@tanstack/react-query';

import { getOfferingSharePrices } from '@arrived/api_v2';
import { OfferingId } from '@arrived/models';
import { MAX_STALE_TIME, UseAwaitedQueryOptions, offeringSharePricesKeyFn } from '@arrived/queries';

export const useGetOfferingSharePricesQuery = (
  offeringId: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getOfferingSharePrices>,
) => {
  return useQuery({
    queryKey: offeringSharePricesKeyFn(offeringId),
    queryFn: () => getOfferingSharePrices(offeringId),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && offeringId != null,
  });
};
