import { useMutation } from '@tanstack/react-query';

import { deleteOfferingPhoto } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';

export function useDeleteOfferingPhotosMutation(options?: UseAwaitedMutationOptions<typeof deleteOfferingPhoto>) {
  return useMutation({
    mutationFn: deleteOfferingPhoto,
    ...options,
  });
}
