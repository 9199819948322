import { useMutation } from '@tanstack/react-query';

import { patchOfferingPhoto } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';

export function usePatchOfferingPhotosMutation(options?: UseAwaitedMutationOptions<typeof patchOfferingPhoto>) {
  return useMutation({
    mutationFn: patchOfferingPhoto,
    ...options,
  });
}
