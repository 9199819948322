import { useQuery } from '@tanstack/react-query';

import { getSFRFundMetadata } from '@arrived/api_v2';
import { OfferingCid, OfferingType } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { assetMetadataKey } from './assetMetadata.keys';

export function useGetSFRFundAssetMetadata(
  offeringCid?: OfferingCid,
  options?: UseAwaitedQueryOptions<typeof getSFRFundMetadata>,
) {
  return useQuery({
    queryKey: assetMetadataKey(offeringCid!, OfferingType.FUND),
    queryFn: () => getSFRFundMetadata(offeringCid!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && offeringCid != null,
  });
}
