import { forwardRef } from 'react';

import { GetProps, Stack, TamaguiElement, createStyledContext, styled, useProps } from '@tamagui/core';

import { UtilityText } from '../../text';

export const FORM_ERROR_CONTAINER_NAME = 'FormErrorContainer';
const FORM_ERROR_TEXT_NAME = 'FormErrorText';

const BaseFormErrorContainer = styled(Stack, {
  name: FORM_ERROR_CONTAINER_NAME,

  backgroundColor: '$surface.negative.defaultAlt',

  flexDirection: 'row',
  alignItems: 'center',
  gap: '$1',
});

const FormAlertStyledContext = createStyledContext({ info: false });

export const FormAlertContainer = styled(BaseFormErrorContainer, {
  name: FORM_ERROR_CONTAINER_NAME,
  context: FormAlertStyledContext,

  paddingHorizontal: '$4',
  paddingVertical: '$4',

  variants: {
    info: {
      true: {
        backgroundColor: '$surface.primary.default',
      },
    },
  } as const,
});

const FormInputErrorContainer = styled(BaseFormErrorContainer, {
  name: FORM_ERROR_CONTAINER_NAME,
  borderColor: '$surface.negative.default',
  borderLeftWidth: 1,
  borderRightWidth: 1,
  borderBottomWidth: 1,
  borderBottomLeftRadius: '$1',
  borderBottomRightRadius: '$1',

  paddingHorizontal: '$4',
  paddingVertical: '$2',
});

export const FormAlertText = styled(UtilityText, {
  name: FORM_ERROR_TEXT_NAME,

  token: 'utility.helper.small',
  color: '$onSurface.negative.default',
  pointerEvents: 'none',
});

const FormErrorComponent = forwardRef<TamaguiElement, GetProps<typeof FormInputErrorContainer>>((propsIn, ref) => {
  const { children, ...rest } = useProps(propsIn);

  return (
    <FormInputErrorContainer ref={ref} {...rest}>
      <FormAlertText>{children}</FormAlertText>
    </FormInputErrorContainer>
  );
});

export const FormError = FormInputErrorContainer.styleable(FormErrorComponent);
export type FormErrorProps = GetProps<typeof FormInputErrorContainer>;
