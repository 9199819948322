import { useCallback } from 'react';

import { useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';

import { getOfferingDocumentRaw } from '@arrived/api_v2';
import { Loading } from '@arrived/bricks';
import { openPdf } from '@arrived/common';
import { MAX_STALE_TIME, offeringDocumentRawKeyFn, useGetOfferingDocumentRawQuery } from '@arrived/queries';

import { PDFItemProps } from './PDFItemProps';
import { StyledInfoBox } from './StyledInfoBox';

/**
 * Used for displaying Offering Documents on the web.
 *
 * Queries for the provided document, shows a loading state, and opens the file in a new tab.
 */
export const PDFItem = ({ children, document }: PDFItemProps) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  // Setting enabled: false means this usage of the query won't actually trigger a fetch but will rather just return
  // us the state of the query so we can use it for a loading indicator.
  const documentRawState = useGetOfferingDocumentRawQuery(document.id, {
    enabled: false,
  });

  const handleOnClick = useCallback(() => {
    queryClient
      .fetchQuery({
        queryKey: offeringDocumentRawKeyFn(document.id),
        queryFn: () => getOfferingDocumentRaw(document.id),
        staleTime: MAX_STALE_TIME,
      })
      .then((data) => openPdf(data));
  }, [queryClient]);

  return (
    <StyledInfoBox
      aria-label={intl.formatMessage({ id: 'product-details.open-document' })}
      tag="button"
      role="button"
      onPress={handleOnClick}
    >
      {documentRawState.isFetching && documentRawState.isLoading ? (
        <Loading row width="100%" justifyContent="center" py="$0.25">
          <Loading.Indicator variant="colored" size="$4" />
        </Loading>
      ) : (
        children
      )}
    </StyledInfoBox>
  );
};
