import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getAccountVouchers } from '@arrived/api_v2';
import { Rewards } from '@arrived/models';
import { getOrCreateRewardsAccountId } from '@arrived/queries';

import { adminVoucherKeyFn, adminVoucherQueryKeyFn } from './rewards.keys';

// @Stith87 - Please take a look at this, I'm not sure what the goal is here
export function useAdminGetVouchersQuery(userInfo: Rewards.RewardsUserInfo) {
  const queryClient = useQueryClient();

  const adminVoucherState = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: adminVoucherKeyFn(userInfo.userId!),
    queryFn: async () => {
      const rewardsAccountUuid = await getOrCreateRewardsAccountId(queryClient, userInfo);
      return getAccountVouchers(rewardsAccountUuid);
    },
  });

  useEffect(() => {
    if (adminVoucherState.data) {
      adminVoucherState.data?.vouchers?.forEach((voucher) => {
        queryClient.setQueryData(adminVoucherQueryKeyFn(userInfo.userId!, voucher), voucher);
      });
    }
  }, [adminVoucherState.data]);

  return adminVoucherState;
}
