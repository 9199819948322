import {
  Lease,
  OfferingCid,
  PropertyId,
  PropertyStatusHistory,
  PropertyStatusHistoryPatchRequest,
  PropertyStatusHistoryPostRequest,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getOfferingHistoryByCid = (offeringCid: OfferingCid) =>
  createQuery<PropertyStatusHistory[]>({
    apiInstance: api,
    method: 'get',
    url: `/property-statuses/${offeringCid}`,
  });

export const getOfferingHistoryByPropertyIdAndStatus = (propertyId: PropertyId, status: string) =>
  createQuery<PropertyStatusHistory[]>({
    apiInstance: api,
    method: 'get',
    url: `/property-statuses/property/${propertyId}/status/${status}`,
  });

export const getOfferingNameByOfferingHistoryCid = (offeringHistoryCid: string) =>
  createQuery<string>({
    apiInstance: api,
    method: 'get',
    url: `/property-statuses/${offeringHistoryCid}/offering`,
  });

export const getOfferingHistoryByPropertyId = (propertyId: PropertyId) =>
  createQuery<PropertyStatusHistory[]>({
    apiInstance: api,
    method: 'get',
    url: `/properties/${propertyId}/property-status-histories`,
  });

export const getPendingOfferingHistory = () =>
  createQuery<PropertyStatusHistory[]>({
    apiInstance: api,
    method: 'get',
    url: `/property-statuses/pending`,
  });

export const patchOfferingHistory = (propertyStatusHistory: PropertyStatusHistoryPatchRequest) =>
  createQuery<PropertyStatusHistory>({
    apiInstance: api,
    method: 'patch',
    url: `/property-statuses/${propertyStatusHistory.cid}`,
    requestData: propertyStatusHistory,
  });

export const postOfferingHistory = (propertyStatusHistory: PropertyStatusHistoryPostRequest) =>
  createQuery<PropertyStatusHistory>({
    apiInstance: api,
    method: 'post',
    url: `/property-statuses`,
    requestData: propertyStatusHistory,
  });

export const getOfferingHistoryLease = (offeringHistoryCid: string) =>
  createQuery<Lease>({
    apiInstance: api,
    method: 'get',
    url: `/property-statuses/${offeringHistoryCid}/lease`,
  });
