import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchMarket } from '@arrived/api_v2';
import { UseAwaitedMutationOptions, marketsKey, marketsKeyFn } from '@arrived/queries';

export function usePatchMarketMutation(options?: UseAwaitedMutationOptions<typeof patchMarket>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchMarket,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: marketsKey });
      queryClient.invalidateQueries({ queryKey: marketsKeyFn(data.id) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
