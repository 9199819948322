/**
 * Structure of information on an individual short term rental offering.
 *
 * NOTE: Be sure that changes to this interface stay in sync with the data being returned by the
 * lambda defined in https://github.com/arrivedhomes/arrived-sst.
 */
export interface STRMetadata {
  airBnbLink?: string;
  amenities: string[];
  estimatedLaunchDate: string;
  market: {
    descriptionMarkdown: string;
    marketGradeImageSrc?: string;
  };
  propertyManagement: PropertyManagementPartnerData;
  feeData: {
    aumGrossRevenues: string;
    pmGrossRentsText: string;
  };
  historicalData?: {
    dailyRateChartImageSrc: string;
    monthlyRevenueChartImageSrc: string;
    occupancyChartImageSrc: string;
  };
}

export interface SFRFundData {
  inceptionDate: string;
  investmentStrategy?: {
    attributes: string;
    video?: FundInvestmentStrategyVideoType;
  };
  markets?: {
    description: string;
    video?: FundInvestmentStrategyVideoType;
  };
  liquidityAndRedemptions?: {
    description: string;
    feeList: Array<{ label: string; value: string }>;
  };
  assetManagementFees?: {
    description: string;
    aumFee: {
      value: number;
      tooltip?: string;
      frequency: AumFeeFrequency;
    };
  };
  offeringFees?: {
    description: string;
  };
  commonQuestions: CommonQuestion[];
  fundPerformance?: {
    intro: string;
    rentalIncome: {
      text: string;
      tooltip: string;
    };
    appreciation: {
      text: string;
      value: string;
      tooltip: string;
    };
    historicalPerformance: string;
    quarterlyPerformanceText: string;
  };
  ipoPerformance?: {
    details?: string;
    header: string;
    introText?: string;
    images: Array<{
      uri: string;
      aspectRatio: string;
      alt: string;
    }>;
  };
}

export const SSTDocumentType = {
  NOTE_PURCHASE_AGREEMENT: 'NOTE_PURCHASE_AGREEMENT',
  NOTE_SUPPLEMENT: 'NOTE_SUPPLEMENT',
  NOTE_SUPPLEMENT_TERMS: 'NOTE_SUPPLEMENT_TERMS',
  PRIVATE_PLACEMENT_MEMORANDUM: 'PRIVATE_PLACEMENT_MEMORANDUM',
} as const;

export type SSTDocumentType = (typeof SSTDocumentType)[keyof typeof SSTDocumentType];

/**
 * Structure of information on an individual short term note offering.
 *
 * NOTE: Be sure that changes to this interface stay in sync with the data being returned by the
 * lambda defined in https://github.com/arrivedhomes/arrived-sst.
 */
export interface STNMetadata {
  about: string;
  programThesis: string;
  assetManagementFees: {
    description: string;
    aumFee: {
      value: number;
      tooltip?: string;
      frequency?: AumFeeFrequency;
    };
  };
  documents: {
    id: number;
    type: SSTDocumentType;
    link: string;
  }[];
  commonQuestions: CommonQuestion[];
}

/**
 * Structure of information on an individual debt fund offering.
 *
 * NOTE: Be sure that changes to this interface stay in sync with the data being returned by the
 * lambda defined in
 **/
export interface PrivateCreditFundMetadata {
  inceptionDate: string;
  about: string;
  fundHighlights: string;
  dividends: string;
  investmentStrategy: {
    intro: string;
    video?: FundInvestmentStrategyVideoType;
    strategy: string;
    webinarVideo?: FundInvestmentStrategyVideoType;
  };
  returns: string;
  anticipatedCashFlow: {
    title: string;
    description: string;
    investmentAmountLabel: string;
    investmentAmountValue: string;
    netInterestEarnedLabel: string;
    netInterestEarnedValue: string;
    arrivedFeesLabel: string;
    arrivedFeesValue: string;
    arrivedFeesTooltipText: string;
    creditLossAccrualLabel: string;
    creditLossAccrualValue: string;
    creditLossAccrualTooltipText: string;
    dividendsPaidLabel: string;
    dividendsPaidValue: string;
    anticipatedAnnualCashFlowYieldLabel: string;
    anticipatedAnnualCashFlowYieldValue: string;
    anticipatedAnnualCashFlowYieldTooltipText?: string;
  };
  targetYield: string;
  offeringFees?: {
    description: string;
  };
  annualizedReturnScenarios?: {
    description?: string;
    tableHeaders?: string[];
    returns: Array<{ label: string; value: string }>;
    disclaimer?: string;
    title: string;
  };
  liquidityAndRedemptions?: {
    description: string;
    feeList: Array<{ label: string; value: string }>;
  };
  assetManagementFees?: {
    description: string;
    aumFee: {
      value: number;
      tooltip?: string;
      frequency: AumFeeFrequency;
    };
  };
  documents: {
    title: string;
    link: string;
  }[];
  introToRealEstateDebt: {
    description: string;
  };
  realEstateBackedDebtVsEquity: {
    description: string;
    comparison: Array<{ title: string; debt: string; equity: string }>;
  };
  capitalStack: {
    title: string;
    description: string;
  };
  capitalStackBlogCallout: string;
  images: {
    uri: string;
    aspectRatio: string;
    alt: string;
  }[];
  commonQuestions: CommonQuestion[];
  footnotes: string;
}

export interface RegDIPOMetadata {
  commonQuestions: CommonQuestion[];
}

export interface CommonQuestion {
  question: string;
  answer: string;
}

/**
 * Structure of information on an individual PM partner.
 *
 * NOTE: Be sure that changes to this interface stay in sync with the data being returned by the
 * lambda defined in https://github.com/arrivedhomes/arrived-sst.
 */
export interface PropertyManagementPartnerData {
  name: string;
  descriptionMarkdown: string;
  imageSrc?: string;
  directLink?: string;
}

/**
 * Structure of information on an Offering's video metadata
 *
 * NOTE: Be sure that changes to this interface stay in sync with the data being returned by the
 * lambda defined in https://github.com/arrivedhomes/arrived-sst.
 */
export interface OfferingVideoMetadata {
  youtubeId: string;
  position: number;
}

export type FundInvestmentStrategyVideoType = {
  youtubeId: string;
  imageUri?: string;
  title: string;
  subTitle?: string;
};

export interface PcfImageType {
  uri: string;
  aspectRatio: string;
  alt: string;
}

export enum AumFeeFrequency {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annual',
}
