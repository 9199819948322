import { useMemo } from 'react';

import { GetProps, Stack, parseRootChildren, styled, useProps, withStaticProperties } from '@arrived/bricks';

import {
  PRODUCT_LIST_ITEM_DETAILS_NAME,
  PRODUCT_LIST_ITEM_IMAGE_NAME,
  PRODUCT_LIST_ITEM_NAME,
  PRODUCT_LIST_ITEM_SUBTITLE_NAME,
  PRODUCT_LIST_ITEM_TAGS_NAME,
  PRODUCT_LIST_ITEM_TITLE_NAME,
} from './constants';
import { ProductListItemDetails } from './ProductListItemDetails';
import { ProductListItemImage } from './ProductListItemImage';
import { ProductListItemStyledContext } from './ProductListItemStyledContext';
import { ProductListItemSubtitle } from './ProductListItemSubtitle';
import { ProductListItemTags } from './ProductListItemTags';
import { ProductListItemTitle } from './ProductListItemTitle';

export const ProductListItemFrame = styled(Stack, {
  name: PRODUCT_LIST_ITEM_NAME,

  context: ProductListItemStyledContext,

  p: '$2',
  gap: '$2',
  row: true,
  alignItems: 'center',
  tag: 'button',
  bg: '$interactive.neutral.restedInverted',
  borderColor: 'transparent',

  // @ts-expect-error doesn't recognize group yet
  group: 'productListItem',

  hoverStyle: {
    cursor: 'pointer',
    bg: '$onSurface.neutral.zebra',
  },
  focusStyle: {
    bg: '$onSurface.neutral.zebra',
  },
  pressStyle: {
    bg: '$onSurface.neutral.zebraAlt',
  },

  variants: {
    bordered: {
      true: {
        borderWidth: '$0.25',
        borderColor: '$onSurface.neutral.outlineAlt',
        borderRadius: '$2',
      },
    },
    size: {
      small: {},
      large: {
        gap: '$4',
      },
    },
  } as const,
});

export const ProductListItem = withStaticProperties(
  ProductListItemFrame.styleable<GetProps<typeof ProductListItemFrame>>((propsIn, ref) => {
    const { children: childrenIn, ...rest } = useProps(propsIn);
    const {
      [PRODUCT_LIST_ITEM_IMAGE_NAME]: Image,
      [PRODUCT_LIST_ITEM_TITLE_NAME]: Title,
      [PRODUCT_LIST_ITEM_SUBTITLE_NAME]: Subtitle,
      [PRODUCT_LIST_ITEM_TAGS_NAME]: Tags,
      [PRODUCT_LIST_ITEM_DETAILS_NAME]: Details,
    } = useMemo(
      () =>
        parseRootChildren(childrenIn, [
          PRODUCT_LIST_ITEM_IMAGE_NAME,
          PRODUCT_LIST_ITEM_TITLE_NAME,
          PRODUCT_LIST_ITEM_SUBTITLE_NAME,
          PRODUCT_LIST_ITEM_TAGS_NAME,
          PRODUCT_LIST_ITEM_DETAILS_NAME,
        ]),
      [childrenIn],
    );

    return (
      <ProductListItemFrame ref={ref} {...rest}>
        {Image}
        <Stack gap="$2" $gtXxs={{ gap: '$3' }} flex={1}>
          <Stack row justifyContent="space-between" flex={1}>
            <Stack gap="$1" alignItems="flex-start" flex={1}>
              {Title}
              {Subtitle}
            </Stack>
            {Tags}
          </Stack>
          {Details}
        </Stack>
      </ProductListItemFrame>
    );
  }),
  {
    Image: ProductListItemImage,
    Title: ProductListItemTitle,
    Subtitle: ProductListItemSubtitle,
    Tags: ProductListItemTags,
    Details: ProductListItemDetails,
  },
);
export type ProductListItemProps = GetProps<typeof ProductListItem>;
