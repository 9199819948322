import { Fragment, forwardRef, useMemo } from 'react';
import { PixelRatio } from 'react-native';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { format } from 'date-fns';

import {
  CheckmarkCircleIcon,
  DividendPaymentIcon,
  Divider,
  EmptyState,
  Image,
  Sheet,
  SheetImperativeContext,
  SheetProps,
  Stack,
  Tag,
  UtilityText,
} from '@arrived/bricks';
import { useInvestmentProductType } from '@arrived/hooks';
import { ikClient } from '@arrived/imagekit';
import { AccountOfferingBalance, DividendEntry, InvestmentProductType, Offering } from '@arrived/models';

const emptyStateImage = ikClient.url({
  path: '/features/product-details/3-coins.png',
  transformation: [
    {
      width: `${PixelRatio.getPixelSizeForLayoutSize(140)}`,
      height: `${PixelRatio.getPixelSizeForLayoutSize(140)}`,
    },
  ],
});

export const DividendPaymentHistorySheet = forwardRef<
  SheetImperativeContext,
  SheetProps & { offering: Offering; offeringBalance: AccountOfferingBalance }
>(({ offering, offeringBalance, ...rest }, ref) => {
  const investmentProductType = useInvestmentProductType(offering);

  const dividendsByYear = useMemo(() => {
    return Object.entries(
      offeringBalance.rentalIncome.allDividends.reduce(
        (map, dividendEntry) => {
          const year = new Date(dividendEntry.postedAt).getFullYear();
          if (!Object.keys(map).includes(year.toString())) {
            map[year] = [];
          }

          map[year].push(dividendEntry);

          return map;
        },
        {} as Record<number, DividendEntry[]>,
      ),
    ).sort((a, b) => parseInt(b[0]) - parseInt(a[0]));
  }, [offeringBalance.rentalIncome.allDividends]);

  return (
    <Sheet ref={ref} {...rest}>
      <Sheet.Header>
        <Sheet.Header.Title>
          <Sheet.Header.Title.Text>
            <FormattedMessage id="product-details.dividend-payment-history" />
          </Sheet.Header.Title.Text>
          <Sheet.Header.CloseIcon />
        </Sheet.Header.Title>
      </Sheet.Header>
      <Sheet.Body centered={dividendsByYear.length === 0}>
        {dividendsByYear.length === 0 ? (
          <EmptyState centered maxWidth={280} hideGradient flex={1}>
            <Image source={{ uri: emptyStateImage }} width={140} height={140} />
            <Stack gap="$2">
              <EmptyState.Text>
                <FormattedMessage id="product-details.no-dividends-yet" />
              </EmptyState.Text>
              {investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO && (
                <EmptyState.Text>
                  <FormattedMessage id="product-details.dividend-eligibility-sfr" />
                </EmptyState.Text>
              )}
              {investmentProductType === InvestmentProductType.VACATION_RENTAL_IPO && (
                <EmptyState.Text>
                  <FormattedMessage id="product-details.dividend-eligibility-vr" />
                </EmptyState.Text>
              )}
            </Stack>
          </EmptyState>
        ) : (
          <Sheet.ScrollView useScrollFade bottomPadding>
            <Stack borderRadius="$2" overflow="hidden" borderColor="$onSurface.neutral.outlineAlt" borderWidth="$0.25">
              {dividendsByYear.map(([year, dividendEntries], idx) => (
                <Fragment key={year}>
                  {idx !== 0 && <Divider solid alt />}
                  <Stack bg="$onSurface.neutral.zebra" py="$3" px="$6">
                    <UtilityText color="$onSurface.neutral.muted" token="utility.label.xsmall">
                      {year}
                    </UtilityText>
                  </Stack>
                  {dividendEntries.map(({ totalAmount, postedAt }, idx) => (
                    <Fragment key={idx}>
                      <Divider solid alt />
                      <Stack p="$4" gap="$4">
                        <UtilityText color="$onSurface.neutral.muted" token="utility.label.small.alt">
                          {format(new Date(postedAt), 'MMM dd')}
                        </UtilityText>
                        <Stack row gap="$3">
                          <Stack bg="$onSurface.neutral.zebra" p="$2">
                            <DividendPaymentIcon />
                          </Stack>
                          <Stack gap="$2" flex={1}>
                            <Stack gap="$2" row justifyContent="space-between" width="100%">
                              <UtilityText token="utility.label.xsmall" ellipse>
                                <FormattedMessage
                                  id="product-details.dividend-payment-name"
                                  values={{ offeringName: offering.name }}
                                />
                              </UtilityText>
                              <UtilityText token="utility.label.xsmall">
                                <FormattedNumber
                                  value={totalAmount}
                                  style="currency"
                                  currency="USD"
                                  maximumFractionDigits={2}
                                  minimumFractionDigits={2}
                                />
                              </UtilityText>
                            </Stack>
                            <Tag alignSelf="flex-start" condensed variant="positive">
                              <Tag.Icon Icon={CheckmarkCircleIcon} />
                            </Tag>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </Stack>
          </Sheet.ScrollView>
        )}
      </Sheet.Body>
    </Sheet>
  );
});
