import { Tokenizer } from '../../../utils/tokenizer';
import { RootTextProps } from '../RootText';

type UtilityHelperVariantsBase = 'medium' | 'small';
type UtilityLegalVariantsBase = 'legal';
type UtilityHyperlinkVariantsBase = 'large' | 'medium' | 'small' | 'xsmall';
type UtilityButtonVariantsBase = 'large' | 'small' | 'xsmall';
type UtilityLabelVariantsBase = 'large' | 'medium' | 'small' | 'small.alt' | 'xsmall' | 'upper' | 'xxsmall';
type UtilityInputVariantsBase = 'input';
type UtilityHelperVariants = Tokenizer<'utility.helper', UtilityHelperVariantsBase>;
type UtilityLegalVariants = Tokenizer<'utility', UtilityLegalVariantsBase>;
type UtilityHyperlinkVariants = Tokenizer<'utility', UtilityLegalVariantsBase>;
type UtilityBodyCompactVariants = Tokenizer<'utility.hyperlink', UtilityHyperlinkVariantsBase>;
type UtilityButtonVariants = Tokenizer<'utility.label.button', UtilityButtonVariantsBase>;
type UtilityLabelVariants = Tokenizer<'utility.label', UtilityLabelVariantsBase>;
type UtilityInputVariants = Tokenizer<'utility', UtilityInputVariantsBase>;

export type UtilityVariants =
  | UtilityHelperVariants
  | UtilityLegalVariants
  | UtilityHyperlinkVariants
  | UtilityBodyCompactVariants
  | UtilityButtonVariants
  | UtilityLabelVariants
  | UtilityInputVariants;

export const UTILITY_VARIANTS: Record<UtilityVariants, RootTextProps> = {
  'utility.helper.medium': {
    fontSize: '$14',
    lineHeight: '$18',
    fontWeight: '$1',
  },
  'utility.helper.small': {
    fontSize: '$12',
    lineHeight: '$16',
    fontWeight: '$1',
  },
  'utility.legal': {
    fontSize: '$12',
    lineHeight: '$16',
    fontWeight: '$1',
  },
  'utility.hyperlink.large': {
    fontSize: '$18',
    fontWeight: '$1',
    lineHeight: '$22',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    color: '$interactive.primary.rested',
  },
  'utility.hyperlink.medium': {
    fontSize: '$16',
    fontWeight: '$1',
    lineHeight: '$22',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    color: '$interactive.primary.rested',
  },
  'utility.hyperlink.small': {
    fontSize: '$14',
    fontWeight: '$1',
    lineHeight: '$18',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    color: '$interactive.primary.rested',
  },
  'utility.hyperlink.xsmall': {
    fontSize: '$12',
    lineHeight: '$16',
    fontWeight: '$1',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  'utility.label.button.large': {
    fontSize: '$24',
    fontWeight: '$4',
    letterSpacing: '$0.04',
    textTransform: 'uppercase',
    lineHeight: '$28',
  },
  'utility.label.button.small': {
    fontSize: '$14',
    fontWeight: '$4',
    letterSpacing: '$0.04',
    textTransform: 'uppercase',
    lineHeight: '$16',
  },
  'utility.label.button.xsmall': {
    fontSize: '$12',
    fontWeight: '$4',
    letterSpacing: '$0.04',
    textTransform: 'uppercase',
    lineHeight: '$14',
  },
  'utility.label.large': {
    fontSize: '$20',
    fontWeight: '$4',
    lineHeight: '$18',
  },
  'utility.label.medium': {
    fontSize: '$18',
    fontWeight: '$4',
    // lineHeight: '$14',
    // TODO: Check with design about line heights being < font size as they get cut off on mobile. Can we set a standard that
    // line height >= font size?
    lineHeight: '$20',
  },
  'utility.label.small': {
    fontSize: '$16',
    fontWeight: '$4',
    // lineHeight: '$14',
    // TODO: Check with design about line heights being < font size as they get cut off on mobile. Can we set a standard that
    // line height >= font size?
    lineHeight: '$18',
  },
  'utility.label.small.alt': {
    fontSize: '$16',
    fontWeight: '$1',
    // lineHeight: '$14',
    // TODO: Check with design about line heights being < font size as they get cut off on mobile. Can we set a standard that
    // line height >= font size?
    lineHeight: '$18',
  },
  'utility.label.xsmall': {
    fontSize: '$14',
    fontWeight: '$4',
    lineHeight: '$12',
  },
  'utility.label.upper': {
    fontSize: '$14',
    fontWeight: '$2',
    textTransform: 'uppercase',
    lineHeight: '$16',
  },
  'utility.label.xxsmall': {
    fontSize: '$12',
    fontWeight: '$4',
    lineHeight: '$12',
  },
  'utility.input': {
    fontSize: '$16',
    lineHeight: '$16',
    fontWeight: '$1',
  },
};
