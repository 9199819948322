import { useMemo } from 'react';

import { GetProps, LinearGradient, gradients, parseRootChildren, useMedia } from '@arrived/bricks';

import { VIDEO_SUB_TITLE_TEXT_NAME, VIDEO_TITLE_TEXT_NAME } from './constants';
import { VideoFrame } from './VideoFrame';
import { VideoTitleFrame } from './VideoTitleFrame';
import { YoutubeVideoIcon } from './YoutubeVideoIcon';

export const VideoDisplay = VideoFrame.styleable<GetProps<typeof VideoFrame>>(
  ({ children: childrenIn, ...rest }, ref) => {
    const media = useMedia();

    const {
      [VIDEO_TITLE_TEXT_NAME]: Title,
      [VIDEO_SUB_TITLE_TEXT_NAME]: SubTitle,
      children,
    } = useMemo(() => parseRootChildren(childrenIn, [VIDEO_TITLE_TEXT_NAME, VIDEO_SUB_TITLE_TEXT_NAME]), [childrenIn]);

    const iconSizeProps = useMemo(
      () => (media.gtXs ? { width: 48, height: 48 } : { width: 40, height: 40 }),
      [media.gtXs],
    );

    return (
      <VideoFrame ref={ref} {...rest}>
        <LinearGradient
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          colors={gradients['gradient.neutral.yin']}
          // Have to add br here as well for mobile
          br="$2"
          $platform-web={{ br: 0 }}
        />
        {children}
        {(Title || SubTitle) && (
          <VideoTitleFrame>
            {Title}
            {SubTitle}
          </VideoTitleFrame>
        )}
        <YoutubeVideoIcon style={{ flexShrink: 0, marginLeft: 'auto', zIndex: 1 }} {...iconSizeProps} />
      </VideoFrame>
    );
  },
);
