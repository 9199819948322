import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postOfferingHistory } from '@arrived/api_v2';
import { UseAwaitedMutationOptions } from '@arrived/queries';

import { offeringHistoryPendingQueryKeyFn } from './offeringHistory.keys';

export const usePostOfferingHistoryMutation = (options?: UseAwaitedMutationOptions<typeof postOfferingHistory>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postOfferingHistory,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: offeringHistoryPendingQueryKeyFn() });

      options?.onSuccess?.(data, variables, context);
    },
  });
};
