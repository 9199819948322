import { useContext, useEffect, useMemo, useState } from 'react';

import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import {
  CellContext,
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

import { InfoIcon, Stack, Tooltip } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { NavigationBarContext } from '@arrived/contexts';
import { OfferingId } from '@arrived/models';

import { useIsMobile } from '../hooks';
import { Table, propertyNameAccessor } from '../Table';

import { HistoricalPerformance } from './historicalPerformance';

const CellBodyTypography = (props: TypographyProps) => {
  return <Typography variant="body1" color={({ palette }) => palette.neutrals.gray} {...props} />;
};

const AppreciationCell = ({ row, table, ...rest }: CellContext<HistoricalPerformance, unknown>) => {
  const intl = useIntl();

  const hasSharePriceBeenUpdated = useMemo(
    () =>
      (
        table.options.meta as unknown as { sharePriceUpdatedOfferingIds: OfferingId[] } | undefined
      )?.sharePriceUpdatedOfferingIds?.includes(row.original.id),
    [
      row.original.id,
      (table.options.meta as unknown as { sharePriceUpdatedOfferingIds: OfferingId[] } | undefined)
        ?.sharePriceUpdatedOfferingIds,
    ],
  );

  return (
    <>
      {hasSharePriceBeenUpdated ? (
        <PercentCell row={row} table={table} {...rest} />
      ) : (
        <CellBodyTypography>
          <Tooltip>
            <Tooltip.Trigger>
              <Stack row alignItems="center" gap="$1">
                <FormattedMessage id="historical-returns.awaiting-valuation" />
                <InfoIcon size="xs" />
              </Stack>
            </Tooltip.Trigger>
            <Tooltip.Content tooltipTitle={<FormattedMessage id="historical-returns.awaiting-valuation" />}>
              <Stack gap="$2">
                <Markdown Text={Tooltip.Text}>
                  {intl.formatMessage({ id: 'share-price-updates.description.markdown' })}
                </Markdown>
              </Stack>
            </Tooltip.Content>
          </Tooltip>
        </CellBodyTypography>
      )}
    </>
  );
};

const PercentCell = ({ getValue }: CellContext<HistoricalPerformance, unknown>) => {
  const value = getValue() as number;

  return (
    <CellBodyTypography>
      <FormattedNumber value={value} maximumFractionDigits={1} minimumFractionDigits={1} style="percent" />
    </CellBodyTypography>
  );
};

const MoneyCell = ({ getValue }: CellContext<HistoricalPerformance, unknown>) => {
  const value = getValue() as number;
  return (
    <CellBodyTypography>
      <FormattedNumber value={value} style="currency" currency="USD" />
    </CellBodyTypography>
  );
};

const DefaultCell = ({ getValue }: CellContext<HistoricalPerformance, unknown>) => (
  <CellBodyTypography>{getValue() as string | number}</CellBodyTypography>
);

const SharePriceToolTip = () => {
  const intl = useIntl();
  return (
    <Tooltip.Content tooltipTitle={<FormattedMessage id="historical-returns.share-price" />}>
      <Tooltip.Text>
        <Markdown Text={Tooltip.Text}>{intl.formatMessage({ id: 'historical-returns.share-price.tooltip' })}</Markdown>
      </Tooltip.Text>
    </Tooltip.Content>
  );
};

const createColumns = (isMobile: boolean): ColumnDef<HistoricalPerformance>[] => [
  {
    header: () => <FormattedMessage id="property-name" defaultMessage="Property Name" />,
    id: 'propertyName',
    accessorFn: (row) => propertyNameAccessor(row.name),
    cell: DefaultCell,
    size: 100,
  },
  {
    header: () => <FormattedMessage id="market" defaultMessage="Market" />,
    accessorKey: 'market',
    cell: DefaultCell,
    size: 100,
  },
  {
    header: () => <FormattedMessage id="months-held" defaultMessage="Months Held" />,
    accessorKey: 'monthsHeld',
    cell: DefaultCell,
    sortingFn: (rowA, rowB) => rowA.original.monthsHeld - rowB.original.monthsHeld,
    size: isMobile ? 100 : 70,
  },
  {
    header: () => (
      <Tooltip>
        <Tooltip.Trigger>
          <Stack row alignItems="center" gap="$1">
            <FormattedMessage id="historical-returns.annualized-rental-income" />
            <InfoIcon size="xs" />
          </Stack>
        </Tooltip.Trigger>
        <Tooltip.Content tooltipTitle={<FormattedMessage id="historical-returns.annualized-rental-income" />}>
          <Tooltip.Text>
            <FormattedMessage id="historical-returns.annualized-rental-income.tooltip" />
          </Tooltip.Text>
        </Tooltip.Content>
      </Tooltip>
    ),
    accessorKey: 'rentalIncomeReturns',
    cell: PercentCell,
    sortingFn: (rowA, rowB) => rowA.original.rentalIncomeReturns - rowB.original.rentalIncomeReturns,
    id: 'rentalIncomeReturns',
    size: 150,
  },
  {
    header: () => {
      return (
        <Tooltip>
          <Tooltip.Trigger>
            <Stack row alignItems="center" gap="$1">
              <FormattedMessage id="historical-returns.share-price" />
              <InfoIcon size="xs" />
            </Stack>
          </Tooltip.Trigger>
          <SharePriceToolTip />
        </Tooltip>
      );
    },
    accessorKey: 'sharePrice',
    cell: MoneyCell,
    sortingFn: (rowA, rowB) => rowA.original.sharePrice - rowB.original.sharePrice,
    size: 120,
    id: 'sharePrice',
  },
  {
    header: () => <FormattedMessage id="annualized-appreciation" defaultMessage="All Time Appreciation" />,
    accessorKey: 'appreciationReturns',
    cell: AppreciationCell,
    sortingFn: (rowA, rowB) => (rowA.original.appreciationReturns ?? 0) - (rowB.original.appreciationReturns ?? 0),
    id: 'appreciationReturns',
    size: 120,
  },
  {
    header: () => (
      <Tooltip>
        <Tooltip.Trigger>
          <Stack row alignItems="center" gap="$1">
            <FormattedMessage id="historical-returns.total-returns" />
            <InfoIcon size="xs" />
          </Stack>
        </Tooltip.Trigger>
        <Tooltip.Content tooltipTitle={<FormattedMessage id="historical-returns.total-returns" />}>
          <Tooltip.Text>
            <FormattedMessage id="historical-resturns.total-returns.tooltip" />
          </Tooltip.Text>
        </Tooltip.Content>
      </Tooltip>
    ),
    accessorKey: 'totalReturns',
    cell: PercentCell,
    sortingFn: (rowA, rowB) => rowA.original.totalReturns - rowB.original.totalReturns,
    size: isMobile ? 100 : 120,
  },
];

export interface PerformanceTableProps extends BoxProps {
  historicalPerformanceData: HistoricalPerformance[];
  sharePriceUpdatedOfferingIds: OfferingId[];
}

export const PerformanceTable = ({
  historicalPerformanceData,
  sharePriceUpdatedOfferingIds,
  sx,
  ...rest
}: PerformanceTableProps) => {
  const isMobile = useIsMobile();
  const { offset, transition } = useContext(NavigationBarContext);

  const columns = useMemo(() => createColumns(isMobile), [isMobile]);

  const [sortState, setSortState] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const table = useReactTable<HistoricalPerformance>({
    meta: {
      sharePriceUpdatedOfferingIds,
    },
    columns,
    data: historicalPerformanceData,
    state: {
      sorting: sortState,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSortState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    table
      .getAllLeafColumns()
      .filter(
        (column) =>
          column.id === 'rentalIncomeReturns' || column.id === 'appreciationReturns' || column.id === 'sharePrice',
      )
      .forEach((column) => column.toggleVisibility(!isMobile));
  }, [isMobile]);

  return (
    <Box
      sx={{
        '& .MuiTableRow-root': {
          '&:not(.MuiTableRow-head) .MuiTableCell-root': { py: 2 },
          '& .MuiTableCell-root:first-of-type': { pl: 2.5 },
        },
        '& .MuiTableRow-root:last-of-type .MuiTableCell-root': {
          borderBottomWidth: 0,
        },
        ...sx,
      }}
      {...rest}
    >
      <Table
        table={table}
        TableHeadProps={{
          sx: {
            backgroundColor: 'background.iceGray',
            position: 'sticky',
            top: offset,
            zIndex: 'mobileStepper',
            '& .MuiTableCell-root': {
              py: 2,
              borderBottomWidth: 0,
            },
          },
          style: {
            transition: `top ${transition}`,
          },
        }}
      />
    </Box>
  );
};
