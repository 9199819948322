import { ReactNode } from 'react';

import { PrimitiveType } from 'react-intl';

import { FormatXMLElementFn } from 'intl-messageformat';

import { Alert } from '@mui/material';

export const SUPPORTED_RICH_TEXT_FEATURES: Record<
  string,
  ReactNode | PrimitiveType | FormatXMLElementFn<ReactNode, ReactNode>
> = {
  br: <br />,
  em: (parts) => <em>{parts}</em>,
  info: (parts) => <Alert severity="info">{parts}</Alert>,
  strong: (parts) => <strong>{parts}</strong>,
};
const [br, em, info, strong] = Object.keys(SUPPORTED_RICH_TEXT_FEATURES);

export const SUPPORTED_RICH_TEXT_FEATURES_LABELS = {
  [br]: 'Line break: {br}',
  [em]: 'Italic: <em>*text*</em>',
  [info]: 'Info Alert: <info>*text*</info>',
  [strong]: 'Bold: <strong>*text*</strong>',
};
