import { Fragment, useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { differenceInDays, format } from 'date-fns';

import { Divider, InfoIcon, Stack, StackProps, Tooltip, useMedia } from '@arrived/bricks';
import { MonthlyFundPerformanceData, getAnnualizedValue } from '@arrived/common';

import { Cell } from './Cell';
import { Label } from './Label';
import { Row } from './Row';
import { Value } from './Value';

export type YearlyPerformanceTableProps = StackProps & {
  monthlyPerformanceData: MonthlyFundPerformanceData[];
};

const TableRow = ({
  appreciationPercent,
  annualizedDividendPercent,
  dividendPerShare,
  postedAtDate,
  sharePriceDuringPeriod,
}: MonthlyFundPerformanceData) => {
  const media = useMedia();

  return (
    <Row variant="content">
      <Cell>
        <Label>{format(postedAtDate, media.gtXxs ? 'MMMM' : 'MMM')}</Label>
      </Cell>
      <Cell>
        {dividendPerShare != null && sharePriceDuringPeriod != null && sharePriceDuringPeriod.sharePrice !== 0 && (
          <Value>
            <FormattedNumber
              value={dividendPerShare / sharePriceDuringPeriod.sharePrice}
              style="percent"
              maximumFractionDigits={2}
              minimumFractionDigits={2}
            />
          </Value>
        )}
      </Cell>
      <Cell>
        {annualizedDividendPercent != null && (
          <Value>
            <FormattedNumber
              value={annualizedDividendPercent}
              style="percent"
              maximumFractionDigits={2}
              minimumFractionDigits={2}
            />
          </Value>
        )}
      </Cell>
      <Cell>
        {appreciationPercent != null && (
          <Value>
            <FormattedNumber
              value={appreciationPercent}
              style="percent"
              maximumFractionDigits={1}
              minimumFractionDigits={1}
            />
          </Value>
        )}
      </Cell>
    </Row>
  );
};

export const YearlyPerformanceTable = ({ monthlyPerformanceData, ...rest }: YearlyPerformanceTableProps) => {
  // Gets the starting share price for the beginning of the year. Since monthlyPerformanceData is
  // ordered we can just get the first sharePriceDuringPeriod.sharePrice value from the array that
  // is non-null.
  const startingSharePrice = useMemo(
    () =>
      monthlyPerformanceData.find(({ sharePriceDuringPeriod }) => sharePriceDuringPeriod != null)
        ?.sharePriceDuringPeriod?.sharePrice,
    [monthlyPerformanceData],
  );

  const ytdAppreciation = useMemo(() => {
    const endingSharePrice = [...monthlyPerformanceData].reverse().find(({ sharePriceEOM }) => sharePriceEOM != null)
      ?.sharePriceEOM?.sharePrice;

    return startingSharePrice && endingSharePrice ? endingSharePrice / startingSharePrice - 1 : 0;
  }, [monthlyPerformanceData, startingSharePrice]);

  const ytdDividend = useMemo(() => {
    if (!startingSharePrice) {
      return;
    }

    const totalDividend = monthlyPerformanceData.reduce(
      (sum, { dividendPerShare }) => sum + (dividendPerShare ?? 0),
      0,
    );

    return totalDividend / startingSharePrice;
  }, [monthlyPerformanceData, startingSharePrice]);

  const ytdAnnualizedDividendPercent = useMemo(() => {
    if (!startingSharePrice || !monthlyPerformanceData.length) {
      return;
    }

    const startDate = monthlyPerformanceData[0].startDate;
    const endDate = monthlyPerformanceData[monthlyPerformanceData.length - 1].endDate;

    if (!endDate) {
      return;
    }

    const daysInPeriod = differenceInDays(new Date(endDate), new Date(startDate)) + 1;

    const totalDividend = monthlyPerformanceData.reduce(
      (sum, { dividendPerShare }) => sum + (dividendPerShare ?? 0),
      0,
    );

    const annualizedDividend = getAnnualizedValue({ daysInPeriod, value: totalDividend });
    return annualizedDividend != null ? annualizedDividend / startingSharePrice : undefined;
  }, [monthlyPerformanceData, startingSharePrice]);

  return (
    <Stack {...rest}>
      <Row variant="top">
        <Cell />
        <Cell>
          <Label textAlign="center">
            <FormattedMessage id="product-details.historical-fund-performance.monthly-dividend" />
          </Label>
        </Cell>
        <Cell>
          <Label textAlign="center">
            <FormattedMessage id="product-details.historical-fund-performance.annualized-dividend" />
          </Label>
        </Cell>
        <Cell>
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Stack row gap="$1" justifyContent="center" width="100%">
                <Label ellipse>
                  <FormattedMessage id="product-details.historical-fund-performance.appreciation" />
                </Label>
                <InfoIcon size="xs" style={{ flexShrink: 0 }} />
              </Stack>
            </Tooltip.Trigger>
            <Tooltip.Content
              tooltipTitle={<FormattedMessage id="product-details.historical-fund-performance.appreciation" />}
            >
              <Tooltip.Text>
                <FormattedMessage id="product-details.historical-fund-performance.appreciation-tooltip" />
              </Tooltip.Text>
            </Tooltip.Content>
          </Tooltip>
        </Cell>
      </Row>
      {monthlyPerformanceData.map((monthlyPerformanceData, idx) => (
        <Fragment key={idx}>
          {idx > 0 && <Divider solid alt />}
          <TableRow {...monthlyPerformanceData} />
        </Fragment>
      ))}
      <Row variant="bottom">
        <Cell>
          <Label>
            <FormattedMessage id="product-details.historical-fund-perforamnce.ytd" />
          </Label>
        </Cell>
        <Cell>
          {ytdDividend != null && (
            <Value>
              <FormattedNumber
                value={ytdDividend}
                style="percent"
                maximumFractionDigits={2}
                minimumFractionDigits={2}
              />
            </Value>
          )}
        </Cell>
        <Cell>
          {ytdAnnualizedDividendPercent != null && (
            <Value>
              <FormattedNumber
                value={ytdAnnualizedDividendPercent}
                style="percent"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Value>
          )}
        </Cell>
        <Cell>
          <Value>
            <FormattedNumber
              value={ytdAppreciation}
              style="percent"
              minimumFractionDigits={1}
              maximumFractionDigits={1}
            />
          </Value>
        </Cell>
      </Row>
    </Stack>
  );
};
