import { Offering, OfferingTransactionDocumentOfferingPreview } from '@arrived/models';

import { getPropertyPrimaryPhoto } from '../PropertyUtils';

export const getOfferingPrimaryPhoto = (
  offering: Offering | OfferingTransactionDocumentOfferingPreview | undefined,
): string | undefined => {
  if (!offering || !offering.properties) {
    return undefined;
  }
  if (offering.properties.length < 1) {
    return undefined;
  }
  return getPropertyPrimaryPhoto(offering.properties[0]);
};
