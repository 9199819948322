import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchQuestionnaireQuestions } from '@arrived/api_v2';

import { questionnaireQuestionsKeyFn } from './questionnaires.keys';

export function usePatchQuestionnaireQuestionOrderMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchQuestionnaireQuestions,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: questionnaireQuestionsKeyFn(data.questionnaireUUID) });
    },
  });
}
