import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import { differenceInDays, format, isAfter, isEqual } from 'date-fns';
import _cloneDeep from 'lodash/cloneDeep';

import { DataPoint, DataPointProps, Divider, Stack, Tooltip } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { getActiveLoansCount, getPaidOffLoansCount, getTimezoneAgnosticDate } from '@arrived/common';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { useInvestmentProductType } from '@arrived/hooks';
import { InvestmentProductType, Offering, OfferingDividend, OfferingSharePrices, SFRFundData } from '@arrived/models';
import { useFundPropertyOccupancyQuery } from '@arrived/queries';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';

export type FundDetailsSectionProps = ProductDetailContentProps & {
  dividends?: OfferingDividend[];
  offering: Offering;
  sfrFundMetadata?: SFRFundData;
  sharePrices?: OfferingSharePrices[];
};

const StyledDataPoint = (props: DataPointProps<false>) => (
  <DataPoint variant="minimized" colors="dark" flexBasis={0} flexGrow={1} flexShrink={1} {...props} />
);

export const FundDetailsSection = ({
  dividends,
  offering,
  sfrFundMetadata,
  sharePrices,
  ...rest
}: FundDetailsSectionProps) => {
  const intl = useIntl();
  const investmentProductType = useInvestmentProductType(offering);
  const isSFRDividendHistoryEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.SFR_DIVIDEND_HISTORY);
  const isSFRInceptionLeverageDataPointsEnabled = useIsFeatureFlagEnabled(
    FEATURE_FLAGS.SFR_FUND_LEVERAGE_INCEPTION_DATA_POINTS,
  );
  const isPCFSharePriceDataPointsEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.PCF_SHARE_PRICE);

  const occupancyRateState = useFundPropertyOccupancyQuery(offering.cid);
  const activeLoansCount = getActiveLoansCount(offering);
  const paidOffLoansCount = getPaidOffLoansCount(offering);

  const inceptionDate = useMemo(() => {
    if (!sfrFundMetadata?.inceptionDate) {
      return;
    }

    // Remove the timezone offset from the date so it parses exactly the day stored in the BE.
    return getTimezoneAgnosticDate(sfrFundMetadata.inceptionDate);
  }, [sfrFundMetadata?.inceptionDate]);

  const totalAnnualizedRent = useMemo(() => {
    let totalRent = 0;

    offering.properties.forEach((property) => {
      totalRent += property.rent ?? 0;
    });

    return totalRent * 12;
  }, [offering.properties]);

  const portfolioLeverage = useMemo(() => {
    // TODO: Function to derive leverage
    return 0;
  }, [offering.properties]);

  const currentDividendYield = useMemo(() => {
    if (!dividends?.length || !sharePrices?.length) {
      return;
    }
    const lastDividend = dividends[dividends.length - 1];
    // Sort share such that the most recent share price is first and then find the first share
    // price was posted at a time before or equal to the start of the most recent dividend period
    const mostRecentSharePrice =
      sharePrices.length > 1
        ? _cloneDeep(sharePrices)
            .sort(({ postedAt: postedAtA }, { postedAt: postedAtB }) =>
              isAfter(new Date(postedAtA), new Date(postedAtB)) ? -1 : 1,
            )
            .find(
              ({ postedAt }) =>
                isAfter(new Date(lastDividend.startDate), new Date(postedAt)) ||
                isEqual(new Date(lastDividend.startDate), new Date(postedAt)),
            )
        : sharePrices[0];

    const daysInPeriod = differenceInDays(new Date(lastDividend.endDate), new Date(lastDividend.startDate)) + 1;

    return daysInPeriod > 0 && mostRecentSharePrice?.sharePrice
      ? (lastDividend.dividendPerShare / daysInPeriod) * (365 / mostRecentSharePrice.sharePrice)
      : undefined;
  }, [dividends, sharePrices]);

  return (
    <ProductDetailContent border="always" {...rest}>
      <ProductDetailContent.Body>
        {(isSFRDividendHistoryEnabled &&
          investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND) ||
        (isPCFSharePriceDataPointsEnabled && investmentProductType === InvestmentProductType.PRIVATE_CREDIT_FUND) ? (
          <Stack alignItems="center" row gap="$6">
            <StyledDataPoint
              tooltip={{
                content: <FormattedMessage id="product-details.sfr-share-price-tooltip" />,
                title: <FormattedMessage id="product-details.share-price" />,
              }}
            >
              <DataPoint.Value>
                <FormattedNumber
                  style="currency"
                  currency="USD"
                  value={offering.sharePrice}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage id="product-details.share-price" />
              </DataPoint.Label>
            </StyledDataPoint>
            {currentDividendYield && (
              <>
                <Divider.Vertical h={36} solid alt />
                <StyledDataPoint
                  tooltip={{
                    content: <FormattedMessage id="product-details.current-dividend-yield.tooltip" />,
                    title: <FormattedMessage id="product-details.current-dividend-yield" />,
                  }}
                >
                  <DataPoint.Value>
                    <FormattedNumber
                      style="percent"
                      value={currentDividendYield}
                      minimumFractionDigits={1}
                      maximumFractionDigits={1}
                    />
                  </DataPoint.Value>
                  <DataPoint.Label>
                    <FormattedMessage id="product-details.current-dividend-yield" />
                  </DataPoint.Label>
                </StyledDataPoint>
              </>
            )}
          </Stack>
        ) : null}
        {investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND && (
          <Stack alignItems="center" row gap="$6">
            <StyledDataPoint
              tooltip={{
                content: <FormattedMessage id="product-details.stabilized-occupancy.tooltip" />,
                title: <FormattedMessage id="product-details.stabilized-occupancy" />,
              }}
            >
              <DataPoint.Value>
                <FormattedNumber
                  style="percent"
                  value={occupancyRateState.data?.occupancyRate ?? 0}
                  minimumFractionDigits={0}
                  maximumFractionDigits={1}
                />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage id="product-details.stabilized-occupancy" />
              </DataPoint.Label>
            </StyledDataPoint>
            <Divider.Vertical h={36} solid alt />
            <StyledDataPoint
              tooltip={{
                content: <FormattedMessage id="product-details.total-annualized-rent.tooltip" />,
                title: <FormattedMessage id="product-details.total-annualized-rent" />,
              }}
            >
              <DataPoint.Value>
                <FormattedNumber
                  style="currency"
                  currency="USD"
                  value={totalAnnualizedRent}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage id="product-details.total-annualized-rent" />
              </DataPoint.Label>
            </StyledDataPoint>
          </Stack>
        )}

        {investmentProductType === InvestmentProductType.PRIVATE_CREDIT_FUND && (
          <Stack alignItems="center" row gap="$6">
            <StyledDataPoint
              tooltip={{
                content: <FormattedMessage id="product-details.active-loans.tooltip" />,
                title: <FormattedMessage id="product-details.active-loans" />,
              }}
            >
              <DataPoint.Value>
                <FormattedNumber value={activeLoansCount} minimumFractionDigits={0} maximumFractionDigits={0} />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage id="product-details.active-loans" />
              </DataPoint.Label>
            </StyledDataPoint>
            <Divider.Vertical h={36} solid alt />
            <StyledDataPoint
              tooltip={{
                content: <FormattedMessage id="product-details.completed-loans.tooltip" />,
                title: <FormattedMessage id="product-details.completed-loans" />,
              }}
            >
              <DataPoint.Value>
                <FormattedNumber value={paidOffLoansCount} minimumFractionDigits={0} maximumFractionDigits={0} />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage id="product-details.completed-loans" />
              </DataPoint.Label>
            </StyledDataPoint>
          </Stack>
        )}
        {Boolean(
          isSFRInceptionLeverageDataPointsEnabled &&
            investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND,
        ) && (
          <Stack alignItems="center" row gap="$6">
            {inceptionDate && (
              <>
                <StyledDataPoint
                  tooltip={{
                    content: <FormattedMessage id="product-details.inception-date.tooltip" />,
                    title: <FormattedMessage id="product-details.inception-date" />,
                  }}
                >
                  <DataPoint.Value>{format(inceptionDate, 'M/d/yyyy')}</DataPoint.Value>
                  <DataPoint.Label>
                    <FormattedMessage id="product-details.inception-date" />
                  </DataPoint.Label>
                </StyledDataPoint>
                <Divider.Vertical h={36} solid alt />
              </>
            )}
            <StyledDataPoint
              tooltip={{
                content: (
                  <Markdown Text={Tooltip.Text}>
                    {intl.formatMessage({ id: 'product-details.portfolio-leverage.tooltip' })}
                  </Markdown>
                ),
                title: <FormattedMessage id="product-details.portfolio-leverage" />,
              }}
            >
              <DataPoint.Value>
                <FormattedNumber
                  value={portfolioLeverage}
                  style="percent"
                  minimumFractionDigits={0}
                  maximumFractionDigits={1}
                />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage id="product-details.portfolio-leverage" />
              </DataPoint.Label>
            </StyledDataPoint>
          </Stack>
        )}
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};
