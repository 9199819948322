import { FormattedMessage } from 'react-intl';

import { Markdown } from '@arrived/bricks-common';
import { SFRFundData } from '@arrived/models';

import { ProductDetailContent } from '../../../../../content';

export const OfferingFees = ({
  offeringFees: { description },
}: {
  offeringFees: NonNullable<SFRFundData['offeringFees']>;
}) => (
  <>
    <ProductDetailContent.SubHeader>
      <FormattedMessage id="product-details.offering-fees-and-other-expenses" />
    </ProductDetailContent.SubHeader>
    <Markdown Text={ProductDetailContent.Text}>{description}</Markdown>
  </>
);
