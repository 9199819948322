/**
 * TODO: This should just be an svg, but we have to make it a component so we can add a prefix to avoid id collisions.
 *  See https://nrwlcommunity.slack.com/archives/C015WMB2A8P/p1666636621370789
 *  Once that gets fixed (i.e. we can provide SVGO option) then we can move this back to an svg file (style goes back
 *  to string on the mask, stopColor attributes become stop-color, remove prefix in ids).
 */
export const ArrivedHeartLogo = ({ prefix }: { prefix?: string }) => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id={`${prefix}mask0_1_95`}
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="21"
      >
        <path
          d="M21.0701 9.86164C19.232 13.3964 14.3148 18.2036 11.5341 20.7643C11.2352 21.0342 10.7805 21.0342 10.4816 20.7643C7.6852 18.2036 2.76799 13.3964 0.929929 9.86164C-3.10752 2.08522 7.07252 -3.09906 11 4.67736C14.9275 -3.09906 25.1075 2.08522 21.0701 9.86164Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${prefix}mask0_1_95)`}>
        <path
          d="M14.2478 8.68672L11.0016 5.4359L7.75547 8.68672L11.0016 11.9375L14.2478 8.68672ZM15.8624 16.805L15.437 16.3772L12.4354 13.3733L15.6816 10.1225L18.9277 13.3733L15.8624 16.805ZM15.6816 7.25094L11.5427 3.10742L12.9945 1.59975L15.6004 0.757235H21.4976C21.714 0.757235 21.8222 1.10147 21.6599 1.23692L15.6816 7.25094ZM20.3615 11.9375L17.1153 8.68672L22.1198 3.67504V9.8516C22.1198 10.0412 22.0387 10.2309 21.9034 10.3663L20.3615 11.9375ZM-0.116516 9.8516V3.67504L14.2478 18.0599L11.5427 20.7689C11.2451 21.0669 10.7852 21.0669 10.5147 20.7689L5.4878 16.0839L0.126948 10.3663C-0.0353613 10.2309 -0.116516 10.0412 -0.116516 9.8516ZM6.32174 7.25094L0.343359 1.26401C0.18105 1.10147 0.289256 0.857658 0.505668 0.857658L6.55114 0.500568L12.7329 0.857658L6.32174 7.25094Z"
          fill={`url(#${prefix}paint0_linear_1_95)`}
        />
      </g>
      <defs>
        <linearGradient
          id={`${prefix}paint0_linear_1_95`}
          x1="22.1198"
          y1="10.5838"
          x2="5.45331"
          y2="20.2186"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1DD3C8" />
          <stop offset="1" stopColor="#00C5F1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
