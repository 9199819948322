import { ReactNode } from 'react';

import clsx from 'clsx';

import { Box } from '@mui/material';

interface PrivacyTermsWrapperProps {
  className?: string;
  children?: ReactNode;
}

export const PrivacyTermsWrapper = ({ children, className }: PrivacyTermsWrapperProps) => {
  return (
    <Box
      className={clsx('privacy-terms-wrapper', className)}
      sx={{
        mt: 2,
        p: 4,
        h4: {
          mt: 4,
        },
        'h5,p,div': {
          mt: 2,
        },
        'ul,ol': {
          li: {
            mb: 2,
            '.title': {
              mb: 2,
            },
          },
        },
        'a,a:visited': {
          color: ({ palette }) => palette.primary.main,
          textDecoration: 'underline',
        },
      }}
      role="main"
    >
      {children}
    </Box>
  );
};
