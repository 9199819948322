import { GetProps, styled, useProps, withStaticProperties } from '@tamagui/core';

import { Stack } from '../layout';
import { StripeSvg } from '../StripeSvg';

const RATIO_INNER_NAME = 'RatioInner';
const RATIO_CONTAINER_NAME = 'RatioContainer';
const RATIO_FILL_NAME = 'RatioFill';

const RatioContainer = styled(Stack, {
  name: RATIO_CONTAINER_NAME,
  row: true,
  borderRadius: '$2',
  overflow: 'hidden',
  h: '$3',
  minWidth: '$15',
  backgroundColor: '$onSurface.neutral.container',
  alignItems: 'center',
  justifyContent: 'center',
});

const RatioFill = styled(Stack, {
  name: RATIO_FILL_NAME,
  position: 'absolute',
  left: 0,
  backgroundColor: '$onSurface.neutral.defaultInverted',
  zIndex: 1,
  h: '$1',

  variants: {
    striped: {
      true: {
        backgroundColor: '$onSurface.primary.decorativeAlt',
        zIndex: 0,
      },
    },
  } as const,
});

const BarFillWithStripe = RatioFill.styleable<GetProps<typeof RatioFill>>((propsIn, ref) => {
  const { children, striped, ...rest } = useProps(propsIn);
  return (
    <RatioFill ref={ref} striped={striped} {...rest}>
      {striped && <StripeSvg color="$onSurface.primary.decorative" />}
      {children}
    </RatioFill>
  );
});

export const Ratio = withStaticProperties(RatioContainer, {
  Inner: styled(Stack, {
    name: RATIO_INNER_NAME,
    position: 'relative',
    m: '$1',
    h: '$1',
    flex: 1,
    backgroundColor: '$onSurface.neutral.container',
    alignItems: 'center',
    overflow: 'hidden',
  }),
  Fill: BarFillWithStripe,
});
