import { useIntl } from 'react-intl';

import { notificationService } from '@arrived/features-toasty';

export const useCopyToClipboard = () => {
  const intl = useIntl();
  const defaultSuccessCopy = intl.formatMessage({ id: 'common.copied-to-clipboard' });

  const handleOnClick = async (textToCopy: string, successToastCopy: string) => {
    await navigator.clipboard.writeText(textToCopy);

    const toastCopy = successToastCopy ?? defaultSuccessCopy;

    notificationService.success(toastCopy);
  };

  return handleOnClick;
};
