import { FundErrorStatus } from '@arrived/models';

const errorStatusDisplayTexts = new Map<FundErrorStatus, string>([
  [FundErrorStatus.R01_INSUFFICIENT_FUNDS, 'Insufficient funds'],
  [FundErrorStatus.R02_BANK_ACCOUNT_CLOSED, 'Bank account closed'],
  [FundErrorStatus.R03_BANK_NOTE_FOUND, 'No bank account/unable to locate account'],
  [FundErrorStatus.R04_INVALID_BANK_ACCOUNT, 'Invalid bank account number'],
  [FundErrorStatus.R05_UNAUTHORIZED_DEBIT_ENTRY, 'Unauthorized Debit Entry'],
  [FundErrorStatus.R06_RETURNED_PER_ODFI_REQUEST, 'Returned per ODFI request'],
  [FundErrorStatus.R07_AUTHORIZATION_REVOKED_BY_CUSTOMER, 'Authorization revoked by customer'],
  [FundErrorStatus.R08_PAYMENT_STOPPED, 'Payment stopped'],
  [FundErrorStatus.R09_UNCOLLECTED_FUNDS, 'Uncollected funds'],
  [FundErrorStatus.R10_CUSTOMER_ADVISES_NOT_AUTHORIZED, 'Customer advises not authorized'],
  [FundErrorStatus.R11_CHECK_TRUNCATION_ENTRY_RETURN, 'Check truncation entry return'],
  [FundErrorStatus.R12_BRANCH_SOLD_TO_ANOTHER_RDFI, 'Branch sold to another RDFI'],
  [FundErrorStatus.R13_RDFI_NOT_QUALIFIED_TO_PARTICIPATE, 'RDFI not qualified to participate'],
  [
    FundErrorStatus.R14_REPRESENTATIVE_PAYEE_DECEASED_OR_UNABLE_TO_CONTINUE_IN_THE_CAPACITY,
    'Representative payee deceased or unable to continue in that capacity',
  ],
  [FundErrorStatus.R15_BENEFICIARY_OR_BANK_ACCOUNT_HOLDER, 'Beneficiary or bank account holder'],
  [FundErrorStatus.R16_BANK_ACCOUNT_FROZEN, 'Bank account frozen'],
  [FundErrorStatus.R17_FILE_RECORD_EDIT_CRITERIA, 'File record edit criteria'],
  [FundErrorStatus.R18_IMPROPER_EFFECTIVE_ENTRY_DATE, 'Improper effective entry date'],
  [FundErrorStatus.R19_AMOUNT_FIELD_ERROR, 'Amount field error'],
  [FundErrorStatus.R20_NON_PAYMENT_BANK_ACCOUNT, 'Non-payment bank account'],
  [FundErrorStatus.R21_INVALID_COMPANY_ID_NUMBER, 'Invalid company ID number'],
  [FundErrorStatus.R22_INVALID_INDIVIDUAL_ID_NUMBER, 'Invalid individual ID number'],
  [FundErrorStatus.R23_CREDIT_ENTRY_REFUSED_BY_RECEIVER, 'Credit entry refused by receiver'],
  [FundErrorStatus.R24_DUPLICATED_ENTRY, 'Duplicate entry'],
  [FundErrorStatus.R25_ADDENDA_ERROR, 'Addenda error'],
  [FundErrorStatus.R26_MANDATORY_FIELD_ERROR, 'Mandatory field error'],
  [FundErrorStatus.R27_TRACE_NUMBER_ERROR, 'Trace number error'],
  [FundErrorStatus.R28_TRANSIT_ROUTING_NUMBER_CHECK_DIGIT_ERROR, 'Transit routing number check digit error'],
  [FundErrorStatus.R29_CORPORATE_CUSTOMER_ADVISES_NOT_AUTHORIZED, 'Corporate customer advises not authorized'],
  [
    FundErrorStatus.R30_RDFI_NOT_PARTICIPANT_IN_CHECK_TRUNCATION_PROGRAM,
    'RDFI not participant in check truncation program',
  ],
  [FundErrorStatus.R31_PERMISSIBLE_RETURN_ENTRY_CCD_AND_CTX_ONLY, 'Permissible return entry (CCD and CTX only)'],
  [FundErrorStatus.R32_RDFI_NOT_SETTLEMENT, 'RDFI non-settlement'],
  [FundErrorStatus.R33_RETURN_OF_XCK_ENTRY, 'Return of XCK entry'],
  [FundErrorStatus.R34_LIMITED_PARTICIPATION_RDFI, 'Limited participation RDFI'],
  [FundErrorStatus.R35_RETURN_OF_IMPROPER_DEBIT_ENTRY, 'Return of improper debit entry'],
]);

export const getFundErrorStatusDisplayTest = (errorStatus?: FundErrorStatus): string | undefined => {
  if (!errorStatus) {
    return undefined;
  }
  if (!errorStatusDisplayTexts.has(errorStatus)) {
    return undefined;
  }

  return errorStatusDisplayTexts.get(errorStatus);
};
