import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UseAwaitedMutationOptions } from '@arrived/queries';

import { postExternalTransfer } from '../../api/transfers';
import { cashAccountKeyFn } from '../cash-accounts/cashAccount.keys';
import { transactionsKeyFn } from '../transactions/transactions.keys';

interface ExtraOptions {
  shouldInvalidate?: boolean;
}

export function usePostExternalTransferMutation(
  options?: UseAwaitedMutationOptions<typeof postExternalTransfer> & ExtraOptions,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postExternalTransfer,
    ...options,
    onSuccess: (data, variables, context) => {
      const cashAccountCid = data.cashAccountCid;
      // Default to true
      if (options?.shouldInvalidate !== false) {
        queryClient.invalidateQueries({ queryKey: cashAccountKeyFn(cashAccountCid) });
        queryClient.invalidateQueries({ queryKey: transactionsKeyFn(cashAccountCid) });
      }
      options?.onSuccess?.(data, variables, context);
    },
  });
}
