import { useMemo } from 'react';

import { DEBT_PERCENT_VOLATILITY_THRESHOLDS } from '@arrived/common';
import { Offering } from '@arrived/models';

import { LeverageAmount } from './LeverageAmount';

export const useLeverageAmount = (offering: Offering) =>
  useMemo(() => {
    if (offering.debtPercent < DEBT_PERCENT_VOLATILITY_THRESHOLDS.LOW) {
      return LeverageAmount.LOW;
    } else if (offering.debtPercent < DEBT_PERCENT_VOLATILITY_THRESHOLDS.AVERAGE) {
      return LeverageAmount.AVERAGE;
    } else {
      return LeverageAmount.HIGH;
    }
  }, [offering.debtPercent]);
