import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BankLinkEventType, EVENTS, convertToTaxonomyCase, useTrack } from '@arrived/analytics';
import { postLinkPlaidExternalAccount } from '@arrived/api_v2';
import { trackMarketingConversionEvent } from '@arrived/marketing';
import { MARKETING_CONVERSION_EVENTS, User } from '@arrived/models';

import { accountsPrimaryKeyFn, currentUserKeyFn } from '../../users';
import { UseAwaitedMutationOptions } from '../../utils';
import { accountsKeyFn } from '../accounts.keys';

export function usePostExternalAccountLinkPlaidMutation(
  options?: UseAwaitedMutationOptions<typeof postLinkPlaidExternalAccount>,
) {
  const track = useTrack();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request) => postLinkPlaidExternalAccount(request),
    ...options,
    onSuccess: (data, variables, context) => {
      track(EVENTS.BANK_LINK, {
        update_type: BankLinkEventType.CREATE,
        verification_status: convertToTaxonomyCase(data.verified),
        connection_type: convertToTaxonomyCase(data.linkType),
      });

      trackMarketingConversionEvent({
        type: MARKETING_CONVERSION_EVENTS.LINKED_BANK,
      });

      const user = queryClient.getQueryData<User>(currentUserKeyFn());

      if (user) {
        queryClient.invalidateQueries({ queryKey: accountsPrimaryKeyFn(user.id) });
      }
      queryClient.invalidateQueries({ queryKey: accountsKeyFn(variables.accountId) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
