import { HTMLProps, PropsWithChildren, useMemo } from 'react';

import Head from 'next/head';

import { format } from 'date-fns';

import { withStaticProperties } from '@arrived/bricks';

type TitleProps = {
  /**
   * Removes the trailing " | Arrived" from the title if
   * set to true.
   *
   * @default false
   */
  excludeTrailingTitle?: boolean;

  /**
   * The title content, by default it will be suffixed with " | Arrived".
   */
  content?: string;
};

// Duplicating BuilderBlogArticleModel to avoid circular dependency and large refactor of builder models
type ArticleModel = {
  title: string;
  slug: string;
  excerpt: string;
  content: string;
  featuredImage: string;
  authors?: {
    id: string;
    author: { value?: { data: AuthorModel } };
  }[];
  postId: number;
};

type AuthorModel = {
  authorName?: string;
  authorId?: number;
  authorAvatar?: string;
};
const Title = ({ content, excludeTrailingTitle = false }: TitleProps) => {
  const title = useMemo(() => {
    if (!content) {
      return null;
    }

    return excludeTrailingTitle ? content : `${content} | Arrived - Easily Invest in Real Estate`;
  }, [content, excludeTrailingTitle]);

  if (!title) {
    return null;
  }

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} key="og:title" />
      <meta property="twitter:title" content={title} key="twitter:title" />
    </Head>
  );
};

const Description = (props: HTMLProps<HTMLMetaElement>) => (
  <Head>
    <meta name="description" key="description" {...props} />
    <meta property="og:description" key="og:description" {...props} />
    <meta property="twitter:description" key="twitter:description" {...props} />
  </Head>
);

const Keywords = ({
  content,
  ...props
}: Omit<HTMLProps<HTMLMetaElement>, 'content'> & {
  content?: string[] | Readonly<string[]>;
}) => (
  <Head>
    <meta name="keywords" key="keywords" content={content?.join(',')} {...props} />
  </Head>
);

const Image = (props: HTMLProps<HTMLMetaElement>) => (
  <Head>
    <meta name="image" key="image" {...props} />
    <meta property="og:image" key="og:image" {...props} />
    <meta property="twitter:image" key="twitter:image" {...props} />
  </Head>
);

const Meta = (props: HTMLProps<HTMLMetaElement>) => (
  <Head>
    <meta key={props.property} {...props} />
  </Head>
);

const Link = (props: HTMLProps<HTMLLinkElement>) => (
  <Head>
    <link key={props.rel} {...props} />
  </Head>
);

/*
 // Uses the https://schema.org/Article schema to try to have our content show up as a rich snippet in Google
 // Can visit https://developers.google.com/search/docs/appearance/structured-data/article to learn more
*/

const StructuredData = ({
  articleData,
}: {
  articleData: {
    createdDate: number;
    data: ArticleModel;
  };
}) => {
  const structuredData = `{
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "${articleData?.data?.title}",
    "image": "${articleData?.data?.featuredImage}",
    "datePublished": "${format(new Date(articleData?.createdDate), "yyyy-MM-dd'T'HH:mm:ss")}",
    "author": {
      "@type": ${
        articleData.data?.authors?.[0]?.author?.value?.data?.authorName?.trim() === 'Arrived Team'
          ? '"Organization"'
          : '"Person"'
      },
      "name": "${articleData.data?.authors?.[0]?.author?.value?.data?.authorName}"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Arrived",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cdn.arrivedhomes.com/images/logos/logo.svg"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://arrived.com/blog/${articleData.data?.slug}"
    },
    "articleBody": "${articleData.data?.excerpt}"
  }`;

  function addProductJsonLd() {
    return {
      __html: structuredData,
    };
  }

  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={addProductJsonLd()} key="article-jsonld" />
    </Head>
  );
};

type SEOProps = PropsWithChildren<{
  opengraph?: Record<`${string}:${string}`, string>;
  articleData?: {
    createdDate: number;
    data: ArticleModel;
  };
}>;

/**
 * A "cross-platform" SEO component that sets the title, description, keywords, and image
 * meta tags for the page. By default we will have viewport meta tag and application name
 * meta tag set.
 *
 * This is "cross-platform" because it can be used in both Next.js and React Native, on native
 * it will simply render Fragments.
 */
export const SEO = withStaticProperties(
  ({ children, opengraph, articleData }: SEOProps) => {
    return (
      <>
        {children}

        {/* Default meta tags / opengraph tags */}

        <Head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="application-name" content="Arrived" />

          {opengraph &&
            Object.entries(opengraph).map(([name, content]) => <meta key={name} property={name} content={content} />)}

          {articleData && <StructuredData articleData={articleData} />}
        </Head>
      </>
    );
  },
  {
    Meta,
    Title,
    Description,
    Keywords,
    Image,
    Link,
    StructuredData,
  },
);
