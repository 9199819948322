import { FormattedMessage } from 'react-intl';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

import { ROUTES } from '@arrived/common';

import { OptionCardProps } from './OptionCard';

export const DefaultOptions: OptionCardProps[] = [
  {
    external: true,
    path: ROUTES.static.help,
    icon: ContactSupportIcon,
    title: <FormattedMessage id="common.help-and-faq" />,
    subTitle: <FormattedMessage id="maintenance-help-faq-subtitle" defaultMessage="Have your questions answered" />,
  },
  {
    external: true,
    path: ROUTES.static.learning,
    icon: LightbulbIcon,
    title: <FormattedMessage id="maintenance-learn-title" defaultMessage="Arrived Learn" />,
    subTitle: <FormattedMessage id="maintenance-learn-subtitle" defaultMessage="Learn more about Arrived" />,
  },
  {
    external: true,
    path: ROUTES.static.webinar,
    icon: HeadsetMicIcon,
    title: <FormattedMessage id="maintenance-webinar-title" defaultMessage="Arrived Webinars" />,
    subTitle: <FormattedMessage id="maintenance-learn-subtitle" defaultMessage="Register for Arrived webinars" />,
  },
  // {
  //   path: AppPaths.properties(),
  //   icon: HomeIcon,
  //   title: <FormattedMessage id="maintenance-invest-title" defaultMessage="Invest" />,
  //   subTitle: <FormattedMessage id="maintenance-invest-subtitle" defaultMessage="Browse our properties" />,
  // },
];
