import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postUserEnrollMFA } from '@arrived/api_v2';

import { userMFADetailsKeyFn } from './users.mfa.keys';

export function usePostCreateMFAEnrollmentMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postUserEnrollMFA,
    onSuccess: (...result) => {
      queryClient.invalidateQueries({ queryKey: userMFADetailsKeyFn(result[1]) });
    },
  });
}
