import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { type loadOptions } from 'rudder-sdk-js';

import { CONFIG } from '@arrived/config';

import { AnalyticsContext } from './AnalyticsContext';

/**
 * Initialize config values from the environment to
 * use for Rudderstack initialization, we cannot call these within the
 * IFEE below because they will be undefined
 */

const writeKey = CONFIG.rudderStack.rudderWriteKey;
const dataPlaneUrl = CONFIG.rudderStack.dataPlaneUrl;

const initOptions = {
  setCookieDomain: CONFIG.rudderStack.cookieDomain,
  configUrl: CONFIG.rudderStack.configUrl,
  integrations: {
    All: true, // load call options
  },
  lockIntegrationsVersion: true,
  sendAdblockPage: true,
  sendAdblockPageOptions: {
    integrations: {
      All: true,
    },
  },
} satisfies loadOptions;

/**
 * List of methods that rudderstack supports from their initialization guide
 * https://www.rudderstack.com/docs/user-guides/how-to-guides/rudderstack-jamstack-integration/rudderstack-nextjs-integration/#method-1-installing-and-configuring-the-javascript-sdk-in-your-nextjs-app
 */
const RudderstackManualMethods = [
  'load',
  'page',
  'track',
  'identify',
  'alias',
  'group',
  'ready',
  'reset',
  'getAnonymousId',
  'setAnonymousId',
  'getUserId',
  'getUserTraits',
  'getGroupId',
  'getGroupTraits',
  'startSession',
  'endSession',
] as const;

type ManualRudderstackInstance = {
  methods: typeof RudderstackManualMethods;
  factory: (t: unknown) => () => void;
  loadJS: (e: string, t: string) => void;
  load: (writeKey: string, dataPlaneUrl: string) => void;
  page: () => void;
};

/**
 * Manually initialize Rudderstack instance if it hasn't already been initialized,
 * and then call the page() method to track the current page
 *
 * https://www.rudderstack.com/docs/user-guides/how-to-guides/rudderstack-jamstack-integration/rudderstack-nextjs-integration/#method-1-installing-and-configuring-the-javascript-sdk-in-your-nextjs-app
 */
async function manuallyInitializeRudderstack() {
  if (window.rudderanalytics) {
    console.log('Manual initialization skipped, Rudderstack already loaded.', window.rudderanalytics);
    return Promise.resolve();
  }

  return new Promise<void>((resolve, reject) => {
    try {
      const e = ((window.rudderanalytics as unknown) =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- not going to bother to figure out how to type this nightmare
        (window.rudderanalytics as unknown as ManualRudderstackInstance) || []) as any;

      // Add reference for methods that Rudderstack has available
      e.methods = RudderstackManualMethods;

      // Add factory method for each method that Rudderstack has available
      e.factory =
        (t: ManualRudderstackInstance['methods'][number]) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- not going to bother to figure out how to type this nightmare
        (...args: any[]) => {
          e.push([t].concat(Array.prototype.slice.call(args)));
        };

      for (let t = 0; t < e.methods.length; t++) {
        const r = e.methods[t];
        e[r] = e.factory(r);
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions -- needed to load the script
      (e.loadJS = () => {
        const rudderScript = document.createElement('script');

        rudderScript.type = 'text/javascript';
        rudderScript.async = true;
        rudderScript.src = `${CONFIG.rudderStack.cdnUrl}/v1.1/rudder-analytics.min.js`;

        const newScriptInst = document.getElementsByTagName('script')[0];

        newScriptInst.parentNode?.insertBefore(rudderScript, newScriptInst);
        // eslint-disable-next-line no-sequences
      }),
        e.loadJS(),
        e.load(writeKey, dataPlaneUrl, initOptions),
        e.page(),
        e.ready(() => {
          console.log('Rudderstack was loaded via manual initialization');
          resolve();
        });
    } catch (e) {
      console.log('Manual initialization failed, this indicates that Rudderstack did not load for this user.', e);

      return reject(e);
    }
  });
}

async function rudderInitialize() {
  await manuallyInitializeRudderstack();
  return Promise.resolve();
}

export const AnalyticsContextProvider = ({ children }: PropsWithChildren) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const initializeOnRender = useCallback(async () => {
    try {
      await rudderInitialize();
      setIsLoaded(true);
    } catch (e) {
      console.log('Failed to initialize Rudderstack.', e);
    }
  }, []);

  useEffect(() => {
    initializeOnRender();
  }, []);

  const analyticsValue = useMemo(
    () => ({
      getAnonymousId: () => window.rudderanalytics?.getAnonymousId?.(),
      isLoaded,
    }),
    [isLoaded],
  );

  return <AnalyticsContext.Provider value={analyticsValue}>{children}</AnalyticsContext.Provider>;
};
