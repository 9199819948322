import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postResponseOption } from '@arrived/api_v2';
import { ResponseOption } from '@arrived/models';

import { questionResponseOptionsKeyFn, responseOptionKeyFn } from './questionnaires.keys';

export function usePostResponseOptionMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postResponseOption,
    onSuccess: (data) => {
      const { questionUUID: questionId, uuid } = data;

      queryClient.setQueryData<ResponseOption[]>(questionResponseOptionsKeyFn(questionId), (responseOptions) => [
        ...(responseOptions ?? []),
        data,
      ]);
      queryClient.setQueryData<ResponseOption>(responseOptionKeyFn(uuid), data);

      queryClient.invalidateQueries({ queryKey: questionResponseOptionsKeyFn(questionId) });
      queryClient.invalidateQueries({ queryKey: responseOptionKeyFn(uuid) });
    },
  });
}
