import { useQuery } from '@tanstack/react-query';

import { getIssuers } from '@arrived/api_v2';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { issuersQueryKey } from './issuers.keys';

export function useGetIssuersQuery(options?: UseAwaitedQueryOptions<typeof getIssuers>) {
  return useQuery({
    queryKey: issuersQueryKey,
    queryFn: () => getIssuers(),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}
