import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { getInvestmentTerm, getYearlyRentalIncrease } from '@arrived/forms';
import { Offering } from '@arrived/models';

export interface DisclaimerPartTwoProps {
  offering: Offering;
}

export const DisclaimerPartTwo = ({ offering }: DisclaimerPartTwoProps) => {
  const intl = useIntl();

  const holdPeriod = useMemo(() => getInvestmentTerm(offering), [offering]);
  const yearlyIncrease = useMemo(() => getYearlyRentalIncrease(offering), [offering]);

  return (
    <>
      {intl.formatMessage(
        { id: 'property-details.calculator.disclaimer-part-two' },
        {
          investmentTerm: intl.formatNumber(holdPeriod),
          yearlyRentalIncrease: intl.formatNumber(yearlyIncrease, {
            style: 'percent',
            maximumFractionDigits: 1,
            minimumFractionDigits: 0,
          }),
        },
      )}
    </>
  );
};
