/**
 * ssn: 123-45-6789 -> XXX-XX-6789
 * ein: 12-3456789 -> XX-XXX6789
 * @param taxId
 */
import { AccountType, EntityType, TaxIdType } from '@arrived/models';

import { TaxIdRequirement } from './TaxIdRequirement';

export const maskTaxId = (taxId?: string): string | undefined => {
  if (!taxId || taxId.length <= 4) {
    return 'N/A';
  }

  return taxId.substring(0, taxId.length - 3).replace(/\d/g, 'X') + taxId.substring(taxId.length - 3);
};

export const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
export const einRegex = /^\d{2}-\d{7}$/;

export const isSsnValid = (ssn?: string | null, masked?: boolean): boolean => {
  if (!ssn) {
    return false;
  }
  const result: RegExpMatchArray | null = masked ? ssn.match(/^X{3}-X{2}-\d{4}$/) : ssn.match(ssnRegex);

  return result != null && result.length > 0;
};

export const isEinValid = (ein?: string | null, masked?: boolean): boolean => {
  if (!ein) {
    return false;
  }
  const result: RegExpMatchArray | null = masked ? ein.match(/^X{2}-X{3}\d{4}$/) : ein.match(einRegex);

  return result != null && result.length > 0;
};

export type TaxIdRequirementMap = { [key in TaxIdType]: TaxIdRequirement };

const createTaxIdRequirementMap = (einRequirement: TaxIdRequirement = TaxIdRequirement.NONE) =>
  ({
    SSN: TaxIdRequirement.REQUIRED,
    EIN: einRequirement,
  }) as const;

const isTaxIdRequirementSatisfied = (
  taxIdRequirement: TaxIdRequirement,
  taxIdType: TaxIdType,
  taxId?: string | null,
) => {
  const isValid = taxIdType === TaxIdType.SSN ? isSsnValid(taxId) : isEinValid(taxId);
  switch (taxIdRequirement) {
    case TaxIdRequirement.REQUIRED:
      return isValid;
    case TaxIdRequirement.OPTIONAL:
      return isValid || taxId == null;
    case TaxIdRequirement.NONE:
      return true;
  }
};

export const isTaxIdRequirementMapSatisfied = (
  taxIdRequirementMap: TaxIdRequirementMap,
  taxIds: { ssn?: string | null; ein?: string | null },
) =>
  isTaxIdRequirementSatisfied(taxIdRequirementMap.SSN, TaxIdType.SSN, taxIds.ssn) &&
  isTaxIdRequirementSatisfied(taxIdRequirementMap.EIN, TaxIdType.EIN, taxIds.ein);

export const EntityTypeTaxIdRequirements = {
  [EntityType.REVOCABLE_TRUST]: createTaxIdRequirementMap(TaxIdRequirement.OPTIONAL),
  [EntityType.IRREVOCABLE_TRUST]: createTaxIdRequirementMap(TaxIdRequirement.REQUIRED),
  [EntityType.LIMITED_PARTNERSHIP]: createTaxIdRequirementMap(TaxIdRequirement.REQUIRED),
  [EntityType.LLC]: createTaxIdRequirementMap(TaxIdRequirement.OPTIONAL),
  [EntityType.CORPORATION]: createTaxIdRequirementMap(TaxIdRequirement.REQUIRED),
} as const;

export const AccountTypeTaxIdRequirements = {
  [AccountType.INDIVIDUAL]: createTaxIdRequirementMap(),
  [AccountType.IRA]: createTaxIdRequirementMap(),
  [AccountType.JOINT]: createTaxIdRequirementMap(),
  [AccountType.JTWROS]: createTaxIdRequirementMap(),
  [AccountType.ROTH]: createTaxIdRequirementMap(),
  [AccountType.SEP_IRA]: createTaxIdRequirementMap(),
  [AccountType.TIC]: createTaxIdRequirementMap(),
  [AccountType.CHECKBOOK_IRA]: createTaxIdRequirementMap(TaxIdRequirement.REQUIRED),
} as const;

export const getTaxIdRequirementMap = (accountType?: AccountType, entityType?: EntityType | null) => {
  if (!accountType) {
    return undefined;
  }

  if (accountType === AccountType.ENTITY) {
    if (entityType != null) {
      return EntityTypeTaxIdRequirements[entityType];
    }

    return undefined;
  }

  return AccountTypeTaxIdRequirements[accountType];
};
