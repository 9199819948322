import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchQuestion } from '@arrived/api_v2';
import { Question } from '@arrived/models';

import { questionKeyFn } from './questionnaires.keys';

export function usePatchQuestionMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchQuestion,
    onSuccess: (data) => {
      queryClient.setQueryData<Question>(questionKeyFn(data.uuid), data);

      queryClient.invalidateQueries({ queryKey: questionKeyFn(data.uuid) });
    },
  });
}
