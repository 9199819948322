import { createContext } from 'react';

import {
  ClearSessionOptions,
  ClearSessionParameters,
  Credentials,
  RefreshTokenOptions,
  RevokeOptions,
  WebAuthorizeOptions,
  WebAuthorizeParameters,
} from 'react-native-auth0';

import { ArrivedAuth0State, ArrivedAuth0User, Auth0UserPermissions, CustomAuthorizationParameters } from './utils';

/**
 * This is a custom implementation of the `Auth0Context` type from `react-native-auth0` that
 * adds our own custom types and methods. This solves a major issue with updating either
 * the users `IDToken` or `AccessToken` on the `Auth0Context` type. Due to how the Auth0Provider
 * worked on the `react-native-auth0` package, the user or access token was not able to be updated
 * via the hook. This is a problem as we rely on properties like `user.emailVerified` to determine
 * if the user has completed certain flows.
 *
 * As of 8/7/2024 --- Distinct from the `Auth0Context` type in the
 * package is the `isAuthenticated`, `refreshUser`, and `refreshCredentials` methods.
 *
 * @see https://github.com/auth0/react-native-auth0/blob/master/src/hooks/auth0-context.ts
 */
export type ArrivedAuth0Context<TUser extends ArrivedAuth0User = ArrivedAuth0User> = ArrivedAuth0State<TUser> & {
  /**
   * A flag that is true when the user is not `null`, and `false` when the user is `null`
   */
  isAuthenticated: boolean;

  /**
   * Authorize the user using Auth0 Universal Login. See {@link WebAuth#authorize}
   * @param parameters The parameters that are sent to the `/authorize` endpoint.
   * @param options Options for customizing the SDK's handling of the authorize call
   */
  authorize: (
    parameters?: WebAuthorizeParameters & CustomAuthorizationParameters,
    options?: WebAuthorizeOptions,
  ) => Promise<Credentials | undefined>;

  /**
   * Whether the SDK currently holds valid, unexpired credentials.
   * @param minTtl The minimum time in seconds that the access token should last before expiration
   * @returns `true` if there are valid credentials. Otherwise, `false`.
   */
  hasValidCredentials: (minTtl?: number) => Promise<boolean>;

  /**
   * Clears the user's web session, credentials and logs them out. See {@link WebAuth#clearSession}
   *
   * ! NOTE: This will prompt a `"Arrived" would like to log in"` modal when called on native.
   *
   * @param parameters Additional parameters to send to the Auth0 logout endpoint.
   * @param options Options for configuring the SDK's clear session behaviour.
   */
  clearSession: (parameters?: ClearSessionParameters, options?: ClearSessionOptions) => Promise<void>;

  /**
   * Gets the user's credentials from the native credential store. If credentials have expired, they are automatically refreshed
   * by default. See {@link CredentialsManager#getCredentials}
   * @param scope The scopes used to get the credentials
   * @param minTtl The minimum time in seconds that the access token should last before expiration
   * @param parameters Any additional parameters to send in the request to refresh expired credentials.
   */
  getCredentials: (
    scope?: string,
    minTtl?: number,
    parameters?: Record<string, unknown>,
    forceRefresh?: boolean,
  ) => Promise<Credentials | undefined>;

  /**
   * Get all user permissions from the access token.
   */
  getPermissions: () => Promise<Auth0UserPermissions[]>;

  /**
   * Get all allowed scopes from the access token.
   */
  getAllowedScopes: () => Promise<string[]>;

  /**
   * Clears the user's credentials without clearing their web session and logs them out.
   *
   * This will **not** prompt an alert when called on native.
   * But will not clear the scope of the user or fully invalidate
   * the users session.
   */
  clearCredentials: () => Promise<void>;

  /**
   * This will refresh a users token and reset their user object with a
   * new access token and refresh token.
   *
   * By default, this will only refresh the credentials if the user
   * no longer has valid credentials. This is to prevent asking
   * for a new token too often when this is called in a loop (for example, biometric authentication).
   */
  refreshCredentials: (options: RefreshTokenOptions) => Promise<Credentials | undefined>;

  /**
   * Refreshes the users ID token and general user information using
   * the access token linked to the account. This will re-request an ID token
   * and update the user object within the ArrivedAuth0Context (`useArrivedAuth0`).
   */
  refreshUser: () => Promise<ArrivedAuth0User | undefined>;

  /**
   * Revokes the refresh token used to refresh the user's access token. This
   * is used when a user disables biometric authentication and we don't want
   * to uphold the refresh token anymore.
   */
  revokeRefreshToken: (options: RevokeOptions) => Promise<void>;
};

const stub = () => {
  throw new Error('No provider was set');
};

const initialContext = {
  error: null,
  user: null,
  isLoading: true,
  isAuthenticated: false,
  authorize: stub,
  hasValidCredentials: stub,
  refreshCredentials: stub,
  refreshUser: stub,
  clearSession: stub,
  getCredentials: stub,
  getPermissions: stub,
  getAllowedScopes: stub,
  clearCredentials: stub,
  revokeRefreshToken: stub,
};

export const ArrivedAuth0Context = createContext<ArrivedAuth0Context>(initialContext);
