import { memo } from 'react';

import { Defs, LinearGradient, Path, Stop, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function ArrivedIconBlueInternal({ size, ...props }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 22 20" {...props}>
      <Path
        d="M11.1694 15.1319L13.6227 12.6583C13.816 12.4634 13.816 12.1491 13.6227 11.9541L11.1694 9.48054C10.9738 9.28333 10.655 9.28333 10.4594 9.48054L8.00614 11.9541C7.81281 12.1491 7.81281 12.4634 8.00613 12.6583L10.4594 15.1319C10.655 15.3291 10.9738 15.3291 11.1694 15.1319Z"
        fill="url(#paint0_linear_7414_743)"
      />
      <Path
        d="M12.5582 7.3644L15.0115 4.8908C15.2071 4.6936 15.5259 4.6936 15.7215 4.89081L18.1748 7.3644C18.3681 7.55932 18.3681 7.87366 18.1748 8.06858L15.7215 10.5422C15.5259 10.7394 15.2071 10.7394 15.0115 10.5422L12.5582 8.06858C12.3649 7.87366 12.3649 7.55932 12.5582 7.3644Z"
        fill="url(#paint1_linear_7414_743)"
      />
      <Path
        d="M12.5582 16.5439L15.0108 14.0709C15.2067 13.8735 15.526 13.8738 15.7215 14.0716L20.7382 19.1486C21.0503 19.4644 20.8265 20 20.3825 20H15.4959C15.3625 20 15.2347 19.9468 15.1409 19.8521L12.5582 17.2481C12.3649 17.0531 12.3649 16.7388 12.5582 16.5439Z"
        fill="url(#paint2_linear_7414_743)"
      />
      <Path
        d="M17.1103 11.9541L19.5592 9.4849C19.7565 9.28601 20.0786 9.28796 20.2735 9.48923L21.4881 10.744C21.5784 10.8372 21.6289 10.9619 21.6289 11.0917V16.0001C21.6289 16.4466 21.0882 16.6692 20.7739 16.3522L17.1103 12.6583C16.9169 12.4634 16.9169 12.1491 17.1103 11.9541Z"
        fill="url(#paint3_linear_7414_743)"
      />
      <Path
        d="M13.6196 3.48204L0.855007 16.3522C0.540626 16.6692 0 16.4466 0 16.0001V11.1041C0 10.9722 0.0521503 10.8456 0.145086 10.752L10.4626 0.354547C10.657 0.158677 10.9733 0.157293 11.1693 0.351456L13.6164 2.77467C13.8127 2.96908 13.8141 3.28587 13.6196 3.48204Z"
        fill="url(#paint4_linear_7414_743)"
      />
      <Path
        d="M0.894994 19.1213L5.90811 14.0693C6.10343 13.8725 6.42161 13.8722 6.61729 14.0687L11.649 19.1206C11.963 19.4359 11.7397 19.9735 11.2947 19.9735H1.24991C0.805385 19.9735 0.581884 19.4368 0.894994 19.1213Z"
        fill="url(#paint5_linear_7414_743)"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_7414_743"
          x1="-6.21184"
          y1="-7.45421"
          x2="20.292"
          y2="19.8779"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset="1" stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_7414_743"
          x1="-6.21184"
          y1="-7.45421"
          x2="20.292"
          y2="19.8779"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset="1" stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_7414_743"
          x1="-6.21184"
          y1="-7.45421"
          x2="20.292"
          y2="19.8779"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset="1" stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_7414_743"
          x1="-6.21184"
          y1="-7.45421"
          x2="20.292"
          y2="19.8779"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset="1" stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_7414_743"
          x1="-6.21184"
          y1="-7.45421"
          x2="20.292"
          y2="19.8779"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset="1" stopColor="#156CD8" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_7414_743"
          x1="-6.21184"
          y1="-7.45421"
          x2="20.292"
          y2="19.8779"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C0C9D4" />
          <Stop offset="1" stopColor="#156CD8" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export const ArrivedIconBlue = memo<IconProps>(withIconTheme(ArrivedIconBlueInternal));
