import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteOfferingDocument } from '@arrived/api_v2';
import { offeringDocumentsKeyFn } from '@arrived/queries';

export function useDeleteOfferingDocumentMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteOfferingDocument,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: offeringDocumentsKeyFn(data.offeringId) });
    },
  });
}
