import { GetProps, styled } from '@tamagui/core';

import { InfoIcon } from '../../icons';
import { Stack } from '../layout';
import { BodyText } from '../text';
import { Tooltip } from '../tooltip';

import { ROW_LABEL_FRAME_NAME, ROW_LABEL_NAME } from './constants';

const LabelText = styled(BodyText, {
  name: ROW_LABEL_NAME,

  token: 'body.compact.small',
  color: '$onSurface.neutral.muted',
  ellipse: true,
});

const LabelFrame = styled(Stack, {
  name: ROW_LABEL_FRAME_NAME,

  row: true,
  alignItems: 'center',
  gap: '$1',
  flexShrink: 1,
});

export type LabelProps = GetProps<typeof LabelFrame> & { tooltip?: GetProps<typeof Tooltip.Text>['children'] };

export const Label = LabelFrame.styleable<LabelProps>(({ children, tooltip, ...rest }, ref) => (
  <>
    {tooltip ? (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <LabelFrame ref={ref} {...rest}>
            <LabelText>{children}</LabelText>
            <InfoIcon size="sm" color="$onSurface.neutral.muted" style={{ flexShrink: 0 }} />
          </LabelFrame>
        </Tooltip.Trigger>
        <Tooltip.Content tooltipTitle={children}>
          <Tooltip.Text>{tooltip}</Tooltip.Text>
        </Tooltip.Content>
      </Tooltip>
    ) : (
      <LabelText>{children}</LabelText>
    )}
  </>
));
