import { ApiError } from '@arrived/models';

export * from './accounts';
export * from './airDna';
export * from './assetMetadata';
export * from './file';
export * from './fixedRateInvestments';
export * from './historicalReturns';
export * from './intercom';
export * from './issuers';
export * from './kpis';
export * from './markets';
export * from './misc';
export * from './offerings';
export * from './offeringHistory';
export * from './properties';
export * from './positions';
export * from './utils';
export * from './questionnaire';
export * from './referrals';
export * from './rewards';
export * from './sales';
export * from './sharesAvailable';
export * from './taxDocuments';
export * from './trades';
export * from './userDocuments';
export * from './users';
export * from './learnBlog';
export * from './webinars';
export * from './zillow';
export * from './portfolio';
export * from './closings';

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: ApiError;
  }
}
