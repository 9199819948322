import { assumedDispositionCostsPercent } from './constants';

export interface CalculateCashFlowsArguments {
  /**
   * If there is additional equity to add back into the asset value when it's sold, include it here. For example, money
   * set aside for property improvements and cash reserves.
   */
  additionalEquity?: number;
  /**
   * The yearly appreciation of the asset.
   */
  annualAppreciation: number;
  /**
   * The value of the underlying asset in the Offering at the beginning of the investment.
   */
  assetValue: number;
  /**
   * The costs associated with the sale of the asset as a percentage of the value of the asset. If omitted the default
   * value of `assumedDispositionCostsPercent` is used.
   */
  dispositionCostsPercent?: number;
  /**
   * The total equity of the Offering.
   */
  equityValue: number;
  /**
   * The investment term of the Offering in years.
   */
  investmentTerm: number;
  /**
   * The value of the loan taken out on the asset, this will be deducted from the total value of equity at the time of
   * sale.
   */
  loanAmount?: number;
  /**
   * The yearly dividends that the asset produces, can be computed using projectedYearlyDividends. Should be an array of
   * length `investmentTerm`.
   */
  yearlyDividends: number[];
}

/**
 * Computes the yearly cash flows for an investment of the provided parameters. The resulting array with be the length
 * of investmentTerm + 1 where the 0th index is equal to the negative value of the total equity of the Offering. Years
 * 1 through investmentTerm - 1 will be the dividends for each of those years. The last value in the array will include
 * the income from the sale of the asset, the dividend from the final year and whatever adjustments need to be made (per
 * the provided equityAdjustment argument).
 */
export function calculateCashFlows({
  additionalEquity = 0,
  annualAppreciation,
  assetValue,
  dispositionCostsPercent = assumedDispositionCostsPercent,
  equityValue,
  investmentTerm,
  loanAmount = 0,
  yearlyDividends,
}: CalculateCashFlowsArguments) {
  // We get the asset value at sale, by compounding the annual appreciation of the asset by the investment term and
  // multiplying that by the initial asset value. We then subtract the disposition costs from the ending asset value.
  // This gets us how much of the asset value is left to investors after the sale.
  const assetValueAtSale =
    Math.pow(1 + annualAppreciation, investmentTerm) * assetValue * (1 - dispositionCostsPercent);

  // To get the annual cash flows starting at year 0 we:
  // - Set the 0th index to the amount invested in the Offering, this indicates that at the start of the investment term
  //   we invested that amount of money.
  // - For years 1 through (investmentTerm - 1) the cash flows are just the yearly dividends.
  // - In the last year, the cash flow is the dividend from that year, plus the value of the asset at sale. The equity
  //   adjustment can account for things like property improvements, cash reserves and loans.
  return [
    -equityValue,
    ...yearlyDividends.slice(0, yearlyDividends.length - 1),
    assetValueAtSale + additionalEquity + yearlyDividends[yearlyDividends.length - 1] - loanAmount,
  ];
}
