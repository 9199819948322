import { QueryKey } from '@tanstack/react-query';

import { OfferingCid, OfferingId, PropertyId } from '@arrived/models';

export const offeringHistoryKey: QueryKey = ['offeringHistory'];
export const offeringHistoryPropertyIdKey = (propertyId: PropertyId): QueryKey =>
  offeringHistoryKey.concat([propertyId]);
export const offeringHistoryByOfferingId = (offeringId: OfferingId): QueryKey =>
  offeringHistoryKey.concat(['offeringId', offeringId]);

export const offeringHistoryLeaseKey = (propertyStatusCid: string): QueryKey =>
  offeringHistoryKey.concat([propertyStatusCid]);

export const offeringSharePricesHistoryKeyFn = (offeringCid: OfferingCid): QueryKey =>
  offeringHistoryKey.concat(['sharePrices', offeringCid]);

export const offeringDividendHistoryKeyFn = (offeringId: OfferingId): QueryKey =>
  offeringHistoryKey.concat(['dividends', offeringId]);
