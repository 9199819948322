import { useMemo } from 'react';

import { Property } from '@arrived/models';

export const usePropertyCoordinates = (property?: Property) =>
  useMemo(() => {
    if (property?.address) {
      const latitude = property.address.lat;
      const longitude = property.address.lon;

      return latitude != null && longitude != null ? { latitude, longitude } : undefined;
    }
  }, [property?.address]);
