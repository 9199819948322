import { parsePhoneNumber } from 'awesome-phonenumber';
import * as yup from 'yup';

// Extending yup to include a phone number validation method
yup.addMethod(yup.string, 'phone', function (errorMessage: string, countryCode?: string) {
  return this.test('phone', errorMessage, function (value) {
    const { path, createError } = this;

    // If the value is not provided, consider it optional
    if (!value) {
      return true;
    }

    const phoneNumber = parsePhoneNumber(value, {
      regionCode: countryCode ?? 'US',
    });

    if (!phoneNumber.valid) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

declare module 'yup' {
  export interface StringSchema {
    /**
     * Check for phone number validity.
     *
     * @param errorMessage - The error message to return if validation fails
     * @param countryCode - The country code to check against (default: `"US"`)
     */
    phone(errorMessage?: string, countryCode?: string): StringSchema;
  }
}

export const PhoneNumberSchema = yup.string().trim().phone('account.phoneNumber.invalid', 'US');
