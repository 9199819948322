import {
  ComponentProps,
  ComponentType,
  ForwardedRef,
  ReactNode,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { RiGiftLine } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

import { useDebounce } from 'usehooks-ts';

import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  AppBarProps,
  Button,
  Drawer,
  IconButton,
  Link,
  List,
  Skeleton,
  Stack,
  StackProps,
  Toolbar,
  ToolbarProps,
  Typography,
  alpha,
} from '@mui/material';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { ROUTES } from '@arrived/common';
import { CONFIG } from '@arrived/config';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { useGetCurrentUserQuery, useGetUserReferralEligibility } from '@arrived/queries';

import { NavItemComponent, NavItemProps, signUpText } from '../navigation';

const NavContainer = (props: StackProps) => <Stack alignItems="center" direction="row" gap={2} {...props} />;

const NavList = (props: StackProps) => <Stack alignItems="center" direction="row" {...props} />;

export enum NavigationBarStyle {
  DEFAULT,
  GRADIENT,
}

export interface NavigationBarProps {
  Component: NavItemComponent;
  primaryItems?: NavItemProps[];
  secondaryItems?: NavItemProps[];
  extraItems?: NavItemProps[];
  centeredItems?: NavItemProps[];
  logoComponent?: ReactNode;
  isTransparent?: boolean;
  hideSignUp?: boolean;
  navigationStyle?: NavigationBarStyle;
  AppBarProps?: Omit<AppBarProps, 'ref'>;
  ToolbarProps?: ToolbarProps;
  Logo: ComponentType;
  navigateToPage: (route: string) => void;
}

export const NavigationBar = forwardRef<HTMLDivElement, NavigationBarProps>(
  (
    {
      AppBarProps,
      ToolbarProps,
      Component,
      hideSignUp,
      extraItems,
      primaryItems,
      secondaryItems,
      centeredItems,
      Logo,
      navigationStyle = NavigationBarStyle.DEFAULT,
      navigateToPage,
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { isAuthenticated, isLoading } = useArrivedAuth0();
    const currentUserState = useGetCurrentUserQuery({ enabled: isAuthenticated });
    const isReferralProgramEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.REFERRAL_PROGRAM);
    const isUserReferralBeta = useIsFeatureFlagEnabled(FEATURE_FLAGS.REFERRAL_PROGRAM_USERS);
    const isReferralEligible = useGetUserReferralEligibility(isReferralProgramEnabled, isUserReferralBeta);
    const intl = useIntl();

    const handleSignUpClick = useCallback(() => {
      navigateToPage(ROUTES.register);
    }, [navigateToPage]);

    const { sx: AppBarPropsSx, ...AppBarPropsRest } = AppBarProps || {};
    const { sx: ToolbarPropsSx, ...ToolbarPropsRest } = ToolbarProps || {};

    const [isOpen, setIsOpen] = useState(false);
    const toggleMobileTopNavOpen = useCallback(() => setIsOpen((isOpen) => !isOpen), []);
    const handleOnClose = useCallback(() => setIsOpen(false), []);

    const debouncedIsOpen = useDebounce(isOpen, 250);

    const { ref: appBarRef, height } = useResizeDetector({ handleHeight: true, handleWidth: true });

    const setRefs = useCallback((element: HTMLDivElement | null) => {
      if (ref) {
        typeof ref === 'function' ? ref(element) : (ref.current = element);
      }
      appBarRef.current = element;
    }, []);

    const mobileItems = useMemo(
      () => [...(primaryItems ?? []), ...(secondaryItems ?? []), ...(extraItems ?? [])],
      [primaryItems, secondaryItems, extraItems],
    );

    const showSkeletonLoading = useMemo(
      () => isLoading || (isAuthenticated && currentUserState.isLoading),
      [currentUserState, isAuthenticated, isLoading],
    );

    const ReferralLinkButton = useCallback(
      () => (
        <>
          {isAuthenticated && isReferralEligible && (
            <Link
              href={`${CONFIG.appRoot}${ROUTES.referral.earn}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              <Typography color={({ palette }) => palette.aqua[600]} variant="body1" component="div">
                <RiGiftLine />
              </Typography>
              <Typography color={({ palette }) => palette.aqua[600]} variant="caption" component="span">
                <FormattedMessage id="common.refer-friend" />
              </Typography>
            </Link>
          )}
        </>
      ),
      [isAuthenticated, isReferralEligible],
    );

    const componentRenderFn = useCallback(
      (props: ComponentProps<typeof Component>, idx: number) => {
        return <Component key={idx} {...props} />;
      },
      [Component],
    );

    return (
      <nav className="app-bar">
        <AppBar
          ref={setRefs}
          sx={{
            overflow: 'hidden',
            background: ({ palette }) =>
              navigationStyle === NavigationBarStyle.GRADIENT
                ? `linear-gradient(90deg, ${palette.aqua[700]}, ${palette.aqua[600]} 100%)`
                : palette.lightened.bare,
            boxShadow: 'unset',
            borderBottom: ({ palette }) => `1px solid ${palette.neutrals.platinum}`,
            // backgroundColor: 'white',
            zIndex: ({ zIndex }) => ({
              xs: isOpen || debouncedIsOpen ? zIndex.drawer + 1 : undefined,
              md: zIndex.appBar,
            }),
            // TODO: These styles really belong on the item components themselves, but we'll need to do some other
            //  restructuring to make that happen.
            '& .nav-item': {
              padding: ({ spacing }) => spacing(0.5, 1, 0, 1),
              height: '52px',
              whiteSpace: 'nowrap',
              '&:hover, &.selected-nav': {
                marginBottom: '-2px',
                borderBottom: ({ palette }) => `2px solid ${palette.primary.main}`,
              },
              '&:hover': {
                backgroundColor: ({ palette }) => alpha(palette.text.primary, 0.1),
              },
              '&.selected-nav': {
                backgroundColor: 'unset',
              },
            },
            ...AppBarPropsSx,
          }}
          data-e2e="nav-bar-wrapper"
          {...AppBarPropsRest}
        >
          <Toolbar sx={{ justifyContent: 'space-between', gap: 2, ...ToolbarPropsSx }} {...ToolbarPropsRest}>
            <NavContainer>
              <Logo />
              <NavList display={{ xs: 'none', md: 'flex' }}>
                {showSkeletonLoading ? <Skeleton width={300} height={40} /> : primaryItems?.map(componentRenderFn)}
              </NavList>
            </NavContainer>
            {centeredItems && <>{centeredItems?.map(componentRenderFn)}</>}
            {showSkeletonLoading ? (
              <Skeleton width={100} height={40} />
            ) : (
              <>
                <NavContainer display={{ md: 'none' }}>
                  {!isAuthenticated && !hideSignUp && (
                    <Button onClick={handleSignUpClick} size="small" sx={{ minWidth: '5rem' }}>
                      {signUpText}
                    </Button>
                  )}
                  <ReferralLinkButton />
                  <IconButton
                    onClick={toggleMobileTopNavOpen}
                    className="mobile-top-nav-menu-button"
                    size="large"
                    aria-label={intl.formatMessage({ id: 'common.open' })}
                    data-e2e="mobile-nav-button"
                    sx={{
                      color: ({ palette }) =>
                        navigationStyle === NavigationBarStyle.GRADIENT ? palette.lightened.bare : undefined,
                      '&:hover': {
                        color: ({ palette }) =>
                          navigationStyle === NavigationBarStyle.GRADIENT ? palette.text.primary : undefined,
                      },
                    }}
                  >
                    <MenuIcon fontSize="large" />
                  </IconButton>
                  <Drawer
                    anchor="top"
                    sx={{
                      '& .MuiDrawer-paper': {
                        marginTop: `${height}px`,
                        maxHeight: `calc(100% - ${height}px)`,
                      },
                    }}
                    open={isOpen}
                    onClose={handleOnClose}
                  >
                    <List
                      // TODO: These styles really belong on the item components themselves, but we'll need to do some
                      //  other restructuring to make that happen.
                      sx={{
                        '& .nav-item-inner': { width: '100%' },
                        '& .MuiAvatar-root': { width: '33px', height: '33px' },
                        '& .nested-list .MuiListItem-root': { pl: 4 },
                        '& .MuiListItem-root, & .MuiListItemButton-root': {
                          '&:hover': { backgroundColor: ({ palette }) => alpha(palette.text.primary, 0.04) },
                          '&.selected-nav': { backgroundColor: ({ palette }) => alpha(palette.text.primary, 0.15) },
                        },
                      }}
                    >
                      {mobileItems.map((item, idx) => (
                        <Component key={idx} onClick={handleOnClose} {...item} />
                      ))}
                    </List>
                  </Drawer>
                </NavContainer>
                <NavContainer display={{ xs: 'none', md: 'flex' }}>
                  <ReferralLinkButton />
                  <NavList
                    className="nav-list"
                    sx={{
                      '& .nav-item-inner, & .nav-item': {
                        color: ({ palette }) =>
                          navigationStyle === NavigationBarStyle.GRADIENT ? palette.lightened.bare : undefined,
                      },
                    }}
                    gap={2}
                  >
                    {secondaryItems?.map(componentRenderFn)}
                  </NavList>
                </NavContainer>
              </>
            )}
          </Toolbar>
        </AppBar>
      </nav>
    );
  },
);
