import { useMutation, useQueryClient } from '@tanstack/react-query';

import { adminAccountsPrimaryKeyFn } from '@arrived/admin-queries';
import { updateAccountBlocks } from '@arrived/api_v2';
import { UseAwaitedMutationOptions, accountsKeyFn } from '@arrived/queries';

export function useAdminPostAccountBlocksUpdateMutation(
  options?: UseAwaitedMutationOptions<typeof updateAccountBlocks>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAccountBlocks,
    ...options,
    onSuccess: (data, variables, context) => {
      const { cid, id } = data;

      queryClient.invalidateQueries({ queryKey: accountsKeyFn(id) });
      queryClient.invalidateQueries({ queryKey: accountsKeyFn(cid) });
      queryClient.invalidateQueries({ queryKey: adminAccountsPrimaryKeyFn(data.primaryUser.id) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
