import { useEffect } from 'react';

import { notificationService } from '@arrived/features-toasty';

/**
 * Used for messaging users on email verification status,
 * https://auth0.com/docs/customize/email/email-templates/email-template-descriptions#redirect-to-results-for-verification-email-template
 */
export const useEmailVerificationNotification = (params: URLSearchParams) => {
  useEffect(() => {
    if (params.has('message')) {
      const successParam = params.get('success');
      const messageParam = params.get('message');
      if (successParam === 'true') {
        notificationService.success(messageParam);
      } else if (successParam === 'false') {
        notificationService.error(messageParam);
      }
    }
  }, [params]);
};
