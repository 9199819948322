import { useCallback, useState } from 'react';

export const useLoopedIndex = (arrayLength: number, defaultIndex = 0, onIndexChange?: (idx?: number) => void) => {
  const [index, setIndexInternal] = useState<undefined | number>(defaultIndex ?? 0);

  const getNextIndex = useCallback(
    (newIndex?: number) => {
      if (newIndex) {
        if (newIndex >= arrayLength) return 0;
        if (newIndex < 0) return arrayLength - 1;
      }
      return newIndex;
    },
    [arrayLength],
  );

  const setIndex = useCallback(
    (initIndex?: number) => {
      const newIndex = getNextIndex(initIndex);
      if (index !== newIndex && onIndexChange) onIndexChange(newIndex);
      setIndexInternal(newIndex);
    },
    [setIndexInternal, getNextIndex, index, onIndexChange],
  );

  return [index, setIndex, getNextIndex] as const;
};
