/**
 * Dalmore's approval status of an investor trade.
 */
export const TradeBrokerApprovalStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DISAPPROVED: 'DISAPPROVED',
  /**
   * Default/only option for NC trades
   */
  NOT_APPLICABLE: 'NOT_APPLICABLE',
} as const;

/**
 * Dalmore's approval status of an investor trade.
 */
export type TradeBrokerApprovalStatus = (typeof TradeBrokerApprovalStatus)[keyof typeof TradeBrokerApprovalStatus];
