import { GetProps, Stack, isWeb, styled } from '@arrived/bricks';
import { LazyImage } from '@arrived/bricks-common';
import { Constants } from '@arrived/common';

import { PRODUCT_LIST_ITEM_IMAGE_NAME } from './constants';
import { ProductListItemStyledContext } from './ProductListItemStyledContext';

export interface ProductListItemImageProps {
  imageSource?: string | null;
}

const ProductListItemImageFrame = styled(Stack, {
  name: PRODUCT_LIST_ITEM_IMAGE_NAME,

  context: ProductListItemStyledContext,

  br: '$2',
  overflow: 'hidden',

  h: 72,
  w: 72,

  variants: {
    size: {
      small: {},
      large: {
        h: 112,
        w: 112,
      },
    },
  } as const,
});

const ProductListItemImageInnerFrame = styled(Stack, {
  context: ProductListItemStyledContext,

  fullscreen: true,

  style: {
    transition: 'transform 150ms',
  },

  // @ts-expect-error hover isn't recognized
  '$group-productListItem-hover': {
    scale: 1.05,
  },
  '$group-productListItem-focus': {
    scale: 1.05,
  },
  '$group-productListItem-press': {
    // TODO: Not sure why, but this doesn't seem to be working on mobile :(
    scale: isWeb ? 1 : 1.05,
  },
});

export const ProductListItemImage = ProductListItemImageFrame.styleable<
  GetProps<typeof ProductListItemImageFrame> & ProductListItemImageProps
>(({ imageSource, ...rest }, ref) => (
  <ProductListItemImageFrame ref={ref} {...rest}>
    <ProductListItemImageInnerFrame>
      <LazyImage flex={1} source={{ uri: imageSource ?? Constants.defaultPropertyImagePath }} />
    </ProductListItemImageInnerFrame>
  </ProductListItemImageFrame>
));
