import { AccountId } from './account';

export enum TAX_DOCUMENT_TYPE {
  FORM_1099_DIV = 'FORM_1099_DIV',
  OFFERING_BREAKDOWN = 'OFFERING_BREAKDOWN',
}

export enum FILE_TYPE {
  PDF = 'PDF',
  JSON = 'JSON',
}

export enum CONTENT_TYPE {
  PDF = 'text/pdf',
  JSON = 'text/json',
}

export interface TaxDocument {
  accountId: AccountId;
  downloadUri: string;
  fileName: string;
  fileType: FILE_TYPE;
  httpContentType: CONTENT_TYPE;
  taxDocType: TAX_DOCUMENT_TYPE;
  taxYear: number;
}

export interface TaxYearRequest {
  reitDemandLetterIsAcknowledged: boolean;
  year: number;
  accountId: AccountId;
}
