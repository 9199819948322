export enum AccountType {
  ENTITY = 'ENTITY',
  INDIVIDUAL = 'INDIVIDUAL',
  IRA = 'IRA',
  JOINT = 'JOINT',
  JTWROS = 'JTWROS',
  ROTH = 'ROTH',
  SEP_IRA = 'SEP_IRA',
  TIC = 'TIC',
  CHECKBOOK_IRA = 'CHECKBOOK_IRA',
}

export enum TaxIdType {
  SSN = 'SSN',
  EIN = 'EIN',
}
