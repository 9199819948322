import { FormattedMessage } from 'react-intl';

import { Link } from '@mui/material';

export const ContactSupport = () => {
  return (
    <Link fontSize="inherit" className="open-intercom">
      <FormattedMessage id="contact-support-text" defaultMessage="contact support" />
    </Link>
  );
};
