import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postPropertyFeatureCategory } from '@arrived/api_v2';

import { featureCategoriesQueryKey } from './features.keys';

export function usePostFeatureCategoryMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postPropertyFeatureCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: featureCategoriesQueryKey });
    },
  });
}
