import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { getProjectedAppreciationRange } from '@arrived/common';
import { ReturnsCalculatorForm, getReturnsData } from '@arrived/forms';
import { Offering } from '@arrived/models';
import { useGetZillowZipCodeAppreciationQuery } from '@arrived/queries';

import { AppreciationInputType, getAppreciationInputOptions } from './constants';

export interface UseHypotheticalReturnsDataParams
  extends Pick<ReturnsCalculatorForm, 'investmentAmount' | 'leverage' | 'rentalDividendYield'> {
  offering: Offering;
}

export function useHypotheticalReturnsData({
  investmentAmount,
  leverage,
  offering,
  rentalDividendYield,
}: UseHypotheticalReturnsDataParams) {
  const intl = useIntl();

  const zipCode = useMemo(() => offering.properties?.[0]?.address?.zipCode, [offering]);

  const zipCodeAppreciationState = useGetZillowZipCodeAppreciationQuery(zipCode);
  const [projectedAppreciationLower, projectedAppreciationUpper] = useMemo(
    () => getProjectedAppreciationRange(zipCodeAppreciationState.data),
    [zipCodeAppreciationState.data],
  );

  const options = useMemo(
    () =>
      getAppreciationInputOptions({
        tenYearHistoricalAppreciation: projectedAppreciationUpper || undefined,
        twentyYearHistoricalAppreciation: projectedAppreciationLower || undefined,
      }),
    [offering, projectedAppreciationLower, projectedAppreciationUpper],
  );

  return useMemo(() => {
    return options
      .filter((option) => !(option.type === AppreciationInputType.ZIP_CODE && zipCode == null))
      .map((option) => {
        const { cumulativeReturns, irr } = getReturnsData({
          annualAppreciation: option.value,
          investmentAmount,
          leverage,
          offering,
          rentalDividendYield,
        });

        const appreciationPercent = intl.formatNumber(option.value, {
          style: 'percent',
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
        });

        return {
          irr,
          label:
            option.type === AppreciationInputType.ZIP_CODE
              ? intl.formatMessage(
                  { id: 'property-details.calculator.zip-code-avg-appreciation' },
                  { percent: appreciationPercent, years: option.years, zipCode },
                )
              : intl.formatMessage(
                  { id: 'property-details.calculator.us-avg-appreciation' },
                  { percent: appreciationPercent, years: option.years },
                ),
          return: cumulativeReturns[cumulativeReturns.length - 1],
          type: option.type,
        };
      });
  }, [investmentAmount, leverage, offering, options, rentalDividendYield, zipCode]);
}
