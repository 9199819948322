import { useQuery } from '@tanstack/react-query';

import { getAirDnaMonthlyOccupancyByOffering } from '@arrived/api_v2';
import { OfferingId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { airDnaMonthlyOccupancyKeyFn } from './airDna.keys';

export function useGetAirDnaMonthlyOccupancyByOffering(
  offeringId: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getAirDnaMonthlyOccupancyByOffering>,
) {
  return useQuery({
    queryKey: airDnaMonthlyOccupancyKeyFn(offeringId),
    queryFn: () => getAirDnaMonthlyOccupancyByOffering({ offeringId }),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
