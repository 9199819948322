import { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { differenceInMonths, format } from 'date-fns';

import { Stack, Typography } from '@mui/material';

import { US_DATE_FORMAT } from '@arrived/common';

interface RegDFirstTradeDateViewProps {
  createdAt: string | null;
}

export const RegDFirstTradeDateView = (props: RegDFirstTradeDateViewProps) => {
  const isLessThanThreeMonthsAgo = useMemo(() => {
    if (!props.createdAt) {
      return undefined;
    }

    const now = new Date();
    const difference = differenceInMonths(now, new Date(props.createdAt));

    return difference <= 3;
  }, [props.createdAt]);

  if (props.createdAt == null) {
    return <FormattedMessage id="common.not-applicable" />;
  }
  const createdAtDate = new Date(props.createdAt);
  return (
    <Stack>
      {isLessThanThreeMonthsAgo && (
        <Typography color="danger">
          <FormattedMessage id="account.created-less-than-three-months-ago" />
        </Typography>
      )}
      <Typography>{format(createdAtDate, US_DATE_FORMAT)}</Typography>
    </Stack>
  );
};
