import { Fragment, useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { differenceInDays } from 'date-fns';

import { AppreciationIcon, ChartTrendingUpIcon, RentalIncomeIcon, Stack, TitleText, Tooltip } from '@arrived/bricks';
import { AccordionList, Markdown } from '@arrived/bricks-common';
import {
  MonthlyFundPerformanceData,
  getAnnualizedValue,
  getSFRFundHistoryRowData,
  mergeOfferingDividendsAndSharePrices,
} from '@arrived/common';
import { OfferingDividend, OfferingSharePrices, SFRFundData } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../../content';
import { ProductInfoBox } from '../../../../../productInfoBox';
import { Row } from '../../../../../row';
import { Graphic } from '../../../returnsGraphic';

import { YearlyPerformanceTable } from './YearlyPerformanceTable';

export type FundPerformanceSectionProps = Omit<ProductDetailContentProps, 'children'> & {
  dividends?: OfferingDividend[];
  fundPerformance?: SFRFundData['fundPerformance'];
  sharePrices?: OfferingSharePrices[];
};

export const FundPerformanceSection = ({
  dividends,
  fundPerformance,
  sharePrices,
  ...rest
}: FundPerformanceSectionProps) => {
  const dividendSharePrices = useMemo(
    () => mergeOfferingDividendsAndSharePrices({ dividends, sharePrices }),
    [dividends, sharePrices],
  );

  const allData = useMemo(() => getSFRFundHistoryRowData(dividendSharePrices), [dividendSharePrices]);

  /**
   * This memo will calculate the annualized dividend for the fund over the previous up to 12
   * months of data. If there is not twelve months of data, we'll simply use the maximum possible
   * (i.e. however many months the fund has existed).
   */
  const twelveMonthAnnualizedDividendPercent = useMemo(() => {
    if (allData.length === 0) {
      return;
    }

    // This will be the index of the array to start the period for which we're annualizing the dividend at.
    const twelveMonthsOrEarliestIndex = Math.max(0, allData.length - 12);

    const startingSharePrice = allData[twelveMonthsOrEarliestIndex].sharePriceDuringPeriod?.sharePrice;
    const startDate = allData[twelveMonthsOrEarliestIndex].startDate;
    const endDate = allData[allData.length - 1].endDate;

    if (!endDate || !startingSharePrice) {
      return;
    }

    const daysInPeriod = differenceInDays(new Date(endDate), new Date(startDate)) + 1;

    const totalDividend = allData.reduce((sum, { dividendPerShare }) => sum + (dividendPerShare ?? 0), 0);

    const annualizedValue = getAnnualizedValue({ daysInPeriod, value: totalDividend });
    return annualizedValue != null ? annualizedValue / startingSharePrice : undefined;
  }, [allData]);

  /**
   * Get the SFR Fund History row data and then append extra months to show future dividend cutoff
   * dates. This also groups the data by year so it can be displayed in separate yearly tables.
   */
  const rowData = useMemo(() => {
    /**
    NOTE: The commented out section here is for calculating the cutoff date for the next 2-3
    months, we are currently not showing this in the table, but in case we decide to in the future
    we'll leave this code here.

    // Now we add the next two months as entries so we can show the future cutoff dates for
    // receiving fund dividends.
    const lastDate = data[data.length - 1].postedAtDate;
    let additionalMonths = 2;

    const currentDate = new Date();

    // If we are currently in a month that is beyond the last entry for the fund history, then we
    // actually add three months of cutoff dates (including the current month).
    if (currentDate.getMonth() !== lastDate.getMonth() || currentDate.getFullYear() !== lastDate.getFullYear()) {
      additionalMonths = 3;
    }

    for (let i = 1; i <= additionalMonths; i++) {
      data.push({
        // We're currently not displaying/using cutoff date, but we'll keep the computation in here
        // in case we later decide to surface it.
        cutoffDate: lastDayOfMonth(add(data[data.length - 1].cutoffDate, { months: 1 })),
        postedAtDate: add(data[data.length - 1].postedAtDate, { months: 1 }),
      });
    }
    */

    // Now group the entries by year
    return Object.entries(
      allData.reduce(
        (map, current) => {
          const year = current.postedAtDate.getFullYear();
          if (!Object.keys(map).includes(year.toString())) {
            map[year] = [];
          }
          map[year].push(current);
          return map;
        },
        {} as Record<number, MonthlyFundPerformanceData[]>,
      ),
    ).sort(([aYear], [bYear]) => parseInt(bYear) - parseInt(aYear));
  }, [allData]);

  const sections = useMemo(() => {
    const sections = [];

    if (fundPerformance) {
      sections.push(
        <Stack gap="$4" $gtXs={{ row: true, gap: '$6', alignItems: 'center' }}>
          <Graphic />
          <Markdown Text={ProductDetailContent.Text}>{fundPerformance.intro}</Markdown>
        </Stack>,
        <>
          <ProductDetailContent.SubHeader Icon={RentalIncomeIcon}>
            <FormattedMessage id="product-details.historical-fund-performance.rental-income" />
          </ProductDetailContent.SubHeader>
          <Markdown Text={ProductDetailContent.Text}>{fundPerformance.rentalIncome.text}</Markdown>
          {twelveMonthAnnualizedDividendPercent != null && (
            <ProductInfoBox p={0} variant="highlight">
              <Row>
                <Row.Label tooltip={<Markdown Text={Tooltip.Text}>{fundPerformance.rentalIncome.tooltip}</Markdown>}>
                  <FormattedMessage id="product-details.historical-fund-performance.historic-dividend-income" />
                </Row.Label>
                <Row.Value variant="large">
                  <FormattedNumber
                    value={twelveMonthAnnualizedDividendPercent}
                    style="percent"
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                  />
                </Row.Value>
              </Row>
            </ProductInfoBox>
          )}
        </>,
        <>
          <ProductDetailContent.SubHeader Icon={AppreciationIcon}>
            <FormattedMessage id="product-details.historical-fund-performance.appreciation" />
          </ProductDetailContent.SubHeader>
          <Markdown Text={ProductDetailContent.Text}>{fundPerformance.appreciation.text}</Markdown>
          <ProductInfoBox p={0} variant="highlight">
            <Row>
              <Row.Label tooltip={<Markdown Text={Tooltip.Text}>{fundPerformance.appreciation.tooltip}</Markdown>}>
                <FormattedMessage id="product-details.historical-fund-performance.historic-annual-appreciation" />
              </Row.Label>
              <Row.Value variant="large">{fundPerformance.appreciation.value}</Row.Value>
            </Row>
          </ProductInfoBox>
        </>,
      );
    }

    if (rowData.length > 0) {
      sections.push(
        <>
          <ProductDetailContent.SubHeader Icon={ChartTrendingUpIcon}>
            <FormattedMessage id="product-details.historical-fund-performance.historic-performance" />
          </ProductDetailContent.SubHeader>
          {fundPerformance && (
            <Markdown Text={ProductDetailContent.Text}>{fundPerformance.historicalPerformance}</Markdown>
          )}
          <AccordionList>
            {rowData.map(([year, monthlyPerformanceData], idx) => (
              <AccordionList.Item
                key={year}
                defaultExpanded={idx === 0}
                Content={() => <TitleText token="title.heading.small">{year}</TitleText>}
              >
                <AccordionList.Item.Children>
                  <YearlyPerformanceTable monthlyPerformanceData={monthlyPerformanceData} />
                </AccordionList.Item.Children>
              </AccordionList.Item>
            ))}
          </AccordionList>
        </>,
      );

      if (fundPerformance) {
        sections.push(
          <>
            <ProductDetailContent.SubHeader>
              <FormattedMessage id="product-details.historical-fund-performance.quarterly-performance" />
            </ProductDetailContent.SubHeader>
            <Markdown Text={ProductDetailContent.Text}>{fundPerformance.quarterlyPerformanceText}</Markdown>
          </>,
        );
      }
    }

    return sections;
  }, [fundPerformance, rowData, twelveMonthAnnualizedDividendPercent]);

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.historical-fund-performance" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body>
        {sections.map((section, idx) => (
          <Fragment key={idx}>
            {idx > 0 && <ProductDetailContent.Divider />}
            {section}
          </Fragment>
        ))}
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};
