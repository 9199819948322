import { useMemo } from 'react';

import { getMinSharesToBuy, getOfferingTransactState } from '@arrived/common';
import { Offering } from '@arrived/models';
import { useCanUserTransactEarlyAccess } from '@arrived/queries';

import { useCanUserTransactOpenForSec } from './useCanUserTransactOpenForSec';
import { useIsOfferingBlocked } from './useIsOfferingBlocked';

/**
 * Returns a high level state value indicating the status of the Offering and how it should be displayed with regards
 * to it's transactability.
 */
export function useOfferingTransactState(offering: Offering) {
  const canUserTransactEarlyAccess = useCanUserTransactEarlyAccess();
  const canUserTransactOpenSecTrade = useCanUserTransactOpenForSec();

  const isOfferingBlocked = useIsOfferingBlocked();

  const isOfferingSoldOut = useMemo(() => offering.totalSharesAvailable < getMinSharesToBuy(offering), [offering]);

  return useMemo(
    () =>
      getOfferingTransactState({
        canUserTransactEarlyAccess,
        canUserTransactOpenSecTrade,
        offering,
        isOfferingBlocked: isOfferingBlocked(offering.shortName),
        isOfferingSoldOut,
      }),
    [
      canUserTransactEarlyAccess,
      canUserTransactOpenSecTrade,
      canUserTransactOpenSecTrade,
      isOfferingBlocked,
      isOfferingSoldOut,
      offering,
    ],
  );
}
