import axios from 'axios';

import { CONFIG } from '@arrived/config';

/**
 * Our base API factory, which creates an axios instance with the base URL and headers.
 *
 * We need to add the user's timezone to the requests made to our services so that we
 * can generate trade document information pertaining to the user's timezone and not the timezone
 * of our services.
 */
export const apiFactory = (baseURL: string) =>
  axios.create({
    baseURL,
    headers: {
      'Content-type': 'application/json',
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

/**
 * Generic Abacus API instance.
 * TODO: Using abacusApi or publicApi causes things to break, for some reason it refuses to assign a bearer token
 */
export const api = apiFactory(CONFIG.abacusApiRoot);

export const abacusApi = apiFactory(CONFIG.abacusApiRoot);
export const publicApi = apiFactory(CONFIG.abacusApiRoot);
export const rewardsApi = apiFactory(CONFIG.rewardsApiRoot);
export const cashAccountApi = apiFactory(CONFIG.cashAccountsApiRoot);
export const cashAccountInternalApi = apiFactory(CONFIG.cashAccountsInternalApiRoot);
export const strapiApi = apiFactory(CONFIG.arrivedCmsApiUrl);
export const marketingApi = apiFactory(CONFIG.marketingApiUrl);
export const wordpressApi = apiFactory(CONFIG.learnBlogUrl);
export const demioApi = apiFactory(CONFIG.demioApiRoot);
export const assetMetadataApi = apiFactory(CONFIG.assetMetadataApiRoot);
export const historicalReturnsApi = apiFactory(CONFIG.historicalReturnsApiRoot);
export const airDnaApi = apiFactory(CONFIG.airDnaApiRoot);

/**
 * @deprecated requires migration for KPI endpoints to be moved to the new marketing API via SST
 */
export const _marketingApi = apiFactory(CONFIG.marketingApiArrivedHomesUrl);

/**
 * Tune Affiliate API instance
 *
 * @note Errors with Timezone header
 */
export const affiliateApi = axios.create({ baseURL: CONFIG.marketing.tuneAffiliateBaseUrl });
