import { useQuery } from '@tanstack/react-query';

import { getSardineSessionKey } from '@arrived/api_v2';
import { useArrivedAuth0 } from '@arrived/arrived-auth0';

import { UseAwaitedQueryOptions } from '../utils';

import { currentUserSardineSessionKeyFn } from './users.keys';

export function useGetSardineSessionKeyQuery(
  options?: Omit<UseAwaitedQueryOptions<typeof getSardineSessionKey>, 'refetchInterval'>,
) {
  const { isAuthenticated } = useArrivedAuth0();

  return useQuery({
    queryKey: currentUserSardineSessionKeyFn(),
    queryFn: () => getSardineSessionKey(),
    refetchInterval: (query) => {
      // We're setting the refetchInterval based on the expiration of the most recently returned
      // sessionKey. We only want to be doing that when:
      //   1. The query is in a success state
      //   2. The query is not currently running
      //   3. There is an existing expiration time for the current sessionKey
      // If all 3 of those cases are true then we set to the time to expire as the remaining TTL on
      // the sessionKey.
      if (query.state.status === 'success' && query.state.fetchStatus === 'idle' && query.state.data?.expiration) {
        const timeToExpire = query.state.data.expiration - Date.now();
        if (timeToExpire > 0) {
          return timeToExpire;
        }
      }

      return false;
    },
    ...options,
    enabled: (options?.enabled ?? true) && isAuthenticated,
  });
}
