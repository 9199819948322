import { QueryClient, useQuery } from '@tanstack/react-query';

import { getOfferingTransaction } from '@arrived/api_v2';
import { OfferingTransactionId, OfferingTransactionQueryParams } from '@arrived/models';

import { AwaitedFetchQueryOptions, UseAwaitedQueryOptions } from '../utils';

import { tradeQueryKeyFn } from './trades.keys';

export function useTradeQuery(
  tradeId?: OfferingTransactionId | string,
  queryParams?: OfferingTransactionQueryParams,
  options?: UseAwaitedQueryOptions<typeof getOfferingTransaction>,
) {
  return useQuery({
    queryKey: tradeQueryKeyFn(tradeId, queryParams),
    queryFn: () => getOfferingTransaction(tradeId!, queryParams),
    ...options,
    enabled: (options?.enabled ?? true) && tradeId != null,
  });
}

export const fetchTradeQuery = async (
  queryClient: QueryClient,
  tradeId: OfferingTransactionId | string,
  queryParams?: OfferingTransactionQueryParams,
  options?: AwaitedFetchQueryOptions<typeof getOfferingTransaction>,
) => {
  return queryClient.fetchQuery({
    queryKey: tradeQueryKeyFn(tradeId, queryParams),
    queryFn: () => getOfferingTransaction(tradeId, queryParams),
    ...options,
  });
};
