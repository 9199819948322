import { ExternalAccountAccountType } from './fullExternalAccount';

export interface ExternalAccount {
  id: number;
  cid: string;
  type?: ExternalAccountAccountType;
  accountHolderFullName?: string;
  accountNickname?: string;
  accountNumberLastFour?: string;
  cashExternalBankAccountCid: null | string;
  verified: ExternalAccountVerificationStatus;
  linkType: ExternalAccountLinkType;
  /**
   * Indicates if the ExternalAccount has been sync'd to NC. This will only be false if a sync has been attempted after
   * the changes to add this attribute were deployed.
   */
  ncSyncCompleted?: boolean;
  ncStatusCode?: number;
  createdAt: Date;
  updatedAt: Date;
}

export enum ExternalAccountVerificationStatus {
  /**
   * External Account has not yet been verified.
   */
  NOT_VERIFIED = 'NOT_VERIFIED',
  /**
   * External Account is ready to verify. This would be the case when we know that the account has received deposits
   * (in the case of manual link with Plaid) and is ready for the user to verify.
   */
  READY_TO_VERIFY = 'READY_TO_VERIFY',
  /**
   * External Account has been verified.
   */
  VERIFIED = 'VERIFIED',
}

export enum ExternalAccountLinkType {
  /**
   * Plaid credentials were used to link the user's bank account.
   */
  PLAID = 'PLAID',
  /**
   * Plaid micro-deposits were used to link the user's bank account.
   */
  PLAID_MICRO_DEPOSITS = 'PLAID_MICRO_DEPOSITS',
  /**
   * We manually created the bank link through our system. This means we received ACH numbers from the user and
   * verified them with a user provided document.
   */
  MANUAL = 'MANUAL',
}

/**
 * Actions needed to setup a users external account for investing, ordered by precedence (i.e. 0 has the highest precedence).
 */
export enum ExternalAccountSetupState {
  LINK_BANK = 'LINK_BANK',
  VERIFY_BANK = 'VERIFY_BANK',
  // TODO: If we're able to get webhook setup with Plaid, we could move users to READY_TO_VERIFY when we know they've
  //  received deposits
  // READY_TO_VERIFY
  VERIFIED = 'VERIFIED',
}
