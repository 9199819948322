export default {
  'disclaimer.individual-issuer': `Arrived Holdings, Inc. ("Arrived"), as a manager of {LLCName} operates the \
  {arrivedHomepageLink} website (the "Site") and is not a broker-dealer or investment advisor. All securities related \
  activity is conducted through Dalmore Group LLC, a registered broker-dealer and member of {FINRALink}/{SIPCLink}, \
  located at 525 Green Place, Woodmere, NY 11598. You can review the {dalmoreBrokerCheckLink}. An up-to-date \
  {dalmoreFormCRSLink} is available. \
  \n\n \
  You should speak with your financial advisor, accountant and/or attorney when evaluating any offering. Neither \
  Arrived, {LLCName}, nor Dalmore makes any recommendations or provides advice about investments, and no communication, \
  through this website or in any other medium, should be construed as a recommendation for any security offered on \
  or off this investment platform. The Site may make forward-looking statements. You should not rely on these \
  statements but should carefully evaluate the offering materials in assessing any investment opportunity, including \
  the complete set of risk factors that are provided as part of the offering circular for your consideration. \
  \n\n \
  {LLCName} is conducting public offerings pursuant to Regulation A, as amended, through the Site. The offering \
  circulars and periodic reports for {LLCName} are available on our <circularsPage>Filing Page</circularsPage>. \
  Past performance is no guarantee of future results. Investments such as those on the Arrived platform are speculative \
  and involve substantial risks to consider before investing, outlined in the respective offering materials and \
  including, but not limited to, illiquidity, lack of diversification and complete loss of capital. Key risks include, \
  but are not limited to, limited operating history, limited diversification, risk of asset damage or theft and lack of \
  voting rights. Also, the adverse economic effects of the COVID-19 pandemic remain unknown and could materially impact \
  this investment. An investment in an offering constitutes only an investment in a particular series and not in \
  {LLCName} or the underlying asset(s). Investors should carefully review the risks located in the respective offering \
  materials for a more comprehensive discussion of risk. \
  \n\n \
  From time to time, {LLCName} will seek to qualify additional series offerings of securities under regulation A. \
  For offerings that have not yet been qualified, no money or other consideration is being solicited and, if sent in \
  response, will not be accepted. No offer to buy securities of a particular offering can be accepted, and no part \
  of the purchase price can be received, until an offering statement filed with the Securities and Exchange \
  Commission (the "SEC") relating to that series has been qualified by the SEC. Any such offer may be \
  withdrawn or revoked, without obligation or commitment of any kind, at any time before notice of acceptance given \
  after the date of qualification by the SEC. An indication of interest involves no obligation or commitment of any kind. \
  \n\n \
  Investment overviews contained herein contain summaries of the purpose and the principal business terms of the \
  investment opportunities. Such summaries are intended for informational purposes only and do not purport to be \
  complete, and each is qualified in its entirety by reference to the more-detailed discussions contained in the \
  respective offering circular filed with the SEC. \
  \n\n \
  {LLCName} does not offer refunds after an investment has been made. Please review the relevant offering \
  materials and subscription documentation for more information. \
  \n\n \
  An active trading market for any series of {LLCName} membership interests may not develop or be sustained. If an active \
  public trading market for {LLCName} series interests does not develop or is not sustained, it may be difficult \
  or impossible for you to resell your interests at any price. Even if an active market does develop, the market \
  price could decline below the amount you paid for your interests. There is no assurance that the Arrived \
  platform will provide an active market for resales of {LLCName} series interests. Further, without the Arrived \
  platform, it may be difficult or impossible for you to dispose of your interests. If the market develops for \
  any series of {LLCName} interests, the market price of such interests could fluctuate significantly for many \
  reasons, including reasons unrelated to performance, the underlying assets or any series, such as reports by \
  industry analysts, investor perceptions or announcements by competitors regarding their own performance, as well \
  as general economic and industry conditions. \
  \n\n \
  For additional risk factors and disclaimer information, you can review our {communicationsDisclaimerLink}.`,

  'disclaimer.fund-issuer': `Arrived Holdings, Inc. ("Arrived"), as a manager of {LLCName} operates the \
  {arrivedHomepageLink} website (the "Site") and is not a broker-dealer or investment advisor. All securities related \
  activity is conducted through Dalmore Group LLC, a registered broker-dealer and member of {FINRALink}/{SIPCLink}, \
  located at 525 Green Place, Woodmere, NY 11598. You can review the {dalmoreBrokerCheckLink}. An up-to-date \
  {dalmoreFormCRSLink} is available. \
  \n\n \
  You should speak with your financial advisor, accountant and/or attorney when evaluating any offering. Neither \
  Arrived, {LLCName}, nor Dalmore makes any recommendations or provides advice about investments, and no communication, \
  through this website or in any other medium, should be construed as a recommendation for any security offered on \
  or off this investment platform. The Site may make forward-looking statements. You should not rely on these \
  statements but should carefully evaluate the offering materials in assessing any investment opportunity, including \
  the complete set of risk factors that are provided as part of the offering circular for your consideration. \
  \n\n \
  {LLCName} is conducting public offerings pursuant to Regulation A, as amended, through the Site. The offering \
  circulars and periodic reports for {LLCName} are available on our <circularsPage>Filing Page</circularsPage>. \
  Past performance is no guarantee of future results. Investments such as those on the Arrived platform are speculative \
  and involve substantial risks to consider before investing, outlined in the respective offering materials and \
  including, but not limited to, illiquidity, lack of diversification and complete loss of capital. Key risks include, \
  but are not limited to, limited operating history, limited diversification, risk of asset damage or theft and lack of \
  voting rights. Also, the adverse economic effects of the COVID-19 pandemic remain unknown and could materially impact \
  this investment. An investment in an offering constitutes only an investment in a particular series and not in \
  {LLCName} or the underlying asset(s). Investors should carefully review the risks located in the respective offering \
  materials for a more comprehensive discussion of risk. \
  \n\n \
  From time to time, {LLCName} may seek to qualify additional membership interests under regulation A. \
  For any offering of membership interests in excess of those that have already been qualified, no money or other consideration is being solicited and, if sent in \
  response, will not be accepted. No offer to buy securities can be accepted, and no part \
  of the purchase price can be received, until an offering statement filed with the Securities and Exchange \
  Commission (the "SEC") relating to those membership interests has been qualified by the SEC. Any such offer may be \
  withdrawn or revoked, without obligation or commitment of any kind, at any time before notice of acceptance given \
  after the date of qualification by the SEC. An indication of interest involves no obligation or commitment of any kind. \
  \n\n \
  Investment overviews contained herein contain summaries of the purpose and the principal business terms of the \
  investment opportunities. Such summaries are intended for informational purposes only and do not purport to be \
  complete, and each is qualified in its entirety by reference to the more-detailed discussions contained in the \
  respective offering circular filed with the SEC. \
  \n\n \
  {LLCName} does not offer refunds after an investment has been made. Please review the relevant offering \
  materials and subscription documentation for more information. \
  \n\n \
  An active trading market for any series of {LLCName} membership interests may not develop or be sustained. If an active \
  public trading market for {LLCName} series interests does not develop or is not sustained, it may be difficult \
  or impossible for you to resell your interests at any price. Even if an active market does develop, the market \
  price could decline below the amount you paid for your interests. There is no assurance that the Arrived \
  platform will provide an active market for resales of {LLCName} series interests. Further, without the Arrived \
  platform, it may be difficult or impossible for you to dispose of your interests. If the market develops for \
  any series of {LLCName} interests, the market price of such interests could fluctuate significantly for many \
  reasons, including reasons unrelated to performance, the underlying assets or any series, such as reports by \
  industry analysts, investor perceptions or announcements by competitors regarding their own performance, as well \
  as general economic and industry conditions. \
  \n\n \
  For additional risk factors and disclaimer information, you can review our {communicationsDisclaimerLink}.`,

  'disclaimer.all-issuers': `Arrived Holdings, Inc. ("Arrived"), \
  as a manager of Arrived Homes, LLC, Arrived STR, LLC, Arrived Homes 3, LLC, Arrived STR 2, LLC and Arrived SFR Genesis Fund, LLC, Arrived Debt Fund, LLC (together the \
  “Arrived Issuers”) operates the {arrivedHomepageLink} website (the "Site") and is not a broker-dealer or investment \
  advisor. All securities related activity is conducted through Dalmore Group LLC, a registered broker-dealer and member \
  of {FINRALink}/{SIPCLink}, located at 525 Green Place, Woodmere, NY 11598. You can review the \
  {dalmoreBrokerCheckLink}. An up-to-date {dalmoreFormCRSLink} is available. \
  \n\n \
  You should speak with your financial advisor, accountant and/or attorney when evaluating any offering. Neither \
  Arrived, any of the Arrived Issuers, nor Dalmore makes any recommendations or provides advice about investments, and \
  no communication, through this website or in any other medium, should be construed as a recommendation for any \
  security offered on or off this investment platform. The Site may make forward-looking statements. You should not \
  rely on these statements but should carefully evaluate the offering materials in assessing any investment opportunity, \
  including the complete set of risk factors that are provided as part of the offering circular for your consideration. \
  \n\n \
  The Arrived Issuers are conducting public offerings pursuant to Regulation A, as amended, through the Site. The \
  offering circulars and periodic reports for each of the Arrived Issuers are available on our <circularsPage>Filings \
  Page</circularsPage>. Past performance is no guarantee of future results. Investments such as \
  those on the Arrived platform are speculative and involve substantial risks to consider before investing, \
  outlined in the respective offering materials and including, but not limited to, illiquidity, lack of \
  diversification and complete loss of capital. Key risks include, but are not limited to, limited operating history, \
  limited diversification, risk of asset damage or theft and lack of voting rights. Also, the adverse economic \
  effects of the COVID-19 pandemic remain unknown and could materially impact this investment. An investment in an \
  offering constitutes only an investment in a particular series and not in any of the Arrived Issuers or the underlying \
  asset(s). Investors should carefully review the risks located in the respective offering materials for a more \
  comprehensive discussion of risk. \
  \n\n \
  From time to time, any of the Arrived Issuers will seek to qualify additional series offerings of securities under regulation A. \
  For offerings that have not yet been qualified, no money or other consideration is being solicited and, if sent in \
  response, will not be accepted. No offer to buy securities of a particular offering can be accepted, and no part \
  of the purchase price can be received, until an offering statement filed with the Securities and Exchange \
  Commission (the "SEC") relating to that series has been qualified by the SEC. Any such offer may be \
  withdrawn or revoked, without obligation or commitment of any kind, at any time before notice of acceptance given \
  after the date of qualification by the SEC. An indication of interest involves no obligation or commitment of any kind. \
  \n\n \
  Investment overviews contained herein contain summaries of the purpose and the principal business terms of the \
  investment opportunities. Such summaries are intended for informational purposes only and do not purport to be \
  complete, and each is qualified in its entirety by reference to the more-detailed discussions contained in the \
  respective offering circular filed with the SEC. \
  \n\n \
  None of the Arrived Issuers offer refunds after an investment has been made. Please review the relevant offering \
  materials and subscription documentation for more information. \
  \n\n \
  An active trading market for any series of any of the Arrived Issuers membership interests may not develop or be \
  sustained. If an active public trading market for such series interests does not develop or is not sustained, it may \
  be difficult or impossible for you to resell your interests at any price. Even if an active market does develop, the \
  market price could decline below the amount you paid for your interests. There is no assurance that the Arrived \
  platform will provide an active market for resales of such series interests. Further, without the Arrived \
  platform, it may be difficult or impossible for you to dispose of your interests. If the market develops for \
  any series of interests offered on the Arrived Platform, the market price of such interests could fluctuate \
  significantly for many reasons, including reasons unrelated to performance, the underlying assets or any series, such \
  as reports by industry analysts, investor perceptions or announcements by competitors regarding their own performance, \
  as well as general economic and industry conditions. \
  \n\n \
  For additional risk factors and disclaimer information, you can review our {communicationsDisclaimerLink}.`,

  'disclaimer.reg-d': `This offering is being made under Rule 506(b) of Regulation D under the Securities Act of 1933 and \
  it is intended only for a limited number of accredited investors. This is not an offer to sell or a solicitation of an \
  offer to buy any securities.  Any offer or sale of securities will be made only by means of a private placement \
  memorandum and other offering documents. \
  \n\n \
  Alternative investments involve specific risks that may be greater than those associated with traditional investments; \
  are not suitable for all clients; and are intended for experienced and sophisticated investors who meet specific \
  suitability requirements and are willing to bear the high economic risks of the investment. Investments of this type \
  may engage in speculative investment practices; carry additional risk of loss, including possibility of partial or total \
  loss of invested capital, due to the nature and volatility of the underlying investments; and are generally considered to \
  be illiquid due to restrictive repurchase procedures. Past performance is no guarantee of future results. These \
  investments may also involve different regulatory and reporting requirements, complex tax structures, and delays in \
  distributing important tax information. \
  \n\n \
  No communication by Arrived Holdings, Inc., Arrived Short Term Notes, LLC., Arrived Homes Duo, LLC.,  or any of its \
  affiliates (collectively, “Arrived”), through any medium, should be construed or is intended to be a recommendation to \
  purchase, sell or hold any security or otherwise to be investment, tax, financial, accounting, legal, regulatory or \
  compliance advice. Arrived does not offer refunds after a purchase or investment has been made.  Any historical returns, \
  expected or target returns, or probability projections may not reflect actual future performance and is no guarantee of \
  future results. All securities involve risk and may result in significant loss, including the loss of your entire \
  investment.`,

  'standard-disclaimer-dalmore-brokercheck-link-text': 'brokercheck for Dalmore',
  'standard-disclaimer-dalmore-form-crs-link-text': 'Dalmore Form CRS',
  'standard-disclaimer-communications-disclaimer-link-text': 'communications disclaimer',
} as const;
