import { FormattedMessage } from 'react-intl';

import { Constants, ROUTES } from '@arrived/common';

type FooterLink = {
  link: string;
  text: JSX.Element;
  isExternal?: boolean;
  shouldFollow?: boolean;
};

type FooterTitle = {
  title: JSX.Element;
  breakpoints: {
    xs: number;
  };
  items: FooterLink[];
};

export const FOOTER_LINKS_CONFIG: FooterTitle[] = [
  {
    title: <FormattedMessage id="footer-invest-title" defaultMessage="Invest" />,
    breakpoints: {
      xs: 4,
    },
    items: [
      {
        link: ROUTES.signUp,
        text: <FormattedMessage id="footer-sign-up" defaultMessage="Sign Up" />,
      },
      {
        link: ROUTES.properties.base,
        text: <FormattedMessage id="footer-invest" defaultMessage="Browse Properties" />,
      },
      {
        link: ROUTES.public.offeringCircular,
        text: <FormattedMessage id="footer-offering-circular" defaultMessage="Offering Circular" />,
      },
    ],
  },
  {
    title: <FormattedMessage id="footer-learn-title" defaultMessage="Learn" />,
    breakpoints: {
      xs: 4,
    },
    items: [
      {
        isExternal: true,
        shouldFollow: true,
        link: ROUTES.static.learning,
        text: <FormattedMessage id="footer-learning-center" defaultMessage="Learning Center" />,
      },
      {
        isExternal: true,
        link: ROUTES.static.help,
        text: <FormattedMessage id="common.help-and-faq" />,
      },
      {
        isExternal: true,
        shouldFollow: true,
        link: ROUTES.static.howItWorks,
        text: <FormattedMessage id="footer-how-it-works" defaultMessage="How It Works" />,
      },
      {
        isExternal: true,
        shouldFollow: true,
        link: ROUTES.static.realEstateInvestingGuide,
        text: (
          <FormattedMessage
            id="footer-investment-guide"
            defaultMessage="Real Estate{br}Investment Guide"
            values={{ br: <br /> }}
          />
        ),
      },
      {
        isExternal: true,
        shouldFollow: true,
        link: ROUTES.static.glossary,
        text: <FormattedMessage id="footer-glossary" defaultMessage="Glossary" />,
      },
    ],
  },
  {
    title: <FormattedMessage id="footer-company-title" defaultMessage="Company" />,
    breakpoints: {
      xs: 4,
    },
    items: [
      {
        link: ROUTES.aboutUs,
        text: <FormattedMessage id="footer-about-us" defaultMessage="About Us" />,
      },
      {
        link: ROUTES.careers,
        text: <FormattedMessage id="footer-careers" defaultMessage="Careers" />,
      },
      {
        link: ROUTES.termsOfService,
        text: <FormattedMessage id="footer-terms-of-service" defaultMessage="Terms of Service" />,
      },
      {
        link: ROUTES.privacyPolicy,
        text: <FormattedMessage id="footer-privacy-policy" defaultMessage="Privacy Policy" />,
      },
      {
        isExternal: true,
        shouldFollow: true,
        link: ROUTES.static.stakeholderCommitments,
        text: <FormattedMessage id="footer-stakeholder-commitments" defaultMessage="Stakeholder Commitments" />,
      },
      {
        isExternal: true,
        link: Constants.arrivedAffiliate,
        text: <FormattedMessage id="footer-affiliate" defaultMessage="Affiliate" />,
      },
      {
        isExternal: true,
        link: ROUTES.static.partnerWithArrivedSurvey,
        text: <FormattedMessage id="footer-partner-with-arrived" defaultMessage="Partner with Arrived" />,
      },
    ],
  },
];
