import { forwardRef } from 'react';

import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import { InputAttributes, NumericFormat, NumericFormatProps } from 'react-number-format';

import { TextField, TextFieldProps } from '@mui/material';

type FormNumberFieldProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> = {
  controllerProps: Omit<ControllerProps<TFieldValues, TName>, 'render'>;
} & Omit<TextFieldProps, 'onChange' | 'type'>;

export const transformNumberToString = (input: number | undefined | null, defaultValue = ''): string => {
  if (input == null) {
    return defaultValue;
  }
  return isNaN(input) ? defaultValue : input.toString();
};

export const transformStringToNumber = (possibleString: string | number, defaultValue: number = -1) => {
  const parsed = parseFloat(possibleString.toString());
  return isNaN(parsed) ? defaultValue : parsed;
};

export const FormNumberField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
  controllerProps,
  ...textFieldProps
}: FormNumberFieldProps<TFieldValues, TName>) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field: { onChange, value, ref } }) => (
        <TextField
          inputRef={ref}
          onChange={(event) => onChange(transformStringToNumber(event.target.value))}
          value={value ?? ''}
          type="number"
          sx={{
            'input::-webkit-inner-spin-button, input::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            input: {
              MozAppearance: 'textfield',
            },
          }}
          {...textFieldProps}
        />
      )}
    />
  );
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const NumberFormatComponent = forwardRef<NumericFormatProps<InputAttributes>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      {...other}
    />
  );
});
