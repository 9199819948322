import { GenericFilter } from './filters';
import { FixedRateInvestment } from './fixedRateInvestments';
import { InvestmentProductType } from './investmentProductType';
import { Issuer } from './issuers';
import { Market } from './markets';
import { Property } from './properties';
import { Tag } from './tag';

export type OfferingId = number;
export type OfferingCid = string;
export type OfferingShortName = string;
export type OfferingIdOrOfferingShortName = OfferingId | OfferingShortName;

export const OfferingStatus = {
  /**
   * Does not show up at all in application except in admin views.
   * No new transactions.
   */
  PRE_LAUNCH: 'PRE_LAUNCH',

  /**
   * Coming soon previews.
   * Does not show full property details.
   */
  COMING_SOON: 'COMING_SOON',

  /**
   * Show full property details.
   * Allow new transactions from early access users only, all other users see the Offering in a COMING SOON state.
   */
  TRANSACT_EARLY_ACCESS: 'TRANSACT_EARLY_ACCESS',

  /**
   * Show full property details.
   * Allow new transactions.
   */
  TRANSACT_ALL: 'TRANSACT_ALL',

  /**
   * Offering has completed funding.
   * View only.
   * No new transactions.
   */
  FUNDED: 'FUNDED',

  /**
   * Show full property details.
   * View only.
   * No new transactions.
   * Not a final state, simply to pause transactions.
   */
  PAUSED: 'PAUSED',
} as const;

export type OfferingStatus = (typeof OfferingStatus)[keyof typeof OfferingStatus];

export const ArrivedNCOfferingStatus = {
  /** Default status */
  PENDING: 'PENDING',

  /** Offering has been approved for taking transactions. */
  APPROVED: 'APPROVED',

  /** Offering has been rejected and can not take transactions. */
  NOT_APPROVED: 'NOT_APPROVED',
} as const;

export type ArrivedNCOfferingStatus = (typeof ArrivedNCOfferingStatus)[keyof typeof ArrivedNCOfferingStatus];

export const OfferingTradeProcessingStatus = {
  DEFAULT: 'DEFAULT',

  /**
   * Process all trades for this offering.
   * This will typically be the status of the "current batch" of offerings e.g. Drop 10 offerings.
   */
  ALL_TRADES: 'ALL_TRADES',
} as const;

export type OfferingTradeProcessingStatus =
  (typeof OfferingTradeProcessingStatus)[keyof typeof OfferingTradeProcessingStatus];

export const OfferingType = {
  EQUITY: 'EQUITY',
  DEBT: 'DEBT',
  HYBRID: 'HYBRID',
  FUND: 'FUND',
} as const;

export type OfferingType = (typeof OfferingType)[keyof typeof OfferingType];

export const SecurityType = {
  REG_A: 'REG_A',
  REG_D: 'REG_D',
} as const;

export type SecurityType = (typeof SecurityType)[keyof typeof SecurityType];

export const AssetType = {
  /**
   * Short Term Rental
   */
  STR: 'STR',

  /**
   * Long Term Rental
   */
  LTR: 'LTR',

  /**
   * Short Term Notes
   */
  STN: 'SHORT_TERM_NOTE',
  PRIVATE_CREDIT: 'PRIVATE_CREDIT',
} as const;

export type AssetType = (typeof AssetType)[keyof typeof AssetType];

export const ValuationSource = {
  HOUSE_CANARY: 'HOUSE_CANARY',
  RED_BELL: 'RED_BELL',
  ZILLOW: 'ZILLOW',
} as const;

export type ValuationSource = (typeof ValuationSource)[keyof typeof ValuationSource];

export interface OfferingPropertyValuationCreateRequest {
  offeringId: OfferingId;
  propertyId: number;
  source: ValuationSource;
  amount?: number;
  minAmount?: number;
  maxAmount?: number;
  date: Date;
  documentUrl?: string;
}

export interface OfferingPropertyValuation extends OfferingPropertyValuationCreateRequest {
  id: number;
}

export interface OfferingPropertyRequest {
  id: number;
  propertyIds: number[];
}

export interface OfferingPostFixedRateInvestmentSchema {
  cid: string;
  fixedRateInvestmentCids: string[];
}

/**
 * An OfferingIdOrShortName can either be the actual numerical id of the offering, or the short name.
 */
export type OfferingIdOrShortName = OfferingId | Offering['shortName'];

export interface OfferingDividend {
  id: number;
  offeringId: OfferingId;
  dividendPerShare: number;
  annualizedDividendPercent: number;
  startDate: Date;
  endDate: Date;
  postedAt: string;
}

export interface Offering {
  id: OfferingId;
  cid: OfferingCid;
  uuid?: string;
  ncId?: string;
  issuer: Issuer;
  status: OfferingStatus;
  ncStatus: ArrivedNCOfferingStatus;
  tradeProcessingStatus: OfferingTradeProcessingStatus;
  seriesCashAccountXRefCid: string | null;
  securityType: SecurityType;
  assetType: AssetType;
  investmentProductType: InvestmentProductType;
  market?: Market;
  type: OfferingType;
  name: string;
  shortName: OfferingShortName;
  llcName?: string;
  description: string;
  stampingText: string;
  targetRaiseAmount: number;
  maxRaiseAmount: number;
  minTransactionAmount: number;
  sharePrice: number;
  sharePriceEstimatedMin?: number;
  sharePriceEstimatedMax?: number;
  totalShares: number;
  /**
   * Total shares currently owned by investors.
   */
  totalSharesOutstanding: number;
  /**
   * Total shares available for investors to purchase.
   *
   * Note that retired and sold shares are *not* available to re-purchase.
   */
  totalSharesAvailable: number;
  /**
   * Total shares purchased by investors.
   */
  totalSharesTransacted: number;
  /**
   * Total shares re-purchased by Arrived.
   */
  totalSharesSold: number;
  lastTransactionDate: string | null;
  maxSharesPerAccount: number | null;
  maxSharesPerAccountPerMonth: number | null;
  investorsCount: number;
  dropId: number | null;
  tags: Tag[];
  hasFlexFinancing: boolean;

  firstSharePriceDate: string | null;
  debtPercent: number;
  debtAmount: number;
  debtInterestPercent: number;
  holdPeriodYearsMin: number;
  holdPeriodYearsMax: number;
  arrivedOneTimeProceeds: number;
  startAt: Date;
  endAt: Date;
  ipoDate: Date | null;

  matureAt: string | null;
  fixedRateInvestments: FixedRateInvestment[] | null;

  qualifiedRaiseAmount?: number;

  closingOfferingAndHoldingCosts: number;
  propertyImprovementsAndCashReserves: number;
  quarterlyAumFeeAmount: number | null;
  // TODO remove dividend calculator specific fields
  vacancyRatePercent: number; // new, used in dividend calculator. should be property model
  maintenancePercent: number; // new, used in dividend calculator. should be property model
  managementFeeAmount: number; // old
  insuranceAmount: number; // new, used in dividend calculator. not yet part of backend offering model, should be property model?
  propertyTaxPercent: number; // new, used in dividend calculator. should be property model
  leasingIncentivePercent: number; // new, used in dividend calculator. should be property model or constant?
  propertyManagementPercent: number; // new, used in dividend calculator. should be property model or constant?
  projectedFirstDividendDate: string | null;
  projectedFirstDividendAmount: string | null;
  projectedAnnualDividendYield: number | null;

  latestValuation: OfferingPropertyValuation | null;

  // dividends
  latestDividend: OfferingDividend | null;

  // derived values
  totalPurchasePrice: number;
  /**
   * The % of the Offering that has been funded including debt.
   */
  fundedPercent: number;

  /**
   * The % of the shares in the Offering that have been purchased.
   */
  fundedPercentNoDebt: number;

  // joined models
  properties: Property[];

  tradeFlow?: 'CASH_ACCOUNT' | 'NORTH_CAPITAL';

  /**
   * Offerings need to stay transactable until new offerings are qualified for that issuer.
   * This boolean will be set to true to allow users with the Auth0 permission abacus:open_for_sec_trade:write
   * to continue to transact on the offering until we qualify new offerings within the respective issuer.
   */
  isOpenForSec?: boolean;
}

export interface OfferingTradesFilters extends GenericFilter {
  offeringId?: OfferingId;
}
