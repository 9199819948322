import { useQuery } from '@tanstack/react-query';

import { CONFIG } from '@arrived/config';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { ArrivedBuilderModels } from '../models';
import { BuilderInvestGroup } from '../models/navItems';
import { FetchEntriesParams, fetchEntries } from '../sdk';

import { builderInvestNavGroupsQueryKeyFun } from './keys';

export const fetchBuilderInvestNavGroups = (params?: FetchEntriesParams) =>
  fetchEntries<BuilderInvestGroup>({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.INVEST_NAV_GROUPS,
    enrich: true,
    ...params,
  });

export const useGetBuilderInvestNavGroupsQuery = (
  params?: FetchEntriesParams,
  options?: UseAwaitedQueryOptions<typeof fetchBuilderInvestNavGroups>,
) =>
  useQuery({
    queryKey: builderInvestNavGroupsQueryKeyFun({ ...params }),
    queryFn: () => fetchBuilderInvestNavGroups(params),
    ...options,
  });
