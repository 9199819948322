import { QueryClient } from '@tanstack/react-query';

import {
  getCurrentUser,
  getPrimaryAccount,
  getRewardsAccounts,
  getUserAccount,
  getUserById,
  postRewardsAccount,
} from '@arrived/api_v2';
import { Account, Rewards, RewardsException, User } from '@arrived/models';

import { accountsKeyFn } from '../../accounts';
import { accountsPrimaryKeyFn, currentUserKeyFn, userKeyFn } from '../../users';
import { rewardsAccountsKeyFn } from '../rewards.keys';

export const getOrCreateRewardsAccountId = async (queryClient: QueryClient, userInfo?: Rewards.RewardsUserInfo) => {
  let userId = userInfo?.userId;
  let accountId = userInfo?.accountId;
  let rewardsAccountUuid;

  if (userInfo) {
    // If the accountId exists, continue with that
    if (userInfo.accountId) {
      const specifiedAccount = await queryClient.fetchQuery<Account>({
        queryKey: accountsKeyFn(userInfo.accountId),
        queryFn: () => getUserAccount(userInfo.accountId!),
      });
      rewardsAccountUuid = specifiedAccount.rewardsAccountUuid;
      accountId = specifiedAccount.id;

      // If there is no accountId, try to get it from the current user's primary account
    } else if (userInfo.userId) {
      const specifiedUser = await queryClient.fetchQuery<User>({
        queryKey: userKeyFn(userInfo.userId),
        queryFn: () => getUserById(userInfo.userId!),
      });
      userId = specifiedUser.id;

      const primarySpecifiedAccount = await queryClient.fetchQuery<Account>({
        queryKey: accountsPrimaryKeyFn(userInfo.userId),
        queryFn: () => getPrimaryAccount(userInfo.userId),
      });

      rewardsAccountUuid = primarySpecifiedAccount.rewardsAccountUuid;
      accountId = primarySpecifiedAccount.id;

      // If neither exists, throw an error
    } else {
      throw new RewardsException(Rewards.REWARDS_CLIENT_ERRORS.NO_ACCOUNT, 'No account found');
    }

    // Fetch the primary account from the query client and check for a rewardsAccountUuid
  } else {
    const currentUser = await queryClient.fetchQuery<User>({
      queryKey: currentUserKeyFn(),
      queryFn: () => getCurrentUser(),
    });
    const primaryAccount = await queryClient.fetchQuery<Account>({
      queryKey: accountsPrimaryKeyFn(currentUser.id),
      queryFn: () => getPrimaryAccount(currentUser.id),
    });

    rewardsAccountUuid = primaryAccount.rewardsAccountUuid;
    accountId = primaryAccount.id;
  }

  // If rewardsAccountUuid does not exist, search for one in the rewards service
  const rewardsAccountFilters: Rewards.AccountFilters = { brokerageAccountId: accountId.toString() };
  if (!rewardsAccountUuid) {
    const rewardsAccountSearch = await queryClient.fetchQuery<Rewards.AccountSearchResult>({
      queryKey: rewardsAccountsKeyFn(rewardsAccountFilters),
      queryFn: () => getRewardsAccounts(rewardsAccountFilters),
    });

    if (rewardsAccountSearch?.accounts?.[0]?.uuid) {
      rewardsAccountUuid = rewardsAccountSearch.accounts[0].uuid;
    }
  }

  // If no rewardsAccountUuid is found, create one
  if (!rewardsAccountUuid) {
    try {
      const newRewardsAccount = await postRewardsAccount({ brokerageAccountId: accountId });
      rewardsAccountUuid = newRewardsAccount.uuid;

      if (newRewardsAccount) {
        await Promise.all(
          [
            queryClient.invalidateQueries({ queryKey: rewardsAccountsKeyFn(rewardsAccountFilters) }),
            userId ? queryClient.invalidateQueries({ queryKey: accountsPrimaryKeyFn(userId) }) : undefined,
            queryClient.invalidateQueries({ queryKey: accountsKeyFn(accountId) }),
          ].filter(Boolean),
        );
      }
    } catch (error) {
      throw new RewardsException(
        Rewards.REWARDS_CLIENT_ERRORS.NO_ACCOUNT,
        'No account found or account failed to create.',
      );
    }
  }

  return rewardsAccountUuid;
};
