import { QueryKey } from '@tanstack/react-query';

import { AccountId } from '@arrived/models';

import { accountsKeyFn } from '../accounts.keys';

export const regDStatusKey = 'regDStatus';

export const regDStatusKeyFn = (accountId: AccountId): QueryKey =>
  (accountsKeyFn(accountId) as readonly unknown[]).concat([regDStatusKey]);
