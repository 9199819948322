import { Issuer, IssuerCreateRequest, IssuerUpdateRequest } from '@arrived/models';

import { api, publicApi } from './api';
import { createQuery } from './create-query';

export const getIssuers = () =>
  createQuery<Issuer[]>({
    apiInstance: publicApi,
    method: 'get',
    url: '/issuers',
  });

export const getIssuer = (id: number) =>
  createQuery<Issuer>({
    apiInstance: publicApi,
    method: 'get',
    url: `/issuers/${id}`,
  });

export const postIssuer = (request: IssuerCreateRequest) =>
  createQuery<Issuer>({
    apiInstance: api,
    method: 'post',
    url: '/issuers',
    requestData: request,
  });

export const patchIssuer = (request: IssuerUpdateRequest) =>
  createQuery<Issuer>({
    apiInstance: api,
    method: 'patch',
    url: `/issuers/${request.id}`,
    requestData: request,
  });

export const deleteIssuer = (id: number) =>
  createQuery<Issuer>({
    apiInstance: api,
    method: 'delete',
    url: `/issuers/${id}`,
  });
