import { useQuery } from '@tanstack/react-query';

import { getVideoMetadataByMarketId } from '@arrived/api_v2';
import { MarketId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { marketVideoMetadataKey } from './assetMetadata.keys';

export function useGetVideoMetadataByMarketId(
  marketId?: MarketId | null,
  options?: UseAwaitedQueryOptions<typeof getVideoMetadataByMarketId>,
) {
  return useQuery({
    queryKey: marketVideoMetadataKey(marketId!),
    queryFn: () => getVideoMetadataByMarketId(marketId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && marketId != null,
  });
}
