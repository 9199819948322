import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postUploadUserDocument } from '@arrived/api_v2';

import { userDocumentsKeyFn } from './userDocuments.keys';

export function usePostUserDocumentMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postUploadUserDocument,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: userDocumentsKeyFn(data.userId) });
    },
  });
}
