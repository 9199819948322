import { FormattedMessage } from 'react-intl';

import { ROUTES } from '@arrived/common';

import { NavItemProps } from '../../navigation.types';

const investLabel = <FormattedMessage id="top-nav-invest-label" defaultMessage="Invest" />;
export const investNavItem: NavItemProps = {
  text: investLabel,
  path: ROUTES.properties.base,
};

export const investNavItemPublic: NavItemProps = {
  text: investLabel,
  publicPath: ROUTES.public.properties.base,
};
