import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function AppreciationIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <Path d="M2.5 23V20H9V23" stroke={color} strokeWidth={1.5} />
      <Path d="M9 23V16H15.5V23" stroke={color} strokeWidth={1.5} />
      <Path d="M15.5 23V12H21.5V23" stroke={color} strokeWidth={1.5} />
      <Path d="M21.8208 3.3999L2.00012 14.8434" stroke={color} strokeWidth={1.5} />
      <Path d="M16.991 2.10583L21.8206 3.39994L20.5265 8.22956" stroke={color} strokeWidth={1.5} />
    </Svg>
  );
}

export const AppreciationIcon = memo<IconProps>(withIconTheme(AppreciationIconInternal));
