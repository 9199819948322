import { createContext } from 'react';
import { NativeModules } from 'react-native';

export async function setupNativeRudderClient() {
  // ! If you are getting pod install errors and can't seem to fix it, check `withPlaidIOS.ts`
  // ! and make sure the version matches the `Rudder` version here
  // ! https://github.com/rudderlabs/rudder-sdk-react-native/blob/f1ebdb2d609a20afe8ae03e39587f3d6056cf654/libs/sdk/RNRudderSdk.podspec#L23
  if (!('RNRudderSdkModule' in NativeModules)) {
    console.warn('RudderStack Native SDK not found');
    return null;
  }

  try {
    const { default: rudderClient } = await import('@rudderstack/rudder-sdk-react-native');
    return rudderClient;
  } catch (e) {
    console.error('Error importing RudderStack Native SDK', e);
    throw e; // Rethrowing the error to be handled in the hook
  }
}

export type RudderClient = Awaited<ReturnType<typeof setupNativeRudderClient>>;

export interface NativeRudderClientContextType {
  rudderClient: RudderClient | null;
}

export const NativeRudderClientContext = createContext<NativeRudderClientContextType>({ rudderClient: null });
