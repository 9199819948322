import { Box } from '@mui/material';

import { ArrivedLogo, ArrivedLogoProps } from '../ArrivedLogo';

export const NavigationArrivedLogo = (props: Omit<ArrivedLogoProps, 'iconOnly'>) => (
  <>
    <Box display={{ md: 'none' }}>
      <ArrivedLogo iconOnly {...props} />
    </Box>
    <Box display={{ xs: 'none', md: 'initial' }}>
      <ArrivedLogo {...props} />
    </Box>
  </>
);
