import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

import { postRewardsRedemptionPreview } from '@arrived/api_v2';
import { Rewards, RewardsException } from '@arrived/models';

import { PromotionUserInfo, getPromotionRedemptionData } from './utils/getPromotionRedemptionData';

export function usePostPromotionRedemptionPreviewMutation(
  // TODO: This should be refactored a bit, as this query is doing a bit too much.
  options?: UseMutationOptions<
    Rewards.RewardRedemption | undefined,
    DefaultError,
    Omit<Rewards.RewardRedemptionRequest, 'accountUuid' | 'promotionUuid'> & {
      userInfo?: PromotionUserInfo;
    }
  >,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (promotionRedemptionRequest) => {
      const body = await getPromotionRedemptionData(queryClient, promotionRedemptionRequest);
      if (!body.promotionUuid && !body.code && !promotionRedemptionRequest?.voucherUuids?.length) {
        return;
      }

      if (!body.accountUuid) {
        throw new RewardsException(
          Rewards.REWARDS_CLIENT_ERRORS.NO_ACCOUNT,
          'No account found or account failed to create.',
        );
      }

      if (!body.promotionUuid && !promotionRedemptionRequest?.voucherUuids?.length) {
        throw new RewardsException(
          Rewards.REWARDS_CLIENT_ERRORS.INVALID_PROMOTION,
          'Invalid promotion: ' + promotionRedemptionRequest?.code,
        );
      }

      if (body.code && !body.promotionUuid) {
        throw new RewardsException(
          Rewards.REWARDS_CLIENT_ERRORS.INVALID_PROMOTION,
          'Invalid promotion: ' + promotionRedemptionRequest?.code,
        );
      }

      if (body.code === '' && !body.promotionUuid && promotionRedemptionRequest.voucherUuids.length) {
        delete body.promotionUuid;
        body.code = null;
      }

      return postRewardsRedemptionPreview({
        ...body,
        accountUuid: body.accountUuid,
        promotionUuid: body.promotionUuid || null,
        voucherUuids: promotionRedemptionRequest.voucherUuids,
      });
    },
    ...options,
  });
}
