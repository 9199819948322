import { FormattedMessage } from 'react-intl';

import { A } from '@expo/html-elements';

import { BodyText, Stack, StackProps, styled } from '@arrived/bricks';

import { AirBnbIcon } from './AirBnbIcon';
import { ProductHeaderBookingName } from './constants';

const ProductHeaderBookingFrame = styled(Stack, {
  name: ProductHeaderBookingName,

  alignItems: 'center',
  flexDirection: 'row',
  gap: '$2',
});

export type BookingProps = StackProps & { airBnbLink: string };

export const Booking = ProductHeaderBookingFrame.styleable<BookingProps>(({ airBnbLink, ...rest }, ref) => (
  <A href={airBnbLink} rel="noopener noreferrer" target="_blank">
    <ProductHeaderBookingFrame ref={ref} {...rest}>
      <AirBnbIcon />
      <BodyText color="$interactive.primary.rested" textDecorationLine="underline">
        <FormattedMessage id="common.airbnb-listing" />
      </BodyText>
    </ProductHeaderBookingFrame>
  </A>
));
