import { memo } from 'react';

import { Defs, Path, Pattern, Rect, Svg } from 'react-native-svg';

import { ColorTokens } from '@tamagui/core';

import { withIconTheme } from '../icons';

function StripeSvgInternal({ color }: { color?: ColorTokens }) {
  return (
    <Svg style={{ zIndex: 20 }} height="100%" width="100%">
      <Defs>
        <Pattern id="patternBG" patternUnits="userSpaceOnUse" x="0" y="0" width="8" height="8" viewBox="0 0 4 4">
          <Path d="M-1,1 l2,-2M0,4 l4,-4M3,5 l2,-2" stroke={color} strokeWidth={1.25} />
        </Pattern>
      </Defs>
      <Rect width="100%" height="100%" fill="url(#patternBG)" />
    </Svg>
  );
}

export const StripeSvg = memo<{ color?: ColorTokens }>(withIconTheme(StripeSvgInternal));
