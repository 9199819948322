const SUFFIX = 'rem';
const DEFAULT_REM = 16;

export const isRem = (value: string): value is 'rem' => value.endsWith(SUFFIX);

export const normalize = (baseString: string, rem = DEFAULT_REM) => {
  const koefStr = baseString.substring(0, baseString.length - SUFFIX.length);
  const koef = !koefStr.length ? 1 : parseFloat(koefStr);

  if (isNaN(koef)) {
    throw new Error(`Invalid "rem" value: ${baseString}`);
  }

  return rem * koef;
};

const applyTransparency = (col: number, alpha: number, bgCol = 255) => col * alpha + bgCol * (1 - alpha);

export const convertToHexValue = (value: string, amt: number) => {
  const hex = parseInt(value, 16) + amt;
  const stringedHex = Math.max(Math.min(255, hex), 0).toString(16);

  return (stringedHex.length < 2 ? '0' : '') + stringedHex;
};

// Generate 7-color palettes based on our existing colors.
// https://stackoverflow.com/a/62640342
export const colorShade = (originalColor: string, amt: number) => {
  let color = originalColor.replace(/^#/, '');

  if (color.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  const matches = color.match(/.{2}/g);

  if (!matches) {
    throw new Error(`Invalid color: ${originalColor}`);
  }

  const [r, g, b] = matches.map((v) => convertToHexValue(v, amt));

  return `#${r}${g}${b}`;
};

export const hexToRgbA = (hex: string, alpha = 1, applyAlpha?: boolean) => {
  const defaultOutput = 'transparent'; // Could be null or falsy value too

  if (!hex) {
    return defaultOutput;
  }

  const hexMatch = hex.match(/\w\w/g);

  if (!Array.isArray(hexMatch)) {
    return defaultOutput;
  }

  const [r, g, b] = hexMatch.map((x) => parseInt(x, 16));

  if (applyAlpha) {
    const newR = applyTransparency(r, alpha);
    const newG = applyTransparency(g, alpha);
    const newB = applyTransparency(b, alpha);
    return `rgb(${newR},${newG},${newB})`;
  }
  return `rgba(${r},${g},${b},${alpha})`;
};

export interface LinearGradientPropsFill {
  colors: string[];
  start?: number[];
  end?: number[];
  locations: number[];
  useAngle?: boolean;
  angleCenter?: { x: number; y: number };
  angle?: number;
}

export const createWebGradient = ({
  colors,
  angle,
  useAngle,
  locations,
  start = [0, 0],
  end = [1, 1],
}: LinearGradientPropsFill) => {
  let deg = 0;

  if (useAngle) {
    deg = angle ?? 0;
  } else {
    const x = end[0] - start[0];
    const y = end[1] - start[1];
    const rad = Math.atan2(y, x);

    deg = rad * (180 / Math.PI);
  }

  const colorList = colors
    .map((color, index) => {
      const location = locations?.[index];

      let locationStyle = '';

      if (location) {
        locationStyle = ` ${location * 100}%`;
      }

      return `${color}${locationStyle}`;
    })
    .join(',');

  return {
    vertical: `linear-gradient(${deg}deg, ${colorList})`,
    verticalReverse: `linear-gradient(${deg + 180}deg, ${colorList})`,
    horizontal: `linear-gradient(${deg + 90}deg, ${colorList})`,
    horizontalReverse: `linear-gradient(${deg + 270}deg, ${colorList})`,
  } as const;
};

export const createNativeGradient = (gradient: LinearGradientPropsFill) =>
  ({
    vertical: {
      ...gradient,
      start: [0, 0],
      end: [0, 1],
    },

    verticalReverse: {
      ...gradient,
      locations: gradient.locations.reverse(),
      start: [0, 1],
      end: [0, 0],
    },

    horizontal: {
      ...gradient,
      start: [0, 0],
      end: [1, 0],
    },

    horizontalReverse: {
      ...gradient,
      locations: gradient.locations.reverse(),
      start: [1, 0],
      end: [0, 0],
    },
  }) as const;
