export enum AppreciationInputType {
  NATIONAL,
  ZIP_CODE,
}

export interface ZipCodeAppreciationInfo {
  tenYearHistoricalAppreciation?: number;
  twentyYearHistoricalAppreciation?: number;
}

export interface AppreciationInputOption {
  type: AppreciationInputType;
  value: number;
  years: number;
}

export const nationalTenYearAppreciation = 0.08;
export const nationalTwentyYearAppreciation = 0.045;
export const defaultAnnualRentalDividendYield = 0.045;

export const getAppreciationInputOptions = (
  zipCodeAppreciationInfo?: ZipCodeAppreciationInfo,
): AppreciationInputOption[] => {
  const options = [
    {
      years: 10,
      type: AppreciationInputType.NATIONAL,
      value: nationalTenYearAppreciation,
    },
    {
      years: 20,
      type: AppreciationInputType.NATIONAL,
      value: nationalTwentyYearAppreciation,
    },
  ];

  if (zipCodeAppreciationInfo?.tenYearHistoricalAppreciation) {
    options.push({
      years: 10,
      type: AppreciationInputType.ZIP_CODE,
      value: zipCodeAppreciationInfo.tenYearHistoricalAppreciation,
    });
  }

  if (zipCodeAppreciationInfo?.twentyYearHistoricalAppreciation) {
    options.push({
      years: 20,
      type: AppreciationInputType.ZIP_CODE,
      value: zipCodeAppreciationInfo.twentyYearHistoricalAppreciation,
    });
  }

  return options;
};

export const investmentAmountOptions = [100, 1_000, 2_500, 5_000, 10_000];
