import { PlannedInvestmentAmount, PlannedInvestmentAmountValues } from '@arrived/models';

const FALLBACK_PAUM_THRESHOLD_AMOUNT = 50000;

/**
 * Boolean check to confirm if the investor's provided net worth is greater than or equal to the
 * minimum threshold provided. Net worth is used to calculate an approximate potential investment,
 * or PAUM, amount.
 *
 * Default threshold is $50,000.
 */
export const isNetWorthGreaterThanOrEqualToPAUMMinimum = (
  providedNetWorth: PlannedInvestmentAmount | undefined,
  netWorthMinimum: number = FALLBACK_PAUM_THRESHOLD_AMOUNT,
): boolean => {
  if (!providedNetWorth) {
    return false;
  }

  return PlannedInvestmentAmountValues[providedNetWorth][0] >= netWorthMinimum;
};
