import { Constants } from '../constants';

interface NextImageKitLoader {
  src: string;
  width: number;
  quality?: number;
}

/**
 * @deprecated Use `@arrived/imagekit` instead.
 */
export const nextImageKitLoader = ({ src, width, quality }: NextImageKitLoader): string => {
  const newImgUrl = src.replace(Constants.awsCdnUrl, Constants.imageCdnUrl);
  const parameters = ['dpr-auto', 'pr-true', 'di-logo_icon_CwvvbRzw9.svg', 'c-maintain_ratio'];

  if (width != null) {
    parameters.push(`w-${width}`);
  }

  if (quality && quality > 0 && quality <= 100) {
    parameters.push(`q-${quality}`);
  }

  const queryParams = parameters.length > 0 ? `?tr=${parameters.filter(Boolean).join(',')}` : '';

  return `${newImgUrl}${queryParams}`;
};
