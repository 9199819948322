import { useContext, useEffect } from 'react';

import { IntercomDisplayContext } from './IntercomDisplayContextProvider';

/**
 * Hides the intercom launcher on a specific page.
 */
export function useHideIntercomLauncher(hide = true) {
  const { hideLauncherOnPage } = useContext(IntercomDisplayContext);

  useEffect(() => {
    if (hide) {
      hideLauncherOnPage(true);
    }
    return () => hideLauncherOnPage(false);
  }, [hide, hideLauncherOnPage]);
}
