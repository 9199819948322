import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { MAX_STALE_TIME, useGetPrimaryAccountQuery } from '@arrived/queries';

import { Transaction } from '../types/cash-accounts';

import { useTransactionSources } from './useTransactionSources';

/**
 * Resolve the "source" and "destination" display names based on the Transaction category.
 */
export const useGetDisplayNameForCashAccountTransfer = (transaction: Transaction) => {
  const intl = useIntl();
  const { data: accountData, isLoading } = useGetPrimaryAccountQuery({ staleTime: MAX_STALE_TIME });
  const { source, destination } = useTransactionSources(transaction);

  const transactionSourceDisplayName = useMemo(() => {
    switch (transaction.transferCategory) {
      case 'WITHDRAW':
        return intl.formatMessage({ id: 'cashAccounts.transactionDetails.label.transactionSource.wallet' });
      case 'DIVIDEND_PAYMENT':
      case 'CORRECTION':
      case 'RETURN':
      case 'SELL_SHARES':
      case 'SALE_PAYMENT':
      case 'SALE_REDEMPTION':
        return 'Arrived';
      case 'BUY_SHARES':
      case 'DEPOSIT':
        // TODO: Should be pulled from the transaction request and not the primary account
        return source.name;
    }
  }, [transaction.transferCategory, accountData?.externalAccount, source]);

  const transactionDestinationDisplayName = useMemo(() => {
    switch (transaction.transferCategory) {
      case 'DEPOSIT':
      case 'DIVIDEND_PAYMENT':
      case 'CORRECTION':
      case 'RETURN':
      case 'SELL_SHARES':
      case 'SALE_PAYMENT':
      case 'SALE_REDEMPTION':
        return intl.formatMessage({ id: 'cashAccounts.transactionDetails.label.transactionSource.wallet' });
      case 'BUY_SHARES':
        return 'Arrived';
      case 'WITHDRAW':
        // TODO: Should be pulled from the transaction request and not the primary account
        return destination.name;
    }
  }, [transaction.transferCategory, accountData?.externalAccount, destination]);

  return {
    isLoading,
    transactionSourceDisplayName,
    transactionDestinationDisplayName,
  };
};
