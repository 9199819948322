import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { assumedDebtInterestPercent, assumedDebtPercent } from '@arrived/common';
import { Offering } from '@arrived/models';

export interface LeverageTooltipProps {
  offering: Offering;
}

export const LeverageTooltip = ({
  offering: { debtPercent, debtInterestPercent, hasFlexFinancing },
}: LeverageTooltipProps) => {
  const intl = useIntl();

  const derivedDebtPercent = useMemo(() => (debtPercent > 0 ? debtPercent / 100 : assumedDebtPercent), [debtPercent]);
  const derivedDebtInterestPercent = useMemo(
    () => (debtPercent > 0 ? debtInterestPercent / 100 : assumedDebtInterestPercent),
    [debtPercent, debtInterestPercent],
  );
  const suffix = useMemo(() => {
    if (debtPercent > 0) {
      return 'levered';
    } else if (hasFlexFinancing) {
      return 'refi';
    } else {
      return 'unlevered';
    }
  }, [debtPercent, hasFlexFinancing]);

  return (
    <>
      {intl.formatMessage(
        { id: `property-details.calculator.leverage-tooltip.${suffix}` },
        {
          leverage: intl.formatNumber(derivedDebtPercent, {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
          interestRate: intl.formatNumber(derivedDebtInterestPercent, {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
        },
      )}
    </>
  );
};
