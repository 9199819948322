import { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import _uniq from 'lodash/uniq';

import {
  AnalyticsContext,
  EVENTS,
  getHistoricalReturnRange,
  useIdentify,
  useTrack,
  useUserTraits,
} from '@arrived/analytics';
import { ArrowLeftIcon, Button, LinearGradient, Stack, StackProps, tokens } from '@arrived/bricks';
import { SocialSharePopper, SocialSharePopperProps } from '@arrived/bricks-common';
import {
  ROUTES,
  UserOfferingTransactState,
  calculatorAppreciationToEquity,
  getProjectedAppreciationRange,
  getPropertyPrimaryPhoto,
} from '@arrived/common';
import { CONFIG } from '@arrived/config';
import { NavigationBarContext } from '@arrived/contexts';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ProductDetails, ProductGallery, ProductWidget } from '@arrived/features-product-details';
import { useOfferingDisplayState, useUserOfferingTransactState } from '@arrived/hooks';
import { Offering, getLeaseStatusMessageDisplayText } from '@arrived/models';
import { useGetZillowZipCodeAppreciationQuery } from '@arrived/queries';

import { Table } from '../../Table';

export type PropertyDetailsProps = StackProps & {
  offering: Offering;
  footer?: ReactNode;
  topOffset?: number;
  handleOnBackButtonClick?: () => void;
  isVerified?: boolean;
  productWidgetButtonOnPress: (userOfferingTransactState: UserOfferingTransactState) => void;
};

const DETAILS_GAP = tokens.space[6].val;

export const PropertyDetails = ({
  offering,
  footer,
  topOffset,
  handleOnBackButtonClick,
  isVerified = false,
  productWidgetButtonOnPress,
  ...rest
}: PropertyDetailsProps) => {
  const track = useTrack();
  const identify = useIdentify();
  const traits = useUserTraits();
  const intl = useIntl();

  const { offset, transition } = useContext(NavigationBarContext);

  const { isLoaded: isAnalyticsLoaded } = useContext(AnalyticsContext);

  const zipCodeAppreciationState = useGetZillowZipCodeAppreciationQuery(offering.properties?.[0]?.address?.zipCode);

  const [projectedAppreciationLower, projectedAppreciationUpper] = useMemo(
    () => getProjectedAppreciationRange(zipCodeAppreciationState.data),
    [zipCodeAppreciationState.data],
  );

  const propertyStatus = useOfferingDisplayState(offering.id);

  useEffect(() => {
    if (isAnalyticsLoaded) {
      traits().then((traits) => {
        identify({
          viewed_offering_details_ids: _uniq(
            [offering.id].concat((traits?.['viewed_offering_details_ids'] ?? []) as number[]),
          ),
        });
      });
    }
  }, [isAnalyticsLoaded]);

  const Footer = useCallback((props: StackProps) => <Stack {...props}>{footer}</Stack>, [footer]);

  useEffect(() => {
    const property = offering.properties.length ? offering.properties[0] : undefined;

    track(EVENTS.PROPERTY_VIEWED, {
      asset_type: offering.assetType,
      property_status: propertyStatus?.toLowerCase(),
      beds: property?.bedrooms,
      baths: (property?.fullBathrooms ?? 0) + (property?.halfBathrooms ?? 0),
      sq_ft: property?.squareFootage,
      year_built: property?.yearBuilt,
      number_of_investors: offering.investorsCount,
      property_purchase_price: offering.totalPurchasePrice,
      monthly_rent: property?.rent,
      offering_id: offering.id,
      offering_name: offering.name.toLowerCase(),
      perc_purchased_status: offering.fundedPercent,
      property_rental_status: getLeaseStatusMessageDisplayText(property?.leaseStatus, intl),
      first_dividend_date: offering.projectedFirstDividendDate ?? undefined,
      first_dividend_yield: offering.projectedAnnualDividendYield ?? undefined,
      ...getHistoricalReturnRange(offering, {
        lower: calculatorAppreciationToEquity(projectedAppreciationLower ?? 0, offering),
        upper: calculatorAppreciationToEquity(projectedAppreciationUpper ?? 0, offering),
      }),
      property_value_growth: projectedAppreciationLower ?? undefined,
    });
  }, [offering]);

  const userOfferingTransactState = useUserOfferingTransactState({ offering, isVerified });

  const stickyTopOffset = useMemo(() => (topOffset ?? 0) + offset + DETAILS_GAP, [offset, topOffset]);

  const title = intl.formatMessage({ id: 'social-share-buttons-title', defaultMessage: 'Arrived Homes' });
  const text = intl.formatMessage({
    id: 'social-share-buttons-text',
    defaultMessage: 'Buy shares of rental properties, earn passive income, and let Arrived take care of the rest.',
  });
  const mainHashtag = intl.formatMessage({
    id: 'social-share-buttons-main-hashtag',
    defaultMessage: 'ArrivedHomes',
  });
  const shareImage = getPropertyPrimaryPhoto(offering.properties[0]);

  const shareConfig = useMemo<SocialSharePopperProps['config']>(() => {
    return [title, text, mainHashtag, shareImage];
  }, [title, text, mainHashtag, shareImage]);

  return (
    <>
      <Stack m="auto" mb="$4" width="100%" maxWidth={1280} minWidth={350} position="relative" role="main" {...rest}>
        <Stack display="flex" py="$2" px="$3" width="100%" alignItems="center" row>
          {handleOnBackButtonClick && (
            <Button Icon={ArrowLeftIcon} iconPosition="before" variant="ghost" onPress={handleOnBackButtonClick}>
              <FormattedMessage id="common.back" />
            </Button>
          )}
          <SocialSharePopper
            ml="auto"
            config={shareConfig}
            url={`${CONFIG.socialShareUrlRoot}${generatePath(ROUTES.properties.propertyDetails, {
              idOrShortName: offering.shortName,
            })}`}
          />
        </Stack>
        <Stack $gtSm={{ px: '$2' }}>
          <ProductGallery
            width="100%"
            aspectRatio={1.5}
            $gtXxs={{ aspectRatio: 2.5 }}
            $gtMd={{ aspectRatio: 2.7 }}
            offering={offering}
          />
        </Stack>
        <Stack
          width="100%"
          pt="$6"
          $gtSm={{
            row: true,
            maxWidth: 1120,
            gap: '$6',
            mx: 'auto',
            alignItems: 'flex-start',
            px: '$4',
          }}
          $gtMd={{ gap: '$10' }}
          position="relative"
        >
          <ProductDetails
            gap="$6"
            offeringIdOrShortName={offering.id}
            $gtSm={{ flexBasis: 700, flexShrink: 1 }}
            HeaderStackProps={{
              gap: '$6',
              px: '$4',
              $gtSm: { px: 0 },
            }}
            ControllerStackProps={{
              bg: '$onSurface.neutral.defaultInverted',
              top: stickyTopOffset,
              zIndex: '$dropdown',
              px: '$4',
              $gtSm: { px: 0 },
              style: { position: 'sticky', transition },
              children: (
                // TODO Use a mask here to make the gradient look smoother, when we get a minute :(
                <>
                  <Stack
                    position="absolute"
                    h={DETAILS_GAP}
                    top={-DETAILS_GAP}
                    bg="$onSurface.neutral.defaultInverted"
                    right={0}
                    left={0}
                  />
                  <Stack position="absolute" h={DETAILS_GAP} bottom={-DETAILS_GAP} right={0} left={0}>
                    <LinearGradient
                      start={[0.5, 0]}
                      end={[0.5, 1]}
                      colors={['$onSurface.neutral.defaultInverted', '$transparent']}
                      fullscreen
                    />
                  </Stack>
                </>
              ),
            }}
            Table={Table}
          />
          <ProductWidget
            offering={offering}
            userOfferingTransactState={userOfferingTransactState}
            flex={1}
            style={{ position: 'sticky' }}
            $gtSm={{ condensed: false, top: (topOffset ?? 0) + offset + 24, bottom: 'unset' }}
            bottom={0}
            condensed
            zIndex="$sticky"
          >
            <ProductWidget.ProductWidgetButton
              onPress={productWidgetButtonOnPress}
              userOfferingTransactState={userOfferingTransactState}
            />
          </ProductWidget>
        </Stack>
      </Stack>
      {footer && <Footer className="property-details-footer" width="100%" $gtSm={{ pt: '$6' }} />}
    </>
  );
};
