import { differenceInWeeks } from 'date-fns';

import { Offering } from '@arrived/models';

/**
 * Returns true if the provided Offering has new properties. A "new" Property is defined to be a
 * Property whose `goLiveDate` in the fund is 2 weeks ago or less.
 */
export function getOfferingHasNewProperties(offering: Offering) {
  return offering.properties.find(
    (property) => property.goLiveDate && Math.abs(differenceInWeeks(new Date(), new Date(property.goLiveDate))) <= 2,
  );
}
