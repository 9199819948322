import { useCallback, useMemo } from 'react';

import { useLocalStorage } from 'usehooks-ts';

export const useIsBannerDismissed = (bannerId?: string) => {
  const [isBannerDismissed, setIsBannerDismissed] = useLocalStorage<Partial<Record<string, boolean>>>(`banners`, {});

  const bannerDismissed = useMemo(
    () => (bannerId ? isBannerDismissed[bannerId] ?? false : false),
    [isBannerDismissed, bannerId],
  );
  const setBannerDismissed = useCallback(
    (bannerId: string, dismissed: boolean) =>
      bannerId ? setIsBannerDismissed({ ...isBannerDismissed, [bannerId]: dismissed }) : {},
    [bannerId, setIsBannerDismissed],
  );

  return [bannerDismissed, setBannerDismissed] as const;
};
