import { useMemo } from 'react';

import { UseCashAccountOptions, useCashAccount } from '../queries/cash-accounts';

import { useFormatCurrency } from './useFormatCurrency';

const parseNaN = (val: number) => (isNaN(val) ? undefined : val);

/**
 * Wrapper around the `useCashAccount` query that returns raw + formatted Wallet balances.
 *
 * The query itself is set to refetch on mount & on window focus to ensure that we're avoiding
 * showing the investor an incorrect or out of date Wallet balance as often as possible while
 * they're creating trades and transfers around the app/in multiple tabs/across multiple platforms.
 */
export const useCashAccountBalances = (cashAccountCid?: string | null, options?: UseCashAccountOptions) => {
  const cashAccountState = useCashAccount(cashAccountCid, {
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
    ...options,
  });
  const [totalBalance, availableBalance, pendingCreditBalance, pendingDebitBalance, onHoldBalance] = [
    parseFloat(cashAccountState.data?.projections?.currentBalance ?? ''),
    parseFloat(cashAccountState.data?.projections?.availableBalance ?? ''),
    parseFloat(cashAccountState.data?.projections?.pendingCredit ?? ''),
    parseFloat(cashAccountState.data?.projections?.pendingDebit ?? ''),
    parseFloat(cashAccountState.data?.projections?.onHoldCredit ?? ''),
  ];

  const formattedTotalAmount = useFormatCurrency(totalBalance);
  const formattedAvailableAmount = useFormatCurrency(availableBalance);
  const formattedPendingInboundAmount = useFormatCurrency(onHoldBalance + pendingCreditBalance);
  const formattedPendingOutboundAmount = useFormatCurrency(pendingDebitBalance);
  const formattedOnHoldAmount = useFormatCurrency(onHoldBalance);

  return useMemo(
    () => ({
      cashAccountState,
      total: {
        raw: parseNaN(totalBalance),
        formatted: formattedTotalAmount,
      },
      available: {
        raw: parseNaN(availableBalance),
        formatted: formattedAvailableAmount,
      },
      /**
       * Represents both pending funds that cash account service has not finished processing, and
       * funds that are still within the Modern Treasury hold period we've enabled.
       *
       * In total, this tends to represent 3-5 business days' worth of user-deposited funds, plus
       * whatever we've transfered to them that's still processing (dividends, corrections, etc.).
       */
      pendingInbound: {
        raw: parseNaN(onHoldBalance + pendingCreditBalance),
        formatted: formattedPendingInboundAmount,
      },
      pendingOutbound: {
        raw: parseNaN(pendingDebitBalance),
        formatted: formattedPendingOutboundAmount,
      },
      onHold: {
        raw: parseNaN(onHoldBalance),
        formatted: formattedOnHoldAmount,
      },
    }),
    [cashAccountState.data],
  );
};
