import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchUser } from '@arrived/api_v2';

import { adminUserKeyFn } from './users.keys';

export function useAdminPatchUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: adminUserKeyFn(data.id) });
    },
  });
}
