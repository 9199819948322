import { memo, useMemo } from 'react';

import { PathFrame, SvgFrame } from './Frames';
import { IndicatorProps } from './IndicatorProps';
import { getLoadingColors, withIndicatorTheme } from './utils';

const IndicatorFrame = ({ variant = 'default', size, ...props }: IndicatorProps) => {
  const colors = useMemo(() => getLoadingColors(variant), [variant]);

  return (
    <SvgFrame width={size} height={size} {...props}>
      <PathFrame
        d="M2.00002 27.0331V17.1058C1.99815 16.7617 2.13334 16.431 2.37572 16.1867L15.0813 3.38279C15.5876 2.87255 16.4126 2.87238 16.9191 3.38242L29.6239 16.1753C29.8665 16.4195 30.0019 16.7504 30 17.0947V27.0642C29.9961 27.7772 29.4166 28.3529 28.7036 28.352L3.29324 28.3209C2.5815 28.32 2.00389 27.7448 2.00002 27.0331Z"
        stroke={colors[0]}
        strokeOpacity={variant !== 'colored' ? 0.25 : 1}
        strokeWidth="2"
        strokeLinejoin="round"
        strokeDasharray="0, 0, 0, 0, 0, 89.51141357421875"
      >
        <animate
          attributeName="stroke-dasharray"
          repeatCount="indefinite"
          dur="2.5s"
          values="0, 0, 0, 0, 0, 89.51141357421875; 0, 0, 0, 44.755706787109375, 44.755706787109375, 0; 0, 0, 22.377853393554688, 67.13356018066406, 0, 0; 0, 44.755706787109375, 0, 44.755706787109375, 0, 0"
          keyTimes="0; 0.5; 0.75; 1"
          begin="0.36716853915878506s"
        />
      </PathFrame>
      <PathFrame
        d="M2.00002 27.0331V17.1058C1.99815 16.7617 2.13334 16.431 2.37572 16.1867L15.0813 3.38279C15.5876 2.87255 16.4126 2.87238 16.9191 3.38242L29.6239 16.1753C29.8665 16.4195 30.0019 16.7504 30 17.0947V27.0642C29.9961 27.7772 29.4166 28.3529 28.7036 28.352L3.29324 28.3209C2.5815 28.32 2.00389 27.7448 2.00002 27.0331Z"
        stroke={colors[1]}
        strokeOpacity={variant !== 'colored' ? 0.5 : 1}
        strokeWidth="2"
        strokeLinejoin="round"
        strokeDasharray="0, 0, 0, 0, 0, 89.51141357421875"
      >
        <animate
          attributeName="stroke-dasharray"
          repeatCount="indefinite"
          dur="2.5s"
          values="0, 0, 0, 0, 0, 89.51141357421875; 0, 0, 0, 44.755706787109375, 44.755706787109375,0; 0, 0, 22.377853393554688, 67.13356018066406, 0, 0; 0, 44.755706787109375, 0, 44.755706787109375, 0, 0"
          keyTimes="0; 0.5; 0.75; 1"
          begin="0.23710986110644927s"
        />
      </PathFrame>
      <PathFrame
        d="M2.00002 27.0331V17.1058C1.99815 16.7617 2.13334 16.431 2.37572 16.1867L15.0813 3.38279C15.5876 2.87255 16.4126 2.87238 16.9191 3.38242L29.6239 16.1753C29.8665 16.4195 30.0019 16.7504 30 17.0947V27.0642C29.9961 27.7772 29.4166 28.3529 28.7036 28.352L3.29324 28.3209C2.5815 28.32 2.00389 27.7448 2.00002 27.0331Z"
        stroke={colors[2]}
        strokeOpacity={variant !== 'colored' ? 0.75 : 1}
        strokeWidth="2"
        strokeLinejoin="round"
        strokeDasharray="0, 0, 0, 0, 0, 89.51141357421875"
      >
        <animate
          attributeName="stroke-dasharray"
          repeatCount="indefinite"
          dur="2.5s"
          values="0, 0, 0, 0, 0, 89.51141357421875; 0, 0, 0, 44.755706787109375, 44.755706787109375, 0; 0, 0, 22.377853393554688, 67.13356018066406, 0, 0; 0, 44.755706787109375, 0, 44.755706787109375, 0, 0"
          keyTimes="0; 0.5; 0.75; 1"
          begin="1.0723594763201885s"
        />
      </PathFrame>
      <PathFrame
        d="M2.00002 27.0331V17.1058C1.99815 16.7617 2.13334 16.431 2.37572 16.1867L15.0813 3.38279C15.5876 2.87255 16.4126 2.87238 16.9191 3.38242L29.6239 16.1753C29.8665 16.4195 30.0019 16.7504 30 17.0947V27.0642C29.9961 27.7772 29.4166 28.3529 28.7036 28.352L3.29324 28.3209C2.5815 28.32 2.00389 27.7448 2.00002 27.0331Z"
        stroke={colors[3]}
        strokeWidth="2"
        strokeLinejoin="round"
        strokeDasharray="0, 0, 0, 0, 0, 89.51141357421875"
      >
        <animate
          attributeName="stroke-dasharray"
          repeatCount="indefinite"
          dur="2.5s"
          values="0, 0, 0, 0, 0, 89.51141357421875; 0, 0, 0, 44.755706787109375, 44.755706787109375, 0; 0, 0, 22.377853393554688, 67.13356018066406, 0, 0; 0, 44.755706787109375, 0, 44.755706787109375, 0, 0"
          keyTimes="0; 0.5; 0.75; 1"
          begin="0.04500342658257106s"
        />
      </PathFrame>
    </SvgFrame>
  );
};

export const Indicator = memo(withIndicatorTheme(IndicatorFrame));
