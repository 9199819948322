import { BuilderBannerIcons } from '../models';

export const getBannerIconSize = (icon?: BuilderBannerIcons) => {
  switch (icon) {
    case BuilderBannerIcons.BELL:
      return { height: 36, width: 36 };
    case BuilderBannerIcons.DIVIDEND_PAYMENT:
      return { height: 60, width: 60 };
    case BuilderBannerIcons.MOVE_MONEY_SUCCESS:
      return { height: 60, width: 60 };
    case BuilderBannerIcons.TRADE_PURCHASE_SUCCESS:
      return { height: 60, width: 60 };
    case BuilderBannerIcons.KORIN:
      return { height: 60, width: 60 };
    default:
      return undefined;
  }
};
