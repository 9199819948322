/**
 * States that a user could be in for Brokerage Account setup and/or KYC/AML verification.
 */

export const BrokerageAccountSetupState = {
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  KYC_NOT_RUN: 'KYC_NOT_RUN',
  KYC_PENDING_SYNC: 'KYC_PENDING_SYNC',
  UPLOAD_DOCS: 'UPLOAD_DOCS',
  UPDATE_SSN: 'UPDATE_SSN',
  PENDING_COMPLIANCE_VERIFICATION: 'PENDING_COMPLIANCE_VERIFICATION',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
} as const;

export type BrokerageAccountSetupState = (typeof BrokerageAccountSetupState)[keyof typeof BrokerageAccountSetupState];

export const BROKERAGE_ACCOUNT_SETUP_ACTION_STATES = [
  BrokerageAccountSetupState.CREATE_ACCOUNT,
  BrokerageAccountSetupState.KYC_NOT_RUN,
  BrokerageAccountSetupState.UPLOAD_DOCS,
  BrokerageAccountSetupState.UPDATE_SSN,
];

export const BROKERAGE_ACCOUNT_STATE_INDICATOR_STATES_PROFILE = [
  BrokerageAccountSetupState.KYC_PENDING_SYNC,
  BrokerageAccountSetupState.PENDING_COMPLIANCE_VERIFICATION,
  BrokerageAccountSetupState.PENDING_VERIFICATION,
  BrokerageAccountSetupState.REJECTED,
];

export const BROKERAGE_ACCOUNT_STATE_INDICATOR_STATES_DETAILS = [
  ...BROKERAGE_ACCOUNT_STATE_INDICATOR_STATES_PROFILE,
  BrokerageAccountSetupState.COMPLETED,
];
