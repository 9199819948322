export default {
  // Account Settings list
  'account-settings.basic-info.title': 'Basic Info',
  'account-settings.basic-info.description': 'Update and review your personal information',

  'account-settings.investment-account.title': 'Investment Account',
  'account-settings.investment-account.description': 'Update your investment account',

  'account-settings.bank-account.title': 'Linked Bank Account',
  'account-settings.bank-account.description': 'Manage & review your linked bank account',

  'account-settings.notifications.title': 'Notifications',
  'account-settings.notifications.description': 'Manage your notification preferences',

  'account-settings.documents.title': 'Documents',
  'account-settings.documents.description': 'View transaction and tax documents',

  'account-settings.security.title': 'Security',
  'account-settings.security.description-with-biometric-option': 'Password, MFA & {biometricOption} Settings',
  'account-settings.security.description-without-biometric-option': 'Password, MFA & Security Settings',

  'account-settings.about.title': 'About',
  'account-settings.about.description': 'Learn more about how Arrived works',

  'account-settings.accreditation-status.sheet.title': 'Update Accreditation',
  'account-settings.accreditation-status.sheet.header': 'Do these statements still apply?',
  'account-settings.accreditation-status.sheet.description':
    'Your accreditation needs to be updated in order to secure your investment.',
  'account-settings.accreditation-status.sheet.continue': 'Continue',
  'account-settings.accreditation-status.sheet.update': 'Update',
  'account-settings.accreditation-status.statement1': 'My net worth is $1M+ (excluding primary residence)',
  'account-settings.accreditation-status.statement2': 'My individual income was $200k+ for each of the past two years',
  'account-settings.accreditation-status.statement3':
    'My joint household income was $300k+ for each of the past two years',
  'account-settings.accreditation-status.statement4':
    'Are you or anyone in your household associated with a FINRA member?',
  'account-settings.accreditation-status.statement5':
    'Are you or anyone in your household or immediate family a 10% shareholder, officer, or member of the board of directors of a publicly traded company?',
  'account-settings.accreditation-status.finra-institutions-name.label': 'Member Names',
  'account-settings.accreditation-status.finra-institutions-name.helper': 'List member names (separate by commas)',
  'account-settings.accreditation-status.finra-institutions-name.validation.required':
    'Please enter valid member names.',
  'account-settings.accreditation-status.finra-institutions-name.validation.comma-separated':
    'Your institutions seems incomplete. Add another item after the comma.',
  'account-settings.accreditation-status.public-company-tickers.label': 'Company Tickers',
  'account-settings.accreditation-status.public-company-tickers.helper': 'List company tickers (separate by commas)',
  'account-settings.accreditation-status.public-company-tickers.validation.required':
    'Please enter valid company tickers.',
  'account-settings.accreditation-status.public-company-tickers.validation.comma-separated':
    'Your tickers seems incomplete. Add another item after the comma.',
  'account-settings.accreditation-status.unaccredited-confirmation':
    'I confirm that I hold an unaccredited status and agree that I will not invest more than 10% of my net worth or income in Arrived Homes offerings.',
  'account-settings.accreditation-status.unaccredited-confirmation.validation.required':
    'Please confirm that you hold an unaccredited status before saving.',
  'account-settings.accreditation-status.screen.title': 'Accreditation',
  'account-settings.accreditation-status.error': '{error}',
  'account-settings.accreditation-status.success': 'Your accreditation status has been updated.',
  'account-settings.accreditation-status.ineligible-to-invest.title': 'Investment Information',
  'account-settings.accreditation-status.ineligible-to-invest.body': 'You’re ineligible to invest',
  'account-settings.accreditation-status.ineligible-to-invest.description': `At this time, only self-accredited investors are eligible to invest in short-term notes. We appreciate your understanding and invite you to explore other investment opportunities available on our platform.{br}{br}Feel free to reach out if you need assistance or have any questions. We're here to help!`,

  'account-settings.security.biometrics.require-label': 'Require {biometricOption}',
  'account-settings.security.biometrics.fingerprint-label': 'Fingerprint',
  'account-settings.security.biometrics.face-id-label': 'Face ID',
  'account-settings.security.biometrics.touch-id-label': 'Touch ID',
  'account-settings.security.biometrics.face-recognition-label': 'Face Recognition',
  'account-settings.security.biometrics.passcode-label': 'Passcode',
  'account-settings.security.biometrics.iris-label': 'Iris',
  'account-settings.security.biometrics.set-up-label': 'Set Up',
  'account-settings.security.biometrics.enable-label': 'Enable',
  'account-settings.security.biometrics.disable-label': 'Disable',

  'account-settings.security.biometrics.sheet.title': '{biometricOption}',
  'account-settings.security.biometrics.sheet.body-title': 'Enable {biometricOption} for authentication',
  'account-settings.security.biometrics.sheet.body-description':
    'Use {biometricOption} for instant, secure entry to the app. With {biometricOption} turned on, any registered users on this device will have access to your account.',

  'account-settings.support.title': 'Support',
  'account-settings.support.description': 'Get help with your Arrived account!',
} as const;
