import * as yup from 'yup';

import { Rewards } from '@arrived/models';

const REQUIRED_STRING = 'This is required';

const promotionsParentSchema = yup
  .object({
    name: yup.string().trim().required(REQUIRED_STRING),
    description: yup.string().trim().nullable().defined(),
    startAt: yup.string().nullable().defined(),
    endAt: yup.string().nullable().defined(),
    accountRedemptionCountLimit: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .integer()
      .positive()
      .nullable()
      .defined(),
    promotionRedemptionCountLimit: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .integer()
      .positive()
      .nullable()
      .defined(),
    discountPercentage: yup
      .string()
      .nullable()
      .transform((_, value) => {
        return value === '' ? null : value;
      })
      .defined(),
    maxDiscountPerTransactionAmount: yup
      .string()
      .nullable()
      .transform((_, value) => {
        return value === '' ? null : value;
      })
      .defined(),
    enabled: yup.boolean().nullable().defined(),
    requireFirstTimeTrader: yup.boolean().nullable().defined(),
  })
  .defined();

export const PromotionsPostSchema = yup
  .object({
    currency: yup.mixed<Rewards.SupportedCurrency>().required(REQUIRED_STRING),
    codes: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(yup.string().min(3).defined())
      .required(REQUIRED_STRING),
  })
  .concat(promotionsParentSchema);

export const PromotionsPutSchema = yup
  .object({
    uuid: yup.string().required(REQUIRED_STRING),
  })
  .concat(promotionsParentSchema);

export type PromotionsPostSchema = yup.InferType<typeof PromotionsPostSchema>;
export type PromotionsPutSchema = yup.InferType<typeof PromotionsPutSchema>;
