import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function ShareIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <Path stroke={color} strokeWidth={1.5} d="M9 13 21 1M13 1h8v8M18 12.5A8.5 8.5 0 1 1 9.5 4" />
    </Svg>
  );
}

export const ShareIcon = memo<IconProps>(withIconTheme(ShareIconInternal));
