import { FormattedMessage } from 'react-intl';

import { Divider, InfoBox, Row, Stack, UtilityText } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { SFRFundData } from '@arrived/models';

import { ProductDetailContent } from '../../../../../content';

export const Liquidity = ({
  liquidityAndRedemptions: { description, feeList },
}: {
  liquidityAndRedemptions: NonNullable<SFRFundData['liquidityAndRedemptions']>;
}) => (
  <>
    <ProductDetailContent.SubHeader>
      <FormattedMessage id="product-details.liquidity" />
    </ProductDetailContent.SubHeader>
    <Markdown Text={ProductDetailContent.Text}>{description}</Markdown>
    <Stack gap="$2">
      <Stack px="$2" row justifyContent="space-between" alignItems="flex-end" gap="$4">
        <UtilityText token="utility.label.medium">
          <FormattedMessage id="product-details.liquidity-and-fees.table-header" />
        </UtilityText>
        <Stack row columnGap="$1" flexWrap="wrap" flex={1} justifyContent="flex-end" gap="$1" alignItems="center">
          <UtilityText token="utility.label.medium">
            <FormattedMessage id="product-details.liquidity-and-fees.table-header-value" />
          </UtilityText>
          <UtilityText token="utility.label.xsmall" fontWeight="normal">
            (<FormattedMessage id="product-details.liquidity-and-fees.table-header-value.description" />)
          </UtilityText>
        </Stack>
      </Stack>
      <Divider solid alt />
      {feeList.length && (
        <Stack gap="$2">
          {feeList.map(({ label, value }, idx) => (
            <InfoBox p={0} key={idx}>
              <Row>
                <Row.Label>{label}</Row.Label>
                <Row.Value>{value}</Row.Value>
              </Row>
            </InfoBox>
          ))}
        </Stack>
      )}
    </Stack>
  </>
);
