import { useQuery } from '@tanstack/react-query';

import { getSTRMetadata } from '@arrived/api_v2';
import { AssetType, OfferingId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { assetMetadataKey } from './assetMetadata.keys';

export function useGetSTRAssetMetadata(
  offeringId?: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getSTRMetadata>,
) {
  return useQuery({
    queryKey: assetMetadataKey(offeringId!, AssetType.STR),
    queryFn: () => getSTRMetadata(offeringId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && offeringId != null,
  });
}
