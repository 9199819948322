import * as yup from 'yup';

import { PropertyStatusTitle } from '@arrived/models';

export const OfferingHistoryPostSchema = yup.object({
  leaseCid: yup.string().nullable().notRequired(),
  propertyId: yup.number().positive().required(),
  displayDate: yup.string().required(),
  marketingRent: yup.number().positive(),
  manualStatusTitleText: yup.string().trim(),
  statusTitle: yup
    .mixed<PropertyStatusTitle>()
    .oneOf(Object.values<PropertyStatusTitle>(PropertyStatusTitle))
    .required(),
  description: yup.string().trim(),
  internalNotes: yup.string().trim(),
});

export type OfferingHistoryPostSchema = yup.InferType<typeof OfferingHistoryPostSchema>;
