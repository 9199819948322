import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postUserAccount } from '@arrived/api_v2';
import { useTrackAccreditedConversionEvent } from '@arrived/marketing';
import { AccreditedStatus } from '@arrived/models';

import { accountsPrimaryKeyFn, currentUserKeyFn, userSsnKeyFn } from '../users';
import { UseAwaitedMutationOptions } from '../utils';

import { accountsKeyFn } from './accounts.keys';

export function usePostAccountMutation(options?: UseAwaitedMutationOptions<typeof postUserAccount>) {
  const queryClient = useQueryClient();
  const trackAccreditedConversionEvent = useTrackAccreditedConversionEvent();

  return useMutation({
    mutationFn: postUserAccount,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: accountsPrimaryKeyFn(data.primaryUser.id) });
      queryClient.invalidateQueries({ queryKey: accountsKeyFn(data.id) });
      queryClient.invalidateQueries({ queryKey: currentUserKeyFn() });
      queryClient.invalidateQueries({ queryKey: userSsnKeyFn(data.primaryUser.id) });

      if (
        data.accreditedStatus === AccreditedStatus.SELF_ACCREDITED ||
        data.accreditedStatus === AccreditedStatus.VERIFIED_ACCREDITED
      ) {
        trackAccreditedConversionEvent({
          userId: data.primaryUser?.id,
        });
      }

      options?.onSuccess?.(data, variables, context);
    },
  });
}
