import { InvestorResponse } from './questionnaire';

export const InvestorArchetype = {
  NEWBIE: 'NEWBIE',
  REBEL: 'REBEL',
  WSJ: 'WSJ',
  UNKNOWN: 'UNKNOWN',
} as const;

export type InvestorArchetype = (typeof InvestorArchetype)[keyof typeof InvestorArchetype];

export const ArchetypeQuestions = {
  INVESTED_IN_RE_BEFORE: 'c3dcc46a-9f81-4034-914e-e49a2e9161a3',
  FINANCIAL_INVESTMENT_EXPERIENCE: '2d406b6e-af66-447a-adf1-5cc6db4b842e',
} as const;

export type ArchetypeQuestions = (typeof ArchetypeQuestions)[keyof typeof ArchetypeQuestions];

export const InvestedInRealEstateBeforeAnswers = {
  NO: 'c6aec066-c26b-4b2f-ab47-2147c28d939c',
  YES: 'fd984fd0-cab7-4235-a639-c4035e855fac',
} as const;

export type InvestedInRealEstateBeforeAnswers =
  (typeof InvestedInRealEstateBeforeAnswers)[keyof typeof InvestedInRealEstateBeforeAnswers];

export const InvestmentExperienceAnswers = {
  PROFICIENT: '16edd710-2e03-4b1b-b8ab-d6f30ec2849e',
  BEGINNER: 'a4c5e8b7-e7e0-4178-b93d-ba992c70eda0',
  EXPERT: 'bce6b648-2f80-4066-9e2f-51193afa3d91',
} as const;

export type InvestmentExperienceAnswers =
  (typeof InvestmentExperienceAnswers)[keyof typeof InvestmentExperienceAnswers];

/**
 * This archetypeMap is a very temporary FE construct, we'd like the answer to the question of
 * "what archetype is the current investors" to live on the user model at some point, however
 * as a quick solution we're simply going to fetch the answers to questions corresponding to
 * archetype and compute that on the FE.
 */
const archetypeMap: Record<
  InvestedInRealEstateBeforeAnswers,
  Record<InvestmentExperienceAnswers, InvestorArchetype>
> = {
  [InvestedInRealEstateBeforeAnswers.NO]: {
    [InvestmentExperienceAnswers.BEGINNER]: InvestorArchetype.NEWBIE,
    [InvestmentExperienceAnswers.PROFICIENT]: InvestorArchetype.WSJ,
    [InvestmentExperienceAnswers.EXPERT]: InvestorArchetype.WSJ,
  },
  [InvestedInRealEstateBeforeAnswers.YES]: {
    [InvestmentExperienceAnswers.BEGINNER]: InvestorArchetype.NEWBIE,
    [InvestmentExperienceAnswers.PROFICIENT]: InvestorArchetype.REBEL,
    [InvestmentExperienceAnswers.EXPERT]: InvestorArchetype.REBEL,
  },
};

/**
 * NOTE: This is not the ideal end state for how we compute investor archetype. However,
 * as a quick solution we have decided to map the responses to the persona questionnaire
 * questions to investor archetypes on the FE.
 */
export const getInvestorArchetypeFromInvestorResponses = (personaQuestionnaireResponses?: InvestorResponse[]) => {
  if (!personaQuestionnaireResponses) {
    return undefined;
  }

  const investedInRealEstateBefore = personaQuestionnaireResponses.find(
    ({ questionUUID }) => questionUUID === ArchetypeQuestions.INVESTED_IN_RE_BEFORE,
  )?.responses?.[0]?.questionResponseOptionUUID;
  const investmentExperience = personaQuestionnaireResponses.find(
    ({ questionUUID }) => questionUUID === ArchetypeQuestions.FINANCIAL_INVESTMENT_EXPERIENCE,
  )?.responses?.[0]?.questionResponseOptionUUID;

  if (investedInRealEstateBefore && investmentExperience) {
    if (Object.keys(archetypeMap).includes(investedInRealEstateBefore)) {
      if (
        Object.keys(archetypeMap[investedInRealEstateBefore as InvestedInRealEstateBeforeAnswers]).includes(
          investmentExperience,
        )
      ) {
        return archetypeMap[investedInRealEstateBefore as InvestedInRealEstateBeforeAnswers][
          investmentExperience as InvestmentExperienceAnswers
        ];
      }
    }
  }

  return InvestorArchetype.UNKNOWN;
};
