import { createTamagui } from '@tamagui/core';

import { media } from './media';
import { themes } from './themes';
import { animations, shorthands, tokens } from './tokens';
import { createCalibreFont } from './utils';

// TODO Style out the fonts

const headingFont = createCalibreFont();
const bodyFont = createCalibreFont();

export const bricksThemeConfig = createTamagui({
  settings: {
    allowedStyleValues: {
      space: 'somewhat-strict-web',
      size: 'somewhat-strict-web',
      radius: 'somewhat-strict-web',
      zIndex: 'somewhat-strict-web',
      color: 'somewhat-strict-web',
    },
    autocompleteSpecificTokens: 'except-special',
  },
  shouldAddPrefersColorThemes: false,
  themeClassNameOnRoot: false,
  shorthands,
  fonts: {
    heading: headingFont,
    body: bodyFont,
  },
  themes,
  tokens,
  animations,
  components: [],
  media,
});

export type AppConfig = typeof bricksThemeConfig;

declare module '@tamagui/core' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TamaguiCustomConfig extends AppConfig {}

  interface TypeOverride {
    groupNames(): 'StackedButton' | 'NavItem' | 'DropdownItem' | 'Choice' | 'NavHoverGroup';
  }
}
