import { useQuery } from '@tanstack/react-query';

import { getOfferingHistoryLease } from '@arrived/api_v2';
import { MAX_STALE_TIME, UseAwaitedQueryOptions, offeringHistoryLeaseKey } from '@arrived/queries';

export const useGetOfferingHistoryLease = (
  propertyStatusCid: string,
  options?: UseAwaitedQueryOptions<typeof getOfferingHistoryLease>,
) => {
  return useQuery({
    queryKey: offeringHistoryLeaseKey(propertyStatusCid!),
    queryFn: () => getOfferingHistoryLease(propertyStatusCid!),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
};
