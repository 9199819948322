import React, { ReactNode, useCallback, useState } from 'react';

import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import 'react-image-gallery/styles/scss/image-gallery.scss';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { CssBaseline } from '@mui/material';

import { AnalyticsContextProvider } from '@arrived/analytics';
import { ApiAuthenticationInterceptor, createArrivedHeaders } from '@arrived/api_v2';
import { ArrivedAuth0Provider } from '@arrived/arrived-auth0';
import { BricksProvider, Stack } from '@arrived/bricks';
import '@arrived/bricks/css/fonts.css';
import '@arrived/bricks/css/dropdown.css';
import { ArrivedIntlProvider, HTMLHeadConstants, ROUTES, accessibilityReport } from '@arrived/common';
import { AppStyles, PromotionHandler, SubscribeFooterWrapper } from '@arrived/components';
import { CONFIG } from '@arrived/config';
import { NavigatorContextProvider, ReferralContextProvider } from '@arrived/contexts';
import { FeatureFlagContextProvider } from '@arrived/feature-flags';
import { Banner } from '@arrived/features-banner';
import { ToastyContainer } from '@arrived/features-toasty';
import { GoogleTagManagerProvider } from '@arrived/marketing';
import { OfferingIdOrOfferingShortName } from '@arrived/models';
import { SEO } from '@arrived/router';
import { ThemeProvider } from '@arrived/theme';

import { PublicFooter } from '../components/footer';
import { IntercomProvider } from '../components/IntercomProvider';
import { LandingPageTestTracker } from '../components/LandingPageTests';
import { NavMenu } from '../components/navigation/NavMenu';
import { NextAnalytics } from '../components/NextAnalytics';
import PackageJson from '../package.json';
import { queryClientConfig } from '../query-client';
import createEmotionCache from '../util/createCache';

import type { AppState } from '@auth0/auth0-react';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

type NextPageWithLayout = NextPage & {
  hasNav?: boolean;
  hasFooter?: boolean;
  showNotification?: boolean;
  isPropertyDetailsPage?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & { getLayout?: (component: ReactNode) => ReactNode };
};

interface MyAppProps extends AppPropsWithLayout {
  emotionCache?: EmotionCache;
}

const publicAppHeaders = createArrivedHeaders(PackageJson.name, PackageJson.version);

function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) {
  const router = useRouter();

  const [queryClient] = useState(() => new QueryClient(queryClientConfig));

  const hasNav = Component.hasNav ?? true;
  const hasFooter = Component.hasFooter ?? false;

  const navigatorBack = useCallback(() => router.back(), [router]);
  const navigatorGo = useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- Intentionally set to any to allow for wide-spread generic support */
    (location: string, { pathOptions, ...options }: any) =>
      router.push({ pathName: location, ...pathOptions }, options),
    [router],
  );
  const navigatorGoPropertyDetails = useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- Intentionally set to any to allow for wide-spread generic support */
    (offeringIdOrShortName: OfferingIdOrOfferingShortName, options: any) =>
      router.push(
        {
          pathname: ROUTES.public.properties.propertyDetails.base,
          query: { shortName: String(offeringIdOrShortName) },
        },
        options,
      ),
    [router],
  );
  const navigatorReplace = useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- Intentionally set to any to allow for wide-spread generic support */
    (location: string, { pathOptions, ...options }: any) =>
      router.replace({ pathName: location, ...pathOptions }, options),
    [router],
  );

  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      router.push(appState?.returnTo || '/');
    },
    [router],
  );

  const getLayout = Component.getLayout ?? ((page) => page);
  const content = getLayout(<Component {...pageProps} />);

  return (
    <CacheProvider value={emotionCache}>
      <SEO>
        <SEO.Title content={HTMLHeadConstants.title} excludeTrailingTitle />
        <SEO.Description content={HTMLHeadConstants.description} />
        <SEO.Keywords content={HTMLHeadConstants.keywords} />
        <SEO.Image content={HTMLHeadConstants.imageUrl} />
        <SEO.Link rel="icon" type="image/x-icon" href="/favicon.ico" />
      </SEO>

      <ArrivedIntlProvider>
        <AnalyticsContextProvider>
          <NextAnalytics>
            <QueryClientProvider client={queryClient}>
              <NavigatorContextProvider
                hash={router.asPath.split('#')?.[1]}
                navigatorBack={navigatorBack}
                navigatorGo={navigatorGo}
                navigatorGoPropertyDetails={navigatorGoPropertyDetails}
                navigatorReplace={navigatorReplace}
              >
                <FeatureFlagContextProvider>
                  <GoogleTagManagerProvider>
                    <BricksProvider>
                      <ThemeProvider>
                        {AppStyles}
                        <CssBaseline />
                        <HydrationBoundary state={pageProps.dehydratedState}>
                          <ArrivedAuth0Provider
                            domain={CONFIG.auth0.domain}
                            clientId={CONFIG.auth0.clientId}
                            onRedirectCallback={onRedirectCallback}
                            authCallbackUri={ROUTES.authCallback}
                          >
                            <ApiAuthenticationInterceptor headers={publicAppHeaders}>
                              <LandingPageTestTracker />
                              <ReferralContextProvider>
                                <PromotionHandler>
                                  <IntercomProvider>
                                    <SubscribeFooterWrapper>
                                      <Stack minHeight="100%">
                                        {/**
                                         * The Stack wrapper with a relative position and z-index of 1 creates a new stacking context such that
                                         * anything hoisted into the PortalContext (as supplied by the BricksProvider) will be rendered "above"
                                         * the main content.
                                         */}
                                        {hasNav ? (
                                          <NavMenu>
                                            <Banner />
                                            <Stack position="relative" zIndex={1}>
                                              {content}
                                            </Stack>
                                          </NavMenu>
                                        ) : (
                                          <Stack position="relative" zIndex={1}>
                                            {content}
                                          </Stack>
                                        )}

                                        {hasFooter && <PublicFooter />}
                                      </Stack>
                                      <ReactQueryDevtools />
                                    </SubscribeFooterWrapper>
                                  </IntercomProvider>
                                </PromotionHandler>
                              </ReferralContextProvider>
                            </ApiAuthenticationInterceptor>
                          </ArrivedAuth0Provider>
                        </HydrationBoundary>
                        <ToastyContainer />
                      </ThemeProvider>
                    </BricksProvider>
                  </GoogleTagManagerProvider>
                </FeatureFlagContextProvider>
              </NavigatorContextProvider>
            </QueryClientProvider>
          </NextAnalytics>
        </AnalyticsContextProvider>
      </ArrivedIntlProvider>
    </CacheProvider>
  );
}

/**
 * Accessibility overlay by @axe-core
 */
accessibilityReport(React);

export default MyApp;
