/**
 * Please keep alphabetical! This allows us to easily double-check which of these actually exist in GrowthBook.
 *
 * If you are deprecating a feature flag. Make sure to delete the corresponding feature flag in GrowthBook after
 * deploying the deprecation.
 *
 * @tip If you need to check your alphabetical order, you can use this comment: `eslint sort-keys: "error"`
 */
export const FEATURE_FLAGS = {
  AIR_DNA_HISTORICAL_CHARTS: 'air_dna_historical_charts',
  BLOCKED_OFFERINGS: 'blocked_offerings',
  /**
   * @deprecated code references removed, GB entry to be deleted in the future
   */
  CASH_ACCOUNT: 'cash_account',
  DEFAULT_TO_PROPERTIES_TAB: 'ab-default_to_properties_tab',
  DISABLE_EMAIL_VERIFICATION_REQUIREMENT: 'disable_email_verification_requirement',
  DISABLE_LINKED_BANK: 'disable_linked_bank',
  /**
   * The Face ID pop up shows after a user has logged in for the first time
   * and they have not yet completed the face id process.
   *
   * We are using "Face ID" loosely to refer to both the Face ID pop up
   * and the actual biometric authentication.
   *
   * @link https://app.growthbook.io/features/feature-face_id_pop_up
   */
  FACE_ID_POP_UP: 'feature-face_id_pop_up',
  /**
   * The actual toggle that enables/disables biometric authentication
   *
   * @link https://app.growthbook.io/features/feature-native_biometric_authentication
   */
  NATIVE_BIOMETRIC_AUTHENTICATION: 'feature-native_biometric_authentication',
  FUND_CTA_WITH_CASH_BALANCE: 'ab-fund_cta_with_cash_balance',
  HAS_REDEMPTIONS_UI: 'has_redemptions_ui',
  INVESTORS_LIKE_YOU: 'ab-investors_like_you',
  IS_NEW_USER: 'is_new_user',
  LANDING_PAGE: 'ab-landing_page',
  MAINTENANCE_MODE: 'maintenance_mode',
  MAINTENANCE_MODE_LOGGED_IN: 'maintenance_mode_logged_in',
  MOBILE_COMING_SOON_BANNER: 'mobile_coming_soon_banner',
  MOBILE_NAV_SIGN_UP: 'feature-mobile_nav_sign_up',
  MULTIPLE_ACCOUNTS: 'multiple_accounts',
  OFFERING_HISTORY: 'feature-offering_history',
  ONBOARDING_INTRO_TEXT: 'ab-onboard_intro_text',
  PCF_ANNUALIZED_RETURNS: 'feature-pcf_annualized_returns',
  PCF_SHARE_PRICE: 'feature-pcf-share-price',
  PERSONA_QUESTIONNAIRE: 'persona_questionnaire',
  PHONE_CONTACT: 'phone_contact',
  PLAID_MANUAL_BANK_LINK: 'plaid_manual_bank_link',
  POST_BANK_LINK_SHEET: 'feature-post_bank_link_sheet',
  REAL_ESTATE_DEBT_FILTER: 'feature-real_estate_debt_filter',
  REFERRAL_PROGRAM: 'referral_program',
  REFERRAL_PROGRAM_MOBILE_APP: 'referral_program_mobile_app',
  REFERRAL_PROGRAM_USERS: 'referral_program_users',
  REFERRAL_REWARD_AMOUNT: 'referral_reward_amount',
  REGISTER_PAGE_TEST: 'abc-register_page',
  RESET_MFA: 'feature-reset_mfa',
  SFR_DIVIDEND_HISTORY: 'feature-sfr_dividend_history',
  SFR_FUND_LEVERAGE_INCEPTION_DATA_POINTS: 'feature-sfr_fund_leverage_inception_data_points',
  APP_INSTALL_SHEET: 'ab-app_install_sheet',
  SHOW_HOMEPAGE_BEZOS_QUOTES: 'feature-show_homepage_bezos_quotes',
  /**
   * Feature flag to disable users from being able to open the accreditation status
   * sheet on the PDP and Invest routes.
   *
   * @link https://app.growthbook.io/features/show_update_accreditation_sheet
   */
  SHOW_UPDATE_ACCREDITATION_SHEET: 'show_update_accreditation_sheet',
  STEP_UP_AUTH: 'step_up_auth',
  WEB_COMING_SOON_BANNER: 'web_coming_soon_banner',
  DIVERSIFY_CHECKOUT_CTA: 'ab-diversify_checkout_cta',
} as const;

export type FEATURE_FLAGS = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];
