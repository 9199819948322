import { useQuery } from '@tanstack/react-query';

import { getProperties } from '@arrived/api_v2';
import { PropertyFilters } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { getPropertiesKey } from './properties.keys';

export function useGetPropertiesQuery(
  filters?: PropertyFilters,
  options?: UseAwaitedQueryOptions<typeof getProperties>,
) {
  return useQuery({
    queryKey: getPropertiesKey(filters),
    queryFn: () => getProperties(filters),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}
