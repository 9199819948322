import { QueryKey } from '@tanstack/react-query';

import { GetSaleQueryParams, GetSalesQueryParams } from '@arrived/api_v2';
import { AccountCid, CreateRedemptionPreviewRequest, SaleCid } from '@arrived/models';

export const saleQueryKey: QueryKey = ['sales'];

export const saleQueryKeyFn = (saleCid?: SaleCid, queryParams?: GetSaleQueryParams): QueryKey =>
  saleQueryKey.concat([saleCid]).concat(queryParams ? queryParams : []);

export const salesQueryKey: QueryKey = saleQueryKey.concat(['lists']);
export const salesQueryKeyFn = (accountCid: AccountCid, filters?: GetSalesQueryParams): QueryKey =>
  salesQueryKey.concat([accountCid]).concat(filters ? filters : []);

export const redemptionPreviewQueryKey: QueryKey = saleQueryKey.concat(['previews', 'redemption']);
export const redemptionPreviewQueryKeyFn = ({ accountCid, offeringCid }: CreateRedemptionPreviewRequest): QueryKey =>
  redemptionPreviewQueryKey.concat([accountCid, offeringCid]);

// Account-level query keys for easy invalidation
export const accountSalesQueryKeyFn = (accountCid: AccountCid): QueryKey => salesQueryKey.concat([accountCid]);
export const accountRedemptionPreviewsQueryKeyFn = (accountCid: AccountCid): QueryKey =>
  redemptionPreviewQueryKey.concat([accountCid]);
