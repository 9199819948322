import { useQuery } from '@tanstack/react-query';

import { getIssuer } from '@arrived/api_v2';
import { Issuer } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { issuerQueryKey } from './issuers.keys';

export function useGetIssuerQuery(issuerId?: Issuer['id'], options?: UseAwaitedQueryOptions<typeof getIssuer>) {
  return useQuery({
    queryKey: issuerQueryKey(issuerId!),
    queryFn: () => getIssuer(issuerId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && issuerId != null,
  });
}
