import { AccountId, SupplementalData, TaxDocumentList, TaxYearRequest } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getTaxDocumentsForYear = (accountId: AccountId, year: number) =>
  createQuery<TaxDocumentList>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/tax-years/${year}`,
  });

export const getAllTaxDocuments = (accountId: AccountId) =>
  createQuery<TaxDocumentList>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/tax-years`,
  });

export const getOfferingBreakdown = (accountId: AccountId, year: number) =>
  createQuery<SupplementalData>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/tax-years/${year}/offering-breakdown`,
  });

export const patchReitDemandLetterAcknowledgement = ({
  reitDemandLetterIsAcknowledged,
  accountId,
  year,
}: TaxYearRequest) =>
  createQuery<TaxDocumentList>({
    apiInstance: api,
    method: 'patch',
    url: `/users/accounts/${accountId}/tax-years/${year}`,
    requestData: {
      reitDemandLetterIsAcknowledged,
    },
  });
