import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postPropertyFeature } from '@arrived/api_v2';
import { UseAwaitedMutationOptions } from '@arrived/queries';

import { featuresQueryKey } from './features.keys';

export function usePostFeatureMutation(options?: UseAwaitedMutationOptions<typeof postPropertyFeature>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postPropertyFeature,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: featuresQueryKey });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
