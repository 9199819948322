import * as yup from 'yup';

import { AccountsBasicInfoSchema, AccountsRequestSchema } from '../accounts';

export const OnboardingSchema = AccountsRequestSchema.concat(
  AccountsBasicInfoSchema.pick(['leadReferral', 'plannedInvestmentAmount', 'agreedToTosPrivacy', 'referralCode']),
).concat(
  yup.object({
    personaQuestionnaire: yup.array(
      yup
        .object({
          option: yup.string(),
          responses: yup.array(
            yup.object({
              uuid: yup.string(),
              checked: yup.boolean(),
              text: yup.string().max(250, 'Max 250 characters'),
            }),
          ),
        })
        .nullable(),
    ),
  }),
);

export type OnboardingSchema = yup.InferType<typeof OnboardingSchema>;
