import { useCallback, useEffect, useState } from 'react';

import { AUTH0_STEP_UP_SCOPE, useArrivedAuth0 } from '@arrived/arrived-auth0';
import { isWeb } from '@arrived/bricks';

export const useStepUpTrigger = (
  callbackFn: ({
    scope,
  }: {
    /**
     * The current applied scopes to pass to the `authorize` call.
     * This enables us to dynamically update the users current access token
     * with the latest scopes. This became useful for `offline_access` since
     * Auth0 will revoke the `offline_access` scope when `step-up` is enabled.
     *
     * In this, we end up passing `offline_access` to the `authorize` call
     * and then updating the users access token with the latest scopes. If the
     * user has Biometrics enabled, it will be able to stay logged in without
     * having to re-authenticate via refresh tokens.
     *
     * If this returns an empty string, we know that we aren't authenticated and
     * need to throw so the user gets navigated back
     */
    scope: string;
  }) => Promise<unknown | undefined>,
  stepUpAuthEnabled?: boolean,
) => {
  /**
   * If the scopes are null, we know that we haven't fetched them yet.
   */
  const [scopes, setScopes] = useState<string[] | null>(null);

  const { getAllowedScopes } = useArrivedAuth0();

  const updateScopes = useCallback(() => {
    getAllowedScopes().then(setScopes);
  }, [getAllowedScopes]);

  const handleAuthorize = useCallback(async () => {
    if (scopes === null) {
      console.log('No scopes yet, waiting for them to be set');
      return;
    }

    if (scopes.length === 0) {
      // If we have no scopes, we are most likely unauthenticated and
      // should throw so the user gets navigated back
      await callbackFn({ scope: '' });

      if (!isWeb) {
        updateScopes();
      }

      return;
    }

    if (stepUpAuthEnabled && scopes.length > 0 && !scopes.includes(AUTH0_STEP_UP_SCOPE)) {
      await callbackFn({ scope: scopes.join(' ') });

      if (!isWeb) {
        updateScopes();
      }
    }
  }, [stepUpAuthEnabled, updateScopes, callbackFn, scopes]);

  useEffect(() => {
    if (stepUpAuthEnabled) {
      updateScopes();
    }
  }, [stepUpAuthEnabled]);

  useEffect(() => {
    handleAuthorize();
  }, [scopes, stepUpAuthEnabled]);

  return scopes;
};
