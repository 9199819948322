import { Timestamps } from './timestamps';

/**
 * The following models are all generated from the BE data models and should not be modified.
 */

export enum KycStatus {
  PENDING = 'PENDING',
  AUTO_APPROVED = 'AUTO_APPROVED',
  MANUALLY_APPROVED = 'MANUALLY_APPROVED',
  AUTO_DISAPPROVED = 'AUTO_DISAPPROVED',
  MANUALLY_DISAPPROVED = 'MANUALLY_DISAPPROVED',
  NEED_MORE_INFO = 'NEED_MORE_INFO',
  PENDING_REVIEW = 'PENDING_REVIEW',
}

export enum AmlStatus {
  PENDING = 'PENDING',
  AUTO_APPROVED = 'AUTO_APPROVED',
  MANUALLY_APPROVED = 'MANUALLY_APPROVED',
  AUTO_DISAPPROVED = 'AUTO_DISAPPROVED',
  MANUALLY_DISAPPROVED = 'MANUALLY_DISAPPROVED',
  NEED_MORE_INFO = 'NEED_MORE_INFO',
  PENDING_REVIEW = 'PENDING_REVIEW',
}

export interface AccountKycAml {
  kycStatus: KycStatus;
  amlStatus: AmlStatus;
  userKycAml: UserKycAml | null;
  accountEntityAml: AccountEntityAml | null;
  entityAmlStatusDetail: KycAmlStatusDetail | null;
  kycAmlRollupStatus: KycAmlRollupStatus;
  kycAmlFailureResolution: KycAmlFailureResolution[];
}

export interface UpdateAccountKycAmlStatusRequest {
  userKycStatus: KycStatus | null;
  userAmlStatus: AmlStatus | null;
  entityAmlStatus: AmlStatus | null;
  entityAmlStatusDetail: KycAmlStatusDetail | null;
  kycStatusDetail: KycAmlStatusDetail | null;
  amlStatusDetail: KycAmlStatusDetail | null;
  manualEmailSent: boolean | null;
}

export interface PerformAccountKycAmlRequest {
  forceRerun?: boolean;
}

export interface AccountEntityAml extends Timestamps {
  uuid: string | null;
  accountId: number;
  ncId: string | null;
  ncEntityId: string;
  amlStatus: AmlStatus;
  entityAmlStatusDetail: KycAmlStatusDetail | null;
  error: KycAmlNCResponseStatus | null;
  restriction: KycAmlRestriction | null;
  active: boolean;
}

export interface UserKycAml extends Timestamps {
  uuid: string | null;
  userId: number;
  ncId: string | null;
  ncPartyId: string;
  kycStatus: KycStatus;
  kycStatusDetail: KycAmlStatusDetail | null;
  amlStatus: AmlStatus;
  amlStatusDetail: KycAmlStatusDetail | null;
  error: KycAmlNCResponseStatus | null;
  idNoteScore: string | null;
  qualifiers: KycQualifier[];
  questions: KycQuestionAndAnswers[];
  answersKey: string | null;
  answersMessage: string | null;
  answersReceivedCount: number;
  restriction: KycAmlRestriction | null;
  resultKey: string | null;
  resultMessage: string | null;
  showQuestions: boolean;
  summaryResultKey: string | null;
  summaryResultMessage: string | null;
  active: boolean;
}

export interface KycAmlNCResponseStatus {
  statusCode: number | null;
  statusDescription: string | null;
  errors: string[];
}

export interface KycAmlRestrictionPA {
  list: string | null;
  score: string | null;
}

export interface KycAmlRestriction {
  key: string;
  message: string;
  pa: KycAmlRestrictionPA[];
}

export interface KycQualifier {
  key: string;
  message: string;
}

export interface KycQuestionAndAnswers {
  prompt: string;
  type: string;
  answers: string[];
}

export enum KycAmlRollupStatus {
  /**
   * kyc/aml checks have not been performed for account
   */
  PENDING = 'PENDING',
  /**
   * kyc/aml check has not been performed yet but is in the queue to be processed
   */
  PENDING_SYNC = 'PENDING_SYNC',
  /**
   * all kyc/aml checks passed (either automatically or manually)
   */
  PASS = 'PASS',
  /**
   * one or more of the following kyc/aml checks weren't approved:
   * user - kyc
   * user - aml
   * account - aml (if applicable - account aml only performed on entity accounts)
   */
  FAIL = 'FAIL',
}

export enum KycAmlFailureResolution {
  /**
   * User needs to upload a document or provide additional information
   * in order to resolve kyc/aml issue
   */
  NEED_DOC_UPLOAD = 'NEED_DOC_UPLOAD',
  /**
   * User has uploaded a document or provided additional information
   * that now needs to be reviewed by customer-service team
   */
  PENDING_REVIEW = 'PENDING_REVIEW',
  /**
   * User needs to update their SSN in order to resolve kyc/aml issue
   * Note: it is possible for a user to pass kyc check, but still
   * need to update their SSN, if a user provided a SSN that is off-by-1
   * This issue technically doesn't cause a kyc failure; however,
   * we do need to get the user's updated ssn for tax purposes.
   */
  NEED_SSN_UPDATE = 'NEED_SSN_UPDATE',
}

export enum KycAmlStatusDetail {
  INTERNATIONAL = 'INTERNATIONAL',
  EXPIRED = 'EXPIRED',
  INCOMPLETE = 'INCOMPLETE',
  PATRIOT_ACT = 'PATRIOT_ACT',
}
