import { Webinar } from '@arrived/models';

import { demioApi } from './api';
import { createQuery } from './create-query';

/**
 * Valid series params for the GET /webinars request, these should match the const enum in arrived-sst.
 */
export const Series = {
  INTRO: 'intro',
} as const;

export type Series = (typeof Series)[keyof typeof Series];

export interface WebinarRegistrationResponse {
  hash: string;
  join_link: string;
}

export interface WebinarRegistrationRequest {
  id: string;
  name: string;
  email: string;
}

export const getWebinars = (series?: Series) =>
  createQuery<Webinar[]>({
    apiInstance: demioApi,
    method: 'get',
    url: '/webinars',
    config: { params: { series } },
  });

export const postWebinarRegistration = (requestData: WebinarRegistrationRequest) =>
  createQuery<WebinarRegistrationResponse>({
    apiInstance: demioApi,
    method: 'post',
    url: '/webinar/register',
    requestData,
  });
