import { HTMLProps, forwardRef } from 'react';

import { ImageProps } from '@arrived/bricks';

/**
 * Quick hack image to support basic server side images and simple
 * layout support for images on the blog.
 */
export const BuilderImage = forwardRef<HTMLImageElement, HTMLProps<'img'> & Pick<ImageProps, 'source'>>(
  (props, ref) => {
    const { source, alt, className } = props;

    if (typeof source === 'string') {
      return <img ref={ref} alt={alt} src={source} className={className} />;
    } else {
      // @ts-expect-error -- Refs and source aren't typed correctly here, should be fixed for SSR
      return <img ref={ref} alt={alt} src={source.uri} className={className} />;
    }
  },
);
