import { Offering, OfferingStatus } from '@arrived/models';

import { OfferingTransactState } from '../constants';

export interface GetOfferingTransactStateArgs {
  canUserTransactEarlyAccess?: boolean;
  canUserTransactOpenSecTrade?: boolean;
  offering: Offering;
  isOfferingBlocked?: boolean;
  isOfferingSoldOut?: boolean;
}

export function getOfferingTransactState({
  canUserTransactEarlyAccess,
  canUserTransactOpenSecTrade,
  offering,
  isOfferingBlocked,
  isOfferingSoldOut,
}: GetOfferingTransactStateArgs) {
  if (isOfferingBlocked) {
    return OfferingTransactState.PRE;
  }

  if (isOfferingSoldOut) {
    return OfferingTransactState.CLOSED;
  }

  switch (offering.status) {
    case OfferingStatus.TRANSACT_ALL:
      return OfferingTransactState.IN_PROGRESS;
    case OfferingStatus.TRANSACT_EARLY_ACCESS:
      return canUserTransactEarlyAccess ? OfferingTransactState.IN_PROGRESS : OfferingTransactState.PRE;
    case OfferingStatus.FUNDED:
      /**
       * If the offering is funded and the offering's isOpenForSec is true,
       * and the user is allowed to transact on the open for SEC trade offering,
       * then we set the status to IN_PROGRESS. Otherwise, we set the state to CLOSED.
       */
      if (offering.isOpenForSec && canUserTransactOpenSecTrade) {
        return OfferingTransactState.IN_PROGRESS;
      } else {
        return OfferingTransactState.CLOSED;
      }
    default:
      return OfferingTransactState.PRE;
  }
}
