import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import { ApiError, ApiResponse } from '@arrived/models';
import { Sentry } from '@arrived/sentry';

/**
 * Checks if the response has a data property and no pagination/meta property.
 */
const checkResponse = <TResponse>(response: AxiosResponse<ApiResponse<TResponse>>) =>
  response &&
  response.data &&
  'data' in response.data &&
  !('pagination' in response.data) &&
  !('meta' in response.data);

type RequestData =
  | {
      [key: string]: unknown;
    }
  | unknown[]
  | object;

interface CreateQueryProps<TData = RequestData> {
  /**
   * The axios instance to use for the request.
   */
  apiInstance: AxiosInstance;

  /**
   * The HTTP method to use for the request, e.g. `post`, `put`, `patch`, `get`, `delete`.
   */
  method: Method;

  /**
   * The URL to make the request to.
   */
  url: string;

  /**
   * The data to send with the request. Used with `post`, `put`, and `patch` requests.
   */
  requestData?: TData;

  /**
   * Optional mocked response data for testing purposes.
   */
  mockedResponseData?: TData;

  /**
   * The config to use for the request.
   */
  config?: AxiosRequestConfig<TData>;

  /**
   * Most requests come back as `data.data`, by default
   * we unwrap the response to just return the data.
   * If you want to keep the response wrapped, set this to `true`.
   *
   * This is useful for requests that need to be further manipulated
   * before returning the data.
   * @default false
   */
  preserveResponse?: boolean;
}

interface PreservedCreateQueryProps<TData = RequestData> extends CreateQueryProps<TData> {
  preserveResponse: true;
}

interface UnwrappedCreateQueryProps<TData = RequestData> extends CreateQueryProps<TData> {
  preserveResponse?: false;
}

export async function createQuery<TResponse, TData = RequestData>(
  props: PreservedCreateQueryProps<TData>,
): Promise<ApiResponse<TResponse>>;

// Overload when preserveResponse is false or omitted
export async function createQuery<TResponse, TData = RequestData>(
  props: UnwrappedCreateQueryProps<TData>,
): Promise<TResponse>;

/**
 * This is the core generic function that powers all of our API calls. It
 * stays fairly generic allowing us to use it for most instances of the API.
 *
 * The overloads allow for stricter typing when we know the return type of the
 * API call.
 */
export async function createQuery<TResponse, TData = RequestData>({
  apiInstance,
  method,
  url,
  requestData,
  mockedResponseData,
  config = {},
  preserveResponse = false,
}: CreateQueryProps<TData>) {
  const requestConfig: AxiosRequestConfig<TData> = {
    url,
    method,
    ...config,
  };

  // Include data in the request for 'post', 'put', 'patch' methods
  if (['post', 'put', 'patch', 'delete'].includes(method.toLowerCase()) && requestData) {
    requestConfig.data = requestData;
  }

  return apiInstance<ApiResponse<TResponse>>(requestConfig)
    .then((response) => {
      if (mockedResponseData) return mockedResponseData;
      // Catch errors and make sure we throw a default AxiosError based on the response
      if (response.data?.error) {
        throw response;
      }

      if (preserveResponse) {
        return response.data;
      }

      /**
       * If the response has a data property and no pagination property, unwrap the response.
       * We want to do this to avoid unwrapping early and returning the incorrect type back
       * to the developer.
       */
      if (checkResponse(response)) {
        return response.data.data;
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      let formattedError: ApiError;

      if (axios.isAxiosError(error)) {
        formattedError = error;

        // If we have a response, we can assume it is a generic Error from Abacus
        // However, we don't know what any of it is, so this is
        // randomly guessing at what the error could be. But helps
        // us get a better error message than just "An error occurred"
        if (error.response && 'data' in error.response) {
          if (error.response.data?.error?.description) {
            formattedError.description = error.response.data.error.description;
          }

          if (error.response.data?.error?.error) {
            formattedError.code = error.response.data.error.error;
          }
        }
      } else {
        // Handle non-Axios errors, basically mapping what we know
        // about the error to the ApiError type.
        const updatedError = new AxiosError<TResponse, TData>(
          error.data?.error?.description ??
            error.data?.error?.message ??
            error.message ??
            'An unexpected error occurred',
          error.data?.error?.error ?? error.code,
          error.config,
          error.request,
          error,
        );

        formattedError = updatedError;
      }

      // Create a quick alias for description as we use that more
      // than message throughout the codebase.
      if (!formattedError.description) {
        formattedError.description = formattedError.message;
      }

      if (!formattedError.status) {
        formattedError.status = formattedError.response?.status;
      }

      if (!formattedError.error) {
        formattedError.error = formattedError.code;
      }

      // Including both versions as separate breadcrumbs in case we're misconfiguring the error.
      // The data of breadcrumbs is often truncated for lengthy error stacktraces, so the split
      // breadcrumbs ensures we aren't losing out on potentially valuable information.
      Sentry.addBreadcrumb({
        category: Sentry.BreadcrumbCategories.Errors,
        level: 'info',
        message: 'Axios error, original state:',
        data: {
          ...error,
        },
      });
      Sentry.addBreadcrumb({
        category: Sentry.BreadcrumbCategories.Errors,
        level: 'info',
        message: 'Axios error, formatted state:',
        data: {
          ...formattedError,
        },
      });

      return Promise.reject(formattedError);
    });
}
