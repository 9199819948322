import { GetProps, styled } from '@tamagui/core';

import { VALUE_VARIANTS } from './mappings/value';
import { RootText } from './RootText';

export const ValueText = styled(RootText, {
  name: 'ValueText',
  userSelect: 'auto',

  variants: {
    token: VALUE_VARIANTS,
  } as const,

  defaultVariants: {
    token: 'value.medium',
  },
});

export type ValueTextProps = GetProps<typeof ValueText>;
