import { differenceInWeeks } from 'date-fns';

import { Offering, OfferingStatus } from '@arrived/models';

import { OfferingDisplayState } from '../constants';

export interface GetOfferingDisplayStateArgs {
  canUserTransactEarlyAccess?: boolean;
  isOfferingBlocked?: boolean;
  isOfferingForSale?: boolean;
  isOfferingSoldOut?: boolean;
  offering: Offering;
}

export function getOfferingDisplayState({
  canUserTransactEarlyAccess,
  isOfferingBlocked,
  isOfferingForSale,
  isOfferingSoldOut,
  offering,
}: GetOfferingDisplayStateArgs) {
  if (isOfferingBlocked || isOfferingSoldOut) {
    return OfferingDisplayState.SOLD_OUT;
  } else if (offering.status === OfferingStatus.PAUSED) {
    return OfferingDisplayState.PAUSED;
  } else if (
    offering.status === OfferingStatus.COMING_SOON ||
    offering.status === OfferingStatus.PRE_LAUNCH ||
    (offering.status === OfferingStatus.TRANSACT_EARLY_ACCESS && !canUserTransactEarlyAccess)
  ) {
    return OfferingDisplayState.COMING_SOON;
  } else if (isOfferingForSale) {
    if (offering.fundedPercentNoDebt >= 80) {
      return OfferingDisplayState.CLOSE;
    } else if (offering.ipoDate && Math.abs(differenceInWeeks(new Date(), new Date(offering.ipoDate))) <= 2) {
      return OfferingDisplayState.NEW;
    } else {
      return OfferingDisplayState.AVAILABLE;
    }
  }
}
