import { useParams } from 'react-router-dom';

import { getOffering } from '@arrived/api_v2';
import { UseAwaitedQueryOptions, useOfferingQuery } from '@arrived/queries';

/**
 * Fetches the offering associated with the id/short name in the URL.
 */
export const useOfferingFromUrlParams = (options?: UseAwaitedQueryOptions<typeof getOffering>) => {
  const { idOrShortName } = useParams();

  return useOfferingQuery(idOrShortName!, {
    ...options,
    enabled: (options?.enabled ?? true) && Boolean(idOrShortName),
  });
};
