import { memo } from 'react';

import { Defs, LinearGradient, Path, Stop, Svg, SvgProps } from 'react-native-svg';

function YoutubeVideoIconInternal(props: SvgProps) {
  return (
    <Svg width="48" height="48" fill="none" viewBox="0 0 48 48" {...props}>
      <Path
        fill="url(#a)"
        d="M45.012 34.56c-.439 2.24-2.304 3.947-4.608 4.267C36.783 39.36 30.748 40 23.945 40c-6.693 0-12.728-.64-16.459-1.173-2.304-.32-4.17-2.027-4.608-4.267C2.439 32.107 2 28.48 2 24s.439-8.107.878-10.56c.439-2.24 2.304-3.947 4.608-4.267C11.107 8.64 17.142 8 23.945 8c6.803 0 12.728.64 16.459 1.173 2.304.32 4.17 2.027 4.608 4.267.439 2.453.988 6.08.988 10.56-.11 4.48-.549 8.107-.988 10.56Z"
      />
      <Path
        fill="#000"
        d="m32.352 22.44-11.436-7.624a1.868 1.868 0 0 0-1.925-.093c-.611.327-.991.96-.991 1.653v15.248a1.874 1.874 0 0 0 2.915 1.561l11.436-7.624c.523-.349.835-.932.835-1.56a1.871 1.871 0 0 0-.834-1.56Z"
        opacity=".05"
      />
      <Path
        fill="#000"
        d="m20.681 15.237 10.79 7.194c.689.495 1.153.938 1.153 1.513s-.224.976-.715 1.334c-.371.27-11.045 7.364-11.045 7.364-.901.604-2.364.476-2.364-1.499V16.744c0-2.005 1.584-1.905 2.181-1.507Z"
        opacity=".07"
      />
      <Path
        fill="#fff"
        d="M19 31.568V16.433a.93.93 0 0 1 1.447-.774l11.352 7.568a.93.93 0 0 1 0 1.549l-11.352 7.568A.931.931 0 0 1 19 31.568Z"
      />
      <Defs>
        <LinearGradient id="a" x1="9.816" x2="41.246" y1="9.871" y2="41.301" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F44F5A" />
          <Stop offset="0.443" stopColor="#EE3D4A" />
          <Stop offset="1" stopColor="#E52030" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export const YoutubeVideoIcon = memo<SvgProps>(YoutubeVideoIconInternal);
