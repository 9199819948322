import { useCashAccountBalances, useFormatCurrency } from '@arrived/cash-account-lib';
import { useGetAccountBalanceQuery, useGetCurrentUserQuery, useGetPrimaryAccountQuery } from '@arrived/queries';

export const useProcessShortCodes = (text?: string) => {
  const { data: currentUser, isFetching: currentUserIsFetching } = useGetCurrentUserQuery();
  const primaryAccountState = useGetPrimaryAccountQuery();
  const cashAccountValues = useCashAccountBalances(primaryAccountState.data?.cashAccountCid);
  const accountBalanceState = useGetAccountBalanceQuery(primaryAccountState.data?.id);

  const rawDividend = accountBalanceState.data?.rentalIncome?.latestDividend?.totalDividendsPaid;
  const formattedDividend = useFormatCurrency(rawDividend);

  const isLoading =
    currentUserIsFetching ||
    primaryAccountState.isFetching ||
    cashAccountValues.cashAccountState.isFetching ||
    accountBalanceState.isFetching;

  if (!text) {
    return ['', isLoading] as const;
  }

  const regex = /\{first_name\}|\{last_name\}|\{wallet_balance}|\{latest_dividend\}/g;

  const processedText = text?.replace(regex, (match) => {
    switch (match) {
      case '{first_name}':
        return currentUser?.firstName ?? match;

      case '{last_name}':
        return currentUser?.lastName ?? match;

      case '{wallet_balance}':
        return cashAccountValues?.total?.formatted ?? match;

      case '{latest_dividend}':
        if (rawDividend && rawDividend > 0 && formattedDividend) {
          return formattedDividend;
        }

        return match;

      default:
        return match;
    }
  });

  return [processedText, isLoading] as const;
};
