import { styled } from '@arrived/bricks';

import { ProductInfoBox } from '../../../../../productInfoBox';

import { GroupName } from './constants';

export const Group = styled(ProductInfoBox, {
  name: GroupName,

  p: 0,
  gap: 0,
});
