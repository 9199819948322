import { FormattedMessage } from 'react-intl';

import { Image, Stack, TitleText } from '@arrived/bricks';
import { getImageKitSource } from '@arrived/bricks-common';

const valueRising = getImageKitSource({
  uri: 'https://cdn.arrivedhomes.com/images/signup/value_rising.png',
  width: 455,
  height: 650,
});

export const Variation2 = () => (
  <>
    <Stack px="$12" pt="$6">
      <TitleText token="title.display.medium" tag="h1" fontWeight="$4">
        <FormattedMessage
          id="earn-income-and-appreciation"
          defaultMessage="Earn income &{br}appreciation"
          values={{ br: <br /> }}
        />
      </TitleText>
    </Stack>
    <Image source={valueRising} position="absolute" bottom={0} left={0} width={455} height={650} contentFit="contain" />
  </>
);
