import {
  AccountId,
  OfferingId,
  OfferingTransaction,
  OfferingTransactionDocument,
  OfferingTransactionId,
  SignDocumentRequest,
  UserId,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';
import { getBlob } from './utils';

export const getOfferingTransactionDocumentsPreviewAsPdf = async (offeringTransactionId: OfferingTransactionId) => {
  return await getBlob(`/trades/${offeringTransactionId}/documents/pdf/preview`);
};

export const postSignOfferingTransactionDocuments = (request: SignDocumentRequest) =>
  createQuery<OfferingTransaction>({
    apiInstance: api,
    method: 'post',
    url: `/trades/${request.offeringTransactionId}/documents/sign`,
    requestData: request,
  });

export const getOfferingTransactionDocuments = (offeringTransactionId: OfferingTransactionId) =>
  createQuery<OfferingTransactionDocument>({
    apiInstance: api,
    method: 'get',
    url: `/trades/${offeringTransactionId}/documents`,
  });

export const deleteOfferingTransactionDocuments = (offeringTransactionId: OfferingTransactionId) =>
  createQuery<OfferingTransactionDocument>({
    apiInstance: api,
    method: 'delete',
    url: `/trades/${offeringTransactionId}/documents`,
  });

export const getOfferingTransactionDocumentsAsPdf = (offeringTransactionId: OfferingTransactionId) =>
  createQuery<Blob>({
    apiInstance: api,
    method: 'get',
    url: `/trades/${offeringTransactionId}/documents/pdf`,
    config: { responseType: 'blob' },
  });

export const getOfferingTransactionDocumentsForOffering = (offeringId: OfferingId) =>
  createQuery<OfferingTransactionDocument[]>({
    apiInstance: api,
    method: 'get',
    url: `/trades/documents/offerings/${offeringId}`,
  });

export const getOfferingTransactionDocumentsForUser = (userId: UserId) =>
  createQuery<OfferingTransactionDocument[]>({
    apiInstance: api,
    method: 'get',
    url: `/trades/documents/users/${userId}`,
  });

export const getOfferingTransactionDocumentsForAccount = (accountId: AccountId) =>
  createQuery<OfferingTransactionDocument[]>({
    apiInstance: api,
    method: 'get',
    url: `/trades/documents/accounts/${accountId}`,
  });
