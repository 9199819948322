import { useQuery } from '@tanstack/react-query';

import { getReferralStatuses } from '@arrived/api_v2';

import { UseAwaitedQueryOptions } from '../utils';

import { referralStatusesQueryKey } from './referrals.keys';

export const useGetReferralStatusesQuery = (options?: UseAwaitedQueryOptions<typeof getReferralStatuses>) => {
  return useQuery({
    queryKey: referralStatusesQueryKey,
    queryFn: () => getReferralStatuses(),
    ...options,
  });
};
