import { QueryKey } from '@tanstack/react-query';

import { AssetType, MarketId, OfferingCid, OfferingId, OfferingType } from '@arrived/models';

import { marketsKey } from '../markets';
import { offeringsKey } from '../offerings';

export const assetMetadataKey = (offeringId: OfferingId | OfferingCid, type: AssetType | OfferingType): QueryKey => [
  offeringsKey,
  offeringId,
  type,
  'metadata',
];

export const regDIPOAssetMetadataKey = (
  offeringId: OfferingId | OfferingCid,
  type: AssetType | OfferingType,
): QueryKey => assetMetadataKey(offeringId, type).concat('regd');

export const offeringVideoMetadataKey = (offeringId: OfferingId, type: AssetType | OfferingType): QueryKey =>
  assetMetadataKey(offeringId, type).concat('video');
export const marketVideoMetadataKey = (marketId: MarketId): QueryKey => [marketsKey, marketId, 'metadata', 'video'];
