import { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { FlashList } from '@shopify/flash-list';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';

import { Count, Divider, Stack, Tag, TitleText } from '@arrived/bricks';
import { Property } from '@arrived/models';

import { ProductDetailContent } from '../../../../../content';

import {
  RentalStatus,
  RentalStatusOrder,
  RentalStatusText,
  getPropertiesByRentalStatus,
} from './getPropertiesByRentalStatus';
import { FundPropertyListItem } from './propertyListItem';

export type FundPropertyListProps = {
  onPropertyPress: (property: Property) => void;
  otherProperties: Property[];
  recentlyAcquiredProperties: Property[];
};

export const FundPropertyListImpl = ({
  onPropertyPress,
  otherProperties,
  recentlyAcquiredProperties,
}: FundPropertyListProps) => {
  const propertiesByRentalStatus = useMemo(() => getPropertiesByRentalStatus(otherProperties), [otherProperties]);

  const allPropertiesCount = useMemo(
    () => Object.entries(propertiesByRentalStatus).reduce((count, [_, properties]) => count + properties.length, 0),
    [propertiesByRentalStatus],
  );

  const allPropertiesListData = useMemo(() => {
    return Object.entries(propertiesByRentalStatus)
      .sort(
        ([rentalStatusA], [rentalStatusB]) =>
          RentalStatusOrder[rentalStatusA as RentalStatus] - RentalStatusOrder[rentalStatusB as RentalStatus],
      )
      .reduce(
        (data, [rentalStatus, properties]: [RentalStatus, Property[]]) => [...data, rentalStatus, ...properties],
        [] as Array<RentalStatus | Property>,
      );
  }, [propertiesByRentalStatus]);

  /**
   * Omitting the sticky header indices for now as there was a few issues:
   *   1. They didn't work at all, I think FlashList expects the list to be wrapped in it's own
   *      ScrollView and the header would stick to the top of that, not the root document.
   *   2. It was causing issues with text wrapping in the section headers.
   */
  // const stickyHeaderIndices = useMemo(() => {
  //   return allPropertiesListData
  //     .map((item, index) => typeof item === 'string' && index)
  //     .filter((item) => typeof item === 'number') as number[];
  // }, [allPropertiesListData]);

  return (
    <Stack gap="$4" $gtSm={{ gap: '$6' }}>
      {recentlyAcquiredProperties.length > 0 && (
        <Stack alignItems="center" gap="$2" row px="$4" $gtSm={{ px: 0 }}>
          <TitleText>
            <FormattedMessage id="product-details.recently-acquired" />
          </TitleText>
          <Count variant="inverted">{recentlyAcquiredProperties.length}</Count>
        </Stack>
      )}
      {recentlyAcquiredProperties.length > 0 && (
        <ProductDetailContent btw="$0.25" bbw="$0.25">
          <FlashList
            data={recentlyAcquiredProperties}
            ItemSeparatorComponent={() => <Divider solid alt />}
            renderItem={({ item: property }) => (
              <FundPropertyListItem
                $gtSm={{ size: 'large' }}
                property={property}
                onPress={() => onPropertyPress?.(property)}
                tags={
                  property.goLiveDate ? (
                    <Tag condensed square variant="inverted">
                      <Tag.Label>
                        {formatDistanceToNowStrict(parseISO(property.goLiveDate), { addSuffix: true })}
                      </Tag.Label>
                    </Tag>
                  ) : undefined
                }
              />
            )}
            estimatedItemSize={90}
          />
        </ProductDetailContent>
      )}
      {allPropertiesCount > 0 && (
        <Stack alignItems="center" gap="$2" row px="$4" $gtSm={{ px: 0 }}>
          <TitleText>
            <FormattedMessage id="product-details.all-properties" />
          </TitleText>
          <Count variant="inverted">{allPropertiesCount}</Count>
        </Stack>
      )}
      {allPropertiesCount > 0 && (
        <ProductDetailContent btw="$0.25" bbw="$0.25">
          <FlashList
            data={allPropertiesListData}
            ItemSeparatorComponent={() => <Divider solid alt />}
            estimatedItemSize={75}
            renderItem={({ item, index }) => {
              if (typeof item === 'string') {
                return (
                  <ProductDetailContent.SectionHeader>
                    <ProductDetailContent.SectionHeader.Text>
                      <FormattedMessage id={RentalStatusText[item as RentalStatus].title} />
                    </ProductDetailContent.SectionHeader.Text>
                    <ProductDetailContent.SectionHeader.SubText>
                      <FormattedMessage id={RentalStatusText[item as RentalStatus].subtitle} />
                    </ProductDetailContent.SectionHeader.SubText>
                  </ProductDetailContent.SectionHeader>
                );
              } else {
                return (
                  <FundPropertyListItem
                    key={`${item.id}-${index}`}
                    property={item}
                    onPress={() => onPropertyPress?.(item)}
                  />
                );
              }
            }}
          />
        </ProductDetailContent>
      )}
    </Stack>
  );
};
