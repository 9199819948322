import { Children, ComponentType } from 'react';

import { Divider, GetProps, Stack, StackProps, styled, withStaticProperties } from '@arrived/bricks';

import { AccordionListItem } from './AccordionListItem';

type AccordionListWrapperProps = StackProps & {
  /**
   * By default, a `Divider` will be rendered between every child item, if you would like to
   * disable the default behavior you can pass `false` or your own custom implementation.
   */
  Divider?: false | ComponentType;
};

const DefaultDivider = styled(Divider, {
  solid: true,
  alt: true,
});

/**
 * An `AccordionList` is a flexible component that can be used to create a list of animated drop
 * downs like the "Common Questions" section on the IDP.
 *
 * This component is a `Stack` which can be customized with the exact same properties in addition
 * to:
 * - Specifying the basic `divider` or a specific `Divider` that will be rendered between each
 *   child in the list.
 * - Passing many `AccordionList.Item` components as children which specify each individual
 *   dropdown which make up the list.
 */
export const AccordionList = withStaticProperties(
  Stack.styleable<AccordionListWrapperProps>(({ children, Divider = DefaultDivider, ...rest }, ref) => (
    <Stack ref={ref} {...rest}>
      {Children.map(children, (child, index) => (
        <>
          {index >= 1 && !!Divider && <Divider />}
          {child}
        </>
      ))}
    </Stack>
  )),
  {
    Item: AccordionListItem,
  },
);

export type AccordionListProps = GetProps<typeof AccordionList>;
