import { ArrivedBuilderModels } from '../models';
import { fetchOneEntry } from '../sdk';

type FetchOneEntryParams = Omit<Parameters<typeof fetchOneEntry>[0], 'apiKey' | 'model'>;

/**
 * The base query key for all Builder queries
 */
export const builderQueryKey = ['builder'] as const;

export const builderPageQueryFn = ({ urlPath, ...options }: { urlPath: string } & FetchOneEntryParams) =>
  [...builderQueryKey, ArrivedBuilderModels.PAGE, urlPath, options] as const;

export const builderSymbolsQueryKeyFn = (query: Record<string, unknown>) =>
  [...builderQueryKey, 'symbol', query] as const;

export const builderBannersQueryKeyFn = (query: Record<string, unknown>) =>
  [...builderQueryKey, 'banner', query] as const;

export const builderNavItemsQueryKeyFun = (query: Record<string, unknown>) =>
  [...builderQueryKey, 'nav-items', query] as const;

export const builderInvestNavGroupsQueryKeyFun = (query: Record<string, unknown>) =>
  [...builderQueryKey, 'invest-nav-groups', query] as const;
