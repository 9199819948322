import { ReactNode, useMemo } from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AppBar,
  AppBarProps,
  Icon,
  Slide,
  SlideProps,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
} from '@mui/material';

import { ArrivedHeartLogo } from '@arrived/images';

import { Subscribe } from './Subscribe';

const SubscribeFooterTypography = ({ children, ...rest }: TypographyProps) => {
  return (
    <Typography color="white" variant="h5" fontWeight={500} lineHeight={1.1} {...rest}>
      {children}
    </Typography>
  );
};

export interface SubscribeFooterProps extends SlideProps {
  accordionProps?: Omit<AccordionProps, 'children'>;
  appBarProps?: AppBarProps;
  cta: ReactNode;
  icon?: boolean;
  onSubscribe?: (email: string) => void;
  stackProps?: StackProps;
}

export const SubscribeFooter = ({
  accordionProps,
  appBarProps,
  children,
  cta,
  icon = true,
  stackProps,
  onSubscribe,
  ...rest
}: SubscribeFooterProps) => {
  const { sx: appBarPropsSx, ...appBarPropsRest } = appBarProps || {};
  const { disabled: accordionDisabled, sx: accordionPropsSx, ...accordionPropsRest } = accordionProps || {};

  const buttonProps = useMemo(() => ({ children: cta, sx: { flexShrink: 0 } }), [cta]);

  return (
    <Slide direction="up" mountOnEnter {...rest}>
      <AppBar
        sx={{ backgroundColor: ({ palette }) => palette.darkened.midnight, ...appBarPropsSx }}
        {...appBarPropsRest}
      >
        <Stack
          alignItems="center"
          direction="row"
          display={{ xs: 'none', md: 'flex' }}
          justifyContent="center"
          py={{ xs: 1, md: 2, xl: 3 }}
          px={{ xs: 2, xl: 3 }}
          mx="auto"
          maxWidth={({ breakpoints }) => breakpoints.values.lg}
          width="100%"
          gap={3}
          {...stackProps}
        >
          <Stack direction="row" alignItems="center" gap={1.5}>
            {icon && (
              <Icon sx={{ flex: '0 0 auto' }}>
                <ArrivedHeartLogo prefix="a" />
              </Icon>
            )}
            <SubscribeFooterTypography>{children}:</SubscribeFooterTypography>
          </Stack>
          <Subscribe buttonProps={buttonProps} flexShrink={0.25} onSubscribe={onSubscribe} context="stack" />
        </Stack>
        <Accordion
          disabled={accordionDisabled}
          defaultExpanded
          sx={{
            backgroundColor: 'black',
            display: { xs: 'initial', md: 'none' },
            ...accordionPropsSx,
          }}
          {...accordionPropsRest}
        >
          <AccordionSummary
            expandIcon={!accordionDisabled && <ExpandMore sx={{ color: 'white', transform: 'rotate(180deg)' }} />}
            sx={{
              px: 0,
              mx: 2,
              '& .MuiAccordionSummary-content': { minWidth: 0, width: '100%' },
              '& .content': { whiteSpace: 'nowrap' },
              '&.Mui-expanded .content': { whiteSpace: 'unset' },
              '&.Mui-disabled': { opacity: 'unset' },
            }}
          >
            <Stack direction="row" alignItems="center" gap={1.5} width="100%">
              {icon && (
                <Icon sx={{ flex: '0 0 auto' }}>
                  <ArrivedHeartLogo prefix="b" />
                </Icon>
              )}
              <SubscribeFooterTypography className="content" overflow="hidden" textOverflow="ellipsis">
                {children}
              </SubscribeFooterTypography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Subscribe buttonProps={buttonProps} onSubscribe={onSubscribe} context="accordion" />
          </AccordionDetails>
        </Accordion>
      </AppBar>
    </Slide>
  );
};
