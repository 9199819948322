import { PropsWithChildren, useCallback, useContext, useEffect } from 'react';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { Intercom, useIsMobile } from '@arrived/components';
import { IntercomDisplayContext } from '@arrived/contexts';

// env variable gets baked in at build time.
// This should always be the prod URL
const APP_PATH = process.env.NX_WEB_APP_ROOT;

export const IntercomProvider = ({ children }: PropsWithChildren) => {
  const { isAuthenticated } = useArrivedAuth0();

  const createPathForAdminPage = useCallback((id: string, hash?: string) => {
    return `${APP_PATH}/admin/user/${id}${hash ?? ''}`;
  }, []);

  return (
    <Intercom createPathForAdminPage={createPathForAdminPage} enabled={isAuthenticated}>
      <IntercomProviderContent>{children}</IntercomProviderContent>
    </Intercom>
  );
};

const IntercomProviderContent = ({ children }: PropsWithChildren) => {
  const { hideLauncherInApp } = useContext(IntercomDisplayContext);
  const isMobile = useIsMobile();
  const { isAuthenticated } = useArrivedAuth0();

  useEffect(() => hideLauncherInApp(isAuthenticated && isMobile), [hideLauncherInApp, isAuthenticated, isMobile]);

  return <>{children}</>;
};
