import { getVariable } from '@tamagui/core';

import { StackProps } from '../../atoms';

export const getOutlinedStyles = (isInverted?: boolean, isLoading?: boolean, isDisabled?: boolean): StackProps => {
  if (isLoading) {
    return {
      disabled: true,
      pointerEvents: 'none',
      bg: '$transparent',
      borderWidth: '$0.5',
      borderColor: isInverted ? '$interactive.neutral.focusInverted' : '$interactive.neutral.focus',
    };
  }

  if (isDisabled) {
    return {
      bg: '$transparent',
      borderWidth: '$0.5',
      borderColor: isInverted ? '$interactive.neutral.disabledInverted' : '$interactive.neutral.disabled',
      disabled: true,
      pointerEvents: 'none',
    };
  }

  return {
    bg: '$transparent',
    borderWidth: '$0.5',
    borderColor: isInverted ? '$interactive.neutral.restedInverted' : '$interactive.neutral.rested',

    focusStyle: {
      borderColor: isInverted ? '$interactive.neutral.focusInverted' : '$interactive.neutral.focus',
    },
    hoverStyle: {
      borderWidth: '$1',
      borderColor: isInverted ? '$interactive.neutral.hoveredInverted' : '$interactive.neutral.hovered',
      px: `calc(${getVariable('$4', 'space')} - ${getVariable('$0.5', 'space')})`,
    },
    pressStyle: {
      borderColor: isInverted ? '$interactive.neutral.focusInverted' : '$interactive.neutral.focus',
    },
  };
};
