/**
 * There is an issue on iOS where you have a special quotation from the keyboard.
 * Previously there was an instance of a user that has a quote in their name that
 * caused our validation to fail. This replaces that quote with a "normal" quote
 * to ensure that the validation passes.
 */
export const sanitizeQuotes = (input?: string) => {
  if (!input) {
    return '';
  }

  // Ensure apostrophes are retained, spaces and letters are kept, everything else is removed.
  // We need to check for any weird apostrophes and replace them with a normal one. This is mainly for mobile devices.
  return input.normalize().replace(/['‘’]/g, "'").trim();
};
