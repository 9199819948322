import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchPropertiesForOffering } from '@arrived/api_v2';
import { UseAwaitedMutationOptions, offeringKeyFn, offeringsKeyFn } from '@arrived/queries';

export function usePatchPropertiesForOfferingMutation(
  options?: UseAwaitedMutationOptions<typeof patchPropertiesForOffering>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchPropertiesForOffering,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: offeringsKeyFn() });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.id) });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.shortName) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
