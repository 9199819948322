import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { AppreciationInputOption, AppreciationInputType } from '../constants';

export interface AppreciationOptionLabelProps extends AppreciationInputOption {
  zipCode?: string;
}

export const AppreciationOptionLabel = ({ type, value, years, zipCode }: AppreciationOptionLabelProps) => {
  const intl = useIntl();

  const percent = useMemo(
    () => intl.formatNumber(value, { style: 'percent', maximumFractionDigits: 1, minimumFractionDigits: 1 }),
    [value],
  );

  if (type === AppreciationInputType.ZIP_CODE) {
    return (
      <>
        {intl.formatMessage(
          { id: 'property-details.calculator.zip-code-avg-appreciation' },
          { percent, years, zipCode },
        )}
      </>
    );
  }

  return <>{intl.formatMessage({ id: 'property-details.calculator.us-avg-appreciation' }, { percent, years })}</>;
};
