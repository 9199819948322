import { QueryKey } from '@tanstack/react-query';

import { QuestionnaireId, User } from '@arrived/models';

export const hydratedQuestionnaireKeyFn = (questionnaireId: QuestionnaireId): QueryKey => [
  'questionnaire',
  'hydrated',
  questionnaireId,
];

export const questionnaireInvestorResponsesKeyFn = (
  questionnaireId: QuestionnaireId,
  userCid: User['cid'],
): QueryKey => ['user', userCid, 'questionnaire', questionnaireId, 'investor-response'];
