import { useCallback, useState } from 'react';

import { BR, EM } from '@expo/html-elements';
import { ImageLoadEventData } from 'expo-image';
import parse, { DOMNode, Element, HTMLReactParserOptions, attributesToProps, domToReact } from 'html-react-parser';

import { BodyText, BodyTextProps, Image, ImageProps, Stack, TitleText, UtilityText, isWeb } from '@arrived/bricks';
import { stringToSnakecase } from '@arrived/common';
import { TextLink } from '@arrived/router';

import { Href } from '../markdown';

export const getTextChildren = (node: JSX.Element): string => {
  if (!node.props?.children) return '';
  if (typeof node.props.children === 'string') return node.props.children;
  else return getTextChildren(node.props.children);
};

const getTextChildrenNode = (node: DOMNode): string => {
  if (!node) {
    return '';
  }

  if (node.type === 'text') {
    return node.data;
  }

  if (node.type === 'tag') {
    return getTextChildrenNode(node.children[0] as DOMNode);
  } else {
    return '';
  }
};

const httpRegex = /(http(s?)):\/\//;

/**
 * Tests if a URL is a full URL with http(s)://
 */
export const hasHttpInUrl = (url: string) => httpRegex.test(url);

/**
 * This regex matches any standard URL starting with http:// or
 * https:// followed by any characters up to the next slash.
 */
const removeArrivedUrl = (url: string) => {
  if (url.includes('arrived.com') || url.includes('arrivedhomes.com')) {
    return url.replace(/^https?:\/\/[^/]+/, '');
  }

  return url;
};

interface ParserOptions {
  transform?: HTMLReactParserOptions['transform'];
  imageTransform?: (url: string) => string;
  pProps?: BodyTextProps;
}

const ArticleImage = (props: Omit<ImageProps, 'aspectRatio' | 'onLoad'>) => {
  // Likely, the image's aspect ratio is not 1, but we don't know it before the image renders, so
  // we'll start with 1 and update after.
  const [aspectRatio, setAspectRatio] = useState(1);

  // On load, we'll store the actual aspect ratio of the image and then re-render the image with
  // the correct aspect ratio corresponding to it's intrinsic dimensions.
  const onLoad = useCallback((args: ImageLoadEventData) => {
    if (args.source.height !== 0) {
      setAspectRatio(args.source.width / args.source.height);
    }
  }, []);

  return <Image {...props} aspectRatio={aspectRatio} onLoad={onLoad} />;
};

export const getOptions = (options?: ParserOptions): HTMLReactParserOptions => ({
  replace(fields) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let props = {} as any;

    if (fields instanceof Element && (fields as Element).attribs) {
      props = attributesToProps((fields as Element).attribs);
    }

    if (fields.type === 'tag') {
      // This needs to go first as there can be nested EM's in the text
      if (fields.name === 'em') {
        if (isWeb) {
          return <BodyText tag="em">{domToReact(fields.children as DOMNode[], this)}</BodyText>;
        }

        return <EM {...fields.attribs} {...props} />;
      }

      if (fields.name === 'p') {
        return (
          <BodyText tag="p" token="body.default.large" pb="$6" {...options?.pProps} {...props}>
            {domToReact(fields.children as DOMNode[], this)}
          </BodyText>
        );
      }

      if (
        fields.name === 'h1' ||
        fields.name === 'h2' ||
        fields.name === 'h3' ||
        fields.name === 'h4' ||
        fields.name === 'h5' ||
        fields.name === 'h6'
      ) {
        const children = fields.children as DOMNode[];
        const id = stringToSnakecase(getTextChildrenNode(children[0]) || '');

        const token = {
          h1: 'title.heading.xlarge',
          h2: 'title.heading.large',
          h3: 'title.heading.large.alt',
          h4: 'title.heading.medium',
          h5: 'title.heading.medium.alt',
          h6: 'title.heading.small',
        }[fields.name];

        return (
          <TitleText
            tag={fields.name}
            token={token}
            py="$2"
            id={id}
            $platform-web={{ scrollMarginTop: '80px' /* height of navbar */ }}
            {...props}
          >
            {domToReact(fields.children as DOMNode[], this)}
          </TitleText>
        );
      }

      // TODO: I would love to see us support being able to enlarge the image
      // The issue on mobile is that you just can't read anything, so having some sort of accessibility for these images
      // would be really helpful.
      if (fields.name === 'img') {
        const parsedImage = options?.imageTransform ? options.imageTransform(fields.attribs.src) : fields.attribs.src;

        return (
          <Stack
            asChild
            className="bricks-blog-image-entry"
            width="100%"
            height="auto"
            flex={1}
            borderRadius="$2"
            overflow="hidden"
          >
            {isWeb ? (
              <img alt={(props.alt as string) || ''} src={parsedImage} {...props} />
            ) : (
              <ArticleImage source={{ uri: parsedImage }} {...props} />
            )}
          </Stack>
        );
      }

      if (fields.name === 'a') {
        const utilityTextComponent = (
          <UtilityText token="utility.hyperlink.large">{domToReact(fields.children as DOMNode[], this)}</UtilityText>
        );

        if (isWeb) {
          return (
            <TextLink {...fields.attribs} {...props} href={fields.attribs.href}>
              {utilityTextComponent}
            </TextLink>
          );
        }

        // On native, we need to remove the beginning arrived domain in order for deep linking to work properly
        // other URLs can be left to use `Linking` from Solito
        const href = removeArrivedUrl(fields.attribs.href);

        // If we still have an http URL, we should use the Href component because Solito will not open it on native
        return hasHttpInUrl(href) ? (
          <Href href={href} {...fields.attribs}>
            {utilityTextComponent}
          </Href>
        ) : (
          <TextLink {...fields.attribs} {...props} href={href}>
            {utilityTextComponent}
          </TextLink>
        );
      }

      if (fields.name === 'br') {
        if (isWeb) {
          return;
        }

        return <BR {...fields.attribs} {...props} />;
      }

      if (fields.name === 'strong') {
        return (
          <BodyText tag="strong" token="body.default.large" fontWeight="$4" {...props}>
            {domToReact(fields.children as DOMNode[], this)}
          </BodyText>
        );
      }

      if (fields.name === 'ul') {
        return (
          <BodyText tag="ul" pl="$2" py="$2" className="blog-list" token="body.default.large" {...props}>
            {domToReact(fields.children as DOMNode[], this)}
          </BodyText>
        );
      }

      if (fields.name === 'ol') {
        return (
          <BodyText tag="ol" pl="$2" py="$2" className="blog-list" token="body.default.large" {...props}>
            {domToReact(fields.children as DOMNode[], this)}
          </BodyText>
        );
      }

      if (fields.name === 'li') {
        return (
          <BodyText tag="li" my="$2" className="blog-list-entry" token="body.default.large" {...props}>
            {domToReact(fields.children as DOMNode[], this)}
          </BodyText>
        );
      }

      if (fields.name === 'figure') {
        return (
          <Stack tag="figure" my="$4" mx={0} gap="$1" className="blog-figure" {...props}>
            {domToReact(fields.children as DOMNode[], this)}
          </Stack>
        );
      }

      if (fields.name === 'figcaption') {
        return (
          <BodyText tag="figcaption" token="body.default.medium" {...props}>
            {domToReact(fields.children as DOMNode[], this)}
          </BodyText>
        );
      }

      if (fields.name === 'iframe') {
        // TODO: Support iframes on native
        if (!isWeb) {
          return <Stack />;
        }

        // Automatic height: https://stackoverflow.com/questions/35814653/automatic-height-when-embedding-a-youtube-video
        // This should be the same as the `builder.io` component, but we are having circular dependencies between the packages
        return (
          <Stack position="relative" paddingBottom="56.25%" height={0} my="$4" mx={0}>
            <Stack
              tag="iframe"
              fullscreen
              width="100%"
              height="100%"
              style={{
                border: 'none',
              }}
              // @ts-expect-error: These are web-only properties. Native should handle this differently via rendering a native video player.
              title={fields.attribs.title}
              src={fields.attribs.src}
              allowFullScreen
            />
          </Stack>
        );
      }

      if (fields.name === 'u') {
        return <BodyText tag="u">{domToReact(fields.children as DOMNode[], this)}</BodyText>;
      }

      return (
        <BodyText tag="span" token="body.default.large" {...props}>
          {domToReact(fields.children as DOMNode[], this)}
        </BodyText>
      );
    }

    if (fields.type === 'text') {
      return fields.data;
    }
  },
  // This fixes the `strings need to be rendered within a <Text> component` error
  trim: !isWeb,
});

export const htmlParser = (content: string, options?: ParserOptions) => parse(content, { ...getOptions(options) });
