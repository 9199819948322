import { Stack } from '@arrived/bricks';

import { GenericBuilderIcons, RegisteredComponent } from '../../sdk';

interface VideoComponentProps {
  youtubeId?: string;
  title?: string;
}

export const VideoComponent = ({ title = 'youtube_embed', youtubeId }: VideoComponentProps) => {
  if (!youtubeId) {
    return null;
  }

  return (
    <Stack position="relative" paddingBottom="56.25%" height={0} my="$4" mx={0}>
      <Stack
        tag="iframe"
        fullscreen
        width="100%"
        height="100%"
        style={{
          border: 'none',
        }}
        // @ts-expect-error: These are web-only properties. Native should handle this differently via rendering a native video player.
        title={title}
        src={`https://www.youtube.com/embed/${youtubeId}?feature=oembed`}
        allowFullScreen
      />
    </Stack>
  );
};

VideoComponent.registerComponent = {
  component: VideoComponent,
  name: 'Bricks:Video',
  friendlyName: 'Video',
  image: GenericBuilderIcons.Video,
  inputs: [
    {
      name: 'youtubeId',
      type: 'text',
    },
    {
      name: 'title',
      type: 'text',
      defaultValue: 'youtube_embed',
    },
  ],
} satisfies RegisteredComponent;
