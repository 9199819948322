import { Rewards } from '@arrived/models';

import { rewardsApi } from '../api';
import { createQuery } from '../create-query';

export const getPromotion = (promotionUuid: string) =>
  createQuery<Rewards.Promotion>({
    apiInstance: rewardsApi,
    method: 'get',
    url: `/promotions/${promotionUuid}`,
  });

export const getPromotions = (filters?: Rewards.PromotionFilters) =>
  createQuery<Rewards.PromotionSearchResult>({
    apiInstance: rewardsApi,
    method: 'get',
    url: `/promotions`,
    config: {
      params: filters,
    },
  });

export const getPromotionUsage = (promotionUuid: string) =>
  createQuery<Rewards.PromotionUsage>({
    apiInstance: rewardsApi,
    method: 'get',
    url: `/promotions/${promotionUuid}/usage`,
  });

export const getPromotionCodes = (promotionUuid: string) =>
  createQuery<Rewards.PromotionCodesSearchResponse>({
    apiInstance: rewardsApi,
    method: 'get',
    url: `/promotions/${promotionUuid}/codes`,
  });

export const putPromotion = ({ uuid, ...requestData }: Rewards.PromotionUpdateRequest & { uuid: string }) =>
  createQuery<Rewards.Promotion>({
    apiInstance: rewardsApi,
    method: 'put',
    url: `/promotions/${uuid}`,
    requestData,
  });

export const postPromotion = (requestData: Rewards.PromotionCreationRequest) =>
  createQuery<Rewards.Promotion>({
    apiInstance: rewardsApi,
    method: 'post',
    url: `/promotions`,
    requestData,
  });
