import { Dispatch, SetStateAction, createContext } from 'react';

import { stub } from '@arrived/common';

export interface StepperLayoutContextType {
  progress: number;
  setProgress: Dispatch<SetStateAction<number>>;
  setIsStepperVisible: Dispatch<SetStateAction<boolean>>;
}

export const StepperLayoutContext = createContext<StepperLayoutContextType>({
  progress: 0,
  setProgress: stub,
  setIsStepperVisible: stub,
});
