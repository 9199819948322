import { GetProps, styled } from '@tamagui/core';

import { Stack } from '../../atoms';

export const ModalContent = styled(Stack, {
  name: 'ModalContent',
  borderRadius: '$2',
  backgroundColor: '$onSurface.neutral.defaultInverted',
  p: '$4',
  m: '$4',
  gap: '$4',
  overflow: 'hidden',

  style: { lineHeight: 0 },

  $gtXxs: {
    minWidth: 200,
    maxWidth: 400,
  },

  $gtMd: {
    minWidth: 300,
    maxWidth: 600,
  },
});

export type ModalContentProps = GetProps<typeof ModalContent>;
