import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function RulerInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="m7.083 18.333-5.416-5.416 11.25-11.25 5.416 5.416-11.25 11.25Zm3.125-13.958L12.5 6.667m-8.125 3.541L6.667 12.5m.625-5.208 2.291 2.291"
        stroke={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const RulerIcon = memo<IconProps>(withIconTheme(RulerInternal));
