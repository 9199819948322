import { forwardRef, useCallback } from 'react';

import { FormattedMessage } from 'react-intl';

import { useLocalStorage } from 'usehooks-ts';

import { Accordion, AnimateHeight, CaretDownIcon, Stack, StackProps, TitleText, UtilityText } from '@arrived/bricks';
import { Offering } from '@arrived/models';

import { StandardDisclaimer } from './StandardDisclaimer';

interface DisclaimerAccordionProps extends Omit<StackProps, 'children'> {
  offering?: Offering;
}

export const DisclaimerAccordion = forwardRef<HTMLDivElement, DisclaimerAccordionProps>(
  ({ offering, ...rest }, ref) => {
    const [isExpandedClientSide, setIsExpandedClientSide] = useLocalStorage('importantInfoAccordion-expanded', true);

    const syncToLocalStorage = useCallback(
      (value: string) => {
        setIsExpandedClientSide(value ? true : false);
      },
      [setIsExpandedClientSide],
    );

    return (
      <Accordion
        ref={ref}
        px="$2"
        collapsible
        type="single"
        defaultValue={isExpandedClientSide ? 'disclaimer' : undefined}
        onValueChange={syncToLocalStorage}
        {...rest}
      >
        <Accordion.Item value="disclaimer" borderWidth={0}>
          <Accordion.Trigger bg="transparent" borderWidth={0} borderColor="transparent" pl={0} pr={0}>
            {({ open }: { open: boolean }) => (
              <Stack row alignItems="center" justifyContent="space-between">
                <TitleText tag="h2" token="title.heading.small">
                  <FormattedMessage id="legal.important-information" />
                </TitleText>
                <Stack row animation="quick" transform={[{ rotateX: !open ? '0deg' : '180deg' }]}>
                  <CaretDownIcon size="lg" />
                </Stack>
              </Stack>
            )}
          </Accordion.Trigger>
          <AnimateHeight expand={true}>
            <Accordion.Content>
              <UtilityText token="utility.legal">
                <StandardDisclaimer offering={offering} />
              </UtilityText>
            </Accordion.Content>
          </AnimateHeight>
        </Accordion.Item>
      </Accordion>
    );
  },
);
