import { Fragment } from 'react';

import { Image } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { ikClient } from '@arrived/imagekit';
import { SFRFundData } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';

export type FundIPOSectionProps = Omit<ProductDetailContentProps, 'children'> & {
  ipoPerformance: NonNullable<SFRFundData['ipoPerformance']>;
};

export const FundIPOSection = ({
  ipoPerformance: { details, header, introText, images },
  ...rest
}: FundIPOSectionProps) => {
  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>{header}</ProductDetailContent.Header>
      {(introText || details || images.length >= 1) && (
        <ProductDetailContent.Body>
          {introText && <Markdown Text={ProductDetailContent.Text}>{introText}</Markdown>}
          {images.map(({ uri, aspectRatio, alt }, idx) => (
            <Fragment key={`${uri}-${idx}`}>
              <Image
                source={{ uri: ikClient.url({ path: uri, transformation: [{ width: '1300' }] }) }}
                aspectRatio={aspectRatio}
                alt={alt}
                accessibilityLabel={alt}
              />
            </Fragment>
          ))}
          {details && (
            <>
              {images.length >= 1 && <ProductDetailContent.Divider />}
              <Markdown Text={ProductDetailContent.Text}>{details}</Markdown>
            </>
          )}
        </ProductDetailContent.Body>
      )}
    </ProductDetailContent>
  );
};
