import { SubscriptionResponse, UserEmail } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const postNewPropertyNotificationsSubscription = (email: UserEmail) =>
  createQuery<SubscriptionResponse>({
    apiInstance: api,
    method: 'post',
    url: '/offerings/notifications/new-properties/subscribe',
    requestData: { email },
  });
