import { QueryKey } from '@tanstack/react-query';

import { ZipCode } from '@arrived/models';

export const zillowZipCodeAppreciationKey = (zipCode: ZipCode): QueryKey => [
  'zillow',
  'zip-code',
  zipCode,
  'appreciation',
];
