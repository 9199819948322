import { createTokens } from '@tamagui/core';

import { colors as color } from './colors';
import { radius } from './radius';
import { size } from './size';
import { space } from './space';
import { zIndex } from './zIndex';

export { gradients } from './gradients';
export { animations } from './animations';
export { shorthands } from './shorthands';

export const tokens = createTokens({ color, radius, zIndex, space, size });
