import { useCallback } from 'react';

import { useArrivedAuth0 } from '../ArrivedAuth0Provider';

export const useStepUpLogin = () => {
  const { authorize } = useArrivedAuth0();

  return useCallback(
    () =>
      authorize({
        scope: 'step-up',
        prompt: null,
      }),
    [authorize],
  );
};
