import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip, TooltipProps } from '@mui/material';

import { LabelTypography } from './LabelTypography';

export const LabelTooltip = ({ children, sx, ...rest }: TooltipProps) => (
  <Tooltip sx={{ cursor: 'pointer', ...sx }} {...rest}>
    {/*
    alignSelf="flex-start" and display="inline-flex" helps ensure that the component doesn't take up the full width of
    the container and misalign the floating tooltip.
    */}
    <Stack display="inline-flex" alignSelf="flex-start" alignItems="center" direction="row" gap={0.5}>
      <LabelTypography>{children}</LabelTypography>
      <InfoOutlined fontSize="small" sx={{ color: ({ palette }) => palette.neutrals.white }} />
    </Stack>
  </Tooltip>
);
