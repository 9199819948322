import { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { Tag as BricksTag, GetProps, styled } from '@arrived/bricks';
import { AssetType, Offering, OfferingType } from '@arrived/models';

import { ProductHeaderTagName } from './constants';

const ProductHeaderTag = styled(BricksTag, {
  name: ProductHeaderTagName,

  variant: 'category',
});

export const Tag = ProductHeaderTag.styleable<GetProps<typeof ProductHeaderTag> & { offering: Offering }>(
  ({ offering, ...rest }, ref) => {
    const tagContent = useMemo(() => {
      if (offering.type === OfferingType.FUND) {
        return <FormattedMessage id="common.fund" />;
      }

      switch (offering.assetType) {
        case AssetType.LTR:
          return <FormattedMessage id="common.single-family" />;
        case AssetType.STR:
          return <FormattedMessage id="common.vacation" />;
        case AssetType.STN:
          return <FormattedMessage id="common.short-term-note" />;
      }
    }, [offering.assetType, offering.type]);

    return (
      <ProductHeaderTag ref={ref} {...rest}>
        <BricksTag.Label>{tagContent}</BricksTag.Label>
      </ProductHeaderTag>
    );
  },
);
