import { createContext, useContext } from 'react';

import { UseFormStepperControllerReturn } from './useFormStepperController';

export const FormStepperContext = createContext({});
export const FormStepperContextProvider = FormStepperContext.Provider;
export const FormStepperContextConsumer = FormStepperContext.Consumer;

export const useFormStepperContext = <U extends Record<string, unknown>, B, T>() => {
  const context = useContext(FormStepperContext) as UseFormStepperControllerReturn<U, B, T>;

  if (!context) {
    throw new Error('useFormStepperContext must be used within a FormStepperContextProvider');
  }

  return context;
};
