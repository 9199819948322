/* eslint-disable react-hooks/rules-of-hooks */

import { FC, useMemo } from 'react';

import { getTokens, getVariable, getVariableValue, usePropsAndStyle, useTheme } from '@tamagui/core';

import { IconProps, IconPropsInternal } from './IconProps';

const defaultIconColor = 'onSurface.neutral.default';

/**
 * This adds a custom layer of theming to the icons that allow
 * us to use custom tokens for the color and size.
 */
export function withIconTheme<A extends FC<IconProps>>(Component: A) {
  const wrapped = (propsIn: IconPropsInternal) => {
    const [props, style] = usePropsAndStyle(propsIn);
    const theme = useTheme();

    const color = useMemo(() => {
      if (props.color && props.color in theme) {
        return getVariable(theme[props.color as keyof typeof theme]);
      }

      if (style.color) {
        return getVariable(style.color in theme ? theme[style.color as keyof typeof theme] : style.color);
      }

      if (props.color === 'currentColor') {
        return 'currentColor';
      }

      return getVariable(theme[defaultIconColor]);
    }, [theme, props.color, style.color]);

    const fill = useMemo(() => {
      if (!props.fill) {
        return 'none';
      }

      if (props.fill === 'none') {
        return 'none';
      }

      if (props.fill && props.fill in theme) {
        return getVariable(theme[props.fill as keyof typeof theme]);
      }

      if (style.fill) {
        return getVariable(style.fill in theme ? theme[style.fill as keyof typeof theme] : style.fill);
      }

      if (props.fill === 'currentColor') {
        return 'currentColor';
      }

      return getVariable(theme[defaultIconColor]);
    }, [theme, props.fill, style.fill]);

    const sizeTokens = getTokens().size;

    const size = useMemo(() => {
      let _size;
      if (typeof props.size === 'string') {
        _size = getVariableValue(sizeTokens[props.size as keyof typeof sizeTokens] || props.size);
      }

      if (props.size === 'xs') {
        _size = sizeTokens['$3'].val;
      }

      if (props.size === 'sm') {
        _size = sizeTokens['$4'].val;
      }

      if (props.size === 'md') {
        _size = sizeTokens['$5'].val;
      }

      if (props.size === 'lg') {
        _size = sizeTokens['$6'].val;
      }

      return _size ?? props.size ?? sizeTokens['$5'].val;
    }, [props.size, sizeTokens]);

    // @ts-ignore
    return <Component {...props} style={style} color={color} size={size} fill={fill} />;
  };

  return wrapped as unknown as A;
}
