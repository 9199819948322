import { Stack, TitleText, styled } from '@arrived/bricks';

import { HeaderName } from './constants';

const ProductDetailContentHeaderFrame = styled(Stack, {
  name: HeaderName,

  bg: '$onSurface.neutral.outlineAlt',
  px: '$4',
  py: '$6',

  $gtXxs: {
    px: '$6',
  },
});

export const Header = ProductDetailContentHeaderFrame.styleable(({ children, ...rest }, ref) => (
  <ProductDetailContentHeaderFrame ref={ref} {...rest}>
    <TitleText token="title.heading.medium">{children}</TitleText>
  </ProductDetailContentHeaderFrame>
));
