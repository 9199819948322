import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Callout, CalloutProps } from '@arrived/bricks';
import { Offering } from '@arrived/models';

export type MaxInvestmentCalloutProps = CalloutProps & { offering: Offering };

export const MaxInvestmentCallout = ({ offering, ...rest }: MaxInvestmentCalloutProps) => (
  <Callout hideCloseIcon variant="inverted" {...rest}>
    <Callout.Content>
      <Callout.Content.Text>
        <FormattedMessage
          id="product-details.max-investment-amount-disclaimer"
          values={{
            offeringName: offering.name,
            maxInvestment: (
              <FormattedNumber
                value={(offering.maxSharesPerAccount ?? 0) * offering.sharePrice}
                maximumFractionDigits={0}
                minimumFractionDigits={0}
                style="currency"
                currency="USD"
              />
            ),
          }}
        />
      </Callout.Content.Text>
    </Callout.Content>
  </Callout>
);
