/**
 * Default icons for the Builder.io SDK
 *
 * @folder https://builder.io/app/asset-library/0ed54da0c7e14d0ebd08a567e427cd68
 */
export const GenericBuilderIcons = {
  Action:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F181452fcbc92463e8de62d0f927d27fd',
  Basket:
    'https://cdn.builder.io/api/v1/image/assets%2Fd4fb2d0e6f954b31b60f6f0573e91b0c%2Fff2fd04bad384f30837b40bda8a062dc',
  ButtonPress:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  Calendar:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fcce3ab63c69e428dba9a914bb352ef47',
  CallToAction:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F9eb8bc3eef754db28d5158362de4f24d',
  Cart: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F18c33a1d1acb4754a491dfba034eafbf',
  CheckCircle:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Fdf2820ffed1f4349a94c40b3221f5b98',
  Command:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fd2faadbf33004df7ae50ac49a1f165b2',
  Dashboard:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Ff55ca826677343f4974badf098b7e732',
  DoubleColumn:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fba4df1020742405d9780c345d858f319',
  Download:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F644c9df9769e40e6b2ad89fb42c5c2f1',
  Expand:
    'https://cdn.builder.io/api/v1/image/assets%2Fe7eb284a1bc549c8856f32d1fc7a44cf%2Ff4a788e347d74c9f9483031f6ee395c6',
  Fingerprint:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fc75b200497644eecaf3338458d3f6b20',
  Hero: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F198bf62299284b2cba6e9434e5ad668b',
  HorizontalAdd:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F618530d61c6e47e9808d9bc91a9cf625',
  Info: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F58254565790848dab34233cf50fa63ad',
  Layer:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F6d8ada56b6bc487d834427560277ccbf',
  Lines: 'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Ff74a2f3de58c4c3e939204e5b6b8f6c3',
  ListThick:
    'https://cdn.builder.io/api/v1/image/assets%2FagZ9n5CUKRfbL9t6CaJOyVSK4Es2%2Ffab6c1fd3fe542408cbdec078bca7f35',
  ListThin:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F83acca093fb24aaf94dee136e9a4b045',
  MosaicLayout:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fd2122b32746f48c1afe36bb61aa66445',
  RightIndent:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Fad6f37889d9e40bbbbc72cdb5875d6ca',
  Settings:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Ff22a313c44944eb1a0435b331a4c6da8',
  ShoppingBag:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fbb49a6e4ffc6482291cfa4085ddb8d14',
  Slider:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F972b4b9346a24b86be1c01a64166283a',
  Slot: 'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F3aad6de36eae43b59b52c85190fdef56',
  Stack:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F8726e4f6bc6b45069859c3a36bf4c4d4',
  StaticExpand: 'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fa21e7dbe14b5404fb5ef1a4a2808fe7e',
  Switch:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F81e71bc50d3d4b3197284095e1ae225d',
  TableRow:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F91a6a1f1f42f42db82a9dac138dcba69',
  Testimonial:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F1f54c0974ebf4874ad15390963137060',
  Text: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F81784f0b4dd245af856112c2f995bd76',
  Touch:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F48b3ab2c7bf049ebb7f7b2edcae94f73',
  Transition:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F4c9485f71cd24fe7abb0bb57ed35afaa',
  TripleColumns:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fc8ca5abd5a51466bb24d731eaec11547',
  Upload:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Fef36d2a846134910b64b88e6d18c5ca5',
  VerticalAdd:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fe1f6dae0a7ae42dd8a86161e6e832b6f',
  Video:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fe1e37f1c5a8c4cd5a7af9553cd21e34c',
} as const;

/**
 * All builder icon endpoints. They are referenced from the Bricks icons we have currently.
 *
 * @folder https://builder.io/app/asset-library/219ed86ce5e94e61b81d51b5f5962e81
 * @docs https://www.figma.com/design/AuxJ1Yte5IW5FpSYSmSDeV/%F0%9F%94%A9-Icons-%26-Illustration?node-id=32-190&m=dev
 */
export const BricksBuilderIcons = {
  Account:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fcca697108b554ed99491958a4ed76f62',
  Activity:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F10e5d047ead140c5b9c84f2e0993151f',
  Add: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fecc0529aa2de4ebca4727d2222c29e99',
  AddAlt:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F518cc78987ca484ba6385e15d3dad016',
  Appreciation:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F8e23e964bfc54931b344b0141221e43b',
  ArrivedLogoWhite:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F40f6ae15be5e46bc8be71a7da5d8d5b9',
  ArrowDown:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F7188dad99d724cc08f5795d5dcc93f1e',
  ArrowLeft:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F6d32aed54f43417cbaf40abb38bced05',
  ArrowRight:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fdd4496913f6d4685bb3a9df3d81977b4',
  ArrowUpDown:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F9effaccb1cc649e2a159c3c391cb16a6',
  AssetFunds:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F17b0b7fac3264ff9b45dca62f0740d8e',
  Assets:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F9128fd0a141d409ab3d7ae6924ef8e65',
  AvailableUnlock:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F5f107905623a43d79bba1715eb6474f8',
  Bank: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fa6910cee863e41f28b59889ca4cf1836',
  Bath: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fba014379d9784c8ba67b29a7f544c3bd',
  Bed: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F2cc7af2eff5944bb9d1e4a1ae8564c24',
  BuildYear:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fc33c336472e548f7bbd1e383730c8eb9',
  BuyShares:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fd9ff507579334e2fa6f392944cf1dae2',
  CaretCircleDown:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fadeb36a722ba4461aea779b6a7d97502',
  CaretCircleUp:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fa5ef401ad15e41eea2136660a673a87f',
  CaretDown:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F33699416ade2429a905cb6cec000860e',
  CaretUp:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F1bf623774d95418a9ac1a51a89b0f950',
  Category:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F0ffc6354ed244a5a9371311f3d6fbcff',
  Checkmark:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F5905f7e8d85d401a9c466e2a53728e0d',
  CheckmarkAlt:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F12d9f91120704a9b9f64163d1ebab3ee',
  Close:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Ff00e6abe34b4453e92f965c452fc1d00',
  Coins:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fcd9039b2862c4ef0937c84b906adbcb7',
  Dimensions:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fc7b440b93642440b917c026ad90bc893',
  Diversify:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fa99cc7196d5a466a83b3bfd5e86fe668',
  DividendPayment:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F276d67caa0b64cea9e135783c82624a9',
  DividendPayments:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F276d67caa0b64cea9e135783c82624a9',
  Edit: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F52a5f96d86c345bb90b48d0f2296af2b',
  ExportDownload:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fac93a56eb7d249ed98532cd80da93030',
  Filter:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F40fe1e0311fb4c71b9c1881ac746bb3c',
  FilterAlt:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F0b431fee6e9e4febaf77158d096b5605',
  Help: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F3928e4e7acc346d69504fdab7c3dc82b',
  Hide: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F3d1ea4c00a31465bb07ee53070f2cfa2',
  HideAlt:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F465b4c59fe4a46438c543695756f9816',
  Hourglass:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F3478532cfb974dd0b6f2ab40f75f1237',
  Inbound:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F2390739f2ed84c0d85fb7e9f3cc7401a',
  Income:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F5622bd802aff44e2b809e9d0591123fa',
  Info: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F37c0b98ced994cd090df8b15d00c3e54',
  Invest:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fd5cfa4bed9f845a59a4c4173531c0f38',
  InvestmentSelection:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F3e2fca9490f84ad88a3d7e7314ebd22e',
  Key: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fc38e595554ed4d1e8d88af6f71d6fec6',
  Learn:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fb0fc7ef118a84355908b75bdc12b5ebf',
  Legal:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F3ffbcd7f1d3f4093b8186024795729c8',
  Lightbulb:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fbef83988557943a9b12fcce79ca5394e',
  Link: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F6d71b90c70de49e19ffcba966f155a75',
  LinkAlt:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F2b94c2b19aa1406e8097285f2927c920',
  Loading:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F19edaca76aa9489692ad464684fe3030',
  Logout:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F784687c95aaf480a8aeb1202488253bd',
  Map: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F811cee2d74d44d89881ccd16462a8411',
  Menu: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fd5700008c91642cc9dd9e4e36eb2476e',
  MoveMoney:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fdbe443902c854da086e2bb5be8592665',
  NewProperty:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fe4e6a6a1af65425bafd5c6b0b624a45d',
  Notification:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F9f11aa6276104b4ebee6671ca095571d',
  Outbound:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F8e9ad51200e0471f98708c0f9db8128d',
  Pause:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fef676d67a3b04c07a2357f7a99eb034e',
  RecurringInvestment:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F7b1914769a99412cb522d9648cd3bad7',
  Retire:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F58c09c45391442b8848d09328d3d552e',
  Search:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fd301dd2b7c934d31909941e212303eca',
  Share:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F2bd28588c8c043f3838045a9a6fc440f',
  Show: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F1be52a04ae094466887f2e458e15d432',
  SingleFamily:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fe1505164202841499ae8edfb21d169db',
  SingleFamilyAlt:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F5ed6d44ee4cf48629f8593d743c108a7',
  SortAscending:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F8ab6d81f50da4ac8b2cc6038b7a34905',
  SortDescending:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F4699db2395394478b81af358eb4d29e5',
  Spreadsheet:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fa5d79179d8964b27a3eb90e36d4877d8',
  Star: 'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fc01b9fe45b30422aa8100f12625caf0f',
  StatusCancelled:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F3ecdeda7a17847e2bd7d6aabe59e7d0b',
  StatusCreated:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F0713e0bae08d45449853d6863cae8c2d',
  StatusProcessing:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F48d4247d9dad4502a55293b652c26c4b',
  SubtractAlt:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fcea479391d314f69926148a6019caf94',
  Trending:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F7b8d09d9395b40c8952025bcc8efef93',
  VacationRental:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F9e79321d44924d2aa15222ca70470ae3',
  VacationRentalAlt:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F97c5041d50ed4f108c9c6f20063bd040',
  VerticalDots:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2Fdd22f61870894af39e9f098da20e6953',
  Warning:
    'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F2d6ac3a6adad4b58b78229445931b1c6',
} as const;
