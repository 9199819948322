import { useQuery } from '@tanstack/react-query';

import { getUserSsn } from '@arrived/api_v2';
import { UserId } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';

import { userSsnKeyFn } from './users.keys';

export function useGetUserSsnQuery(userId?: UserId, options?: UseAwaitedQueryOptions<typeof getUserSsn>) {
  return useQuery({
    queryKey: userSsnKeyFn(userId!),
    queryFn: () => getUserSsn(userId!),
    ...options,
    enabled: (options?.enabled ?? true) && userId != null,
  });
}
