import { QueryKey } from '@tanstack/react-query';

import { UserId } from '@arrived/models';

export const currentUserKeyFn = (): QueryKey => ['currentUser'];
export const userKeyFn = (userId: UserId): QueryKey => ['users', userId];
export const accountsPrimaryKeyFn = (userId: UserId): QueryKey =>
  (currentUserKeyFn() as readonly unknown[]).concat(userId ? [userId] : []).concat(['accounts', 'primary']);

export const userSsnKeyFn = (userId: UserId): QueryKey => userKeyFn(userId).concat(['ssn']);

export const userNetWorthKeyFn = (userId: UserId): QueryKey => userKeyFn(userId).concat(['net-worth']);

export const currentUserSardineSessionKeyFn = (): QueryKey => currentUserKeyFn().concat(['session-key']);
