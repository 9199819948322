import { useQuery } from '@tanstack/react-query';

import { getRewardsRedemption } from '@arrived/api_v2';

import { UseAwaitedQueryOptions } from '../utils';

import { promotionUsageKeyFn } from './rewards.keys';

export function useGetRewardsRedemptionQuery(
  rewardsRedemptionUuid?: string | null,
  options?: UseAwaitedQueryOptions<typeof getRewardsRedemption>,
) {
  return useQuery({
    queryKey: promotionUsageKeyFn(rewardsRedemptionUuid!),
    queryFn: () => getRewardsRedemption(rewardsRedemptionUuid!),
    ...options,
    enabled: (options?.enabled ?? true) && rewardsRedemptionUuid != null,
  });
}
