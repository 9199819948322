import { useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { DataPoint, Stack, UtilityText } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { useHasOfferingPaidDividend } from '@arrived/hooks';
import { LEASE_STATUS_MESSAGES_STRINGS, Offering, Property } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';

export type TenantLeasingSectionProps = ProductDetailContentProps & { offering: Offering };

export const TenantLeasingSection = ({ offering, ...rest }: TenantLeasingSectionProps) => {
  const intl = useIntl();
  const hasOfferingPaidDividend = useHasOfferingPaidDividend(offering.id);

  const property = useMemo((): Property | undefined => offering.properties[0], [offering.properties[0]]);

  const leaseStatus = useMemo(
    () => (property?.leaseStatus ? LEASE_STATUS_MESSAGES_STRINGS[property.leaseStatus] : undefined),
    [property?.leaseStatus],
  );

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.tenant-leasing-process" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body>
        <Markdown Text={ProductDetailContent.Text}>
          {intl.formatMessage({ id: 'product-details.tenant-leasing-process.description' })}
        </Markdown>
        {(offering.projectedFirstDividendDate || leaseStatus) && (
          <>
            <ProductDetailContent.Divider />
            <Stack row alignItems="flex-end" justifyContent="space-between" gap="$4">
              {leaseStatus && (
                <DataPoint
                  colors="dark"
                  variant="minimized"
                  tooltip={
                    leaseStatus.tooltip
                      ? {
                          content: <FormattedMessage id={leaseStatus.tooltip.id} />,
                          title: <FormattedMessage id="product-details.rental-status" />,
                        }
                      : undefined
                  }
                >
                  <DataPoint.Value>
                    <FormattedMessage id={leaseStatus.display.id} />
                  </DataPoint.Value>
                  <DataPoint.Label>
                    <FormattedMessage id="product-details.rental-status" />
                  </DataPoint.Label>
                </DataPoint>
              )}
              {!hasOfferingPaidDividend && (
                <UtilityText
                  flex={1}
                  maxWidth={250}
                  token="utility.helper.medium"
                  color="$onSurface.neutral.defaultAlt"
                  textAlign="right"
                >
                  <FormattedMessage id="product-details.dividend-eligibility-sfr" />
                </UtilityText>
              )}
            </Stack>
          </>
        )}
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};
