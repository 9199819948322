import { ReactNode } from 'react';

import { useIntl } from 'react-intl';

import { Box, BoxProps, TooltipProps as MuiTooltipProps, Tooltip, Typography, TypographyProps } from '@mui/material';

export interface ArrivedTooltipProps extends Omit<MuiTooltipProps, 'title' | 'children'> {
  /**
   * Content to be shown in the header of the tooltip; nothing is shown on web if not provided.
   */
  tooltipTitle?: ReactNode;
  /**
   * Content to be show inside the tooltip.
   */
  tooltipContent: ReactNode;
  /**
   * Prop overrides for the referenced content.
   */
  referencedTextPropOverrides?: TypographyProps;
  /**
   * Prop overrides for the referenced content's container.
   */
  referencedTextContainerPropOverrides?: BoxProps;
  children?: ReactNode;
}

/**
 * Custom wrapper around MUI's Tooltip component.
 */
export const ArrivedTooltip = ({
  children,
  placement,
  tooltipTitle,
  tooltipContent,
  referencedTextPropOverrides,
  referencedTextContainerPropOverrides,
  ...tooltipProps
}: ArrivedTooltipProps) => {
  const intl = useIntl();

  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      placement={placement || 'bottom-start'}
      {...tooltipProps}
      describeChild
      title={
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- deprecated, going to be moving to bricks
        (tooltipContent as any)?.props?.id ? (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- deprecated, going to be moving to bricks
          intl.formatMessage({ id: (tooltipContent as any)?.props?.id }, (tooltipContent as any)?.props?.values)
        ) : (
          <Box component="span" sx={{ pointerEvents: 'auto' }}>
            {tooltipTitle && (
              <Typography variant="body1.semibold" pb={2}>
                {tooltipTitle}
              </Typography>
            )}
            {tooltipContent}
          </Box>
        )
      }
    >
      <Box
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        component="span"
        {...referencedTextContainerPropOverrides}
      >
        <Typography
          mr={0.5}
          variant="body1"
          component="span"
          sx={{
            textDecoration: 'underline dashed',
            textUnderlineOffset: (theme) => theme.spacing(0.6),
          }}
          {...referencedTextPropOverrides}
        >
          {children}
        </Typography>
      </Box>
    </Tooltip>
  );
};
