import { memo, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { getActiveLoansCount } from '@arrived/common';

import { useProductWidgetContext } from './ProductWidgetContext';
import { useProductWidgetStyledContext } from './ProductWidgetStyledContext';

export const LoansDataPoint = memo(() => {
  const { offering } = useProductWidgetContext();
  const { condensed } = useProductWidgetStyledContext();
  const loanCount = useMemo(() => getActiveLoansCount(offering), [offering]);

  if (!offering.fixedRateInvestments || offering.fixedRateInvestments?.length === 0) return null;

  return (
    <DataPoint variant="minimized" colors="muted" alignment={condensed ? 'right' : 'left'}>
      <DataPoint.Value>{loanCount}</DataPoint.Value>
      <DataPoint.Label>
        <FormattedMessage id="product-details.loans" />
      </DataPoint.Label>
    </DataPoint>
  );
});
