export interface FixedRateInvestment {
  cid: string;
  yieldPercent: number | null;
  interestAccrualStartAt: string;
  salesforceId: string | null;
  matureAt: string;
  projectLoanToCost: number | null;
  projectLoanToValue: number | null;
  originator?: FixedRateInvestmentOriginatorType | null;
  originatorLoanId?: number | null;
  loanStrategy?: FixedRateInvestmentLoanStrategyType | null;
  instrumentType?: FixedRateInvestmentInstrumentTypeType | null;
  paidAt?: string | null;
  active?: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface FixedRateInvestmentRequest {
  cid: string;
  yieldPercent: number;
  interestAccrualStartAt: string;
  matureAt: string;
  salesforceId?: string | null;
  projectLoanToCost?: number | null;
  projectLoanToValue?: number | null;
  originator?: FixedRateInvestmentOriginatorType | null;
  originatorLoanId?: number | null;
  loanStrategy?: FixedRateInvestmentLoanStrategyType | null;
  instrumentType?: FixedRateInvestmentInstrumentTypeType | null;
  paidAt?: string | null;
  active?: boolean;
}

export const FixedRateInvestmentOriginator = {
  VONTIVE: 'VONTIVE',
} as const;

export const FixedRateInvestmentLoanStrategy = {
  FIX_AND_FLIP: 'FIX_AND_FLIP',
  TRANSITIONAL: 'TRANSITIONAL',
  REHAB: 'REHAB',
  BRIDGE: 'BRIDGE',
  NEW_CONSTRUCTION: 'NEW_CONSTRUCTION',
} as const;

export const FixedRateInvestmentInstrumentType = {
  MORTGAGE: 'MORTGAGE',
  NOTE_AND_DEED_OF_TRUST: 'NOTE_AND_DEED_OF_TRUST',
} as const;

export type FixedRateInvestmentOriginatorType =
  (typeof FixedRateInvestmentOriginator)[keyof typeof FixedRateInvestmentOriginator];
export type FixedRateInvestmentLoanStrategyType =
  (typeof FixedRateInvestmentLoanStrategy)[keyof typeof FixedRateInvestmentLoanStrategy];
export type FixedRateInvestmentInstrumentTypeType =
  (typeof FixedRateInvestmentInstrumentType)[keyof typeof FixedRateInvestmentInstrumentType];
