import {
  CreateOfferingTransactionRequest,
  OfferingTransaction,
  OfferingTransactionFundStatus,
  OfferingTransactionId,
  OfferingTransactionQueryParams,
  PatchOfferingTransactionRequest,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const postCreateOfferingTransaction = (request: CreateOfferingTransactionRequest) =>
  createQuery<OfferingTransaction>({
    apiInstance: api,
    method: 'post',
    url: '/trades',
    requestData: request,
  });

export const getOfferingTransaction = (
  offeringTransactionId: OfferingTransactionId | string,
  queryParams?: OfferingTransactionQueryParams,
) =>
  createQuery<OfferingTransaction>({
    apiInstance: api,
    method: 'get',
    url: `/trades/${offeringTransactionId}`,
    config: { params: queryParams },
  });

export const patchOfferingTransaction = ({
  id: offeringTransactionId,
  ...request
}: {
  id: OfferingTransactionId;
} & PatchOfferingTransactionRequest) =>
  createQuery<OfferingTransaction>({
    apiInstance: api,
    method: 'patch',
    url: `/trades/${offeringTransactionId}`,
    requestData: request,
  });

export const putNCSyncOfferingTransaction = (offeringTransactionId: OfferingTransactionId) =>
  createQuery<OfferingTransaction>({
    apiInstance: api,
    method: 'put',
    url: `/trades/${offeringTransactionId}/sync`,
  });

export const deleteCancelOfferingTransaction = (offeringTransactionId: OfferingTransactionId) =>
  createQuery<OfferingTransaction>({
    apiInstance: api,
    method: 'delete',
    url: `/trades/${offeringTransactionId}`,
  });

export const postSubmitOfferingTransaction = (offeringTransactionId: OfferingTransactionId) =>
  createQuery<OfferingTransaction>({
    apiInstance: api,
    method: 'post',
    url: `/trades/${offeringTransactionId}/submit`,
  });

export const postInitiateOfferingTransactionFundsMovement = (offeringTransactionId: OfferingTransactionId) =>
  createQuery<OfferingTransaction>({
    apiInstance: api,
    method: 'post',
    url: `/trades/${offeringTransactionId}/funds/send`,
  });

export const deleteCancelOfferingTransactionFundsMovement = (offeringTransactionId: OfferingTransactionId) =>
  createQuery<OfferingTransaction>({
    apiInstance: api,
    method: 'delete',
    url: `/trades/${offeringTransactionId}/funds/cancel`,
  });

export const getTradeFundStatuses = (offeringTransactionId: OfferingTransactionId) =>
  createQuery<OfferingTransactionFundStatus[]>({
    apiInstance: api,
    method: 'get',
    url: `/trades/${offeringTransactionId}/fund-statuses`,
  });
