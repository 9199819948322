export default {
  'checkout.title': 'Buy',
  'checkout.amount.title': 'Desired Investment Amount',
  'checkout.amount.label.purchaseAmount.tooltip':
    'The maximum you can invest per property is 9.8% of the equity available. For example, if the property has $100,000 available to invest in, then you could invest up to $9,800.',
  'checkout.amount.label.howMuch.description.large': 'Pick a preset or enter a custom amount you wish to buy. ',
  'checkout.amount.label.howMuch.description': 'You can invest up to {monetaryAmount} in {offeringName}. ',
  'checkout.amount.label.howMuch.description.monthLimit':
    'You can invest up to {monetaryAmount}{hasUsedMonthlyLimit, select, true {. You’ve invested {monetaryUsedMonthlyAmount} of the {monetaryMonthlyLimit} limit this month.} other { this month.}}',
  'checkout.amount.label.howMuch.description.rounding':
    'We’ll round to the closest number of whole shares given the asset’s pricing and availability.',
  'checkout.amount.validation.minimumAmount': 'A minimum of {minTransactionAmount} is required.',
  'checkout.amount.validation.minimum.shares':
    'Minimum of {shareCountFormatted} ({shareValue}) {shareCount, plural, one {share} other {shares}} is required.',
  'checkout.amount.validation.minimum.notes':
    'Minimum of {shareCountFormatted} ({shareValue}) {shareCount, plural, one {note} other {notes}} is required.',
  'checkout.amount.validation.maximum': 'Please specify an amount below the amount available.',
  'checkout.amount.validation.multiple.shares': 'Shares cannot be partially purchased. Price per share is {price}.',
  'checkout.amount.validation.multiple.notes': 'Notes cannot be partially purchased. Price per note is {price}.',
  'checkout.amount.validation.required': 'Please specify a purchase amount.',
  'checkout.amount.shareCount': '{amount} {shareCount, plural, one {Share} other {Shares}} of {name}',
  'checkout.amount.noteCount': '{amount} {shareCount, plural, one {Note} other {Notes}} of {name}',
  'checkout.amount.noSharesAvailable': 'There are no remaining shares available for you in {name}.',
  'checkout.amount.availableEquity.large': 'Current valuation at {perUnit}/{isStn, select, true {note} other {share}}',
  'checkout.amount.availableEquity':
    '{total} available at {perUnit}/{isStn, select, true {note} other {share}}{isMonthlyLimit, select, true { for {month}} other {}}',
  'checkout.amount.insufficient-equity-remaining':
    'Requested share count exceeds the remaining in the Offering. Please select a lower amount and try again.',
  'checkout.quickButton.label.shares': '{amount} {shareCount, plural, one {Share} other {Shares}}',

  'checkout.quickButton.label.notes': '{amount} {shareCount, plural, one {Note} other {Notes}}',
  'checkout.duplicateTrade.notice':
    'Existing transaction found for this Offering. Please confirm or cancel your current transaction before initiating another one for this investment.',
  'checkout.account-block.error-message':
    'An error occurred with your transaction, please contact us at {supportEmail}.',

  'checkout.update-ssn.update-your-information': 'Update your information',
  'checkout.update-ssn.update-your-information.description':
    'We are missing key pieces of information from you for verification purposes. Without this information, we cannot process your trade.',

  'checkout.payment.paymentDetails': 'Payment Details',
  'checkout.payment.walletOption.label': 'Cash Balance',
  'checkout.payment.walletOption.balanceDescription': '{amount} Available',
  'checkout.payment.useAvailableCashBalance': 'Use available cash balance | {cashBalance}',
  'checkout.payment.availableCashBalance.helperText': 'of {amount}',
  'checkout.payment.promotionVoucherLabel': 'Do you have a promo code or a voucher?',
  'checkout.payment.promotionVoucherLabel.applied':
    'A promotion or voucher has been applied. Check the purchase summary below to review applied discounts.',
  'checkout.payment.promotionVoucherButton.update': 'Update',
  'checkout.payment.promotionVoucherButton.add': 'Add',
  'checkout.payment.insufficientCashAccount':
    'Your cash balance doesn’t have enough funds to cover the entire transaction, so we will automatically take the remaining funds needed from your linked bank account.',
  'checkout.voucherAndPromotion.promotion.label': 'Promo Code',
  'checkout.voucherAndPromotion.promotion.error.invalid': 'The provided promotion code is not valid.',
  'checkout.voucherAndPromotion.promotion.error.noAccount': 'No account was found to apply a promotion to.',
  'checkout.voucherAndPromotion.vouchers.label': 'Vouchers',
  'checkout.voucherAndPromotion.vouchers.description': 'Select up to 10 vouchers to apply to your purchase.',
  'checkout.voucherAndPromotion.vouchers.error.stackingPromotion':
    'Some selected vouchers are not stackable with a promotion.',
  'checkout.voucherAndPromotion.vouchers.error.stackingVoucher':
    'Some selected vouchers are not stackable with other vouchers.',
  'checkout.voucherAndPromotion.vouchers.error.notRedeemable':
    'One or more selected vouchers are not in a redeemable state.',
  'checkout.voucherAndPromotion.vouchers.error.exceed':
    'The selected vouchers cannot total to exceed the trade total ({amount}).',
  'checkout.voucherAndPromotion.button': 'Apply',
  'checkout.header.cancel': 'Cancel',
  'checkout.sign.signLabel': 'Please acknowledge and sign',
  'checkout.sign.circularAgreement':
    'I read, understand, and approve the information laid out in the {subscriptionAgreement}, {offeringCircular}, {formW9} and any supplements therein.',
  'checkout.sign.stnAgreement':
    'I read, understand, and approve the information laid out in the {subscriptionAgreement}, {notePurchaseAgreement}, {privatePlacementMemorandum}, {formW9} and any supplements therein.',
  'checkout.sign.circularAgreement.offeringCircular': 'Offering Circular',
  'checkout.sign.circularAgreement.subscriptionAgreement': 'Subscription Agreement',
  'checkout.sign.circularAgreement.formW9': 'Form W-9',
  'checkout.sign.faq': 'FAQ',
  'checkout.sign.circularAgreement.notePurchaseAgreement': 'Note Purchase Agreement',
  'checkout.sign.circularAgreement.privatePlacementMemorandum': 'Private Placement Memorandum',
  'checkout.sign.circularAgreement.checkboxLabel': 'I have read and approve',
  'checkout.sign.holdingAgreement':
    'I am investing with the intention of holding my securities for the target investment period, and that Arrived will <strong>not offer refunds</strong> on my investment outside of the 24 hour cancellation window. To learn more about liquidity, check out this {FAQComponent}.',
  'checkout.sign.minimumHolderPeriodAgreement':
    'I understand that I am investing with the intention of holding my securities for the full investment period and am required to hold my securities for a minimum of 12 months, or until maturity if the term is less than 12 months.',
  'checkout.sign.holdingAgreement.checkboxLabel': 'I understand',
  'checkout.sign.initials.label': 'Your first and last name initials ({initials})',
  'checkout.sign.initials.label.tooltip': 'For example, if your name is John Doe, then your initials would be JD.',
  'checkout.sign.initials.placeholder': 'Initials',
  'checkout.sign.allFields.error.required': 'Please fill out all remaining fields.',
  'checkout.sign.initials.error.required': 'Initials are required.',
  'checkout.sign.initials.error.mismatch': 'Initials must match in capitalization and text "{initials}".',
  'checkout.sign.overallAgreement': `By clicking “Confirm Order” button: I adopt the above electronic initials as my signature, and hereby electronically sign the documents listed above. l acknowledge that I have accessed, have read and hereby agree to the Arrived Terms of Service and that I authorize the Arrived services, in the manner designated therein, to process the documents and signatures provided herewith and to create, store, and communicate electronic records of documents listed above.`,
  'checkout.sign.error.allFieldsRequired': 'You must select all required fields to buy shares.',
  'checkout.success.returnButton': 'Return to Invest',
  'checkout.success.mainText': '{units} purchase for {name} complete!',
  'checkout.success.subText':
    'Your {units} have been purchased for {name}!\nYour trade request has been received, and is in progress. Congratulations!',
  'checkout.success.timeline.subText': 'Congratulations! Your investment in the {name} is complete.',
  'checkout.success.timeline.heading.equity': "You're on your way to your first expected dividends!",
  'checkout.success.timeline.heading.funds': '{date} - mark the calendar for your first expected dividends!',
  'checkout.success.timeline.heading.stn': "You're on your way to earning interest income!",
  'checkout.success.timeline.heading.subheading': "Here's a look at what's next in the meantime",
  'checkout.success.timeline.today': 'Today',
  'checkout.success.timeline.orderConfirmed': 'Order Confirmed',
  'checkout.success.timeline.orderInitiated': 'Order Initiated',
  'checkout.success.timeline.orderAmount': '{shareCount} {unit} @ ${price} each',
  'checkout.success.timeline.nextWeek': 'By Next Week',
  'checkout.success.timeline.tradeProcessed': 'Trade processed',
  'checkout.success.timeline.tradeProcessedDescription':
    'You can expect the money to move from your bank account in the next 3-5 business days',
  'checkout.success.timeline.monthsApproximation': 'In about {monthsNumber} months',
  'checkout.success.timeline.months': 'In {monthsNumber} months',
  'checkout.success.timeline.onMaturityDate': 'On {maturityDate}',
  'checkout.success.timeline.monthlyDividendsPaid': 'Monthly dividends paid',
  'checkout.success.timeline.monthlyDividendsPaid.description':
    'Dividends are paid monthly, beginning shortly after the property is rented.',
  'checkout.success.timeline.expectedFundsDividendDate': 'Monthly beginning on or around {date} for this investment',
  'checkout.success.timeline.expectedDividendsPaid': 'Expected Dividends Paid',
  'checkout.success.timeline.onceRented': 'Once property is rented',
  'checkout.success.timeline.onceBookingReady': 'Once property is booking ready',
  'checkout.success.timeline.onceBookingReady.description':
    'Dividends are paid monthly, beginning shortly after the property begins accepting reservations.',
  'checkout.success.timeline.noteMatures': 'Note matures',
  'checkout.success.timeline.whenNoteMatures': 'When Note Matures',
  'checkout.success.timeline.noteMaturesDescription':
    'The principal and accrued interest will be paid out on the maturity date.',
  'checkout.success.timeline.tradeSettles': 'Trade settles',
  'checkout.success.timeline.tradeFunded': 'Trade is funded',
  'checkout.success.timeline.whatsNext': "What's next?",
  'checkout.success.timeline.signDocuments': 'Sign Documents',
  'checkout.success.timeline.signDocumentsDescription':
    'We’ll hold your shares for an hour while you sign trade documents (below).',
  'checkout.success.button.viewInYourPortfolio': 'View in your portfolio',
  'checkout.success.button.continue': 'Continue',
  'checkout.summary.edit-button.label': 'Edit',
  'checkout.summary.items.label': 'Items',
  'checkout.summary.item':
    '{units} {isShare, select, true {{shareCount, plural, one {share} other {shares}}} other {{shareCount, plural, one {note} other {notes}}}} at {sharePrice}/{isShare, select, true {share} other {note}}',
  'checkout.summary.breakdown.totalAmount.label': 'Total Amount',
  'checkout.summary.breakdown.investmentSummary.label': 'Investment Summary',
  'checkout.summary.breakdown.investmentSummary.wallet.label': 'Arrived - Cash Balance',
  'checkout.summary.breakdown.investmentSummary.vouchers.label': 'Vouchers ({amount})',
  'checkout.summary.breakdown.investmentSummary.promotion.label': 'Promo Code - {promoCode}',
  'checkout.summary.investmentSummaryButtonDescription': 'Show investment summary',
  'checkout.summary.legalDisclaimerButtonDescription': 'Show legal disclaimer',
  'checkout.summary.legalDisclaimerCloseButtonDescription': 'Close legal disclaimer',
  'checkout.summary.breakdown.paymentMethod.label': 'Payment Method',
  'checkout.summary.secureSharesAgreement': `By clicking “Secure Investment” we’ll hold your {isStn, select, true {notes} other {shares}} for an hour while you sign trade documents.`,
  'checkout.summary.action.review': 'Review Investment',
  'checkout.summary.action.continue': 'Continue',
  'checkout.summary.action.secureInvestment': 'Secure Investment',
  'checkout.summary.action.confirmOrder': 'Confirm Order',
  'checkout.summary.unknownBank': 'Linked Bank',
  'checkout.summary.info.maxAmountReset': 'The limit is {formattedMonetaryLimit}. The amount has been updated.',
  'checkout.diversify.private-credit-fund.main-text':
    'Explore our {name}{hasYield, select, true {, currently paying {yield}!} other {}}',
  'checkout.diversify.sfr-fund.main-text': 'Balance your portfolio with {name}',
  'checkout.diversify.private-credit-fund.sub-text':
    'Diversified investors see higher returns on average. Keep up your momentum by investing in our {name}.',
  'checkout.diversify.sfr-fund.sub-text':
    'A balanced blend of future equity appreciation & current dividends. Plus, a 6 month minimum hold period.',
  'checkout.diversify.invest': 'Invest Now',
} as const;
