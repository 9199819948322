import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function InfoIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 8.75v5M10 7.667V6"
        stroke={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const InfoIcon = memo<IconProps>(withIconTheme(InfoIconInternal));
