import { CommonQuestionsConfig, CommonQuestionsKeys } from '../components/commonQuestions/config';

const commonQuestionsKeys = [
  CommonQuestionsKeys.HOW_ARRIVED_WORKS,
  CommonQuestionsKeys.EXPECTED_RETURNS,
  CommonQuestionsKeys.SEEKING_TENANT,
  CommonQuestionsKeys.DIVERSIFICATION,
  CommonQuestionsKeys.INVESTING_HORIZON,
];

export const commonQuestions = commonQuestionsKeys.map((key) => CommonQuestionsConfig[key]);
