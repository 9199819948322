export const ProductDetailsView = {
  DETAILS: 'DETAILS',
  FINANCIALS: 'FINANCIALS',
  PERFORMANCE: 'PERFORMANCE',
  PROPERTIES: 'PROPERTIES',
  OFFERING: 'OFFERING',
  INTRO_TO_DEBT: 'INTRO_TO_DEBT',
} as const;

export type ProductDetailsView = (typeof ProductDetailsView)[keyof typeof ProductDetailsView];
