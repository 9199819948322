import { cashAccountInternalApi, createQuery } from '@arrived/api_v2';

import { Counterparty, CounterpartyLockReason, CounterpartyType } from '../types/cash-accounts';

interface CounterPartiesParams {
  entityId: string;
  counterpartyType: CounterpartyType;
  includeCounterpartyDetails?: boolean;
  includeUserAuthorizationLevel?: boolean;
  includeExternalBankAccounts?: boolean;
}

interface CounterpartyLockRequest {
  counterpartyCid: string;
  reason: CounterpartyLockReason;
  internalNotes?: string;
}

interface CounterpartyUnlockRequest {
  counterpartyCid: string;
}

export const adminGetCounterparty = (counterpartyCid: string, options?: CounterPartiesParams) =>
  createQuery<Counterparty>({
    apiInstance: cashAccountInternalApi,
    method: 'get',
    url: `/counterparties/${counterpartyCid}`,
    config: { params: options },
  });

export const adminPostLockCounterparty = (requestData: CounterpartyLockRequest) =>
  createQuery<Counterparty>({
    apiInstance: cashAccountInternalApi,
    method: 'post',
    url: `/counterparties/${requestData.counterpartyCid}/lock`,
    requestData: { reason: requestData.reason, internalNotes: requestData.internalNotes },
  });

export const adminPostUnlockCounterparty = (requestData: CounterpartyUnlockRequest) =>
  createQuery<Counterparty>({
    apiInstance: cashAccountInternalApi,
    method: 'post',
    url: `/counterparties/${requestData.counterpartyCid}/unlock`,
  });
