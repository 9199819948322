import { useCallback, useMemo } from 'react';

import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import { format, isAfter, isBefore } from 'date-fns';

import {
  DataPoint,
  Divider,
  Stack,
  StackProps,
  TitleText,
  UtilityText,
  styled,
  tokens,
  useMedia,
} from '@arrived/bricks';
import { BricksChart, useChartsTheme, useFormatTickLabel } from '@arrived/bricks-charts';
import { Markdown } from '@arrived/bricks-common';
import { enUS } from '@arrived/common';
import { AirDnaApiResponse, PercentileOptions } from '@arrived/models';

type ChartProps = Omit<StackProps, 'children'> & {
  chartData: AirDnaApiResponse;
  percentile: PercentileOptions;
  numberType: 'percent' | 'currency';
  tooltipLabel: keyof typeof enUS;
  title: keyof typeof enUS;
};

interface ChartDatum {
  date: number;
  value: number;
  totalRevenue?: number;
}

interface ChartConfig {
  data: ChartDatum[];
  average: number;
  min: number;
  max: number;
  minDate?: number;
  maxDate?: number;
}

interface ChartConfigIterator {
  min: number;
  max: number;
  minDate: number;
  maxDate: number;
  total: number;
}

const DisclaimerText = styled(UtilityText, {
  token: 'utility.legal',
  color: '$onSurface.neutral.muted',
});

const DataPointValue = ({ numberType, value }: { numberType: 'percent' | 'currency'; value: number }) =>
  numberType === 'currency' ? (
    <FormattedNumber value={value} style="currency" currency="USD" maximumFractionDigits={0} />
  ) : (
    <FormattedNumber value={value} style="percent" />
  );

export const VacationRentalPerformanceChart = Stack.styleable<ChartProps>(
  ({ chartData, percentile, numberType, title, tooltipLabel, ...rest }, ref) => {
    const intl = useIntl();
    const { formatDateLabel, formatPercentLabel } = useFormatTickLabel();

    const formatCurrencyLabel = useCallback(
      (value?: number) => {
        if (typeof value !== 'undefined') {
          return intl.formatNumber(value, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
            notation: 'compact',
            maximumSignificantDigits: 3,
          });
        }

        return '';
      },
      [intl],
    );

    const { axisStyles, lineStyles } = useChartsTheme();

    const media = useMedia();

    const chartConfig: ChartConfig = useMemo(() => {
      // Rebuild the chartData to be only the data we need to render the
      //   chart by: Creating a date based on year and month values
      //   Pulling just the 75th percentile value out Optionally storing
      //   totalRevenue value Calculate average by keeping a running
      // total and dividing by array length Get min value and date by
      // setting value per iteration Get max value and date by setting
      // value per iteration
      const formattedData: ChartDatum[] = [];

      const { min, max, minDate, maxDate, total } = chartData
        ? chartData.reduce((curr, { month, year, percentiles, total_revenue }): ChartConfigIterator => {
            // Convert month and year to a date
            const date = new Date(year, month - 1);
            const dateTime = date.getTime();
            const percentileValue = percentiles[percentile];

            formattedData.push({
              date: dateTime,
              value: percentileValue,
              ...(total_revenue ? { totalRevenue: total_revenue } : {}),
            });

            return {
              min: curr.min == null || percentileValue < curr.min ? percentileValue : curr.min,
              max: curr.max == null || percentileValue > curr.max ? percentileValue : curr.max,
              minDate: curr.minDate == null || isBefore(dateTime, curr.minDate) ? dateTime : curr.minDate,
              maxDate: curr.maxDate == null || isAfter(dateTime, curr.maxDate) ? dateTime : curr.maxDate,
              total: curr.total ? curr.total + percentileValue : percentileValue,
            };
          }, {} as ChartConfigIterator)
        : ({} as ChartConfigIterator);

      return {
        min,
        minDate,
        max,
        maxDate,
        data: formattedData,
        average: chartData ? total / chartData.length : 0,
      };
    }, [chartData, percentile]);

    const tooltipFormat = useCallback(
      ({ x, y }: { x: number; y: number }) => {
        const formattedDate = format(new Date(x), 'MMMM yyyy');
        const formattedValue = intl.formatNumber(
          y,
          numberType === 'percent' ? { style: 'percent' } : { style: 'currency', currency: 'USD' },
        );

        return `(${formattedDate}) ${intl.formatMessage({ id: tooltipLabel }, { value: formattedValue })}`;
      },
      [intl, numberType, tooltipLabel],
    );

    const lineData = useMemo(
      () =>
        chartConfig.data.map(({ date, value }) => ({
          x: date,
          y: value,
        })),
      [chartConfig.data],
    );

    return (
      <Stack width="100%" gap="$3" ref={ref} {...rest}>
        <TitleText token="title.heading.small">
          <FormattedMessage id={title} />
        </TitleText>
        <Stack
          bg="$onSurface.neutral.zebra"
          p="$6"
          br="$0.5"
          borderWidth="$0.25"
          borderColor="$onSurface.neutral.outlineAlt"
          gap="$6"
        >
          <Stack justifyContent="space-between" row>
            <DataPoint colors="dark">
              <DataPoint.Value>
                <DataPointValue value={chartConfig.average} numberType={numberType} />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage id="chart.average" />
              </DataPoint.Label>
              <DataPoint.Pill bg="$onSurface.primary.decorative" />
            </DataPoint>
            <Stack alignItems="center" row gap="$6">
              <DataPoint alignment="right" colors="dark" variant="minimized">
                <DataPoint.Value>
                  <DataPointValue value={chartConfig.min} numberType={numberType} />
                </DataPoint.Value>
                <DataPoint.Label>
                  <FormattedMessage id="chart.min" />
                </DataPoint.Label>
              </DataPoint>
              <Divider.Vertical h="$10" solid />
              <DataPoint alignment="right" colors="dark" variant="minimized">
                <DataPoint.Value>
                  <DataPointValue value={chartConfig.max} numberType={numberType} />
                </DataPoint.Value>
                <DataPoint.Label>
                  <FormattedMessage id="chart.max" />
                </DataPoint.Label>
              </DataPoint>
            </Stack>
          </Stack>
          <Stack
            bg="$onSurface.neutral.zebraAlt"
            br="$1"
            borderWidth="$0.25"
            borderColor="$onSurface.neutral.outlineAlt"
            overflow="hidden"
            height={350}
          >
            <BricksChart
              bg="$onSurface.neutral.defaultInverted"
              tooltipFormat={tooltipFormat}
              bblr="$1"
              bbrr="$1"
              chartPaddingProps={{ left: tokens.space['12'].val, bottom: tokens.space['8'].val }}
            >
              <BricksChart.Axis
                name="x-axis"
                style={axisStyles.default.x}
                tickFormat={formatDateLabel}
                tickCount={media.gtXxs ? 7 : 3}
              />
              <BricksChart.Axis
                name="y-axis"
                style={axisStyles.default.y}
                tickFormat={numberType === 'currency' ? formatCurrencyLabel : formatPercentLabel}
                dependentAxis
                domain={numberType === 'percent' ? [0, 1] : undefined}
              />
              <BricksChart.Line data={lineData} style={lineStyles['primary']} />
            </BricksChart>
            <Stack p="$3" centered>
              <Markdown Text={DisclaimerText}>{intl.formatMessage({ id: 'airdna.chart.link.data-provided' })}</Markdown>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);
