import { createContext, useContext } from 'react';

import { stub } from '@arrived/common';

export interface NavigationBarContextType {
  offset: number;
  setIsFixed: (isFixed: boolean) => void;
  transition: string;
}

// This is the default transition for the Slide component, we define it here so it can be used on other attributes
// (like top instead of transform) to transition components below the nav bar in the DOM.
export const navigationBarTransition = '225ms cubic-bezier(0, 0, 0.2, 1) 0ms';

export const NavigationBarContext = createContext<NavigationBarContextType>({
  offset: 0,
  setIsFixed: stub,
  transition: navigationBarTransition,
});

export const useNavigationBarContext = () => {
  const context = useContext(NavigationBarContext);

  if (!context) {
    throw new Error('useNavigationBarContext must be used within a NavigationBarProvider');
  }

  return context;
};
