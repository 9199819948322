import { LinearGradientPropsFill, createNativeGradient, createWebGradient } from '../utilities';

import { colors } from './colors';

export const gradientTokens = {
  /**
   * @name Sea
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */
  sea: {
    colors: [colors.aqua[500], colors.mint[500]],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name Dawn
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */
  dawn: {
    colors: [colors.coral[500], colors.tangerine[500]],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name Sky
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */
  sky: {
    colors: [colors.aqua[500], colors.aqua[200]],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name Blush
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */
  blush: {
    colors: [colors.coral[500], colors.coral[200]],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name Mist
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */
  mist: {
    colors: [colors.mint[500], colors.mint[200]],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name Juice
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */
  juice: {
    colors: [colors.tangerine[500], colors.tangerine[200]],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name Shades
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */
  shades: {
    colors: [colors.darkened.midnight, colors.darkened.steel],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name Steam
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */
  steam: {
    colors: [colors.neutrals.milk, colors.neutrals.white],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name Mercury
   * @link https://www.figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=dFpcgB2nuPKQwC20-4
   */
  mercury: {
    colors: [colors.neutrals.platinum, colors.neutrals.white],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name Yin
   */
  yin: {
    colors: ['rgba(12,19,26,0.9)', 'rgba(29,45,61,0.9)'],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name NewLight
   */
  newLight: {
    colors: ['#F2F2F4', '#fff', colors.newPrimary[100]],
    locations: [0, 0.5, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name AquaMint
   * @link figma.com/file/DqzLlYE7owyaMHf7tuzYqB/Arrived-Design-System?node-id=39%3A1791&t=uteLeKncm5rL9Nlp-0
   */
  aquaMint: {
    colors: [colors.aqua[300], colors.mint[300]],
    locations: [0, 1],
    useAngle: true,
    angle: 90,
  },

  /**
   * @name AquaMintLight
   * @link figma.com/file/imzmMV5bgsW0V0Vz8gr7x7/Public-App?node-id=1301%3A21125&t=yusHH70AgNlIEugw-0
   */
  aquaMintLight: {
    colors: [colors.aqua[100], colors.mint[100]],
    locations: [0, 1],
    useAngle: true,
    angle: 180,
  },

  /**
   * @name Logo
   * @description The gradient used in the Arrived logo
   */
  logo: {
    colors: [colors.mint[500], colors.aqua[500]],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name TransparentMidnight
   */
  transparentMidnight: {
    colors: [colors.darkened.midnight, 'transparent'],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name TransparentLight
   */
  transparentLight: {
    colors: [colors.lightened.iceGray, 'transparent'],
    locations: [0, 1],
    useAngle: true,
    angle: 0,
  },

  /**
   * @name TransparentMint
   */
  transparentMint: {
    colors: [colors.mint[50], 'transparent'],
    locations: [0, 3],
    useAngle: true,
    angle: 180,
  },

  /**
   * @name TransparentSeal
   */
  transparentSeal: {
    colors: [colors.mint[50], 'transparent'],
    locations: [0, 3],
    useAngle: true,
    angle: 180,
  },
};

// For web based gradients until full react-native-web support
export const webGradients = Object.entries(gradientTokens).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: createWebGradient(value),
  }),
  {} as GradientsWeb,
);

export const nativeGradients = Object.entries(gradientTokens).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: createNativeGradient(value),
  }),
  {} as GradientsNative,
);

export type Gradient = typeof gradientTokens;
export type Gradients = keyof Gradient;
export type GradientDirections = 'vertical' | 'verticalReverse' | 'horizontal' | 'horizontalReverse';

export type GradientsWeb = {
  [key in Gradients]: {
    [key in GradientDirections]: string;
  };
};

export type GradientsNative = {
  [key in Gradients]: {
    [key in GradientDirections]: LinearGradientPropsFill;
  };
};
