import { useCallback } from 'react';

import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import { format, parseISO } from 'date-fns';

import { useGetPropertyQuery } from '@arrived/admin-queries';
import {
  Accordion,
  Anchor,
  AnimateHeight,
  BodyText,
  CaretDownIcon,
  ChartTrendingUpIcon,
  DividendPaymentIcon,
  KeyIcon,
  NotificationIcon,
  Stack,
  Tag,
  TagIcon,
  UtilityText,
  isWeb,
  styled,
} from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { CONFIG } from '@arrived/config';
import { PropertyStatusHistory, PropertyStatusTitle } from '@arrived/models';

import { OfferingStatusTitle } from './OfferingHistoryTitles';

export type OfferingHistoryEventProps = {
  event: PropertyStatusHistory;
  emphasized: boolean;
};

const OfferingHistoryEventIconContainer = styled(Stack, {
  name: 'OfferingHistoryEventIconContainer',
  position: 'relative',
  mr: '$4',
  width: 40,
  height: 40,
  borderRadius: '$6',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$onSurface.neutral.zebra',
  zIndex: 10,
  variants: {
    manualState: {
      true: {
        backgroundColor: '$onSurface.neutral.zebra',
      },
    },
    emphasized: {
      true: {
        borderColor: '$onSurface.primary.light',
        borderWidth: '$1',
        backgroundColor: '$onSurface.primary.decorative',
      },
    },
  } as const,
});

const OfferingHistoryEventTitleText = styled(UtilityText, {
  name: 'OfferingHistoryEventTitleText',
  token: 'utility.label.medium',
  color: '$onSurface.neutral.defaultAlt',
  variants: {
    major: {
      true: {
        color: '$onSurface.neutral.default',
        token: 'utility.label.medium',
      },
    },
  },
});

export type OfferingHistoryEventContentProps = {
  open: boolean;
  isAccordion?: boolean;
  event: PropertyStatusHistory;
  emphasized: boolean;
};

const offeringHistoryEventIcon = (event: PropertyStatusHistory, emphasized: boolean) => {
  const color = emphasized ? '$onSurface.primary.light' : '$onSurface.neutral.defaultAlt';

  if (event.statusTitle === PropertyStatusTitle.IPO_DATE) return <TagIcon color={color} />;
  if (event.statusTitle === PropertyStatusTitle.MANUAL_STATUS_ENTRY) return <NotificationIcon color={color} />;
  if (
    event.statusTitle === PropertyStatusTitle.LEASE_STARTS ||
    event.statusTitle === PropertyStatusTitle.FIRST_LEASE_STARTS ||
    event.statusTitle === PropertyStatusTitle.LEASE_CANCELLED ||
    event.statusTitle === PropertyStatusTitle.LEASE_ENDS ||
    event.statusTitle === PropertyStatusTitle.EARLY_LEASE_BREAK ||
    event.statusTitle === PropertyStatusTitle.FULL_LEASE_RENEWAL ||
    event.statusTitle === PropertyStatusTitle.M2M_LEASE_RENEWAL
  )
    return <KeyIcon color={color} />;

  if (
    event.statusTitle === PropertyStatusTitle.DIVIDENDS_PAID ||
    event.statusTitle === PropertyStatusTitle.DIVIDENDS_PAUSED
  ) {
    return <DividendPaymentIcon color={color} />;
  }

  if (event.statusTitle === PropertyStatusTitle.SHARE_PRICE_UPDATED) return <ChartTrendingUpIcon color={color} />;

  return <Stack height="$2" width="$2" borderRadius="$6" backgroundColor="$onSurface.neutral.outlineAlt" mr="$0.5" />;
};

const showRentAmount = (event: PropertyStatusHistory) => {
  if (
    event.statusTitle === PropertyStatusTitle.FULL_LEASE_RENEWAL ||
    event.statusTitle === PropertyStatusTitle.M2M_LEASE_RENEWAL
  ) {
    return true;
  } else {
    return false;
  }
};

const showAlteredRentAmount = (event: PropertyStatusHistory) => {
  if (
    event.statusTitle === PropertyStatusTitle.LEASE_STARTS ||
    event.statusTitle === PropertyStatusTitle.FIRST_LEASE_STARTS
  ) {
    return true;
  } else {
    return false;
  }
};

const showDividendYield = (event: PropertyStatusHistory) => {
  if (event.statusTitle === PropertyStatusTitle.DIVIDENDS_PAID) {
    return true;
  } else {
    return false;
  }
};

const showSharePrice = (event: PropertyStatusHistory) => {
  if (event.statusTitle === PropertyStatusTitle.SHARE_PRICE_UPDATED) {
    return true;
  } else {
    return false;
  }
};

const calculateLeaseRent = (monthlyRent: number, monthToMonthIncrease: number | undefined) => {
  if (monthToMonthIncrease === undefined) {
    return monthlyRent;
  } else {
    return Math.round(monthlyRent / (1 + monthToMonthIncrease / 100));
  }
};

const isClientGeneratedDescriptionText = (event: PropertyStatusHistory) => {
  if (
    event.statusTitle === PropertyStatusTitle.DIVIDENDS_PAID ||
    event.statusTitle === PropertyStatusTitle.DIVIDENDS_PAUSED ||
    event.statusTitle === PropertyStatusTitle.SHARE_PRICE_UPDATED ||
    event.statusTitle === PropertyStatusTitle.CHANGE_IN_MARKETING_RENT
  ) {
    return true;
  }

  return false;
};

const GenerateEventDescription = (event: PropertyStatusHistory) => {
  const intl = useIntl();
  if (event.statusTitle === PropertyStatusTitle.DIVIDENDS_PAID) {
    return intl.formatMessage(
      {
        id: 'offering-history.dividends-paid.description',
      },
      {
        link: (
          <Anchor
            underline
            target="_BLANK"
            color="$interactive.primary.rested"
            href={`${CONFIG.helpUrl}/en/articles/6500012-how-are-dividend-amounts-determined`}
          >
            <BodyText color="$interactive.primary.rested" token="body.default.small">
              FAQ
            </BodyText>
          </Anchor>
        ),
      },
    );
  } else if (event.statusTitle === PropertyStatusTitle.DIVIDENDS_PAUSED) {
    return intl.formatMessage(
      {
        id: 'offering-history.dividends-paused.description',
      },
      {
        link: (
          <Anchor
            underline
            target="_BLANK"
            color="$interactive.primary.rested"
            href={`${CONFIG.helpUrl}/en/articles/6500012-how-are-dividend-amounts-determined`}
          >
            <BodyText color="$interactive.primary.rested" token="body.default.small">
              FAQ
            </BodyText>
          </Anchor>
        ),
      },
    );
  } else if (event.statusTitle === PropertyStatusTitle.SHARE_PRICE_UPDATED) {
    return intl.formatMessage(
      {
        id: 'offering-history.share-price-updated.description',
      },
      {
        link: (
          <Anchor
            underline
            target="_BLANK"
            color="$interactive.primary.rested"
            href={`${CONFIG.helpUrl}/en/articles/6909386-how-are-share-prices-calculated`}
          >
            <BodyText color="$interactive.primary.rested" token="body.default.small">
              blog
            </BodyText>
          </Anchor>
        ),
      },
    );
  } else if (event.statusTitle === PropertyStatusTitle.CHANGE_IN_MARKETING_RENT) {
    return intl.formatMessage({ id: 'offering-history.change-in-marketing-rent.description' });
  } else {
    return '';
  }
};

const EventContent = (props: OfferingHistoryEventContentProps) => {
  const { event, emphasized, open, isAccordion } = props;
  const propertiesState = useGetPropertyQuery(event.propertyId);

  const proformaRent = propertiesState.data?.proformaRent;

  // If month-to-month rent we use originalMonthlyRent
  let currentRentToProformaRent: number | null;
  if (proformaRent && event.statusTitle === PropertyStatusTitle.FIRST_LEASE_STARTS && event.monthlyRent) {
    currentRentToProformaRent = calculateLeaseRent(event.monthlyRent, event.monthToMonthIncrease) - proformaRent;
  } else {
    currentRentToProformaRent = null; // or any other default handling
  }

  const getForecastTagVariant = useCallback((currentRentToProformaRent: number) => {
    if (currentRentToProformaRent > 0) {
      return 'positive';
    } else if (currentRentToProformaRent < 0) {
      return 'negative';
    } else {
      return 'inverted';
    }
  }, []);

  const getActualVProformaDirectionLabel = useCallback((currentRentToProformaRent: number) => {
    if (currentRentToProformaRent > 0) {
      return 'Above';
    } else if (currentRentToProformaRent < 0) {
      return 'Below';
    } else {
      return 'At';
    }
  }, []);

  return (
    <>
      <Stack row position="relative" pt="$2" pb="$2">
        <OfferingHistoryEventIconContainer emphasized={emphasized}>
          {offeringHistoryEventIcon(event, emphasized)}
        </OfferingHistoryEventIconContainer>

        <Stack>
          <OfferingHistoryEventTitleText textAlign="left" pb="$3">
            {OfferingStatusTitle[event.statusTitle]}
          </OfferingHistoryEventTitleText>
          <UtilityText token="utility.helper.medium" color="$onSurface.neutral.muted" textAlign="left">
            <FormattedMessage
              id="offering-histoy.event-date"
              defaultMessage="{date}"
              values={{
                date: format(parseISO(event.displayDate), 'MM/dd/yyyy'),
              }}
            />
          </UtilityText>
        </Stack>
      </Stack>
      <Stack row alignItems="center">
        <Stack mr={!isAccordion ? '$8' : 0}>
          {showRentAmount(event) && (
            <Stack>
              {event.monthlyRent && (
                <UtilityText token="utility.label.medium" mb="$2" textAlign="right">
                  <FormattedMessage
                    id={'offering-history.rent-amount'}
                    defaultMessage="{value}"
                    values={{
                      value: (
                        <FormattedNumber
                          value={event.monthlyRent}
                          style="currency"
                          currency="USD"
                          minimumFractionDigits={0}
                        />
                      ),
                    }}
                  />
                </UtilityText>
              )}
              {currentRentToProformaRent !== null && (
                <Tag condensed variant={getForecastTagVariant(currentRentToProformaRent)}>
                  <Tag.Label>
                    <FormattedMessage
                      id={'offering-history.actual-v-proforma-amount'}
                      defaultMessage="{value} {direction} Forecast"
                      values={{
                        value:
                          currentRentToProformaRent !== 0 ? (
                            <FormattedNumber
                              value={currentRentToProformaRent}
                              style="currency"
                              currency="USD"
                              minimumFractionDigits={0}
                            />
                          ) : null,
                        direction: getActualVProformaDirectionLabel(currentRentToProformaRent),
                      }}
                    />
                  </Tag.Label>
                </Tag>
              )}
            </Stack>
          )}
          {showAlteredRentAmount(event) && (
            <Stack>
              {event.monthlyRent && (
                <UtilityText token="utility.label.medium" mb="$2" textAlign="right">
                  <FormattedMessage
                    id={'offering-history.rent-amount'}
                    defaultMessage="{value}"
                    values={{
                      value: (
                        <FormattedNumber
                          value={calculateLeaseRent(event.monthlyRent, event.monthToMonthIncrease)}
                          style="currency"
                          currency="USD"
                          minimumFractionDigits={0}
                        />
                      ),
                    }}
                  />
                </UtilityText>
              )}

              {currentRentToProformaRent !== null && (
                <Tag condensed variant={getForecastTagVariant(currentRentToProformaRent)}>
                  <Tag.Label>
                    <FormattedMessage
                      id={'offering-history.actual-v-proforma-amount'}
                      defaultMessage="{value} {direction} Forecast"
                      values={{
                        value:
                          currentRentToProformaRent !== 0 ? (
                            <FormattedNumber
                              value={currentRentToProformaRent}
                              style="currency"
                              currency="USD"
                              minimumFractionDigits={0}
                            />
                          ) : null,
                        direction: getActualVProformaDirectionLabel(currentRentToProformaRent),
                      }}
                    />
                  </Tag.Label>
                </Tag>
              )}
            </Stack>
          )}

          {showDividendYield(event) && event.dividendYield && (
            <Stack>
              <UtilityText token="utility.label.medium" mb="$2" textAlign="right">
                <FormattedMessage
                  id={'offering-history.dividend-yield-amount'}
                  defaultMessage="{value}"
                  values={{
                    value: (
                      <FormattedNumber value={event.dividendYield / 100} style="percent" minimumFractionDigits={2} />
                    ),
                  }}
                />
              </UtilityText>
              <UtilityText token="utility.helper.medium" color="$onSurface.neutral.muted" textAlign="left">
                <FormattedMessage id={'offering-history.dividend-yield'} />
              </UtilityText>
            </Stack>
          )}

          {showSharePrice(event) && (
            <Stack>
              {event.sharePrice && (
                <UtilityText token="utility.label.medium" mb="$2" textAlign="right">
                  <FormattedMessage
                    id={'offering-history.share-price-amount'}
                    defaultMessage="{value}"
                    values={{
                      value: (
                        <FormattedNumber
                          value={event.sharePrice}
                          style="currency"
                          currency="USD"
                          minimumFractionDigits={2}
                        />
                      ),
                    }}
                  />
                </UtilityText>
              )}

              {event.sharePriceEstimatedMin && event.sharePriceEstimatedMax && (
                <UtilityText token="utility.helper.medium" color="$onSurface.neutral.muted" textAlign="left">
                  <FormattedMessage
                    id="offering-history.share-price-range"
                    defaultMessage="{minValue} | {maxValue}"
                    values={{
                      minValue: (
                        <FormattedNumber
                          value={event.sharePriceEstimatedMin}
                          style="currency"
                          currency="USD"
                          minimumFractionDigits={2}
                        />
                      ),
                      maxValue: (
                        <FormattedNumber
                          value={event.sharePriceEstimatedMax}
                          style="currency"
                          currency="USD"
                          minimumFractionDigits={2}
                        />
                      ),
                    }}
                  />
                </UtilityText>
              )}
            </Stack>
          )}
        </Stack>
        {isAccordion && (
          <Stack ml="$2">
            <Stack animation="quick" transform={[{ rotate: open ? '180deg' : '0deg' }]}>
              <CaretDownIcon />
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};
export const OfferingHistoryEvent = (props: OfferingHistoryEventProps) => {
  const { event, emphasized } = props;

  return (
    <Stack py="$2">
      <Stack
        borderLeftWidth={1}
        borderColor={'$onSurface.neutral.outlineAlt'}
        w={1}
        h={emphasized ? '50%' : '100%'}
        position="absolute"
        bottom={0}
        left="$5"
      />
      {event.description || isClientGeneratedDescriptionText(event) ? (
        <Accordion collapsible type="single" defaultValue={isWeb ? event.cid : ''}>
          <Accordion.Item value={event.cid} borderWidth={0}>
            <Accordion.Trigger bg="transparent" borderWidth={0} borderColor="transparent" pl={0} pr={0}>
              {({ open }: { open: boolean }) => (
                <Stack row alignItems="center" justifyContent="space-between">
                  <EventContent event={event} open={open} isAccordion emphasized={emphasized} />
                </Stack>
              )}
            </Accordion.Trigger>
            <AnimateHeight expand={true}>
              <Accordion.Content ml="$2" pl="$12" pt={0} pb="$10" pr="$6">
                <BodyText token="body.default.small">
                  {isClientGeneratedDescriptionText(event) ? (
                    GenerateEventDescription(event)
                  ) : (
                    <Markdown>{String(event.description)}</Markdown>
                  )}
                </BodyText>
              </Accordion.Content>
            </AnimateHeight>
          </Accordion.Item>
        </Accordion>
      ) : (
        <Stack row alignItems="center" justifyContent="space-between">
          <EventContent event={event} open={false} emphasized={emphasized} />
        </Stack>
      )}
    </Stack>
  );
};
