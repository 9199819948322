import * as yup from 'yup';

import {
  FixedRateInvestmentInstrumentType,
  FixedRateInvestmentInstrumentTypeType,
  FixedRateInvestmentLoanStrategy,
  FixedRateInvestmentLoanStrategyType,
  FixedRateInvestmentOriginator,
  FixedRateInvestmentOriginatorType,
} from '@arrived/models';

export const FixedRateInvestmentRequestSchema = yup.object({
  cid: yup.string(),
  yieldPercent: yup.number().min(0).max(100).required('Yield percent is required').nullable(),
  interestAccrualStartAt: yup.string().required('Interest accrual start date is required'),
  matureAt: yup.string().required('Mature date is required'),
  salesforceId: yup.string().nullable(),
  paidAt: yup.string().nullable(),
  loanAmount: yup.number().nullable(),
  originator: yup
    .mixed<FixedRateInvestmentOriginatorType>()
    .oneOf(Object.values(FixedRateInvestmentOriginator) as FixedRateInvestmentOriginatorType[])
    .nullable(),
  loanStrategy: yup
    .mixed<FixedRateInvestmentLoanStrategyType>()
    .oneOf(Object.values(FixedRateInvestmentLoanStrategy) as FixedRateInvestmentLoanStrategyType[])
    .nullable(),
  instrumentType: yup
    .mixed<FixedRateInvestmentInstrumentTypeType>()
    .oneOf(Object.values(FixedRateInvestmentInstrumentType) as FixedRateInvestmentInstrumentTypeType[])
    .nullable(),
  marketId: yup.number().nullable(),
  projectLoanToCost: yup.number().nullable(),
  projectLoanToValue: yup.number().nullable(),
  createdAt: yup.string(),
  updatedAt: yup.string(),
  active: yup.boolean(),
});

export const FixedRateInvestmentPostSchema = yup.object({
  cid: yup.string(),
  yieldPercent: yup.number().min(0).max(100).required('Yield percent is required'),
  interestAccrualStartAt: yup.string().required('Interest accrual start date is required'),
  matureAt: yup.string().required('Mature date is required'),
  active: yup.boolean(),
});
