import { OfferingTransaction, TransactionCategory } from '@arrived/models';

import { CANCELLED_TRANSACTION_STATUSES, COMPLETE_FUND_STATUSES, COMPLETE_TRANSACTION_STATUSES } from '../constants';

/**
 * Check to verify if a transaction is complete meaning that no user or admin action is required at this time in order
 * to complete processing of the trade.
 */
export function isTransactionComplete(transaction: OfferingTransaction) {
  return (
    COMPLETE_TRANSACTION_STATUSES.includes(transaction.status) &&
    COMPLETE_FUND_STATUSES.includes(transaction.fundStatus) &&
    transaction.documentsSignedStatus === 'SIGNED'
  );
}

/**
 * Gets the TransactionCategory for a provided OfferingTransaction.
 */
export function getTransactionCategory(transaction: OfferingTransaction): TransactionCategory {
  if (CANCELLED_TRANSACTION_STATUSES.includes(transaction.status)) {
    return TransactionCategory.CANCELLED;
  }

  /*
  By this point, we know that the OfferingTransaction is in one of the active states. So we can directly use the server
  provided value of isComplete to decide whether the Transaction is complete or needs action.
   */

  return isTransactionComplete(transaction) ? TransactionCategory.COMPLETED : TransactionCategory.INCOMPLETE;
}
