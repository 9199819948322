export const DeviceType = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
  UNKNOWN: 'unknown',
} as const;

/**
 * Uses the user agent to determine the device type. While this is not 100% accurate, because the user agent can be
 * spoofed, it is accurate enough for low-risk use cases such as showing the iOS App Install Sheet.
 */
export const getUserDeviceType = (): (typeof DeviceType)[keyof typeof DeviceType] => {
  if (typeof window === 'undefined' || !window.navigator) {
    return DeviceType.UNKNOWN;
  }

  const userAgent = window.navigator.userAgent || window.navigator.vendor;

  if (/android/i.test(userAgent)) {
    return DeviceType.ANDROID;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return DeviceType.IOS;
  }

  return DeviceType.WEB;
};
