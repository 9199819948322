import * as yup from 'yup';

export const VoucherSchema = yup.object({
  accountUuid: yup.string().required(),
  amount: yup.number().required(),
  description: yup.string().nullable(),
  currency: yup.string().required().default('USD'),
  note: yup.string().nullable(),
});

export type VoucherSchema = yup.InferType<typeof VoucherSchema>;
