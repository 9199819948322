import { GetProps, createStyledContext, styled, useProps } from '@tamagui/core';

import { ValueText } from '../../text';
import { PillContainer } from '../Pill';

const COUNT_CONTAINER_NAME = 'CountContainer';
const COUNT_LABEL_NAME = 'CountLabel';

type CountVariant = 'default' | 'inverted' | 'primary';
interface CountStyleContextType {
  variant?: CountVariant;
}

const CountStyledContext = createStyledContext<CountStyleContextType>({
  variant: 'default',
});

const CountContainer = styled(PillContainer, {
  name: COUNT_CONTAINER_NAME,
  context: CountStyledContext,

  condensed: true,

  variants: {
    variant: {
      default: {
        bg: '$surface.neutral.default',
      },
      inverted: {
        bg: '$onSurface.neutral.container',
      },
      primary: {
        bg: '$onSurface.primary.decorative',
      },
    },
  } as const,

  defaultVariants: {
    variant: 'default',
  },
});

const CountLabel = styled(ValueText, {
  name: COUNT_LABEL_NAME,
  context: CountStyledContext,

  token: 'value.xsmall',

  variants: {
    variant: {
      default: {
        color: '$onSurface.neutral.muted',
      },
      inverted: {
        color: '$onSurface.neutral.defaultInverted',
      },
      primary: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
  } as const,
});

const CountComponent = CountContainer.styleable((propsIn, ref) => {
  const { children, ...rest } = useProps(propsIn);

  return (
    <CountContainer ref={ref} {...rest}>
      <CountLabel>{children}</CountLabel>
    </CountContainer>
  );
});

export const Count = CountContainer.styleable(CountComponent);
export type CountProps = GetProps<typeof Count>;
