import { memo, useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { getPercentFunded } from '@arrived/common';

import { useProductWidgetContext } from './ProductWidgetContext';
import { useProductWidgetStyledContext } from './ProductWidgetStyledContext';

export const Funded = memo(() => {
  const { offering } = useProductWidgetContext();
  const { condensed } = useProductWidgetStyledContext();

  const fundedPercent = useMemo(() => {
    return (
      <FormattedNumber
        value={getPercentFunded(offering) / 100}
        style="percent"
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
    );
  }, [offering]);

  return (
    <DataPoint variant="minimized" colors="muted" alignment={condensed ? 'right' : 'left'}>
      <DataPoint.Value>{fundedPercent}</DataPoint.Value>
      <DataPoint.Label>
        <FormattedMessage id="common.funded" />
      </DataPoint.Label>
    </DataPoint>
  );
});
