import { ReactNode, useMemo } from 'react';
import { GestureResponderEvent, Pressable } from 'react-native';

import { GetProps, createStyledContext, isTamaguiElement, styled, useProps, withStaticProperties } from '@tamagui/core';

import { Background } from '../../atoms/background';
import { Stack } from '../../atoms/layout';
import { TitleText } from '../../atoms/text';
import { ArrowRightIcon } from '../../icons';

const CARD_CONTAINER_NAME = 'CardContainer';
const CARD_HEADER_NAME = 'CardHeader';
const CARD_CONTENT_NAME = 'CardContent';
const CARD_FOOTER_NAME = 'CardFooter';

const CardStyledContext = createStyledContext({
  condensed: false,
  bordered: false,
  hasFooter: false,
});

const CardContainer = styled(Stack, {
  name: CARD_CONTAINER_NAME,
  borderRadius: '$2',
  overflow: 'hidden',
  context: CardStyledContext,

  variants: {
    condensed: {
      true: {},
    },

    hasFooter: {
      true: {},
    },

    variant: {
      emphasized: {},
      aggregate: {},
      sub: {
        backgroundColor: '$onSurface.neutral.container',
      },
    },
  } as const,
});

const CardHeader = styled(TitleText, {
  name: CARD_HEADER_NAME,
  context: CardStyledContext,
  token: 'title.heading.small.alt',
  color: '$onSurface.neutral.defaultInverted',
});

const CardContent = styled(Stack, {
  name: CARD_CONTENT_NAME,
  context: CardStyledContext,
  position: 'relative',
  marginTop: '$5',
  marginBottom: '$6',
  marginHorizontal: '$6',
  gap: '$4',
  flex: 1,
  variants: {
    condensed: {
      true: {
        marginTop: '$4',
        marginBottom: '$4',
        marginHorizontal: '$4',
      },
    },

    hasFooter: {
      true: {
        marginBottom: '$4',
      },
    },
  } as const,
});

interface CardContentExtraProps {
  title?: ReactNode;
  handleOnPress?: (event: GestureResponderEvent) => void;
}

const CardFooter = styled(Stack, {
  name: CARD_FOOTER_NAME,
  context: CardStyledContext,
  padding: '$6',
  position: 'relative',
  width: '100%',
  bg: '$onSurface.neutral.containerAlt',
});

export const Card = withStaticProperties(
  CardContainer.styleable<GetProps<typeof CardContainer> & { backgroundProps?: GetProps<typeof Background.Noise> }>(
    (propsIn, ref) => {
      const { children, backgroundProps, ...rest } = useProps(propsIn);

      const hasFooter = useMemo(() => {
        return Array.isArray(children)
          ? Boolean(
              children.find(
                (child) => isTamaguiElement(child) && child?.type?.staticConfig?.componentName === CARD_FOOTER_NAME,
              ),
            )
          : children.type?.staticConfig?.componentName === CARD_FOOTER_NAME;
      }, [children]);

      return (
        <CardContainer ref={ref} zIndex={1} hasFooter={hasFooter} {...rest}>
          {children}
          {rest.variant !== 'sub' && (
            <Background.Noise
              fullscreen
              emphasized={rest.variant === 'emphasized'}
              height="100%"
              {...backgroundProps}
            />
          )}
        </CardContainer>
      );
    },
  ),
  {
    Content: CardContent.styleable<GetProps<typeof CardContent> & CardContentExtraProps>((propsIn, ref) => {
      const { children, title, handleOnPress, ...rest } = useProps(propsIn);

      if (!handleOnPress) {
        return (
          <CardContent ref={ref} {...rest}>
            {title && <CardHeader>{title}</CardHeader>}
            {children}
          </CardContent>
        );
      }

      return (
        <Pressable style={{ flex: 1 }} onPress={handleOnPress}>
          <CardContent ref={ref} {...rest}>
            <Stack flexDirection="row-reverse" justifyContent="space-between" alignItems="center">
              <ArrowRightIcon size="$4" color="$onSurface.neutral.defaultInverted" />
              {title && <CardHeader>{title}</CardHeader>}
            </Stack>
            {children}
          </CardContent>
        </Pressable>
      );
    }),
    Footer: CardFooter,
  },
);
