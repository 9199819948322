import { useQuery } from '@tanstack/react-query';

import { getZillowZipCodeAppreciation } from '@arrived/api_v2';
import { ZipCode } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { zillowZipCodeAppreciationKey } from './zillow.keys';

export function useGetZillowZipCodeAppreciationQuery(
  zipCode?: ZipCode,
  options?: UseAwaitedQueryOptions<typeof getZillowZipCodeAppreciation>,
) {
  return useQuery({
    queryKey: zillowZipCodeAppreciationKey(zipCode!),
    queryFn: () => getZillowZipCodeAppreciation(zipCode!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && zipCode != null,
  });
}
