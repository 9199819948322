import { FC, useMemo } from 'react';

import { Count, Group, NavItem, NavItemType, NavLink, RootTextProps } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { ConvertBuilderPath, useGetBuilderInvestNavGroupsQuery } from '@arrived/builder.io';
import { ROUTES, offeringFilterToSearchParams } from '@arrived/common';
import { InvestmentCategory, convertOfferingFilterToSearchQuery } from '@arrived/models';
import { useOfferingSearchQuery } from '@arrived/queries';

const getContentComponent =
  (content: string) =>
  ({ Text }: { Text: FC<RootTextProps> }) => <Markdown Text={Text}>{content}</Markdown>;

/**
 * Returns a NavItem of type NavItemType.SECTION specifically for the "Invest" NavItem. We have a
 * special hook for this one because instead of defining the entire NavItem in builder.io, we
 * define only the content for each of the Groups so that we can hook up live data queried from the
 * API to each of the Group labels.
 *
 * This hook will handle the fetching of the builder.io NavItem Group data as well as the fetching
 * of the query numbers for each of our Offering types and then associate them all together
 * producing a NavItem object that can be passed as a primary item to the MainNav from
 * @arrived/bricks.
 */
export function useInvestNavItem(
  convertBuilderPath: ConvertBuilderPath,
  fallback: Omit<NavLink, 'type' | 'key' | 'label'>,
) {
  const builderInvestNavGroups = useGetBuilderInvestNavGroupsQuery(undefined);

  const allInvestmentCount = useOfferingSearchQuery({ fields: ['id'], size: 1 }).data?.pagination.totalResults;
  const singleFamilyCount = useOfferingSearchQuery({
    ...convertOfferingFilterToSearchQuery({ investmentCategory: InvestmentCategory.SINGLE_FAMILY }),
    fields: ['id'],
    size: 1,
  }).data?.pagination.totalResults;
  const vacationRentalCount = useOfferingSearchQuery({
    ...convertOfferingFilterToSearchQuery({ investmentCategory: InvestmentCategory.VACATION }),
    fields: ['id'],
    size: 1,
  }).data?.pagination.totalResults;
  const fundCount = useOfferingSearchQuery({
    ...convertOfferingFilterToSearchQuery({ investmentCategory: InvestmentCategory.FUNDS }),
    fields: ['id'],
    size: 1,
  }).data?.pagination.totalResults;
  const debtCount = useOfferingSearchQuery({
    ...convertOfferingFilterToSearchQuery({ investmentCategory: InvestmentCategory.REAL_ESTATE_DEBT }),
    fields: ['id'],
    size: 1,
  }).data?.pagination.totalResults;

  const groups = useMemo(
    (): Group[] =>
      (builderInvestNavGroups.data
        ?.map(({ data: { key, content, label, resourceLists } }) => {
          switch (key) {
            case 'all-investments':
              return {
                adornment: allInvestmentCount != null ? <Count>{allInvestmentCount}</Count> : null,
                Content: getContentComponent(content),
                resource: {
                  label,
                  ...convertBuilderPath(ROUTES.properties.base),
                },
                resourceLists: resourceLists.map(({ header, resources }) => ({
                  header,
                  resources: resources.map(({ label, path }) => ({ label, ...convertBuilderPath(path) })),
                })),
              };
            case 'single-family-residential':
              return {
                adornment: singleFamilyCount != null ? <Count>{singleFamilyCount}</Count> : null,
                Content: getContentComponent(content),
                resource: {
                  label,
                  ...convertBuilderPath(
                    ROUTES.properties.base +
                      `?${offeringFilterToSearchParams({ investmentCategory: InvestmentCategory.SINGLE_FAMILY })}`,
                  ),
                },
                resourceLists: resourceLists.map(({ header, resources }) => ({
                  header,
                  resources: resources.map(({ label, path }) => ({ label, ...convertBuilderPath(path) })),
                })),
              };
            case 'vacation-rental':
              return {
                adornment: vacationRentalCount != null ? <Count>{vacationRentalCount}</Count> : null,
                Content: getContentComponent(content),
                resource: {
                  label,
                  ...convertBuilderPath(
                    ROUTES.properties.base +
                      `?${offeringFilterToSearchParams({ investmentCategory: InvestmentCategory.VACATION })}`,
                  ),
                },
                resourceLists: resourceLists.map(({ header, resources }) => ({
                  header,
                  resources: resources.map(({ label, path }) => ({ label, ...convertBuilderPath(path) })),
                })),
              };
            case 'fund':
              return {
                adornment: fundCount != null ? <Count>{fundCount}</Count> : null,
                Content: getContentComponent(content),
                resource: {
                  label,
                  ...convertBuilderPath(
                    ROUTES.properties.base +
                      `?${offeringFilterToSearchParams({ investmentCategory: InvestmentCategory.FUNDS })}`,
                  ),
                },
                resourceLists: resourceLists.map(({ header, resources }) => ({
                  header,
                  resources: resources.map(({ label, path }) => ({ label, ...convertBuilderPath(path) })),
                })),
              };
            case 'real-estate-debt':
              return {
                adornment: debtCount != null ? <Count>{debtCount}</Count> : null,
                Content: getContentComponent(content),
                resource: {
                  label,
                  ...convertBuilderPath(
                    ROUTES.properties.base +
                      `?${offeringFilterToSearchParams({ investmentCategory: InvestmentCategory.REAL_ESTATE_DEBT })}`,
                  ),
                },
                resourceLists: resourceLists.map(({ header, resources }) => ({
                  header,
                  resources: resources.map(({ label, path }) => ({ label, ...convertBuilderPath(path) })),
                })),
              };
            default:
              return null;
          }
        })
        .filter(Boolean) ?? []) as Group[],
    [
      allInvestmentCount,
      debtCount,
      fundCount,
      singleFamilyCount,
      vacationRentalCount,
      builderInvestNavGroups,
      convertBuilderPath,
    ],
  );

  return useMemo(
    (): NavItem =>
      groups.length > 0
        ? {
            key: 'invest',
            type: NavItemType.SECTION,
            label: 'Invest',
            groups,
            ...convertBuilderPath(ROUTES.properties.base),
          }
        : {
            key: 'invest',
            type: NavItemType.LINK,
            label: 'Invest',
            ...fallback,
          },
    [fallback, groups],
  );
}
