import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchOfferingTransaction } from '@arrived/api_v2';
import { OfferingTransaction } from '@arrived/models';
import { UseAwaitedMutationOptions, userTradesQueryKeyFn } from '@arrived/queries';

import { offeringTradesQueryKeyFn } from './trades.keys';

export function useAdminPatchOfferingTradeMutation(
  options?: UseAwaitedMutationOptions<typeof patchOfferingTransaction>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchOfferingTransaction,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData(
        { queryKey: offeringTradesQueryKeyFn(data.offeringId) },
        (trades?: OfferingTransaction[]) => trades?.map((trade) => (trade.id === data.id ? data : trade)) ?? [],
      );
      queryClient.invalidateQueries({ queryKey: offeringTradesQueryKeyFn(data.offeringId) });
      queryClient.invalidateQueries({ queryKey: userTradesQueryKeyFn(data.userId) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
