import { ReactElement, Ref, forwardRef } from 'react';

import { Slide, SlideProps } from '@mui/material';

export const SlideUp = forwardRef(function Transition(
  props: SlideProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});
