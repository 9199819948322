import { useMemo, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';

import {
  LineSegment,
  PaddingProps,
  VictoryChart,
  VictoryChartProps,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryVoronoiContainerProps,
} from 'victory';

import { Loading, Stack, UtilityText, composeEventHandlers, useTheme, withStaticProperties } from '@arrived/bricks';

import { BricksArea } from './BricksArea';
import { BricksAxis } from './BricksAxis';
import { BricksChartStack } from './BricksChartStack';
import { BricksLine } from './BricksLine';
import { ChartSvgPatternDefinitions } from './ChartSvgPatternDefinitions';
import { SharedBricksChartProps } from './types';
import { useChartPadding, useChartsTheme } from './utils';

interface BricksChartProps extends SharedBricksChartProps {
  /**
   * Given coordinate point data, this lets you format the text you'd like to show on hover.
   *
   * Providing this function is all that's needed to render those tooltips. This will take
   * precedence over the vertical cursor bars if `onPointHover` is also provided.
   *
   * NOTE: this type specifically references the web export from victory.
   */
  chartContainerProps?: VictoryChartProps;
  chartPaddingProps?: PaddingProps;
}

/**
 * Root component for our styled Victory Charts implementation.
 *
 * - wraps their `VictoryChart` component in a `Stack` for easy container styling
 * - offers a `chartContainerProps` prop for overriding/manipulating that component if needed
 * - includes the SVG definitions for themed patterns
 */
export const BricksChart = withStaticProperties(
  Stack.styleable<BricksChartProps>(
    (
      {
        children,
        chartContainerProps,
        chartPaddingProps,
        isLoading,
        loadingText,
        isEmpty,
        EmptyStateComponent,
        onPointHover,
        tooltipFormat,
        onLayout,
        ...rest
      },
      ref,
    ) => {
      const theme = useTheme();
      const { tooltipStyles } = useChartsTheme();
      const { chartPadding, chartPaddingBottomValue } = useChartPadding(chartPaddingProps);

      const [layoutRectangle, setLayoutRectangle] = useState<LayoutRectangle>();

      /**
       * Annoyingly, if we want to include vertical lines on hover, it won't render anything if
       * the `labels` prop is undefined.
       *
       * So, we'll prioritize the provided formatter if available, but if we're supporting a hover
       * state we need to offer it a stub function.
       *
       * Same goes for swapping between the default styled tooltip if we have instructions for
       * resolving the label text, the vertical line for hover states, or undefined.
       */
      const shouldRenderTooltips = tooltipFormat !== undefined;
      const shouldRenderHoverLine = onPointHover !== undefined;
      const renderVoronoiLabel = useMemo(() => {
        if (shouldRenderTooltips) {
          return ({ datum: { x, y } }: { datum: { x: number; y: number } }) => tooltipFormat({ x, y });
        }

        if (shouldRenderHoverLine) {
          return () => 'x';
        }

        return undefined;
      }, [shouldRenderTooltips, shouldRenderHoverLine, tooltipFormat]);
      // Their types aren't quite right, but these are provided x/y coordinates
      const VoronoiLabelComponent = ({
        children: _,
        ...rest
      }: VictoryVoronoiContainerProps & { x?: number; y?: number }) => {
        if (shouldRenderTooltips) {
          return (
            <VictoryTooltip
              {...rest}
              constrainToVisibleArea
              style={{ ...tooltipStyles.label, angle: 0 }}
              flyoutStyle={tooltipStyles.flyout}
            />
          );
        }

        if (shouldRenderHoverLine && rest.x && rest.height) {
          return (
            <LineSegment
              x1={rest.x}
              x2={rest.x}
              y1={0}
              y2={rest.height - chartPaddingBottomValue}
              style={{ stroke: theme['onSurface.neutral.default'].val, strokeWidth: 1 }}
            />
          );
        }

        return undefined;
      };

      const composedOnLayout = useMemo(
        () =>
          composeEventHandlers(onLayout, (event: LayoutChangeEvent) => setLayoutRectangle(event.nativeEvent.layout)),
        [onLayout],
      );

      return (
        <Stack
          ref={ref}
          width="100%"
          flex={1}
          justifyContent="center"
          alignItems="center"
          onLayout={composedOnLayout}
          className="bricks-chart-container"
          {...rest}
        >
          {isEmpty && EmptyStateComponent && (
            <Stack width="100%" height="100%" flex={1} alignItems="center" justifyContent="center">
              <EmptyStateComponent />
            </Stack>
          )}
          {!isEmpty && isLoading && (
            <Stack alignItems="center" gap="$2">
              <Loading.Indicator variant="inverted" />
              <UtilityText token="utility.helper.small">{loadingText}</UtilityText>
            </Stack>
          )}
          {!isEmpty && !isLoading && (
            <VictoryChart
              containerComponent={
                <VictoryVoronoiContainer
                  labels={renderVoronoiLabel}
                  labelComponent={<VoronoiLabelComponent />}
                  onActivated={(points: Array<{ x: number; y: number }>) => {
                    if (onPointHover) {
                      // *Lots* more info is available within `points` for future expansion
                      // https://commerce.nearform.com/open-source/victory/docs/victory-voronoi-container#onactivated
                      onPointHover({ datum: { x: points[0]?.x, y: points[0]?.y } });
                    }
                  }}
                  voronoiDimension="x"
                  style={{
                    lineHeight: 0,
                  }}
                />
              }
              padding={chartPadding}
              width={layoutRectangle?.width}
              height={layoutRectangle?.height}
              {...chartContainerProps}
            >
              {/* Pattern definitions *need* to be within `VictoryChart` so they're children of the same <svg>...</svg> as the charts themselves */}
              <ChartSvgPatternDefinitions />
              {children}
            </VictoryChart>
          )}
        </Stack>
      );
    },
  ),
  {
    Area: BricksArea,
    Axis: BricksAxis,
    Stack: BricksChartStack,
    Line: BricksLine,
  },
);
