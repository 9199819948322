import {
  PropsWithChildren,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';

import { HtmlProps } from 'next/dist/shared/lib/html-context.shared-runtime';

import {
  GetProps,
  TamaguiElement,
  composeEventHandlers,
  createStyledContext,
  styled,
  useMedia,
  withStaticProperties,
} from '@tamagui/core';

import {
  FloatingFocusManager,
  FloatingList,
  FloatingPortal,
  autoUpdate,
  offset,
  safePolygon,
  shift,
  size,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react';

import { BodyText, Divider, Stack, TitleText, UtilityText } from '../../../atoms';
import { CaretRightIcon } from '../../../icons';
import { tokens } from '../../../tokens';
import { ResourceProps } from '../MainNav';
import { useMainNavContext } from '../MainNavContext';
import { NavItem } from '../navItem';
import { Group, NavSection as NavSectionType } from '../types';

type NavSectionProps = GetProps<typeof NavItem> & { item: NavSectionType };

interface GroupLabelStyledContext {
  active?: boolean;
}

const GroupLabelStyledContext = createStyledContext<GroupLabelStyledContext>({ active: false });

const GroupLabelFrame = styled(Stack, {
  context: GroupLabelStyledContext,

  group: 'NavHoverGroup',

  tag: 'a',

  py: '$4',
  row: true,
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'auto',
  gap: '$2',
  style: {
    containerType: 'normal',
  },
  focusStyle: {
    outlineWidth: 0,
  },

  variants: {
    active: {
      true: {},
    },
  } as const,
  defaultVariants: {
    active: false,
  },
});

const Right = styled(CaretRightIcon, {
  context: GroupLabelStyledContext,

  animation: 'quick',

  variants: {
    active: {
      true: {
        color: '$interactive.primary.rested',
      },
    },
  } as const,
});

const CondensedRight = styled(CaretRightIcon, {
  animation: 'quick',

  color: 'currentColor',

  '$group-NavItem-press': {
    // @ts-ignore color isn't recognized... but it works!
    color: '$onSurface.neutral.defaultInverted',
  },

  $gtXs: {
    display: 'none',
  },
});

const GroupLabelText = styled(TitleText, {
  context: GroupLabelStyledContext,

  token: 'title.heading.small',

  variants: {
    active: {
      true: {
        color: '$interactive.primary.rested',
      },
    },
  } as const,
});

const GroupLabel = GroupLabelFrame.styleable(({ children, ...rest }, ref) => (
  <GroupLabelFrame ref={ref} {...rest}>
    {children}
    <Right />
  </GroupLabelFrame>
));

const Wrapper = ({ children, link, isExternal }: PropsWithChildren<ResourceProps>) => {
  const { Resource } = useMainNavContext();
  const media = useMedia();

  return media.gtXs ? (
    <Resource link={link} isExternal={isExternal}>
      {children}
    </Resource>
  ) : (
    <>{children}</>
  );
};

const NavSectionGroup = forwardRef<
  TamaguiElement,
  Omit<GetProps<typeof GroupLabel>, 'active'> & Group & { index: number }
>(({ adornment, children, content: _1, resource: { label, link, isExternal }, resourceLists: _2, ...rest }, ref) => {
  const item = useListItem({ label });
  const { Resource } = useMainNavContext();
  const { selectedIndex, getItemProps } = useContext(MenuContext);

  const isActive = selectedIndex === item.index;

  return (
    <>
      {children}
      <Resource link={link} isExternal={isExternal}>
        <GroupLabel
          // It was important to assign a `key` here to cause a re-render because we were finding
          // that the `item.ref` from `useListItem` wasn't assigning this DOM node to the
          // elementsRef array (as found in NavSection) as is necessary to make list navigation
          // work correctly. By assigning a key here and causing a re-render we were able to
          // trigger a call of the ref callback and get this node assigned correctly.
          key={item.index}
          active={isActive}
          tabIndex={isActive ? 0 : -1}
          ref={useMergeRefs([item.ref, ref])}
          {...getItemProps(rest as unknown as HtmlProps)}
        >
          <Stack row alignItems="center" gap="$2">
            <GroupLabelText>{label}</GroupLabelText>
            {adornment}
          </Stack>
        </GroupLabel>
      </Resource>
    </>
  );
});

const ContentText = styled(BodyText, { token: 'body.default.large' });

interface MenuContext {
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  selectedIndex: number;
}

const MenuContext = createContext<MenuContext>({
  getItemProps: () => ({}),
  selectedIndex: 0,
});

const floatingElementMargin = tokens.space['4'].val;
const floatingElementMaxWidth = 1440;

export const NavSection = withStaticProperties(
  NavItem.styleable<NavSectionProps>(
    ({ children, item: { groups, Highlight, isExternal, link }, onPress, ...rest }, ref) => {
      const { Resource, setFloatingMenuIds } = useMainNavContext();
      const id = useId();

      const media = useMedia();

      const [isOpen, setIsOpen] = useState(false);

      useEffect(() => {
        if (isOpen) {
          setFloatingMenuIds((floatingMenuIds) =>
            floatingMenuIds.includes(id) ? floatingMenuIds : [...floatingMenuIds, id],
          );
        } else {
          setFloatingMenuIds((floatingMenuIds) => floatingMenuIds.filter((menuId) => menuId !== id));
        }
      }, [id, isOpen]);

      const { context, refs, floatingStyles } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'bottom',
        strategy: 'absolute',
        whileElementsMounted: autoUpdate,
        middleware: [
          offset({ mainAxis: tokens.space['4'].val }),
          shift(),
          size({
            apply({ availableHeight, availableWidth, elements }) {
              elements.floating.style.maxHeight = `${availableHeight}px`;

              // In theory, we'd like to take the scrollbar width and subtract here as well, however,
              // in practice it always computes to 0 due to our usage of the `lockScroll` prop on the
              // FloatingOverlay component.
              const actualAvailableWidth = availableWidth - floatingElementMargin * 2;

              if (actualAvailableWidth < floatingElementMaxWidth) {
                elements.floating.style.left = `${floatingElementMargin}px`;
                elements.floating.style.right = `${floatingElementMargin}px`;
              } else {
                const computedMargin = (availableWidth - floatingElementMaxWidth) / 2;

                elements.floating.style.right = `${computedMargin}px`;
                elements.floating.style.left = `${computedMargin}px`;
              }
            },
          }),
          // The x translation that was getting derived from 'shift' was causing this element to
          // go beyond the edge of the screen, since we're handling the left/right positioning we
          // are not in need of any horizontal translation and can just remove it here.
          {
            name: 'remove_x_translation',
            fn: (state) => ({ ...state, x: 0 }),
          },
        ],
      });

      useEffect(() => {
        if (!media.gtXs) {
          setIsOpen(false);
        }
      }, [media.gtXs]);

      const [activeIndex, setActiveIndex] = useState<number | null>(null);
      const [selectedIndex, setSelectedIndex] = useState(0);

      // We separately keep track of `selectedIndex` and `activeIndex` such that we can have an
      // `activeIndex` usable by floating-ui/react to keep track of their internals correctly and
      // visually use selectedIndex to indicate which element is currently showing it's resource
      // lists.
      useEffect(() => {
        if (activeIndex != null) {
          setSelectedIndex(activeIndex);
        }
      }, [activeIndex]);

      const elementsRef = useRef<HTMLElement[]>([]);

      const listNavigation = useListNavigation(context, {
        listRef: elementsRef,
        activeIndex,
        onNavigate: setActiveIndex,
        focusItemOnHover: true,
        loop: true,
        enabled: media.gtXs,
      });
      const focus = useFocus(context, { enabled: media.gtXs });
      const hover = useHover(context, {
        delay: {
          open: 150,
        },
        handleClose: safePolygon({ blockPointerEvents: true }),
        enabled: media.gtXs,
        mouseOnly: true,
        move: false,
      });
      const dismiss = useDismiss(context, { enabled: media.gtXs });

      const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
        dismiss,
        focus,
        hover,
        listNavigation,
      ]);

      const { isMounted, styles: floatingTransitionStyles } = useTransitionStyles(context, {
        duration: 200,
        initial: { opacity: 0 },
        open: { opacity: 1 },
      });

      const menuContextValue = useMemo(() => ({ getItemProps, selectedIndex }), [getItemProps, selectedIndex]);

      const Content = useCallback(() => {
        const Content = groups[selectedIndex].Content;

        return Content ? <Content Text={ContentText} /> : null;
      }, [groups, selectedIndex]);

      return (
        <>
          <Wrapper link={link} isExternal={isExternal}>
            <NavItem
              ref={useMergeRefs([ref, refs.setReference])}
              tag={media.gtXs ? 'a' : 'button'}
              {...rest}
              {...getReferenceProps({
                onPress: composeEventHandlers(() => setIsOpen(false), onPress),
              } as unknown as HtmlProps)}
            >
              {children}
              <CondensedRight />
            </NavItem>
          </Wrapper>
          {isMounted && (
            <MenuContext.Provider value={menuContextValue}>
              <FloatingPortal>
                <FloatingFocusManager initialFocus={-1} context={context}>
                  <FloatingList elementsRef={elementsRef}>
                    <Stack
                      ref={refs.setFloating}
                      style={floatingStyles}
                      zIndex="$overlay"
                      focusStyle={{ outlineWidth: 0 }}
                      maxWidth={floatingElementMaxWidth}
                      {...getFloatingProps()}
                    >
                      <Stack
                        style={floatingTransitionStyles}
                        bg="$onSurface.neutral.defaultInverted"
                        p="$10"
                        bbrr="$2"
                        bblr="$2"
                        gap="$10"
                        row
                        width="100%"
                        overflow={'auto' as 'scroll'}
                      >
                        <Stack flexShrink={1} minWidth={300} gap="$4">
                          <Stack>
                            {groups.map((props, idx) => (
                              <NavSectionGroup key={idx} index={idx} onPress={() => setIsOpen(false)} {...props}>
                                {idx > 0 && <Divider solid alt />}
                              </NavSectionGroup>
                            ))}
                          </Stack>
                          {Highlight && <Highlight />}
                        </Stack>
                        <Divider.Vertical position={'sticky' as 'absolute'} top={0} solid alt />
                        <Stack flex={1} gap="$6">
                          <Stack gap="$2">
                            <Content />
                          </Stack>
                          <Divider alt />
                          {groups[selectedIndex].resourceLists.map(({ header, resources }, idx) => (
                            <Stack key={idx} gap="$4">
                              <UtilityText token="utility.label.xxsmall" textTransform="uppercase">
                                {header}
                              </UtilityText>
                              <Stack gap="$2">
                                {resources.map(({ label, ...rest }, idx) => (
                                  <Resource key={idx} {...rest}>
                                    <UtilityText
                                      onPress={() => setIsOpen(false)}
                                      alignSelf="flex-start"
                                      token="utility.hyperlink.small"
                                      tag="a"
                                    >
                                      {label}
                                    </UtilityText>
                                  </Resource>
                                ))}
                              </Stack>
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    </Stack>
                  </FloatingList>
                </FloatingFocusManager>
              </FloatingPortal>
            </MenuContext.Provider>
          )}
        </>
      );
    },
  ),
  {
    Text: NavItem.Text,
  },
);
