import { useQuery } from '@tanstack/react-query';

import { getFundsPropertyOccupancy } from '@arrived/api_v2';
import { OfferingCid } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { fundsPropertyOccupancyKey } from './properties.keys';

export function useFundPropertyOccupancyQuery(
  offeringCid: OfferingCid,
  options?: UseAwaitedQueryOptions<typeof getFundsPropertyOccupancy>,
) {
  return useQuery({
    queryKey: fundsPropertyOccupancyKey(offeringCid),
    queryFn: () => getFundsPropertyOccupancy(offeringCid),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
