import { useMemo } from 'react';

import { getInvestmentProductType } from '@arrived/common';
import { Offering } from '@arrived/models';

export function useInvestmentProductType(offering?: Offering) {
  return useMemo(
    () =>
      offering
        ? getInvestmentProductType({
            assetType: offering.assetType,
            offeringType: offering.type,
          })
        : undefined,
    [offering],
  );
}
