import { useCallback } from 'react';

import { useIntl } from 'react-intl';

import { GetProps, styled } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { enUS } from '@arrived/common';

import { ProductDetailContent } from '../../../content';

import { AnswerTextName } from './constants';

export type AnswerBodyTextProps = Omit<GetProps<typeof ProductDetailContent.Text>, 'children' | 'id'>;
export type FormattedAnswerProps = AnswerBodyTextProps & { id: keyof typeof enUS };

export const AnswerText = styled(ProductDetailContent.Text, { name: AnswerTextName });

export const FormattedAnswer = AnswerText.styleable<FormattedAnswerProps>(({ id, ...rest }, _) => {
  const intl = useIntl();

  const Text = useCallback(
    ({ children, ...passedRest }: GetProps<typeof ProductDetailContent.Text>) => (
      // The props passed from Markdown should override the ones passed to Answer directly.
      <AnswerText {...rest} {...passedRest}>
        {children}
      </AnswerText>
    ),
    [rest],
  );

  return <Markdown Text={Text}>{intl.formatMessage({ id })}</Markdown>;
});

export type AnswerProps = AnswerBodyTextProps & { children: string };

export const Answer = AnswerText.styleable<AnswerProps>(({ children, ...rest }, _) => {
  const Text = useCallback(
    ({ children, ...passedRest }: GetProps<typeof ProductDetailContent.Text>) => (
      // The props passed from Markdown should override the ones passed to Answer directly.
      <AnswerText {...rest} {...passedRest}>
        {children}
      </AnswerText>
    ),
    [rest],
  );

  return <Markdown Text={Text}>{children}</Markdown>;
});
