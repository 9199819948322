import { useContext } from 'react';

import { createStyledContext } from '@tamagui/core';

import { OfferingTransactState } from '@arrived/common';

export interface ProductWidgetStyledContextType {
  condensed?: boolean;
  simplified?: boolean;
  status?: OfferingTransactState;
}

export const ProductWidgetStyledContext = createStyledContext<ProductWidgetStyledContextType>({
  condensed: false,
  simplified: false,
  status: OfferingTransactState.PRE,
});

export const useProductWidgetStyledContext = () => useContext(ProductWidgetStyledContext);
