import { Offering } from '@arrived/models';

import { isRemainingSharesLessThanMinimumActionableShares } from './isRemainingSharesLessThanMinimumActionableShares';

export const calculateRemainingShares = (offering: Offering) => {
  const { totalShares, totalSharesTransacted } = offering;

  if (isRemainingSharesLessThanMinimumActionableShares(offering)) {
    return 0;
  }

  return totalShares - totalSharesTransacted;
};
