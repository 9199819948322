import { AssetType, Offering } from '@arrived/models';

/**
 * Keeping old code commented out here,
 * If this does not get touched in the next year or so,
 * we can probably take out the commented out portions.
 * Keeping comments here because I forsee potential different
 * iterations of the formulas being defined here.
 */

const ARRIVED_SOURCING_FEE = 0.06;
// const sellingFee = 0.06;
const PERIODS = 10;
// const defaultLeverage = 0.65;

export const appreciationToEquity = (appreciation: number, leverage: number) => {
  // RRI formula: https://gist.github.com/ghalimi/4643452
  const present = 1 - leverage + ARRIVED_SOURCING_FEE;
  const future = Math.pow(1 + appreciation, PERIODS) * (1 - ARRIVED_SOURCING_FEE) - leverage;
  return Math.pow(future / present, 1 / PERIODS) - 1;
};

export const calculatorAppreciationToEquity = (appreciation: number, offering: Offering) => {
  const {
    assetType,
    debtPercent,
    closingOfferingAndHoldingCosts,
    arrivedOneTimeProceeds,
    targetRaiseAmount,
    holdPeriodYearsMin,
    propertyImprovementsAndCashReserves,
  } = offering;

  const fees = closingOfferingAndHoldingCosts + arrivedOneTimeProceeds;
  const leverage = debtPercent / 100;

  const equityGrowthRate = (1 / (1 - leverage)) * appreciation - fees / (holdPeriodYearsMin * targetRaiseAmount);

  if (assetType === AssetType.LTR) {
    return equityGrowthRate;
  } else {
    // If the offering is an STR, the target equity growth rate is a % of the standard calculation.
    // This is because the raise amount includes additional money set aside for furnishing costs and
    // cash reserves that will not be appreciating at the same rate as the property asset itself.
    const strMultiplier = (targetRaiseAmount - propertyImprovementsAndCashReserves) / targetRaiseAmount;

    return strMultiplier * equityGrowthRate;
  }
};

// leverage / (1 - sellingFee) = (1 + x) ^ periods
// periods = Math.log10(leverage / ( 1 - sellingFee)) / Math.log10(1 + x)
// Math.log10(1 + x) = Math.log10(leverage / (1 - sellingFee)) / periods
// x  = Math.pow(Math.log10(leverage / (1 - sellingFee)) / periods, 10) - 1

// export const getMinimumAppreciation = (leverage = defaultLeverage) => {
//   const foundMinimumAppreciation = Math.pow(10, Math.log10(leverage / (1 - sellingFee)) / periods) - 1;
//   // Round up a little bit for JavaScript error
//   return Math.ceil(10000 * foundMinimumAppreciation) / 10000;
// };
