import { useMemo } from 'react';
import { UseAwaitedQueryOptions, useGetCurrentUserQuery, useGetQuestionnaireInvestorResponses } from '@arrived/queries';
import { Constants } from '@arrived/common';
import { getQuestionnaireInvestorResponse } from '@arrived/api_v2';
import { getInvestorGoalFromInvestorResponses, InvestorGoals } from '@arrived/models';

export function useGetUserInvestorGoal(
  options?: UseAwaitedQueryOptions<typeof getQuestionnaireInvestorResponse>,
): InvestorGoals | undefined {
  const currentUserState = useGetCurrentUserQuery();
  const personaQuestionnaireResponses = useGetQuestionnaireInvestorResponses(
    { questionnaireId: Constants.personaQuestionnaireId, userCid: currentUserState.data?.cid! },
    { ...options, enabled: (options?.enabled ?? true) && currentUserState.isSuccess },
  );

  return useMemo(
    () => getInvestorGoalFromInvestorResponses(personaQuestionnaireResponses.data),
    [personaQuestionnaireResponses.data],
  );
}
