import { ArrivedRudderIdentify, ArrivedRudderTrack, ArrivedRudderUserTraits } from './ArrivedRudder';
import { EventSource } from './EventProperties';

export function useIdentify(): ArrivedRudderIdentify {
  // If we pass userId as undefined it'll overwrite
  return (traits, userId) =>
    userId ? window.rudderanalytics?.identify?.(userId, traits) : window.rudderanalytics?.identify(traits || {});
}

export function useTrack(): ArrivedRudderTrack {
  return (eventName, eventProperties) =>
    window.rudderanalytics?.track?.(eventName, { ...eventProperties, client: EventSource.WEB });
}

export function useUserTraits(): () => Promise<ArrivedRudderUserTraits> {
  return async () => window.rudderanalytics?.getUserTraits?.();
}
