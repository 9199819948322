import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postCloneProperty } from '@arrived/api_v2';
import { propertiesKey } from '@arrived/queries';

import { propertyKeyFn } from './properties.keys';

export function usePostClonePropertyMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postCloneProperty,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: propertiesKey });
      queryClient.setQueryData(propertyKeyFn(data.id), data);
    },
  });
}
