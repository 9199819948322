import { forwardRef } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint, Stack, TamaguiElement, UtilityText, useDisclosure, useMedia } from '@arrived/bricks';
import { useHasOfferingPaidDividend, useInvestmentProductType } from '@arrived/hooks';
import { AccountOfferingBalance, InvestmentProductType, Offering } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';
import { ProductInfoBox, ProductInfoBoxProps } from '../../../../productInfoBox';
import { StyledDataPointLabel } from '../components';

import { DividendPaymentHistorySheet } from './DividendPaymentHistorySheet';

export type AssetDividendSectionProps = ProductDetailContentProps & {
  offering: Offering;
  offeringBalance: AccountOfferingBalance;
};

// Can't use `styled` here because something happens where the context value for ProductInfoBoxStyledContext seems to disappear and the
// header no longer sees that it's a link
const StyledInfoBox = forwardRef<TamaguiElement, ProductInfoBoxProps>((props, ref) => (
  <ProductInfoBox ref={ref} flexGrow={1} flexShrink={1} $gtXs={{ flexBasis: 0 }} {...props} />
));

export const AssetDividendSection = ({ offering, offeringBalance, ...rest }: AssetDividendSectionProps) => {
  const media = useMedia();
  const investmentProductType = useInvestmentProductType(offering);

  const hasOfferingPaidDividend = useHasOfferingPaidDividend(offering.id);

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <ProductDetailContent {...rest}>
        <ProductDetailContent.Header>
          <FormattedMessage id="product-details.asset-and-dividend" />
        </ProductDetailContent.Header>
        {/* TODO: Can't do $gtXxs={{ row: true }} here because that overrides the default $gtXxs in ProductDetailContent.Body so we'd have to add the px: '$6' explicitly */}
        <ProductDetailContent.Body gap="$3" row={media.gtXs}>
          <StyledInfoBox>
            <ProductInfoBox.Header>
              <FormattedMessage id="product-details.asset" />
            </ProductInfoBox.Header>
            <DataPoint colors="dark">
              <DataPoint.Value>
                <FormattedNumber value={offeringBalance.totalSharesCount} />
              </DataPoint.Value>
              <StyledDataPointLabel>
                <FormattedMessage id="product-details.your-shares-count" />
              </StyledDataPointLabel>
            </DataPoint>
          </StyledInfoBox>
          <StyledInfoBox onPress={onOpen}>
            <ProductInfoBox.Header>
              <FormattedMessage id="product-details.dividend" />
            </ProductInfoBox.Header>
            {hasOfferingPaidDividend ? (
              <Stack row justifyContent="space-between" gap="$4">
                <DataPoint flex={1} colors="dark">
                  <DataPoint.Value>
                    <FormattedNumber
                      value={offeringBalance.rentalIncome.latestDividend?.totalDividendsPaid ?? 0}
                      style="currency"
                      currency="USD"
                      maximumFractionDigits={2}
                      minimumFractionDigits={2}
                    />
                  </DataPoint.Value>
                  <StyledDataPointLabel>
                    <FormattedMessage id="product-details.your-last-payment" />
                  </StyledDataPointLabel>
                </DataPoint>
                <DataPoint flex={1} colors="dark" alignment="right">
                  <DataPoint.Value>
                    <FormattedNumber
                      value={offeringBalance.rentalIncome.totalDividendsPaid}
                      style="currency"
                      currency="USD"
                      maximumFractionDigits={2}
                      minimumFractionDigits={2}
                    />
                  </DataPoint.Value>
                  <StyledDataPointLabel>
                    <FormattedMessage id="product-details.your-all-time-payment" />
                  </StyledDataPointLabel>
                </DataPoint>
              </Stack>
            ) : (
              <>
                {investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO && (
                  <UtilityText token="utility.helper.medium" color="$onSurface.neutral.defaultAlt" textAlign="left">
                    <FormattedMessage id="product-details.dividend-eligibility-sfr" />
                  </UtilityText>
                )}
                {investmentProductType === InvestmentProductType.VACATION_RENTAL_IPO && (
                  <UtilityText token="utility.helper.medium" color="$onSurface.neutral.defaultAlt" textAlign="left">
                    <FormattedMessage id="product-details.dividend-eligibility-vr" />
                  </UtilityText>
                )}
                {investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND && (
                  <UtilityText token="utility.helper.medium" color="$onSurface.neutral.defaultAlt" textAlign="left">
                    <FormattedMessage id="product-details.dividend-eligibility-sfr-fund" />
                  </UtilityText>
                )}
              </>
            )}
          </StyledInfoBox>
        </ProductDetailContent.Body>
      </ProductDetailContent>
      <DividendPaymentHistorySheet
        offering={offering}
        offeringBalance={offeringBalance}
        open={isOpen}
        onClose={onClose}
      />
    </>
  );
};
