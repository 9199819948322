import { BodyText, Divider, InfoBox, Row, Stack, UtilityText, styled } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { PrivateCreditFundMetadata } from '@arrived/models';

import { ProductDetailContent } from '../../../../../content';

const DisclaimerText = styled(BodyText, {
  token: 'body.compact.small',
  color: '$onSurface.neutral.muted',
});

export const AnnualizedReturns = ({
  annualizedReturnScenarios: { description, disclaimer, returns, tableHeaders, title },
}: {
  annualizedReturnScenarios: NonNullable<PrivateCreditFundMetadata['annualizedReturnScenarios']>;
}) => (
  <>
    <ProductDetailContent.SubHeader>{title}</ProductDetailContent.SubHeader>
    {description && <Markdown Text={ProductDetailContent.Text}>{description}</Markdown>}
    {Boolean(returns.length) && (
      <Stack gap="$2">
        {tableHeaders && Boolean(tableHeaders.length) && (
          <Stack px="$2" row justifyContent="space-between" alignItems="flex-end" gap="$4">
            {tableHeaders.map((header, idx) => (
              <UtilityText token="utility.label.small" key={idx}>
                {header}
              </UtilityText>
            ))}
          </Stack>
        )}
        <Divider solid alt />
        <Stack gap="$2">
          {returns.map(({ label, value }, idx) => (
            <InfoBox p={0} key={idx}>
              <Row>
                <Row.Label>{label}</Row.Label>
                <Row.Value>{value}</Row.Value>
              </Row>
            </InfoBox>
          ))}
        </Stack>
      </Stack>
    )}
    {disclaimer && <Markdown Text={DisclaimerText}>{disclaimer}</Markdown>}
  </>
);
