import { FormattedMessage, FormattedNumber } from 'react-intl';

import { BodyText, Stack } from '@arrived/bricks';
import { Offering } from '@arrived/models';

export const PropertyMonetaryAvailabilityInfo = ({ offering }: { offering: Offering }) => (
  <Stack>
    <BodyText>
      <FormattedMessage
        id="admin.percent-shares-purchased"
        defaultMessage="{fundedPercentage} shares purchased"
        values={{
          fundedPercentage: (
            <FormattedNumber
              value={Math.floor(offering.fundedPercentNoDebt) / 100}
              style="percent"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          ),
        }}
      />
    </BodyText>
  </Stack>
);
