import { CommonQuestion } from '@arrived/models';

import { Answer } from './Answer';
import { CommonQuestionsProps } from './CommonQuestions';
import { QuestionTitle } from './Question';

/**
 * Takes a parameter of an array of common question objects that we use in our SST Offering
 * metadata models and returns a new list of Question/Answer React Components that can be passed to
 * the CommonQuestionsSection component.
 */
export function getMetadataCommonQuestions(commonQuestions: CommonQuestion[]): CommonQuestionsProps['questions'] {
  return commonQuestions.map(({ question, answer }) => ({
    Question: (props) => <QuestionTitle {...props}>{question}</QuestionTitle>,
    Answer: (props) => <Answer {...props}>{answer}</Answer>,
  }));
}
