import { UtilityText as BricksUtilityText, Stack, StackProps, UtilityTextProps } from '@arrived/bricks';

import type { RegisteredComponent } from '../sdk';

/**
 * This is a temporary fix for the `builder.io` package to allow for the legacy components to be used.
 * Once the `builder.io` package is updated to use the new components, this can be removed. These
 * are mainly found with the `Article` component.
 */

/**
 * @deprecated -- Use `Text` instead
 */
export const UtilityText = (props: UtilityTextProps) => <BricksUtilityText {...props} />;

UtilityText.registerComponent = {
  component: UtilityText,
  name: 'Utility Text',
  canHaveChildren: true,
  hideFromInsertMenu: true,
  static: true,
  noWrap: true,
  inputs: [
    {
      name: 'text',
      type: 'longText',
      required: true,
      defaultValue: '',
    },
  ],
} satisfies RegisteredComponent;

const spacingOptions = ['$1', '$2', '$3', '$4', '$5', '$6'];
const spacingParams = [
  'marginLeft',
  'marginRight',
  'marginBottom',
  'marginTop',
  'paddingLeft',
  'paddingRight',
  'paddingBottom',
  'paddingTop',
  'gap',
];

const supportedParams = ['children', ...spacingParams];

const commonInputs = spacingParams.map((option) => ({
  name: option,
  type: 'enum',
  enum: spacingOptions,
}));

/**
 * @deprecated -- Use literally any other layout component instead
 */
export const BricksLayout = (props: StackProps) => {
  const pluckedProps: StackProps = {};
  supportedParams.forEach((key) => {
    // @ts-ignore only supported attributes should be passed to Stack
    pluckedProps[key] = props[key];
  });
  return <Stack {...pluckedProps} />;
};

BricksLayout.registerComponent = {
  name: 'Bricks Layout',
  component: BricksLayout,
  canHaveChildren: true,
  hideFromInsertMenu: true,
  inputs: [
    {
      name: 'row',
      type: 'boolean',
    },
    ...commonInputs,
    {
      name: 'justifyContent',
      type: 'text',
      enum: ['center', 'space-between', 'space-around', 'space-evenly', 'flex-start'],
    },
  ],
} satisfies RegisteredComponent;
