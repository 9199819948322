import { useCallback, useId, useState } from 'react';

import { StackProps } from '../atoms';

import { useCallbackRef } from './useCallbackRef';

export type UseDisclosureProps = {
  /**
   * Status of the disclosure
   */
  isOpen?: boolean;

  /**
   * The initial state of `isOpen`
   * @default false
   */
  defaultIsOpen?: boolean;

  /**
   * Callback fired when the disclosure opens
   */
  onClose?(): void;

  /**
   * Callback fired when the disclosure closes
   */
  onOpen?(): void;

  /**
   * Override the `useId` hook to generate the `id`
   */
  id?: string;
};

/**
 * `useDisclosure` is a hook that provides common feedback scenarios for `Modal`, `AlertDialog`,
 * `Drawer`, etc. We recommend using this anywhere you need to show or hide a component based on
 * user interaction.
 */
export function useDisclosure({
  onClose: onCloseProp,
  onOpen: onOpenProp,
  isOpen: isOpenProp,
  id: idProp,
  defaultIsOpen,
}: UseDisclosureProps = {}) {
  const uid = useId();

  const [isOpenState, setIsOpen] = useState(defaultIsOpen || false);

  const handleOpen = useCallbackRef(onOpenProp);
  const handleClose = useCallbackRef(onCloseProp);

  const isOpen = isOpenProp !== undefined ? isOpenProp : isOpenState;
  const isControlled = isOpenProp !== undefined;
  const id = idProp ?? `disclosure-${uid}`;

  const onClose = useCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    handleClose?.();
  }, [isControlled, handleClose]);

  const onOpen = useCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    handleOpen?.();
  }, [isControlled, handleOpen]);

  const onToggle = useCallback(() => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }, [isOpen, onOpen, onClose]);

  const getButtonProps = (props: StackProps = {}): StackProps => ({
    ...props,
    'aria-expanded': isOpen,
    onPress(event) {
      props.onPress?.(event);
      onToggle();
    },
  });

  const getDisclosureProps = (props: StackProps = {}): StackProps => ({
    ...props,
    'aria-hidden': !isOpen,
    id,
  });

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
    isControlled,
    getButtonProps,
    getDisclosureProps,
  };
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>;
