import { ReactNode } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import {
  FundStatus,
  Offering,
  OfferingTransaction,
  OfferingTransactionFundStatus,
  TransactionStatus,
} from '@arrived/models';

import { formatUserDateFromString } from '../dateUtils';
import { getFundStatusDisplayName } from '../getFundStatusDisplayName';
import { getTradeFundStatus, isTradeNeedsFunding } from '../transaction';

interface TradeDetailsInfo {
  key: ReactNode;
  value: ReactNode;
  error?: boolean;
}

export const getTradeDetailsInfoList = (
  transaction: OfferingTransaction,
  offering: Offering,
  renderTradeStatus: (status: TransactionStatus) => ReactNode,
  tradeFundStatuses: OfferingTransactionFundStatus[],
): TradeDetailsInfo[] => {
  const tradeFundStatus = getTradeFundStatus(transaction, tradeFundStatuses);

  const tradeInfo = [
    {
      key: <FormattedMessage id="account.transaction.details.info.offering.name" defaultMessage="Offering Name" />,
      value: <>{offering.name}</>,
    },
    {
      key: <FormattedMessage id="account.transaction.details.info.purchase.date" defaultMessage="Purchase Date" />,
      value: <>{formatUserDateFromString(transaction.createdAt, 'PPPp')}</>,
    },
    {
      key: (
        <FormattedMessage id="account.transaction.details.info.transaction.type" defaultMessage="Transaction Type" />
      ),
      value: <>{transaction.type}</>,
    },
    transaction.purchaseAmount !== null &&
      transaction.promotionCode && {
        key: (
          <FormattedMessage id="account.transaction.details.info.promotion.code" defaultMessage="Promotion Code Used" />
        ),
        value: transaction.promotionCode,
      },
    {
      key: <FormattedMessage id="account.transaction.details.info.total.amount" defaultMessage="Total Amount" />,
      value: <FormattedNumber style="currency" currency="USD" value={transaction.totalAmount} />,
    },
    transaction.purchaseAmount !== null &&
      transaction.discountAmount !== null &&
      transaction.purchaseAmount !== transaction.totalAmount &&
      transaction.discountAmount !== 0 && {
        key: (
          <FormattedMessage id="account.transaction.details.info.discount.amount" defaultMessage="Discount Amount" />
        ),
        value: <FormattedNumber style="currency" currency="USD" value={-transaction.discountAmount} />,
      },
    transaction.purchaseAmount !== null &&
      transaction.discountAmount !== null &&
      transaction.purchaseAmount !== transaction.totalAmount &&
      transaction.discountAmount !== 0 && {
        key: (
          <FormattedMessage id="account.transaction.details.info.purchase.amount" defaultMessage="Purchase Amount" />
        ),
        value: <FormattedNumber style="currency" currency="USD" value={transaction.purchaseAmount} />,
      },
    {
      key: <FormattedMessage id="account.transaction.details.info.shares.count" defaultMessage="Shares Count" />,
      value: <FormattedNumber value={transaction.sharesCount} />,
    },
    {
      key: <FormattedMessage id="account.transaction.details.info.share.price" defaultMessage="Share Price" />,
      value: <FormattedNumber style="currency" currency="USD" value={transaction.sharePrice} />,
    },
    {
      key: <FormattedMessage id="account.transaction.details.info.trade.status" defaultMessage="Trade Status" />,
      value: renderTradeStatus(transaction.status),
    },
    {
      key: <FormattedMessage id="account.transaction.details.info.funding.status" defaultMessage="Funding Status" />,
      value: getFundStatusDisplayName(transaction.fundStatus),
      error:
        transaction.fundStatus === FundStatus.RETURNED ||
        transaction.fundStatus === FundStatus.DECLINED ||
        isTradeNeedsFunding(transaction),
    },
    tradeFundStatus.error && {
      key: <FormattedMessage id="account.transaction.details.info.funding.error" defaultMessage="Funding Error" />,
      value: (
        <>
          {tradeFundStatus.errorMessage} ({tradeFundStatus.errorCode})
        </>
      ),
      error: true,
    },
  ].filter(Boolean) as TradeDetailsInfo[];

  return tradeInfo;
};
