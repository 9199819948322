import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { Offering } from '@arrived/models';

import { getReturnsData } from './getReturnsData';
import { ReturnsCalculatorForm } from './ReturnsCalculatorForm';

export interface UseReturnsCalculatorFormOptions {
  /**
   * If the Offering provided has no projectedAnnualDividendYield property, this value will be used as the default for
   * rentalDividendYield;
   */
  defaultRentalDividendYield: number;
  /**
   * This is the default value used by the form for annualAppreciation.
   */
  defaultAnnualAppreciation: number;
}

export function useReturnsCalculatorForm(
  offering: Offering,
  { defaultAnnualAppreciation, defaultRentalDividendYield }: UseReturnsCalculatorFormOptions,
) {
  const form = useForm<ReturnsCalculatorForm>({
    defaultValues: {
      investmentAmount: 1000,
      rentalDividendYield: offering.projectedAnnualDividendYield ?? defaultRentalDividendYield,
      annualAppreciation: defaultAnnualAppreciation,
      leverage: offering.debtPercent > 0,
    },
  });

  const { reset, watch } = form;

  const [investmentAmount, rentalDividendYield, annualAppreciation, leverage] = watch([
    'investmentAmount',
    'rentalDividendYield',
    'annualAppreciation',
    'leverage',
  ]);

  useEffect(() => {
    reset({
      investmentAmount: 1000,
      rentalDividendYield: offering.projectedAnnualDividendYield ?? defaultRentalDividendYield,
      annualAppreciation: defaultAnnualAppreciation,
      leverage: offering.debtPercent > 0,
    });
  }, [offering]);

  const { irr, cumulativeReturns } = useMemo(
    () =>
      getReturnsData({
        annualAppreciation,
        investmentAmount,
        leverage,
        offering,
        rentalDividendYield,
      }),
    [annualAppreciation, investmentAmount, leverage, offering, rentalDividendYield],
  );

  return useMemo(() => ({ form, irr, cumulativeReturns }), [form, irr, cumulativeReturns]);
}
