import { useCallback, useMemo } from 'react';
import { GestureResponderEvent } from 'react-native';

import { generatePath, useNavigate } from 'react-router-dom';

import { SHARED_ROUTES } from '@arrived/common';

export const useHandleRedemptionsFlowNavigation = (idOrShortName?: string) => {
  const navigate = useNavigate();

  const redemptionsFlowPath = useMemo(() => {
    return generatePath(SHARED_ROUTES.redemptions.SheetRoot, {
      idOrShortName: idOrShortName ?? '',
    });
  }, [idOrShortName]);

  return {
    href: redemptionsFlowPath,
    onPress: useCallback<(e: GestureResponderEvent) => void>(
      (e) => {
        e.preventDefault();
        navigate(redemptionsFlowPath);
      },
      [navigate, redemptionsFlowPath],
    ),
  };
};
