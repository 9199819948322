import { GetProps, Stack, styled, withStaticProperties } from '@arrived/bricks';

import { ProductDetailContentBody } from './Body';
import { ContentName } from './constants';
import { ProductDetailContentDivider } from './Divider';
import { Header } from './Header';
import { SectionHeader } from './SectionHeader';
import { SubHeader } from './SubHeader';
import { Text } from './Text';

const ProductDetailContentFrame = styled(Stack, {
  name: ContentName,

  borderColor: '$onSurface.neutral.outlineAlt',

  variants: {
    border: {
      always: {
        borderWidth: '$0.25',
        br: '$1',
        mx: '$4',
        $gtXs: {
          mx: 0,
        },
      },
      default: {
        borderWidth: 0,

        $gtSm: {
          borderWidth: '$0.25',
          br: '$1',
        },
      },
      never: {
        borderWidth: 0,
      },
    },
  } as const,
  defaultVariants: {
    border: 'default',
  },
});

export const ProductDetailContent = withStaticProperties(ProductDetailContentFrame, {
  Body: ProductDetailContentBody,
  Divider: ProductDetailContentDivider,
  Header,
  SectionHeader,
  SubHeader,
  Text,
});
export type ProductDetailContentProps = GetProps<typeof ProductDetailContent>;
