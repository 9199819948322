import { useQuery } from '@tanstack/react-query';

import { getResponseOption } from '@arrived/api_v2';
import { ResponseOptionId } from '@arrived/models';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { responseOptionKeyFn } from './questionnaires.keys';

export function useGetResponseOptionQuery(
  responseOptionId: ResponseOptionId,
  options?: UseAwaitedQueryOptions<typeof getResponseOption>,
) {
  return useQuery({
    queryKey: responseOptionKeyFn(responseOptionId),
    queryFn: () => getResponseOption(responseOptionId),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
