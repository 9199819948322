import { AccountType } from '@arrived/models';

export const AccountTypeTranslations = {
  [AccountType.INDIVIDUAL]: 'Individual',
  [AccountType.ENTITY]: 'Entity',
  [AccountType.TIC]: 'Individual TIC',
  [AccountType.JTWROS]: 'Individual Joint w/ROS',
  [AccountType.IRA]: 'Individual IRA',
  [AccountType.SEP_IRA]: 'Individual SEP IRA',
  [AccountType.ROTH]: 'Individual Roth',
  [AccountType.JOINT]: 'Individual Roth Joint',
  [AccountType.CHECKBOOK_IRA]: 'Checkbook IRA',
} as const;

export const formatAccountType = (type?: AccountType) => (type && AccountTypeTranslations[type]) ?? 'N/A';
