import { useEffect, useMemo, useState } from 'react';

import { useCashAccount } from '@arrived/cash-account-lib';
import { UserOfferingTransactState } from '@arrived/common';
import { FEATURE_FLAGS, useGrowthBook } from '@arrived/feature-flags';
import { useGetPrimaryAccountQuery } from '@arrived/queries';

export const ProductWidgetButtonCTATestVariant = {
  CONTROL: 'CONTROL',
  VARIANT: 'VARIANT',
} as const;

export type ProductWidgetButtonCTATestVariant =
  (typeof ProductWidgetButtonCTATestVariant)[keyof typeof ProductWidgetButtonCTATestVariant];

/**
 * This hook handles the assignment of users to an experimental group for the ProductWidgetButton
 * CTA test. In this test users who have an available cash account balance will be shown a CTA on
 * the IDP that either says the control ("Invest") or the variant ("Reinvest Dividends"). The
 * logic for adding users to this test is isolated in this hook as we need to make sure that only
 * users who have an available cash account balance and are able to transact on the offering.
 */
export function useProductWidgetButtonCTATest(userOfferingTransactState?: UserOfferingTransactState) {
  const gb = useGrowthBook();
  const primaryAccountState = useGetPrimaryAccountQuery();
  const cashAccountState = useCashAccount(primaryAccountState.data?.cashAccountCid);

  const [productWidgetButtonCTA, setProductWidgetButtonCTA] = useState<ProductWidgetButtonCTATestVariant>();

  const availableBalance = useMemo(
    () => parseFloat(cashAccountState.data?.projections?.availableBalance ?? '0'),
    [cashAccountState.data?.projections?.availableBalance],
  );

  useEffect(() => {
    if (gb?.ready && availableBalance > 0 && userOfferingTransactState === UserOfferingTransactState.TRANSACT) {
      setProductWidgetButtonCTA(
        gb.evalFeature(FEATURE_FLAGS.FUND_CTA_WITH_CASH_BALANCE).on
          ? ProductWidgetButtonCTATestVariant.VARIANT
          : ProductWidgetButtonCTATestVariant.CONTROL,
      );
    }
  }, [cashAccountState.data?.projections?.availableBalance, gb?.ready, userOfferingTransactState]);

  return productWidgetButtonCTA;
}
