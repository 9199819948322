import { useMemo } from 'react';

import { getQuestionnaireInvestorResponse } from '@arrived/api_v2';
import { Constants } from '@arrived/common';
import { InvestorArchetype, getInvestorArchetypeFromInvestorResponses } from '@arrived/models';
import { UseAwaitedQueryOptions, useGetCurrentUserQuery, useGetQuestionnaireInvestorResponses } from '@arrived/queries';

export function useGetInvestorArchetype(
  options?: UseAwaitedQueryOptions<typeof getQuestionnaireInvestorResponse>,
): InvestorArchetype | undefined {
  const currentUserState = useGetCurrentUserQuery();
  const personaQuestionnaireResponses = useGetQuestionnaireInvestorResponses(
    { questionnaireId: Constants.personaQuestionnaireId, userCid: currentUserState.data?.cid! },
    { ...options, enabled: (options?.enabled ?? true) && currentUserState.isSuccess },
  );

  return useMemo(
    () => getInvestorArchetypeFromInvestorResponses(personaQuestionnaireResponses.data),
    [personaQuestionnaireResponses.data],
  );
}
