import { ForwardedRef, useCallback, useRef } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';

import Animated, {
  scrollTo,
  useAnimatedReaction,
  useAnimatedRef,
  useAnimatedScrollHandler,
  useSharedValue,
} from 'react-native-reanimated';

import { GetProps, TamaguiElement, composeEventHandlers, styled, useMedia } from '@tamagui/core';

import { Stack } from '../../atoms';
import { tokens } from '../../tokens';

import { SIDEBAR_FRAME_NAME, SIDEBAR_ITEM_CONTENT_NAME, SIDEBAR_ITEM_NAME } from './consants';
import { useGalleryState } from './GalleryStateContext';
import { RenderItem } from './types';

const SidebarFrame = styled(Stack, {
  name: SIDEBAR_FRAME_NAME,

  height: '100%',
});

const SidebarItem = styled(Stack, {
  name: SIDEBAR_ITEM_NAME,

  overflow: 'hidden',

  cursor: 'pointer',
  width: '100%',

  // @ts-expect-error -- still erroring at 1.98.0
  group: 'sidebarItem',

  '$platform-web': {
    transform: [
      // @ts-ignore -- enabled for web to allow smooth scrolling
      { translateZ: 0 },
      // @ts-ignore -- enabled for web to allow smooth scrolling
      { perspective: 1000 },
    ],
  },

  backfaceVisibility: 'hidden',
});

const SidebarItemContent = styled(Stack, {
  name: SIDEBAR_ITEM_CONTENT_NAME,

  flex: 1,

  // @ts-expect-error
  '$group-sidebarItem-hover': {
    scale: 1.02,
  },

  '$group-sidebarItem-focus': {
    scale: 1.02,
  },

  '$group-sidebarItem-press': {
    scale: 1.01,
  },

  style: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
});

export type SidebarProps<T> = Omit<GetProps<typeof SidebarFrame>, 'height'> & {
  renderItem: RenderItem<T>;
  SidebarItemProps?: GetProps<typeof SidebarItem>;
};

const SidebarImpl = <T,>(
  { gap, renderItem, SidebarItemProps, ...props }: SidebarProps<T>,
  ref: ForwardedRef<TamaguiElement>,
) => {
  const sidebarRef = useAnimatedRef<Animated.FlatList<T>>();

  const media = useMedia();

  const { items, currentIndex, snapIndex, carouselRef } = useGalleryState<T>();

  const { onPress: SidebarItemOnPress, onLayout: SidebarItemOnLayout, ...SidebarItemRest } = SidebarItemProps || {};

  const itemLayouts = useRef<LayoutRectangle[]>([]);

  const handleSidebarItemLayoutEvent = useCallback((e: LayoutChangeEvent, index: number) => {
    itemLayouts.current[index] = e.nativeEvent.layout;
  }, []);

  const handleThumbnailPress = useCallback(
    (selectedIndex: number) => {
      const count = selectedIndex - currentIndex.value;

      carouselRef.current?.scrollTo({
        count, // Count will be positive for forward, negative for backward
        animated: true,
      });
    },
    [carouselRef, currentIndex],
  );

  const scrollOffset = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      scrollOffset.value = event.contentOffset.y;
    },
  });

  useAnimatedReaction(
    () => snapIndex.value,
    (current, previous) => {
      if (sidebarRef.current && current !== previous) {
        const gapValue = Object.values(tokens.space).find(({ key }) => key === gap)?.val ?? 0;
        const sidebarOffsetY = itemLayouts.current.reduce(
          (totalOffset, { height }, itemIndex) => totalOffset + (current > itemIndex ? height + gapValue : 0),
          0,
        );
        scrollTo(sidebarRef, 0, sidebarOffsetY, true);
      }
    },
    [gap, snapIndex, items],
  );

  const ItemSeparator = useCallback(() => <Stack pt={gap} />, [gap]);

  if (!media.gtXs) {
    console.log('Smaller devices cannot display a sidebar, this is just a heads up.');
    return null;
  }

  return (
    <SidebarFrame ref={ref} {...props}>
      <Animated.FlatList
        ref={sidebarRef}
        data={items}
        initialNumToRender={5}
        showsVerticalScrollIndicator={false}
        removeClippedSubviews={false}
        viewabilityConfig={{
          itemVisiblePercentThreshold: 50,
        }}
        renderItem={({ item, index }) => (
          <SidebarItem
            onLayout={composeEventHandlers(
              (e: LayoutChangeEvent) => handleSidebarItemLayoutEvent(e, index),
              SidebarItemOnLayout,
            )}
            onPress={composeEventHandlers(() => handleThumbnailPress(index), SidebarItemOnPress)}
            {...SidebarItemRest}
          >
            <SidebarItemContent>
              {itemLayouts.current[index] &&
                renderItem?.({ item, index, animationValue: scrollOffset, dimensions: itemLayouts.current[index] })}
            </SidebarItemContent>
          </SidebarItem>
        )}
        ItemSeparatorComponent={ItemSeparator}
        onScroll={scrollHandler}
      />
    </SidebarFrame>
  );
};

// Fixes weird child typing issue where `Element` could not be assigned to `undefined`
export const Sidebar = SidebarFrame.styleable(SidebarImpl) as typeof SidebarImpl;
