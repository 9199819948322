import { useQuery } from '@tanstack/react-query';

import { GetSalesQueryParams, getSales } from '@arrived/api_v2';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { adminSalesQueryKeyFn } from './sales.keys';

export function useAdminGetSalesQuery(
  queryParams?: GetSalesQueryParams,
  options?: UseAwaitedQueryOptions<typeof getSales>,
) {
  return useQuery({
    queryKey: adminSalesQueryKeyFn(queryParams),
    queryFn: () => getSales(queryParams),
    ...options,
  });
}
