import { ReactNode } from 'react';

import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

import { ApiError } from '@arrived/models';

export const notificationService = {
  /**
   * Any client facing success messages, used as `notificationService.success('message')``
   */
  success: (message: ReactNode, options?: ToastOptions<{}> | undefined) =>
    toast.success(message, {
      className: 'notification-success-body',
      ...options,
    }),

  /**
   * Any client facing errors, used as `notificationService.errors(['message'])``
   */
  errors: (errorMessages: ReactNode[], options?: ToastOptions<{}> | undefined) =>
    errorMessages.forEach((error) =>
      toast.error(error, {
        className: 'notification-error-body',
        ...options,
      }),
    ),

  /**
   * Generally API response errors, used as `notificationService.error('message')``
   */
  error: (error: ApiError | ReactNode, options?: ToastOptions<{}> | undefined) => {
    if (!error) {
      return;
    }

    const text = typeof error === 'object' && 'description' in error ? error.description : (error as ReactNode);

    return toast.error(text, {
      className: 'notification-error-body',
      ...options,
    });
  },
};
