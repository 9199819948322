import { forwardRef } from 'react';

import { Marker as MapboxMarker } from 'react-map-gl';

import { TamaguiElement } from '@arrived/bricks';

import { MarkerPin } from './MarkerPin';
import { MarkerProps } from './MarkerProps';

export const Marker = forwardRef<TamaguiElement, MarkerProps>(({ coordinates, ...rest }, ref) => (
  <MapboxMarker {...coordinates}>
    <MarkerPin ref={ref} {...rest} />
  </MapboxMarker>
));
