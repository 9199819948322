import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteProperty } from '@arrived/api_v2';
import { UseAwaitedMutationOptions, propertiesKey } from '@arrived/queries';

import { propertyKeyFn } from './properties.keys';

export function useDeletePropertyMutation(options?: UseAwaitedMutationOptions<typeof deleteProperty>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteProperty,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: propertiesKey });
      queryClient.removeQueries({ queryKey: propertyKeyFn(variables) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
