import { useMemo } from 'react';

import { useRouter } from 'next/router';

import { useFeature } from '@arrived/feature-flags';

import { abPageTestSearchParamsKey } from '../../util';

/**
 * Shell component that invokes the `useFeature` function in order to track an experiment_viewed event for the landing
 * page A/B tests. It checks the search params for the feature flag test key and passes that onto the `useFeature` hook
 * which will call the tracking callback to track the experiment_viewed event.
 */
export const LandingPageTestTracker = () => {
  const router = useRouter();

  const featureFlagKey = useMemo(() => router.query?.[abPageTestSearchParamsKey] as string, [router.query]);

  useFeature(featureFlagKey ?? '');

  return null;
};
