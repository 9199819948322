import { useQuery } from '@tanstack/react-query';

import { getPrimaryAccount } from '@arrived/api_v2';
import { UserId } from '@arrived/models';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { adminAccountsPrimaryKeyFn } from './users.keys';

export function useAdminGetPrimaryAccountQuery(
  userId?: UserId,
  options?: UseAwaitedQueryOptions<typeof getPrimaryAccount>,
) {
  return useQuery({
    queryKey: adminAccountsPrimaryKeyFn(userId!),
    queryFn: () => getPrimaryAccount(userId!),
    ...options,
    enabled: (options?.enabled ?? true) && userId != null,
  });
}
