import { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { differenceInYears, format } from 'date-fns';

import { Stack, Typography } from '@mui/material';

import { US_DATE_FORMAT } from '@arrived/common';

interface RegDSuitabilityQuestionnaireDateViewProps {
  createdAt: string | null;
}

export const RegDSuitabilityQuestionnaireDateView = (props: RegDSuitabilityQuestionnaireDateViewProps) => {
  const isOverAYearAgo = useMemo(() => {
    if (!props.createdAt) {
      return undefined;
    }
    return differenceInYears(new Date(), new Date(props.createdAt)) >= 1;
  }, [props.createdAt]);

  if (props.createdAt == null) {
    return <FormattedMessage id="common.not-applicable" />;
  }
  const createdAtDate = new Date(props.createdAt);
  return (
    <>
      <Stack>
        {isOverAYearAgo && (
          <Typography color="danger">
            <FormattedMessage id="account.created-more-than-one-year-ago" />
          </Typography>
        )}
        <Typography>{format(createdAtDate, US_DATE_FORMAT)}</Typography>
      </Stack>
    </>
  );
};
