export default {
  'redemption.ineligible.header': 'Looks like you aren’t eligible for redemption yet.',
  'redemption.ineligible.description':
    'Each Fund transaction requires a 6-month hold to be eligible for redemption. You currently have no eligible transactions, and the earliest one will qualify on {eligibleAfterDate}.{br}Please note that this is a long-term investment and early redemptions are subject to fees.',
  'redemption.existing.header': 'Looks like you have a pending redemption request already.',
  'redemption.existing.description':
    'Only one redemption request can be submitted per offering during each review period. If you would like to edit your current redemption request of {existingRedemptionAmount}, you must cancel and resubmit a new request.',
  'redemption.existing.edit-request-button': 'Cancel existing request',
  'redemption.existing.cancellation-success': 'Existing redemption request cancelled',
  'redemption.existing.cancellation-failure': 'There was an error cancelling your redemption request: {error}',
  'redemption.intro.header': 'Request Redemption',
  'redemption.intro.description':
    'You can redeem all or a portion of your eligible shares. If your request is approved, they will be redeemed at the current share price by {paymentWindowEnd}.{br}Arrived can only redeem a maximum of 5% of the total net assets of the fund per quarter. If we are oversubscribed, we may edit your redemption request amount to stay within legal limits.{br}If you have any questions please visit our FAQ.',
  'redemption.details.header': 'Things to keep in mind',
  'redemption.details.description':
    "Depending on how long you've held your shares, a fee may be deducted from the total value of your redemption.",
  'redemption.fees.2-percent-timeframe': '6 Months - 1 Year',
  'redemption.fees.1-percent-timeframe': '1 Year - 5 Years',
  'redemption.fees.0-percent-timeframe': 'More than 5 Years',
  'redemption.review-period.header': 'The redemption window is currently closed',
  'redemption.review-period.description':
    'New redemption requests can be submitted once the current review cycle ends on {paymentWindowEnd}.',
  'redemption.review-period.existing.header': 'Looks like you have a redemption request under review already',
  'redemption.review-period.existing.description':
    "You aren't able to cancel or edit your existing request, or submit new requests, until the current review cycle ends on {paymentWindowEnd}.",

  'redemption.request.header': 'Request Redemption',

  'redemption.share-count.header': 'How Much?',
  'redemption.share-count.input.label': 'Shares',
  'redemption.share-count.input.description':
    '{numSharesAvailable} out of {numSharesOwned} shares available for the redemption period of {periodStartDate} to {periodEndDate}.',
  'redemption.share-count.input.tooltip':
    'You currently own {numSharesOwned} shares in {offeringName}. However, only {numSharesEligible} of those shares have met the 6-month minimum hold required for redemption eligibility.',
  'redemption.share-count.current-share-price': 'Current valuation at {perUnit}/share',
  'redemption.share-count.validation.required': 'Please specify a valid number of shares.',
  'redemption.share-count.validation.minimum':
    'You must select a number of shares larger than or equal to {minShareCount}.',
  'redemption.share-count.validation.maximum':
    'You must select a number of shares less than or equal to {maxShareCount}.',
  'redemption.share-count.validation.maximum-owned':
    'You must select a number of shares less than or equal to your total eligible shares.',
  'redemption.share-count.validation.maximum-dollar-amount':
    'You must select a number of shares whose value is less than or equal to {maxDollarAmount}.',

  'redemption.reason.header': "What's motivating you to redeem?",
  'redemption.reason.description': 'This will not affect your redemption request.',
  'redemption.reason.input-label': 'Redemption reason input',
  'redemption.reason.placeholder': 'Enter your answer here',
  'redemption.reason.validation.required': "Please provide why you're seeking to redeem your shares.",

  'redemption.approval.header': 'Please acknowledge and sign',
  'redemption.approval.agreement.text':
    'I have read, understand and approve the information laid out in the {offeringCircular} and any supplements thereto. Additionally, I understand that my Redemption Request is subject to review and may not be fulfilled in full or at all.',
  'redemption.approval.agreement.text.offering-circular': 'Offering Circular',
  'redemption.approval.agreement.checkbox-label': 'I have read and approve',
  'redemption.approval.initials.label': 'Your first and last name initials',
  'redemption.approval.initials.tooltip': 'For example, if your name is John Doe, then your initials would be JD.',
  'redemption.approval.initials.placeholder': 'Initials',
  'redemption.approval.disclaimer':
    'By clicking “Confirm Request” button: I adopt the above electronic initials as my signature, and hereby electronically sign the documents listed above. l acknowledge that I have accessed, have read and hereby agree to the Arrived Terms of Service and that I authorize the Arrived services, in the manner designated therein, to process the documents and signatures provided herewith and to create, store, and communicate electronic records of documents listed above.',
  'redemption.approval.complete-request-button': 'Complete request',
  'redemption.approval.initials.validation.required': 'Please provide your initials.',
  'redemption.approval.initials.validation.initials-match':
    'Please make sure the initials you provide match your first and last name listed in your Arrived account.',
  'redemption.approval.initials.missing-user-data':
    'Failed to create redemption request -- no first or last name available',
  'redemption.approval.disclaimer.validation.required': 'All fields are required to create your redemption request.',
  'redemption.approval.request-submission-failed.server':
    'Failed to submit your redemption request; status code {statusCode}. Please try again later or contact support ',
  'redemption.approval.request-submission-failed.other':
    'Failed to submit your redemption request. Please try again later or contact support ',

  'redemption.footer.summary.total-amount': 'Total Amount',
  'redemption.footer.summary.breakdown.title': 'Redemption Summary',
  'redemption.footer.summary.items': 'Items',
  'redemption.footer.summary.cost': 'Redemption cost',
  'redemption.footer.summary.cost.tooltip':
    'Arrived applies redemption fees individually to each trade. For redemptions involving multiple trades, each trade has its own age-based fee which is rounded to the nearest cent and summed to determine your total fee. This total may differ slightly from a fee calculated by applying a single percentage directly to the redemption amount.',
  'redemption.footer.summary.deposit-method': 'Deposit method',
  'redemption.footer.summary.deposit-location': 'Arrived Cash Balance',
  'redemption.footer.summary.offering-details':
    '{units} {isShare, select, true {{shareCount, plural, one {share} other {shares}}} other {{shareCount, plural, one {note} other {notes}}}} at {sharePrice}/{isShare, select, true {share} other {note}}',

  'redemption.success.label': 'Redemption request successful!',
  'redemption.success.description':
    'Your request to redeem {numShareCount} {numShareCount, plural, one {share} other {shares}} of {offeringName} is under review. You will be notified by {paymentWindowEnd} regarding the status of your request.',
};
