import { useMemo } from 'react';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';

export const useMaskedEmail = () => {
  const { user } = useArrivedAuth0();

  const maskedEmail = useMemo(
    () => (user?.email ? user.email.replace(/^(.{2})[^@]+/, '$1****') : undefined),
    [user?.email],
  );

  return maskedEmail;
};
