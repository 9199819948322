import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function DividendPaymentInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M3.81925 7.51616C2.44217 8.63695 1.5625 10.3455 1.5625 12.2594C1.5625 15.6352 4.29916 18.3719 7.675 18.3719C9.58019 18.3719 11.2818 17.5002 12.4029 16.1339"
        stroke={color}
        strokeWidth="1.25"
      />
      <Path
        d="M6.14688 7.675C6.14688 8.4777 6.30499 9.27255 6.61217 10.0142C6.91935 10.7558 7.36959 11.4296 7.93719 11.9972C8.50479 12.5648 9.17863 13.015 9.92023 13.3222C10.6618 13.6294 11.4567 13.7875 12.2594 13.7875C13.0621 13.7875 13.8569 13.6294 14.5985 13.3222C15.3401 13.015 16.014 12.5648 16.5816 11.9972C17.1492 11.4296 17.5994 10.7558 17.9066 10.0142C18.2138 9.27255 18.3719 8.4777 18.3719 7.675C18.3719 6.87229 18.2138 6.07745 17.9066 5.33585C17.5994 4.59425 17.1492 3.92041 16.5816 3.35281C16.014 2.78521 15.3401 2.33497 14.5985 2.02779C13.8569 1.7206 13.0621 1.5625 12.2594 1.5625C11.4567 1.5625 10.6618 1.7206 9.92023 2.02779C9.17863 2.33497 8.50479 2.78521 7.93719 3.35281C7.36959 3.92041 6.91935 4.59425 6.61217 5.33585C6.30499 6.07745 6.14688 6.87229 6.14688 7.675Z"
        stroke={color}
        strokeWidth="1.25"
      />
      <Path d="M12.2594 5.38282V3.85469" stroke={color} strokeWidth="1.25" />
      <Path d="M12.2594 11.4953V9.96719" stroke={color} strokeWidth="1.25" />
      <Path
        d="M13.9785 6.52891V6.14687C13.9785 5.7249 13.6364 5.38281 13.2145 5.38281H11.3043C10.8823 5.38281 10.5402 5.7249 10.5402 6.14687V6.48904C10.5402 6.84716 10.7889 7.15723 11.1386 7.23492L13.3802 7.73305C13.7298 7.81074 13.9785 8.12081 13.9785 8.47895V9.20313C13.9785 9.62512 13.6364 9.96719 13.2145 9.96719H11.3043C10.8823 9.96719 10.5402 9.62512 10.5402 9.20313V8.82109"
        stroke={color}
        strokeWidth="1.25"
      />
    </Svg>
  );
}

export const DividendPaymentIcon = memo<IconProps>(withIconTheme(DividendPaymentInternal));
