import { Fragment } from 'react';

import { Divider, GetProps, Stack, styled } from '@arrived/bricks';

import { PRODUCT_LIST_ITEM_DETAILS_NAME } from './constants';
import { ProductListItemDetail, ProductListItemDetailProps } from './ProductListItemDetail';

export interface ProductListItemDetailsProps {
  details: ProductListItemDetailProps[];
}

const ProductListItemDetailsFrame = styled(Stack, {
  name: PRODUCT_LIST_ITEM_DETAILS_NAME,

  gap: '$2',
  row: true,
  alignItems: 'center',
  flexShrink: 1,
});

export const ProductListItemDetails = ProductListItemDetailsFrame.styleable<
  GetProps<typeof ProductListItemDetailsFrame> & ProductListItemDetailsProps
>(({ details, ...rest }, ref) => {
  return (
    <ProductListItemDetailsFrame ref={ref} {...rest}>
      {details.map((detail, index) => (
        <Fragment key={index}>
          {index >= 1 && <Divider.Vertical solid alt h={20} />}
          <ProductListItemDetail {...detail} />
        </Fragment>
      ))}
    </ProductListItemDetailsFrame>
  );
});
