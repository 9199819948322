import { useEffect } from 'react';

import { Key } from 'ts-key-enum';

/* This will allow for detecting keyboard input without having to worry about
   element focus blocking the input
 */
export function useGlobalKeydownListener(keyCode: Key, callback: () => void) {
  const isKeyPressedFilter = (event: KeyboardEvent) => {
    if (event.code === keyCode) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', isKeyPressedFilter);
    return () => document.removeEventListener('keydown', isKeyPressedFilter);
  });
}
