import { PropsWithChildren, ReactNode } from 'react';

import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';

import { BodyText } from '@arrived/bricks';

import enUS from './l10n/en/en-US';

/**
 * https://formatjs.io/docs/react-intl/api/#createintlcache
 */
const cache = createIntlCache();

// We only support en-US right now, we don't need to support other locales just yet
// When we do, we can use `import { getLocales } from 'expo-localization';` to get the current locale
// const getCurrentLocale = () => {
//   try {
//     const locale = getLocales()?.[0]?.languageTag?.replace('_', '-');

//     if (locale) {
//       console.log(`Current locale: ${locale}`);
//     } else {
//       console.log('No locale found - using "en-US"');
//     }

//     return locale ?? 'en-US';
//   } catch (error) {
//     console.log('Error getting locale', error);
//     return 'en-US';
//   }
// };

/**
 * This is the main internationalization provider for the app.
 * https://formatjs.io/docs/react-intl/api/#createintl
 */
export const ArrivedIntl = createIntl(
  {
    locale: 'en-US', // getCurrentLocale(),
    messages: enUS,
    defaultLocale: 'en-US',
    onError: () => void 0, // Temporary to avoid console spam
    defaultRichTextElements: { strong: (parts: ReactNode[]) => <BodyText fontWeight="$2">{parts}</BodyText> },
  },
  cache,
);

export const ArrivedIntlProvider = ({ children }: PropsWithChildren) => (
  <RawIntlProvider
    value={ArrivedIntl}
    // @ts-expect-error -- This will not work type wise, but will make sure that strings are returned from messages
    textComponent={null}
    onError={() => void 0}
  >
    {children}
  </RawIntlProvider>
);
