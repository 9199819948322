import { memo } from 'react';

import { Defs, LinearGradient, Path, Stop, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function ArrivedIconInternal(props: IconProps) {
  return (
    <Svg width={21} height={20} fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.134 10.608a.456.456 0 00-.134.323v4.543c0 .406.491.61.779.322L12.65 3.924a.456.456 0 000-.645L10.38 1.008a.456.456 0 00-.646 0l-9.6 9.6zm11.553-3.105a.456.456 0 000 .645l2.271 2.271a.456.456 0 00.646 0l2.27-2.271a.456.456 0 000-.645l-2.27-2.272a.456.456 0 00-.646 0l-2.271 2.272zm-4.224 4.869a.456.456 0 010-.646l2.271-2.271a.456.456 0 01.646 0l2.27 2.271a.456.456 0 010 .646l-2.27 2.271a.456.456 0 01-.646 0l-2.271-2.271zm4.224 3.578a.456.456 0 000 .645l2.495 2.495a.456.456 0 00.322.133h4.543c.407 0 .61-.491.323-.779l-4.766-4.766a.456.456 0 00-.646 0l-2.271 2.272zm-10.62 3.274a.456.456 0 01-.323-.78l4.767-4.766a.456.456 0 01.645 0l4.766 4.767a.456.456 0 01-.323.779H1.067zm14.843-7.498a.456.456 0 000 .646l3.39 3.39a.456.456 0 00.779-.324v-4.542a.456.456 0 00-.134-.323l-1.118-1.118a.456.456 0 00-.646 0l-2.27 2.271z"
        fill="url(#prefix__paint0_linear_1954_21644)"
      />
      <Defs>
        <LinearGradient
          id="prefix__paint0_linear_1954_21644"
          x1={-9.167}
          y1={-8.356}
          x2={18.511}
          y2={19.437}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" stopOpacity={0} />
          <Stop offset={1} stopColor="#fff" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export const ArrivedIcon = memo<IconProps>(withIconTheme(ArrivedIconInternal));
