import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { IntercomProps, IntercomProvider, useIntercom } from 'react-use-intercom';

import { getUnixTime, parseISO } from 'date-fns';
import _merge from 'lodash/merge';

import { CONFIG } from '@arrived/config';
import { IntercomDisplayContextProvider } from '@arrived/contexts';
import { IntercomClientType } from '@arrived/models';
import {
  useGetAccountBalanceQuery,
  useGetCurrentUserQuery,
  useGetPrimaryAccountQuery,
  usePostIntercomIdentityToken,
} from '@arrived/queries';

export interface IntercomComponentProps {
  createPathForAdminPage: (id: string, hash?: string) => string;
  enabled?: boolean;
}

export const Intercom = ({ children, createPathForAdminPage, enabled }: PropsWithChildren<IntercomComponentProps>) => {
  const isEnabled = useMemo(() => enabled ?? true, [enabled]);
  const [showing, setShowing] = useState(false);
  const currentUserState = useGetCurrentUserQuery({ enabled: isEnabled && showing });
  const primaryAccountState = useGetPrimaryAccountQuery({ enabled: isEnabled && showing });
  const accountBalanceState = useGetAccountBalanceQuery(primaryAccountState.data?.id, {
    enabled: isEnabled && showing,
  });

  const { data: intercomIdentityTokenData } = usePostIntercomIdentityToken({
    clientType: IntercomClientType.WEB,
  });

  const setEnabled = useCallback(() => {
    setShowing(true);
  }, []);
  const setDisabled = useCallback(() => {
    setShowing(false);
  }, []);

  /**
   * Props provided to Intercom.
   *
   * Mixture of default and custom ones, all consumed/mapped via Google Tag Manager.
   */
  const bootProps = useMemo<Partial<IntercomProps>>(() => {
    const { createdAt, email, firstName, id, lastName, phoneNumber, profilePictureUrl } = currentUserState.data || {};
    const { initialInvestment } = accountBalanceState.data || {};
    const { active } = primaryAccountState.data || {};
    const userId = String(id);

    const currentUserConfig =
      currentUserState.data && intercomIdentityTokenData?.token
        ? {
            // NOTE: this item MUST be included in the `bootProps` object for authenticated conversations
            // https://www.intercom.com/help/en/articles/183-set-up-identity-verification-for-web-and-mobile
            userHash: intercomIdentityTokenData.token,
            email,
            avatar: {
              type: 'avatar',
              image_url: profilePictureUrl,
            },
            name: firstName || lastName ? `${firstName} ${lastName}` : '',
            phone: phoneNumber,
            createdAt: createdAt ? getUnixTime(parseISO(createdAt)).toString() : undefined,
            userId: id ? userId : undefined,
          }
        : {};
    const customAttributes = {
      ...(id
        ? {
            link_to_client_admin_page: createPathForAdminPage(userId, '#primary-account'),
            link_to_client_kyc_aml_admin_page: createPathForAdminPage(userId, '#kycaml'),
            link_to_client_transactions_admin_page: createPathForAdminPage(userId, '#transactions'),
          }
        : {}),
      ...(accountBalanceState.data
        ? {
            total_investment_amount: initialInvestment,
          }
        : {}),
      does_user_have_account: active,
    };

    return _merge({}, currentUserConfig, {
      customAttributes,
      customLauncherSelector: '.open-intercom',
    });
  }, [
    intercomIdentityTokenData?.token,
    currentUserState.data,
    accountBalanceState.data,
    primaryAccountState.data,
    createPathForAdminPage,
  ]);

  return (
    <IntercomProvider
      appId={CONFIG.intercomId}
      autoBootProps={bootProps}
      autoBoot
      onShow={setEnabled}
      onHide={setDisabled}
    >
      <IntercomProviderInnerWrapper bootProps={bootProps}>{children}</IntercomProviderInnerWrapper>
    </IntercomProvider>
  );
};

interface IntercomProviderInnerWrapperProps {
  bootProps: IntercomProps;
}

const IntercomProviderInnerWrapper = ({
  children,
  bootProps,
}: PropsWithChildren<IntercomProviderInnerWrapperProps>) => {
  const { update } = useIntercom();

  useEffect(() => {
    update(bootProps);
  }, [bootProps, update]);

  const setIsIntercomLauncherHidden = useCallback(
    (isHidden: boolean) => {
      update({ hideDefaultLauncher: isHidden });
    },
    [update],
  );

  return (
    <IntercomDisplayContextProvider setIsIntercomLauncherHidden={setIsIntercomLauncherHidden}>
      {children}
    </IntercomDisplayContextProvider>
  );
};
