import { InvestorResponse } from './questionnaire';

export const InvestorGoalsQuestion = 'af25b533-56bb-4ee3-b208-ea75e00220f9';
export const InvestorGoalsAnswers = {
  Income: '0381e9a1-8ce0-431f-82c6-cee1efd064ba',
  Growth: 'd3bbbb8b-2282-4480-9401-df152f916134',
  Balanced: 'b1b1665b-ef2f-4971-a85d-e35adf9c6ac6',
} as const;

export type InvestorGoalsAnswers = (typeof InvestorGoalsAnswers)[keyof typeof InvestorGoalsAnswers];

export const InvestorGoals = {
  GROWTH: 'Growth',
  INCOME: 'Income',
  BALANCED: 'Balanced',
} as const;

export type InvestorGoals = (typeof InvestorGoals)[keyof typeof InvestorGoals];

export const getInvestorGoalFromInvestorResponses = (personaQuestionnaireResponses?: InvestorResponse[]) => {
  if (!personaQuestionnaireResponses) {
    return undefined;
  }

  const investorGoals = personaQuestionnaireResponses.find(({ questionUUID }) => questionUUID === InvestorGoalsQuestion)
    ?.responses?.[0]?.questionResponseOptionUUID;

  if (Object.values(InvestorGoalsAnswers).includes(investorGoals as InvestorGoalsAnswers)) {
    if (investorGoals === InvestorGoalsAnswers.Income) {
      return InvestorGoals.INCOME;
    }

    if (investorGoals === InvestorGoalsAnswers.Growth) {
      return InvestorGoals.GROWTH;
    }
  }

  return InvestorGoals.BALANCED;
};
