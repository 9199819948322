import {
  WP_REST_API_Categories,
  WP_REST_API_Category,
  WP_REST_API_Page,
  WP_REST_API_Pages,
  WP_REST_API_Post,
  WP_REST_API_Posts,
} from 'wp-types';

import { BaseWordpressApiArguments, LearnBlogPost, WordpressPost, WordpressPostSearchOptions } from '@arrived/models';

import { wordpressApi } from './api';

interface GetLearnBlogPostsParams extends BaseWordpressApiArguments {
  categories?: WP_REST_API_Category['id'] | WP_REST_API_Category['id'][];
  orderBy?:
    | 'author'
    | 'date'
    | 'id'
    | 'include'
    | 'modified'
    | 'parent'
    | 'relevance'
    | 'slug'
    | 'include_slugs'
    | 'title';
  slug?: WP_REST_API_Post['slug'] | WP_REST_API_Post['slug'][];
  sticky?: boolean;
}

export const getLearnBlogPosts = async (params: GetLearnBlogPostsParams, returnError = false) => {
  try {
    const response = await wordpressApi.get<WP_REST_API_Posts>('wp-json/wp/v2/posts', {
      params,
    });

    return response.data;
  } catch (error) {
    if (returnError) throw error;
    console.error('Error fetching posts', error);
    return [];
  }
};

export const getWebinarPosts = async (params: GetLearnBlogPostsParams) => {
  try {
    const response = await wordpressApi.get<WP_REST_API_Posts>('wp-json/wp/v2/webinars', {
      params,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching posts', error);

    return [];
  }
};

export const getWebinarPost = async (webinarId: string, params: GetLearnBlogPostsParams) => {
  try {
    const response = await wordpressApi.get<WP_REST_API_Posts>(`wp-json/wp/v2/webinars/${webinarId}`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching posts', error);

    return [];
  }
};

interface GetLearnBlogPostParams extends BaseWordpressApiArguments {
  id: WP_REST_API_Post['id'];
}
export const getLearnBlogPost = async ({ id, ...params }: GetLearnBlogPostParams) => {
  try {
    const response = await wordpressApi.get<WP_REST_API_Post>(`wp-json/wp/v2/posts/${id}`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching post', error);

    return null;
  }
};

// https://developer.wordpress.org/rest-api/reference/categories/#arguments
export interface GetLearnBlogCategoriesParams extends BaseWordpressApiArguments {
  orderBy?: 'id' | 'include' | 'name' | 'slug' | 'include_slugs' | 'term_group' | 'description' | 'count';
  hide_empty?: boolean;
  post?: number; // Limit result set to terms assigned to a specific post.
  slug?: string | string[]; // Limit result set to terms with one or more specific slugs.
}

export const getLearnBlogCategories = async (params?: GetLearnBlogCategoriesParams) => {
  try {
    const response = await wordpressApi.get<WP_REST_API_Categories>(`wp-json/wp/v2/categories`, {
      params: {
        exclude: [1],
        per_page: 100,
        ...params,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching post', error);

    return null;
  }
};

export interface GetLearnBlogCategoryParams extends BaseWordpressApiArguments {
  categoryId: WP_REST_API_Category['id'];
}

export const getLearnBlogCategory = async (params: GetLearnBlogCategoryParams) => {
  try {
    const response = await wordpressApi.get<WP_REST_API_Categories>(`wp-json/wp/v2/categories/${params.categoryId}`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching post', error);

    return null;
  }
};

export const getLearnBlogPages = async (params: GetLearnBlogPageParams, returnError = false) => {
  try {
    const response = await wordpressApi.get<WP_REST_API_Pages>('wp-json/wp/v2/pages', {
      params,
    });

    return response.data;
  } catch (error) {
    if (returnError) throw error;
    console.error('Error fetching posts', error);
    return [];
  }
};

export interface GetLearnBlogPageParams {
  pageId: string;
  password?: string;
}
export const getLearnBlogPage = async ({ pageId }: GetLearnBlogPageParams) => {
  try {
    const response = await wordpressApi.get<WP_REST_API_Page>(`wp-json/wp/v2/pages/${pageId}`);

    return response.data;
  } catch (error) {
    console.error('Error fetching page', error);

    return null;
  }
};

const unwrapWordpressListResponse = (response: WordpressPost[]): LearnBlogPost[] =>
  response?.map((item) => ({
    ...item,
    id: item.id,
    date: item.date,
    title: item.title.rendered,
    slug: item.slug,
    excerpt: item.excerpt.rendered,
    content: item.content.rendered,
    link: item.link,
    readingTime: item.yoast_head_json?.twitter_misc?.['Est. reading time'] ?? '',
    featuredImage:
      item.yoast_head_json?.og_image?.[0]?.url ?? (item._embedded ?? item._links)['wp:featuredmedia']?.[0]?.source_url,
  }));

export interface GetLeanBlogPostsOldParams extends WordpressPostSearchOptions {
  pageParam: number;
  perPage?: number;
  [key: string]: unknown;
}
export interface GetLearnBlogPostsOldResponse {
  data: LearnBlogPost[];
  nextCursor: number;
  prevCursor: number;
}

/**
 * @deprecated - Use getLearnBlogPosts instead
 */
export const getLearnBlogPostsOld = async ({ pageParam, perPage = 10, ...rest }: GetLeanBlogPostsOldParams) => {
  try {
    const response = await wordpressApi.get<WordpressPost[]>('wp-json/wp/v2/posts', {
      params: {
        _embed: true,
        page: pageParam,
        per_page: perPage,
        ...rest,
      },
    });

    const totalPages = parseInt(response.headers['x-wp-totalpages'], 10);

    return {
      data: unwrapWordpressListResponse(response.data),
      nextCursor: totalPages > pageParam ? pageParam + 1 : undefined,
      prevCursor: pageParam > 1 ? pageParam - 1 : undefined,
    };
  } catch (error) {
    console.error('Error fetching posts', error);

    return {
      data: [],
      nextCursor: undefined,
      prevCursor: undefined,
    };
  }
};

export interface GetLearnBlogArticleParams {
  date?: string;
  guid?: string;
  id?: number;
  link?: string;
  uri?: string;
  slug?: string;
  status?: 'publish' | 'future' | 'draft' | 'pending' | 'private';
  type?: string;
  permalink_template?: string;
  generated_slug?: string;
  title?: Record<string, unknown>;
  content?: Record<string, unknown>;
  author?: number;
  excerpt?: Record<string, unknown>;
  featured_media?: number;
  comment_status?: 'open' | 'closed';
  ping_status?: 'open' | 'closed';
  format?: string;
  meta?: Record<string, unknown>;
  sticky?: boolean;
  template?: string;
  categories?: number[];
  tags?: string[];
}

/**
 * @deprecated - Use getLearnBlogPost instead
 */
export const getLearnBlogArticle = async ({ id, ...params }: GetLearnBlogArticleParams) => {
  try {
    const response = await wordpressApi.get<WordpressPost[]>(`wp-json/wp/v2/posts`, {
      params: {
        include: id,
        ...params,
      },
    });

    return unwrapWordpressListResponse(response.data)[0];
  } catch (error) {
    console.error('Error fetching post', error);

    return null;
  }
};
