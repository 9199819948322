import { useContext } from 'react';

import { createStyledContext } from '@tamagui/core';

export interface NavItemStyledContext {
  active?: boolean;
}

export const NavItemStyledContext = createStyledContext<NavItemStyledContext>({
  active: false,
});

export function useNavItemStyledContext() {
  const context = useContext(NavItemStyledContext);

  if (!context) {
    throw new Error('useNavItemStyledContext must be used within a NavItem');
  }

  return context;
}
