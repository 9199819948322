import { OfferingDividend, OfferingId } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getOfferingDividends = (offeringId: OfferingId) =>
  createQuery<OfferingDividend[]>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringId}/dividends`,
  });
