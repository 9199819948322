import { TitleText, styled } from '@arrived/bricks';

import { ProductHeaderTitleName } from './constants';
import { ProductHeaderStyledContext } from './ProductHeaderStyledContext';

export const Title = styled(TitleText, {
  name: ProductHeaderTitleName,
  context: ProductHeaderStyledContext,

  token: 'title.display.small',

  numberOfLines: 3,

  flex: 1,

  variants: {
    condensed: {
      true: {
        token: 'title.heading.xlarge',
      },
    },
  } as const,
});
