import { isAfter } from 'date-fns';

import { getTimezoneAgnosticDate } from '../getTimezoneAgnosticDate';

import { MergeOfferingDividendsAndSharePricesReturn } from './mergeDividendsAndSharePrices';
import { MonthlyFundPerformanceData } from './MonthlyFundPerformanceData';

/**
 * Given a list of dividends tied together with the share prices they're tied to (both the share
 * price at the start of the dividend period and the share price at the time of the dividend
 * postedAt date), this function produces a list of monthly performance data to show for the Fund.
 */
export const getSFRFundHistoryRowData = (
  dividendSharePrices: MergeOfferingDividendsAndSharePricesReturn[],
): MonthlyFundPerformanceData[] => {
  let prevSharePriceIndex = -1;

  return (
    dividendSharePrices
      ?.filter(({ postedAt }) => !isAfter(new Date(postedAt), new Date()))
      .map(
        (
          {
            annualizedDividendPercent,
            dividendPerShare,
            endDate,
            postedAt,
            sharePriceDuringPeriod,
            sharePriceEOM,
            startDate,
          },
          idx,
        ) => {
          let appreciationPercent;

          // First determine the appreciation percent by comparing the `sharePriceEOM` to the
          // most recently updated share price, if one does not exist then this first sharePriceEOM
          // value becomes our "most recently updated share price".
          if (prevSharePriceIndex < 0 && sharePriceEOM) {
            prevSharePriceIndex = idx;
          } else if (
            prevSharePriceIndex >= 0 &&
            sharePriceEOM &&
            idx !== prevSharePriceIndex &&
            sharePriceEOM.postedAt !== dividendSharePrices[prevSharePriceIndex].sharePriceEOM?.postedAt
          ) {
            const prevSharePrice = dividendSharePrices[prevSharePriceIndex].sharePriceEOM;
            if (prevSharePrice && prevSharePrice.sharePrice) {
              appreciationPercent = sharePriceEOM.sharePrice / prevSharePrice.sharePrice - 1;
            }
            prevSharePriceIndex = idx;
          }

          return {
            // Divide by 100 because the BE provides this % as a whole number value
            annualizedDividendPercent: annualizedDividendPercent / 100,
            appreciationPercent,
            // We want to remove the timezone offset from the cutoff value so we just get the date
            // since the cutoff date for dividends is timezone agnostic.
            cutoffDate: getTimezoneAgnosticDate(endDate),
            // To get the dividend percent we actually use the share price that the Offering had
            // at the beginning of the dividend period, this is not the share price that we use
            // to determine appreciation for this entry.
            // dividendPercent: sharePriceDuringPeriod?.sharePrice
            //   ? dividendPerShare / sharePriceDuringPeriod?.sharePrice
            //   : undefined,
            dividendPerShare,
            endDate,
            postedAtDate: new Date(postedAt),
            sharePriceDuringPeriod,
            sharePriceEOM,
            startDate,
          };
        },
      ) ?? ([] as MonthlyFundPerformanceData[])
  );
};
