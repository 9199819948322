import { useMemo } from 'react';

import { ArrivedIconWithText, Stack } from '@arrived/bricks';

import { Variation1 } from './Variation1';
import { Variation2 } from './Variation2';

export enum SignupContentVariation {
  VAR_1,
  VAR_2,
}

export interface SignupContentProps {
  variation: SignupContentVariation;
}

export const SignupContent = ({ variation }: SignupContentProps) => {
  const content = useMemo(() => {
    switch (variation) {
      case SignupContentVariation.VAR_1:
        return <Variation1 />;
      case SignupContentVariation.VAR_2:
        return <Variation2 />;
    }
  }, [variation]);

  return (
    <>
      <Stack position="relative" px="$12" pt="$6">
        <ArrivedIconWithText height={40} width={186} />
      </Stack>
      {content}
    </>
  );
};
