import { useMutation, useQueryClient } from '@tanstack/react-query';

import { markClosingAsSigned } from '@arrived/api_v2';

import { closingsQueryKey } from './closings.keys';

export function useMarkClosingSignedQuery() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markClosingAsSigned,
    onSuccess: (data) => {
      queryClient.setQueryData(closingsQueryKey(data?.cid), data);
    },
  });
}
