import { QueryKey } from '@tanstack/react-query';

import { AccountId } from '@arrived/models';

import { accountsKeyFn, accountsKeyRoot } from '../accounts.keys';

export const kycAmlKey = 'kycAml';

export const accountKycAmlKeyFn = (accountId: AccountId, hasLinkedBankAccount?: boolean): QueryKey =>
  (accountsKeyFn(accountId) as readonly unknown[]).concat([kycAmlKey, hasLinkedBankAccount]);

/**
 * Indicates if a given QueryKey is one that is keying an AccountKycAml.
 *
 * If the shape of accountKycAmlKeyFn above changes, we need to make sure that this stays in sync.
 */
export const isAccountKycAmlKey = (queryKey: QueryKey) => queryKey[0] === accountsKeyRoot && queryKey[2] === kycAmlKey;
