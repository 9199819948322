import { useCallback } from 'react';

import { FormattedMessage } from 'react-intl';

import { EVENTS, useTrack } from '@arrived/analytics';
import { Button, LinearGradient, Stack, useDisclosure } from '@arrived/bricks';
import {
  OfferingHistoryEvent,
  OfferingHistorySheet,
  useGetSortedOfferingHistoryEvents,
} from '@arrived/features-shared';
import { Offering } from '@arrived/models';

interface OfferingHistoryButtonProps {
  offering: Offering;
  defaultIsOpen?: boolean;
}
export const OfferingHistoryButton = ({ offering, defaultIsOpen }: OfferingHistoryButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: defaultIsOpen });
  const track = useTrack();
  const { sortedEvents } = useGetSortedOfferingHistoryEvents(offering);

  const handleOpen = useCallback(() => {
    onOpen();
    track(EVENTS.OFFERING_HISTORY_CLICKED, {
      offering_cid: offering.cid,
      offering_id: offering.id,
      offering_name: offering.name,
      location: 'details',
    });
  }, [onOpen, track]);

  if (!sortedEvents) return null;

  return (
    <Stack gap="$4" pb={0} w="100%" m="auto">
      <Stack
        p="$8"
        pb="$1"
        borderWidth={1}
        borderColor="$onSurface.neutral.outlineAlt"
        borderRadius="$1"
        backgroundColor="#f8f8f8"
      >
        <LinearGradient
          locations={[0, 0.6]}
          colors={['$surface.neutral.default', '$onSurface.neutral.defaultInverted']}
          fullscreen
        />
        <Stack>{sortedEvents[0] ? <OfferingHistoryEvent event={sortedEvents[0][0]} emphasized={false} /> : null}</Stack>
        <Stack y={-44} pt="$10" flexGrow={0} alignItems="center" w="100%">
          <Button variant="outlined" onPress={handleOpen}>
            <FormattedMessage id="property-history-button" />
          </Button>
        </Stack>
      </Stack>
      <OfferingHistorySheet offeringIdOrShortname={offering.id} open={isOpen} onClose={onClose} />
    </Stack>
  );
};
