import { useQuery } from '@tanstack/react-query';

import { getPromotion } from '@arrived/api_v2';

import { UseAwaitedQueryOptions } from '../utils';

import { promotionKeyFn } from './rewards.keys';

export function useGetPromotionQuery(promotionUuid?: string, options?: UseAwaitedQueryOptions<typeof getPromotion>) {
  return useQuery({
    queryKey: promotionKeyFn(promotionUuid!),
    queryFn: () => getPromotion(promotionUuid!),
    ...options,
    enabled: (options?.enabled ?? true) && promotionUuid != null,
  });
}
