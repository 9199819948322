import { AccountBalanceV2, AccountId, AccountOfferingBalances, AccountPropertyOccupancy } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getAccountBalance = (accountId: AccountId) =>
  createQuery<AccountBalanceV2>({
    apiInstance: api,
    method: 'get',
    url: `/accounts/${accountId}/balance`,
  });

export const getAccountOfferingBalances = (accountId: AccountId) =>
  createQuery<AccountOfferingBalances>({
    apiInstance: api,
    method: 'get',
    url: `/accounts/${accountId}/balance/offerings`,
  });

export const getAccountPropertyOccupancy = (accountId: AccountId) =>
  createQuery<AccountPropertyOccupancy>({
    apiInstance: api,
    method: 'get',
    url: `/accounts/${accountId}/balance/property-occupancy`,
  });
