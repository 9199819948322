import { Property } from '@arrived/models';

import { PropertyLocation, PropertyLocationProps } from '../../../location';

export type LocationSectionProps = Omit<PropertyLocationProps, 'icon'> & {
  property: Property;
};

export const LocationSection = ({ property, ...rest }: LocationSectionProps) => (
  <PropertyLocation minHeight={84} property={property} icon="home" {...rest} />
);
