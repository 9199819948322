import { AirDnaApiResponse, OfferingId } from '@arrived/models';

import { airDnaApi } from './api';
import { createQuery } from './create-query';

export const getAirDnaAverageDailyRateByOffering = ({ offeringId }: { offeringId: OfferingId }) =>
  createQuery<AirDnaApiResponse>({
    apiInstance: airDnaApi,
    method: 'get',
    url: `/offerings/${offeringId}/daily-rate`,
  });

export const getAirDnaMonthlyRevenueByOffering = ({ offeringId }: { offeringId: OfferingId }) =>
  createQuery<AirDnaApiResponse>({
    apiInstance: airDnaApi,
    method: 'get',
    url: `/offerings/${offeringId}/monthly-revenue`,
  });

export const getAirDnaMonthlyOccupancyByOffering = ({ offeringId }: { offeringId: OfferingId }) =>
  createQuery<AirDnaApiResponse>({
    apiInstance: airDnaApi,
    method: 'get',
    url: `/offerings/${offeringId}/occupancy`,
  });
