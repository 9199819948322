import { MARKETING_CONVERSION_EVENTS } from '@arrived/models';

import { trackAffiliateConversion, trackQuoraPixelEvent } from './';

interface TrackMarketingConversionEventArgs {
  type: MARKETING_CONVERSION_EVENTS;
  email?: string;
  value?: number | string;
}

export const trackMarketingConversionEvent = (args: TrackMarketingConversionEventArgs) => {
  const { email, type } = args;

  /*
    Funnel goals that are used for marketing conversion tracking.
    Use the Switch statement to send conversion goals to the various marketing applications.
    We use Google Tag Manager to load the Tune Tracking tag and run the Tune `identify` call.

    The `window.tdl.convert() method tracks
    affiliate conversions based on their Tune `transaction_id` that comes in the query string after clicking an affiliate link.
    The `identify()` call that we fire in Google Tag Manager stores this in local storage, and it gets automatically appended when we call the `convert()`
    function.

    The other trackAffiliateConversion() functions are tied to a specific campaign goal in Tune.

    Learn more about Tune affiliate tracking at https://developers.tune.com/javascript-sdk/getting-started-with-the-javascript-sdk/
   */

  trackQuoraPixelEvent({ type, email });

  switch (type) {
    case MARKETING_CONVERSION_EVENTS.TRADE_DOCS_SIGNED:
      break;
    case MARKETING_CONVERSION_EVENTS.LINKED_BANK:
      // Youtube campaign conversion
      trackAffiliateConversion('/aff_lsr?offer_id=3');
      // Benzinga campaign conversion
      trackAffiliateConversion('/aff_goal?a=lsr&offer_id=4&goal_name=bank_link&adv_id=0');

      // Investopedia Bank link conversion
      trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=6&goal_name=bank-link&adv_id=0`);

      // Crowdstreet Bank link conversion
      trackAffiliateConversion(`/aff_lsr?offer_id=9`);

      // Moneywise Bank Link conversion
      trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=13&goal_name=bank-link&adv_id=0`);

      // Brand Partner Bank Link conversion
      trackAffiliateConversion('/aff_lsr?offer_id=17');

      // Bank Link conversion
      trackAffiliateConversion('/aff_goal?a=lsr&offer_id=5&goal_name=bank_link');
      break;
    case MARKETING_CONVERSION_EVENTS.EMAIL_LEAD:
      // Fire generic tracking
      if (window && window.tdl) {
        window.tdl.convert();
      }

      // Default Arrived affiliate lead conversion
      trackAffiliateConversion(`/aff_lsr?offer_id=1&adv_sub=${email}`);

      // Benzinga campaign lead conversion
      trackAffiliateConversion(`/aff_lsr?offer_id=4&adv_sub=${email}`);

      // Investopedia campaign lead conversion
      trackAffiliateConversion(`/aff_lsr?offer_id=6&adv_sub=${email}`);

      // Youtube lead tracking
      trackAffiliateConversion(`/aff_lsr?offer_id=18&adv_sub=${email}`);
      break;
    case MARKETING_CONVERSION_EVENTS.USER_CREATED:
      if (window && window.tdl) {
        window.tdl.convert();
      }

      // 07/19/24 - disabling because we aren't paying out on user created and are going to track leads here as well.
      // Investopedia Create Account conversion.
      //trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=6&goal_name=create-account&adv_id=0`);

      // Moneywise Create Account conversion
      //trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=13&goal_name=create-account&adv_id=0`);

      break;
    case MARKETING_CONVERSION_EVENTS.BROKERAGE_ACCOUNT_CREATED:
      // Investopedia Brokerage Account conversion
      trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=6&goal_name=brokerage-account-created&adv_id=0`);

      // Moneywise Brokerage Account conversion
      trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=13&goal_name=brokerage-account-created&adv_id=0`);

      break;
    case MARKETING_CONVERSION_EVENTS.INTENDED_INVESTMENT_AMOUNT_PROVIDED:
      switch (args.value) {
        case '100_2000':
          // Moneywise IAUM $100 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=13&goal_name=iaum_1_100-2000&adv_id=0`);
          // Moneywise HNW IAUM $100 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=14&goal_name=iaum_1_100-2000&adv_id=0`);
          // Benzinga - April 2023 IAUM $100 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=4&goal_name=iaum_1_100-2000&adv_id=0`);
          // Benzinga - Baseline IAUM $100 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=15&goal_name=iaum_1_100-2000&adv_id=0`);
          // Arrived - $40 Qualified Lead $100 conversion
          trackAffiliateConversion(`/aff_lsr?offer_id=5&goal_name=iaum_1_100-2000`);
          break;
        case '2000_5000':
          // Moneywise IAUM $2000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=13&goal_name=iaum_2_2000-5000&adv_id=0`);
          // Moneywise HNW IAUM $2000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=14&goal_name=iaum_2_2000-5000&adv_id=0`);
          // Benzinga - April 2023 IAUM $2000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=4&goal_name=iaum_2_2000-5000&adv_id=0`);
          // Benzinga - Baseline IAUM $2000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=15&goal_name=iaum_2_2000-5000&adv_id=0`);
          // Arrived - $40 Qualified Lead $2000 conversion
          trackAffiliateConversion(`/aff_lsr?offer_id=5&goal_name=iaum_2_2000-5000`);
          break;
        case '5000_25000':
          // Moneywise IAUM $5000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=13&goal_name=iaum_3_5000-25000&adv_id=0`);
          // Moneywise HNW IAUM $5000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=14&goal_name=iaum_3_5000-25000&adv_id=0`);
          // Benzinga - April 2023 IAUM $5000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=4&goal_name=iaum_3_5000-25000&adv_id=0`);
          // Benzinga - Baseline IAUM $5000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=15&goal_name=iaum_3_5000-25000&adv_id=0`);
          // Arrived - $40 Qualified Lead $5000 conversion
          trackAffiliateConversion(`/aff_lsr?offer_id=5&goal_name=iaum_3_5000-25000`);
          break;
        case '25000_100000':
          // Moneywise IAUM $25000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=13&goal_name=iaum_4_25000-10000&adv_id=0`);
          // Moneywise HNW IAUM $25000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=14&goal_name=iaum_4_25000-10000&adv_id=0`);
          // Benzinga - April 2023 IAUM $25000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=4&goal_name=iaum_4_25000-10000&adv_id=0`);
          // Benzinga - Baseline IAUM $25000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=15&goal_name=iaum_4_25000-10000&adv_id=0`);
          break;
        case '100000_OR_MORE':
          // Moneywise IAUM $100000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=13&goal_name=iaum_5_100000-or-more&adv_id=0`);
          // Moneywise HNW IAUM $100000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=14&goal_name=iaum_5_100000-or-more&adv_id=0`);
          // Benzinga - April 2023 IAUM $100000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=4&goal_name=iaum_5_100000-or-more&adv_id=0`);
          // Benzinga - Baseline IAUM $100000 conversion
          trackAffiliateConversion(`/aff_goal?a=lsr&offer_id=15&goal_name=iaum_5_100000-or-more&adv_id=0`);
          // Arrived - $40 Qualified Lead $100000 conversion
          trackAffiliateConversion(`/aff_lsr?offer_id=5&goal_name=iaum_5_100000-or-more`);
          break;
      }

    case MARKETING_CONVERSION_EVENTS.HIGH_VALUE_USER:
      break;
  }
};
