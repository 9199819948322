import { useMemo } from 'react';

export const useTransactionType = (cid?: string) => {
  return useMemo(() => {
    if (cid?.startsWith('trad_')) {
      return 'TRADE';
    }

    if (cid?.startsWith('ctxx_')) {
      return 'CASH';
    }

    if (cid?.startsWith('sale_')) {
      return 'SALE';
    }
  }, [cid]);
};
