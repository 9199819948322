import { createContext, useContext } from 'react';

interface BannerContextValues {
  isLoading?: boolean;
  isVisible: boolean;
}

export const BannerContext = createContext({} as BannerContextValues);

export const useBannerContext = () => {
  const context = useContext(BannerContext);

  if (!context) {
    throw new Error('useBannerContext must be used within a BannerContext.Provider');
  }

  return context;
};
