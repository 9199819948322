import { ComponentType, FC, ReactNode } from 'react';

import { RootTextProps } from '../../../atoms';
import { Resource } from './Resource';

export const NavItemType = {
  DROPDOWN: 'DROPDOWN',
  LINK: 'LINK',
  SECTION: 'SECTION',
} as const;

export type NavItemType = (typeof NavItemType)[keyof typeof NavItemType];

/**
 * ResourceList is a list of resources that is presented to the user with a label providing some
 * context about the resources.
 */
export interface ResourceList {
  /**
   * The header label of the group of resources that'll be shown to the user.
   */
  header: string;
  /**
   * The actual resources to present in the ResourceList underneath the label.
   */
  resources: Resource[];
}

/**
 * Groups are children of Sections. They are a collection of resources that can be customized with
 * information and a few interactions.
 */
export interface Group {
  /**
   * Adornment that'll be rendered next to the label of the group.
   */
  adornment?: ReactNode;
  /**
   * Content that is displayed wheen this group is selected.
   */
  Content: ComponentType<{ Text: FC<RootTextProps> }>;
  /**
   * The label of the group. This will represent the
   */
  resource: Resource & { linkText?: string };
  /**
   * The ResourceLists that'll be displayed as a part of this Group.
   */
  resourceLists: ResourceList[];
}

/**
 * A navigation NavDropdown is the second simplest type of navigation element, it is just a label
 * with a list of resources that are shown to the user when the label is clicked.
 */
export interface NavDropdown {
  type: 'DROPDOWN';
  key: string;
  /**
   * The label presented to the user for the Dropdown.
   */
  label: string;
  /**
   * The Resources that appear in the Dropdown when it is selected.
   */
  resources: Resource[];
}

/**
 * A NavLink is the simplest type of navigation element as it is simply a label and a link.
 */
export interface NavLink extends Resource {
  active?: boolean;
  type: 'LINK';
  key: string;
}

/**
 * A navigation Section is one that will receive a hover interaction on desktop in which a pop-up
 * will appear listing all of the Groups defined to show as a part of that Section. On mobile these
 * end up being buttons that will show the Groups as dropdowns on a separate window that slides
 * into view when that Section is selected.
 */
export interface NavSection extends Resource {
  type: 'SECTION';
  key: string;
  /**
   * This component will be displayed under all of the groups in the NavSection.
   */
  Highlight?: ComponentType;
  /**
   * The groups to render in this section.
   */
  groups: Group[];
}

/**
 * A NavItem can either be a Dropdown, Link or a Section. Currently we're only specifying Dropdown or
 * Link to allow for an incremental transition over to the new nav.
 */
export type NavItem = NavDropdown | NavLink | NavSection;
