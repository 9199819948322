import { OfferingDocument, OfferingDocumentId, OfferingDocumentUploadRequest } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';
import { buildMultipartFormData, getBlob } from './utils';

export const getOfferingDocuments = (offeringId: number) =>
  createQuery<OfferingDocument[]>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringId}/documents`,
  });

export const uploadOfferingDocument = (request: OfferingDocumentUploadRequest) =>
  createQuery<OfferingDocument>({
    apiInstance: api,
    method: 'post',
    url: `/offerings/${request.offeringId}/documents`,
    requestData: buildMultipartFormData(request).body,
    config: {
      headers: buildMultipartFormData(request).headers,
    },
  });

export const getOfferingDocument = (offeringDocumentId: OfferingDocumentId) =>
  createQuery<OfferingDocument>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/documents/${offeringDocumentId}`,
  });

export const getOfferingDocumentRaw = (offeringDocumentId: OfferingDocumentId) =>
  getBlob(`/offerings/documents/${offeringDocumentId}/raw`);

export const deleteOfferingDocument = (offeringDocumentId: OfferingDocumentId) =>
  createQuery<OfferingDocument>({
    apiInstance: api,
    method: 'delete',
    url: `/offerings/documents/${offeringDocumentId}`,
  });
