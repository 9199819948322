import { useMemo } from 'react';

import { useIntl } from 'react-intl';

export const useFormatCurrency = (num?: number | string | null) => {
  const intl = useIntl();

  return useMemo(() => {
    if (!num && num !== 0) return '';
    return intl.formatNumber(typeof num === 'number' ? num : parseFloat(num), {
      currency: 'USD',
      style: 'currency',
      maximumFractionDigits: 2,
    });
  }, [intl, num]);
};
