import { MutableRefObject, useMemo } from 'react';

import { useDrag, useDrop } from 'react-dnd';

import { Identifier, XYCoord } from 'dnd-core';

/**
 * Sets up a component to be part of a drag and drop vertical sorted list. Components that are part of a vertical
 * sort must all be children of the same DndProvider.
 *
 * @param ref the ref of the component that is draggable/droppable in the vertical sorted list.
 * @param index the position of the component within the list.
 * @param moveItem function that will move the item within the list, this is called when the item is hovered over to
 *  make it so that the component using this hook can reposition itself to reflect the dragging action.
 * @param itemType a string that distinguishes this set of components as grouped in the list. If there are multiple
 *  sortable lists with different itemTypes they will not be interactable.
 */
export function useVerticalSortComponent<T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  index: number,
  moveItem: (dragIndex: number, dropIndex: number) => void,
  itemType: string,
) {
  const [{ handlerId, isHovered }, drop] = useDrop<
    { index: number },
    void,
    { handlerId: Identifier | null; isHovered: boolean }
  >({
    accept: itemType,
    collect: (monitor) => ({ handlerId: monitor.getHandlerId(), isHovered: monitor.isOver() }),
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      if (item.index === index) {
        return;
      }

      // This logic is adopted from a react-dnd example https://react-dnd.github.io/react-dnd/examples/sortable/simple
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (item.index < index && hoverClientY < hoverMiddleY) {
        return;
      } else if (item.index > index && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(item.index, index);

      item.index = index;
    },
  });

  const [{ isDragging }, drag] = useDrag<{ index: number }, void, { isDragging: boolean }>({
    type: itemType,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return useMemo(
    () => ({
      handlerId,
      isHovered,
      isDragging,
    }),
    [handlerId, isHovered, isDragging],
  );
}
