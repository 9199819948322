import { IntlShape } from 'react-intl';

import { Address } from './addresses';
import { MarketId } from './markets';
import { OfferingId } from './offerings';

export type PropertyId = number;
export type PropertySlug = string;
export type PhotoId = number;

export interface Photo {
  id: PhotoId;
  url: string;
  position: number;
}

export interface PhotoPost {
  file: unknown;
}

export interface PhotoPatch {
  position?: number;
}

export enum PropertyType {
  NONE = 'NONE',
  SINGLE_FAMILY_HOME = 'SINGLE_FAMILY_HOME',
  MULTI_FAMILY_HOME = 'MULTI_FAMILY_HOME',
  APARTMENT = 'APARTMENT',
}

export enum UsageType {
  LONG_TERM_RENTAL = 'LONG_TERM_RENTAL',
  SHORT_TERM_RENTAL = 'SHORT_TERM_RENTAL',
}

export enum LeaseStatus {
  /**
   * Properties that haven’t closed yet
   */
  UNDER_CONTRACT = 'UNDER_CONTRACT',

  /**
   * Seller post close occupancy
   */
  OCCUPIED_PCO = 'OCCUPIED_PCO',

  /**
   * Vacant property not being marketed for rent for reasons other than initial rehab or turn.
   */
  VACANT_OFFLINE = 'VACANT_OFFLINE',

  /**
   * Initial rehab/turn
   *
   * Prop is vacant, not rented (ie no lease signed), and undergoing rehab, turn, or maintenance work.
   *
   * Repairs etc prior to home being listed for rent.
   */
  VACANT_UNRENTED_NOT_READY = 'VACANT_UNRENTED_NOT_READY',

  /**
   * Approved application
   *
   * Prop is vacant, rented (ie lease signed, hold fee paid, general good chance of successful occupancy but not
   * yet guaranteed), and QA (quality assurance) complete (ie rent ready or ready for tenants to move in)
   *
   * Majority of the time this changes to Occupied status upon tenant move-in, but NOT ALWAYS so we must be
   * careful with messaging as we want to avoid falsely communicating with investors of a leased property
   * that still may fall through.
   */
  VACANT_RENTED_READY = 'VACANT_RENTED_READY',

  /**
   * Approved application
   *
   * Prop is vacant, rented (ie lease signed, hold fee paid), but still undergoing rehab, turn, or maintenance
   * work preventing tenants from moving in.
   *
   * NOTE: this is the least common for Arrived today as we are not actively pre-leasing many homes.
   */
  VACANT_RENTED_NOT_READY = 'VACANT_RENTED_NOT_READY',

  /**
   * Property is listed for rent
   *
   * Prop is vacant, not rented (ie no lease signed), and open for leasing (ie prospective tenants can book tours of
   * home and all rehab, turn, and maintenance work is complete)
   *
   * Exceptions could be the rehab, turn, or maintenance work is mostly complete and the PM is still working on
   * non-critical items (ex: blinds install, waiting for fridge delivery, etc) but home is clean, safe,
   * and functional for lease up
   */
  VACANT_UNRENTED_READY = 'VACANT_UNRENTED_READY',

  /**
   * Property is rented by a rent-paying tenant
   */
  OCCUPIED = 'OCCUPIED',

  /**
   * This property has a signed lease and is occupied by a tenant.
   */
  NOTICE = 'NOTICE',

  /**
   * A non-approved resident has gained access to this property.
   * We are working with our property manager to resolve this issue in a timely manner.
   */
  SQUATTER = 'SQUATTER',

  /**
   * This property is undergoing an eviction. We are working with our property manager to resolve this issue in a timely manner.
   */
  EVICTION = 'EVICTION',

  FOR_SALE = 'FOR_SALE',

  CANCELLED_TRANSACTION = 'CANCELLED_TRANSACTION',

  /**
   * @deprecated
   */
  RENTED = 'RENTED',

  /**
   * @deprecated
   */
  LISTED = 'LISTED',

  /**
   * @deprecated
   */
  UNLISTED = 'UNLISTED',

  DISPOSITION_VACANT_NOT_READY = 'DISPOSITION_VACANT_NOT_READY',
  DISPOSITION_VACANT_LISTED = 'DISPOSITION_VACANT_LISTED',
  DISPOSITION_UNDER_CONTRACT = 'DISPOSITION_UNDER_CONTRACT',
  DISPOSITION_OCCUPIED_NOT_READY = 'DISPOSITION_OCCUPIED_NOT_READY',
  DISPOSITION_OCCUPIED_LISTED = 'DISPOSITION_OCCUPIED_LISTED',
  DISPOSED = 'DISPOSED',
}

export interface Property {
  id: PropertyId;
  slug?: PropertySlug;
  propertyType?: PropertyType;
  description?: string;
  youtubeWalkthroughVideoId?: string;
  matterportTourId?: string;
  address?: Address;
  photos: Photo[];
  squareFootage?: number;
  bedrooms?: number;
  fullBathrooms?: number;
  halfBathrooms?: number;
  yearBuilt?: number;
  purchasePrice?: number;
  currentValue?: number;
  rent?: number;
  rentbackPercent?: number;
  securityDeposit?: number;
  desiredLeaseLengthMonths?: number;
  availabilityDate?: Date;
  petsAllowed?: boolean;
  leaseStatus: LeaseStatus;
  leaseStartAt?: Date;
  leaseEndAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  listed?: boolean;
  arrivedOwned?: boolean;
  active?: boolean;
  isStabilized?: boolean;
  isStabilizedOccupancy?: boolean;
  goLiveDate?: string | null;
  projections: {
    offeringIds?: OfferingId[] | null;
  };
  marketId?: MarketId | null;
  proformaRent?: number | null;
}

export const getLeaseStatusMessageDisplayText = (leaseStatus: LeaseStatus | undefined, intl: IntlShape): string => {
  return leaseStatus
    ? intl.formatMessage({ id: LEASE_STATUS_MESSAGES_STRINGS[leaseStatus].display.id })
    : intl.formatMessage({ id: 'common.not-applicable' });
};

/***
 * Not all Lease Statuses have accompanying tooltips.
 */
interface LeaseStatusMessageStrings {
  display: { id: string; text?: string };
  tooltip?: { id: string; text?: string };
}

export const isLeaseStatusRented = (leaseStatus?: LeaseStatus | null): boolean => {
  switch (leaseStatus) {
    case LeaseStatus.NOTICE:
    case LeaseStatus.OCCUPIED:
      return true;
    default:
      return false;
  }
};

export const LEASE_STATUS_MESSAGES_STRINGS: Record<LeaseStatus, LeaseStatusMessageStrings> = {
  CANCELLED_TRANSACTION: {
    display: { id: 'lease-status.cancelled-transaction.text' },
    tooltip: { id: 'lease-status.cancelled-transaction.tooltip' },
  },
  DISPOSED: {
    display: { id: 'lease-status.disposed.text' },
    tooltip: { id: 'lease-status.disposed.tooltip' },
  },
  DISPOSITION_OCCUPIED_LISTED: {
    display: { id: 'lease-status.disposition-occupied-listed.text' },
    tooltip: { id: 'lease-status.disposition-occupied-listed.tooltip' },
  },
  DISPOSITION_OCCUPIED_NOT_READY: {
    display: { id: 'lease-status.disposition-occupied-not-ready.text' },
    tooltip: { id: 'lease-status.disposition-occupied-not-ready.tooltip' },
  },
  DISPOSITION_UNDER_CONTRACT: {
    display: { id: 'lease-status.disposition-under-contract.text' },
    tooltip: { id: 'lease-status.disposition-under-contract.tooltip' },
  },
  DISPOSITION_VACANT_LISTED: {
    display: { id: 'lease-status.disposition-vacant-listed.text' },
    tooltip: { id: 'lease-status.disposition-vacant-listed.tooltip' },
  },
  DISPOSITION_VACANT_NOT_READY: {
    display: { id: 'lease-status.disposition-vacant-not-ready.text' },
    tooltip: { id: 'lease-status.disposition-vacant-not-ready.tooltip' },
  },
  EVICTION: {
    display: { id: 'lease-status.eviction.text' },
    tooltip: { id: 'lease-status.eviction.tooltip.text' },
  },
  FOR_SALE: {
    display: { id: 'lease-status.for-sale.text' },
  },
  NOTICE: {
    display: { id: 'lease-status.notice.text' },
    tooltip: { id: 'lease-status.notice.tooltip.text' },
  },
  SQUATTER: {
    display: { id: 'lease-status.squatter.text' },
    tooltip: { id: 'lease-status.squatter.tooltip.text' },
  },
  OCCUPIED: {
    display: { id: 'lease-status.occupied.text' },
    tooltip: { id: 'lease-status.occupied.tooltip.text' },
  },
  VACANT_OFFLINE: {
    display: { id: 'lease-status.vacant-offline.text' },
    tooltip: { id: 'lease-status.vacant-offline.tooltip.text' },
  },
  OCCUPIED_PCO: {
    display: { id: 'lease-status.occupied-pco.text' },
    tooltip: { id: 'lease-status.occupied-pco.tooltip.text' },
  },
  UNDER_CONTRACT: {
    display: { id: 'lease-status.under-contract.text' },
    tooltip: { id: 'lease-status.under-contract.tooltip.text' },
  },
  VACANT_UNRENTED_NOT_READY: {
    display: { id: 'lease-status.vacant-unrented-not-ready.text' },
    tooltip: { id: 'lease-status.vacant-unrented-not-ready.tooltip.text' },
  },
  VACANT_UNRENTED_READY: {
    display: { id: 'lease-status.vacant-unrented-ready.text' },
    tooltip: { id: 'lease-status.vacant-unrented-ready.tooltip.text' },
  },
  VACANT_RENTED_NOT_READY: {
    display: { id: 'lease-status.vacant-rented-not-ready.text' },
    tooltip: { id: 'lease-status.vacant-rented-not-ready.tooltip.text' },
  },
  VACANT_RENTED_READY: {
    display: { id: 'lease-status.vacant-rented-ready.text' },
    tooltip: { id: 'lease-status.vacant-rented-ready.tooltip.text' },
  },
  // legacy
  RENTED: {
    display: { id: 'lease-status.rented.text' },
  },
  LISTED: {
    display: { id: 'lease-status.listed.text' },
  },
  UNLISTED: {
    display: { id: 'lease-status.unlisted.text' },
  },
};
