import { useQuery } from '@tanstack/react-query';

import { getZillowPriceByZipCode } from '@arrived/api_v2';
import { ZipCode } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { zillowPriceByZipCodeKeyFn } from './misc.keys';

export function useZillowPriceByZipCodeQuery(
  zipCode: ZipCode,
  from: Date,
  options?: UseAwaitedQueryOptions<typeof getZillowPriceByZipCode>,
) {
  return useQuery({
    queryKey: zillowPriceByZipCodeKeyFn(zipCode, from),
    queryFn: () => getZillowPriceByZipCode(zipCode, from),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}
