import { useQuery } from '@tanstack/react-query';

import { getQuestionResponseOptions } from '@arrived/api_v2';
import { QuestionId } from '@arrived/models';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { questionResponseOptionsKeyFn } from './questionnaires.keys';

export function useGetQuestionResponseOptionsQuery(
  questionId: QuestionId,
  options?: UseAwaitedQueryOptions<typeof getQuestionResponseOptions>,
) {
  return useQuery({
    queryKey: questionResponseOptionsKeyFn(questionId),
    queryFn: () => getQuestionResponseOptions(questionId),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
