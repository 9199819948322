import { useMutation } from '@tanstack/react-query';

import { postChangePassword } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';

export function usePostChangePasswordMutation(options?: UseAwaitedMutationOptions<typeof postChangePassword>) {
  return useMutation({
    mutationFn: postChangePassword,
    ...options,
  });
}
