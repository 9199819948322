import { ColorTokens } from '@tamagui/core';

import { TagVariant } from '../Tag.constants';

export const getTagVariantFontColor = ({
  disabled,
  pressable,
  variant,
}: {
  disabled?: boolean;
  pressable?: boolean;
  variant?: TagVariant;
}): ColorTokens => {
  if (disabled && pressable) {
    return '$onSurface.neutral.defaultInverted';
  }

  switch (variant) {
    case 'inverted':
      return pressable ? '$interactive.primary.rested' : '$onSurface.neutral.default';
    case 'negative':
      return pressable ? '$interactive.negative.rested' : '$onSurface.negative.defaultAlt';
    case 'positive':
      return pressable ? '$interactive.positive.rested' : '$onSurface.positive.defaultAlt';
    case 'category':
    case 'primary':
      return '$onSurface.neutral.default';
    case 'black':
      return '$onSurface.neutral.defaultInverted';
    case 'white':
      return '$interactive.neutral.rested';
    default:
      return '$onSurface.neutral.defaultInverted';
  }
};
