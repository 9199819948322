export const BREAKPOINTS = {
  base: 0,
  xs: 0,
  sm: 425,
  md: 800,
  lg: 1024,
  xl: 1440,
  '2xl': 1536,
};

export const MEDIA_QUERIES = {
  isExtraLargeScreen: `screen and (min-width: ${BREAKPOINTS.xl}px)`,
  isLargeScreen: `screen and (min-width: ${BREAKPOINTS.lg}px) and (max-width: ${BREAKPOINTS.xl - 1}px)`,
  isSmallScreen: `screen and (min-width: ${BREAKPOINTS.md + 1}px) and (max-width: ${BREAKPOINTS.lg - 1}px)`,
  isTopNavTruncate: `screen and (min-width: ${BREAKPOINTS.md + 1}px) and (max-width: ${BREAKPOINTS.md + 100}px)`,
  isMobile: `(max-width: ${BREAKPOINTS.md}px)`,
  isTinyScreen: `screen and (max-width: ${BREAKPOINTS.sm}px)`,
  isPrint: 'print',
  isSafari: 'not all and (min-resolution:.001dpcm)',
};
