import _isEmpty from 'lodash/isEmpty';

import { ByersHouseMetadata, CustomerCount, OptIn } from '@arrived/models';

import { _marketingApi, affiliateApi, marketingApi } from './api';
import { createQuery } from './create-query';

/**
 * Grab content from SST for the byers home project
 */
export const getByersHouseMetadata = () =>
  createQuery<ByersHouseMetadata>({
    apiInstance: marketingApi,
    method: 'get',
    url: `/metadata/thebyershouse`,
  });

export const getByersHouseViralLoopsCampaignStats = () =>
  createQuery<{ leadCount: number; referralCountTotal: number }>({
    apiInstance: marketingApi,
    method: 'get',
    url: '/campaign/stats',
  });

export const getCustomerSubscriptions = (cioId: string) =>
  createQuery<OptIn>({
    apiInstance: _marketingApi,
    method: 'get',
    url: `/customerio/${cioId}`,
  });

export const postCustomerSubscription = (requestData: OptIn) =>
  createQuery<OptIn>({
    apiInstance: _marketingApi,
    method: 'post',
    url: '/customerio/unsubscribe',
    requestData,
  });

export const getCustomerCount = () =>
  createQuery<CustomerCount>({
    apiInstance: _marketingApi,
    method: 'get',
    url: '/customerio/count',
  });

// Assuming that `trackTuneAffiliateConversion` will always be a post method without a request body
export const trackTuneAffiliateConversion = (urlPath: string) =>
  createQuery<void>({
    apiInstance: affiliateApi,
    method: 'post',
    url: urlPath,
  });
