/**
 * Common errors from failed signup. Pulled from Auth0 documentation
 * https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#sign-up
 */
export enum Auth0SignupErrorCodes {
  INVALID_PASSWORD = 'invalid_password',
  INVALID_SIGNUP = 'invalid_signup',
  PASSWORD_DICTIONARY_ERROR = 'password_dictionary_error',
  PASSWORD_NO_USER_INFO_ERROR = 'password_no_user_info_error',
  PASSWORD_STRENGTH_ERROR = 'password_strength_error',
  UNAUTHORIZED = 'unauthorized',
  USER_EXISTS = 'user_exists',
  USERNAME_EXISTS = 'username_exists',
}
