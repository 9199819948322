import { FormattedMessage } from 'react-intl';

import { gradients, Image, Stack, TitleText } from '@arrived/bricks';
import { getImageKitSource } from '@arrived/bricks-common';

const houseImage = getImageKitSource({
  uri: 'https://cdn.arrivedhomes.com/images/signup/house_cutoff_left.png',
  width: 500,
  height: 390,
});

const texturedTriangle = getImageKitSource({
  uri: 'https://cdn.arrivedhomes.com/images/signup/textured_triangle.png',
  width: 190,
  height: 124,
});

export const Variation1 = () => {
  return (
    <>
      <Stack px="$12" pt="$6">
        <TitleText token="title.display.xlarge" tag="h1" fontWeight="bold">
          <FormattedMessage
            id="new-properties-available-now"
            defaultMessage="<new>New</new>{br}Properties{br}Available Now!"
            values={{
              new: (parts) => (
                <TitleText
                  token="title.display.xlarge"
                  tag="span"
                  fontWeight="bold"
                  style={{
                    background: `linear-gradient(to right, ${gradients['gradient.primary.default'][0]}, ${gradients['gradient.primary.default'][1]})`,
                    backgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {parts}
                </TitleText>
              ),
              br: <br />,
            }}
          />
        </TitleText>
      </Stack>
      <Image
        source={houseImage}
        position="absolute"
        bottom={0}
        left={0}
        width={500}
        height={390}
        contentFit="contain"
      />
      <Image position="absolute" bottom={0} left={0} source={texturedTriangle} />
    </>
  );
};
