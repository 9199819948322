import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postCreateOfferingTransaction } from '@arrived/api_v2';
import { OfferingTransaction } from '@arrived/models';

import { offeringTradesQueryKeyFn } from './trades.keys';

export function useAdminPostTradeMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postCreateOfferingTransaction,
    onSuccess: (data) => {
      // TODO: When we port all admin queries over here, we should invalidate the trades for the user/account as well.
      const { offeringId } = data;

      queryClient.setQueriesData<OfferingTransaction[] | undefined>(
        {
          queryKey: offeringTradesQueryKeyFn(offeringId),
        },
        (trades) => [data].concat(trades ?? []),
      );
      queryClient.invalidateQueries({ queryKey: offeringTradesQueryKeyFn(offeringId) });
    },
  });
}
