import {
  CreateQuestionnaireRequest,
  HydratedQuestionnaire,
  InvestorResponse,
  PatchQuestionRequest,
  PatchQuestionResponseOptionsRequest,
  PatchQuestionResponseOptionsResponse,
  PatchQuestionnaireQuestionsRequest,
  PatchQuestionnaireQuestionsResponse,
  PatchQuestionnaireRequest,
  PatchResponseOptionRequest,
  PostInvestorResponseRequest,
  PostQuestionRequest,
  PostResponseOptionRequest,
  Question,
  QuestionId,
  Questionnaire,
  QuestionnaireId,
  QuestionnaireQuestion,
  ResponseOption,
  ResponseOptionId,
  User,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getHydratedQuestionnaire = (id: QuestionnaireId) =>
  createQuery<HydratedQuestionnaire>({
    apiInstance: api,
    method: 'get',
    url: `/questionnaire/${id}/hydrated`,
  });

export const getAllQuestionnaires = () =>
  createQuery<Questionnaire[]>({
    apiInstance: api,
    method: 'get',
    url: '/questionnaire',
  });

export const getQuestionnaire = (questionnaireId: QuestionnaireId) =>
  createQuery<Questionnaire>({
    apiInstance: api,
    method: 'get',
    url: `/questionnaire/${questionnaireId}`,
  });

export const postQuestionnaire = (requestData: CreateQuestionnaireRequest) =>
  createQuery<Questionnaire>({
    apiInstance: api,
    method: 'post',
    url: '/questionnaire',
    requestData,
  });

export const patchQuestionnaire = ({
  questionnaireId,
  ...requestData
}: { questionnaireId: QuestionnaireId } & PatchQuestionnaireRequest) =>
  createQuery<Questionnaire>({
    apiInstance: api,
    method: 'patch',
    url: `/questionnaire/${questionnaireId}`,
    requestData,
  });

export const patchQuestionnaireQuestions = ({
  questionnaireId,
  ...requestData
}: { questionnaireId: QuestionnaireId } & PatchQuestionnaireQuestionsRequest) =>
  createQuery<PatchQuestionnaireQuestionsResponse>({
    apiInstance: api,
    method: 'patch',
    url: `/questionnaire/${questionnaireId}/question`,
    requestData,
  });

export const deleteQuestionnaire = (questionnaireId: QuestionnaireId) =>
  createQuery<Questionnaire>({
    apiInstance: api,
    method: 'delete',
    url: `/questionnaire/${questionnaireId}`,
  });

export const getQuestionnaireQuestions = (questionnaireId: QuestionnaireId) =>
  createQuery<QuestionnaireQuestion[]>({
    apiInstance: api,
    method: 'get',
    url: `/questionnaire/${questionnaireId}/question`,
  });

export const getQuestion = (questionId: QuestionId) =>
  createQuery<Question>({
    apiInstance: api,
    method: 'get',
    url: `/questionnaire/question/${questionId}`,
  });

export const postQuestion = ({
  questionnaireId,
  ...requestData
}: { questionnaireId: QuestionnaireId } & PostQuestionRequest) =>
  createQuery<Question>({
    apiInstance: api,
    method: 'post',
    url: `/questionnaire/${questionnaireId}/question`,
    requestData,
  });

export const patchQuestion = ({
  questionId,
  ...requestData
}: {
  questionId: QuestionId;
} & PatchQuestionRequest) =>
  createQuery<Question>({
    apiInstance: api,
    method: 'patch',
    url: `/questionnaire/question/${questionId}`,
    requestData,
  });

export const deleteQuestion = ({ questionId }: { questionId: QuestionId; questionnaireId: QuestionnaireId }) =>
  createQuery<Question>({
    apiInstance: api,
    method: 'delete',
    url: `questionnaire/question/${questionId}`,
  });

export const patchQuestionResponseOptions = ({
  questionId,
  ...requestData
}: {
  questionId: QuestionId;
} & PatchQuestionResponseOptionsRequest) =>
  createQuery<PatchQuestionResponseOptionsResponse>({
    apiInstance: api,
    method: 'patch',
    url: `/questionnaire/question/${questionId}/response-option/order`,
    requestData,
  });

export const getQuestionResponseOptions = (questionId: QuestionId) =>
  createQuery<ResponseOption[]>({
    apiInstance: api,
    method: 'get',
    url: `/questionnaire/question/${questionId}/response-option`,
  });

export const getResponseOption = (responseOptionId: ResponseOptionId) =>
  createQuery<ResponseOption>({
    apiInstance: api,
    method: 'get',
    url: `/questionnaire/response-option/${responseOptionId}`,
  });

export const postResponseOption = ({
  questionId,
  ...requestData
}: { questionId: QuestionId } & PostResponseOptionRequest) =>
  createQuery<ResponseOption>({
    apiInstance: api,
    method: 'post',
    url: `/questionnaire/question/${questionId}/response-option`,
    requestData,
  });

export const patchResponseOption = ({
  responseOptionId,
  ...requestData
}: { responseOptionId: ResponseOptionId } & PatchResponseOptionRequest) =>
  createQuery<ResponseOption>({
    apiInstance: api,
    method: 'patch',
    url: `/questionnaire/response-option/${responseOptionId}`,
    requestData,
  });

export const deleteResponseOption = (responseOptionId: ResponseOptionId) =>
  createQuery<ResponseOption>({
    apiInstance: api,
    method: 'delete',
    url: `/questionnaire/response-option/${responseOptionId}`,
  });

export const postInvestorResponse = ({
  questionnaireId,
  ...requestData
}: {
  questionnaireId: QuestionnaireId;
} & PostInvestorResponseRequest) =>
  createQuery<Record<string, never>>({
    apiInstance: api,
    method: 'post',
    url: `/questionnaire/${questionnaireId}/investor-response`,
    requestData,
  });

export const getQuestionnaireInvestorResponse = ({
  questionnaireId,
  userCid,
}: {
  questionnaireId: QuestionnaireId;
  userCid: User['cid'];
}) =>
  createQuery<InvestorResponse[]>({
    apiInstance: api,
    method: 'get',
    url: `/questionnaire/${questionnaireId}/investor-response?userCid=${userCid}`,
  });
