import { AssetType, OfferingStatus, OfferingType, SecurityType } from './offerings';
import { LeaseStatus } from './properties';

export interface OfferingSearchQuery {
  /**
   * When using sort, if you are sorting by `propertyRank` also make sure to sort my something that has guaranteed
   * ordering like `id`. This is because if we do not sorting by `propertyRank` can lead to orderings that get changed
   * for the same query since many offerings have the same `propertyRank`.
   */
  sort?: string;
  id?: NumberQuery;
  issuerId?: NumberQuery;
  dropId?: NumberQuery;
  marketId?: NumberQuery;
  tagId?: TermQuery<string>;
  tagCategory?: TermQuery<string>;
  status?: TermQuery<OfferingStatus>;
  securityType?: TermQuery<SecurityType>;
  assetType?: TermQuery<AssetType>;
  leaseStatus?: TermQuery<LeaseStatus>;
  availability?: TermQuery<AvailabilityFilterTerm>;
  leverage?: TermQuery<LeverageFilterTerm>;
  name?: string;
  q?: string;
  investorsCount?: NumberQuery;
  debtPercent?: NumberQuery;
  debtAmount?: NumberQuery;
  debtInterestPercent?: NumberQuery;
  fundedPercent?: NumberQuery;
  propertyRank?: NumberQuery;
  rent?: NumberQuery;
  squareFootage?: NumberQuery;
  bedrooms?: NumberQuery;
  fullBathrooms?: NumberQuery;
  halfBathrooms?: NumberQuery;
  yearBuilt?: NumberQuery;
  geo?: GeoQuery;
  page?: number;
  size?: number;
  fields?: string[];
  fund?: BooleanQuery;
  isEarlyAccess?: BooleanQuery;
  isOwned?: BooleanQuery;
  type?: TermQuery<OfferingType>;
}

export type OfferingSearchQueryKeys = keyof OfferingSearchQuery;

export interface GeoQuery {
  lat?: number;
  lon?: number;
  distance: string;
}

export interface NumberQuery {
  eq?: number[];
  neq?: number[];
  gt?: number;
  gte?: number;
  lt?: number;
  lte?: number;
}

export interface BooleanQuery {
  eq: boolean[];
}

export interface TermQuery<T> {
  eq?: T[];
  /* Only concerned about supporting equality for now. */
  // neq?: T[];
}

export const LeverageFilterTerm = {
  NONE: 'NONE',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
} as const;

export type LeverageFilterTerm = (typeof LeverageFilterTerm)[keyof typeof LeverageFilterTerm];

export const AvailabilityFilterTerm = {
  // deprecated
  // IS_OWNED = 'IS_OWNED',
  FOR_SALE: 'FOR_SALE',
  SOLD_OUT: 'SOLD_OUT',
  COMING_SOON: 'COMING_SOON',
} as const;

export type AvailabilityFilterTerm = (typeof AvailabilityFilterTerm)[keyof typeof AvailabilityFilterTerm];
