import { GetProps, createStyledContext, isWeb, styled, withStaticProperties } from '@tamagui/core';

import { Stack } from '../layout';
import { BodyText, UtilityText } from '../text';

const INFO_BOX_NAME = 'InfoBox';
const INFO_BOX_ROW_NAME = 'InfoBoxRow';
const INFO_BOX_LABEL_TEXT_NAME = 'InfoBoxLabelText';
const INFO_BOX_VALUE_TEXT_NAME = 'InfoBoxValueText';

const InfoBoxStyledContext = createStyledContext({
  highlighted: false,
});

const InfoBoxFrame = styled(Stack, {
  name: INFO_BOX_NAME,
  context: InfoBoxStyledContext,
  /// @ts-expect-error Works on web
  overflow: isWeb ? 'auto' : undefined,
  bg: '$onSurface.neutral.zebra',
  borderColor: '$onSurface.neutral.outlineAlt',
  borderWidth: '$0.25',
  borderRadius: '$2',
  p: '$6',
  variants: {
    highlighted: {
      true: {
        bg: '$surface.primary.default',
        borderColor: '$onSurface.primary.decorative',
      },
    },
    condensed: {
      true: {
        p: '$4',
      },
    },
  } as const,
});

const InfoBoxRow = styled(Stack, {
  name: INFO_BOX_ROW_NAME,
  p: '$4',
  justifyContent: 'space-between',
  alignItems: 'center',
  row: true,
});

const InfoBoxLabelText = styled(BodyText, {
  name: INFO_BOX_LABEL_TEXT_NAME,
  token: 'body.compact.small',
  color: '$onSurface.neutral.muted',
});

const InfoBoxValueText = styled(UtilityText, {
  name: INFO_BOX_VALUE_TEXT_NAME,
  token: 'utility.label.small',
});

export const InfoBox = withStaticProperties(InfoBoxFrame, {
  Row: InfoBoxRow,
  Label: InfoBoxLabelText,
  Value: InfoBoxValueText,
});

export type InfoBoxProps = GetProps<typeof InfoBox>;
