import { Fragment, forwardRef, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import { FormattedMessage, useIntl } from 'react-intl';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Carousel from '@mikehuebner/react-native-reanimated-carousel';
import { format } from 'date-fns';

import { useGetPropertyQuery } from '@arrived/admin-queries';
import {
  AnimatePresence,
  Divider,
  Gallery,
  GetProps,
  InfoBox,
  Loading,
  RenderItemArgs,
  Row,
  Sheet,
  SheetImperativeContext,
  SheetProps,
  Stack,
  isWeb,
  tokens,
} from '@arrived/bricks';
import { LazyImage } from '@arrived/bricks-common';
import { enUS, getTimezoneAgnosticDate } from '@arrived/common';
import { LEASE_STATUS_MESSAGES_STRINGS, Photo, Property, isLeaseStatusRented } from '@arrived/models';
import { useGetMarketQuery, useGetVideoMetadataByMarketId } from '@arrived/queries';

import { PropertyLocation } from '../../../../../location';
import { ProductHeader } from '../../../../../productHeader';
import { Video } from '../../../../../video';

import { usePropertyLabel } from './propertyListItem/usePropertyLabel';

type Detail = {
  label: keyof typeof enUS;
  value: string;
  tooltip?: GetProps<typeof Row.Label>['tooltip'];
};

// This comes from the border radius of the SheetBody when using rounded edges and is used to align the carousel header under the overlapping rounded edges
const OVERLAP = tokens.space['6'].val;

const FundPropertySheetContent = ({ property }: { property: Property }) => {
  const intl = useIntl();
  const { top } = useSafeAreaInsets();

  const label = usePropertyLabel(property);

  const { width } = useWindowDimensions();

  const marketState = useGetMarketQuery(property.marketId);
  const marketVideoState = useGetVideoMetadataByMarketId(property.marketId);
  const leaseStatusTooltip = useMemo(
    () => property.leaseStatus && LEASE_STATUS_MESSAGES_STRINGS[property.leaseStatus].tooltip,
    [property.leaseStatus, intl],
  );

  const goLiveDate = useMemo(() => {
    // Right now we're just assuming these are in UTC and displaying the same date for all users.
    // This should be a value that we're displaying differently depending on the user's TZ as
    // This should be a specific date/time in which the property goes "live".
    // https://arrivedhomes.slack.com/archives/C02SR97PVS8/p1703018164564119
    if (property.goLiveDate) {
      return getTimezoneAgnosticDate(property.goLiveDate);
    }
  }, [property.goLiveDate]);

  const details = useMemo<Detail[]>(
    () =>
      [
        !!property.purchasePrice && {
          label: 'product-details.purchase-price',
          // TODO: What value do we use here?
          value: intl.formatNumber(property.purchasePrice, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        },
        !!property.rent && {
          label: isLeaseStatusRented(property.leaseStatus)
            ? 'product-details.rent'
            : 'product-details.anticipated-rent',
          value: intl.formatNumber(property.rent, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
        },
        // TODO: Fund Ownership %
        !!goLiveDate && {
          label: 'product-details.date-added',
          value: format(goLiveDate, 'MMMM d, yyyy'),
        },
        {
          label: 'product-details.type',
          // TODO: Not entirely sure what "property type" we're talking about here, but for now they're all going to be SFR's
          value: intl.formatMessage({ id: 'product-details.type.single-family' }),
        },
        {
          label: 'product-details.status',
          value: intl.formatMessage({
            id: property.isStabilized ? 'product-details.stabilized' : 'product-details.recently-acquired',
          }),
        },
        {
          label: 'product-details.rental-status',
          value: intl.formatMessage({
            id: LEASE_STATUS_MESSAGES_STRINGS[property.leaseStatus].display.id as keyof typeof enUS,
          }),
          ...(leaseStatusTooltip
            ? {
                tooltip: intl.formatMessage({
                  id: leaseStatusTooltip.id as keyof typeof enUS,
                }),
              }
            : {}),
        },
      ].filter(Boolean) as Detail[],
    [intl, goLiveDate, property],
  );

  return (
    <>
      <Stack
        flexShrink={0}
        $platform-native={{
          width: width,
          aspectRatio: '4/3',
        }}
        width="100%"
        aspectRatio={1.6}
        overflow="visible"
      >
        <Stack position="absolute" top={0} left={0} right={0} bottom={-OVERLAP}>
          {!isWeb ? (
            <Stack flex={1}>
              <Carousel
                loop
                width={width}
                snapEnabled
                // I know this doesn't make sense, but it is the only way to adjust the velocity correctly
                pagingEnabled={false}
                data={property.photos}
                renderItem={({ item, index }) => (
                  <LazyImage
                    source={{
                      uri: item.url,
                    }}
                    ImageProps={{ priority: index === 0 ? 'high' : undefined }}
                    flex={1}
                  />
                )}
              />
            </Stack>
          ) : (
            <Gallery flex={1} items={property.photos}>
              <Gallery.Carousel
                renderItem={({ item, index }: RenderItemArgs<Photo>) => (
                  <LazyImage
                    source={{
                      uri: item.url,
                    }}
                    ImageProps={{ priority: index === 0 ? 'high' : undefined }}
                    flex={1}
                  />
                )}
              >
                {property.photos.length > 1 && (
                  <>
                    <Gallery.Carousel.Arrows
                      disableHover={isWeb}
                      transform={[
                        {
                          translateY: -OVERLAP / 2,
                        },
                      ]}
                    />
                    <Gallery.Carousel.Pagination
                      transform={[
                        {
                          translateY: -OVERLAP,
                        },
                      ]}
                    />
                  </>
                )}
              </Gallery.Carousel>
            </Gallery>
          )}
        </Stack>
      </Stack>

      <Stack position="absolute" top={top / 2} right={0} m="$6">
        <Sheet.Header.CloseIcon />
      </Stack>

      <Sheet.Body roundedEdges>
        <Sheet.ScrollView useScrollFade>
          <Stack gap="$6">
            <ProductHeader condensed>
              <ProductHeader.Title>{label}</ProductHeader.Title>
              <ProductHeader.Details property={property} />
            </ProductHeader>
            <Divider />
            <PropertyLocation property={property} icon="fund" />
            {/* TODO: need to fetch youtube video for market here */}
            {marketVideoState.isSuccess && (
              <Video youtubeId={marketVideoState.data}>
                <Video.Image.Cameron />
                <Video.Title>
                  <FormattedMessage
                    id={
                      marketState.isSuccess ? 'product-details.market-spotlight-x' : 'product-details.market-spotlight'
                    }
                    values={{ label: marketState.data?.title }}
                  />
                </Video.Title>
              </Video>
            )}
            {!!details.length && (
              <InfoBox p={0}>
                {details.map(({ label, value, tooltip }, idx) => (
                  <Fragment key={idx}>
                    {idx >= 1 && <Divider solid alt />}
                    <Row>
                      <Row.Label tooltip={tooltip}>
                        <FormattedMessage id={label} />
                      </Row.Label>
                      <Row.Value>{value}</Row.Value>
                    </Row>
                  </Fragment>
                ))}
              </InfoBox>
            )}
          </Stack>
        </Sheet.ScrollView>
      </Sheet.Body>
    </>
  );
};

type FundPropertySheetProps = SheetProps & {
  /**
   * If you can provide a property, we use that as placeholder data while we fetch the property from the API.
   */
  property?: Property;

  /**
   * By default, just pass a property ID and we'll fetch the property from the API.
   */
  propertyId?: Property['id'];
};

export const FundPropertySheet = forwardRef<SheetImperativeContext, FundPropertySheetProps>(
  ({ propertyId, property, ...rest }, ref) => {
    const definedPropertyId = property?.id ?? propertyId;

    const propertyState = useGetPropertyQuery(definedPropertyId, {
      placeholderData: property,
      enabled: definedPropertyId != null,
    });

    return (
      <Sheet ref={ref} {...rest}>
        <AnimatePresence>
          {!propertyState.isSuccess ? (
            <Stack
              key="property-loading"
              centered
              fullscreen
              bg="$foundation.neutral.yang"
              zIndex="$banner"
              animation="smoothSpring"
              opacity={1}
              enterStyle={{ opacity: 0 }}
              exitStyle={{ opacity: 0 }}
            >
              <Loading.Indicator variant="colored" />
            </Stack>
          ) : (
            <Stack
              key="property-loaded"
              asChild
              animation="smoothSpring"
              opacity={1}
              enterStyle={{ opacity: 0 }}
              exitStyle={{ opacity: 0 }}
            >
              <FundPropertySheetContent property={propertyState.data} />
            </Stack>
          )}
        </AnimatePresence>
      </Sheet>
    );
  },
);
