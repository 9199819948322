import { AccountCid, RegulationDAccessSummary } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getRegDAccessSummary = (accountCid?: AccountCid) => {
  if (!accountCid) {
    return {} as RegulationDAccessSummary;
  }

  return createQuery<RegulationDAccessSummary>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountCid}/reg-d-access-summary`,
  });
};
