import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { Offering } from '@arrived/models';

export const useOfferingUnits = (offering?: Offering) => {
  const intl = useIntl();
  return useMemo(() => {
    if (offering?.assetType === 'SHORT_TERM_NOTE') {
      return intl.formatMessage({ id: 'invest.units.notes' });
    }

    return intl.formatMessage({ id: 'invest.units.shares' });
  }, [offering, intl]);
};
