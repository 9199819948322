import { QueryClient, useQuery } from '@tanstack/react-query';

import { getUserAccount } from '@arrived/api_v2';
import { AccountCid, AccountId } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../utils';

import { accountsKeyFn } from './accounts.keys';

export function useGetAccountQuery(
  accountId?: AccountId | AccountCid,
  options?: UseAwaitedQueryOptions<typeof getUserAccount>,
) {
  return useQuery({
    queryKey: accountsKeyFn(accountId!),
    queryFn: () => getUserAccount(accountId!),
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}

export async function fetchAccountQuery(queryClient: QueryClient, accountId: AccountId | AccountCid) {
  return await queryClient.fetchQuery({
    queryKey: accountsKeyFn(accountId),
    queryFn: () => getUserAccount(accountId),
  });
}
