import { useCallback } from 'react';

import { FieldValues, Path, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';

import CheckCircle from '@mui/icons-material/CheckCircle';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Stack, StackProps } from '@mui/material';

import { notificationService } from '@arrived/features-toasty';
import { EmailListSubscriptionSchema } from '@arrived/forms';
import { EmailSubscribeMutationRequest, useEmailSubscribeMutation } from '@arrived/queries';

import { FormTextField, FormTextFieldProps } from '../form';

export interface SubscribeProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> extends StackProps {
  buttonProps?: LoadingButtonProps;
  formTextFieldProps?: Omit<FormTextFieldProps<TFieldValues, TName>, 'controllerProps'>;
  onSubscribe?: (email: string) => void;
  context?: string;
}

export const Subscribe = <TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({
  buttonProps,
  formTextFieldProps,
  onSubscribe,
  context,
  sx,
  ...rest
}: SubscribeProps<TFieldValues, TName>) => {
  const { control, formState, handleSubmit } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(EmailListSubscriptionSchema),
  });

  const { errors } = formState;
  const intl = useIntl();

  const { sx: formTextFieldPropsSx, ...formTextFieldPropsRest } = formTextFieldProps || {};
  const { children: buttonPropsChildren, sx: buttonPropsSx, ...buttonPropsRest } = buttonProps || {};

  const { mutate: postEmailSubscribe, isPending, isSuccess } = useEmailSubscribeMutation();

  const handleOnSubmit = useCallback(
    (values: EmailSubscribeMutationRequest) =>
      postEmailSubscribe(values, {
        onSuccess: (_, { email }) => {
          notificationService.success(
            <FormattedMessage id="thank-you-for-subscribing" defaultMessage="Thank you for subscribing!" />,
          );
          onSubscribe?.(email);
        },
        onError: () =>
          notificationService.error(
            <FormattedMessage id="email-subscription-error" defaultMessage="Error subscribing to mailing list" />,
          ),
      }),
    [postEmailSubscribe],
  );

  const StackFormComponent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is weird with MUI, not worth fixing
    (props: any) => <form onSubmit={handleSubmit(handleOnSubmit)} {...props} />,
    [handleSubmit, handleOnSubmit],
  );

  return (
    <Stack
      alignItems="stretch"
      component={StackFormComponent}
      direction="row"
      borderRadius={1}
      overflow="hidden"
      height="fit-content"
      position="relative"
      sx={{ backgroundColor: 'white', borderTopRightRadius: 0, borderBottomRightRadius: 0, ...sx }}
      {...rest}
    >
      <FormTextField
        id={`${context ? `${context}.` : ''}subscribe-email.${Math.random()}`} // We need to math random because we create two copies of footer and dont remove it correctly from the DOM
        controllerProps={{ control, name: 'email' }}
        error={Boolean(errors.email)}
        placeholder={intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}
        variant="filled"
        type="email"
        sx={{
          backgroundColor: 'white',
          minWidth: '170px',
          flex: '1 1 auto',
          '& .MuiInputBase-input': { backgroundColor: 'white', px: 2, py: 1.5 },
          '& .MuiInputBase-root::before': { display: 'none' },
          ...formTextFieldPropsSx,
        }}
        {...formTextFieldPropsRest}
      />
      <LoadingButton
        color="secondary"
        disabled={isSuccess}
        loading={isPending}
        type="submit"
        sx={{
          height: 'unset',
          width: 'auto',
          minWidth: '150px',
          minHeight: 'unset',
          px: 2,
          py: 1.5,
          '&.Mui-disabled': {
            backgroundColor: 'dark.200',
          },
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          ...buttonPropsSx,
        }}
        variant="contained"
        {...buttonPropsRest}
      >
        <Stack direction="row" gap={0.5}>
          {isSuccess ? (
            <>
              <CheckCircle />
              <FormattedMessage id="youre-set" defaultMessage="You're set!" />
            </>
          ) : (
            <>{buttonPropsChildren ?? <FormattedMessage id="join-list" defaultMessage="Join List" />}</>
          )}
        </Stack>
      </LoadingButton>
    </Stack>
  );
};
