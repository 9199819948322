import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchReitDemandLetterAcknowledgement } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';

import { offeringBreakdownKeyFn, taxDocumentKeyFn, taxDocumentsKeyFn } from './taxDocuments.keys';

export function usePatchTaxYearMutation(
  options?: UseAwaitedMutationOptions<typeof patchReitDemandLetterAcknowledgement>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchReitDemandLetterAcknowledgement,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: taxDocumentsKeyFn(variables.accountId) });
      queryClient.invalidateQueries({ queryKey: taxDocumentKeyFn(variables.accountId, variables.year) });
      queryClient.invalidateQueries({ queryKey: offeringBreakdownKeyFn(variables.accountId, variables.year) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
