import { styled } from '@tamagui/core';

import { Stack } from '../../../atoms';

export const SecondarySpotButtonsWrapper = styled(Stack, {
  alignItems: 'center',
  gap: '$3',
  row: true,
  p: '$4',
  justifyContent: 'flex-end',
  bg: '$onSurface.neutral.zebraAlt',
  $gtXs: {
    bg: '$transparent',
    p: 0,
  },
});
