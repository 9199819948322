import { OfferingDocument, OfferingDocumentFormat, OfferingDocumentType, SSTDocumentType } from '@arrived/models';

/**
 * An OfferingDocument can either be defined in the abacus DB or in SST, and each have slightly
 * different attributes so we can use this type to delineate them.
 */
export const ProductDocumentSource = {
  SST: 'SST',
  ABACUS: 'ABACUS',
} as const;

/**
 * An OfferingDocument can either be defined in the abacus DB or in SST, and each have slightly
 * different attributes so we can use this type to delineate them.
 */
export type ProductDocumentSource = (typeof ProductDocumentSource)[keyof typeof ProductDocumentSource];

export interface ProductDocumentBaseType {
  source: ProductDocumentSource;
  format: OfferingDocumentFormat;
}

/**
 * An OfferingDocument that was defined in SST. These are always links to external URLs.
 */
export interface SSTDocument extends ProductDocumentBaseType {
  source: 'SST';
  format: 'URL';
  type: SSTDocumentType;
  link: string;
}

/**
 * An OfferingDocument coming from abacus that was defined with a PDF that was uploaded to S3 and
 * will need to be downloaded before it can be viewed.
 */
export interface AbacusPDFDocument extends ProductDocumentBaseType {
  source: 'ABACUS';
  fileName?: string;
  format: 'PDF';
  id: OfferingDocument['id'];
  type: OfferingDocumentType;
}

/**
 * An OfferingDocument coming from abacus that was defined with a URL.
 */
export interface AbacusLinkDocument extends ProductDocumentBaseType {
  source: 'ABACUS';
  format: 'URL';
  id: OfferingDocument['id'];
  type: OfferingDocumentType;
  link: string;
}

export type ProductDocumentType = SSTDocument | AbacusPDFDocument | AbacusLinkDocument;
