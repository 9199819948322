import { useMemo } from 'react';

import { Property } from '@arrived/models';

import { Location, LocationProps } from '../../../../location';
import { useProductDetailsViewContext } from '../../../ProductDetailsViewContext';

export const FundPropertiesMap = Location.styleable<LocationProps & { properties: Property[] }>(
  ({ properties, $gtSm, $gtXxs, ...rest }, ref) => {
    const { setCurrentlySelectedPropertyId } = useProductDetailsViewContext();

    const propertiesWithCoordinates = useMemo(
      () => properties.filter(({ address }) => address?.lat != null && address?.lon != null),
      [properties],
    );

    const markers = useMemo(
      () => (
        <>
          {propertiesWithCoordinates.map(({ address, id }) => (
            <Location.Marker
              key={`marker-${id}`}
              coordinates={{ latitude: address?.lat!, longitude: address?.lon! }}
              icon="fund"
              // TODO: This is a hack to get the onPress to fire, but it's not ideal, nor should be happening
              onPressOut={() => {
                setCurrentlySelectedPropertyId(id);
              }}
              onPress={() => {
                setCurrentlySelectedPropertyId(id);
              }}
            />
          ))}
        </>
      ),
      [propertiesWithCoordinates],
    );

    // TODO: This formula is way too simplified for how this should actually be, but for now it gets us a good estimate and will get the coordinates in view
    const { latitude, longitude } = useMemo(() => {
      if (propertiesWithCoordinates.length < 1) {
        return { latitude: 38, longitude: -100 };
      }

      const { latitude, longitude } = propertiesWithCoordinates.reduce(
        ({ latitude, longitude }, { address }) => ({
          latitude: latitude + address?.lat!,
          longitude: longitude + address?.lon!,
        }),
        { latitude: 0, longitude: 0 },
      );
      return {
        latitude: latitude / propertiesWithCoordinates.length,
        longitude: longitude / propertiesWithCoordinates.length,
      };
    }, [propertiesWithCoordinates]);

    return (
      <Location ref={ref} h={140} $gtXxs={{ h: 180, ...$gtXxs }} $gtSm={{ h: 240, ...$gtSm }} {...rest}>
        <Location.Map coordinates={{ latitude, longitude }} zoom={3} interactive>
          {markers}
        </Location.Map>
      </Location>
    );
  },
);
