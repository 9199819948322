import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postSubmitOfferingTransaction } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';

import { accountTradesQueryKeyFn, tradeQueryKeyFn, userTradesQueryKeyFn } from './trades.keys';

export function usePostSubmitTradeMutation(options?: UseAwaitedMutationOptions<typeof postSubmitOfferingTransaction>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tradeId) => postSubmitOfferingTransaction(tradeId),
    ...options,
    onSuccess: (data, variables, context) => {
      const { id: tradeId, accountId, userId } = data;

      queryClient.invalidateQueries({ queryKey: tradeQueryKeyFn(tradeId) });
      queryClient.invalidateQueries({ queryKey: userTradesQueryKeyFn(userId) });
      queryClient.invalidateQueries({ queryKey: accountTradesQueryKeyFn(accountId) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
