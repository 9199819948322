import { useQuery } from '@tanstack/react-query';

import { getAccountTaxId } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../../utils';
import { accountsTaxIdKeyFn } from '../accounts.keys';

export function useGetAccountTaxIdQuery(
  accountId?: AccountId,
  options?: UseAwaitedQueryOptions<typeof getAccountTaxId>,
) {
  return useQuery({
    queryKey: accountsTaxIdKeyFn(accountId!),
    queryFn: () => getAccountTaxId(accountId!),
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}
