import { FormattedMessage } from 'react-intl';

import { A } from '@expo/html-elements';

import { Stack } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { PropertyManagementPartnerData } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../content';

export type PropertyManagementPartnerSectionProps = ProductDetailContentProps & {
  propertyManagement: PropertyManagementPartnerData;
};

export const PropertyManagementPartnerSection = ({
  propertyManagement,
  ...rest
}: PropertyManagementPartnerSectionProps) => (
  <ProductDetailContent {...rest}>
    <ProductDetailContent.Header>
      <FormattedMessage id="product-details.property-management" />
    </ProductDetailContent.Header>
    <ProductDetailContent.Body>
      <Stack gap="$4">
        <PropertyManagementTitle {...propertyManagement} />
        <Markdown Text={ProductDetailContent.Text}>{propertyManagement.descriptionMarkdown}</Markdown>
      </Stack>
    </ProductDetailContent.Body>
  </ProductDetailContent>
);

const PropertyManagementTitle = ({ directLink, name }: PropertyManagementPartnerData) =>
  directLink ? (
    <A href={directLink} rel="noopener noreferrer" target="_blank">
      <ProductDetailContent.SubHeader
        TextProps={{ color: '$onSurface.primary.decorative', textDecorationLine: 'underline' }}
      >
        {name}
      </ProductDetailContent.SubHeader>
    </A>
  ) : (
    <ProductDetailContent.SubHeader>{name}</ProductDetailContent.SubHeader>
  );
