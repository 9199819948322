import { ReactNode, useContext, useEffect } from 'react';

import { useRouter } from 'next/router';

import { getCookie } from 'cookies-next';

import { AnalyticsContext, arrivedVisitorIdCookie } from '@arrived/analytics';

const useAnalytics = () => {
  const router = useRouter();
  const { isLoaded } = useContext(AnalyticsContext);

  useEffect(() => {
    if (isLoaded) {
      window.rudderanalytics?.page?.();

      const pageEvent = () => {
        window.rudderanalytics?.page?.();
      };

      router.events.on('routeChangeComplete', pageEvent);
      router.events.on('hashChangeComplete', pageEvent);

      return () => {
        router.events.off('routeChangeComplete', pageEvent);
        router.events.off('hashChangeComplete', pageEvent);
      };
    }
  }, [isLoaded]);

  useEffect(() => {
    const arrivedVisitorId = getCookie(arrivedVisitorIdCookie);
    if (arrivedVisitorId) {
      window.rudderanalytics?.identify({ arrived_visitor_id: arrivedVisitorId });
    }
  }, [isLoaded]);
};

export const NextAnalytics = ({ children }: { children: ReactNode }) => {
  useAnalytics();

  return <>{children}</>;
};
