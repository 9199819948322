import { useQuery } from '@tanstack/react-query';

import { getPropertyFeatureCategories } from '@arrived/api_v2';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { featureCategoriesQueryKey } from './features.keys';

export function useGetFeatureCategoriesQuery(options?: UseAwaitedQueryOptions<typeof getPropertyFeatureCategories>) {
  return useQuery({
    queryKey: featureCategoriesQueryKey,
    queryFn: () => getPropertyFeatureCategories(),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}
