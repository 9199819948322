import { Dispatch, SetStateAction, createContext } from 'react';

import { stub } from '@arrived/common';
import { PostInvestorResponseRequest, QuestionId } from '@arrived/models';

export interface QuestionnaireContextType {
  currentQuestionIndex: number;
  goBack: () => void;
  proceed: () => void;
  questionnaireId: string;
  /**
   * Stores the responses locally since we don't have a way to fetch them from the server and this will allow us to
   * visually show the user that we recorded their response.
   */
  responses: Record<QuestionId, PostInvestorResponseRequest>;
  setResponses: Dispatch<SetStateAction<Record<QuestionId, PostInvestorResponseRequest>>>;
}

export const QuestionnaireContext = createContext<QuestionnaireContextType>({
  currentQuestionIndex: 0,
  goBack: stub,
  proceed: stub,
  questionnaireId: '',
  responses: {},
  setResponses: stub,
});
