import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postSyncAccountToCashAccountService } from '@arrived/api_v2';

import { accountsPrimaryKeyFn } from '../users';
import { UseAwaitedMutationOptions } from '../utils';

import { accountsKeyFn, cashAccountSyncKey } from './accounts.keys';

export function usePostSyncAccountToCashAccountServiceMutation(
  options?: UseAwaitedMutationOptions<typeof postSyncAccountToCashAccountService>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: cashAccountSyncKey,
    mutationFn: postSyncAccountToCashAccountService,
    ...options,
    onSuccess: (updatedAccount, variables, context) => {
      // Since we get an updated Account object that has updated CID values, let's replace what's in the cache
      if (updatedAccount) {
        queryClient.setQueryData(accountsPrimaryKeyFn(updatedAccount.primaryUser.id), updatedAccount);
        queryClient.setQueryData(accountsKeyFn(updatedAccount.id), updatedAccount);
        queryClient.setQueryData(accountsKeyFn(updatedAccount.cid), updatedAccount);
      }

      options?.onSuccess?.(updatedAccount, variables, context);
    },
  });
}
