export enum EVENTS {
  DOCUMENTS_SIGNED = 'Trade Docs Signed - Web',
  ONBOARDING_STEP_COMPLETED = 'Onboarding Step Completed - Web',
  CTA_CLICKED = 'CTA Clicked - Web',
  ACCOUNT_CREATED = 'User Account Login Created - Web',
  PERSONA_QUESTIONNAIRE_STARTED = 'User Account Persona Questionnaire Started - Web',
  PROPERTY_VIEWED = 'Property Viewed - Web',
  PORTFOLIO_PAGE_VIEWED = 'Portfolio Page Viewed - Web',
  BROKERAGE_ACCOUNT_CREATED = 'Brokerage Account Created - Web',
  OFFERING_FEATURED_FILTER_CLICKED = 'Offering Featured Filter Clicked - Web',
  OFFERING_FILTER_CLICKED = 'Offering Filter Clicked - Web',
  OFFERING_FILTER_STATE = 'Offering Filter State - Web',
  OFFERING_HISTORY_CLICKED = 'Offering History Clicked - Web',
  EMAIL_SUBSCRIPTION = 'email_subscription',
  BANK_LINK = 'Bank Account Updated - Web',
  PLAID_BANK_LINKED = 'plaid_bank_linked',
  EXPERIMENT_VIEWED = 'experiment_viewed',
  OFFERING_VIDEO_WATCHED = 'offering_video_watched',
  LEAD_FORM_COMPLETED = 'Lead Form Completed - Web',
  SIGNUP_FORM_DISPLAYED = 'Signup Form Displayed - Web',
  INTENDED_INVESTMENT_AMOUNT_PROVIDED = 'Intended Investment Amount Provided',
  WEBINAR_REGISTRATION = 'Webinar Registration',
  RECORDING_BUTTON_CLICKED = 'Recording Button Clicked',
  /**
   * Accounts for various steps in the Plaid flow. Primarily used for debugging.
   */
  BANK_LINK_EVENT_DETAILS = 'Bank Link Event Details',

  ACCREDITATION_UPDATE_SHEET_FIRED = 'Accreditation Update Sheet Fired',

  // Native Enums

  /**
   * When using the native app, this event is fired when the user
   * clicks on a deep link that redirected them to the application.
   */
  DEEP_LINK = 'Deep Link - Mobile',

  ONBOARDING_STEP_COMPLETED_MOBILE = 'Onboarding Step Completed - Mobile',
  /**
   * When a user ends up on a not found, we should be notified
   */
  NOT_FOUND = 'Not Found - Mobile',
  /**
   * The user selects a response to the ATT Prompt
   */
  ATT_TRACKING_RESPONSE = 'ATT Tracking Response - Mobile',

  /**
   * Used for the Byers House campaign to track interest in the property with the users
   * first name, last name, and email.
   *
   * @campaign https://fly.customer.io/workspaces/118079/journeys/campaigns/113/overview?channels=email-twilio-push-webhook-slack-in_app
   * @segment https://fly.customer.io/workspaces/118079/journeys/segments/520/overview
   */
  BYERS_HOUSE_INTEREST = 'byers_house_interest',

  /**
   * Archetype identified events to use for Google/Meta marketing conversions
   */
  REBEL_ARCHETYPE = 'REBEL Archetype Identified',
  WSJ_ARCHETYPE = 'WSJ Archetype Identified',
  NEWBIE_ARCHETYPE = 'Newbie Archetype Identified',
  ACCREDITED_INVESTOR_IDENTIFIED = 'Accredited Investor Identified',

  /**
   * Tracks when a user clicks the CTA to install the app sheet
   */
  APP_INSTALL_SHEET_CTA_CLICKED = 'app_install_sheet_cta_clicked',

  /**
   * Users who see the biometrics modal within the settings screen
   */
  BIOMETRIC_SETTINGS_MODAL_VIEWED = 'Biometric Settings Modal Viewed',

  /**
   * Enabled Biometric Authentication, this event is called
   * when a user successfully enables biometrics within the settings or modal,
   * we can differentiate between the two by merging account IDs
   */
  BIOMETRIC_AUTHENTICATION_ENABLED = 'Biometric Authentication Enabled',

  /**
   * Disabled Biometric Authentication, this event is called
   * when a user disables biometrics within the settings or modal.
   *
   * We can differentiate between the two by merging account IDs
   */
  BIOMETRIC_AUTHENTICATION_DISABLED = 'Biometric Authentication Disabled',

  /**
   * Error event for biometrics, this event is called
   * when a user encounters an error while enabling or disabling biometrics
   */
  BIOMETRIC_AUTHENTICATION_ERROR = 'Biometric Authentication Error',

  /**
   * When a user is prompted to authenticate with biometrics after
   * logging in, this event is called.
   */
  BIOMETRIC_AUTHENTICATION_POST_AUTHENTICATION_PROMPT_VIEWED = 'Biometric Authentication Post Authentication Prompt Viewed',

  /**
   * When a user is prompted to authenticate with biometrics after
   * logging in and they successfully enable biometrics, this event is called.
   */
  BIOMETRIC_AUTHENTICATION_POST_AUTHENTICATION_PROMPT_ENABLED = 'Biometric Authentication Post Authentication Prompt Enabled',

  /**
   * Post authentication prompt dismissed event, this event is called
   * when a user dismisses the post authentication prompt by hitting
   * "Maybe Later" or the "X" at the top of the modal.
   */
  BIOMETRIC_AUTHENTICATION_POST_AUTHENTICATION_PROMPT_DISMISSED = 'Biometric Authentication Post Authentication Prompt Dismissed',

  /**
   * If we catch an error during the post authentication prompt, this event is called.
   */
  BIOMETRIC_AUTHENTICATION_POST_AUTHENTICATION_PROMPT_ERROR = 'Biometric Authentication Post Authentication Prompt Error',
}
