import { forwardRef } from 'react';

import YouTube from 'react-youtube';

import { Button, GetProps, Modal, TamaguiElement, useDisclosure } from '@arrived/bricks';

import { VideoDisplay } from './VideoDisplay';

const playerOptions = { playerVars: { rel: 0 }, width: '100%', height: '100%' };

export const VideoPlayer = forwardRef<
  TamaguiElement,
  Omit<GetProps<typeof VideoDisplay>, 'onPress'> & { youtubeId: string }
>(({ youtubeId, ...rest }, ref) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  return (
    <>
      <VideoDisplay ref={ref} onPress={onOpen} {...rest} />
      <Modal open={isOpen} onClose={onClose} onToggle={onToggle} p={0} w="90%" aspectRatio="373 / 224">
        {/* Interestingly, without another focusable component the Modal traps focus and it cannot be passed onto the iframe where the youtube player
        is embedded */}
        <Button onPress={onClose} position="absolute" aria-label="Close YouTube player" top={-99999} left={-99999} />
        <YouTube videoId={youtubeId} opts={playerOptions} style={{ width: '100%', height: '100%' }} />
      </Modal>
    </>
  );
});
