import { trackTuneAffiliateConversion } from '@arrived/api_v2';

// Tune tracking SDK is loaded via Google Tag Manager. We check that it has loaded prior to firing the event.
export const trackAffiliateConversion = (conversionGoalUrlString: string) => {
  const transactionId = localStorage.getItem('arrived-tune-transaction-id');
  if (transactionId) {
    const finalUrlString = `${conversionGoalUrlString}&transaction_id=${transactionId}`;
    trackTuneAffiliateConversion(finalUrlString).catch((error) =>
      console.log('Error tracking affiliate conversion', error),
    );
  }
};
