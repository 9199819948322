import { useQuery } from '@tanstack/react-query';

import { CONFIG } from '@arrived/config';

import { ArrivedBuilderModels } from '../models';
import { FetchOneEntryParams, fetchOneEntry } from '../sdk';

import { builderPageQueryFn } from './keys';

export type FetchBuilderPageParams = {
  urlPath: string;
} & FetchOneEntryParams;

export const fetchBuilderPage = ({ urlPath, ...rest }: FetchBuilderPageParams) =>
  fetchOneEntry({
    apiKey: CONFIG.builderApiKey,
    model: ArrivedBuilderModels.PAGE,
    userAttributes: {
      urlPath,
    },
    enrich: true,
    ...rest,
  });

export const useGetBuilderPageQuery = (params: FetchBuilderPageParams) =>
  useQuery({
    queryKey: builderPageQueryFn(params),
    queryFn: () => fetchBuilderPage(params),
  });
