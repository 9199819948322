import { VariantSpreadFunction } from '@tamagui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO we should better understand `VariantSpreadFunction` and how it works
export const getCondensedVariant: VariantSpreadFunction<any, boolean> = (condensed, config) => {
  const isIconOnly = config.props.isIconOnly;
  const dimension = condensed ? '$8' : '$10';

  if (isIconOnly) {
    return {
      h: dimension,
      w: dimension,
      px: 0,
    };
  }

  return {
    h: dimension,
    px: '$4',
  };
};
