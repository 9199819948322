export const BuilderBannerIcons = {
  BELL: 'BELL',
  DIVIDEND_PAYMENT: 'DIVIDEND_PAYMENT',
  TRADE_PURCHASE_SUCCESS: 'TRADE_PURCHASE_SUCCESS',
  MOVE_MONEY_SUCCESS: 'MOVE_MONEY_SUCCESS',
  KORIN: 'KORIN',
} as const;

export type BuilderBannerIcons = keyof typeof BuilderBannerIcons;

export type BuilderBannerModel = {
  title?: string;
  description?: string;
  callToActionText?: string;
  callToActionUrl?: string;
  nativeCallToActionScreen?: string;
  userTargeting?: BuilderBannerUserTargetingModel[];
  icon?: BuilderBannerIcons;
};

export type BuilderBannerUserTargetingModel = {
  authenticated?: boolean;
  accredited?: boolean;
  userIds?: {
    ids: number[];
  };
  admins?: boolean;
};

export type BuilderBannerModelQuery = {
  data: BuilderBannerModel[];
};
