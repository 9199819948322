import { Rewards } from '@arrived/models';

import { rewardsApi } from '../api';
import { createQuery } from '../create-query';

export const getAccountVouchers = (accountUuid: string) =>
  createQuery<Rewards.VoucherSearchResponse>({
    apiInstance: rewardsApi,
    method: 'get',
    url: '/vouchers',
    config: {
      params: {
        accountUuid,
      },
    },
  });

export const postAccountVoucher = (requestData: Rewards.VoucherCreationRequest) =>
  createQuery<Rewards.Voucher>({
    apiInstance: rewardsApi,
    method: 'post',
    url: '/vouchers',
    requestData,
  });

export const postRevokeVoucher = (requestData: Rewards.Voucher) =>
  createQuery<Rewards.Voucher>({
    apiInstance: rewardsApi,
    method: 'post',
    url: `/vouchers/${requestData.uuid}/revoke`,
    requestData,
  });
