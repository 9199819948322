import { InfoBox, InfoBoxProps, styled, withStaticProperties } from '@arrived/bricks';

import { PRODUCT_INFO_BOX_NAME } from './constants';
import { ProductInfoBoxHeader } from './Header';
import { ProductInfoBoxStyledContext } from './ProductInfoBoxStyledContext';

export type ProductInfoBoxProps = InfoBoxProps & {};

export const ProductInfoBoxFrame = styled(InfoBox, {
  context: ProductInfoBoxStyledContext,

  name: PRODUCT_INFO_BOX_NAME,
  gap: '$6',

  variants: {
    variant: {
      highlight: {
        bg: '$surface.positive.default',
        borderColor: '$surface.positive.default',
      },
      highlightAlt: {
        bg: '$surface.positive.defaultAlt',
        borderColor: '$surface.positive.defaultAlt',
      },
      default: {},
    },

    link: {
      true: {
        cursor: 'pointer',
        hoverStyle: {
          bg: '$onSurface.neutral.zebraAlt',
        },
        pressStyle: {
          bg: '$onSurface.neutral.defaultInverted',
        },
      },
    },
  } as const,
  defaultVariants: {
    variant: 'default',
  },
});

export const ProductInfoBox = withStaticProperties(
  ProductInfoBoxFrame.styleable(({ children, onPress, ...rest }, ref) => {
    return (
      <ProductInfoBoxStyledContext.Provider link={onPress ? true : false}>
        <ProductInfoBoxFrame ref={ref} tag={onPress ? 'button' : undefined} onPress={onPress} {...rest}>
          {children}
        </ProductInfoBoxFrame>
      </ProductInfoBoxStyledContext.Provider>
    );
  }),
  {
    Header: ProductInfoBoxHeader,
  },
);
