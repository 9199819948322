import { ComponentType, useCallback } from 'react';

import { useNavigation } from '@react-navigation/native';

import { AUTH0_STEP_UP_SCOPE, useArrivedAuth0 } from '@arrived/arrived-auth0';
import { Loading, isWeb } from '@arrived/bricks';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { Sentry } from '@arrived/sentry';

import { useStepUpTrigger } from './use-step-up-trigger';

const useNativeNavigation = () => {
  if (isWeb) {
    return;
  }

  // We don't need to worry about this as we only use this for native instances.
  // This is how other library authors (solito) have done this.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useNavigation();
};

export const withStepUpAuthenticationRequired = <P extends object>(PassedComponent: ComponentType<P>) => {
  return (props: P) => {
    const { authorize } = useArrivedAuth0();
    const navigation = useNativeNavigation();
    const stepUpAuthEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.STEP_UP_AUTH);

    const _goBack = useCallback(() => {
      if (!isWeb && navigation) {
        const { canGoBack, goBack } = navigation;
        if (canGoBack()) {
          goBack();
        }
      }
    }, [navigation]);

    const scopes = useStepUpTrigger(({ scope }) => {
      const params: Parameters<typeof authorize>[0] = isWeb
        ? {
            scope: `${scope} step-up`,
            // Need to override the default URL (base path) since this isn't a login
            redirectUrl: window.location.href,
            prompt: null,
          }
        : {
            scope: `${scope} step-up`,
            prompt: null,
          };

      if (scope === '') {
        params.prompt = 'login';
      }

      return authorize(params)
        .then((credentials) => {
          // If we are undefined, we know that the credentials are expired
          // and we don't have a refresh token
          if (credentials === undefined) {
            _goBack();
          }
        })
        .catch(() => {
          // If we get an error, we need to clear the credentials and go back
          _goBack();
        });
    }, stepUpAuthEnabled);

    if (stepUpAuthEnabled && (scopes === null || !scopes.includes(AUTH0_STEP_UP_SCOPE))) {
      Sentry.addBreadcrumb({
        category: Sentry.BreadcrumbCategories.Auth,
        level: 'info',
        message: 'Step up authentication required',
        data: {
          scopes,
        },
      });

      return (
        <Loading centered fullscreen flexGrow={1}>
          <Loading.Indicator variant="colored" />
        </Loading>
      );
    }

    return <PassedComponent {...props} />;
  };
};
