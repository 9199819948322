import { FormattedNumber } from 'react-intl';

interface FormattedPrimaryDataPointProps {
  value: number;
}

export const FormattedPrimaryDataPoint = ({ value }: FormattedPrimaryDataPointProps) => {
  return (
    <FormattedNumber
      minimumFractionDigits={value % 1 === 0 ? 0 : 2}
      maximumFractionDigits={2}
      trailingZeroDisplay="stripIfInteger"
      value={value}
      style="currency"
      currency="USD"
    />
  );
};
