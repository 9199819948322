import { GetProps, styled } from '@tamagui/core';

import { RootLayout } from '../RootLayout';

export const Stack = styled(RootLayout, {
  name: 'Stack',
  tag: 'div',
});

export type StackProps = GetProps<typeof Stack>;
