import { ForwardedRef, forwardRef, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { GetProps, composeEventHandlers, styled, useComposedRefs, withStaticProperties } from '@tamagui/core';

import ReanimatedCarousel, { ICarouselInstance, TCarouselProps } from '@mikehuebner/react-native-reanimated-carousel';
import _debounce from 'lodash/debounce';

import { Stack } from '../../atoms';

import { Arrows } from './Arrows';
import { CarouselStateContext } from './CarouselStateContext';
import { CAROUSEL_FRAME_NAME, CAROUSEL_ITEM_NAME } from './consants';
import { useGalleryState } from './GalleryStateContext';
import { Pagination } from './Pagination';
import { RenderItem } from './types';

const CarouselFrame = styled(Stack, {
  name: CAROUSEL_FRAME_NAME,

  flex: 1,
  overflow: 'hidden',
});

const CarouselItem = styled(Stack, {
  name: CAROUSEL_ITEM_NAME,

  flex: 1,
  overflow: 'hidden',
});

export type CarouselProps<T> = GetProps<typeof CarouselFrame> & {
  ReanimatedCarouselProps?: Pick<
    TCarouselProps<T>,
    | 'onSnapToItem'
    | 'onProgressChange'
    | 'autoPlay'
    | 'autoPlayInterval'
    | 'defaultIndex'
    | 'loop'
    | 'withAnimation'
    | 'customAnimation'
    | 'enabled'
  >;
  renderItem: RenderItem<T>;
};

export const CarouselImpl = <T,>(
  { children, onLayout, renderItem, ReanimatedCarouselProps, ...rest }: CarouselProps<T>,
  ref: ForwardedRef<ICarouselInstance>,
) => {
  const [frameWidth, setFrameWidth] = useState<number>();
  const [frameHeight, setFrameHeight] = useState<number>();

  const { carouselRef, currentIndex, items, snapIndex, swipeProgress } = useGalleryState<T>();

  const composedCarouselRef = useComposedRefs(carouselRef, ref);

  const handleCarouselFrameOnLayout = useMemo(
    () =>
      composeEventHandlers<LayoutChangeEvent>((e) => {
        setFrameWidth(e.nativeEvent.layout.width);
        setFrameHeight(e.nativeEvent.layout.height);
      }, onLayout),
    [onLayout],
  );

  const { onProgressChange, onSnapToItem, ...ReanimatedCarouselRest } = ReanimatedCarouselProps || {};

  const handleOnProgressChange = useCallback(
    (offsetProgress: number, absoluteProgress: number) => {
      swipeProgress.value = absoluteProgress;

      const index = carouselRef.current?.getCurrentIndex() ?? 0;

      if (!isNaN(index) && index !== currentIndex.value && index < items.length) {
        currentIndex.value = index;
      }

      if (onProgressChange && typeof onProgressChange === 'function') {
        onProgressChange(offsetProgress, absoluteProgress);
      }
    },
    [currentIndex, carouselRef, items, swipeProgress, onProgressChange],
  );

  const handleOnSnapToItem = useCallback(
    (index: number) => {
      snapIndex.value = index;

      onSnapToItem?.(index);
    },
    [snapIndex, onSnapToItem],
  );

  const carouselStateContextValue = useMemo(
    () => ({ layout: { width: frameWidth, height: frameHeight } }),
    [frameWidth, frameHeight],
  );

  return (
    <CarouselStateContext.Provider value={carouselStateContextValue}>
      <CarouselFrame onLayout={handleCarouselFrameOnLayout} {...rest}>
        {Boolean(frameWidth) && Boolean(frameHeight) && (
          <ReanimatedCarousel
            width={frameWidth ?? 0}
            height={frameHeight ?? 0}
            ref={composedCarouselRef}
            data={items}
            renderItem={(item) => (
              <CarouselItem>
                {renderItem({ ...item, dimensions: { width: frameWidth ?? 0, height: frameHeight ?? 0 } })}
              </CarouselItem>
            )}
            {...ReanimatedCarouselRest}
            onProgressChange={handleOnProgressChange}
            onSnapToItem={handleOnSnapToItem}
            snapEnabled
            // I know this doesn't make sense, but it is the only way to adjust the velocity correctly
            pagingEnabled={false}
          />
        )}
        {children}
      </CarouselFrame>
    </CarouselStateContext.Provider>
  );
};

export const Carousel = withStaticProperties(
  forwardRef(CarouselImpl) as <T>(props: CarouselProps<T> & { ref?: ForwardedRef<ICarouselInstance> }) => JSX.Element,
  {
    Arrows,
    Pagination,
  },
);
