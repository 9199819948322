import { isAfter } from 'date-fns';

import { isPostedOnHoldTransaction, isTradeSignNeeded } from '@arrived/cash-account-lib';
import {
  Sale,
  SaleApprovalStatus,
  SaleStatus,
  OfferingTransaction as Trade,
  TransactionStatus as TradeStatus,
} from '@arrived/models';

import { CashAccountTransactionStatus, Transaction } from '../types';

/**
 * Provides the investor-readable status of a given Abacus trade. status or cash account transaction status.
 *
 * To be used within the cash account wallet screens where these two objects are conjoined.
 */
export const getDisplayStatusForTrade: (trade: Trade) => CashAccountTransactionStatus = (trade: Trade) => {
  if (isTradeSignNeeded(trade)) {
    return CashAccountTransactionStatus.SIGNATURE_NEEDED;
  }
  if ([TradeStatus.FUNDED, TradeStatus.SETTLED].indexOf(trade.status) >= 0) {
    return CashAccountTransactionStatus.POSTED;
  }
  if ([TradeStatus.CREATED].indexOf(trade.status) >= 0) {
    return CashAccountTransactionStatus.PROCESSING;
  }
  // TradeStatus.CANCELLED, TradeStatus.CANCELLED_PENDING, TradeStatus.UNWIND_PENDING, TradeStatus.UNWIND_SETTLED
  return CashAccountTransactionStatus.CANCELLED;
};

/**
 * Maps the status of a given Abacus Redemption to a more investor-readable CashAccountTransactionStatus.
 */
export const getDisplayStatusForRedemption: (sale: Sale) => CashAccountTransactionStatus = (sale) => {
  const isAfterNotificationWindow =
    sale.projections.redemptionPeriod &&
    isAfter(new Date(), new Date(sale.projections.redemptionPeriod.notificationWindowStart));

  if (SaleStatus.REQUESTED === sale.status && !isAfterNotificationWindow) {
    return CashAccountTransactionStatus.REQUESTED;
  }
  if ((SaleStatus.REQUESTED === sale.status && isAfterNotificationWindow) || sale.status === SaleStatus.PROCESSING) {
    if (sale.approvalStatus === SaleApprovalStatus.DISAPPROVED) {
      return CashAccountTransactionStatus.REJECTED;
    } else {
      return CashAccountTransactionStatus.APPROVED;
    }
  }
  if (SaleStatus.CANCELLED === sale.status && SaleApprovalStatus.DISAPPROVED === sale.approvalStatus) {
    return CashAccountTransactionStatus.REJECTED;
  }
  if (SaleStatus.SETTLED === sale.status) {
    return CashAccountTransactionStatus.POSTED;
  }

  return CashAccountTransactionStatus.CANCELLED;
};

/**
 * Provides the investor-readable status of a given cash account transaction.
 *
 * To be used within the cash account wallet screens where these two objects are conjoined.
 */
export const getDisplayStatusForTransaction = (
  transaction: Transaction,
  currentTimestampInMs: number,
): CashAccountTransactionStatus => {
  switch (transaction.status) {
    case 'PENDING':
      return CashAccountTransactionStatus.PENDING;
    case 'POSTED':
      if (isPostedOnHoldTransaction(transaction, currentTimestampInMs)) {
        return CashAccountTransactionStatus.PENDING;
      } else {
        return CashAccountTransactionStatus.POSTED;
      }
    case 'VOIDED':
      return CashAccountTransactionStatus.CANCELLED;
  }
};
