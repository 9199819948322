import { ReactNode } from 'react';
import { GestureResponderEvent } from 'react-native';

import { FormattedMessage } from 'react-intl';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ArrowLeftIcon, Button, ButtonProps, Container, Stack, TitleText, isWeb } from '@arrived/bricks';

interface SubnavHeaderProps {
  /**
   * FormattedMessage for the title to be shown.
   *
   * Web/desktop: rendered inside the card.
   * Mobile: rendered inside the header, next to the back button.
   */
  titleText: ReactNode;
  backButton?: {
    /**
     * Navigation props for the Back button. If undefined, no back button is shown.
     *
     * Doesn't have a default behavior so our 'back buttons' don't unintentionally strand users.
     */
    navigationProps: {
      href: string;
      onPress: (e: GestureResponderEvent) => void;
    };
    text?: ReactNode;
  };
  rightIcon?: {
    Icon: ButtonProps['Icon'];
    onPress: ButtonProps['onPress'];
    ariaLabel: string;
  };
}

/**
 * Subheader navigation + card header. Handles the back button & title text placement for all platforms.
 *
 * Accounts for the device safe area on native, as the background color needs to extend to the top.
 */
export const SubnavHeader = ({ titleText, backButton, rightIcon }: SubnavHeaderProps) => {
  const { top } = useSafeAreaInsets();

  return (
    <>
      {/* Card header section */}
      <Container.Bar
        position="unset"
        zIndex="$dropdown"
        py="$3"
        px={0}
        $gtSm={{ px: '$2' }}
        $platform-native={{ pt: top, pb: 0, px: '$2' }}
      >
        {/* Card header content -- simplifies alignment by separating from background color + safe area inset */}
        <Stack
          row
          width="100%"
          position="relative"
          alignItems="center"
          justifyContent="flex-start"
          $platform-native={{ justifyContent: 'center' }}
        >
          {backButton && (
            <Button
              condensed
              variant="ghost"
              Icon={ArrowLeftIcon}
              iconPosition="before"
              isIconOnly={!isWeb}
              left={0}
              position={isWeb ? 'relative' : 'absolute'}
              tag="a"
              {...backButton.navigationProps}
            >
              {/* For native, the button is shown alongside the title to conserve vertical space. */}
              {isWeb && (backButton.text || <FormattedMessage id="common.back" />)}
            </Button>
          )}
          {!isWeb && (
            <Stack p="$4" $gtXxs={{ p: '$6' }}>
              <TitleText token="title.heading.medium" aria-level="1">
                {titleText}
              </TitleText>
            </Stack>
          )}
          {rightIcon && !isWeb && (
            <Button
              variant="ghost"
              Icon={rightIcon.Icon}
              iconPosition="before"
              isIconOnly
              right={0}
              position="absolute"
              onPress={rightIcon.onPress}
              aria-label={rightIcon.ariaLabel}
            />
          )}
        </Stack>
      </Container.Bar>
      {/* For web: Title is presented as the first "child" within the card content */}
      {isWeb && (
        <Stack p="$4" $gtXxs={{ p: '$6' }} row justifyContent="space-between" alignItems="center">
          <TitleText token="title.heading.large" aria-level="1">
            {titleText}
          </TitleText>
          {rightIcon && (
            <Button
              variant="ghost"
              Icon={rightIcon.Icon}
              iconPosition="before"
              isIconOnly
              onPress={rightIcon.onPress}
              aria-label={rightIcon.ariaLabel}
            />
          )}
        </Stack>
      )}
    </>
  );
};
