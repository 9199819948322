import { useIntl } from 'react-intl';

import { AssetType, Offering } from '@arrived/models';

export interface ExpensesTooltipContentProps {
  offering: Offering;
}

export const ExpensesTooltipContent = ({ offering }: ExpensesTooltipContentProps) => {
  const intl = useIntl();

  return (
    <>
      {offering.assetType === AssetType.LTR
        ? intl.formatMessage({ id: 'property-details.returns-data.expenses-tooltip.sfr' })
        : intl.formatMessage({ id: 'property-details.returns-data.expenses-tooltip.vr' })}
    </>
  );
};
