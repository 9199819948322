import { useQuery } from '@tanstack/react-query';
import _merge from 'lodash/merge';

import { getUsersTrades } from '@arrived/api_v2';
import { TradesQueryFilters, UserId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { userTradesQueryKeyFn } from './trades.keys';

const defaultFilters = {
  size: 1000,
};

export function useUserTradesQuery(
  userId?: UserId,
  filters?: TradesQueryFilters,
  options?: UseAwaitedQueryOptions<typeof getUsersTrades>,
) {
  return useQuery({
    queryKey: userTradesQueryKeyFn(userId!, { ...defaultFilters, ...filters }),
    queryFn: () => (userId ? getUsersTrades(userId, _merge({}, defaultFilters, filters)) : Promise.resolve([])),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && userId != null,
  });
}
