import { useCallback } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { GetProps, Stack, isWeb, styled, useProps } from '@tamagui/core';

import { useSheetImperativeContext } from './SheetImperativeContext';
import { SheetStyledContext } from './SheetStyledContext';

export const SHEET_FOOTER_NAME = 'SheetFooter';

const SheetFooterFrame = styled(Stack, {
  name: SHEET_FOOTER_NAME,
  context: SheetStyledContext,

  justifyContent: 'flex-end',

  px: '$4',
  py: '$6',

  $gtXxs: {
    p: '$6',
  },

  $gtSm: {
    p: '$10',
  },
});

const SheetFooterBackground = styled(Stack, {
  context: SheetStyledContext,
  variants: {
    variant: {
      form: {
        bg: '$onSurface.neutral.zebraAlt',
      },
    },
  } as const,
});

export type SheetFooterProps = GetProps<typeof SheetFooterFrame> & {
  containerProps?: GetProps<typeof SheetFooterBackground>;
};

/**
 * Simple footer that watches for bottom safe areas.
 */
export const SheetFooter = SheetFooterFrame.styleable<SheetFooterProps>(({ containerProps, ...props }, ref) => {
  const activeProps = useProps(props);
  const { setFooterDimensions } = useSheetImperativeContext();
  const { bottom } = useSafeAreaInsets();

  const handleFooterLayout = useCallback(
    (e: LayoutChangeEvent) => {
      setFooterDimensions?.(e.nativeEvent.layout);
    },
    [setFooterDimensions],
  );

  return (
    // Special case for safe areas where we need to care for the bottom of the page
    // but we also want to be able to adjust the background color to anything else.
    // So grabbing the correct "backgroundColor" prop from `useProps` allows
    // use to extract that and apply it.
    <SheetFooterBackground
      bg={activeProps.backgroundColor}
      onLayout={!isWeb ? handleFooterLayout : undefined}
      {...containerProps}
    >
      <SheetFooterFrame ref={ref} mb={bottom} {...props} />
    </SheetFooterBackground>
  );
});
