import { useContext, useMemo } from 'react';

import Animated, { Extrapolate, interpolate, interpolateColor, useAnimatedStyle } from 'react-native-reanimated';

import { createStyledContext, styled } from '@tamagui/core';

import { BlurView, Stack } from '../../atoms';
import { tokens } from '../../tokens';
import { setColorAlpha } from '../../utils';

import { PAGINATION_FRAME_NAME } from './consants';
import { useGalleryState } from './GalleryStateContext';

const DOT_SIZE = 6;
const DOT_MARGIN = tokens.space['1'].val;
const PAGINATION_WIDTH = 48;
const PAGINATION_HORIZONTAL_PADDING = tokens.space['2'].val;
const DOT_STARTING_TRANSLATION = (PAGINATION_WIDTH - PAGINATION_HORIZONTAL_PADDING * 2) / 2 - DOT_SIZE / 2;

const AnimatedStack = Animated.createAnimatedComponent(Stack);

type PaginationContext = {
  colors?: 'light' | 'dark';
};

const PaginationContext = createStyledContext({ colors: 'light' });

export const usePaginationContext = () => {
  const context = useContext(PaginationContext);

  if (!context) {
    throw new Error('usePaginationContext must be used within an PaginationContextProvider');
  }

  return context;
};

const PaginationDot = ({ index }: { index: number }) => {
  const { items, swipeProgress } = useGalleryState();

  const { colors } = usePaginationContext();

  const color = useMemo(() => {
    switch (colors) {
      case 'dark':
        return tokens.color['neutral.light.1000'].val;
      case 'light':
      default:
        return tokens.color['neutral.light.0'].val;
    }
  }, [colors]);

  /**
   * Since `setColorAlpha` and its underlying function uses are non-worklet functions, they cannot be called
   * in reanimated hooks like these that run on a different thread. `mobile-app` testing will highlight this
   * while on web it will not.
   */
  const alphaColors = useMemo(
    () => [setColorAlpha(color, 0), setColorAlpha(color, 1), setColorAlpha(color, 0)],
    [color],
  );

  const borderColor = useMemo(() => {
    switch (colors) {
      case 'dark':
        return '$onSurface.neutral.default';
      case 'light':
      default:
        return '$onSurface.neutral.defaultInverted';
    }
  }, [colors]);

  const count = items.length;

  const paginationStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      swipeProgress.value,
      [0, count - 1],
      [DOT_STARTING_TRANSLATION, DOT_STARTING_TRANSLATION - (DOT_SIZE + DOT_MARGIN) * (count - 1)],
      Extrapolate.CLAMP,
    );

    const scale = interpolate(
      swipeProgress.value,
      [index - 5, index - 1, index, index + 5],
      [0.5, 1, 1, 0.1],
      Extrapolate.CLAMP,
    );

    const opacity = interpolate(
      swipeProgress.value,
      [index - 0.5, index, index + 0.5],
      [0.5, 1, 0.5],
      Extrapolate.CLAMP,
    );

    const backgroundColor = interpolateColor(swipeProgress.value, [index - 0.5, index, index + 0.5], alphaColors);

    return {
      transform: [{ translateX }, { scale }],
      backgroundColor,
      opacity: opacity,
    };
  }, [count, swipeProgress, index, alphaColors]);

  return (
    <AnimatedStack
      bg="$interactive.neutral.restedInverted"
      boc={borderColor}
      borderWidth={1}
      style={paginationStyle}
      w={DOT_SIZE}
      h={DOT_SIZE}
      br="$full"
    />
  );
};

const PaginationFrame = styled(Stack, {
  name: PAGINATION_FRAME_NAME,
  context: PaginationContext,

  centered: true,

  position: 'absolute',
  left: 0,
  right: 0,
  bottom: '$4',

  zIndex: '$docked',
  pointerEvents: 'none',

  variants: {
    colors: {
      light: {},
      dark: {},
    },
  } as const,
  defaultVariants: {
    colors: 'light',
  },
});

export const Pagination = PaginationFrame.styleable((props, ref) => {
  const { items } = useGalleryState();

  return (
    <PaginationFrame ref={ref} {...props}>
      <BlurView
        tint="dark"
        row
        px={PAGINATION_HORIZONTAL_PADDING}
        py="$1"
        borderRadius="$full"
        gap={DOT_MARGIN}
        width={PAGINATION_WIDTH}
      >
        {items.map((_, index) => (
          <PaginationDot key={`pagination-${index}`} index={index} />
        ))}
      </BlurView>
    </PaginationFrame>
  );
});
