import {
  AccountId,
  AccountKycAml,
  PerformAccountKycAmlRequest,
  UpdateAccountKycAmlStatusRequest,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export interface PutAccountKycAmlRequest {
  accountId: AccountId;
  request: UpdateAccountKycAmlStatusRequest;
}

export interface PostAccountKycAmlRequest {
  accountId: AccountId;
  request: PerformAccountKycAmlRequest;
}

export const getAccountKycAml = (accountId: AccountId) =>
  createQuery<AccountKycAml>({
    apiInstance: api,
    method: 'get',
    url: `/accounts/${accountId}/kycaml`,
    config: {
      validateStatus: (status) => status === 422 || status < 300, // TODO: Remove this pending BE response
    },
  });

export const findAccountKycAml = (accountId: AccountId) =>
  createQuery<AccountKycAml>({
    apiInstance: api,
    method: 'get',
    url: `/accounts/${accountId}/kycaml/find`,
  });

export const postAccountKycAml = ({
  accountId,
  request,
}: {
  accountId: AccountId;
  request: PerformAccountKycAmlRequest;
}) =>
  createQuery<AccountKycAml>({
    apiInstance: api,
    method: 'post',
    url: `/accounts/${accountId}/kycaml`,
    requestData: request,
  });

export const putAccountKycAml = ({
  accountId,
  request,
}: {
  accountId: AccountId;
  request: UpdateAccountKycAmlStatusRequest;
}) =>
  createQuery<AccountKycAml>({
    apiInstance: api,
    method: 'put',
    url: `/accounts/${accountId}/kycaml`,
    requestData: request,
  });
