import { useMemo } from 'react';

import { bricksThemeConfig, useMedia, useTheme } from '@arrived/bricks';

import { CHART_SVG_PATTERNS } from '../ChartSvgPatternDefinitions';
import { BricksChartsTheme } from '../types';

/**
 * Creates a theme object for all supported cross-platform Victory charting components.
 *
 * We're using this custom setup instead of Victory's `theme` solution because their approach only
 * supports single-color fill values, nor do they have a concept of variants.
 */
export const useChartsTheme = (): BricksChartsTheme => {
  const theme = useTheme();
  const media = useMedia();

  const chartTheme = useMemo(() => {
    const defaultLabelStyle = {
      fontWeight: 100,
      fontSize: bricksThemeConfig.fonts.body.size['12'] as number,
      // Value is already defined in `createCalibreFont.ts`
      fontFamily: bricksThemeConfig.fonts.body.family as string,
      fill: theme['onSurface.neutral.muted'].val,
    };

    return {
      axisStyles: {
        default: {
          x: {
            tickLabels: defaultLabelStyle,
            axis: { stroke: theme['onSurface.neutral.outline'].val },
          },
          y: {
            tickLabels: defaultLabelStyle,
            grid: { stroke: theme['onSurface.neutral.outlineAlt'].val },
            axis: { strokeWidth: 0 },
          },
        },
        sparkline: {
          x: {
            tickLabels: defaultLabelStyle,
            axis: { strokeWidth: 0 },
            grid: { strokeWidth: 0 },
          },
          y: {
            tickLabels: defaultLabelStyle,
            axis: { strokeWidth: 0 },
            grid: { strokeWidth: 0 },
          },
        },
      },

      chartStyles: {
        primary: {
          data: {
            fill: CHART_SVG_PATTERNS.gradient.primary.fill,
            stroke: theme['onSurface.primary.decorative'].val,
            strokeWidth: 1,
          },
          labels: defaultLabelStyle,
        },
        primaryAlt: {
          data: {
            fill: CHART_SVG_PATTERNS.polkaDot.primary.fill,
          },
          labels: defaultLabelStyle,
        },
        secondary: {
          data: {
            fill: theme['onSurface.secondary.default'].val,
          },
          labels: defaultLabelStyle,
        },
        secondaryAlt: {
          data: {
            fill: CHART_SVG_PATTERNS.diagonalHatch.secondary.fill,
          },
          labels: defaultLabelStyle,
        },
        sparkline: {
          data: {
            fill: CHART_SVG_PATTERNS.gradient.primary.fill,
            stroke: theme['onSurface.primary.decorative'].val,
            strokeWidth: 3,
            strokeLinecap: 'round',
          },
          labels: defaultLabelStyle,
        },
      },

      tooltipStyles: {
        flyout: { strokeWidth: 0, fill: CHART_SVG_PATTERNS.neutral.dark.fill, pointerEvents: 'none' },
        label: {
          ...defaultLabelStyle,
          fill: theme['onSurface.neutral.defaultInverted'].val,
        },
      },

      lineStyles: {
        primary: {
          data: {
            stroke: theme['onSurface.primary.decorative'].val,
            strokeWidth: 1,
          },
          labels: defaultLabelStyle,
        },
        primaryAlt: {
          data: {
            stroke: theme['onSurface.primary.light'].val,
            strokeWidth: 1,
          },
          labels: defaultLabelStyle,
        },
        secondary: {
          data: {
            stroke: theme['onSurface.secondary.default'].val,
            strokeWidth: 1,
          },
          labels: defaultLabelStyle,
        },
        secondaryAlt: {
          data: {
            stroke: theme['onSurface.secondary.low'].val,
            strokeWidth: 1,
          },
          labels: defaultLabelStyle,
        },
        sparkline: {
          data: {
            stroke: theme['onSurface.primary.decorative'].val,
            strokeWidth: 3,
            strokeLinecap: 'round',
          },
          labels: defaultLabelStyle,
        },
      },
    };
  }, [media, theme]);

  return chartTheme;
};
