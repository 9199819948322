import { ArrivedEnvironmentVariableKeys, ArrivedEnvironmentVariables } from '../environment-variables';

/**
 * Grabs any platform specific keys, on native this uses `expo-constants` in place
 * of Vite.
 *
 * ! [Vite environments and modes are simply not available in NextJS or Cypress](https://vitejs.dev/guide/env-and-mode.html) !
 *
 * Null-coalescing the `process.env` check helps prevent crashing Vite apps when a
 * key is missing from our redefined object; if the app uses it, it will throw errors
 * during local dev but for unused vars the issue only arises on production.
 */
export const getEnvKey = (key: ArrivedEnvironmentVariableKeys) => {
  if (import.meta?.env?.[key] !== undefined || import.meta?.env?.[key] === '') {
    return import.meta.env[key] as string;
  }

  if (typeof process !== 'undefined' && process?.env?.[key]) {
    return process.env[key] as string;
  }

  return ArrivedEnvironmentVariables[key];
};
