import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

import { BodyText, Card, Stack, StackProps, TitleText, baseMediaBreakpoints } from '@arrived/bricks';
import { Constants } from '@arrived/common';
import { CompanyKpis, CustomerCount, KpiHighlightKey } from '@arrived/models';

interface KpiHighlight {
  key: KpiHighlightKey;
  title: FormattedMessageProps;
  description: FormattedMessageProps;
  kpiExtractFn: number | ((arrivedKpis?: CompanyKpis & CustomerCount) => number);
  isMonetaryValue?: boolean;
}

const KPI_HIGHLIGHTS: Record<KpiHighlightKey, KpiHighlight> = {
  'property-values': {
    key: 'property-values',
    title: {
      id: 'property-value-funded-title',
      defaultMessage: 'Property Value Funded',
    },
    description: {
      id: 'property-value-funded-description',
      defaultMessage:
        'Thousands of Arrived investors are fully funding new single family rental properties and investing in building their long-term wealth.',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.fundedPropertyValue ?? 0,
    isMonetaryValue: true,
  },
  'total-aum': {
    key: 'total-aum',
    title: {
      id: 'total-aum-title',
      defaultMessage: 'Assets Under Management',
    },
    description: {
      id: 'total-aum-description',
      defaultMessage:
        'Tens of thousands of people on Arrived have fully funded hundreds of properties, building their long-term wealth through real estate investing.',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.totalAum ?? 0,
    isMonetaryValue: true,
  },
  'active-markets': {
    key: 'active-markets',
    title: {
      id: 'active-markets',
      defaultMessage: 'Active Markets',
    },
    description: {
      id: 'active-markets-description',
      defaultMessage:
        'Arrived has properties and operations across the country, helping investors invest remotely in diversified markets.',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.cityCount ?? 0,
  },
  'company-funding': {
    key: 'company-funding',
    title: {
      id: 'company-funding-title',
      defaultMessage: 'Company Funding',
    },
    description: {
      id: 'company-funding-description',
      defaultMessage:
        'Arrived is backed by a world class group of investors and partnered with top tier financial institutions that believe in our mission of democratizing real estate investing.',
    },
    kpiExtractFn: Constants.equityFinancingAmount,
    isMonetaryValue: true,
  },
  'properties-funded': {
    key: 'properties-funded',
    title: {
      id: 'properties-funded',
      defaultMessage: 'Properties Funded',
    },
    description: {
      id: 'properties-funded-description',
      defaultMessage:
        'All Arrived properties are hand-picked and individually analyzed by our investment team, ensuring that our investors choose from an exceptional selection of homes.',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.fundedPropertiesCount ?? 0,
  },
  'active-investors': {
    key: 'active-investors',
    title: {
      id: 'active-investors',
      defaultMessage: 'Active Investors',
    },
    description: {
      id: 'active-investors-description',
      defaultMessage: '',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.investorsCount ?? 0,
  },
  'user-count': {
    key: 'user-count',
    title: {
      id: 'user-count',
      defaultMessage: 'Registered Users',
    },
    description: {
      id: 'user-count-description',
      defaultMessage: '',
    },
    kpiExtractFn: (arrivedKpis) => arrivedKpis?.count ?? 0,
  },
};

export interface CompanyKpiDisplayProps extends StackProps {
  kpis: CompanyKpis & CustomerCount;
  kpi1: KpiHighlightKey;
  kpi2: KpiHighlightKey;
  kpi3: KpiHighlightKey;
  showDescriptions?: boolean;
}

const maxWidth = baseMediaBreakpoints.md.maxWidth as number;

export const CompanyKpiDisplay = ({
  kpis,
  kpi1,
  kpi2,
  kpi3,
  showDescriptions = true,
  ...rest
}: CompanyKpiDisplayProps) => {
  const highlights = useMemo(
    () => [KPI_HIGHLIGHTS[kpi1], KPI_HIGHLIGHTS[kpi2], KPI_HIGHLIGHTS[kpi3]],
    [kpi1, kpi2, kpi3],
  );

  return (
    <Card
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      overflow="hidden"
      maxWidth={maxWidth}
      mx="$4"
      my="$6"
      $gtMd={{ mx: 'auto' }}
      {...rest}
      px="$12"
      py="$8"
    >
      <Stack
        gap="$8"
        $platform-web={{ display: 'flex', gridTemplateColumns: 'unset' }}
        // @ts-expect-error grid works in the nested `platform-web` however the typing is incorrect.
        $gtSm={{ row: true, '$platform-web': { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' } }}
      >
        {highlights.map((kpiHighlight) => {
          const kpi =
            typeof kpiHighlight.kpiExtractFn === 'function'
              ? kpiHighlight.kpiExtractFn(kpis)
              : kpiHighlight.kpiExtractFn;

          return (
            <Stack key={kpiHighlight.key} flexShrink={1} flexGrow={0} flexBasis="auto">
              <Stack gap="$2">
                <TitleText
                  tag="h3"
                  token="title.display.medium"
                  textAlign="center"
                  color="$onSurface.neutral.defaultInverted"
                  $gtSm={{ textAlign: 'left' }}
                >
                  {kpiHighlight.isMonetaryValue && <span className="dollar-sign">$</span>}
                  <FormattedNumber notation="compact" value={kpi} />
                  <span className="plus-sign">+</span>
                </TitleText>
                <Stack>
                  <TitleText
                    className="kpi-title"
                    token="title.heading.medium"
                    tag="div"
                    textAlign="center"
                    color="$onSurface.neutral.defaultInverted"
                    mb={0.5}
                    $gtSm={{ textAlign: 'left' }}
                  >
                    <FormattedMessage {...kpiHighlight.title} />
                  </TitleText>
                </Stack>
                {showDescriptions && (
                  <Stack>
                    <BodyText
                      color="$onSurface.neutral.defaultInverted"
                      textAlign="center"
                      $gtSm={{ textAlign: 'left' }}
                    >
                      <FormattedMessage {...kpiHighlight.description} />
                    </BodyText>
                  </Stack>
                )}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
};
