import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import _difference from 'lodash/difference';
import _uniq from 'lodash/uniq';
import _without from 'lodash/without';

import { EVENTS, useTrack } from '@arrived/analytics';
import { UnArray, stub } from '@arrived/common';
import { InvestmentCategory, OfferingFilter, convertOfferingFilterToSearchQuery } from '@arrived/models';

import { createUseContext } from '../createUseContext';

export interface OfferingFiltersContextState {
  /**
   * The currently specified filter options.
   *
   */
  offeringFilter: OfferingFilter;

  /**
   * The number of currently applied filters. All filter categories count as a single filter except
   * for markets which count 1 filter for each market specified.
   */
  getFilterCount: () => Promise<number>;

  /**
   * Updates the currently specified filter options.
   */
  setOfferingFilter: (filter: OfferingFilter) => void;

  /**
   * Updates the filter for a specific key.
   *
   * @example Toggles a filter, if it's already applied it will be removed, otherwise it'll be
   * applied.
   * ```ts
   * updateOfferingFilter('price', 'testing');
   * ```
   *
   * @example Removing a filter
   * ```ts
   * updateOfferingFilter('price');
   * ```
   */
  updateOfferingFilter: <K extends keyof OfferingFilter>(
    key: K,
    filter?: UnArray<OfferingFilter[K]>,
    override?: boolean,
  ) => void;

  /**
   * Checks if a specific filter of a specific key is currently active. If no filter is passed to
   * this function then it'll return true IFF there is no currently specified filter for the
   * requested key. Otherwise this returns true IFF the requested filter is one of (or the only)
   * filter applied for the specified key.
   */
  isFilterActive: <K extends keyof OfferingFilter>(key: K, filter?: UnArray<OfferingFilter[K]>) => boolean;
}

export interface OfferingFiltersContextProviderProps {
  offeringFilter: OfferingFilter;
  setOfferingFilter: (filter: OfferingFilter) => void;
}

/**
 * Context for managing the filters for the offering page.
 */
export const [OfferingFiltersContextProvider, useOfferingFilters] = createUseContext(
  {
    offeringFilter: {},
    getFilterCount: async () => 0,
    isFilterActive: () => false,
    setOfferingFilter: stub,
    updateOfferingFilter: stub,
  } as OfferingFiltersContextState,
  (Provider) =>
    ({ children, offeringFilter, setOfferingFilter }: PropsWithChildren<OfferingFiltersContextProviderProps>) => {
      const track = useTrack();

      useEffect(() => {
        track(EVENTS.OFFERING_FILTER_STATE, convertOfferingFilterToSearchQuery(offeringFilter));
      }, [offeringFilter]);

      const isFilterActive = useCallback(
        <K extends keyof OfferingFilter>(key: K, filter?: UnArray<OfferingFilter[K]>) => {
          if (key === 'investmentCategory') {
            return filter == null ? offeringFilter[key] == null : offeringFilter[key] === filter;
          }

          const currentFilters = offeringFilter[key] as Array<UnArray<OfferingFilter[K]>> | undefined;

          if (filter == null) {
            return !currentFilters?.length;
          }

          return currentFilters?.includes(filter) ?? false;
        },
        [offeringFilter],
      );

      const updateOfferingFilter = useCallback(
        <K extends keyof OfferingFilter>(key: K, filter?: UnArray<OfferingFilter[K]>, override?: boolean) => {
          // If no filter is passed, then we reset the values for that filter.
          if (filter == null) {
            const copy = { ...offeringFilter };
            delete copy[key];
            setOfferingFilter(copy);
            return;
          }

          if (key === 'investmentCategory') {
            setOfferingFilter({
              ...offeringFilter,
              investmentCategory: filter as InvestmentCategory,
            });
            return;
          }

          let updatedFilters: UnArray<OfferingFilter[K]>[] = [];
          const currentFilters = offeringFilter[key] as UnArray<OfferingFilter[K]>[] | undefined;

          if (override || !currentFilters?.length) {
            updatedFilters = [filter];
          } else if (currentFilters.includes(filter)) {
            updatedFilters = _without(currentFilters, filter);
          } else {
            updatedFilters = [...currentFilters, filter];
          }

          setOfferingFilter({ ...offeringFilter, [key]: updatedFilters });
        },
        [offeringFilter, setOfferingFilter],
      );

      const getFilterCount = useCallback(async () => {
        return Object.entries(offeringFilter).reduce(
          <K extends keyof OfferingFilter>(appliedFilterCount: number, [key, value]: [K, OfferingFilter[K]]) => {
            if (key !== 'markets') {
              if (Array.isArray(value)) {
                return appliedFilterCount + Math.min(1, value.length);
              } else {
                return appliedFilterCount + (value == null ? 0 : 1);
              }
            } else {
              return appliedFilterCount + ((value as OfferingFilter['markets'])?.length ?? 0);
            }
          },
          0,
        );
      }, [offeringFilter]);

      const value = useMemo(
        () => ({
          getFilterCount,
          isFilterActive,
          offeringFilter,
          setOfferingFilter,
          updateOfferingFilter,
        }),
        [getFilterCount, isFilterActive, offeringFilter, setOfferingFilter, updateOfferingFilter],
      );

      return <Provider value={value}>{children}</Provider>;
    },
);
