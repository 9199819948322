import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchProperty } from '@arrived/api_v2';
import { Property } from '@arrived/models';
import { propertiesKey } from '@arrived/queries';

import { propertyKeyFn } from './properties.keys';

export function usePatchPropertyMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchProperty,
    onSuccess: (data) => {
      queryClient.setQueryData(propertiesKey, (currentProperties: Property[] | undefined) => {
        const propertiesCopy = ([] as Property[]).concat(currentProperties ?? []);
        propertiesCopy.push(data);
        return propertiesCopy;
      });

      queryClient.setQueryData(propertyKeyFn(data.id), data);

      queryClient.invalidateQueries({ queryKey: propertiesKey });
      queryClient.invalidateQueries({ queryKey: propertyKeyFn(data.id) });
    },
  });
}
