import { FC, useMemo } from 'react';

import { GetProps, styled, useProps } from '@tamagui/core';

import { IconProps } from '../../../../icons';
import { Stack } from '../../../layout';
import { TAG_ICON_NAME, TagStyledContext } from '../Tag.constants';

import { getTagLabelPressableColor } from './getTagLabelPressableColor';
import { getTagVariantFontColor } from './getTagVariantFontColor';

const TagIconContainer = styled(Stack, {
  name: TAG_ICON_NAME,
});

export const TagIcon = TagIconContainer.styleable<
  GetProps<typeof TagIconContainer> & {
    Icon: FC<IconProps>;
  }
>((propsIn, ref) => {
  const { Icon, ...rest } = useProps(propsIn);

  const { disabled, isHovered, isKeyDown, isPressed, pressable, variant } = TagStyledContext.useStyledContext();

  const pressableProps = useMemo(() => {
    const color = getTagLabelPressableColor(variant, pressable);

    if (disabled) {
      return {};
    }

    return (isHovered || isKeyDown || isPressed) && color ? { color } : {};
  }, [isHovered, isKeyDown, isPressed, pressable, variant]);

  return (
    <TagIconContainer ref={ref} {...rest}>
      {Icon && <Icon color={getTagVariantFontColor({ disabled, pressable, variant })} size="xs" {...pressableProps} />}
    </TagIconContainer>
  );
});
