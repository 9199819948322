import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { Property, isLeaseStatusRented } from '@arrived/models';

import { PreFormattedDataPointProps } from './types';

type PropertyWithRent = Omit<Property, 'rent'> & { rent: number };

export type RentDataPointProps = PreFormattedDataPointProps & {
  property: PropertyWithRent;
};

export function propertyHasRent(property: Property): property is PropertyWithRent {
  return property.rent != null;
}

export const RentDataPoint = ({ property, ...rest }: RentDataPointProps) => {
  const rentMessageId = useMemo(
    () =>
      isLeaseStatusRented(property.leaseStatus)
        ? 'product-details.rent-monthly'
        : 'product-details.anticipated-rent-monthly',
    [property.leaseStatus],
  );

  return (
    <DataPoint
      colors="dark"
      variant="minimized"
      tooltip={{
        content: (
          <FormattedMessage
            id={
              isLeaseStatusRented(property.leaseStatus)
                ? 'product-details.rent.tooltip'
                : 'product-details.anticipated-rent.tooltip'
            }
          />
        ),
        title: <FormattedMessage id={rentMessageId} />,
      }}
      {...rest}
    >
      <DataPoint.Value>
        <FormattedNumber
          value={property.rent}
          currency="USD"
          style="currency"
          maximumFractionDigits={0}
          minimumFractionDigits={0}
        />
      </DataPoint.Value>
      <DataPoint.Label>
        <FormattedMessage id={rentMessageId} />
      </DataPoint.Label>
    </DataPoint>
  );
};
