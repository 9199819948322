import { QueryClient, useQuery } from '@tanstack/react-query';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { getCashAccount } from '../../api/cash-accounts';

import { cashAccountKeyFn } from './cashAccount.keys';

export type UseCashAccountOptions = UseAwaitedQueryOptions<typeof getCashAccount>;

export function useCashAccount(cashAccountId?: string | null, options?: UseCashAccountOptions) {
  return useQuery({
    queryKey: cashAccountKeyFn(cashAccountId),
    queryFn: () => {
      if (!cashAccountId) {
        throw Error('cashAccountId is required');
      }

      return getCashAccount(cashAccountId, { includeBalances: true });
    },
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
    enabled: (options?.enabled ?? true) && !!cashAccountId,
  });
}

export function fetchCashAccount(queryClient: QueryClient, cashAccountId?: string | null) {
  return queryClient.fetchQuery({
    queryKey: cashAccountKeyFn(cashAccountId),
    queryFn: () => {
      if (!cashAccountId) {
        throw Error('cashAccountId is required');
      }

      return getCashAccount(cashAccountId, { includeBalances: true });
    },
    staleTime: MAX_STALE_TIME,
  });
}
