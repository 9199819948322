import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import YouTube, { YouTubePlayer } from 'react-youtube';

import { EVENTS, useTrack } from '@arrived/analytics';
import { Stack, composeEventHandlers, isWebTouchable } from '@arrived/bricks';

import { type ProductVideoProps } from './ProductVideoProps';

export const ProductVideo = ({
  videoId,
  propertyName,
  offeringId,
  isPlaying,
  setIsPlaying,
  onPress,
  ...props
}: ProductVideoProps) => {
  const [pointerEvents, setPointerEvents] = useState<'none' | 'auto'>(isWebTouchable ? 'none' : 'auto');
  const playerRef = useRef<YouTubePlayer>(null);

  const track = useTrack();

  const handleOnPlay = useCallback(() => {
    track(EVENTS.OFFERING_VIDEO_WATCHED, {
      offering_id: offeringId,
      property_name: propertyName,
    });

    setIsPlaying(true);
  }, [propertyName, offeringId]);

  const handleOnReady = useCallback((player: YouTubePlayer) => {
    playerRef.current = player;
  }, []);

  const handleOnPress = useMemo(
    () =>
      composeEventHandlers(() => {
        if (!isWebTouchable) {
          return;
        }

        const timeout = setTimeout(() => {
          setPointerEvents('none');
        }, 1000);

        setPointerEvents('auto');
        setIsPlaying(!isPlaying);

        return () => clearTimeout(timeout);
      }, onPress),
    [isPlaying, onPress],
  );

  useEffect(() => {
    if (!isPlaying) {
      playerRef.current?.target?.pauseVideo();
    } else {
      playerRef.current?.target?.playVideo();
    }
  }, [playerRef, isPlaying]);

  return (
    <Stack pointerEvents={pointerEvents} onPress={handleOnPress} {...props}>
      <YouTube
        videoId={videoId}
        onPlay={handleOnPlay}
        onReady={handleOnReady}
        // https://developers.google.com/youtube/player_parameters
        opts={{ playerVars: { rel: 0 }, width: '100%', height: '100%' }}
        style={{ width: '100%', height: '100%' }}
      />
    </Stack>
  );
};
