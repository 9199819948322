import { QueryClient } from '@tanstack/react-query';

import { getPromotions } from '@arrived/api_v2';
import { AccountId, Rewards, UserId } from '@arrived/models';

import { getOrCreateRewardsAccountId } from './getOrCreateRewardsAccountId';

export interface PromotionUserInfo {
  accountId?: AccountId;
  userId?: UserId;
}

export const getPromotionRedemptionData = async (
  queryClient: QueryClient,
  promotionRedemptionRequest: Omit<
    Rewards.RewardRedemptionRequest,
    'accountUuid' | 'promotionUuid' | 'voucherUuids'
  > & {
    userInfo?: PromotionUserInfo;
  },
) => {
  const { userInfo } = promotionRedemptionRequest;
  const rewardsAccountUuid = await getOrCreateRewardsAccountId(queryClient, userInfo);

  // Find the enabled promotions with the specified 'code'
  let validPromotions;
  if (promotionRedemptionRequest.code && promotionRedemptionRequest.code !== '') {
    validPromotions = await getPromotions({ code: promotionRedemptionRequest.code, enabled: 'true' });
  }

  let foundPromotion;
  if (validPromotions) {
    foundPromotion = validPromotions.promotions.length ? validPromotions.promotions[0] : undefined;
  }

  // Pull out the now retrieved accountUuid and promotionUuid and use them for the preview
  const addedFields: Partial<Rewards.PromotionRedemptionRequest> = {};
  addedFields.accountUuid = rewardsAccountUuid;
  addedFields.promotionUuid = foundPromotion?.uuid;

  return {
    ...promotionRedemptionRequest,
    ...addedFields,
  };
};
