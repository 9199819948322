import { useMemo } from 'react';

import { useInvestmentProductType } from '@arrived/hooks';
import { InvestmentProductType, OfferingId, TradeFlow, TransactionStatus } from '@arrived/models';
import {
  useGetAccountOfferingBalancesQuery,
  useGetPrimaryAccountQuery,
  useOfferingQuery,
  useTradesQuery,
} from '@arrived/queries';

import { ProductDetailsView } from '..';

/**
 * Returns the IDP views that will apply to the requested offering.
 */
export function useProductViews(offeringId?: OfferingId) {
  const offeringState = useOfferingQuery(offeringId);

  const accountState = useGetPrimaryAccountQuery();
  const accountOfferingBalancesState = useGetAccountOfferingBalancesQuery(accountState.data?.id);

  const investmentProductType = useInvestmentProductType(offeringState.data);

  const offeringBalance = useMemo(
    () => accountOfferingBalancesState.data?.offerings.find(({ id }) => id === offeringId),
    [accountOfferingBalancesState.data?.offerings, offeringId],
  );

  const transactionsState = useTradesQuery(
    {
      flows: [TradeFlow.CASH_ACCOUNT, TradeFlow.NORTH_CAPITAL],
      offeringId: offeringId,
      statuses: [TransactionStatus.CREATED, TransactionStatus.FUNDED, TransactionStatus.SETTLED],
      // Will we ever need pagination here?
      size: 1_000,
    },
    { enabled: offeringId != null },
  );

  return useMemo(() => {
    const views: ProductDetailsView[] = [];

    switch (investmentProductType) {
      case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO:
        views.push(ProductDetailsView.DETAILS, ProductDetailsView.FINANCIALS, ProductDetailsView.PERFORMANCE);
        break;
      case InvestmentProductType.VACATION_RENTAL_IPO:
        views.push(ProductDetailsView.DETAILS, ProductDetailsView.FINANCIALS, ProductDetailsView.PERFORMANCE);
        break;
      case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND:
        views.push(ProductDetailsView.DETAILS, ProductDetailsView.PROPERTIES);
        if (offeringBalance || transactionsState.data?.length) {
          views.push(ProductDetailsView.PERFORMANCE);
        }
        break;
      case InvestmentProductType.PRIVATE_CREDIT_FUND:
        views.push(ProductDetailsView.DETAILS, ProductDetailsView.OFFERING, ProductDetailsView.INTRO_TO_DEBT);
        break;
      default:
        return null;
    }

    return views;
  }, [investmentProductType, offeringBalance, transactionsState.data]);
}
