import { stringify } from 'qs';

import { cashAccountApi, createQuery } from '@arrived/api_v2';

import { Transaction } from '../types/cash-accounts';
import {
  CashAccountTransactionQueryFilters,
  CashAccountTransactionsQueryFilters,
} from '../types/cashAccountTransactionQueryFilters';

export const getTransaction = (transactionCid: Transaction['cid'], filters: CashAccountTransactionQueryFilters) =>
  createQuery<Transaction>({
    apiInstance: cashAccountApi,
    method: 'get',
    url: `/transactions/${transactionCid}`,
    config: {
      params: filters,
      paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
    },
  });

export const getTransactions = (filters: CashAccountTransactionsQueryFilters) =>
  createQuery<Transaction[]>({
    apiInstance: cashAccountApi,
    method: 'get',
    url: `/transactions`,
    config: {
      params: filters,
      paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
    },
  });
