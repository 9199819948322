import { useCallback, useMemo } from 'react';

import { Stack, StackProps, ArrivedIconWithText, ArrivedIcon, ArrivedIconBlue } from '@arrived/bricks';

export interface ArrivedLogoProps extends StackProps {
  iconOnly?: boolean;
  whiteLogo?: boolean;
}

const LOGO_ICON_ONLY_MAP = {
  standard: <ArrivedIconBlue />,
  white: <ArrivedIcon />,
} as const;

const LOGO_MAP = {
  standard: <ArrivedIconWithText />,
  white: <ArrivedIconWithText />,
} as const;

export const ArrivedLogo = ({ whiteLogo, iconOnly, ...rest }: ArrivedLogoProps) => {
  const logoKey = useMemo(() => (whiteLogo ? 'white' : 'standard'), [whiteLogo]);

  const Icon = useCallback(
    () => <>{iconOnly ? LOGO_ICON_ONLY_MAP[logoKey] : LOGO_MAP[logoKey]}</>,
    [iconOnly, logoKey],
  );

  return (
    <Stack
      position="relative"
      alignItems="center"
      justifyContent="center"
      py="$2"
      width={iconOnly ? 36 : 154}
      {...rest}
    >
      <Icon />
    </Stack>
  );
};
