import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { Experiment, GrowthBook, GrowthBookProvider, Result } from '@growthbook/growthbook-react';
import { useQueryClient } from '@tanstack/react-query';
import { getUnixTime, parseISO } from 'date-fns';
import Cookies from 'js-cookie';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';

import {
  AnalyticsContext,
  EVENTS,
  arrivedVisitorIdCookie,
  useIdentify,
  useTrack,
  useUserTraits,
} from '@arrived/analytics';
import { getCurrentUser } from '@arrived/api_v2';
import { CONFIG } from '@arrived/config';
import { currentUserKeyFn, useGetCurrentUserQuery, useGetInvestorArchetype } from '@arrived/queries';

import { AppFeatures } from './types';

export const FeatureFlagContextProvider = ({ children }: PropsWithChildren) => {
  const { isLoaded, getAnonymousId } = useContext(AnalyticsContext);
  const identify = useIdentify();
  const track = useTrack();
  const traits = useUserTraits();
  const queryClient = useQueryClient();
  const currentUserState = useGetCurrentUserQuery();
  const archetype = useGetInvestorArchetype();
  const trackingCallbackRef = useRef<(experiment: Experiment<unknown>, result: Result<unknown>) => void>();

  trackingCallbackRef.current = (experiment, result) => {
    const trackUser = () =>
      track(EVENTS.EXPERIMENT_VIEWED, {
        archetype,
        experimentName: experiment.key,
        experimentGroupNumber: result.variationId,
      });

    // If the experiment is hashed on the `id` we can assume that the user is logged in (since it won't hash on
    // user_id unless we have explicitly provided that to Growthbook already). In that case we want to make sure that
    // the user ID has been identified in rudderstack before we track the experiment view so that it is tied to that
    // user ID.
    if (experiment.hashAttribute === 'id') {
      traits()
        .then((traits) => {
          if (_isEmpty(traits) || _isEmpty(traits['user_id'])) {
            queryClient
              .fetchQuery({ queryKey: currentUserKeyFn(), queryFn: getCurrentUser, staleTime: Infinity })
              .then(({ id }) => identify({ user_id: String(id) }, String(id)));
          }
        })
        .finally(trackUser);
    } else if (experiment.hashAttribute === 'arrivedVisitorId') {
      traits()
        .then((traits) => {
          if (_isEmpty(traits) || _isEmpty(traits['arrived_visitor_id'])) {
            const arrivedVisitorId = Cookies.get(arrivedVisitorIdCookie);
            if (arrivedVisitorId) {
              identify({ arrived_visitor_id: arrivedVisitorId });
            }
          }
        })
        .finally(trackUser);
    } else {
      trackUser();
    }
  };

  const trackingCallback = useCallback(
    (experiment: Experiment<unknown>, result: Result<unknown>) => trackingCallbackRef.current?.(experiment, result),
    [],
  );

  const gb = useMemo(
    () =>
      new GrowthBook<AppFeatures>({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: CONFIG.growthbookApiKey,
        trackingCallback,
      }),
    [trackingCallback],
  );

  useEffect(() => {
    gb.loadFeatures();
  }, []);

  useEffect(() => {
    const attributes = {};

    const arrivedVisitorId = Cookies.get(arrivedVisitorIdCookie);
    if (arrivedVisitorId) {
      _merge(attributes, { arrivedVisitorId });
    }

    if (isLoaded) {
      _merge(attributes, { rudderStackId: getAnonymousId() });
    }

    // Set attributes to target specific users by id or by role
    if (currentUserState.data) {
      const { id, admin, createdAt } = currentUserState.data;
      _merge(attributes, {
        // TODO: This really should be passed as a number but GrowthBook doesn't work with number "in" list operators
        //  so we're working with them to figure out the issue.
        id: `${id}`,
        loggedIn: true,
        employee: admin,
        createdAtEpoch: getUnixTime(parseISO(createdAt)),
      });
    } else {
      _merge(attributes, { id: undefined, loggedIn: false, employee: false });
    }

    gb.setAttributes(attributes);
  }, [currentUserState.data, getAnonymousId, isLoaded]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};
