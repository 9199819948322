import { FormattedMessage } from 'react-intl';

import { UserId } from '@arrived/models';
import { useGetCurrentUserQuery, useGetMFADetailsQuery } from '@arrived/queries';

import { VERIFICATION_STATUS, VerificationIndicator } from '../accounts';

interface ProfileMFAVerificationIndicatorProps {
  userId?: UserId;
}

export const ProfileMFAVerificationIndicator = ({ userId }: ProfileMFAVerificationIndicatorProps) => {
  const currentUserState = useGetCurrentUserQuery({ enabled: userId === undefined });
  const mfaDetailsState = useGetMFADetailsQuery(userId ?? currentUserState.data?.id);
  const isEnrolled = mfaDetailsState.data?.enrollmentStatus === 'ENROLLED';

  // Don't show anything if we're unsure of your MFA state, or if it's definitively not enabled
  if (mfaDetailsState.isLoading || mfaDetailsState.isError || !isEnrolled) return null;

  return (
    <VerificationIndicator
      prefix={
        <FormattedMessage id="profile.security.items.mfa.enrolledSecondary" defaultMessage="2-Step Verification: On" />
      }
      disableText
      status={VERIFICATION_STATUS.VERIFIED}
    />
  );
};
