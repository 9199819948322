import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addPropertyToOffering } from '@arrived/api_v2';
import { UseAwaitedMutationOptions, offeringKeyFn, offeringsKeyFn } from '@arrived/queries';

import { propertyKeyFn } from '../properties';

export function usePostAddPropertyToOfferingMutation(
  options?: UseAwaitedMutationOptions<typeof addPropertyToOffering>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addPropertyToOffering,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: offeringsKeyFn() });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.id) });
      queryClient.invalidateQueries({ queryKey: offeringKeyFn(data.shortName) });

      variables.propertyIds.forEach((propertyId) =>
        queryClient.invalidateQueries({ queryKey: propertyKeyFn(propertyId) }),
      );

      options?.onSuccess?.(data, variables, context);
    },
  });
}
