import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UseAwaitedMutationOptions } from '@arrived/queries';

import { adminPostUnlockCounterparty } from '../../../api';

import { adminCounterPartyKeyFn } from './adminCounterparty.keys';

export function useAdminPostCounterpartyUnlock(
  options?: UseAwaitedMutationOptions<typeof adminPostUnlockCounterparty>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminPostUnlockCounterparty,
    ...options,
    onSuccess: (data, variables, context) => {
      const counterpartyCid = data.cid;

      if (counterpartyCid) {
        queryClient.invalidateQueries({ queryKey: adminCounterPartyKeyFn(counterpartyCid) });
      }

      options?.onSuccess?.(data, variables, context);
    },
  });
}
