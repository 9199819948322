import { useCallback } from 'react';

import YouTube from 'react-youtube';

import clsx from 'clsx';

import { Box } from '@mui/material';

import { EVENTS, useTrack } from '@arrived/analytics';
import { Offering } from '@arrived/models';

import { PropertyThumbnailProps } from './PropertyThumbnail';

export interface PropertyVideoProps extends Omit<PropertyThumbnailProps, 'offering' | 'PropertyStatusFlagComponent'> {
  offering: Offering;
  video: string;
}

// Parameters for the YouTube embedded player, more information can be found
// https://developers.google.com/youtube/player_parameters
const playerOptions = { playerVars: { rel: 0 } };

/**
 * TODO: Replace this and PropertyThumbnail with better components that aren't styled as complexly. Purely using this
 *  as a stop-gap to get the feature out and then we can tackle making these components easier to use.
 */
export const PropertyVideo = ({ className, offering, video, wrapperHeight, wrapperWidth }: PropertyVideoProps) => {
  const track = useTrack();

  const handleOnPlay = useCallback(() => {
    track(EVENTS.OFFERING_VIDEO_WATCHED, {
      offering_id: offering.id,
      property_name: offering.name,
    });
  }, [offering]);

  return (
    <Box
      flex={1}
      width={wrapperWidth ?? 'auto'}
      height={wrapperHeight ?? 'auto'}
      lineHeight={0}
      className={clsx('property-thumbnail-wrapper', className)}
    >
      <Box
        className="property-thumbnail-image-wrapper"
        position="relative"
        sx={{ '& .youtube-player': { aspectRatio: '373 / 224', height: 'auto', width: '100%' } }}
      >
        <YouTube videoId={video} iframeClassName="youtube-player" onPlay={handleOnPlay} opts={playerOptions} />
      </Box>
    </Box>
  );
};
