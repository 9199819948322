import { useMemo } from 'react';

import { CONFIG } from '@arrived/config';

import * as Components from './components';
import { Content as BuilderContent, RegisteredComponent } from './sdk';

/**
 * This is the equivalent of Builders `registerComponent` function,
 * we are doing this as of their v2 release as they deprecated the `registerComponent` function.
 * https://github.com/BuilderIO/builder/blob/4fd24d4d917e218646623ad3b59e59410b42428d/examples/react-native/App.jsx
 */
const GenericRegisteredComponents = [
  Components.Button.registerComponent,
  Components.ResponsiveImage.registerComponent,
  Components.RichTextContent.registerComponent,
  Components.VideoComponent.registerComponent,
  Components.CallToAction.registerComponent,
  Components.Stack.registerComponent,
  Components.InfoGrid.registerComponent,
  Components.Header.registerComponent,
  Components.Text.registerComponent,
  Components.Icon.registerComponent,
  Components.TestimonialGrid.registerComponent,

  // @depreciated -- This is a temporary fix to allow for the legacy components to be used
  Components.UtilityText.registerComponent,
  Components.BricksLayout.registerComponent,
] satisfies RegisteredComponent[];

export type EssentialBuilderContentProps = Omit<Parameters<typeof BuilderContent>[0], 'apiKey'>;

/**
 * This component wraps the Builder.io Content component with
 * our API key and generic components that all instances of
 * Builder.io Content have access to.
 */
export const EssentialBuilderContent = ({ customComponents, ...props }: EssentialBuilderContentProps) => {
  const builderComponents = useMemo(
    () => [...GenericRegisteredComponents, ...(customComponents ?? [])],
    [customComponents],
  );

  return (
    <BuilderContent
      apiKey={CONFIG.builderApiKey}
      blocksWrapper={Components.Stack}
      customComponents={builderComponents}
      {...props}
    />
  );
};
