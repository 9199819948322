import { MutableRefObject, ReactNode, createContext, useContext, useEffect, useMemo, useRef } from 'react';

interface TooltipProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

interface TooltipControllerContextProps {
  tooltipRef: MutableRefObject<TooltipProps>;
}

const TooltipControllerContext = createContext({
  tooltipRef: {},
} as TooltipControllerContextProps);

export const useTooltipController = () => {
  const context = useContext(TooltipControllerContext);

  if (!context) {
    throw new Error('useTooltipController must be used within a TooltipControllerProvider');
  }

  return context;
};

export const TooltipControllerProvider = ({ children }: { children?: ReactNode }) => {
  const ref = useRef<TooltipProps>({ open: false, setOpen: () => null });

  const sheetValue = useMemo(() => ({ tooltipRef: ref }), []);

  return <TooltipControllerContext.Provider value={sheetValue}>{children}</TooltipControllerContext.Provider>;
};

/**
 * Handles synching tooltips such that only one is open at a time
 */
export const useTooltipSync = ([open, setOpen]: [boolean, (val: boolean) => void], action?: () => void) => {
  const { tooltipRef } = useTooltipController();

  useEffect(() => {
    action?.();

    if (open && tooltipRef && tooltipRef.current?.setOpen !== setOpen) {
      tooltipRef.current.setOpen?.(false);
      tooltipRef.current = { open, setOpen };
    }
  }, [open, action]);

  /**
   * This really only applies to native, it seems setting the above is not enough.
   */
  useEffect(() => {
    if (!tooltipRef.current) {
      tooltipRef.current = { open, setOpen };
    }
  }, []);
};
