import { useMutation } from '@tanstack/react-query';

import { postPropertyPhoto } from '@arrived/api_v2';
import { PhotoPost } from '@arrived/models';

export interface PropertyPhotoPostRequest {
  data: PhotoPost;
  position?: number;
  propertyId: number;
}

export function usePostPropertyPhotoMutation() {
  // Avoiding invalidation here since we use this mutation multiple times in a row and want to avoid reloading the
  // property multiple times.
  return useMutation({
    mutationFn: postPropertyPhoto,
  });
}
