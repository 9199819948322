import { RefObject, createContext, useContext } from 'react';

import { SharedValue } from 'react-native-reanimated';

import { ICarouselInstance } from '@mikehuebner/react-native-reanimated-carousel';

export type GalleryItem<T> = T;

export type GalleryStateContextProps<T> = {
  items: GalleryItem<T>[];

  carouselRef: RefObject<ICarouselInstance>;

  currentIndex: SharedValue<number>;
  snapIndex: SharedValue<number>;
  swipeProgress: SharedValue<number>;
};

export const GalleryStateContext = createContext({} as GalleryStateContextProps<unknown>);

export const useGalleryState = <T,>() => {
  const context = useContext(GalleryStateContext) as GalleryStateContextProps<T>;

  if (!context) {
    throw new Error('useGalleryState must be used within a GalleryStateContextProvider');
  }

  return context;
};
