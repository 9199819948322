import { PropsWithChildren, useCallback, useMemo } from 'react';

import type {
  ClearSessionOptions,
  ClearSessionParameters,
  Credentials,
  User,
  WebAuthorizeParameters,
} from 'react-native-auth0';

import { Auth0Provider, Auth0ProviderOptions, AuthorizationParams, useAuth0 } from '@auth0/auth0-react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import jwtDecode from 'jwt-decode';

import { ROUTES } from '@arrived/common';
import { CONFIG } from '@arrived/config';
import { Sentry } from '@arrived/sentry';

import { Auth0UserPermissions, CustomAuthorizationParameters, convertUser, getIdTokenProfileClaims } from './utils';

/**
 * A singleton instance of `Auth0` that can be used to interact with
 * the Auth0 API outside of the context of a React component. This
 * is useful for other parts of the application that need data related
 * to the current user that do not interact with React.
 */
export const UniversalAuth0 = new Auth0Client({
  domain: CONFIG.auth0.domain,
  clientId: CONFIG.auth0.clientId,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
});

// To be used when we consolidate the Auth0 SDK for web and mobile
// export const _NativeAuth0 = new Auth0({
//   domain: CONFIG.auth0.domain,
//   clientId: CONFIG.auth0.clientId,
// });

/**
 * A parent wrapper around `Auth0Provider` that provides our
 * `domain` and `clientId` from our environment configuration.
 * @see https://auth0.com/docs/quickstart/spa/react
 */
export const ArrivedAuth0Provider = ({
  children,
  authorizationParams,
  authCallbackUri = '',
  ...rest
}: PropsWithChildren<Omit<Auth0ProviderOptions, 'clientId' | 'domain'> & { authCallbackUri: string }>) => {
  const redirect_uri = useMemo(
    () =>
      typeof window === 'undefined' || window.location.pathname === CONFIG.baseHref
        ? CONFIG.appRoot
        : `${window.location.origin}${CONFIG.baseHref}${authCallbackUri}`,
    [authCallbackUri],
  );

  return (
    <Auth0Provider
      clientId={CONFIG.auth0.clientId}
      domain={CONFIG.auth0.domain}
      useRefreshTokens
      useRefreshTokensFallback
      authorizationParams={{
        audience: CONFIG.auth0.audience,
        scope: CONFIG.auth0.scope,
        connection: CONFIG.auth0.connection,
        redirect_uri,
        ...authorizationParams,
      }}
      {...rest}
    >
      {children}
    </Auth0Provider>
  );
};

export const useArrivedAuth0 = () => {
  const { loginWithRedirect, getAccessTokenSilently, logout, error, user, isLoading, isAuthenticated } = useAuth0();

  const authorize = useCallback(
    async (
      {
        prompt,
        scope,
        authorizationParams,
        redirectUrl = `${window.location.origin}${CONFIG.baseHref}`,
        ...parameters
      }: WebAuthorizeParameters &
        CustomAuthorizationParameters & {
          /**
           * This is a web-only option that is only used to allow
           * optional params to be passed in on the web `authorize` call.
           * We also don't want to overwrite the `prompt` option, so we
           * need to destructure it out here.
           *
           * @platform web
           */
          authorizationParams?: AuthorizationParams;
        } = {
        prompt: 'login',
      },
    ) => {
      try {
        let promptParams: Record<string, string> = {};

        if (prompt) {
          if (prompt === 'signup') {
            promptParams = {
              screen_hint: 'signup',
            };
          } else {
            promptParams = {
              prompt,
            };
          }
        }

        Sentry.addBreadcrumb({
          category: Sentry.BreadcrumbCategories.Auth,
          level: 'info',
          message: `Auth0 authorization parameters: ${JSON.stringify(promptParams)}`,
          data: {
            promptParams,
            ...parameters,
          },
        });

        return loginWithRedirect({
          appState: { returnTo: redirectUrl },

          ...parameters,

          authorizationParams: {
            scope,
            ...promptParams,
            ...authorizationParams,
          },
        });
      } catch (e) {
        console.error('Error authorizing user', e);
        Sentry.captureException(e);
      }
    },
    [loginWithRedirect],
  );

  const getCredentials = useCallback(async () => {
    try {
      // Get the access token from the cache
      const credentials = await getAccessTokenSilently({
        detailedResponse: true,
      });

      // Get any extra info we need to match the native auth0 package
      // credentials.expires_in is a different format than what the idToken returns
      const claims = getIdTokenProfileClaims(credentials.id_token);

      return {
        accessToken: credentials.access_token,
        idToken: credentials.id_token,
        expiresAt: (claims?.exp as number) ?? credentials.expires_in,
        scope: credentials.scope,
        tokenType: 'Bearer', // Hardcoded to match native -- this doesn't matter for us truly
        refreshToken: undefined, // On web, this is handled by the package and not exposed
      } satisfies Credentials;
    } catch (e) {
      Sentry.addBreadcrumb({
        category: Sentry.BreadcrumbCategories.Auth,
        level: 'error',
        message: `Error getting credentials: ${e}`,
      });

      Sentry.captureException(e);
    }
  }, [getAccessTokenSilently]);

  const hasValidCredentials = useCallback(
    async (minTtl: number = 0) => {
      try {
        const credentials = await getCredentials();

        if (!credentials) {
          return false;
        }

        const now = new Date().getTime();
        const expiration = credentials.expiresAt * 1000;
        const isExpired = expiration > now + minTtl;

        Sentry.addBreadcrumb({
          category: Sentry.BreadcrumbCategories.Auth,
          level: 'info',
          message: `Credentials expiration: ${expiration}`,
          data: {
            minTtl,
            now,
            isExpired,
            expiration,
            ...credentials,
          },
        });

        return !isExpired;
      } catch (e) {
        Sentry.addBreadcrumb({
          category: Sentry.BreadcrumbCategories.Auth,
          level: 'error',
          message: `Error checking if credentials are valid: ${e}`,
        });

        Sentry.captureException(e);

        return false;
      }
    },
    [getCredentials],
  );

  const getPermissions = useCallback(async () => {
    try {
      if (!isAuthenticated) {
        return [];
      }

      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        return [];
      }

      const { permissions } = jwtDecode<{ permissions?: Auth0UserPermissions[] }>(accessToken);

      if (!permissions || !Array.isArray(permissions)) {
        Sentry.addBreadcrumb({
          category: Sentry.BreadcrumbCategories.Auth,
          level: 'error',
          message: 'No permissions returned from Auth0',
        });

        throw new Error('No permissions returned from Auth0');
      }

      Sentry.addBreadcrumb({
        category: Sentry.BreadcrumbCategories.Auth,
        level: 'info',
        data: permissions,
      });

      return permissions;
    } catch (e) {
      Sentry.addBreadcrumb({
        category: Sentry.BreadcrumbCategories.Auth,
        level: 'error',
        message: `Error getting permissions: ${e}`,
      });

      Sentry.captureException(e);

      // Return an empty array if we can't get the permissions, just so
      // we don't break everything else.
      return [];
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const getAllowedScopes = useCallback(async () => {
    try {
      if (!isAuthenticated) {
        return [];
      }

      const credentials = await getCredentials();

      if (!credentials || !credentials.scope) {
        Sentry.addBreadcrumb({
          category: Sentry.BreadcrumbCategories.Auth,
          level: 'error',
          message: 'No scopes returned from Auth0',
        });

        throw new Error('No scopes returned from Auth0');
      }

      return credentials.scope.split(' ');
    } catch (e) {
      Sentry.addBreadcrumb({
        category: Sentry.BreadcrumbCategories.Auth,
        level: 'error',
        message: `Error getting user scopes: ${e}`,
      });

      Sentry.captureException(e);

      return [];
    }
  }, [isAuthenticated, getCredentials]);

  const clearSession = useCallback(
    (
      /**
       * By default on web, we redirect to the `logout` route within `web-app`,
       * this is the default behavior of the previous auth0 implementation.
       *
       * @platform web
       */
      { returnToUrl, ...parameters }: ClearSessionParameters = {
        returnToUrl: undefined,
      },
      _?: ClearSessionOptions,
    ) => {
      try {
        const returnTo = returnToUrl ?? `${window.location.origin}${CONFIG.baseHref}${ROUTES.logOut}`;

        return logout({
          // Previously, we were using this to redirect to the logout page, but this caused
          // odd redirect behaviors that made the user pathing more confusing
          logoutParams: {
            returnTo,
            federated: parameters?.federated ?? false,
            ...parameters,
          },
        });
      } catch (e) {
        Sentry.addBreadcrumb({
          category: Sentry.BreadcrumbCategories.Auth,
          level: 'error',
          message: `Error logging out: ${e}`,
        });

        Sentry.captureException(e);
      }
    },
    [logout],
  );

  const refreshUser = useCallback(async () => {
    console.warn(
      'Refresh user is not implemented on web, please refer to the `auth0-react` package for best practices on how to refresh a user.',
    );
    return Promise.resolve();
    // Keeping the below as that is how we got refreshing a user to work on web
    // try {
    //   const token = await getAccessTokenSilently({
    //     cacheMode: 'off', // When we refresh the user, we don't want to use cached tokens
    //   });

    // This works because all it does is call the `refresh-token` endpoint on Auth0,
    // on web this will return the correct user and data. But it does not update the
    // user within the Auth0 React Context.
    //   const refreshedUser = await _NativeAuth0.auth.userInfo({
    //     token,
    //   });

    //   return convertUser(refreshedUser);
    // } catch (e) {
    //   // Sentry.addBreadcrumb({
    //   //   category: Sentry.BreadcrumbCategories.Auth,
    //   //   level: 'error',
    //   //   message: `Error refreshing user: ${e}`,
    //   // });

    //   Sentry.captureException(e);
    // }
  }, []);

  return useMemo(
    () =>
      ({
        // State
        error,
        user: convertUser((user ?? {}) as User), // Temporary type cast until `react-native-auth0` is cross-platform
        isLoading,
        isAuthenticated,
        hasValidCredentials,

        // Actions
        /**
         * This is a general mutation to add `scope` and `audience`
         * to the authorize call by default.
         * @default `scope`: CONFIG.auth0.scope
         * @default `audience`: CONFIG.auth0.audience
         */
        authorize,

        /**
         * Clears the current session via `logout` on web.
         */
        clearSession,

        /**
         * Gets the current user's permissions from the
         * access token.
         */
        getPermissions,

        /**
         * Gets the current user's allowed scopes from the
         * access token.
         */
        getAllowedScopes,

        /**
         * Alias for native `getCredentials` method. Returns everything
         * except the `refreshToken` on web.
         */
        getCredentials,

        /**
         * Resets the user's id token by re-requesting a new one with
         * getIdTokenClaims. For web, this just simply grabs the id token.
         */
        refreshUser,

        // These are stubs to keep the API consistent across platforms,
        // any of these calls should not be used on either platform but
        // this is to prevent accidentally causing a strange crash or state on web.
        // Should really just add `debug` logs for these, but that
        // would require us adding a Logger package. Which, not a bad idea?

        /**
         * We don't use Refresh Tokens on web, so this is a no-op.
         */
        refreshCredentials: () => {
          console.log('There are no `refresh-token` calls on web as we do not use refresh tokens. This is a no-op.');
          return Promise.resolve();
        },

        /**
         * Revokes the refresh token used to refresh the user's access token. This
         * is used when a user disables biometric authentication and we don't want
         * to uphold the refresh token anymore.
         */
        revokeRefreshToken: () => {
          console.log(
            'There are no `revoke-refresh-token` calls on web as we do not use refresh tokens. This is a no-op.',
          );
          return Promise.resolve();
        },

        /**
         * Clears the current user credentials, there is no concept of this
         * on the web. On native, this will clear the user's credentials from
         * the device's keychain but not reset their scopes.
         */
        clearCredential: () => {
          console.log(
            'There are no `clearCredential` methods on web, please refer to the `clearSession` method instead.',
          );
          return Promise.resolve();
        },
      }) as const,
    [
      clearSession,
      refreshUser,
      getCredentials,
      getPermissions,
      getAllowedScopes,
      hasValidCredentials,
      isLoading,
      isAuthenticated,
      user,
      error,
      authorize,
    ],
  );
};

export {
  /**
   * @deprecated Use `useArrivedAuth0` instead
   */
  useAuth0,
};
