import {
  Offering,
  OfferingDividendHistory,
  OfferingSharePricesHistory,
  PropertyStatusApprovalStatus,
  PropertyStatusHistory,
  PropertyStatusTitle,
} from '@arrived/models';
import {
  useGetOfferingDividendHistoryQuery,
  useGetOfferingHistoryByPropertyId,
  useGetOfferingSharePricesHistoryQuery,
} from '@arrived/queries';

const orderSameDateEvent = (a: PropertyStatusHistory, b: PropertyStatusHistory) => {
  if (
    (b.statusTitle === PropertyStatusTitle.APPROVED_APPLICATION &&
      a.statusTitle === PropertyStatusTitle.FIRST_LEASE_STARTS) ||
    (b.statusTitle === PropertyStatusTitle.APPROVED_APPLICATION &&
      a.statusTitle === PropertyStatusTitle.LEASE_STARTS) ||
    (b.statusTitle === PropertyStatusTitle.MARKET_PREPARATION &&
      a.statusTitle === PropertyStatusTitle.MARKETED_FOR_RENT) ||
    (b.statusTitle === PropertyStatusTitle.LEASE_ENDS && a.statusTitle === PropertyStatusTitle.MARKETED_FOR_RENT) ||
    (b.statusTitle === PropertyStatusTitle.IPO_DATE && a.statusTitle === PropertyStatusTitle.SHARE_PRICE_UPDATED) ||
    (b.statusTitle === PropertyStatusTitle.SHARE_PRICE_UPDATED &&
      (a.statusTitle === PropertyStatusTitle.DIVIDENDS_PAUSED || a.statusTitle === PropertyStatusTitle.DIVIDENDS_PAID))
  ) {
    return -1;
  } else {
    return 0;
  }
};

const adjustEvents = (events: PropertyStatusHistory[]) => {
  if (!events) return [];

  // Iterate through events and see if there is a month to month lease and adjust intial lease to subtract the M2M rent increase
  const mtmLease = events?.find((event) => event.statusTitle === PropertyStatusTitle.M2M_LEASE_RENEWAL);
  if (mtmLease) {
    const eventsWithSameLease = events?.filter((event) => {
      return (
        event.leaseCid === mtmLease.leaseCid &&
        (event.statusTitle === PropertyStatusTitle.FIRST_LEASE_STARTS ||
          event.statusTitle === PropertyStatusTitle.LEASE_STARTS)
      );
    });

    eventsWithSameLease?.forEach((event) => {
      const foundEvent = events.find((e) => e.cid === event.cid);
      if (foundEvent && event.monthlyRent && event.monthToMonthIncrease) {
        foundEvent.originalMonthlyRent = event?.monthlyRent - event?.monthToMonthIncrease;
      }
    });
  }

  return events;
};

const convertDividendsToEvents = (
  dividends: OfferingDividendHistory[],
  propertyId: number,
): PropertyStatusHistory[] => {
  const events = dividends.map((dividend) => {
    return {
      cid: dividend.cid || dividend.id.toString(),
      active: true,
      statusTitle: dividend.status,
      displayDate: dividend.postedAt,
      createdAt: dividend.postedAt,
      updatedAt: dividend.postedAt,
      dividendYield: dividend.dividendYield,
      offeringCid: dividend.offeringCid || null,
      propertyId: propertyId,
      approvalStatus: PropertyStatusApprovalStatus.APPROVED,
    };
  });
  return events;
};

const convertSharePricesToEvents = (
  sharePrices: OfferingSharePricesHistory[],
  propertyId: number,
  offering: Offering,
): PropertyStatusHistory[] => {
  const filteredEvents = sharePrices.filter((sharePrice) => {
    if (offering.firstSharePriceDate) {
      return new Date(sharePrice.postedAt) >= new Date(offering.firstSharePriceDate);
    }
    return false;
  });

  const events = filteredEvents.map((sharePrice) => {
    return {
      cid: sharePrice.uuid,
      active: true,
      statusTitle: sharePrice.status,
      displayDate: sharePrice.postedAt,
      createdAt: sharePrice.postedAt,
      updatedAt: sharePrice.postedAt,
      sharePrice: sharePrice.sharePrice,
      sharePriceEstimatedMin: sharePrice.sharePriceEstimatedMin || null,
      sharePriceEstimatedMax: sharePrice.sharePriceEstimatedMax || null,
      offeringCid: sharePrice.offeringCid || null,
      propertyId: propertyId,
      approvalStatus: PropertyStatusApprovalStatus.APPROVED,
    };
  });

  return events;
};

export const useGetSortedOfferingHistoryEvents = (
  offering: Offering,
): { sortedEvents: PropertyStatusHistory[][]; isLoading: boolean } => {
  const propertyId = offering?.properties?.[0]?.id;
  const events = useGetOfferingHistoryByPropertyId({ propertyId });
  const dividends = useGetOfferingDividendHistoryQuery(offering?.id);
  const sharePrices = useGetOfferingSharePricesHistoryQuery(offering?.cid);
  const isLoading = events.isLoading || dividends.isLoading || sharePrices.isLoading;

  const dividendsData = dividends?.data || [];
  const sharePricesData = sharePrices?.data || [];

  const dividendEvents = convertDividendsToEvents(dividendsData, propertyId);
  const sharePriceEvents = convertSharePricesToEvents(sharePricesData, propertyId, offering);

  const eventData = events?.data || [];

  // Adjust events for display
  const adjustedEvents = adjustEvents([...eventData, ...dividendEvents, ...sharePriceEvents]);

  // Loop through events and group by year then sort by date
  const groupedEvents = adjustedEvents.reduce((acc: Record<string, PropertyStatusHistory[]>, event) => {
    const year = new Date(event.displayDate).getFullYear().toString();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(event);
    return acc;
  }, {});

  // Sorts events by date in descending order grouped by year
  if (!groupedEvents) return { isLoading: false, sortedEvents: [] };

  const sortedEvents = Object.keys(groupedEvents)
    ?.sort((a, b) => Number(b) - Number(a))
    ?.map((year: string) => {
      return groupedEvents[year]?.sort((a, b) => {
        if (new Date(a.displayDate).getTime() === new Date(b.displayDate).getTime()) {
          return orderSameDateEvent(a, b);
        } else {
          return new Date(b.displayDate).getTime() - new Date(a.displayDate).getTime();
        }
      });
    });

  return {
    sortedEvents,
    isLoading,
  };
};
