import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function ArrowRightInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.825 9.375L10.89 5.442l.884-.884 5 5 .442.442-.442.442-5 5-.884-.884 3.933-3.933H3v-1.25h11.825z"
        fill={color}
      />
    </Svg>
  );
}

export const ArrowRightIcon = memo<IconProps>(withIconTheme(ArrowRightInternal));
