export const Auth0UserPermissions = {
  ALL: 'abacus:*',
  EARLY_ACCESS_TRADE: 'abacus:early_access_trade:write',
  REFERRAL_BETA: 'referrals:beta',
  BROKER_APPROVAL_STATUS_WRITE: 'abacus:trade-broker-approval-status:write',
  BROKER_APPROVAL_INFO_READ: 'abacus:trade-broker-approval-info:read',
  /**
   * @deprecated Use `useRegulationAccessSummaryQuery` instead
   */
  STN_BETA: 'abacus:short-term-note:beta',
  REG_D_EXEMPT_WRITE: 'abacus:account:reg-d-exempt:write',
  PERMISSION_ACCOUNT_BLOCK_WRITE: 'abacus:account:block:write',
  COUNTERPARTY_WRITE: 'cash:counterparty:write',
  COUNTERPARTY_READ: 'cash:counterparty:read',
  OPEN_FOR_SEC_TRADE_WRITE: 'abacus:open_for_sec_trade:write',
} as const;

export type Auth0UserPermissions = (typeof Auth0UserPermissions)[keyof typeof Auth0UserPermissions];
