import { useQuery } from '@tanstack/react-query';

import { getRegDIPOMetadata } from '@arrived/api_v2';
import { AssetType, OfferingCid } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { regDIPOAssetMetadataKey } from './assetMetadata.keys';

/**
 * Returns the asset metadata for a Reg D IPO, note this is not for STN's which are also Reg D but
 * have a separate endpoint specifically for fetching STN metadata.
 */
export function useGetRegDIPOAssetMetadata(
  offeringCid: OfferingCid,
  options?: UseAwaitedQueryOptions<typeof getRegDIPOMetadata>,
) {
  return useQuery({
    queryKey: regDIPOAssetMetadataKey(offeringCid, AssetType.STR),
    queryFn: () => getRegDIPOMetadata(offeringCid),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
