import { ReactElement, ReactNode, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import _isEmpty from 'lodash/isEmpty';

import { InvestmentProductType, Offering, SecurityType } from '@arrived/models';

import { getInvestmentProductType } from '../utils/getInvestmentProductType';

import { ROUTES } from './Routes';

export const StandardDisclaimerVersion = {
  ALL_ISSUERS: 'ALL_ISSUERS',
  FUND: 'FUND',
  INDIVIDUAL_ISSUER: 'INDIVIDUAL_ISSUER',
  REG_D_ISSUER: 'REG_D_ISSUER',
} as const;

export type StandardDisclaimerVersion = (typeof StandardDisclaimerVersion)[keyof typeof StandardDisclaimerVersion];

export function getStandardDisclaimerProps(offering?: Offering) {
  let issuerName = '';
  let version;

  if (offering?.securityType === SecurityType.REG_D) {
    version = StandardDisclaimerVersion.REG_D_ISSUER;
  } else if (
    offering &&
    getInvestmentProductType({
      offeringType: offering.type,
      assetType: offering.assetType,
    }) === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND
  ) {
    issuerName = offering?.issuer.name;
    version = StandardDisclaimerVersion.FUND;
  } else if (offering?.issuer.name) {
    issuerName = offering?.issuer.name;
    version = StandardDisclaimerVersion.INDIVIDUAL_ISSUER;
  } else {
    version = StandardDisclaimerVersion.ALL_ISSUERS;
  }

  return { issuerName, version };
}

export interface StandardDisclaimerContentProps<T extends StandardDisclaimerVersion> {
  LinkElement: SharedLinkElement;
  lineBreakElement: ReactElement;
  version: T;
  issuerName: T extends 'INDIVIDUAL_ISSUER' | 'FUND' ? string : never;
}

export type SharedLinkElement = (props: { href: string; text: ReactNode }) => ReactElement;

export const StandardDisclaimerContent = <T extends StandardDisclaimerVersion>({
  issuerName,
  LinkElement,
  lineBreakElement,
  version,
}: StandardDisclaimerContentProps<T>) => {
  const values = useMemo(
    () => ({
      br: lineBreakElement,
      LLCName: issuerName,
      arrivedHomepageLink: <LinkElement href="https://arrived.com/" text="arrived.com" />,
      FINRALink: <LinkElement href="https://www.finra.org/" text="FINRA" />,
      SIPCLink: <LinkElement href="https://www.sipc.org/" text="SIPC" />,
      dalmoreBrokerCheckLink: (
        <LinkElement
          href="https://brokercheck.finra.org/"
          text={<FormattedMessage id="standard-disclaimer-dalmore-brokercheck-link-text" />}
        />
      ),
      dalmoreFormCRSLink: (
        <LinkElement
          href="https://app.box.com/s/hlyq8kust4t786703q6ox7ehh5b6nvou"
          text={<FormattedMessage id="standard-disclaimer-dalmore-form-crs-link-text" />}
        />
      ),
      circularsPage: (parts: ReactNode) => (
        <LinkElement href={`${ROUTES.static.base}${ROUTES.public.offeringCircular}`} text={parts} />
      ),
      communicationsDisclaimerLink: (
        <LinkElement
          href={`${ROUTES.static.base}${ROUTES.communicationsDisclaimer}`}
          text={<FormattedMessage id="standard-disclaimer-communications-disclaimer-link-text" />}
        />
      ),
    }),
    [LinkElement, issuerName, lineBreakElement],
  );

  switch (version) {
    case StandardDisclaimerVersion.ALL_ISSUERS:
      return <FormattedMessage id="disclaimer.all-issuers" values={values} />;
    case StandardDisclaimerVersion.FUND:
      return <FormattedMessage id="disclaimer.fund-issuer" values={values} />;
    case StandardDisclaimerVersion.INDIVIDUAL_ISSUER:
      return <FormattedMessage id="disclaimer.individual-issuer" values={values} />;
    case StandardDisclaimerVersion.REG_D_ISSUER:
      return <FormattedMessage id="disclaimer.reg-d" />;
  }
};
