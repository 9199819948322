import { ComponentProps } from 'react';

import { SvgIconComponent } from '@mui/icons-material/index.d';
import { useTheme } from '@mui/material';

interface GradientIconProps {
  colorOne?: string;
  colorTwo?: string;
  Icon: SvgIconComponent;
}

export const GradientIcon = ({
  colorOne,
  colorTwo,
  Icon,
  ...iconProps
}: GradientIconProps & ComponentProps<SvgIconComponent>) => {
  const { palette } = useTheme();
  const stopColorOne = colorOne ?? palette.secondary.main;
  const stopColorTwo = colorTwo ?? palette.primary.main;

  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
          <stop offset={0} stopColor={stopColorOne} />
          <stop offset={1} stopColor={stopColorTwo} />
        </linearGradient>
      </svg>
      <Icon sx={{ fill: 'url(#linearColors)' }} {...iconProps} />
    </>
  );
};
