import { Tokenizer } from '../../../utils/tokenizer';
import { RootTextProps } from '../RootText';

type TitleDisplayVariantsBase = 'xlarge' | 'large' | 'medium' | 'small';
type TitleHeadingVariantsBase = 'xlarge' | 'large' | 'large.alt' | 'medium' | 'medium.alt' | 'small' | 'small.alt';
type TitleDisplayVariants = Tokenizer<'title.display', TitleDisplayVariantsBase>;
type TitleHeadingVariants = Tokenizer<'title.heading', TitleHeadingVariantsBase>;

export type TitleVariants = TitleDisplayVariants | TitleHeadingVariants;

export const TITLE_VARIANTS: Record<TitleVariants, RootTextProps> = {
  'title.display.xlarge': {
    fontSize: '$80',
    lineHeight: '$80',
    fontWeight: '$4',
  },
  'title.display.large': {
    fontSize: '$72',
    lineHeight: '$72',
    fontWeight: '$4',
  },
  'title.display.medium': {
    fontSize: '$56',
    lineHeight: '$56',
    fontWeight: '$4',
  },
  'title.display.small': {
    fontSize: '$40',
    lineHeight: '$48',
    fontWeight: '$4',
  },
  'title.heading.xlarge': {
    fontSize: '$32',
    lineHeight: '$36',
    fontWeight: '$4',
  },
  'title.heading.large': {
    fontSize: '$24',
    lineHeight: '$32',
    fontWeight: '$4',
  },
  'title.heading.large.alt': {
    fontSize: '$24',
    lineHeight: '$32',
    fontWeight: '$0',
  },
  'title.heading.medium': {
    fontSize: '$20',
    lineHeight: '$24',
    fontWeight: '$4',
  },
  'title.heading.medium.alt': {
    fontSize: '$20',
    lineHeight: '$24',
    fontWeight: '$1',
  },
  'title.heading.small': {
    fontSize: '$18',
    lineHeight: '$20',
    fontWeight: '$4',
  },
  'title.heading.small.alt': {
    fontSize: '$18',
    lineHeight: '$20',
    fontWeight: '$0',
  },
};
