import { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';

import { stub } from '@arrived/common';

export interface IntercomDisplayContextType {
  /**
   * This is an "app-wide" launcher hide. That is to say that regardless of the status of the "hidden on page" state,
   * the launcher will be hidden if this is set to true.
   */
  hideLauncherInApp: (hide: boolean) => void;
  /**
   * This is a page specific launcher hide meaning that even if the launcher isn't hidden for the application, you can
   * hide the launcher on a specific page using this function. This should only be used via the
   * `useHideIntercomLauncher` hook which un-hides the launcher when the component/page invoking the hook unmounts.
   */
  hideLauncherOnPage: (hide: boolean) => void;
}

/**
 * Context that can be used to hide the Intercom launcher at the page or application level.
 */
export const IntercomDisplayContext = createContext<IntercomDisplayContextType>({
  hideLauncherInApp: stub,
  hideLauncherOnPage: stub,
});

export interface IntercomDisplayContextProviderProps {
  setIsIntercomLauncherHidden: (isHidden: boolean) => void;
}

/**
 * This is the context which we should be using to hide/show the Intercom launcher so that there's a single controlled
 * instance in which we're updating `hideDefaultLauncher` since we can't check the current state of the launcher we can
 * maintain our own state here and update appropriately as it changes.
 */
export const IntercomDisplayContextProvider = ({
  children,
  setIsIntercomLauncherHidden,
}: PropsWithChildren<IntercomDisplayContextProviderProps>) => {
  // State indicating if the launcher should be hidden by default at the application level.
  const [isApplicationLauncherHidden, setIsApplicationLauncherHidden] = useState(false);
  // state indicating if the launcher should be hidden at the page level.
  const [isPageLauncherHidden, setIsPageLauncherHidden] = useState(false);

  const value = useMemo(
    () => ({ hideLauncherInApp: setIsApplicationLauncherHidden, hideLauncherOnPage: setIsPageLauncherHidden }),
    [],
  );

  // This is the ONLY place in the application in which we should be setting hideDefaultLauncher
  useEffect(() => {
    setIsIntercomLauncherHidden(isPageLauncherHidden || isApplicationLauncherHidden);
  }, [isApplicationLauncherHidden, isPageLauncherHidden, setIsIntercomLauncherHidden]);

  return <IntercomDisplayContext.Provider value={value}>{children}</IntercomDisplayContext.Provider>;
};
