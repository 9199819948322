import { useContext } from 'react';

import {
  BodyText,
  LinearGradient,
  Stack,
  TitleText,
  createStyledContext,
  gradients,
  styled,
  withStaticProperties,
} from '@arrived/bricks';

import { Blocks, BuilderBlock, BuilderDataProps, GenericBuilderIcons, RegisteredComponent } from '../../sdk';
import { ButtonBlockElement } from '../molecules';

const CallToActionStyledContext = createStyledContext({
  variant: 'emphasized',
  layout: 'inline',
});

export const useCallToActionStyledContext = () => useContext(CallToActionStyledContext);

const DetailsFrame = styled(Stack, {
  name: 'CallToActionDetails',
  context: CallToActionStyledContext,

  width: '100%',
  gap: '$4',
  p: '$4',
  flex: 1,

  $gtSm: {
    gap: '$6',
    p: '$12',
  },

  variants: {
    layout: {
      inline: {
        row: false,

        $gtSm: {
          row: true,
          alignItems: 'center',
        },
      },
      center: {
        centered: true,
      },
    },
  },
});

const CallToActionDetails = DetailsFrame.styleable((props) => {
  const { children, ...rest } = props;
  const { variant } = useCallToActionStyledContext();

  return (
    <Stack overflow="hidden" flex={1} w="100%">
      {variant === 'emphasized' && (
        <LinearGradient fullscreen w="100%" h="100%" colors={gradients['gradient.primary.default']} />
      )}
      {variant === 'yin' && <LinearGradient fullscreen w="100%" h="100%" colors={gradients['gradient.neutral.yin']} />}
      {variant === 'light' && (
        <LinearGradient fullscreen w="100%" h="100%" colors={gradients['gradient.neutral.light']} />
      )}
      <DetailsFrame {...rest}>{children}</DetailsFrame>
    </Stack>
  );
});

const CallToActionDetailsFrame = styled(Stack, {
  name: 'CallToActionDetailsFrame',
  context: CallToActionStyledContext,

  gap: '$4',
  flex: 1,

  variants: {
    layout: {
      inline: {
        row: false,
        alignItems: 'flex-start',
      },
      center: {
        centered: true,
      },
    },
  },
});

const CallToActionDetailsAction = styled(Stack, {
  name: 'CallToActionDetailsAction',
  context: CallToActionStyledContext,

  gap: '$4',
  flex: 1,
  w: '100%',

  variants: {
    layout: {
      inline: {
        row: false,
        alignItems: 'center',

        $gtSm: {
          alignItems: 'flex-end',
        },
      },
      center: {
        centered: true,
      },
    },
  },
});

const CallToActionHeading = styled(TitleText, {
  name: 'CallToActionHeading',
  context: CallToActionStyledContext,

  token: 'title.heading.xlarge',

  $gtSm: {
    token: 'title.display.small',
  },

  variants: {
    variant: {
      emphasized: {
        color: '$onSurface.neutral.defaultInverted',
      },
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
  },
});

const CallToActionContent = styled(BodyText, {
  name: 'CallToActionContent',
  context: CallToActionStyledContext,

  token: 'body.default.large',

  variants: {
    variant: {
      emphasized: {
        color: '$onSurface.neutral.defaultInverted',
      },
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
  },
});

const CallToActionFrame = styled(Stack, {
  name: 'CallToActionFrame',
  context: CallToActionStyledContext,

  flex: 1,

  row: false,

  $gtSm: {
    row: true,
  },
});

const CallToActionComponent = withStaticProperties(CallToActionFrame, {
  Details: withStaticProperties(CallToActionDetails, {
    Frame: CallToActionDetailsFrame,
    Heading: CallToActionHeading,
    Content: CallToActionContent,
    Action: CallToActionDetailsAction,
  }),
});

type CallToActionProps = {
  variants: (typeof CallToActionStyledContext)['context'];
  content: {
    heading?: string;
    details?: string;
    hideButton?: boolean;
    button?: BuilderBlock[];
  };
} & BuilderDataProps;

export const CallToAction = ({ variants, content, builderContext }: CallToActionProps) => {
  return (
    <CallToActionStyledContext.Provider {...variants}>
      <CallToActionComponent>
        <CallToActionComponent.Details>
          <CallToActionComponent.Details.Frame>
            {Boolean(content.heading) && (
              <CallToActionComponent.Details.Heading>{content.heading}</CallToActionComponent.Details.Heading>
            )}

            {Boolean(content.details) && (
              <CallToActionComponent.Details.Content>{content.details}</CallToActionComponent.Details.Content>
            )}
          </CallToActionComponent.Details.Frame>

          <CallToActionComponent.Details.Action centered>
            {!content.hideButton && (
              <Stack $gtSm={{ alignSelf: 'center' }} alignSelf="stretch">
                <Blocks path="component.options.content.button" blocks={content.button} context={builderContext} />
              </Stack>
            )}
          </CallToActionComponent.Details.Action>
        </CallToActionComponent.Details>
      </CallToActionComponent>
    </CallToActionStyledContext.Provider>
  );
};

CallToAction.registerComponent = {
  component: CallToAction,
  name: 'Call to Action',
  description: 'A call to action block with a heading, details, and a button',
  image: GenericBuilderIcons.CallToAction,
  canHaveChildren: false,
  shouldReceiveBuilderProps: {
    builderContext: true,
    builderBlock: true,
  },
  docsLink: 'https://www.figma.com/design/pOLoKPNXzfRiDTqAeP9YBr/%F0%9F%A7%B1-Builder.io-Blocks?node-id=6205-322304',
  inputs: [
    {
      name: 'variants',
      friendlyName: 'Variants',
      type: 'object',
      defaultValue: {
        variant: 'emphasized',
        layout: 'center',
      },
      subFields: [
        {
          name: 'variant',
          type: 'enum',
          enum: [
            {
              value: 'emphasized',
              label: 'Emphasized',
            },
            {
              value: 'light',
              label: 'Light',
            },
            {
              value: 'yin',
              label: 'Yin',
            },
          ],
        },
        {
          name: 'layout',
          type: 'enum',
          enum: [
            {
              value: 'center',
              label: 'Center',
            },
            {
              value: 'inline',
              label: 'Inline',
            },
          ],
        },
      ],
    },
    {
      name: 'content',
      friendlyName: 'Content',
      type: 'object',
      defaultValue: {
        heading: 'Heading',
        details: 'Details',
        button: [ButtonBlockElement()],
        hideButton: false,
      },
      subFields: [
        {
          name: 'heading',
          type: 'string',
        },
        {
          name: 'details',
          type: 'string',
        },
        {
          name: 'hideButton',
          type: 'boolean',
          friendlyName: 'Hide Button',
          helperText: 'Hide the Button',
        },
        {
          name: 'button',
          type: 'uiBlocks',
          hideFromUI: true,
        },
      ],
    },
  ],
} satisfies RegisteredComponent;
