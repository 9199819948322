import { useCallback, useEffect, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { subDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';

import { GetSalesQueryParams, getAccountTrades, getSales } from '@arrived/api_v2';
import { getTruncatedDateFromString } from '@arrived/common';
import { SaleType, TradeFlow, TradesQueryFilters } from '@arrived/models';
import {
  MAX_STALE_TIME,
  accountTradesQueryKeyFn,
  salesQueryKeyFn,
  useGetAccountSalesQuery,
  useGetPrimaryAccountQuery,
  useTradesQuery,
} from '@arrived/queries';

import { getTransactions } from '../api';
import { transactionsKeyFn, useTransactions } from '../queries/transactions';
import { CashAccountTransactionsQueryFilters } from '../types';
import { TransferCategory } from '../types/cash-accounts';
import { CashAccountTransaction } from '../types/CashAccountTransaction';
import { getTransactionAmountWithSign } from '../utils';
import {
  getDisplayStatusForRedemption,
  getDisplayStatusForTrade,
  getDisplayStatusForTransaction,
} from '../utils/getDisplayStatusForCashAccountTransaction';

/**
 * CAS defaults to a 60d cutoff; we want to scan a little farther back in time by default, but
 * still allow a passed param to override.
 */
const getNextTransactionStartTimestamp = (startTimestamp?: string, endTimestamp?: string) => {
  if (startTimestamp) {
    return startTimestamp;
  }

  if (endTimestamp) {
    const endDateMinus180Days = subDays(new Date(endTimestamp).setHours(0, 0, 0, 0), 180);
    return zonedTimeToUtc(endDateMinus180Days, 'UTC').toISOString();
  }

  return undefined;
};

/**
 * Data layer for Wallet activity lists; merges CA transactions and abacus trades.
 *
 * Returns lists grouped by pending and posted activity, with posted trades being optionally bound
 * by a provided date range. Date strings should be in ISO+UTC format
 */
export const useCashAccountTransactionActivity = ({
  createdAfterISOTimestamp,
  createdBeforeISOTimestamp,
  pageSize = 10,
  prefetchAtNextCursor = false,
  waitForTimestampCursor = false,
}: {
  /**
   * Used for time-bound queries; e.g., only scanning up to the last 30d worth of data.
   */
  createdAfterISOTimestamp?: string;
  /**
   * Primary cursor used for pagination; use this & pageSize to scan back in time.
   */
  createdBeforeISOTimestamp?: string;
  /**
   * Max size of the returned data set.
   *
   * Default is the joint list of up to 1000 trades and 100 cash account transactions.
   */
  pageSize?: number;
  /**
   * Whether the hook should try to prefetch the next page's data in the background.
   */
  prefetchAtNextCursor?: boolean;
  /**
   * Prevents duplicate fetches on the initial load before the first cursor value resolves.
   */
  waitForTimestampCursor?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { data: primaryAccount, isPending: isPrimaryAccountQueryLoading } = useGetPrimaryAccountQuery({
    staleTime: MAX_STALE_TIME,
  });
  const currentTimestampInMs = useMemo(() => new Date().getTime(), []);

  /**
   * Query for n+1 items, even though we'll end up discarding at least the last item in one of the
   * lists, to know if there is more data to query.
   *
   * Modern Treasury has a 100 item cap, but they do return 101 items to address the same issue.
   */
  const pageSizeWithOverscan = useMemo(() => pageSize + 1, [pageSize]);

  const transactionsQueryFilters = useMemo<CashAccountTransactionsQueryFilters>(() => {
    return {
      cashAccountCid: primaryAccount?.cashAccountCid ?? null,
      end: createdBeforeISOTimestamp,
      start: getNextTransactionStartTimestamp(createdAfterISOTimestamp, createdBeforeISOTimestamp),
      // TODO: chat w/BE about adding this pagesize option to CAS here
      // size: pageSizeWithOverscan,
    };
  }, [primaryAccount, createdBeforeISOTimestamp, createdAfterISOTimestamp]);

  const tradesQueryFilters = useMemo<TradesQueryFilters>(
    () => ({
      size: pageSizeWithOverscan, // needs to be the first filter in the obj for prefetching to work
      flows: [TradeFlow.CASH_ACCOUNT, TradeFlow.NORTH_CAPITAL],
      createdAfter: createdAfterISOTimestamp,
      createdBefore: createdBeforeISOTimestamp,
    }),
    [createdBeforeISOTimestamp, createdAfterISOTimestamp, pageSizeWithOverscan],
  );

  const redemptionsQueryFilters = useMemo<GetSalesQueryParams>(
    () => ({
      size: pageSizeWithOverscan, // needs to be the first filter in the obj for prefetching to work
      createdAfter: createdAfterISOTimestamp,
      createdBefore: createdBeforeISOTimestamp,
      types: [SaleType.REDEMPTION],
      includeOffering: true,
      includeRedemptionPeriod: true,
    }),
    [createdBeforeISOTimestamp, createdAfterISOTimestamp, pageSizeWithOverscan],
  );

  // Fetch the max of 100 transactions for this cash account, starting at the given timestamp.
  const { data: cashAccountTransactions = [], isPending: isCashAccountTransactionsQueryLoading } = useTransactions(
    transactionsQueryFilters,
    { enabled: waitForTimestampCursor && !!transactionsQueryFilters.end },
  );

  // Fetch the specified number of trades matching the current filters, starting at the given timestamp.
  const { data: trades = [], isPending: isTradesQueryLoading } = useTradesQuery(tradesQueryFilters, {
    enabled: waitForTimestampCursor && !!tradesQueryFilters.createdBefore,
  });

  // Fetch the specified number of redemptions matching the current filters, starting at the given timestamp.
  const { data: redemptions = [], isPending: isRedemptionsQueryLoading } = useGetAccountSalesQuery(
    redemptionsQueryFilters,
    {
      enabled: waitForTimestampCursor && !!redemptionsQueryFilters.createdBefore,
    },
  );

  /**
   * Joint list of trades and transactions (limited to the provided page size), and a boolean value
   * for whether data was 'discarded' beyond the returned array. Useful for pagination scanning.
   */
  const [activityList, hasMoreData]: [CashAccountTransaction[], boolean] = useMemo(() => {
    // List of cash account transactions/transfers, excluding share purchases & Sale Redemptions
    const availableTransactions = cashAccountTransactions
      .filter((transaction) => {
        const isBuySharesTransfer = transaction.transferCategory === 'BUY_SHARES';
        const isUnspecifiedSaleTransfer =
          transaction.transferCategory === 'SELL_SHARES' && !transaction.transferMetadata?.saleType;
        const isRedemptionTransfer =
          transaction.transferCategory === 'SELL_SHARES' &&
          transaction.transferMetadata?.saleType === SaleType.REDEMPTION;

        return !(isBuySharesTransfer || isUnspecifiedSaleTransfer || isRedemptionTransfer);
      })
      .map((transaction) => {
        // Override category for Sale payments
        const displayCategoryForTransaction =
          transaction.transferCategory === 'SELL_SHARES' && transaction.transferMetadata.saleType === SaleType.MATURITY
            ? 'SALE_PAYMENT'
            : transaction.transferCategory;

        return {
          uniqueId: transaction.cid,
          type: 'CASH_ACCOUNT',
          timestamp: transaction.createdAt,
          category: displayCategoryForTransaction,
          status: getDisplayStatusForTransaction(transaction, currentTimestampInMs),
          totalAmount: getTransactionAmountWithSign(transaction, primaryAccount?.cashAccountCid),
          extraData: transaction,
        } as CashAccountTransaction;
      });

    // Lists of trades
    const availableTrades = trades.map((trade) => {
      return {
        uniqueId: `${trade.cid}`,
        type: 'TRADE',
        timestamp: trade.createdAt,
        category: 'BUY_SHARES' as TransferCategory,
        status: getDisplayStatusForTrade(trade),
        totalAmount: trade.totalAmount,
        extraData: trade,
      } as CashAccountTransaction;
    });

    // Lists of redemptions
    const availableRedemptions = redemptions.map((redemption) => {
      return {
        uniqueId: `${redemption.cid}`,
        type: 'SALE',
        timestamp: redemption.createdAt,
        category: 'SALE_REDEMPTION' as TransferCategory,
        status: getDisplayStatusForRedemption(redemption),
        totalAmount: redemption.paymentAmount,
        extraData: redemption,
      } as CashAccountTransaction;
    });

    const orderedActivity = _orderBy(
      [...availableTransactions, ...availableTrades, ...availableRedemptions],
      ['timestamp', 'category'],
      ['desc', 'asc'],
    );

    return [orderedActivity.slice(0, pageSize), orderedActivity.length > pageSize];
  }, [cashAccountTransactions, trades, redemptions, primaryAccount?.cashAccountCid]);

  const createFirstDayToTransactionMap = useCallback((activityList: CashAccountTransaction[]) => {
    const truncatedDateToFirstTransactionMap: Record<string, string> = {};

    activityList.forEach((transaction) => {
      const truncatedTransactionDate = getTruncatedDateFromString(transaction.timestamp);

      // If the map doesn't have that truncated day yet, or it does but our timestamp is lower, update the entry
      if (!truncatedDateToFirstTransactionMap[truncatedTransactionDate]) {
        truncatedDateToFirstTransactionMap[truncatedTransactionDate] = transaction.uniqueId;
      }
    });

    return truncatedDateToFirstTransactionMap;
  }, []);

  const firstActivityByDay = createFirstDayToTransactionMap(activityList);

  /**
   * If
   * - we should be prefetching,
   * - all existing queries have finished loading,
   * - there's more data to paginate to, and
   * - we have existing data to refer to,
   *
   * prefetch the queries in the background with the next cursor so we can cut down on visible load times.
   */
  useEffect(() => {
    if (
      prefetchAtNextCursor &&
      primaryAccount &&
      !isTradesQueryLoading &&
      !isCashAccountTransactionsQueryLoading &&
      !isRedemptionsQueryLoading &&
      hasMoreData &&
      activityList.length
    ) {
      const nextEndCursorTimestamp = activityList[activityList.length - 1].timestamp;

      const prefetchTransactionsFilters: CashAccountTransactionsQueryFilters = {
        ...transactionsQueryFilters,
        end: nextEndCursorTimestamp,
        start: getNextTransactionStartTimestamp(createdAfterISOTimestamp, nextEndCursorTimestamp),
      };
      const prefetchTradesFilters: TradesQueryFilters = {
        ...tradesQueryFilters,
        createdBefore: nextEndCursorTimestamp,
      };
      const prefetchRedemptionsFilters: GetSalesQueryParams = {
        ...redemptionsQueryFilters,
        createdBefore: nextEndCursorTimestamp,
      };

      queryClient.prefetchQuery({
        queryKey: transactionsKeyFn(primaryAccount?.cashAccountCid!, prefetchTransactionsFilters),
        queryFn: () => getTransactions(prefetchTransactionsFilters),
        staleTime: 60000,
      });
      queryClient.prefetchQuery({
        queryKey: accountTradesQueryKeyFn(primaryAccount?.id!, prefetchTradesFilters),
        queryFn: () => getAccountTrades(primaryAccount?.id!, prefetchTradesFilters),
        staleTime: 60000,
      });
      queryClient.prefetchQuery({
        queryKey: salesQueryKeyFn(primaryAccount?.cid!, prefetchRedemptionsFilters),
        queryFn: () => getSales({ accountCid: primaryAccount?.cid!, ...prefetchRedemptionsFilters }),
        staleTime: 60000,
      });
    }
  }, [
    primaryAccount,
    isTradesQueryLoading,
    isCashAccountTransactionsQueryLoading,
    isRedemptionsQueryLoading,
    activityList[activityList.length - 1]?.timestamp,
    hasMoreData,
    createdAfterISOTimestamp,
  ]);

  return {
    isActivityListLoading:
      isPrimaryAccountQueryLoading ||
      isRedemptionsQueryLoading ||
      (isCashAccountTransactionsQueryLoading && !!primaryAccount?.cashAccountCid) ||
      (isTradesQueryLoading && !!primaryAccount?.id),
    activityList,
    firstActivityByDay,
    hasMoreData,
  };
};
