import { useMemo } from 'react';

import { OfferingTransactionId, SecurityType } from '@arrived/models';
import { MAX_STALE_TIME, useOfferingQuery, useTradeQuery } from '@arrived/queries';

/**
 * Confirming that the investor has access to this offering before enabling them to navigate to it.
 *
 * Primarily used for Reg D offerings if the investor becomes un-accredited post-purchase.
 *
 * Temporarily accessing the Offering's security type directly until the BE is able to send back
 * better error messages for accreditation issues and/or return a limited set of info back.
 */
export const useIsUnauthorizedRegDTrade = (tradeId: OfferingTransactionId | string | undefined, enabled?: boolean) => {
  const tradeState = useTradeQuery(tradeId, { includePayments: true }, { staleTime: MAX_STALE_TIME, enabled });
  const offeringState = useOfferingQuery(tradeState.data?.offeringId, { staleTime: MAX_STALE_TIME, enabled });

  const isUnauthedOffering = useMemo(
    () =>
      tradeId &&
      tradeState.data?.offeringId &&
      offeringState.isError &&
      tradeState.data?.offering.securityType === SecurityType.REG_D,
    [tradeId, tradeState, offeringState],
  );

  return isUnauthedOffering;
};
