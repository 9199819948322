import {
  Account,
  AccountCid,
  AccountId,
  ExternalAccount,
  FullExternalAccountRequest,
  PlaidCreateTokenLinkRequest,
  PlaidCreateTokenLinkResponse,
  PlaidLinkExternalAccountRequest,
  PlaidPublicTokenExchangeRequest,
  PlaidPublicTokenExchangeResponse,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getUserExternalAccount = (accountId: AccountId) =>
  createQuery<ExternalAccount>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/external/bank`,
  });

export const postUserExternalAccount = (request: FullExternalAccountRequest) =>
  createQuery<ExternalAccount>({
    apiInstance: api,
    method: 'post',
    url: `/users/accounts/${request.accountId}/external/bank`,
    requestData: request,
  });

export const deleteUserExternalAccount = (accountId: AccountId) =>
  createQuery<void>({
    apiInstance: api,
    method: 'delete',
    url: `/users/accounts/${accountId}/external/bank`,
  });

export const postLinkPlaidExternalAccount = (request: PlaidLinkExternalAccountRequest) =>
  createQuery<ExternalAccount>({
    apiInstance: api,
    method: 'post',
    url: `/users/accounts/${request.accountId}/external/bank/link/plaid`,
    requestData: request,
  });

export const postPlaidCreateTokenLink = (request: PlaidCreateTokenLinkRequest) =>
  createQuery<PlaidCreateTokenLinkResponse>({
    apiInstance: api,
    method: 'post',
    url: '/plaid/link/token/create',
    requestData: request,
  });

export const getPlaidVerificationTokenLink = (accountId: AccountId) =>
  createQuery<PlaidCreateTokenLinkResponse>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/external/bank/link/plaid/verify`,
  });

export const postPlaidVerifyExternalAccount = (accountId: AccountId) =>
  createQuery<ExternalAccount>({
    apiInstance: api,
    method: 'post',
    url: `/users/accounts/${accountId}/external/bank/verify`,
  });

export const getPlaidVerificationStatus = (accountId: AccountId) =>
  createQuery<string>({
    apiInstance: api,
    method: 'get',
    url: `/users/accounts/${accountId}/external/bank/link/plaid/verification`,
  });

export const postPlaidExchangePublicToken = (request: PlaidPublicTokenExchangeRequest) =>
  createQuery<PlaidPublicTokenExchangeResponse>({
    apiInstance: api,
    method: 'post',
    url: '/plaid/item/public_token/exchange',
    requestData: request,
  });

export const postSyncAccountToCashAccountService = (accountCid: AccountCid) =>
  createQuery<Account>({
    apiInstance: api,
    method: 'post',
    url: `/accounts/${accountCid}/sync-account-to-cash-account-service`,
  });
