import { forwardRef } from 'react';

import { FundsAltIcon, Pin, PinProps, SingleFamilyAltIcon, TamaguiElement } from '@arrived/bricks';

export type MarkerPinProps = Omit<PinProps, 'children' | 'Icon'> & { icon: 'home' | 'fund' };

/**
 * This exists so we can use this cross platform within `Marker.tsx` and `Marker.native.tsx`
 */
export const MarkerPin = forwardRef<TamaguiElement, MarkerPinProps>(({ icon, ...rest }, ref) => (
  <Pin variant="alt" ref={ref} pointerEvents="auto" zIndex="$banner" {...rest}>
    <Pin.Icon Icon={icon === 'home' ? SingleFamilyAltIcon : FundsAltIcon} />
  </Pin>
));
