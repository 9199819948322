import { useQuery } from '@tanstack/react-query';

import { getUserDocuments } from '@arrived/api_v2';
import { UserId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { userDocumentsKeyFn } from './userDocuments.keys';

export function useUserDocumentsQuery(userId?: UserId, options?: UseAwaitedQueryOptions<typeof getUserDocuments>) {
  return useQuery({
    queryKey: userDocumentsKeyFn(userId!),
    queryFn: () => getUserDocuments(userId!),
    ...options,
    enabled: (options?.enabled ?? true) && userId != null,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}
