import { Theme } from '@mui/material';

export const DefaultButtonProps = () => {
  return {
    defaultProps: {
      // TODO: The default button should not be 'secondary'. It should be 'primary'
      variant: 'contained',
      disableElevation: true,
    },
  };
};

export const BaseButtonStyles = (base: Theme) => {
  return {
    root: {
      color: base.palette.text.primary,
      borderRadius: base.spacing(0.5),
      fontWeight: base.fontWeights.medium,
      maxWidth: '300px',
      maxHeight: '50px',
      padding: base.spacing(2, 4.5),
      lineHeight: 'normal',
      textAlign: 'center',
      textTransform: 'none',
    },
    fullWidth: {
      maxWidth: 'unset',
    },
    sizeLarge: {
      fontSize: base.fontSizes['2xl'],
      padding: `${base.spacing(1)} ${base.spacing(2.5)}`,
      minHeight: '54px',
    },
    sizeMedium: {
      fontSize: base.fontSizes.xl,
      padding: `${base.spacing(1)} ${base.spacing(2)}`,
      minHeight: '48px',
    },
    sizeSmall: {
      fontSize: base.fontSizes.md,
      padding: `${base.spacing(0.5)} ${base.spacing(1.5)}`,
      minHeight: '36px',
    },
  };
};

export const OutlinedButtonStyles = (base: Theme) => {
  const outlineButtonStyle = {
    border: `1px solid ${base.palette.darkened.midnight}`,
    color: 'baseTheme.palette.darkened.midnight',
    backgroundColor: 'transparent',

    '&:hover': {
      border: `1px solid ${base.palette.neutrals.gray}`,
      color: base.palette.neutrals.gray,
      backgroundColor: 'transparent',
    },

    '&.Mui-disabled': {
      border: `1px solid ${base.palette.extras.fourAm}`,
      color: base.palette.extras.fourAm,
      backgroundColor: 'transparent',
    },

    '&.MuiLoadingButton-loading': {
      backgroundColor: 'transparent',

      '.MuiLoadingButton-loadingIndicator': {
        color: base.palette.darkened.midnight,
      },
    },
  };

  return {
    outlinedSizeLarge: {
      padding: `calc(${base.spacing(2)} - 1)`,
    },
    outlinedSizeMedium: {
      padding: `calc(${base.spacing(1)} - 1)`,
    },
    outlinedSizeSmall: {
      padding: `calc(${base.spacing(0.5)} - 1)`,
    },
    outlinedPrimary: outlineButtonStyle,
    outlinedSecondary: outlineButtonStyle,
  };
};

export const ContainedButtonStyles = (base: Theme) => {
  return {
    containedPrimary: {
      backgroundColor: base.palette.darkened.midnight,
      color: base.palette.neutrals.white,

      '&:visited': {
        color: base.palette.neutrals.white,
      },

      '&:hover': {
        backgroundColor: base.palette.neutrals.gray,
        color: base.palette.neutrals.white,
      },

      '&.Mui-disabled': {
        backgroundColor: base.palette.extras.fourAm,
        color: base.palette.lightened.iceGray,
      },

      '&.MuiLoadingButton-loading': {
        backgroundColor: base.palette.extras.fourAm,

        '.MuiLoadingButton-loadingIndicator': {
          color: base.palette.neutrals.white,
        },
      },
    },
  };
};

export const ContainedSecondaryButtonStyles = (base: Theme) => {
  return {
    containedSecondary: {
      backgroundColor: base.palette.neutrals.platinum,
      color: base.palette.darkened.midnight,
      '&:hover': {
        backgroundColor: base.palette.neutrals.milk,
        color: base.palette.neutrals.gray,
      },

      '&.Mui-disabled': {
        backgroundColor: base.palette.extras.fog,
        color: base.palette.extras.fourAm,
      },

      '&.MuiLoadingButton-loading': {
        backgroundColor: base.palette.extras.fog,

        '.MuiLoadingButton-loadingIndicator': {
          color: base.palette.darkened.midnight,
        },
      },
    },
  };
};

export const TextButtonStyles = (base: Theme) => {
  return {
    text: {
      '&:hover': {
        color: base.palette.secondary,
      },

      '&.Mui-disabled': {
        color: base.palette.text.disabled,
      },
    },
    textPrimary: {
      color: base.palette.darkened.midnight,
      backgroundColor: 'transparent',

      '&:hover': {
        color: base.palette.darkened.midnight,
        backgroundColor: base.palette.neutrals.milk,
      },

      '&.Mui-disabled': {
        color: base.palette.extras.fourAm,
      },

      '&.MuiLoadingButton-loading': {
        '.MuiLoadingButton-loadingIndicator': {
          color: base.palette.darkened.midnight,
        },
      },
    },
  };
};
