import { stringify } from 'qs';

import {
  AccountCid,
  CreateCancelSaleRequest,
  CreateRedemptionPreviewRequest,
  CreateRedemptionRequest,
  OfferingCid,
  OpsUpdateSaleRequest,
  RedemptionPreview,
  Sale,
  SaleCid,
  SaleStatus,
  SaleType,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export interface GetSaleQueryParams {
  /**
   * Adds SaleTrade projections to the Sale.
   */
  includeSaleTrades?: boolean;
  /**
   * Adds an Offering projection to the Sale.
   */
  includeOffering?: boolean;
  /**
   * Includes the redemption period the Sale was created in, if applicable.
   *
   * Use this instead of matching existing Sale records to the 'current' period via the redemption
   * preview's period when referring to historical data.
   */
  includeRedemptionPeriod?: boolean;
  /**
   * Includes any related cash account transactions for the Sale.
   */
  includePayments?: boolean;
  includePerformance?: boolean;
  includeTrades?: boolean;
}

export interface GetSalesQueryParams {
  /**
   * Required for investors to see their own Sales; optional for admins viewing all Sales.
   */
  accountCid?: AccountCid;
  /**
   * ISO date string in UTC time.
   */
  createdBefore?: string;
  /**
   * ISO date string in UTC time.
   */
  createdAfter?: string;
  /**
   * Page size.
   */
  size?: number;
  types?: SaleType[];
  statuses?: SaleStatus[];
  offeringCids?: OfferingCid[];
  // Projections for each Sale entry
  includeSaleTrades?: boolean;
  includeOffering?: boolean;
  includePerformance?: boolean;
  includeTrades?: boolean;
  includeRedemptionPeriod?: boolean;
  includePayments?: boolean;
}

export const postCreateRedemption = (request: CreateRedemptionRequest) =>
  createQuery<Sale>({
    apiInstance: api,
    method: 'post',
    url: '/sales/create-redemption',
    requestData: request,
  });

export const getSale = (saleCid: SaleCid, queryParams?: GetSaleQueryParams) =>
  createQuery<Sale>({
    apiInstance: api,
    method: 'get',
    url: `/sales/${saleCid}`,
    config: { params: queryParams },
  });

export const getSales = (queryParams?: GetSalesQueryParams) =>
  createQuery<Sale[]>({
    apiInstance: api,
    method: 'get',
    url: `/sales`,
    config: {
      params: {
        ...queryParams,
      },
      paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
    },
  });

export const postCreateRedemptionPreview = (request: CreateRedemptionPreviewRequest) =>
  createQuery<RedemptionPreview>({
    apiInstance: api,
    method: 'post',
    url: '/sales/generate-redemption-preview',
    requestData: request,
  });

export const cancelSale = ({ saleCid, requestBody }: { saleCid: SaleCid; requestBody: CreateCancelSaleRequest }) =>
  createQuery<Sale>({
    apiInstance: api,
    method: 'post',
    url: `/sales/${saleCid}/cancel`,
    requestData: requestBody,
  });

// --------------------------------

/**
 * Ops-only endpoint used within the Redemptions Review Portal experience.
 *
 * @param requestData the set of updates to apply to the Sale, including the CID to target
 * @returns the updated Sale entry
 */
export const postOpsUpdateSale = (requestData: OpsUpdateSaleRequest) =>
  createQuery<Sale>({
    apiInstance: api,
    method: 'post',
    url: `/sale/${requestData.cid}/ops-update`,
    requestData: requestData,
  });
