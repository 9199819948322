import { useCallback } from 'react';
import { LayoutChangeEvent } from 'react-native';

import {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withDelay,
  withSequence,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import { smoothSpring } from './smoothSpring';

/**
 * A reusable animation for the progress bar. This takes a progress status (0-100)
 * and animates the progress bar to that point. Use the `setProgressContainerWidth`
 * with `onLayout` to get the width of the progress bar container that we are animating around.
 *
 * For list items that are being rendered, we also pass the `
 */
export const useProgressBarAnimation = (progressStatus: number) => {
  const containerWidth = useSharedValue(0);
  const mount = useSharedValue(false);
  const hasWidth = useSharedValue(false);

  const setProgressContainerWidth = useCallback((event: LayoutChangeEvent) => {
    containerWidth.value = event.nativeEvent.layout.width;
    hasWidth.value = true;
  }, []);

  // For some odd reason, and based on the frame rate report this isn't even true,
  // but the `withSpring` happening here and then us interpolating the result
  // makes a much smoother like being drawn. Along with having a `setTimeout` act as the layout
  // this makes absolutely no sense, but from a user experience perspective it's much smoother.
  const progressBarAnimation = useDerivedValue(() => {
    if (!hasWidth.value) {
      return -containerWidth.value;
    }

    const containerPercentWidth = interpolate(progressStatus, [0, 100], [-containerWidth.value, 0]);

    // Wait until we've got the container width
    return !mount.value
      ? withSequence(
          withTiming(-containerWidth.value, { duration: 0 }),
          withDelay(
            800,
            withSpring(containerPercentWidth, smoothSpring, () => {
              mount.value = true;
            }),
          ),
        )
      : withDelay(800, withSpring(containerPercentWidth, smoothSpring));
  }, [containerWidth, hasWidth, progressStatus]);

  const progressAnimationStyles = useAnimatedStyle(
    () => ({
      // Width is really expensive to animate, so we are going to use a translateX
      transform: [{ translateX: progressBarAnimation.value }],

      // Provide defaults
      width: hasWidth.value ? '100%' : 0,
      height: hasWidth.value ? '100%' : 0,
    }),
    [hasWidth, progressBarAnimation],
  );

  return [
    /**
     * The general styles for the progress bar animation
     */
    progressAnimationStyles,

    /**
     * Set the width of the progress bar container to calculate
     * the width of the progress bar animation
     */
    setProgressContainerWidth,

    /**
     * The interpolated value of the progress bar animation
     */
    progressBarAnimation,
  ] as const;
};
