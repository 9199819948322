import { OfferingCid } from './offerings';
import { LeaseStatus } from './properties';

export const PropertyStatusTitle = {
  IPO_DATE: 'IPO_DATE',
  OCCUPIED_SELLER_PCO: 'OCCUPIED_SELLER_PCO',
  MARKET_PREPARATION: 'MARKET_PREPARATION',
  MARKETED_FOR_RENT: 'MARKETED_FOR_RENT',
  CHANGE_IN_MARKETING_RENT: 'CHANGE_IN_MARKETING_RENT',
  APPROVED_APPLICATION: 'APPROVED_APPLICATION',
  FIRST_LEASE_STARTS: 'FIRST_LEASE_STARTS',
  FULL_LEASE_RENEWAL: 'FULL_LEASE_RENEWAL',
  M2M_LEASE_RENEWAL: 'M2M_LEASE_RENEWAL',
  LEASE_CANCELLED: 'LEASE_CANCELLED',
  LEASE_ENDS: 'LEASE_ENDS',
  LEASE_STARTS: 'LEASE_STARTS',
  EARLY_LEASE_BREAK: 'EARLY_LEASE_BREAK',
  NOTICE: 'NOTICE',
  PROPERTY_ISSUE: 'PROPERTY_ISSUE',
  PROPERTY_ISSUE_RESOLVED: 'PROPERTY_ISSUE_RESOLVED',
  EVICTION: 'EVICTION',
  EVICTION_RESOLVED: 'EVICTION_RESOLVED',
  VACANT_OFFLINE: 'VACANT_OFFLINE',
  VACANT_OFFLINE_RESOLVED: 'VACANT_OFFLINE_RESOLVED',
  MANUAL_STATUS_ENTRY: 'MANUAL_STATUS_ENTRY',
  DIVIDENDS_PAID: 'DIVIDENDS_PAID',
  DIVIDENDS_PAUSED: 'DIVIDENDS_PAUSED',
  SHARE_PRICE_UPDATED: 'SHARE_PRICE_UPDATED',
  PROPERTY_MANAGEMENT_UPDATE: 'PROPERTY_MANAGEMENT_UPDATE',
  CURRENTLY_LIVE_ACCEPTING_BOOKINGS: 'CURRENTLY_LIVE_ACCEPTING_BOOKINGS',
  TEMPORARILY_UNAVAILABLE: 'TEMPORARILY_UNAVAILABLE',
  ONBOARDING_UPDATE: 'ONBOARDING_UPDATE',
  STATUS_UPDATE: 'STATUS_UPDATE',
  DISPOSITION_VACANT_LISTED: 'DISPOSITION_VACANT_LISTED',
  DISPOSITION_UNDER_CONTRACT: 'DISPOSITION_UNDER_CONTRACT',
  DISPOSITION_OCCUPIED_NOT_READY: 'DISPOSITION_OCCUPIED_NOT_READY',
  DISPOSITION_OCCUPIED_LISTED: 'DISPOSITION_OCCUPIED_LISTED',
} as const;

export type PropertyStatusTitle = (typeof PropertyStatusTitle)[keyof typeof PropertyStatusTitle];

export enum PropertyStatusApprovalStatus {
  PENDING_REVIEW = 'PENDING_REVIEW',
  APPROVED = 'APPROVED',
  AUTO_APPROVED = 'AUTO_APPROVED',
  DISAPPROVED = 'DISAPPROVED',
}
export interface PropertyStatusHistory {
  cid: string;
  propertyId: number;
  leaseCid?: string | null;
  statusTitle: PropertyStatusTitle;
  manualStatusTitleText?: string;
  approvalStatus: PropertyStatusApprovalStatus;
  propertyStatus?: LeaseStatus;
  marketingRent?: number;
  actualVProformaRent?: number;
  description?: string;
  displayDate: string;
  lease?: Lease;
  active: boolean;
  internalNotes?: string;
  updatedAt: string;
  createdAt: string;
  offeringName?: string;
  offeringCid?: string | null;
  leaseStatus?: LeaseStatus;
  leaseSalesforceId?: string;
  monthlyRent?: number;
  monthToMonthIncrease?: number;
  renewalRentIncreasePercent?: number;
  originalMonthlyRent?: number; // This is used on the FE to store the original rent for a lease that has a month to month lease renewal
  sharePriceEstimatedMin?: number | null;
  sharePriceEstimatedMax?: number | null;
  dividendYield?: number;
  sharePrice?: number;
}

export interface PropertyStatusHistoryPatchRequest {
  cid: string;
  propertyId?: number;
  leaseCid?: string | null;
  statusTitle?: PropertyStatusTitle;
  manualStatusTitleText?: string;
  approvalStatus?: PropertyStatusApprovalStatus;
  propertyStatus?: LeaseStatus;
  internalNotes?: string;
  description?: string;
  displayDate?: string;
  active?: boolean;
}

export interface Lease {
  cid: string;
  property_id: number;
  salesforce_id?: string;
  leaseStatus: LeaseStatus;
  monthlyRent: number;
  active: boolean;
  startDate: string;
  endDate?: string;
  isMonthToMonth: boolean;
  monthToMonthIncrease: number;
  renewalRentIncreasePercent: 0;
  createdAt: string;
  updatedAt: string;
}

export interface PropertyStatusHistoryPostRequest {
  propertyId: number;
  leaseCid?: string | null;
  statusTitle: PropertyStatusTitle;
  manualStatusTitleText?: string;
  approvalStatus: PropertyStatusApprovalStatus;
  propertyStatus?: LeaseStatus;
  marketingRent?: number;
  description?: string;
  displayDate: string;
}

export interface PropertyStatusHistoryByStatusQuery {
  propertyId: number;
  status: PropertyStatusTitle;
}

export interface PropertyStatusHistoryByPropertyIdQuery {
  propertyId: number;
}

export interface PropertyStatusHistoryOfferingNameQuery {
  cid: string;
}

export interface OfferingDividendHistory {
  id: number;
  cid: string | null;
  offeringCid: OfferingCid | null;
  postedAt: string;
  status: PropertyStatusTitle;
  dividendYield: number;
}

export interface OfferingSharePricesHistory {
  uuid: string;
  offeringCid: OfferingCid | null;
  postedAt: string;
  sharePrice: number;
  status: PropertyStatusTitle;
  sharePriceEstimatedMin: number | null;
  sharePriceEstimatedMax: number | null;
}
