import { useMemo } from 'react';

import { Auth0UserPermissions } from '@arrived/arrived-auth0';

import { usePermissions } from './usePermissions';

export function useCanUserTransactOpenForSec() {
  const { hasPermission } = usePermissions();
  return useMemo(() => hasPermission(Auth0UserPermissions.OPEN_FOR_SEC_TRADE_WRITE), [hasPermission]);
}
