import { type VariantSpreadFunction } from '@tamagui/core';

import { ButtonProps } from './Button';
import { getDefaultStyles } from './getDefaultStyles';
import { getDestructiveStyles } from './getDestructiveStyles';
import { getEmphasizedStyles } from './getEmphasizedStyles';
import { getGhostStyles } from './getGhostStyles';
import { getOutlinedStyles } from './getOutlinedStyles';

export type ButtonVariant = 'default' | 'outlined' | 'ghost' | 'emphasized' | 'destructive';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getButtonVariant: VariantSpreadFunction<any, ButtonVariant> = (variant, config) => {
  const isInverted = config.props.inverted;
  const isDisabled = config.props.disabled;
  const isLoading = config.props.loading;

  return isInverted
    ? getInvertedButtonStyles(variant, isLoading, isDisabled)
    : getDefaultButtonStyles(variant, isLoading, isDisabled);
};

const getDefaultButtonStyles = (variant: ButtonVariant, isLoading?: boolean, isDisabled?: boolean): ButtonProps => {
  if (variant === 'ghost') {
    return getGhostStyles(false, isLoading, isDisabled);
  }

  if (variant === 'outlined') {
    return getOutlinedStyles(false, isLoading, isDisabled);
  }

  if (variant === 'emphasized') {
    return getEmphasizedStyles(isLoading, isDisabled);
  }

  if (variant === 'destructive') {
    return getDestructiveStyles(isLoading, isDisabled);
  }

  return getDefaultStyles(false, isLoading, isDisabled);
};

const getInvertedButtonStyles = (variant: ButtonVariant, isLoading?: boolean, isDisabled?: boolean) => {
  if (variant === 'ghost') {
    return getGhostStyles(true, isLoading, isDisabled);
  }

  if (variant === 'outlined') {
    return getOutlinedStyles(true, isLoading, isDisabled);
  }

  if (variant === 'emphasized') {
    return getEmphasizedStyles(isLoading, isDisabled);
  }

  if (variant === 'destructive') {
    return getDestructiveStyles(isLoading, isDisabled);
  }

  return getDefaultStyles(true, isLoading, isDisabled);
};
