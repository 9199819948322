import { useQuery } from '@tanstack/react-query';

import { getPlaidVerificationTokenLink } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';

import { UseAwaitedQueryOptions } from '../../utils';
import { plaidVerificationTokenLinkKeyFn } from '../accounts.keys';

export function usePlaidVerificationTokenLinkQuery(
  accountId: AccountId,
  options?: UseAwaitedQueryOptions<typeof getPlaidVerificationTokenLink>,
) {
  return useQuery({
    queryKey: plaidVerificationTokenLinkKeyFn(accountId),
    queryFn: () => getPlaidVerificationTokenLink(accountId),
    ...options,
    staleTime: options?.staleTime ?? 0,
  });
}
