import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postInitiateOfferingTransactionFundsMovement } from '@arrived/api_v2';

import { accountTradesQueryKeyFn, tradeQueryKeyFn, userTradesQueryKeyFn } from './trades.keys';

export function usePostInitiateTradeFundsMovementMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postInitiateOfferingTransactionFundsMovement,
    onSuccess: (data) => {
      const { id: tradeId, accountId, userId } = data;

      queryClient.setQueryData(tradeQueryKeyFn(tradeId), data);

      queryClient.invalidateQueries({ queryKey: tradeQueryKeyFn(tradeId) });
      queryClient.invalidateQueries({ queryKey: accountTradesQueryKeyFn(accountId) });
      queryClient.invalidateQueries({ queryKey: userTradesQueryKeyFn(userId) });
    },
  });
}
