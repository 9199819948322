import { useMutation } from '@tanstack/react-query';

import { postOfferingPhoto } from '@arrived/api_v2';

import { UseAwaitedMutationOptions } from '../utils';

export function usePostOfferingPhotosMutation(options?: UseAwaitedMutationOptions<typeof postOfferingPhoto>) {
  return useMutation({
    mutationFn: postOfferingPhoto,
    ...options,
  });
}
