import { forwardRef, useMemo, useState } from 'react';

import { Controller, UseFormReturn } from 'react-hook-form';
import { FormattedMessage, FormattedNumber, IntlShape, useIntl } from 'react-intl';

import { FormatNumberOptions } from '@formatjs/intl/src/types';

import {
  Box,
  BoxProps,
  Slider,
  SliderProps,
  Stack,
  StackProps,
  Switch,
  TextField,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';

import { ArrivedTooltip } from '@arrived/features-tooltips';
import { ReturnsCalculatorForm } from '@arrived/forms';
import { Offering } from '@arrived/models';

import { LabelTypography, ReturnsDivider } from '../components';

import { AppreciationOptionLabel, AppreciationSelectionChip } from './components';
import {
  AppreciationInputType,
  ZipCodeAppreciationInfo,
  getAppreciationInputOptions,
  investmentAmountOptions,
} from './constants';
import { LeverageTooltip } from './text';

const SectionWrapper = (props: StackProps) => <Stack {...props} />;

const SliderWrapper = (props: StackProps) => <Stack direction="row" gap={1} alignItems="center" {...props} />;

const SliderValueTypography = (props: TypographyProps) => (
  <Typography
    color={({ palette }) => palette.neutrals.white}
    variant="h6"
    fontWeight="semibold"
    component="span"
    {...props}
  />
);

const SliderValueWrapper = (props: BoxProps) => (
  <Box display="flex" justifyContent="flex-end" minWidth="60px" {...props} />
);

interface GetFormattedSliderValuePercentage extends FormatNumberOptions {
  value: Parameters<Intl.NumberFormat['format']>[0];
}
const getFormattedSliderValuePercentage = ({ value, ...rest }: GetFormattedSliderValuePercentage, intl: IntlShape) => {
  return intl.formatNumber(value, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1, ...rest });
};

const CalculatorSlider = forwardRef<HTMLSpanElement, SliderProps>((props, ref) => (
  <Slider size="small" ref={ref} {...props} />
));

export interface CalculatorInputFormProps extends StackProps {
  form: UseFormReturn<ReturnsCalculatorForm>;
  offering: Offering;
  zipCodeInfo: ZipCodeAppreciationInfo & { zipCode?: string };
}

export const CalculatorInputForm = ({
  form,
  offering,
  zipCodeInfo: { zipCode, ...zipCodeAppreciationInfo },
  ...rest
}: CalculatorInputFormProps) => {
  const intl = useIntl();
  const { control, watch } = form;

  const rentalDividendYield = watch('rentalDividendYield');
  const annualAppreciation = watch('annualAppreciation');

  const appreciationInputOptions = useMemo(
    () => getAppreciationInputOptions(zipCodeAppreciationInfo),
    [zipCodeAppreciationInfo],
  );

  const [isLeverageTooltipOpen, setIsLeverageTooltipOpen] = useState(false);

  const { palette } = useTheme();

  return (
    <Stack divider={<ReturnsDivider />} {...rest}>
      <SectionWrapper gap={1}>
        <LabelTypography id="calculator-input.investment-amount.label">
          <FormattedMessage id="common.investment-amount" />
        </LabelTypography>
        <Controller
          control={control}
          name="investmentAmount"
          render={({ field }) => (
            <TextField
              InputProps={{
                sx: {
                  borderColor: ({ palette }) => palette.neutrals.white,
                  color: ({ palette }) => palette.neutrals.white,
                },
              }}
              inputProps={{ 'aria-labelledby': 'calculator-input.investment-amount.label' }}
              select
              SelectProps={{ native: true }}
              size="small"
              sx={{
                '& .MuiOutlinedInput-notchedOutline': { borderColor: ({ palette }) => palette.neutrals.white },
                '.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: ({ palette }) => palette.neutrals.platinum,
                },
                '& .MuiSvgIcon-root': { color: ({ palette }) => palette.neutrals.white },
                '.MuiInputBase-root:hover .MuiSvgIcon-root': { color: ({ palette }) => palette.neutrals.platinum },
              }}
              fullWidth
              {...field}
            >
              {investmentAmountOptions.map((amount) => (
                <option
                  key={amount}
                  value={amount}
                  style={{ backgroundColor: palette.neutrals.white, color: palette.darkened.midnight }}
                >
                  <FormattedNumber style="currency" maximumFractionDigits={0} currency="USD" value={amount} />
                </option>
              ))}
            </TextField>
          )}
        />
      </SectionWrapper>
      <SectionWrapper>
        <LabelTypography id="calculator-input.annual-cashflow.label">
          <FormattedMessage id="common.annual-cashflow" />
        </LabelTypography>
        <SliderWrapper>
          <Controller
            control={control}
            name="rentalDividendYield"
            render={({ field }) => (
              <CalculatorSlider
                min={0}
                max={0.08}
                step={0.001}
                aria-labelledby="calculator-input.annual-cashflow.label"
                getAriaValueText={(value) => getFormattedSliderValuePercentage({ value }, intl)}
                {...field}
              />
            )}
          />
          <SliderValueWrapper>
            <SliderValueTypography id="calculator-input.annual-cashflow.value">
              {getFormattedSliderValuePercentage({ value: rentalDividendYield }, intl)}
            </SliderValueTypography>
          </SliderValueWrapper>
        </SliderWrapper>
      </SectionWrapper>
      <SectionWrapper gap={1.5}>
        <Box>
          <LabelTypography id="calculator-input.annual-appreciation.label">
            <FormattedMessage id="common.annual-appreciation" />
          </LabelTypography>
          <SliderWrapper>
            <Controller
              control={control}
              name="annualAppreciation"
              render={({ field }) => (
                <CalculatorSlider
                  min={-0.05}
                  max={0.15}
                  step={0.001}
                  aria-labelledby="calculator-input.annual-appreciation.label"
                  getAriaValueText={(value) => getFormattedSliderValuePercentage({ value }, intl)}
                  {...field}
                />
              )}
            />
            <SliderValueWrapper>
              <SliderValueTypography>
                {getFormattedSliderValuePercentage({ value: annualAppreciation }, intl)}
              </SliderValueTypography>
            </SliderValueWrapper>
          </SliderWrapper>
        </Box>
        <Controller
          control={control}
          name="annualAppreciation"
          render={({ field: { onChange, value } }) => (
            <Stack gap={1}>
              {appreciationInputOptions.map(({ type, value: appreciationValue, years }) => {
                if (type === AppreciationInputType.ZIP_CODE && zipCode == null) {
                  return null;
                }

                return (
                  <AppreciationSelectionChip
                    key={`${type}-${years}`}
                    label={
                      <AppreciationOptionLabel type={type} value={appreciationValue} years={years} zipCode={zipCode} />
                    }
                    onChange={onChange}
                    selected={Math.abs(Math.round(value * 1000) - Math.round(appreciationValue * 1000)) < 1}
                    value={appreciationValue}
                  />
                );
              })}
            </Stack>
          )}
        />
      </SectionWrapper>
      <SectionWrapper>
        <ArrivedTooltip
          open={isLeverageTooltipOpen}
          tooltipContent={<LeverageTooltip offering={offering} />}
          onOpen={() => setIsLeverageTooltipOpen(true)}
          onClose={() => setIsLeverageTooltipOpen(false)}
          referencedTextContainerPropOverrides={{
            alignSelf: 'flex-start',
          }}
          referencedTextPropOverrides={{
            fontWeight: 'semibold',
            color: ({ palette }) => palette.neutrals.white,
          }}
        >
          <FormattedMessage id="common.leverage" />
        </ArrivedTooltip>
        <Controller
          control={control}
          name="leverage"
          render={({ field: { value, ...rest } }) => (
            <Box
              onClick={() => {
                if (!offering.hasFlexFinancing) {
                  setIsLeverageTooltipOpen(true);
                }
              }}
            >
              <Switch
                disabled={!offering.hasFlexFinancing}
                checked={value}
                inputProps={{
                  'aria-label': intl.formatMessage({
                    id: value
                      ? 'property-details.calculator.leverage-checkbox.levered.label'
                      : 'property-details.calculator.leverage-checkbox.unlevered.label',
                  }),
                }}
                {...rest}
              />
            </Box>
          )}
        />
      </SectionWrapper>
    </Stack>
  );
};
