import { DependencyList, useEffect } from 'react';

export const useTimer = (timerLength: number, callback: () => void, deps: DependencyList = []) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, timerLength);

    return () => clearTimeout(timer);
  }, deps);
};
