import { FormattedMessage, useIntl } from 'react-intl';

import { Callout, Stack, styled } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { Offering } from '@arrived/models';

import { ProductDetailContent, ProductDetailContentProps } from '../../../../content';
import { ProductInfoBox, ProductInfoBoxFrame } from '../../../../productInfoBox';

import { LeverageAmountBar } from './LeverageAmountBar';
import { LeverageAmountText } from './LeverageAmountText';

export type PropertyLeverageVolatilitySectionProps = ProductDetailContentProps & { offering: Offering };

const VolatilityInfoBox = styled(ProductInfoBoxFrame, {
  flexGrow: 1,
  flexShrink: 1,
  $gtSm: { flexBasis: 0 },
});

export const PropertyLeverageVolatilitySection = ({ offering, ...rest }: PropertyLeverageVolatilitySectionProps) => {
  const intl = useIntl();
  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage id="product-details.property-leverage-volatility" />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body gap="$3">
        {offering.hasFlexFinancing && (
          <Callout hideCloseIcon variant="inverted">
            <Callout.Heading>
              <FormattedMessage id="product-details.strategic-refinance-candidate" />
            </Callout.Heading>
            <Callout.Content>
              <Markdown Text={Callout.Content.Text}>
                {intl.formatMessage({ id: 'product-details.strategic-refinance-candidate.description' })}
              </Markdown>
            </Callout.Content>
          </Callout>
        )}
        <Stack gap="$3" $gtSm={{ row: true }}>
          <VolatilityInfoBox>
            <ProductInfoBox.Header tooltip={<FormattedMessage id="product-details.property-leverage.tooltip" />}>
              <FormattedMessage id="product-details.property-leverage" />
            </ProductInfoBox.Header>
            <LeverageAmountBar offering={offering} />
          </VolatilityInfoBox>
          <VolatilityInfoBox>
            <ProductInfoBox.Header
              tooltip={<FormattedMessage id="product-details.property-equity-volatility.tooltip" />}
            >
              <FormattedMessage id="product-details.property-equity-volatility" />
            </ProductInfoBox.Header>
            <LeverageAmountText offering={offering} />
          </VolatilityInfoBox>
        </Stack>
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};
