import { memo } from 'react';

import { Divider, DividerProps } from '../../atoms';

export const SheetDivider = memo((props: DividerProps) => (
  <Divider
    solid
    alt
    px="$4"
    $gtXxs={{
      px: '$6',
    }}
    $gtSm={{
      px: '$10',
    }}
    {...props}
  />
));
