import { useEffect, useState } from 'react';

import { Photo } from '@arrived/models';
import {
  useGetOfferingPhotosQuery,
  useGetVideoMetadataByMarketId,
  useGetVideoMetadataByOfferingId,
} from '@arrived/queries';

export type OfferingPhoto = {
  uri: string;
  youtubeId?: string;
};

const getYoutubeVideoThumbnail = (youtubeId: string) => `https://img.youtube.com/vi/${youtubeId}/0.jpg`;

/**
 * Quick utility to grab all the relevant photos for an offering, cross platform.
 *
 * Optionally searches for and includes offering or market video thumbnails; defaults to true.
 */
export const useProductPhotos = ({
  cid,
  marketId,
  offeringId,
  defaultOfferingPhotos,
  includeExternalSources = true,
}: {
  cid?: string;
  marketId?: number;
  offeringId?: number;
  defaultOfferingPhotos?: Photo[];
  /**
   * Whether the hook will search for offering and market video metadata. Defaults to true.
   */
  includeExternalSources?: boolean;
}) => {
  const [photos, setPhotos] = useState<OfferingPhoto[]>([]);

  const offeringPhotosState = useGetOfferingPhotosQuery(cid, {
    includeUrls: true,
    select: (data) => data.photos.map(({ projections: { url } }) => url),
  });

  // Fetch potential external image sources if necessary
  const offeringVideoState = useGetVideoMetadataByOfferingId(offeringId, { enabled: includeExternalSources });
  const marketVideoState = useGetVideoMetadataByMarketId(marketId, { enabled: includeExternalSources });

  useEffect(() => {
    let offeringVideoPosition = 0;
    let _photos: OfferingPhoto[] = [];

    if (defaultOfferingPhotos) {
      // If we don't have any photos, use the default offering photos
      _photos =
        defaultOfferingPhotos.map(({ url }) => ({
          uri: url,
        })) ?? [];
    }

    if (offeringPhotosState.isSuccess && offeringPhotosState.data.length > 0) {
      _photos = offeringPhotosState.data.map((url) => ({
        uri: url,
      }));
    }

    if (offeringVideoState.data) {
      offeringVideoState.data.forEach(({ position, youtubeId }) => {
        offeringVideoPosition = Math.max(0, Math.min(position, photos.length));

        _photos.splice(offeringVideoPosition, 0, {
          uri: getYoutubeVideoThumbnail(youtubeId),
          youtubeId,
        });
      });
    }

    if (marketVideoState.data) {
      const position = offeringVideoPosition + 1;

      _photos.splice(position, 0, {
        uri: getYoutubeVideoThumbnail(marketVideoState.data),
        youtubeId: marketVideoState.data,
      });
    }

    setPhotos(_photos);
  }, [
    offeringPhotosState.isSuccess,
    offeringPhotosState.data,
    offeringVideoState.data,
    marketVideoState.data,
    defaultOfferingPhotos,
    photos.length,
  ]);

  return photos;
};
