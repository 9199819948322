import { useQuery } from '@tanstack/react-query';

import { getAccountOfferingBalances } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../../utils';
import { offeringBalancesKeyFn } from '../accounts.keys';

/**
 * The balances per offering for the given account.
 */
export function useGetAccountOfferingBalancesQuery(
  accountId?: AccountId,
  options?: UseAwaitedQueryOptions<typeof getAccountOfferingBalances>,
) {
  return useQuery({
    queryKey: offeringBalancesKeyFn(accountId!),
    queryFn: () => getAccountOfferingBalances(accountId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}
