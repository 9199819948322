import { Dispatch, PropsWithChildren, SetStateAction, createContext, useCallback, useMemo, useState } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import {
  EVENTS,
  useHasAnonymousUserLeadCaptured,
  useHasAnonymousUserLoggedIn,
  useIdentify,
  useTrack,
} from '@arrived/analytics';
import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { stub } from '@arrived/common';
import { trackMarketingConversionEvent } from '@arrived/marketing';
import { MARKETING_CONVERSION_EVENTS } from '@arrived/models';
import { useGetCustomerCountQuery } from '@arrived/queries';

import { SubscribeFooter, SubscribeFooterProps } from './SubscribeFooter';

export type SubscribeFooterCustomProps = Omit<SubscribeFooterProps, 'appBarProps' | 'in' | 'onSubscribe'>;

export interface SubscribeFooterContextType {
  /**
   * Indicates whether the SubscribeFooter is present on the page, this does not indicate if the footer is
   * showing as it is controlled by a number of other factors including the users lead/login status.
   */
  isOnPage: boolean;
  /**
   * Indicates whether the SubscribeFooter is showing. This takes into account isPresent and other attributes
   * which determine the visibility of the footer.
   */
  isShowing: boolean;
  /**
   * Can be used to override the default subscribe footer message & button text.
   */
  setCustomProps: Dispatch<SetStateAction<SubscribeFooterCustomProps | undefined>>;
  setIsOnPage: Dispatch<SetStateAction<boolean>>;
}

export const SubscribeFooterContext = createContext<SubscribeFooterContextType>({
  isOnPage: false,
  isShowing: false,
  setCustomProps: stub,
  setIsOnPage: stub,
});

export const SubscribeFooterWrapper = ({ children }: PropsWithChildren) => {
  const [isOnPage, setIsOnPage] = useState(false);
  const [customProps, setCustomProps] = useState<SubscribeFooterCustomProps>();
  const { isAuthenticated } = useArrivedAuth0();
  const customerCountState = useGetCustomerCountQuery();
  const track = useTrack();
  const identify = useIdentify();
  const hasAnonymousUserLoggedIn = useHasAnonymousUserLoggedIn();
  const hasAnonymousUserLeadCaptured = useHasAnonymousUserLeadCaptured();

  const isShowing = useMemo(
    () => isOnPage && !isAuthenticated && hasAnonymousUserLoggedIn === false && hasAnonymousUserLeadCaptured === false,
    [hasAnonymousUserLoggedIn, hasAnonymousUserLeadCaptured, isAuthenticated, isOnPage],
  );

  const handleOnSubscribe = useCallback((email: string) => {
    track(EVENTS.EMAIL_SUBSCRIPTION, { position: 'sticky-bar' });
    track(EVENTS.LEAD_FORM_COMPLETED, {
      email,
      lead_form_type: 'App - Home Page',
      referral_source: document.referrer,
      url: window.location.href,
    });
    identify({
      email,
      user_setup_stage: 'lead',
      lead_created_at: new Date().toISOString(),
    });
    trackMarketingConversionEvent({ email, type: MARKETING_CONVERSION_EVENTS.EMAIL_LEAD });
  }, []);

  const contextValue = useMemo(
    () => ({
      isOnPage,
      isShowing,
      setCustomProps,
      setIsOnPage,
    }),
    [isOnPage, isShowing],
  );

  const { cta: customPropsCta, children: customPropsChildren, ...rest } = customProps || {};

  return (
    <SubscribeFooterContext.Provider value={contextValue}>
      {children}
      {customerCountState.isSuccess && (
        <SubscribeFooter
          appBarProps={{ sx: { top: 'unset', bottom: 0 } }}
          in={isShowing}
          onSubscribe={handleOnSubscribe}
          cta={customPropsCta ?? <FormattedMessage id="common.get-started" />}
          {...rest}
        >
          {customPropsChildren ?? (
            <FormattedMessage
              id="subscribe-footer.text"
              values={{ count: <FormattedNumber value={customerCountState.data.count} notation="compact" /> }}
            />
          )}
        </SubscribeFooter>
      )}
    </SubscribeFooterContext.Provider>
  );
};
