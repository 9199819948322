import { useQuery } from '@tanstack/react-query';

import { getOfferingTrades } from '@arrived/api_v2';
import { GenericFilter, OfferingId } from '@arrived/models';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { offeringTradesQueryKeyFn } from './trades.keys';

export function useAdminOfferingTradesQuery(
  offeringId?: OfferingId,
  filters?: GenericFilter,
  options?: UseAwaitedQueryOptions<typeof getOfferingTrades>,
) {
  return useQuery({
    queryKey: offeringTradesQueryKeyFn(offeringId!, filters),
    queryFn: () => getOfferingTrades(offeringId!, filters),
    ...options,
    enabled: (options?.enabled ?? true) && Boolean(offeringId),
  });
}
