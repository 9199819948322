import { PlannedInvestmentAmount, PlannedInvestmentAmountValues } from '@arrived/models';

const FALLBACK_IAUM_THRESHOLD_AMOUNT = 2000;

/**
 * Boolean check to confirm if the investor's provided intended investment amount, or IAUM, is
 * greater than or equal to the minimum threshold provided.
 *
 * Default threshold is $2,000.
 */
export const isIntendedInvestmentGreaterThanOrEqualToIAUMMinimum = (
  plannedInvestmentAmount: PlannedInvestmentAmount | undefined,
  plannedInvestmentMinimum: number = FALLBACK_IAUM_THRESHOLD_AMOUNT,
): boolean => {
  if (!plannedInvestmentAmount) {
    return false;
  }

  return PlannedInvestmentAmountValues[plannedInvestmentAmount][0] >= plannedInvestmentMinimum;
};
