import { useCallback, useEffect, useMemo } from 'react';

import { useMutationState } from '@tanstack/react-query';

import { Account, AccountKycAml, KycAmlRollupStatus } from '@arrived/models';
import {
  cashAccountSyncKey,
  useGetAccountKycAmlQuery,
  useGetPrimaryAccountQuery,
  usePostSyncAccountToCashAccountServiceMutation,
} from '@arrived/queries';

const brokerageAccountNeedsSync = (account?: Account, accountKycAml?: AccountKycAml) =>
  account &&
  account.cid != null &&
  accountKycAml?.kycAmlRollupStatus === KycAmlRollupStatus.PASS &&
  (account.cashAccountCid == null ||
    account.cashCounterpartyCid == null ||
    (!!account.externalAccount && account.externalAccount.cashExternalBankAccountCid == null));

/**
 * Responsible for confirming whether the user's brokerage account needs to be synced to cash account service.
 *
 * Will run the POST if the brokerage account exists, and:
 * - there is no `cashAccountCid`
 * - there is no `cashCounterpartyCid`
 * - there is a linked bank account *without* a `cashExternalBankAccountCid`
 */
export const useSyncBrokerageAccountToCashAccount = () => {
  const { data: accountData } = useGetPrimaryAccountQuery();
  const { data: accountKycAmlData } = useGetAccountKycAmlQuery(accountData?.id);
  const { mutateAsync: postSyncBrokerageAccount } = usePostSyncAccountToCashAccountServiceMutation();
  const data = useMutationState({ filters: { mutationKey: cashAccountSyncKey } });

  const isMutating = useMemo(() => data.length > 0 && data[data.length - 1].status === 'pending', [data]);

  const syncBrokerageAccountToCashAccountIfNeeded = useCallback(
    (account?: Account, accountKycAml?: AccountKycAml) => {
      if (brokerageAccountNeedsSync(account, accountKycAml) && !isMutating && account?.cid != null) {
        return postSyncBrokerageAccount(account.cid);
      }
    },
    [isMutating],
  );

  useEffect(() => {
    syncBrokerageAccountToCashAccountIfNeeded(accountData, accountKycAmlData);
  }, [accountData, accountKycAmlData]);

  return syncBrokerageAccountToCashAccountIfNeeded;
};
