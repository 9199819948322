import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function BuildingInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M5 18.333V15h3.333v3.333m-4.166-6.666h5m-5-3.334h5m2.5 10h7.083V8.75h-5.208"
        stroke={color}
        strokeWidth={1.25}
      />
      <Path d="M1.667 6.667v11.666h10V6.667l-5-5-5 5Z" stroke={color} strokeWidth={1.25} />
    </Svg>
  );
}

export const BuildingIcon = memo<IconProps>(withIconTheme(BuildingInternal));
