import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { format } from 'date-fns';

/**
 * Provides formatting functions for common tick/label formats like currency and dates.
 */
export const useFormatTickLabel = () => {
  const intl = useIntl();

  const formatCurrencyLabel = useCallback((value?: number) => {
    if (typeof value !== 'undefined') {
      return intl.formatNumber(value, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      });
    }

    return '';
  }, []);

  const formatDateLabel = useCallback((value?: string | number) => {
    if (typeof value !== 'undefined') {
      return format(new Date(value), "MMM ''yy");
    }

    return '';
  }, []);

  const formatPercentLabel = useCallback((value?: number) => {
    if (typeof value != 'undefined') {
      return intl.formatNumber(value, { style: 'percent' });
    }

    return '';
  }, []);

  return useMemo(
    () => ({ formatCurrencyLabel, formatDateLabel, formatPercentLabel }),
    [formatCurrencyLabel, formatDateLabel, formatPercentLabel],
  );
};
