import { getMonth, getYear, isBefore, format, subMonths } from 'date-fns';

export function getCurrentMonthAtNoonUTC(shortHand = false) {
  // Normalize the current time to UTC by constructing it using the UTC time components
  const now = new Date();

  // Construct the first day of the current month at 12:00 PM UTC
  const firstOfMonthAtNoonUTC = new Date(
    Date.UTC(
      getYear(now),
      getMonth(now),
      1, // First day of the month
      12, // 12:00 PM UTC
      0,
      0,
      0,
    ),
  );
  const formatting = shortHand ? 'MMM' : 'MMMM';

  // Check if the current time is before 12:00 PM UTC on the first of the month
  if (isBefore(now, firstOfMonthAtNoonUTC)) {
    // If we are before 12:00 PM on the first of the month, return the previous month
    const previousMonthDate = subMonths(now, 1);
    return format(previousMonthDate, formatting); // Get the previous month's name
  } else {
    // Otherwise, return the current month
    return format(now, formatting); // Get the current month's name
  }
}
