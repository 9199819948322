import { useEffect } from 'react';

import { EVENTS, useIdentify, useTrack } from '@arrived/analytics';
import { AccountBalanceV2, AccountOfferingBalance } from '@arrived/models';

export const useTrackPortfolioAnalytics = (
  balances?: AccountBalanceV2,
  individualBalances?: AccountOfferingBalance[],
) => {
  const track = useTrack();
  const identify = useIdentify();

  useEffect(() => {
    if (balances && individualBalances) {
      const currentPropertiesOwned = individualBalances.map((offeringBalance) => offeringBalance.name.toLowerCase());
      const currentPropertiesOwnedString = currentPropertiesOwned.join(',');
      track(EVENTS.PORTFOLIO_PAGE_VIEWED, {
        initial_investment: balances.initialInvestment,
        total_balance: balances.totalValue,
        total_returns: balances.totalReturns,
        total_appreciation: balances.appreciation.totalAppreciation,
        total_dividends: balances.rentalIncome.totalDividendsPaid,
        number_current_properties_owned: currentPropertiesOwned.length,
        list_current_properties_owned: currentPropertiesOwnedString,
      });
      identify({
        number_current_properties_owned: currentPropertiesOwned.length,
        list_current_properties_owned: currentPropertiesOwnedString,
        LTV: balances.initialInvestment,
      });
    }
  }, [balances, individualBalances]);
};
