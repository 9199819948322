import { RefObject, useEffect } from 'react';

import { TamaguiElement, isWeb } from '@tamagui/core';

/**
 * https://www.w3.org/WAI/ARIA/apg/patterns/tabs/
 * Implements the keyboard controls for navigating tabs as defined here.
 * Attaches listeners to the child elements of the 'tablist'.
 */
export const useTabsControls = (parentRef: RefObject<TamaguiElement>, setIndex: (idx: number) => void) => {
  useEffect(() => {
    if (isWeb) {
      if (parentRef.current instanceof HTMLElement) {
        const items = Array.from(parentRef.current.children);

        const getNextIndex = (newIndex: number) => {
          if (newIndex >= items.length) return 0;
          if (newIndex < 0) return items.length - 1;
          return newIndex;
        };

        items.forEach((item, idx) => {
          if (item instanceof HTMLElement) {
            item.onkeydown = (e) => {
              if (e.code === 'ArrowRight') {
                const buttonComponent = items[getNextIndex(idx + 1)];
                buttonComponent instanceof HTMLElement && buttonComponent.focus();
              }

              if (e.code === 'ArrowLeft') {
                const buttonComponent = items[getNextIndex(idx - 1)];
                buttonComponent instanceof HTMLElement && buttonComponent.focus();
              }

              if (e.code === 'Enter' || e.code === 'Space') {
                setIndex(idx);
              }

              if (e.code === 'Home') {
                setIndex(0);
              }

              if (e.code === 'End') {
                setIndex(items.length - 1);
              }
            };
          }
        });
      }
    }
  }, [setIndex]);
};
