/**
 * This is a very precise spring animation that makes springs
 * look like the have `ease-in-out` attributes, only _smoother_
 */
export const smoothSpring = {
  damping: 30,
  stiffness: 200,
  mass: 1,
  overshootClamping: false,
  restDisplacementThreshold: 0.001,
  restSpeedThreshold: 0.001,
} as const;
