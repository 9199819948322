import { ReactNode, useMemo, useRef, useState } from 'react';

import { useResizeDetector } from 'react-resize-detector';

import { Slide, useScrollTrigger } from '@mui/material';

import { Stack, TamaguiElement } from '@arrived/bricks';
import { NavigationBarContext, navigationBarTransition } from '@arrived/contexts';

import { useIsMobile } from '../hooks';

const DEFAULT_NAV_HEIGHT = 80;
export const NavigationBarDisplay = ({ children, NavComponent }: { children: ReactNode; NavComponent: ReactNode }) => {
  const [isFixed, setIsFixed] = useState(false);

  const isMobile = useIsMobile();

  const trigger = useScrollTrigger({ threshold: 600 });
  const mainNavRef = useRef<TamaguiElement>(null);
  const { height: foundNavHeight } = useResizeDetector({
    handleHeight: true,
    handleWidth: true,
    targetRef: mainNavRef,
  });
  const navHeight = useMemo(() => foundNavHeight ?? DEFAULT_NAV_HEIGHT, [foundNavHeight]);

  const navIn = useMemo(() => !isMobile || isFixed || !trigger, [isFixed, trigger, isMobile]);

  const contextValue = useMemo(
    () => ({
      setIsFixed,
      offset: navIn ? navHeight : 0,
      transition: navigationBarTransition,
    }),
    [navIn, navHeight],
  );

  return (
    <NavigationBarContext.Provider value={contextValue}>
      <Slide appear={false} direction="down" in={navIn}>
        <Stack
          ref={mainNavRef}
          position={'sticky' as 'absolute'}
          top={0}
          zIndex="$sticky"
          bg="$surface.neutral.default"
        >
          {NavComponent}
        </Stack>
      </Slide>
      {children}
    </NavigationBarContext.Provider>
  );
};
