import { useQuery } from '@tanstack/react-query';

import { getPlaidVerificationStatus } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { plaidVerificationStatusQueryKeyFn } from '../accounts.keys';

export function useAdminGetPlaidVerificationStatus(
  accountId: AccountId,
  options?: UseAwaitedQueryOptions<typeof getPlaidVerificationStatus>,
) {
  return useQuery({
    queryKey: plaidVerificationStatusQueryKeyFn(accountId),
    queryFn: () => getPlaidVerificationStatus(accountId),
    enabled: options?.enabled ?? true,
    ...options,
  });
}
