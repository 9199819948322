import { useQuery } from '@tanstack/react-query';

import { getPromotionUsage } from '@arrived/api_v2';

import { UseAwaitedQueryOptions } from '../utils';

import { promotionUsageKeyFn } from './rewards.keys';

export function useGetPromotionUsageQuery(
  promotionUuid?: string,
  options?: UseAwaitedQueryOptions<typeof getPromotionUsage>,
) {
  return useQuery({
    queryKey: promotionUsageKeyFn(promotionUuid!),
    queryFn: () => getPromotionUsage(promotionUuid!),
    ...options,
    enabled: (options?.enabled ?? true) && promotionUuid != null,
  });
}
