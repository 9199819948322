import { Stack, styled } from '@arrived/bricks';

import { BodyName } from './constants';

export const ProductDetailContentBody = styled(Stack, {
  name: BodyName,

  py: '$6',
  px: '$4',
  gap: '$6',

  $gtXxs: {
    px: '$6',
  },
});
