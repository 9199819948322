import { createContext, useContext, useMemo, useState } from 'react';

import { useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';

import { ModalOptions } from './ModalProps';

export function useModal({ open, onClose, onToggle }: ModalOptions = {}) {
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const data = useFloating({
    open,
    onOpenChange: onToggle,
  });

  const context = data.context;

  const click = useClick(context);
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      onClose,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, onClose, interactions, data, labelId, descriptionId],
  );
}

type ContextType =
  | (ReturnType<typeof useModal> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
    })
  | null;

export const ModalContext = createContext<ContextType>(null);

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context == null) {
    throw new Error('Modal components must be wrapped in <Modal />');
  }

  return context;
};
