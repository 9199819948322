import { QueryKey } from '@tanstack/react-query';

import { OfferingId } from '@arrived/models';

export const airDnaKey: QueryKey = ['airDna'];
export const airDnaAverageDailyRateKeyFn = (offeringId: OfferingId): QueryKey => [
  airDnaKey.concat([offeringId, 'averageDailyRate']),
];
export const airDnaMonthlyRevenueKeyFn = (offeringId: OfferingId): QueryKey => [
  airDnaKey.concat([offeringId, 'monthlyRevenue']),
];
export const airDnaMonthlyOccupancyKeyFn = (offeringId: OfferingId): QueryKey => [
  airDnaKey.concat([offeringId, 'monthlyOccupancy']),
];
