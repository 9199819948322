import { ComponentProps } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Divider, DividerProps, Grid, Stack, Typography } from '@mui/material';

import { ArrivedTooltip } from '@arrived/features-tooltips';
import { AssetType, Offering } from '@arrived/models';

import { ExpensesTooltipContent } from './text/ExpensesTooltipContent';
import { useRentalIncomeCalculationData } from './useRentalIncomeCalculationData';

export interface RentalIncomeCalculationProps {
  offering: Offering;
}

const RentalIncomeCalculationDivider = ({ sx, ...rest }: DividerProps) => (
  <Divider sx={{ borderColor: ({ palette }) => palette.darkened.midnight, ...sx }} {...rest} />
);

const CurrencyFigure = (props: ComponentProps<typeof FormattedNumber>) => (
  <FormattedNumber style="currency" currency="USD" maximumFractionDigits={0} {...props} />
);

const PercentFigure = (props: ComponentProps<typeof FormattedNumber>) => (
  <FormattedNumber style="percent" maximumFractionDigits={1} minimumFractionDigits={0} {...props} />
);

export const RentalIncomeCalculation = ({ offering }: RentalIncomeCalculationProps) => {
  const { assetType } = offering;
  const { dividends, dividendYield, expenses, grossYield, rent } = useRentalIncomeCalculationData(offering);

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item xs={4} md={5} display="flex" justifyContent="flex-end">
          <Typography fontWeight="semibold">
            <CurrencyFigure value={rent} />
          </Typography>
        </Grid>
        <Grid item xs={8} md={7}>
          <Stack direction="row" flexWrap="wrap" columnGap={1} alignItems="center">
            <ArrivedTooltip
              tooltipContent={
                <FormattedMessage
                  id={
                    assetType === AssetType.LTR
                      ? 'property-details.annual-rent.description.sfr'
                      : 'property-details.annual-rent.description.vr'
                  }
                />
              }
            >
              <FormattedMessage id="common.annual-rent" />
            </ArrivedTooltip>
            <Typography variant="body2" fontWeight="medium">
              {'— '}
              <FormattedNumber
                value={grossYield}
                style="percent"
                minimumFractionDigits={1}
                maximumFractionDigits={1}
              />{' '}
              <FormattedMessage id="common.gross-yield" />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={1} md={2} display="flex" justifyContent="center">
          <Typography fontSize={({ fontSizes }) => fontSizes['4xl']} fontWeight="semibold">
            -
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="flex-end">
          <Typography>
            <CurrencyFigure value={expenses} />
          </Typography>
        </Grid>
        <Grid item xs={8} md={7}>
          <ArrivedTooltip tooltipContent={<ExpensesTooltipContent offering={offering} />}>
            <FormattedMessage id="property-details.returns-data.expenses-line-item" />
          </ArrivedTooltip>
        </Grid>
      </Grid>
      <RentalIncomeCalculationDivider />
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item xs={4} md={5} display="flex" justifyContent="flex-end">
          <Typography fontWeight="semibold">
            <CurrencyFigure value={dividends} />
          </Typography>
        </Grid>
        <Grid item xs={8} md={7}>
          <Typography>
            <FormattedMessage id="property-details.returns-data.cash-flow-dividends" />
          </Typography>
        </Grid>
        <Grid item xs={1} md={2} display="flex" justifyContent="center">
          <Typography fontSize={({ fontSizes }) => fontSizes['4xl']} fontWeight="semibold">
            ÷
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="flex-end">
          <Typography>
            <CurrencyFigure value={offering.targetRaiseAmount} />
          </Typography>
        </Grid>
        <Grid item xs={8} md={7}>
          <Typography>
            <FormattedMessage id="common.raise-amount" />
          </Typography>
        </Grid>
      </Grid>
      <RentalIncomeCalculationDivider sx={{ borderBottomWidth: '3px' }} />
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item xs={4} md={5} display="flex" justifyContent="flex-end">
          <Typography
            fontWeight="semibold"
            fontSize={({ fontSizes }) => fontSizes['3xl']}
            lineHeight={({ lineHeights }) => lineHeights['2xl']}
          >
            <PercentFigure value={dividendYield} />
          </Typography>
        </Grid>
        <Grid lineHeight={0} item xs={8} md={7}>
          <ArrivedTooltip
            tooltipContent={<FormattedMessage id="property-details.returns-data.rental-dividend-yield.tooltip" />}
            referencedTextPropOverrides={{
              fontWeight: 'semibold',
              fontSize: ({ fontSizes }) => fontSizes['3xl'],
              lineHeight: ({ lineHeights }) => lineHeights['2xl'],
            }}
          >
            <FormattedMessage id="common.anticipated-annual-cashflow" />
          </ArrivedTooltip>
        </Grid>
      </Grid>
    </>
  );
};
