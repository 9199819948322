import * as yup from 'yup';

import { AmlStatus, KycAmlStatusDetail, KycStatus, UpdateAccountKycAmlStatusRequest } from '@arrived/models';

export const AccountKycAmlSchema: yup.ObjectSchema<UpdateAccountKycAmlStatusRequest> = yup
  .object({
    userKycStatus: yup.mixed<KycStatus>().oneOf(Object.values(KycStatus)).nullable().defined(),
    kycStatusDetail: yup.mixed<KycAmlStatusDetail>().oneOf(Object.values(KycAmlStatusDetail)).nullable().defined(),
    userAmlStatus: yup.mixed<AmlStatus>().oneOf(Object.values(AmlStatus)).nullable().defined(),
    amlStatusDetail: yup.mixed<KycAmlStatusDetail>().oneOf(Object.values(KycAmlStatusDetail)).nullable().defined(),
    entityAmlStatus: yup.mixed<AmlStatus>().oneOf(Object.values(AmlStatus)).nullable().defined(),
    entityAmlStatusDetail: yup
      .mixed<KycAmlStatusDetail>()
      .oneOf(Object.values(KycAmlStatusDetail))
      .nullable()
      .defined(),
    manualEmailSent: yup.bool().nullable().defined(),
  })
  .defined();

export type AccountKycAmlSchema = yup.InferType<typeof AccountKycAmlSchema>;
