import { space } from './space';

const container = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
} as const;

export const sizes = {
  ...space,
  ...{
    '3xs': 224,
    '2xs': 256,
    xs: 320,
    sm: 384,
    md: 448,
    lg: 512,
    xl: 576,
    '2xl': 672,
  },
  container,
} as const;

export type Sizes = keyof typeof sizes;
