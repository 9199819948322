import { ReactNode, cloneElement, isValidElement, useCallback, useMemo } from 'react';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { FormattedMessage, useIntl } from 'react-intl';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import {
  Button,
  MainNav,
  NavItem,
  NavItemType,
  ResourceProps,
  Stack,
  useMainNavContext,
  useMedia,
} from '@arrived/bricks';
import { ConvertBuilderPath, convertBuilderNavItem, useGetBuilderNavItemsQuery } from '@arrived/builder.io';
import { ROUTES } from '@arrived/common';
import { NavigationBarDisplay } from '@arrived/components';
import { CONFIG } from '@arrived/config';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { useInvestNavItem } from '@arrived/hooks';
import { useGetCurrentUserQuery } from '@arrived/queries';

const NavResource = ({ children, link, isExternal }: ResourceProps) => {
  if (isExternal) {
    if (isValidElement(children)) {
      return cloneElement(children, {
        ...children.props,
        href: link,
      });
    }
  } else {
    return (
      <Link legacyBehavior passHref href={link}>
        {children}
      </Link>
    );
  }
};

const SecondarySpot = () => {
  const { isAuthenticated, isLoading: isAuthLoading, authorize } = useArrivedAuth0();
  const currentUserState = useGetCurrentUserQuery();
  const isAdmin = Boolean(currentUserState.data?.admin);

  const intl = useIntl();
  const { close } = useMainNavContext();
  const media = useMedia();

  const handleLoginPress = useCallback(() => {
    authorize({
      prompt: 'login',
    });
  }, [authorize]);

  if (isAuthLoading || (isAuthenticated && !currentUserState.isSuccess)) {
    return <MainNav.SecondarySpot.SkeletonLoader />;
  } else if (isAuthenticated) {
    return (
      <MainNav.SecondarySpot.DropdownWrapper>
        {media.gtXs && <MainNav.Divider />}
        <MainNav.Dropdown
          resources={[
            {
              label: intl.formatMessage({ id: 'navigation.user.account' }),
              isExternal: true,
              link: CONFIG.baseHref + ROUTES.account.settings,
            },
            {
              label: intl.formatMessage({ id: 'navigation.user.logout' }),
              isExternal: true,
              link: CONFIG.baseHref + ROUTES.logOut,
            },
            ...(isAdmin
              ? [
                  {
                    label: intl.formatMessage({ id: 'navigation.user.admin' }),
                    isExternal: true,
                    link: CONFIG.baseHref + ROUTES.admin.base,
                  },
                ]
              : []),
          ]}
        >
          <MainNav.Dropdown.Text>{currentUserState.data?.firstName}</MainNav.Dropdown.Text>
        </MainNav.Dropdown>
      </MainNav.SecondarySpot.DropdownWrapper>
    );
  } else {
    return (
      <MainNav.SecondarySpot.ButtonsWrapper>
        {/*
  Not wrapping this in NavResource b/c we do _not_ want the NavResource behavior for
  external links where it has `rel="noopener noreferrer"` and `target="_blank"` specified.
  */}
        <Button variant="ghost" onPress={handleLoginPress} flex={1}>
          <FormattedMessage id="common.log-in" />
        </Button>
        <NavResource link={ROUTES.public.register}>
          <Button flex={1} tag="a" onPress={close}>
            <FormattedMessage id="auth.signup" />
          </Button>
        </NavResource>
      </MainNav.SecondarySpot.ButtonsWrapper>
    );
  }
};

const useConvertBuilderPath = (): ConvertBuilderPath => {
  const { isAuthenticated } = useArrivedAuth0();

  return useCallback(
    (path) => {
      // First handle absolute paths, all of these will be external.
      if (!path.startsWith('/')) {
        return { isExternal: true, link: path };
        // Since we are in the public-app, if we get a path for anything under `/properties` it should be
        // changed to route to `/app/properties` if we are authenticated.
      } else if (path.startsWith('/properties')) {
        return {
          isExternal: isAuthenticated,
          link: CONFIG.publicAppRoot + (isAuthenticated ? CONFIG.baseHref : '') + path,
        };
        // Finally, if it's a relative link that does not start with `/app` we can assume that it
        // is a public-app route.
      } else {
        return { isExternal: path.startsWith('/app'), link: CONFIG.publicAppRoot + path };
      }
    },
    [isAuthenticated],
  );
};

const usePrimaryItems = () => {
  const { isAuthenticated } = useArrivedAuth0();
  const convertBuilderPath = useConvertBuilderPath();

  const intl = useIntl();
  const pathname = usePathname();

  const investNavItem = useInvestNavItem(convertBuilderPath, {
    active: pathname.startsWith(ROUTES.properties.base),
    ...convertBuilderPath(ROUTES.properties.base),
  });
  const builderNavItems = useGetBuilderNavItemsQuery();

  return useMemo(
    (): NavItem[] => [
      investNavItem,
      ...(isAuthenticated
        ? [
            {
              key: 'account',
              label: intl.formatMessage({ id: 'navigation.account' }),
              link: CONFIG.baseHref + ROUTES.account.base,
              isExternal: true,
              type: NavItemType.LINK,
            },
          ]
        : []),
      ...(builderNavItems.isSuccess
        ? builderNavItems.data.map(({ data }) => convertBuilderNavItem(data, convertBuilderPath))
        : []),
    ],
    [builderNavItems, investNavItem, isAuthenticated],
  );
};

const PrimaryMobileCta = () => {
  const showMobileSignUp = useIsFeatureFlagEnabled(FEATURE_FLAGS.MOBILE_NAV_SIGN_UP);

  if (showMobileSignUp) {
    return (
      <Stack alignItems="flex-end">
        <NavResource link={ROUTES.public.register}>
          <Button condensed tag="a">
            <FormattedMessage id="auth.signup" />
          </Button>
        </NavResource>
      </Stack>
    );
  } else {
    return null;
  }
};

const NavComponent = () => {
  const { isAuthenticated } = useArrivedAuth0();
  const primaryItems = usePrimaryItems();

  return (
    <Stack className="main-nav-container" row width="100%" $gtXs={{ px: '$4' }}>
      <MainNav
        home={{
          isExternal: isAuthenticated,
          link: isAuthenticated ? CONFIG.baseHref + ROUTES.account.portfolioOverview : '/',
        }}
        Resource={NavResource}
        maxWidth={1440}
        mx="auto"
        primaryItems={primaryItems}
        width="100%"
        SecondarySpot={SecondarySpot}
        MobilePrimaryCta={PrimaryMobileCta}
      />
    </Stack>
  );
};
export const NavMenu = ({ children }: { children: ReactNode }) => {
  return <NavigationBarDisplay NavComponent={<NavComponent />}>{children}</NavigationBarDisplay>;
};
