export const SuitabilityQuestionsIntl = {
  NetWorth: {
    individual: {
      id: 'suitability-questionnaire.net-worth-over-1m.label',
    },
    entity: {
      id: 'suitability-questionnaire.net-worth-over-1m-entity.label',
    },
  },

  IndividualIncome: {
    individual: {
      id: 'suitability-questionnaire.income-over-200k.label',
    },

    entity: {
      id: 'suitability-questionnaire.income-over-200k-entity.label',
    },
  },

  JointIncome: {
    individual: {
      id: 'suitability-questionnaire.household-income-over-300k.label',
    },

    entity: {
      id: 'suitability-questionnaire.household-income-over-300k-entity.label',
    },
  },

  OrganizationAssets: {
    id: 'suitability-questionnaire.organization-assets-over-5m.label',
  },

  AssociatedWithFinra: {
    id: 'suitability-questionnaire.associated-with-finra.label',
  },

  FinraMemberNames: {
    id: 'suitability-questionnaire.finra-members-names.label',
  },

  AssociatedWithPublicCompany: {
    id: 'suitability-questionnaire.net-worth.label',
  },

  PublicCompanyTickers: {
    id: 'suitability-questionnaire.public-company-tickers.label',
  },
} as const;
