import { FormattedMessage, useIntl } from 'react-intl';

import { Markdown } from '@arrived/bricks-common';
import { ProductDetailContent, ProductDetailContentProps } from '@arrived/features-product-details';
import { Offering } from '@arrived/models';

export type HowAreDividendsDeterminedProps = ProductDetailContentProps & { offering: Offering };

export const HowAreDividendsDetermined = ({ offering, ...rest }: HowAreDividendsDeterminedProps) => {
  const intl = useIntl();

  return (
    <ProductDetailContent {...rest}>
      <ProductDetailContent.Header>
        <FormattedMessage
          id="product-details.how-are-dividends-determined.header"
          values={{
            offeringName: offering.name,
          }}
        />
      </ProductDetailContent.Header>
      <ProductDetailContent.Body>
        <Markdown Text={ProductDetailContent.Text}>
          {intl.formatMessage({
            id: 'property-details.how-are-dividends-determined.description',
          })}
        </Markdown>
      </ProductDetailContent.Body>
    </ProductDetailContent>
  );
};
