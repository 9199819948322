import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchPropertyFeatureCategory } from '@arrived/api_v2';
import { UseAwaitedMutationOptions } from '@arrived/queries';

import { featureCategoriesQueryKey } from './features.keys';

export function usePatchFeatureCategoryMutation(
  options?: UseAwaitedMutationOptions<typeof patchPropertyFeatureCategory>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchPropertyFeatureCategory,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: featureCategoriesQueryKey });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
