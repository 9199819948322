import { PropsWithChildren, ReactNode } from 'react';

import { FloatingFocusManager, useFloating } from '@floating-ui/react';

import { Stack } from '../../../atoms';
import { useSheetImperativeContext } from '../SheetImperativeContext';

/**
 * The container that displays the content of the sheet. This is the web
 * version that just uses `AnimatePresence` and a few stacks to display the
 * sheet instead of @gorhom/bottom-sheet on native.
 * @see {@link SheetFrame.native.tsx}
 */
export const SheetFrame = ({
  children,
}: PropsWithChildren<{
  /**
   * This is used to render the backdrop for bottom sheet, no relevance for web.
   * @native
   */
  backdropComponent?: ReactNode;
  /**
   * This is used to render the footer for bottom sheet, no relevance for web.
   * @native
   */
  footerComponent?: ReactNode;

  /**
   * Initial snap point index, provide -1 to initiate bottom sheet in closed state.
   *
   * @native
   * @default -1
   */
  index?: number;

  /**
   * Animates the sheet on mount
   *
   * @native
   * @default false
   */
  animateOnMount?: boolean;
}>) => {
  const { open } = useSheetImperativeContext();
  const { context, refs } = useFloating({ open });

  return (
    <FloatingFocusManager context={context}>
      <Stack ref={refs.setFloating}>{children}</Stack>
    </FloatingFocusManager>
  );
};
