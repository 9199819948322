import { PropsWithChildren, ReactNode, useCallback } from 'react';

import { Link } from 'react-router-dom';

import { SvgIconComponent } from '@mui/icons-material/index.d';
import { Box, Paper, PaperProps, Stack, Typography } from '@mui/material';

import { GradientIcon } from '../images';

export interface OptionCardProps extends Omit<PaperProps, 'title'> {
  icon: SvgIconComponent;
  path: string;
  title: ReactNode;
  subTitle: ReactNode;
  external?: boolean;
}

export const OptionCard = ({ icon, path, title, subTitle, external, sx, ...rest }: OptionCardProps) => {
  const Wrapper = useCallback(
    ({ children }: PropsWithChildren) => {
      if (external) {
        return (
          <a style={{ textDecoration: 'none' }} href={path}>
            {children}
          </a>
        );
      } else {
        return (
          <Link style={{ textDecoration: 'none' }} to={path}>
            {children}
          </Link>
        );
      }
    },
    [external, path],
  );

  return (
    <Wrapper>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          height: '100%',
          p: 3,
          '&:hover': {
            boxShadow: ({ shadows }) => shadows[4],
            '.icon-container.MuiStack-root': {
              background: ({ gradients }) => gradients.sea.horizontal,
            },
            '.option-icon': {
              fill: ({ palette }) => palette.neutrals.white,
            },
          },
          ...sx,
        }}
        {...rest}
      >
        <Stack
          className="icon-container"
          bgcolor={({ palette }) => palette.lightened.iceBlue}
          p={3}
          borderRadius="50%"
          justifyContent="center"
          alignItems="center"
        >
          <GradientIcon className="option-icon" Icon={icon} width="30px" height="30px" />
        </Stack>
        <Box>
          <Typography variant="h6.semibold">{title}</Typography>
          <Typography variant="body2">{subTitle}</Typography>
        </Box>
      </Paper>
    </Wrapper>
  );
};
