import { createContext } from 'react';

import { stub } from '@arrived/common';

interface AnalyticsContextType {
  isLoaded: boolean;
  getAnonymousId: () => string | undefined;
}

export const AnalyticsContext = createContext<AnalyticsContextType>({
  isLoaded: false,
  getAnonymousId: stub,
});
