import { CompanyKpis } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getCompanyKpis = () =>
  createQuery<CompanyKpis>({
    apiInstance: api,
    method: 'get',
    url: '/arrived/kpis',
  });
