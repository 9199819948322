/**
 * These are all the sizes supported within the design system, there
 * should be no ambiguity about what size is being used. Each token matches
 * 1:4 with the value it represents. $2 = 8px, $4 = 16px, etc.
 */
export const size = {
  // Note: These keys "must" be strings
  '0': 0,
  '1': 4,
  '2': 8,
  '3': 12,
  '4': 16,
  '5': 20,
  '6': 24,
  '7': 28,
  '8': 32,
  '10': 40,
  '12': 48,
  '15': 60,
  '16': 64,

  /**
   * @default
   */
  true: 16,
} as const;
