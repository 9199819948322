import { ComponentType, Dispatch, PropsWithChildren, SetStateAction, createContext, useContext } from 'react';

import { Resource } from './types';

export interface MainNavContext {
  close: () => void;
  /**
   * To ensure optimal functionality and UX across our platforms, we surface a Resource component
   * wrapper to consumers. The provied wrapper should render the `children` provided to it (they
   * will always be a component rendered as an anchor tag) and handle the application of the props
   * relevant to a Resource.
   *
   * The simplest wrapper would be to clone the children and pass the `link` as the `href` in
   * addition to existing props, however, you can do whatever is needed to optimize links for the
   * platform the component is being consumed in.
   */
  Resource: ComponentType<PropsWithChildren<Omit<Resource, 'label'>>>;
  /**
   * This is somewhat compensating for the fact that we need to have a separate floating element
   * for each of the `NavSection`s that is rendered in the nav. Since we know that we can get into
   * a state in which multiple `NavSection`s are open, we need to keep track of all of them that
   * are open and render a blur view in the background as long as at least one is open.
   */
  setFloatingMenuIds: Dispatch<SetStateAction<string[]>>;
  /**
   * This function should be used by NavItems of type `NavItemType.SECTION` to update the currently
   * selected Section NavItem when in a mobile viewport so that the overlay can be propertly
   * transitioned on the MainNav component.
   */
  setSelectedSectionNavItemKey: Dispatch<SetStateAction<string | null>>;
}

export const MainNavContext = createContext<MainNavContext>({
  close: () => {},
  Resource: () => null,
  setFloatingMenuIds: () => {},
  setSelectedSectionNavItemKey: () => {},
});

export function useMainNavContext() {
  const context = useContext(MainNavContext);

  if (!context) {
    throw new Error('useMainNavContext must be used within a MainNav');
  }

  return context;
}
