import { GlobalStyles } from '@mui/material';

export const AppStyles = (
  <GlobalStyles
    styles={`
  html,body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px; // For REM purposes and to ensure cross-browser 16px setting
  }

  #__next, #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
  }

  a,
  a:visited,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  .root {
    position: relative;

    .max-height {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
    }
  }

  .open-intercom {
    cursor: pointer;
  }

  .notification-success-body {
    background-color: #dff2bf !important;
    color: #4f8a10 !important;
  }

  .notification-error-body {
    background-color: #ffd2d2 !important;
    color: #d8000c !important;
  }

  /* forms */

  .error-message {
    color: #f28379;
  }

  .success-message {
    color: #47ce90;
  }

  .col-empty,
  .col:empty,
  [class^='col-']:empty {
    display: none;
  }

  .line-spacer {
    width: 100%;
    border-bottom: 1px solid #555;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`}
  />
);
