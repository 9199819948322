import { useQuery } from '@tanstack/react-query';

import { getAllTaxDocuments } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { taxDocumentsKeyFn } from './taxDocuments.keys';

export function useGetTaxDocumentsQuery(
  accountId?: AccountId,
  options?: UseAwaitedQueryOptions<typeof getAllTaxDocuments>,
) {
  return useQuery({
    queryKey: taxDocumentsKeyFn(accountId!),
    queryFn: () => getAllTaxDocuments(accountId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}
