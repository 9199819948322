import { useCallback } from 'react';

import { Offering, OfferingStatus } from '@arrived/models';

import { useCanUserTransactEarlyAccess } from '../../users';

export const useIsOfferingForSale = () => {
  const canUserTransactEarlyAccess = useCanUserTransactEarlyAccess();

  return useCallback(
    (offering: Offering) =>
      offering.totalSharesTransacted < offering.totalShares &&
      (offering.status === OfferingStatus.TRANSACT_ALL ||
        (canUserTransactEarlyAccess && offering.status === OfferingStatus.TRANSACT_EARLY_ACCESS)),
    [canUserTransactEarlyAccess],
  );
};
