import { useEffect, useMemo, useState } from 'react';

import { NavItem, NavItemType, NavSection } from '../types';

/**
 * Logic that maintains the key for the currently selected Section NavItem as well as derives the
 * corresponding NavItem from a list of provided NavItems.
 */
export function useSectionNavItemOverlay(primaryItems: NavItem[]) {
  const [selectedSectionNavItemKey, setSelectedSectionNavItemKey] = useState<string | null>(null);

  const selectedSectionNavItem = useMemo(
    () =>
      primaryItems.find(({ key, type }) => key === selectedSectionNavItemKey && type === NavItemType.SECTION) as
        | NavSection
        | undefined,
    [selectedSectionNavItemKey, primaryItems],
  );

  // In the case that the `selectedSectionNavItemKey` gets set, but we can't find a valid Nav Item
  // that matches that key (and is of type `NavItemType.SECTION`) we use this efffec to unset the
  // selected key.
  useEffect(() => {
    if (selectedSectionNavItem == null && selectedSectionNavItemKey != null) {
      setSelectedSectionNavItemKey(null);
    }
  }, [selectedSectionNavItemKey, selectedSectionNavItem]);

  return useMemo(
    () => ({
      selectedSectionNavItemKey,
      setSelectedSectionNavItemKey,
      selectedSectionNavItem,
    }),
    [selectedSectionNavItemKey, selectedSectionNavItem],
  );
}
