export const stockHomeUrl = 'https://www.familyhomeplans.com/varnish-images/plans/56712/56712-b600.jpg';

const defaultPropertyImagePath = '/default-property-image.jpg';

export const Constants = {
  /**
   * @deprecated
   */
  inputMaxWidth: '300px',
  /**
   * @deprecated
   */
  accountCreateBackButtonSpacing: '129px',
  /**
   * @deprecated
   */
  companySiteMobileSpacing: '36px',
  /**
   * @deprecated
   */
  companySiteMobileSpacingMultiplier: 3,

  bullet: String.fromCharCode(8226),

  maxUserFileUploadSizeMB: 10,
  maxPropertyPhotoFileUploadSizeMB: 25,

  maxSupportedAge: 123, // https://www.guinnessworldrecords.com/news/2020/10/the-worlds-oldest-people-and-their-secrets-to-a-long-life-632895#:~:text=Oldest%20person%20(living),verified%20on%2012%20February%202020)
  minSupportedAge: 18,

  arrivedPhoneNumber: 18142774833,
  arrivedPhoneNumberFormatted: '1 (814) 277-4833',
  arrivedNamedPhoneNumber: '1 (814) ARRIVED',
  arrivedAddress: {
    street: '1700 Westlake Ave N, Suite 200,',
    city: 'Seattle, WA 98109',
  },
  arrivedCirculars: 'https://arrived.com/circulars',
  arrivedSupportEmail: 'support@arrived.com',
  arrivedFacebook: 'https://www.facebook.com/arrivedhomes',
  arrivedTwitter: 'https://twitter.com/arrivedhomes',
  arrivedInstagram: 'https://www.instagram.com/arrivedhomes/',
  arrivedLinkedIn: 'https://www.linkedin.com/company/arrivedhomes',
  arrivedReddit: 'https://www.reddit.com/r/arrived/',
  arrivedAffiliate: 'https://arrivedhomes.hasoffers.com/signup', // still used 01/10/2023?
  bbbUrl: 'https://www.bbb.org/us/wa/seattle/profile/real-estate-investing/arrived-homes-llc-1296-1000116569',

  mainUrl: 'https://arrived.com',
  awsCdnUrl: 'https://cdn.arrivedhomes.com',
  awsCdnFontsUrl: 'https://cdn.arrivedhomes.com/fonts',
  awsCdnNewsUrl: 'https://cdn.arrivedhomes.com/about/news',
  awsCdnEmployeesUrl: 'https://cdn.arrivedhomes.com/employees',
  awsCdnLandingUrl: 'https://cdn.arrivedhomes.com/images/landing',
  awsCdnBricksUrl: 'https://cdn.arrivedhomes.com/bricks',
  imageCdnUrl: 'https://images.arrived.com',

  /**
   * Grab the `imageCdnUrl` from the current object
   */
  get bannerCdnUrl() {
    return `${this.imageCdnUrl}/images/banners` as const;
  },

  defaultPropertyImagePath,

  defaultPropertyImageUrl: `https://images.arrived.com${defaultPropertyImagePath}`,

  /**
   * To be used with `getImageKitSource` to get the default Arrived Owners Equity Card image
   */
  defaultArrivedOwnerEquityImage: '/features/portfolio/arrived-owners-thumbnail.jpg',

  // w9PdfCdnUrl: 'https://cdn.arrivedhomes.com/documents/fw9.pdf', // TODO resolve cloud-front cors issues.
  w9PdfUrl: 'https://s3.us-west-2.amazonaws.com/cdn.arrivedhomes.com/documents/fw9.pdf',

  whatIsArrivedVideoId: 581075884,

  arrivedLoadingLogoTransparentDataUri: 'https://cdn.arrivedhomes.com/logos/arrived_loading_logo_colored.gif',
  arrivedLoadingLogoWhiteDataUri: 'https://cdn.arrivedhomes.com/logos/arrived_loading_logo_white.gif',
  arrivedLoadingLogoTransparentUrl: 'https://images.arrivedhomes.com/animated_logo_transparent_nGIriCSPW.gif',

  // Offerings
  numberOfTrendingOfferings: 6,

  /**
   * @deprecated
   */
  propertyBubbleInset: '4.75%',
  /**
   * @deprecated
   */
  propertyFilterHeight: '64px',

  breezyHomepage: 'https://arrived.breezy.hr',

  // Documents
  treasuryRegLink:
    'https://s3.us-west-2.amazonaws.com/cdn.arrivedhomes.com/documents/REIT+Letter+Treasure+Reg+Supplement.pdf',
  videoLink: 'https://cdn.arrivedhomes.com/about/arrived_video.mp4',

  // This classname is important and triggers a playbook via Intercom
  notifyMeButtonClassName: 'notify-me-button-intercom',

  personaQuestionnaireId: 'c0a1fa8e-80ea-4d5f-a78e-cd0945b574f2',

  equityFinancingAmount: 40_000_000,
} as const;

export const DEFAULT_PHONE_NUMBER_COUNTRY_CODE = 'US';
export const US_DATE_FORMAT = 'P';
export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const CONFIRM_TEXT = 'Confirm';

export const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const HTMLHeadConstants = {
  title: 'Arrived | Easily Invest in Real Estate',
  description:
    'Arrived is a platform for easily investing in Real Estate, starting from $100. Invest in rental properties, earn passive income, and let Arrived take care of the rest',
  imageUrl: `${Constants.imageCdnUrl}/arrived_share-2.png`,
  keywords: ['arrived', 'rent', 'home-ownership', 'equity', 'investment'],
} as const;

export const OFFERING_CONSTANTS = {
  DEFAULT_SELECTED_SHARES: 250,
  DEFAULT_SHARE_PRICE: 10,
  MIN_SHARES_PRICE: 100,
  SHARE_SELECTOR_VALUES: [10, 100, 250, 500, 1000],
  SHARES_TO_BUY_STEP_VALUE: 10,
} as const;

/**
 * Dividend date to start showing next dividend date
 */
export const VALID_DIVIDEND_DATE = new Date(2023, 0, 11);
/**
 * Dividend date to display on portfolio table for next dividend
 */
export const NEXT_DIVIDEND_DATE = new Date(2023, 12, 31);
