import { PropsWithChildren } from 'react';
import { Platform, StyleSheet, ViewProps } from 'react-native';

import { GestureHandlerRootView as NativeGestureHandlerRootView } from 'react-native-gesture-handler';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  // @ts-expect-error -- technically display block isn't real. but it is!
  web: {
    ...Platform.select({
      web: {
        display: 'contents',
      },
    }),
  },
});

export const GestureHandlerRootView = (props: PropsWithChildren<ViewProps>) => (
  <NativeGestureHandlerRootView {...props} style={[styles.container, styles.web, props.style]} />
);
