import { Stack, styled } from '@arrived/bricks';

import { VIDEO_TITLE_FRAME_NAME } from './constants';

export const VideoTitleFrame = styled(Stack, {
  name: VIDEO_TITLE_FRAME_NAME,

  gap: '$1',
  flex: 1,
  py: '$2',
});
