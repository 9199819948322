import { FC } from 'react';

import { GetProps, IconProps, Stack, UtilityText, styled } from '@arrived/bricks';

export const GraphicText = styled(UtilityText, {
  token: 'utility.helper.medium',
});

const GraphicFrame = styled(Stack, {
  gap: '$1',
  flexDirection: 'row',
  alignItems: 'center',
});

export const GraphicItem = GraphicFrame.styleable<GetProps<typeof GraphicFrame> & { Icon: FC<IconProps> }>(
  ({ children, Icon, ...rest }, ref) => (
    <GraphicFrame ref={ref} {...rest}>
      <Icon />
      <GraphicText>{children}</GraphicText>
    </GraphicFrame>
  ),
);
