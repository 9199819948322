import {
  SizeTokens,
  SizeVariantSpreadFunction,
  StackProps,
  VariantSpreadExtras,
  getVariableValue,
  isAndroid,
  isVariable,
} from '@tamagui/core';

/**
 * Get the elevation based on a provided size.
 *
 * @param size The size value.
 * @param extras Additional properties including tokens.
 * @returns Elevation properties.
 */
export const getElevation: SizeVariantSpreadFunction<StackProps> = (size, extras) => {
  if (!size) {
    return;
  }

  const { tokens } = extras;

  // Fetch the token based on the provided size.
  // @ts-ignore
  const token = tokens.size[size];

  // If the token is a variable, use its value. Otherwise, use the size itself.
  const sizeNum = (isVariable(token) ? +token.val : size) as number;

  return getSizedElevation(sizeNum, extras);
};

/**
 * Calculate shadow properties for the given value.
 *
 * @param val The size token or a number or a boolean to determine shadow.
 * @param tokens The set of tokens available.
 * @returns Shadow properties.
 */
export const getSizedElevation = (val: SizeTokens | number | boolean, { tokens }: VariantSpreadExtras<unknown>) => {
  let num = 0;

  // If val is 'true', then retrieve the size variable value.
  if (val === true) {
    const val = getVariableValue(tokens.size['true']);

    if (typeof val === 'number') {
      num = val;
    } else {
      num = 10;
    }
  } else {
    num = +val;
  }

  // Calculate shadow properties based on the derived number.
  const height = Math.round(num / 4 + 1);
  const shadowRadius = Math.round(num / 2 + 2);

  return {
    shadowColor: 'rgba(0,0,0,0.1)', // TODO: add a shadow color token in theme
    shadowRadius,
    shadowOffset: { height, width: 0 },
    ...(isAndroid
      ? {
          // Double the elevation for Android.
          elevationAndroid: 2 * height,
        }
      : {}),
  };
};
