import { useQuery } from '@tanstack/react-query';

import { Constants } from '@arrived/common';
import { Position } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { openPositionsQueryKey } from './positions.keys';

const getOpenPositions = async (): Promise<Position[]> => {
  const response = await fetch(`${Constants.breezyHomepage}/json`);
  return response.json();
};

export const useOpenPositionsQuery = (options?: UseAwaitedQueryOptions<typeof getOpenPositions>) =>
  useQuery({
    queryKey: openPositionsQueryKey,
    queryFn: () => getOpenPositions(),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
