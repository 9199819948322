import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchPropertyFeature } from '@arrived/api_v2';
import { UseAwaitedMutationOptions } from '@arrived/queries';

import { featuresQueryKey, featuresQueryKeyFn } from './features.keys';

export function usePatchFeatureMutation(options?: UseAwaitedMutationOptions<typeof patchPropertyFeature>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchPropertyFeature,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(featuresQueryKeyFn(data.id), data);

      queryClient.invalidateQueries({ queryKey: featuresQueryKey });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
