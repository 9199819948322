import { Fragment, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import _filter from 'lodash/filter';
import _isNil from 'lodash/isNil';

import {
  DividendPaymentIcon,
  Divider,
  GetProps,
  Stack,
  UtilityText,
  parseRootChildren,
  styled,
  useProps,
  withStaticProperties,
} from '@arrived/bricks';
import { OfferingTransactState, UserOfferingTransactState } from '@arrived/common';
import { useInvestmentProductType, useOfferingTransactState } from '@arrived/hooks';
import { InvestmentProductType, Offering, OfferingType, SecurityType } from '@arrived/models';

import { PRODUCT_WIDGET_BUTTON_NAME, PRODUCT_WIDGET_INNER_FRAME_NAME, PRODUCT_WIDGET_NAME } from './constants';
import { Funded } from './Funded';
import { Investors } from './Investors';
import { LoansDataPoint } from './LoansDataPoint';
import { PrimaryDataPoint } from './primaryDataPoint';
import { ProductWidgetButton } from './ProductWidgetButton';
import { ProductWidgetContext } from './ProductWidgetContext';
import { ProductWidgetStyledContext } from './ProductWidgetStyledContext';
// TODO: Can use this component potentially once deposits are enabled again. 02/22/2024
// import { ProductWidgetWalletBalance } from './ProductWidgetWalletBalance';
import { Progress } from './Progress';
import { PropertiesDataPoint } from './PropertiesDataPoint';
import { ProductWidgetButtonCTATestVariant, useProductWidgetButtonCTATest } from './useProductWidgetButtonCTATest';

const ConfidentialText = styled(UtilityText, {
  token: 'utility.helper.medium',
});

export type ProductWidgetProps = Omit<GetProps<typeof ProductWidgetFrame>, 'status'> & {
  offering: Offering;
  userOfferingTransactState?: UserOfferingTransactState;
};

const ProductWidgetFrame = styled(Stack, {
  name: PRODUCT_WIDGET_NAME,
  context: ProductWidgetStyledContext,

  variants: {
    condensed: {
      true: {},
      false: {
        borderWidth: '$0.25',
        borderRadius: '$2',
        p: '$6',
        pt: '$8',
      },
    },
    status: {
      [OfferingTransactState.PRE]: {
        borderColor: '$onSurface.neutral.outline',
        backgroundColor: '$onSurface.neutral.zebraAlt',
      },
      [OfferingTransactState.CLOSED]: {
        borderColor: '$surface.positive.default',
        backgroundColor: '$surface.positive.defaultAlt',
        color: '$onSurface.neutral.default',
      },
      [OfferingTransactState.IN_PROGRESS]: {
        backgroundColor: '$surface.primary.default',
        color: '$interactive.primary.rested',
      },
    },
  } as const,
  defaultVariants: {
    status: OfferingTransactState.PRE,
    condensed: false,
  },
});

const ProductWidgetInnerFrame = styled(Stack, {
  name: PRODUCT_WIDGET_INNER_FRAME_NAME,
  context: ProductWidgetStyledContext,

  variants: {
    condensed: {
      true: {
        p: '$6',
        gap: '$4',
      },
      false: {
        gap: '$6',
      },
    },
  } as const,
  defaultVariants: {
    condensed: false,
  },
});

export const ProductWidget = withStaticProperties(
  ProductWidgetFrame.styleable<ProductWidgetProps>(({ offering, ...props }, ref) => {
    const { children: childrenIn, condensed, userOfferingTransactState, ...rest } = useProps(props);

    const investmentProductType = useInvestmentProductType(offering);
    const ctaTestVariant = useProductWidgetButtonCTATest(userOfferingTransactState);

    const { [PRODUCT_WIDGET_BUTTON_NAME]: Button } = useMemo(
      () => parseRootChildren(childrenIn, [PRODUCT_WIDGET_BUTTON_NAME]),
      [childrenIn],
    );

    const offeringTransactState = useOfferingTransactState(offering);

    const ProgressBar = useMemo(() => {
      if (
        investmentProductType &&
        !(
          [
            InvestmentProductType.PRIVATE_CREDIT_FUND,
            InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND,
          ] as InvestmentProductType[]
        ).includes(investmentProductType) &&
        offeringTransactState !== OfferingTransactState.PRE
      ) {
        return <Progress />;
      } else {
        return null;
      }
    }, [investmentProductType, offeringTransactState]);

    const DataPoints = useMemo(
      () => (
        <Stack alignItems="center" row gap="$4">
          {investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND ? (
            <PropertiesDataPoint />
          ) : investmentProductType === InvestmentProductType.PRIVATE_CREDIT_FUND ? (
            <LoansDataPoint />
          ) : (
            <Funded />
          )}

          <Divider.Vertical solid h="$8" />
          <Investors />
        </Stack>
      ),
      [investmentProductType],
    );

    const Bottom = useMemo(() => {
      let content = null;

      switch (offeringTransactState) {
        case OfferingTransactState.PRE:
          break;
        case OfferingTransactState.CLOSED:
        case OfferingTransactState.IN_PROGRESS:
          if (!condensed) {
            content = (
              <Stack gap="$4">
                <Divider pb="$2" />
                {DataPoints}
                {ProgressBar}
              </Stack>
            );
          }
          break;
      }

      // If there's no content from above AND the security type of the Offering is NOT Reg D then
      // we should return `null` since this section will be empty.
      if (content == null && offering.securityType !== SecurityType.REG_D) {
        return null;
      }

      return (
        <Stack gap="$4">
          {content}
          {offering.securityType === SecurityType.REG_D && (
            <Stack maxWidth={condensed ? undefined : 300}>
              <ConfidentialText>
                <FormattedMessage
                  id="invest.reg-d-confidential"
                  values={{ strong: (parts) => <ConfidentialText fontWeight="600">{parts}</ConfidentialText> }}
                />
              </ConfidentialText>
            </Stack>
          )}
        </Stack>
      );
    }, [DataPoints, ProgressBar, condensed, offering.securityType, offeringTransactState]);

    const Middle = useMemo(() => {
      switch (offeringTransactState) {
        case OfferingTransactState.CLOSED:
          return null;
        case OfferingTransactState.PRE:
          return <Stack width="100%">{Button}</Stack>;
        case OfferingTransactState.IN_PROGRESS:
          return (
            <Stack width="100%" gap="$3">
              {Button}
              {/*
                We're only showing the test variant here because it should only be showing when
                the Offering is in a transact state, additionally the ctaTestVariant value will
                only be VARIANT when the userOfferingTransactState is in TRANSACT.
                */}
              {ctaTestVariant === ProductWidgetButtonCTATestVariant.VARIANT && (
                <Stack alignSelf="center" gap="$2" row alignItems="center">
                  <DividendPaymentIcon size="sm" />
                  <UtilityText token="utility.helper.medium">
                    <FormattedMessage id="product-details.cta.reinvest-dividends" />
                  </UtilityText>
                </Stack>
              )}
            </Stack>
          );
      }
    }, [offeringTransactState, Button, ctaTestVariant]);

    const Top = useMemo(() => {
      switch (offeringTransactState) {
        case OfferingTransactState.PRE:
          const isFund = offering.type === OfferingType.FUND;

          return (
            <Stack gap="$4" row justifyContent="space-between">
              {!isFund && <PrimaryDataPoint />}
            </Stack>
          );
        case OfferingTransactState.CLOSED:
        case OfferingTransactState.IN_PROGRESS:
          return (
            <Stack gap="$4" row justifyContent="space-between">
              <PrimaryDataPoint />
              {condensed && DataPoints}
            </Stack>
          );
      }
    }, [DataPoints, condensed, offering.type, offeringTransactState]);

    const content = useMemo(() => _filter([Top, Middle, Bottom], (obj) => !_isNil(obj)), [Bottom, Middle, Top]);

    const productWidgetContext = useMemo(() => ({ offering }), [offering]);

    return (
      <ProductWidgetContext.Provider value={productWidgetContext}>
        <ProductWidgetFrame ref={ref} status={offeringTransactState} condensed={condensed} {...rest}>
          {condensed && ProgressBar}
          <ProductWidgetInnerFrame condensed={condensed}>
            {content.map((section, idx) => (
              <Fragment key={idx}>{section}</Fragment>
            ))}
          </ProductWidgetInnerFrame>
        </ProductWidgetFrame>
      </ProductWidgetContext.Provider>
    );
  }),
  {
    ProductWidgetButton,
  },
);
