import { OfferingCid, OfferingPhoto } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';
import { buildMultipartFormData } from './utils';

export interface OfferingPhotosResponse<IncludeUrls extends boolean, IncludePositions extends boolean> {
  offeringCid: string;
  photos: Array<OfferingPhoto<IncludeUrls, IncludePositions>>;
}

export const getOfferingPhotos = <IncludeUrls extends boolean = false, IncludePositions extends boolean = false>(
  offeringCid: OfferingCid,
  options?: { includeUrls?: IncludeUrls; includePositions?: IncludePositions },
) =>
  createQuery<OfferingPhotosResponse<IncludeUrls, IncludePositions>>({
    apiInstance: api,
    method: 'get',
    url: `/offerings/${offeringCid}/photos`,
    config: {
      params: {
        includeUrls: options?.includeUrls,
        includePositions: options?.includePositions,
      },
    },
  });

export interface PostOfferingPhotoRequest {
  file: File;
  position?: number;
}

export const postOfferingPhoto = <IncludeUrls extends boolean = false, IncludePositions extends boolean = false>({
  offeringCid,
  request,
  options,
}: {
  offeringCid: OfferingCid;
  request: PostOfferingPhotoRequest;
  options?: { includeUrls?: IncludeUrls; includePositions?: IncludePositions };
}) => {
  const { body, headers } = buildMultipartFormData(request);

  return createQuery<OfferingPhotosResponse<IncludeUrls, IncludePositions>>({
    apiInstance: api,
    method: 'post',
    url: `/offerings/${offeringCid}/photos`,
    requestData: body,
    config: {
      headers,
      params: {
        includeUrls: options?.includeUrls,
        includePositions: options?.includePositions,
      },
    },
  });
};

export interface PatchOfferingPhotoRequest {
  photoId: number;
  position: number;
}

export const patchOfferingPhoto = <IncludeUrls extends boolean = true, IncludePositions extends boolean = true>({
  offeringCid,
  request,
  options,
}: {
  offeringCid: OfferingCid;
  request: PatchOfferingPhotoRequest;
  options?: { includeUrls?: IncludeUrls; includePositions?: IncludePositions };
}) =>
  createQuery<OfferingPhotosResponse<IncludeUrls, IncludePositions>>({
    apiInstance: api,
    method: 'patch',
    url: `/offerings/${offeringCid}/photos/${request.photoId}`,
    requestData: request,
    config: {
      params: {
        includeUrls: options?.includeUrls,
        includePositions: options?.includePositions,
      },
    },
  });

export const deleteOfferingPhoto = <IncludeUrls extends boolean = false, IncludePositions extends boolean = false>({
  offeringCid,
  photoId,
  options,
}: {
  offeringCid: OfferingCid;
  photoId: number;
  options?: { includeUrls: IncludeUrls; includePositions: IncludePositions };
}) =>
  createQuery<OfferingPhotosResponse<IncludeUrls, IncludePositions>>({
    apiInstance: api,
    method: 'delete',
    url: `/offerings/${offeringCid}/photos/${photoId}`,
    config: {
      params: {
        includeUrls: options?.includeUrls,
        includePositions: options?.includePositions,
      },
    },
  });
