import { useQuery } from '@tanstack/react-query';

import { getHydratedQuestionnaire } from '@arrived/api_v2';
import { QuestionnaireId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { hydratedQuestionnaireKeyFn } from './questionnaire.keys';

export function useGetHydratedQuestionnaire(
  questionnaireId: QuestionnaireId,
  options?: UseAwaitedQueryOptions<typeof getHydratedQuestionnaire>,
) {
  return useQuery({
    queryKey: hydratedQuestionnaireKeyFn(questionnaireId),
    queryFn: () => getHydratedQuestionnaire(questionnaireId),
    staleTime: MAX_STALE_TIME,
    ...options,
  });
}
