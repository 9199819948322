import { useCallback } from 'react';

import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, Radio } from '@mui/material';

import { getUserDocumentDisplayName } from '@arrived/common';
import { UserDocumentType } from '@arrived/models';

export interface TypeSelectListItemProps extends Omit<ListItemButtonProps, 'onClick'> {
  documentType: UserDocumentType;
  setDocumentType: (documentType: UserDocumentType) => void;
}

export const TypeSelectListItem = ({ documentType, setDocumentType, sx, ...rest }: TypeSelectListItemProps) => {
  const handleOnClick = useCallback(() => {
    setDocumentType(documentType);
  }, [documentType]);

  return (
    <ListItemButton
      onClick={handleOnClick}
      sx={{
        border: ({ palette }) => `1px solid ${palette.neutrals.gray}`,
        padding: ({ spacing }) => spacing(1.5, 1),
        ...sx,
      }}
      {...rest}
    >
      <ListItemIcon sx={{ minWidth: 'unset' }}>
        <Radio value={documentType} sx={{ '&:hover': { backgroundColor: 'initial' } }} />
      </ListItemIcon>
      <ListItemText primary={getUserDocumentDisplayName(documentType)} primaryTypographyProps={{ variant: 'body1' }} />
    </ListItemButton>
  );
};
