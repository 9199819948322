import { BreadcrumbCategories } from './BreadcrumbCategories';
import * as SentrySdk from './sdk';

/**
 * Augmented, cross-platform Sentry client.
 */
export const Sentry = {
  /**
   * All of the breadcrumb categories that we use within our apps.
   * @see {@link https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/breadcrumbs | Sentry Docs}
   */
  BreadcrumbCategories,

  addBreadcrumb: SentrySdk.addBreadcrumb,
  addEventProcessor: SentrySdk.addEventProcessor,

  captureException: SentrySdk.captureException,
  captureMessage: SentrySdk.captureMessage,

  setUser: SentrySdk.setUser,
  setTag: SentrySdk.setTag,
  setTags: SentrySdk.setTags,
  setExtra: SentrySdk.setExtra,
  setExtras: SentrySdk.setExtras,
  setContext: SentrySdk.setContext,

  getCurrentScope: SentrySdk.getCurrentScope,
  withScope: SentrySdk.withScope,
};
