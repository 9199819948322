import { ExternalAccountVerificationStatus } from './externalAccount';

export interface FullExternalAccountRequest {
  accountId: number;
  type?: ExternalAccountAccountType;
  accountHolderFullName?: string;
  accountNickname?: string;
  accountRoutingNumber?: string;
  accountNumber?: string;
  updatedIpAddress?: string;
}

export interface PlaidLinkExternalAccountRequest {
  accountId: number;
  publicToken: string; // plaid public token, backend exchanges for access token to make subsequent requests
  plaidAccountId: string; // plaid account id, used to filter accounts and perform validation
  verificationStatus: ExternalAccountVerificationStatus;
}

export enum ExternalAccountAccountType {
  NONE = '',
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}
