import { styled } from '@tamagui/core';

import { Stack } from '../../atoms';

export const MainNavFrame = styled(Stack, {
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 64,
  zIndex: '$sticky',
  row: true,
  bg: '$surface.neutral.default',
  px: '$4',
  gap: '$8',

  $gtXs: {
    height: 80,
    py: '$6',
    px: '$6',
  },
});
