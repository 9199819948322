import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postSignOfferingTransactionDocuments } from '@arrived/api_v2';

import { balanceKeyFn, offeringBalancesKeyFn } from '../accounts';
import { accountTradesQueryKeyFn, tradeQueryKeyFn, userTradesQueryKeyFn } from '../trades';
import { UseAwaitedMutationOptions } from '../utils';

export function usePostSignTradeMutation(
  options?: UseAwaitedMutationOptions<typeof postSignOfferingTransactionDocuments>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postSignOfferingTransactionDocuments,
    ...options,
    onSuccess: (data, variables, context) => {
      const { id: tradeId, cid, accountId, userId } = data;
      queryClient.invalidateQueries({ queryKey: tradeQueryKeyFn(cid) });
      queryClient.invalidateQueries({ queryKey: tradeQueryKeyFn(tradeId) });
      queryClient.invalidateQueries({ queryKey: userTradesQueryKeyFn(userId) });
      queryClient.invalidateQueries({ queryKey: balanceKeyFn(accountId) });
      queryClient.invalidateQueries({ queryKey: offeringBalancesKeyFn(accountId) });
      queryClient.invalidateQueries({ queryKey: accountTradesQueryKeyFn(accountId) });

      options?.onSuccess?.(data, variables, context);
    },
  });
}
