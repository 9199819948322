import { Divider, EqualsIcon, RentalIncomeIcon, RootText, Stack, SubtractIcon, TitleText } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { PrivateCreditFundMetadata } from '@arrived/models';

import { ProductInfoBox } from '../../../../../productInfoBox';
import { Row } from '../../../../../row';

export const AnnualCashFlow = ({ pcfMetadata }: { pcfMetadata: PrivateCreditFundMetadata }) => {
  return (
    <>
      <Stack row>
        <RentalIncomeIcon />
        <TitleText ml="$2" token="title.heading.small">
          {pcfMetadata.anticipatedCashFlow.title}
        </TitleText>
      </Stack>
      <Markdown>{pcfMetadata.anticipatedCashFlow.description}</Markdown>

      <ProductInfoBox p={0}>
        <Row bg="$onSurface.neutral.zebraAlt">
          <Row.Label>{pcfMetadata.anticipatedCashFlow.investmentAmountLabel}</Row.Label>
          <Row.Value variant="bold">{pcfMetadata.anticipatedCashFlow.investmentAmountValue}</Row.Value>
        </Row>
      </ProductInfoBox>

      <Stack>
        <ProductInfoBox p={0} gap={0}>
          <Row>
            <Row.Label>{pcfMetadata.anticipatedCashFlow.netInterestEarnedLabel}</Row.Label>
            <Row.Value>{pcfMetadata.anticipatedCashFlow.netInterestEarnedValue}</Row.Value>
          </Row>
          <Stack>
            <Divider solid={false} w="100%" />
            <Stack
              centered
              position="absolute"
              left="50%"
              transform={[{ translateY: -22 }, { translateX: -22 }]}
              p="$3"
              bg="$onSurface.neutral.zebra"
            >
              <SubtractIcon color="$interactive.neutral.restedInverted" fill="$interactive.neutral.rested" />
            </Stack>
          </Stack>
          <Row>
            <Row.Label tooltip={pcfMetadata.anticipatedCashFlow.arrivedFeesTooltipText} maxWidth={220}>
              <RootText whiteSpace="wrap">{pcfMetadata.anticipatedCashFlow.arrivedFeesLabel}</RootText>
            </Row.Label>
            <Row.Value>{pcfMetadata.anticipatedCashFlow.arrivedFeesValue}</Row.Value>
          </Row>

          <Stack>
            <Divider solid={false} w="100%" />
            <Stack
              centered
              position="absolute"
              left="50%"
              transform={[{ translateY: -22 }, { translateX: -22 }]}
              p="$3"
              bg="$onSurface.neutral.zebra"
            >
              <SubtractIcon color="$interactive.neutral.restedInverted" fill="$interactive.neutral.rested" />
            </Stack>
          </Stack>

          <Row>
            <Row.Label tooltip={pcfMetadata.anticipatedCashFlow.creditLossAccrualTooltipText}>
              {pcfMetadata.anticipatedCashFlow.creditLossAccrualLabel}
            </Row.Label>
            <Row.Value>{pcfMetadata.anticipatedCashFlow.creditLossAccrualValue}</Row.Value>
          </Row>

          <Row bg="$onSurface.neutral.zebraAlt">
            <Row.Label>{pcfMetadata.anticipatedCashFlow.dividendsPaidLabel}</Row.Label>
            <Row.Value variant="bold">{pcfMetadata.anticipatedCashFlow.dividendsPaidValue}</Row.Value>
          </Row>
        </ProductInfoBox>
      </Stack>

      <Stack centered>
        <EqualsIcon color="$interactive.neutral.restedInverted" fill="$interactive.neutral.rested" />
      </Stack>

      <ProductInfoBox p={0} bg="$surface.positive.default">
        <Row>
          <Row.Label tooltip={pcfMetadata.anticipatedCashFlow.anticipatedAnnualCashFlowYieldTooltipText}>
            {pcfMetadata.anticipatedCashFlow.anticipatedAnnualCashFlowYieldLabel}
          </Row.Label>
          <Row.Value variant="bold">{pcfMetadata.anticipatedCashFlow.anticipatedAnnualCashFlowYieldValue}</Row.Value>
        </Row>
      </ProductInfoBox>
    </>
  );
};
