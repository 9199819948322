import { memo } from 'react';

import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function SubtractInternal({ size, color, fill = 'none', ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <G clip-path="url(#clip0_1520_5075)">
        <Path
          d="M-0.00012207 10.0001C-0.00012207 12.6523 1.05345 15.1958 2.92881 17.0712C4.80417 18.9466 7.34771 20.0001 9.99988 20.0001C12.652 20.0001 15.1956 18.9466 17.0709 17.0712C18.9463 15.1958 19.9999 12.6523 19.9999 10.0001C19.9999 7.34796 18.9463 4.80442 17.0709 2.92905C15.1956 1.05369 12.652 0.00012207 9.99988 0.00012207C7.34771 0.00012207 4.80417 1.05369 2.92881 2.92905C1.05345 4.80442 -0.00012207 7.34796 -0.00012207 10.0001Z"
          fill={fill}
        />
        <Path d="M5.83337 10H14.1667" stroke={color} />
      </G>
      <Defs>
        <ClipPath id="clip0_1520_5075">
          <Rect width="20" height="20" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export const SubtractIcon = memo<IconProps>(withIconTheme(SubtractInternal));
