import React from 'react';

import { FieldValues, Path } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { OmitBetter } from '@arrived/models';

import { FormTextField, FormTextFieldProps } from '../../../form';

export const StreetTwoInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
  controllerProps,
  ...textFieldProps
}: OmitBetter<FormTextFieldProps<TFieldValues, TName>, 'autoComplete' | 'label'>) => {
  return (
    <FormTextField
      {...textFieldProps}
      autoComplete="new-password"
      controllerProps={controllerProps}
      label={<FormattedMessage id="onboarding.residential-address.street-2.label" />}
    />
  );
};
