import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function SingleFamilyAltIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <Path
        d="M18 17.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5V9.423a.5.5 0 0 1 .166-.372l7.5-6.75a.5.5 0 0 1 .668 0l7.5 6.75a.5.5 0 0 1 .166.372V17.5Z"
        fill={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const SingleFamilyAltIcon = memo<IconProps>(withIconTheme(SingleFamilyAltIconInternal));
