import {
  FC,
  PropsWithChildren,
  Provider,
  createContext as reactCreateContext,
  useContext as useContextReact,
} from 'react';

/**
 * The goal of this helper is to make creating contexts more consistent. This takes
 * a state of a context and props the provider can have and returns a full context state.
 * This is useful for creating consistent contexts that can be exported fully typed.
 *
 * @use
 * ```ts
 * export const [
 *   NAME_OF_CONTEXT,
 *   NAME_OF_PROVIDER,
 *   NAME_OF_CONTEXT_HOOK,
 * ] = createUseContext({} as INITIAL_STATE, (Provider) => (props: PROPS) => ...)
 * ```
 *
 * A few todos...
 * - Remove the need for nested functions
 * - Adjust where default state is instantiated
 *
 * @param initialState The default value of the context, this is passed to the provider
 * @param createContextProvider Function that passes the Provider as an argument which returns the component
 * @returns Context, Provider, and useContext hook
 */
export const createUseContext = <ContextState extends object, Props>(
  initialState: ContextState,
  createContextProvider: (Provider: Provider<ContextState>) => FC<PropsWithChildren<Props>>,
) => {
  if (typeof createContextProvider !== 'function') {
    throw new Error(`The createContextProvider must be a function, received ${typeof createContextProvider}`);
  }

  const Context = reactCreateContext(initialState);
  const Provider = createContextProvider(Context.Provider);

  const useContext = () => {
    const context = useContextReact(Context);

    if (context === undefined) {
      throw new Error(`useContext must be used within a Provider, this is generated at createUseContext`);
    }

    return context;
  };

  return [Provider, useContext, Context] as const;
};
