import { QueryKey } from '@tanstack/react-query';

import {
  AccountId,
  OfferingTransactionId,
  OfferingTransactionQueryParams,
  TradeBrokerApprovalInfoQuery,
  TradesQueryFilters,
  UserId,
} from '@arrived/models';

export const tradeQueryKey: QueryKey = ['trades'];
export const tradeQueryKeyFn = (
  tradeId?: OfferingTransactionId | string,
  queryParams?: OfferingTransactionQueryParams,
): QueryKey =>
  tradeQueryKey
    .concat([tradeId])
    .concat(queryParams ? Object.entries(queryParams).map(([key, value]) => `${key}:${value}`) : []);

export const userTradesQueryKey: QueryKey = tradeQueryKey.concat(['users']);
export const userTradesQueryKeyFn = (userId: UserId, filters?: Partial<TradesQueryFilters>): QueryKey =>
  userTradesQueryKey.concat(userId).concat(filters ? Object.entries(filters).map(([key, val]) => `${key}:${val}`) : []);

export const userTradeDocumentsQueryKeyFn = (userId: UserId): QueryKey =>
  (userTradesQueryKeyFn(userId) as readonly unknown[]).concat(['documents']);

export const accountTradesQueryKey: QueryKey = tradeQueryKey.concat(['accounts']);
export const accountTradesQueryKeyFn = (accountId: AccountId, filters?: TradesQueryFilters): QueryKey =>
  accountTradesQueryKey
    .concat(accountId)
    .concat(filters ? Object.entries(filters).map(([key, val]) => `${key}:${val}`) : []);

export const tradeDocumentsQueryKeyFn = (tradeId: OfferingTransactionId): QueryKey =>
  tradeQueryKey.concat([tradeId, 'documents']);
export const tradeDocumentsPdfQueryKeyFn = (tradeId: OfferingTransactionId): QueryKey =>
  tradeQueryKey.concat([tradeId, 'documents', 'raw']);

export const tradeFundStatusesQueryKeyFn = (tradeId: OfferingTransactionId): QueryKey =>
  tradeQueryKey.concat([tradeId, 'fund-statuses']);

export const tradeBrokerApprovalInfoQueryKey = tradeQueryKey.concat(['brokerApprovalInfo']);

export const tradeBrokerApprovalInfoTradesQueryKey = tradeBrokerApprovalInfoQueryKey.concat(['trades']);
export const tradeBrokerApprovalInfoTradesQueryKeyFn = (filters?: TradeBrokerApprovalInfoQuery): QueryKey =>
  tradeBrokerApprovalInfoTradesQueryKey.concat(
    filters ? Object.entries(filters).map(([key, val]) => `${key}:${val}`) : [],
  );

export const tradeBrokerApprovalInfoFiltersQueryKey = tradeBrokerApprovalInfoQueryKey.concat(['activeFilters']);
