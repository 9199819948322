import { OfferingStatus } from '@arrived/models';

export const OfferingConstants = {
  DEBT_INTEREST_PERCENT: 3.875,
  HOLD_PERIOD_YEARS_MIN: 5,
  HOLD_PERIOD_YEARS_MAX: 7,
};

export const shouldHideInvestorCount = (offeringStatus: OfferingStatus, canUserTransactEarlyAccess?: boolean) => {
  return (
    offeringStatus === OfferingStatus.PRE_LAUNCH ||
    offeringStatus === OfferingStatus.COMING_SOON ||
    (offeringStatus === OfferingStatus.TRANSACT_EARLY_ACCESS && !canUserTransactEarlyAccess)
  );
};

/**
 * These two values are respectively the thresholds at which we classify a low/average volatility Offering based on the
 * debt percent/leverage of the offering.
 */
export enum DEBT_PERCENT_VOLATILITY_THRESHOLDS {
  LOW = 40,
  AVERAGE = 61,
}
