import { useId, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { GetProps, composeEventHandlers, styled } from '@tamagui/core';

import { BodyText, Divider, Stack, UtilityText } from '../../../atoms';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../icons';
import { Button } from '../../../molecules';
import { AnimateHeight } from '../../../utils';
import { useMainNavContext } from '../MainNavContext';
import { NavDropdown } from '../navDropdown';
import { NavItem as NavItemDisplay, NavItemProps as NavItemDisplayProps } from '../navItem';
import { Group, NavSection } from '../types';

import { SectionOverlayContext, useSectionOverlayContext } from './SectionOverlayContext';

const SectionOverlayFrame = styled(Stack, {
  position: 'absolute',
  top: 0,
  left: '100%',
  height: '100%',
  width: '100%',
  bg: '$onSurface.neutral.defaultInverted',
  animation: 'slideout',
  opacity: 0,
  overflow: 'auto' as 'scroll',

  variants: {
    active: {
      true: {
        left: 0,
        opacity: 1,
      },
    },
  } as const,
  defaultVariants: {
    active: false,
  },
});

const ArrowLeft = styled(ArrowLeftIcon, {
  animation: 'quick',

  color: 'currentColor',

  '$group-NavItem-press': {
    // @ts-ignore color isn't recognized... but it works!
    color: '$onSurface.neutral.defaultInverted',
  },
});

export type SectionOverlayProps = Omit<GetProps<typeof SectionOverlayFrame>, 'active'> & {
  selectedSectionNavItem?: NavSection;
};

const ContentText = styled(BodyText, { token: 'body.default.large' });

const OverlayNavItemContent = ({ isOpen, group }: { isOpen?: boolean; group: Group }) => {
  const { close, Resource } = useMainNavContext();

  const {
    resource: { linkText, label, ...rest },
  } = group;

  const Content = group.Content;

  return (
    <AnimateHeight expand={isOpen}>
      <Stack bg="$onSurface.neutral.zebraAlt" py="$6" px="$4" gap="$6">
        <Stack gap="$4">
          <Stack gap="$2">
            <Content Text={ContentText} />
          </Stack>
          <Resource {...rest}>
            <Button
              Icon={ArrowRightIcon}
              tabIndex={isOpen ? 0 : -1}
              alignSelf="flex-start"
              variant="outlined"
              tag="a"
              onPress={close}
            >
              {linkText ?? label}
            </Button>
          </Resource>
        </Stack>
        <Divider />
        {group.resourceLists.map(({ header, resources }, idx) => (
          <Stack key={idx} gap="$4">
            <UtilityText token="utility.label.xxsmall" textTransform="uppercase">
              {header}
            </UtilityText>
            <Stack gap="$2">
              {resources.map(({ label, ...rest }, idx) => (
                <Resource key={idx} {...rest}>
                  <UtilityText tabIndex={isOpen ? 0 : -1} tag="a" token="utility.hyperlink.small" onPress={close}>
                    {label}
                  </UtilityText>
                </Resource>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </AnimateHeight>
  );
};

type SectionOverlayItemProps = Omit<NavItemDisplayProps, 'group'> & { group: Group };

const SectionOverlayItem = ({ group, onPress, ...rest }: SectionOverlayItemProps) => {
  const id = useId();
  const { openedId, setOpenedId } = useSectionOverlayContext();

  const composedOnPress = useMemo(
    () => composeEventHandlers(onPress, () => setOpenedId((currentId) => (currentId === id ? null : id))),
    [onPress],
  );

  return (
    <>
      <NavItemDisplay onPress={composedOnPress} {...rest}>
        <Stack row alignItems="center" gap="$2">
          <NavItemDisplay.Text>{group.resource.label}</NavItemDisplay.Text>
          {group.adornment}
        </Stack>
        <NavDropdown.Icon open={openedId === id} />
      </NavItemDisplay>
      <OverlayNavItemContent isOpen={openedId === id} group={group} />
    </>
  );
};

export const SectionOverlay = SectionOverlayFrame.styleable<SectionOverlayProps>(
  ({ selectedSectionNavItem, ...rest }, ref) => {
    const { setSelectedSectionNavItemKey } = useMainNavContext();
    const [openedId, setOpenedId] = useState<string | null>(null);

    const sectionOverlayContext = useMemo(() => ({ openedId, setOpenedId }), [openedId]);

    return (
      <SectionOverlayContext.Provider value={sectionOverlayContext}>
        <SectionOverlayFrame ref={ref} active={selectedSectionNavItem != null} {...rest}>
          <NavItemDisplay
            onPress={() => setSelectedSectionNavItemKey(null)}
            justifyContent="flex-start"
            focusable={selectedSectionNavItem != null}
          >
            <ArrowLeft />
            <NavItemDisplay.Text>
              <FormattedMessage id="common.back" />
            </NavItemDisplay.Text>
          </NavItemDisplay>
          <Divider solid alt />
          {selectedSectionNavItem?.groups.map((item, idx) => <SectionOverlayItem key={idx} group={item} />)}
          {selectedSectionNavItem?.Highlight && (
            <Stack p="$4" mt="auto">
              <selectedSectionNavItem.Highlight />
            </Stack>
          )}
        </SectionOverlayFrame>
      </SectionOverlayContext.Provider>
    );
  },
);
