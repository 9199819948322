import { Offering, Property } from '@arrived/models';

import { Constants } from '../constants';

export const getPropertyPrimaryPhoto = (property: Property): string => {
  if (property == null) {
    return Constants.defaultPropertyImageUrl;
  }

  if (property.photos != null && property.photos.length > 0) {
    return property.photos[0].url;
  }

  return Constants.defaultPropertyImageUrl;
};

export const getBathroomCount = (property: Property): number => {
  return Number((property.fullBathrooms || 0) + (property.halfBathrooms || 0) / 2.0);
};

export const isRentInfoSectionEnabled = (offering: Offering) => {
  const firstDividend = offering?.projectedFirstDividendAmount;
  const firstDividendDate = offering?.projectedFirstDividendDate;
  const firstDividendYield = offering?.projectedAnnualDividendYield;
  return Boolean(firstDividend || firstDividendDate || firstDividendYield);
};
