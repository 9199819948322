import { useContext, useEffect } from 'react';

import { SubscribeFooterContext, SubscribeFooterCustomProps } from './SubscribeFooterWrapper';

export interface UseSubscribeFooterOptions {
  /**
   * Indicates whether or not the footer should be present on the page, defaults to true.
   */
  isPresent?: boolean;
  /**
   * Custom props to set on the footer.
   */
  customProps?: SubscribeFooterCustomProps;
}

export function useSubscribeFooter(options?: UseSubscribeFooterOptions) {
  const { isShowing, setCustomProps, setIsOnPage } = useContext(SubscribeFooterContext);

  useEffect(() => {
    if (options?.isPresent ?? true) {
      setIsOnPage(true);
    }

    return () => setIsOnPage(false);
  }, [options?.isPresent]);

  useEffect(() => {
    if (options?.customProps) {
      setCustomProps(options.customProps);
    }

    return () => setCustomProps(undefined);
  }, [options?.customProps]);

  return isShowing;
}
