export type UserEmail = string;

export interface EmailVerificationStatusResponse {
  isVerified: boolean;
}

export interface EmailVerificationResendResponse {
  status: EMAIL_VERIFICATION_RESEND_STATUS;
}

export enum EMAIL_VERIFICATION_RESEND_STATUS {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export interface EmailVerificationOverrideResponse {
  status: EMAIL_VERIFICATION_OVERRIDE_STATUS;
}

export enum EMAIL_VERIFICATION_OVERRIDE_STATUS {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}
