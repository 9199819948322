import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function WarningIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <Path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={color}
        strokeWidth={1.5}
      />
      <Path d="M12 7V13" stroke={color} strokeWidth={1.5} />
      <Path d="M12 15V17" stroke={color} strokeWidth={1.5} />
    </Svg>
  );
}

export const WarningIcon = memo<IconProps>(withIconTheme(WarningIconInternal));
