import * as yup from 'yup';

import { UserDocumentType } from '@arrived/models';

export const UploadIdentificationDocumentSchema = yup.object({
  file: yup.mixed().required('account.document-upload.document.required').nullable(), // Needs to be nullable for refreshing the state
  userId: yup.number(),
  type: yup
    .mixed<UserDocumentType>()
    .oneOf(Object.values(UserDocumentType))
    .required('account.document-upload.documentType.required'),
});

export type UploadIdentificationDocumentSchemaType = yup.InferType<typeof UploadIdentificationDocumentSchema>;
