import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function CheckmarkCircleIconInteral({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" {...rest}>
      <Path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z" stroke={color} strokeWidth={1.25} />
      <Path d="M6.042 10.625l2.916 2.5 5-6.25" stroke={color} strokeWidth={1.25} />
    </Svg>
  );
}

export const CheckmarkCircleIcon = memo<IconProps>(withIconTheme(CheckmarkCircleIconInteral));
