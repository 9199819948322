import { Rewards } from '@arrived/models';

import { rewardsApi } from '../api';
import { createQuery } from '../create-query';

export const getPromotionRedemption = (promotionUuid: string) =>
  createQuery<Rewards.PromotionRedemption>({
    apiInstance: rewardsApi,
    method: 'get',
    url: `/promotion-redemptions/${promotionUuid}`,
  });

export const postCancelPromotionRedemption = (
  promotionUuid: string,
  requestData?: Rewards.PromotionCancelRedemptionRequest,
) =>
  createQuery<Rewards.PromotionRedemption>({
    apiInstance: rewardsApi,
    method: 'post',
    url: `/promotion-redemptions/${promotionUuid}/cancel`,
    requestData,
  });

export const postPromotionRedemption = (requestData: Rewards.PromotionRedemptionRequest) =>
  createQuery<Rewards.PromotionRedemption>({
    apiInstance: rewardsApi,
    method: 'post',
    url: `/promotion-redemptions`,
    requestData,
  });

export const postPromotionRedemptionReapply = ({
  promotionUuid,
  ...requestData
}: Rewards.PromotionReapplyRedemptionRequest & {
  promotionUuid: string;
}) =>
  createQuery<Rewards.PromotionRedemption>({
    apiInstance: rewardsApi,
    method: 'post',
    url: `/promotion-redemptions/${promotionUuid}/reapply`,
    requestData,
  });

export const postPromotionRedemptionCancel = ({
  promotionUuid,
  ...requestData
}: Rewards.PromotionCancelRedemptionRequest & {
  promotionUuid: string;
}) =>
  createQuery<Rewards.PromotionRedemption>({
    apiInstance: rewardsApi,
    method: 'post',
    url: `/promotion-redemptions/${promotionUuid}/cancel`,
    requestData,
  });
