import {
  AccountCid,
  AccountId,
  AssetType,
  InvestorArchetype,
  Offering,
  OfferingId,
  OfferingSearchQuery,
  Webinar,
} from '@arrived/models';

import { EVENTS } from './Events';

export enum EventSource {
  MOBILE = 'mobile',
  WEB = 'web',
}

export type EventProperties = {
  [EVENTS.DOCUMENTS_SIGNED]: DocumentSignedEventProperties;
  [EVENTS.ONBOARDING_STEP_COMPLETED]: OnboardingCompletedEventProperties;
  [EVENTS.CTA_CLICKED]: CTAClickedEventProperties;
  [EVENTS.ACCOUNT_CREATED]: AccountCreateEventProperties;
  [EVENTS.PERSONA_QUESTIONNAIRE_STARTED]: undefined;
  [EVENTS.PROPERTY_VIEWED]: PropertyViewedEventProperties;
  [EVENTS.PORTFOLIO_PAGE_VIEWED]: PortfolioPageViewedEventProperties;
  [EVENTS.BROKERAGE_ACCOUNT_CREATED]: BrokerageAccountCreatedEventProperties;
  [EVENTS.OFFERING_FEATURED_FILTER_CLICKED]: OfferingFeaturedFilterClickedEventProperties;
  [EVENTS.OFFERING_FILTER_CLICKED]: OfferingFilterClickedEventProperties;
  [EVENTS.OFFERING_FILTER_STATE]: OfferingSearchQuery;
  [EVENTS.OFFERING_HISTORY_CLICKED]: OfferingHistoryClickedEventProperties;
  [EVENTS.BANK_LINK]: BankLinkEventProperties;
  [EVENTS.PLAID_BANK_LINKED]: PlaidBankLinkEventProperties;
  [EVENTS.BANK_LINK_EVENT_DETAILS]: BankLinkEventDetailsProperties;
  [EVENTS.EMAIL_SUBSCRIPTION]: EmailSubscriptionEventProperties;
  [EVENTS.EXPERIMENT_VIEWED]: ExperimentViewedEventProperties;
  [EVENTS.OFFERING_VIDEO_WATCHED]: OfferingVideoWatchedEventProperties;
  [EVENTS.LEAD_FORM_COMPLETED]: LeadFormCompleted;
  [EVENTS.DEEP_LINK]: DeepLinkEventProperties;
  [EVENTS.NOT_FOUND]: NotFoundEventProperties;
  [EVENTS.SIGNUP_FORM_DISPLAYED]: SignupFormDisplayedEventProperties;
  [EVENTS.ONBOARDING_STEP_COMPLETED_MOBILE]: OnboardingCompletedEventProperties;
  [EVENTS.INTENDED_INVESTMENT_AMOUNT_PROVIDED]: IntendedInvestmentAmountEventProperties;
  [EVENTS.WEBINAR_REGISTRATION]: WebinarRegistrationProperties;
  [EVENTS.ACCREDITATION_UPDATE_SHEET_FIRED]: AccreditationUpdateSheetFiredEventProperties;
  [EVENTS.ATT_TRACKING_RESPONSE]: ATTPromptEventProperties;
  [EVENTS.RECORDING_BUTTON_CLICKED]: {};
  [EVENTS.BYERS_HOUSE_INTEREST]: ByersHouseInterestEventProperties;
  [EVENTS.ACCREDITED_INVESTOR_IDENTIFIED]: {};
  [EVENTS.REBEL_ARCHETYPE]: {};
  [EVENTS.WSJ_ARCHETYPE]: {};
  [EVENTS.NEWBIE_ARCHETYPE]: {};
  [EVENTS.APP_INSTALL_SHEET_CTA_CLICKED]: {};

  /**
   * Biometric Prompt Events
   */

  [EVENTS.BIOMETRIC_SETTINGS_MODAL_VIEWED]: {
    accountId: AccountId;
    accountCid: AccountCid;
    deviceInformation: DeviceAuthenticationInformation;
  };

  [EVENTS.BIOMETRIC_AUTHENTICATION_POST_AUTHENTICATION_PROMPT_VIEWED]: {
    accountId: AccountId;
    accountCid: AccountCid;
    deviceInformation: DeviceAuthenticationInformation;
  };

  [EVENTS.BIOMETRIC_AUTHENTICATION_POST_AUTHENTICATION_PROMPT_ENABLED]: {
    accountId: AccountId;
    accountCid: AccountCid;
    deviceInformation: DeviceAuthenticationInformation;
  };

  [EVENTS.BIOMETRIC_AUTHENTICATION_POST_AUTHENTICATION_PROMPT_DISMISSED]: {
    accountId: AccountId;
    accountCid: AccountCid;
    deviceInformation: DeviceAuthenticationInformation;
  };

  [EVENTS.BIOMETRIC_AUTHENTICATION_POST_AUTHENTICATION_PROMPT_ERROR]: {
    accountId: AccountId;
    accountCid: AccountCid;
    deviceInformation: DeviceAuthenticationInformation;
    error: unknown;
  };

  [EVENTS.BIOMETRIC_AUTHENTICATION_ENABLED]: {
    accountId: AccountId;
    accountCid: AccountCid;
    deviceInformation: DeviceAuthenticationInformation;
  };

  [EVENTS.BIOMETRIC_AUTHENTICATION_DISABLED]: {
    accountId: AccountId;
    accountCid: AccountCid;
    deviceInformation: DeviceAuthenticationInformation;
  };

  [EVENTS.BIOMETRIC_AUTHENTICATION_ERROR]: {
    accountId: AccountId;
    accountCid: AccountCid;
    deviceInformation: DeviceAuthenticationInformation;
    error: unknown;
  };
};

export interface AccreditationUpdateSheetFiredEventProperties {
  rejectionPageShown: boolean;
}

export interface WebinarRegistrationProperties {
  webinarId: Webinar['id'];
  webinarName: Webinar['name'];
  webinarTimestamp: Webinar['timestamp'];
}

export interface NotFoundEventProperties {
  key?: string;
  path: string;
  name: string;
}

export interface DeepLinkEventProperties {
  url: string;
}

export interface LeadFormCompleted {
  email: string;
  first_name?: string;
  last_name?: string;
  // Unbounce, demio, app landing page, etc.
  lead_form_type: string;
  url: string;
  referral_source: string;
}

export interface OfferingVideoWatchedEventProperties {
  offering_id: OfferingId;
  property_name: Offering['name'];
}

export interface DocumentSignedEventProperties {
  id: number;
  transaction_id: number;
  asset_type: AssetType;
  total_transaction_amount: number;
  revenue: number;
  currency: string;
  property_status: string;
  num_shares_purchased: number;
  property_tag_status: string;
  beds: number;
  baths: number;
  sq_ft: number;
  year_built: number;
  number_of_investors: number;
  property_purchase_price: number;
  monthly_rent: number;
  offering_id: number;
  property_name: string;
  perc_purchased_status: number;
  property_rental_status: string;
  first_dividend_date: string;
  first_dividend_yield: number;
  total_historical_return_min: number;
  total_historical_return_max: number;
  historical_rental_dividends: number;
  property_value_growth: number;
  signing_completion_location: string;
}

interface OnboardingCompletedEventProperties {
  email: string;
  first_name: string;
  last_name: string;
  citizenship: string;
  brokerage_account_type: string;
  address_account: string;
  city_account: string;
  state_account: string;
  zip_account: string;
  date_of_birth: string;
  accredited_selection: string;
  step: string;
  lead_referral_source: string;
  lead_referral_source_other: string;
}

interface CTAClickedEventProperties {
  destination: string;
  location: string;
  text: string;
  type: string;
  url: string;
  short_name?: string;
  offering_id?: number;
}

export enum SignupFormComponent {
  OFFERING_VIEW_LIMIT_POP_UP = 'offering-view-limit-pop-up',
}

export interface SignupFormDisplayedEventProperties {
  component: SignupFormComponent;
  action: string;
}

export interface AccountCreateEventProperties {
  email: string;
  first_name: string;
  last_name: string;
  intended_investment_amount: string;
  how_did_you_hear: string;
  page: string;
  component: string;
  lead_referral_source: string;
  lead_referral_source_other: string;
}

interface PropertyViewedEventProperties {
  asset_type: AssetType;
  property_status: string;
  beds: number;
  baths: number;
  sq_ft: number;
  year_built: number;
  amount_invested: number;
  number_of_investors: number;
  property_purchase_price: number;
  monthly_rent: number;
  offering_id: number;
  offering_name: string;
  perc_purchased_status: number;
  property_rental_status: string;
  first_dividend_date: string;
  first_dividend_yield: number;
  total_historical_return_min: number;
  total_historical_return_max: number;
  historical_rental_dividends: number;
  property_value_growth: number;
}

interface PortfolioPageViewedEventProperties {
  initial_investment: number;
  total_balance: number;
  total_returns: number;
  total_appreciation: number;
  total_dividends: number;
  number_current_properties_owned: number;
  list_current_properties_owned: string;
}

interface BrokerageAccountCreatedEventProperties {
  email: string;
  account_type: string;
}

interface OfferingFeaturedFilterClickedEventProperties {
  feature_name: string;
}

interface OfferingFilterClickedEventProperties {
  filter_name: string;
}

interface EmailSubscriptionEventProperties {
  position: string;
}

export enum BankLinkEventType {
  CREATE = 'create',
  VERIFY = 'verify',
  DELETE = 'delete',
}

interface BankLinkEventProperties {
  update_type: BankLinkEventType;
  connection_type: string;
  verification_status: string;
}

interface PlaidBankLinkEventProperties {
  accountId: AccountId;
}

interface BankLinkEventDetailsProperties {
  accountId: AccountId;
  step: string;
  selectedAuthType?: string;
  location?: string;
}

interface ATTPromptEventProperties {
  status: string;
}

interface ExperimentViewedEventProperties {
  archetype: InvestorArchetype;
  experimentName: string;
  /**
   * Use this for tracking groups in multi-variate tests.
   */
  experimentGroupNumber?: number;
}

interface IntendedInvestmentAmountEventProperties {
  intended_investment_amount: string;
}

interface OfferingHistoryClickedEventProperties {
  offering_cid: string;
  offering_id: number;
  offering_name: string;
  location?: string;
}

interface ByersHouseInterestEventProperties {
  first_name: string;
  last_name: string;
  email: string;
}

/**
 * A general type that represents the return type
 * of the `useDeviceAuthenticationInfo` hook.
 *
 * We are creating an alias of this here due to the circular
 * dependency that exists between the `useDeviceAuthenticationInfo`
 * hook and the `useTrack` hook.
 */
interface DeviceAuthenticationInformation {
  hasHardware: boolean;
  isEnrolled: boolean;
  supportedAuthenticationAlias: unknown[];
  supportedAuthenticationTitle: string;
  supportedAuthenticationTypes: unknown[];
}
