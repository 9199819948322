import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postPromotionRedemptionCancel } from '@arrived/api_v2';

import { promotionsKeyFn, promotionsRedemptionKeyFn } from './rewards.keys';

export function usePostPromotionRedemptionCancelMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postPromotionRedemptionCancel,
    onSuccess: (data) => {
      queryClient.setQueryData(promotionsRedemptionKeyFn(data.uuid), data);
      queryClient.invalidateQueries({ queryKey: promotionsKeyFn() });
    },
  });
}
