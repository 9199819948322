import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function LegalInternal({ size, color, fill = 'none', ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill={fill} {...rest}>
      <Path
        d="M1.66667 15.8334V3.33337H6.66667C7.55073 3.33337 8.39857 3.68456 9.02369 4.30968C9.64882 4.93481 10 5.78265 10 6.66671V17.5C10 17.058 9.82441 16.6341 9.51185 16.3215C9.19929 16.009 8.77537 15.8334 8.33334 15.8334H1.66667Z"
        stroke={color}
        strokeWidth="1.25"
      />
      <Path
        d="M18.3333 15.8334H11.6667C11.2246 15.8334 10.8007 16.009 10.4882 16.3215C10.1756 16.6341 10 17.058 10 17.5V6.66671C10 5.78265 10.3512 4.93481 10.9763 4.30968C11.6014 3.68456 12.4493 3.33337 13.3333 3.33337H18.3333V15.8334Z"
        stroke={color}
        strokeWidth="1.25"
      />
    </Svg>
  );
}

export const LegalIcon = memo<IconProps>(withIconTheme(LegalInternal));
