import { ExternalAccount, ExternalAccountVerificationStatus } from '@arrived/models';

import { ExternalAccountSetupState } from '../../constants';

export function getExternalAccountSetupState(externalAccount?: ExternalAccount) {
  if (externalAccount == null) {
    return ExternalAccountSetupState.LINK_BANK;
  } else if (externalAccount.verified !== ExternalAccountVerificationStatus.VERIFIED) {
    return ExternalAccountSetupState.VERIFY_BANK;
  }

  return ExternalAccountSetupState.VERIFIED;
}
