import { useMemo } from 'react';

import { Property } from '@arrived/models';
import { useGetMarketQuery } from '@arrived/queries';

export function useIPOPropertyLocation(property?: Property) {
  const marketState = useGetMarketQuery(property?.marketId);

  return useMemo(() => {
    return (
      marketState.data?.title ||
      [property?.address?.city, property?.address?.province?.replace('US-', '')]
        .filter((str) => str != null)
        .join(', ') ||
      undefined
    );
  }, [marketState.data?.title, property?.address?.city, property?.address?.province]);
}
