import { User } from 'react-native-auth0';

import jwtDecode, { JwtPayload } from 'jwt-decode';
import camelCase from 'lodash/camelCase';

import type { ArrivedAuth0User } from './types';
import type { IdToken } from '@auth0/auth0-react';

/**
 * All the `snake_case` claims that are part of original ID token
 * when jwt-decode is used. This is not a complete list, but it is
 * directly copied from the original `react-native-auth0` package.
 *
 * @see https://github.com/auth0/react-native-auth0/blob/e1771491969934cc20dd3ed0e8beeb6c5cf70577/src/jwt/utils.ts#L4
 */
export const idTokenNonProfileClaims = new Set([
  'iss',
  'aud',
  'exp',
  'nbf',
  'iat',
  'jti',
  'azp',
  'nonce',
  'auth_time',
  'at_hash',
  'c_hash',
  'acr',
  'amr',
  'sub_jwk',
  'cnf',
  'sip_from_tag',
  'sip_date',
  'sip_callid',
  'sip_cseq_num',
  'sip_via_branch',
  'orig',
  'dest',
  'mky',
  'events',
  'toe',
  'txn',
  'rph',
  'sid',
  'vot',
  'vtm',
]);

export type CrossPlatformIdToken = IdToken & {
  sub: string;
  used_mfa: boolean;
};

export type CustomJwtPayload = JwtPayload & CrossPlatformIdToken;

/**
 * List of all claims that we want to camelCase on the ID Token,
 * this is to match the native auth0 package for cross-platform consistency.
 *
 * @see https://github.com/auth0/react-native-auth0/blob/e1771491969934cc20dd3ed0e8beeb6c5cf70577/src/utils/userConversion.ts#L14
 */
const claimsToCamelize: Array<keyof CrossPlatformIdToken> = [
  'name',
  'exp', // Web only - seconds since epoch, we use this to handle `hasValidCredentials` logic on web
  'given_name',
  'family_name',
  'middle_name',
  'nickname',
  'preferred_username',
  'profile',
  'picture',
  'website',
  'email',
  'email_verified',
  'is_first_login',
  'used_mfa',
  'gender',
  'birthdate',
  'zoneinfo',
  'locale',
  'phone_number',
  'phone_number_verified',
  'address',
  'updated_at',
  'sub',
];

export const convertUser = (payload: CustomJwtPayload | User = {}) =>
  Object.keys(payload).reduce((profile, claim) => {
    if ((claimsToCamelize as string[]).includes(claim)) {
      return {
        ...profile,
        // @ts-ignore -- Union type narrowing is not worth it here as we don't care about whether the type is correct
        [camelCase(claim)]: payload[claim],
      };
    } else if (!idTokenNonProfileClaims.has(claim)) {
      return {
        ...profile,
        // @ts-ignore -- Union type narrowing is not worth it here as we don't care about whether the type is correct
        [claim]: payload[claim],
      };
    } else {
      return profile;
    }
  }, {} as ArrivedAuth0User);

export const getIdTokenProfileClaims = (idToken: string) => {
  const payload = jwtDecode<CustomJwtPayload>(idToken);

  return convertUser(payload);
};
