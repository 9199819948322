import { stringify } from 'qs';

import {
  TradeBrokerApprovalInfo,
  TradeBrokerApprovalInfoQuery,
  UpdateTradeBrokerApprovalStatusRequest,
  UpdateTradeBrokerApprovalStatusResponse,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getTradeBrokerApprovalInfo = (filters: TradeBrokerApprovalInfoQuery) =>
  createQuery<TradeBrokerApprovalInfo[]>({
    apiInstance: api,
    method: 'get',
    url: '/trades/broker-approval-info',
    config: {
      params: filters,
      paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
    },
  });

export const postTradeBrokerApprovalStatus = (requestData: UpdateTradeBrokerApprovalStatusRequest) =>
  createQuery<UpdateTradeBrokerApprovalStatusResponse>({
    apiInstance: api,
    method: 'post',
    url: `/trades/${requestData.tradeCid}/update-broker-approval`,
    requestData: { brokerApprovalStatus: requestData.brokerApprovalStatus },
  });
