import { ComponentType, useCallback } from 'react';

import Map, { Marker, NavigationControl } from 'react-map-gl';

import Circle from '@mui/icons-material/Circle';
import Home from '@mui/icons-material/Home';

import 'mapbox-gl/dist/mapbox-gl.css';

import { CONFIG } from '@arrived/config';

export interface MapboxProps {
  lat: number;
  long: number;
  height: number | string;
  width: number | string;
  zoom?: number;
  markers: { long: number; lat: number }[];
  marker?: ComponentType | 'circle' | 'home';
}

const settings = {
  dragPan: true,
  dragRotate: false,
  scrollZoom: false,
  touchZoom: false,
  touchRotate: false,
  keyboard: false,
  doubleClickZoom: false,
};

const StyledCircle = () => (
  <Circle
    sx={{
      color: 'transparent',
      height: '12px',
      width: '12px',
      background: ({ gradients }) => gradients.sea.horizontal,
      borderRadius: '36px',
    }}
  />
);

export const Mapbox = ({ lat, long, height, width, zoom, markers, marker }: MapboxProps) => {
  const MarkerComponent = useCallback(() => {
    if (marker == null) {
      return <StyledCircle />;
    }

    if (typeof marker !== 'string') {
      const Marker = marker;
      return <Marker />;
    }

    return marker === 'home' ? <Home fontSize="large" transform="translate(-50%, -50%)" /> : <StyledCircle />;
  }, [marker]);

  return (
    <Map
      initialViewState={{
        longitude: long,
        latitude: lat,
        zoom: zoom || 4,
      }}
      style={{ height: height, width: width }}
      mapStyle="mapbox://styles/mapbox/light-v10"
      mapboxAccessToken={CONFIG.mapboxAccessKey}
      {...settings}
    >
      {markers?.map((marker, index) => {
        return (
          <Marker longitude={marker?.long} latitude={marker?.lat} color="red" key={index}>
            <MarkerComponent />
          </Marker>
        );
      })}
      <NavigationControl showCompass={false} />
    </Map>
  );
};
