export const LEAD_REFERRAL_OPTIONS = [
  {
    value: 'newsletter',
    label: 'leadReferralList.newsletter',
  },
  {
    value: 'podcast-spotify-radio',
    label: 'leadReferralList.podcast-spotify-radio',
  },
  {
    value: 'facebook-instagram',
    label: 'leadReferralList.facebook-instagram',
  },
  {
    value: 'benzinga',
    label: 'leadReferralList.benzinga',
  },
  {
    value: 'moneywise',
    label: 'leadReferralList.moneywise',
  },
  {
    value: 'investopedia',
    label: 'leadReferralList.investopedia',
  },
  {
    value: 'biggest-pockets',
    label: 'leadReferralList.biggest-pockets',
  },
  {
    value: 'yahoo-news',
    label: 'leadReferralList.yahoo-news',
  },
  {
    value: 'youtube',
    label: 'leadReferralList.youtube',
  },
  {
    value: 'tiktok',
    label: 'leadReferralList.tiktok',
  },
  {
    value: 'twitter',
    label: 'leadReferralList.twitter',
  },
  {
    value: 'quora',
    label: 'leadReferralList.quora',
  },
  {
    value: 'linkedin',
    label: 'leadReferralList.linkedin',
  },
  {
    value: 'search-engines',
    label: 'leadReferralList.search-engines',
  },
  {
    value: 'friends-family',
    label: 'leadReferralList.friends-family',
  },
  {
    value: 'other',
    label: 'leadReferralList.other',
  },
] as const;
