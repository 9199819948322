import _invert from 'lodash/invert';

import { AccountType } from './accountType';

/**
 * Represent our different "categories" of available account types,
 * each currently is mapped to a account/entity type or has additional
 * sub-type selection options.
 */
export const UmbrellaAccountType = {
  INDIVIDUAL: 'INDIVIDUAL',
  ENTITY: 'ENTITY',
  RETIREMENT: 'RETIREMENT',
} as const;

export type UmbrellaAccountType = (typeof UmbrellaAccountType)[keyof typeof UmbrellaAccountType];

export const UMBRELLA_ACCOUNT_TYPE_TO_ACCOUNT_TYPE = {
  [UmbrellaAccountType.INDIVIDUAL]: AccountType.INDIVIDUAL,
  [UmbrellaAccountType.RETIREMENT]: AccountType.CHECKBOOK_IRA,
  [UmbrellaAccountType.ENTITY]: AccountType.ENTITY,
} as const;

export type UMBRELLA_ACCOUNT_TYPE_TO_ACCOUNT_TYPE = typeof UMBRELLA_ACCOUNT_TYPE_TO_ACCOUNT_TYPE;

// Type guard to ensure that the account type is a valid umbrella account type, it should just be reversed
export const ACCOUNT_TYPE_TO_UMBRELLA_ACCOUNT_TYPE = _invert(UMBRELLA_ACCOUNT_TYPE_TO_ACCOUNT_TYPE) as {
  [K in keyof UMBRELLA_ACCOUNT_TYPE_TO_ACCOUNT_TYPE as UMBRELLA_ACCOUNT_TYPE_TO_ACCOUNT_TYPE[K]]: K;
};

export const isUmbrellaAccountType = <T>(type: T): type is Extract<T, UmbrellaAccountType> =>
  Object.values(UmbrellaAccountType).includes(type as UmbrellaAccountType);

export const isAccountType = <T>(type: T): type is Extract<T, AccountType> =>
  Object.values(AccountType).includes(type as AccountType);
