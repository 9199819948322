import { CSSProperties, useCallback, useRef } from 'react';

import { HeaderGroup, Row, Table as TanTable, flexRender } from '@tanstack/react-table';
import { VirtualItem, VirtualizerOptions, useVirtualizer } from '@tanstack/react-virtual';
import _merge from 'lodash/merge';

import {
  Box,
  BoxProps,
  Table as MuiTable,
  SxProps,
  TableBody,
  TableCell,
  TableHead,
  TableHeadProps,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material';

interface TableNonvirtualProps {
  virtual?: false;
}

interface TableVirtualProps {
  containerClassName?: string;
  height?: BoxProps['height'];
  virtual: true;
}

export type TableComponentProps = TableNonvirtualProps | TableVirtualProps;

export interface TableProps<TData> {
  className?: string;
  cellClassName?: string;
  onRowClick?: (row: Row<TData>) => void;
  table: TanTable<TData>;
  virtualRows?: VirtualItem[];
  virtualOptions?: Partial<VirtualizerOptions<HTMLDivElement, HTMLDivElement>>;
  TableHeadProps?: TableHeadProps;
}

const TableComponent = <D,>({
  className,
  cellClassName,
  table,
  onRowClick,
  virtualRows,
  TableHeadProps,
}: TableProps<D> & TableComponentProps) => {
  const rows = table.getRowModel().rows;

  const Row = useCallback(
    ({ row, index, style, sx }: { row: Row<D>; index: number; style?: CSSProperties; sx?: SxProps<Theme> }) => {
      return (
        <TableRow
          key={`row-${index}`}
          style={_merge({}, style)}
          sx={{ borderBottom: ({ palette }) => `1px solid ${palette.lightened.iceGray}`, ...sx }}
        >
          {row.getVisibleCells().map((cell, cellIdx) => {
            return (
              <TableCell
                className={cellClassName}
                sx={{
                  width: cell.column.getSize(),
                  borderBottom: 'unset',
                }}
                key={`row-${index}-cell-${cellIdx}`}
                onClick={() => onRowClick && onRowClick(row)}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [cellClassName, onRowClick],
  );

  return (
    <MuiTable className={className}>
      <TableHead {...TableHeadProps}>
        {table.getHeaderGroups().map((headerGroup: HeaderGroup<D>, headerGroupIdx) => (
          <TableRow key={headerGroupIdx}>
            {headerGroup.headers.map((header, columnIdx) => {
              const isSorted = header.column.getIsSorted();
              const sortDirection = isSorted ? isSorted : undefined;
              return (
                <TableCell
                  className={cellClassName}
                  variant="head"
                  sx={{
                    width: header.getSize(),
                  }}
                  key={`${headerGroupIdx}-col-${columnIdx}`}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <TableSortLabel hideSortIcon active={Boolean(isSorted)} direction={sortDirection}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {virtualRows
          ? virtualRows.map((virtualRow, index) => (
              <Row
                key={index}
                index={index}
                row={rows[virtualRow.index] as Row<D>}
                sx={{
                  height: virtualRow.size,
                  transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                }}
              />
            ))
          : rows.map((row, index) => <Row key={index} index={index} row={row} />)}
      </TableBody>
    </MuiTable>
  );
};

/**
 * For virtualization to work, vritual-table-container must have a fixed height with overflow allowed
 */
const VirtualTableComponent = <D,>(props: TableProps<D> & TableVirtualProps) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const reactVirtualizer = useVirtualizer({
    count: props.table.getRowModel().rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 65,
    overscan: 10,
    ...props.virtualOptions,
  });

  return (
    <Box ref={tableContainerRef} className={props.containerClassName} height={props.height ?? '500px'} overflow="auto">
      <Box height={reactVirtualizer.getTotalSize()} width="100%" position="relative">
        <TableComponent {...props} virtualRows={reactVirtualizer.getVirtualItems()} />
      </Box>
    </Box>
  );
};

export const Table = <D,>(props: TableProps<D> & TableComponentProps) => {
  if (props.virtual) return <VirtualTableComponent {...props} />;
  return <TableComponent {...props} />;
};
