import { ColorTokens, GetProps, VariantSpreadFunction, styled } from '@tamagui/core';

import { PillContainer } from '../Pill';

import { TAG_CONTAINER_NAME, TagStyledContext, TagStyledContextType, TagVariant } from './Tag.constants';

const getTagContainerVariantProps: VariantSpreadFunction<GetProps<typeof PillContainer>, TagVariant | undefined> = (
  variant,
  { props: { disabled, pressable } },
) => {
  if (disabled && pressable) {
    return {
      backgroundColor: '$interactive.neutral.disabled',
    };
  }

  switch (variant) {
    case 'inverted':
      return {
        backgroundColor: '$surface.primary.default',
      };
    case 'negative':
      return {
        backgroundColor: '$surface.negative.defaultAlt',
      };
    case 'positive':
      return {
        backgroundColor: '$surface.positive.defaultAlt',
      };
    case 'category':
      return { backgroundColor: '$onSurface.neutral.zebraAlt' };
    case 'black':
      return { backgroundColor: '$onSurface.primary.default' };
    case 'white':
      return { backgroundColor: '$onSurface.neutral.defaultInverted' };
    case 'primary':
      return { backgroundColor: '$surface.primary.default' };
    default:
      return {
        backgroundColor: '$onSurface.neutral.container',
      };
  }
};

const getTagContainerPressableProps: VariantSpreadFunction<
  GetProps<typeof PillContainer> & Partial<TagStyledContextType>,
  boolean | undefined
> = (pressable, { props: { disabled, variant } }) => {
  let backgroundColor: ColorTokens | undefined = undefined;
  let borderColor: ColorTokens | undefined = undefined;
  let hoverBorderColor: ColorTokens | undefined = undefined;

  switch (variant) {
    case 'inverted':
      borderColor = '$onSurface.primary.decorativeAlt';
      break;
    case 'negative':
      borderColor = '$surface.negative.default';
      break;
    case 'positive':
      borderColor = '$surface.positive.default';
      break;
    case 'category':
      hoverBorderColor = '$onSurface.primary.decorativeAlt';
      break;
    case 'primary':
      break;
    case 'default':
    default:
      backgroundColor = '$interactive.neutral.hovered';
      borderColor = '$onSurface.primary.decorative';
  }

  return {
    borderWidth: pressable ? '$0.25' : 0,
    cursor: pressable && !disabled ? 'pointer' : undefined,
    focusStyle: pressable ? { borderColor } : undefined,
    hoverStyle: pressable && !disabled ? { backgroundColor, borderColor: hoverBorderColor } : undefined,
    pressStyle: pressable && !disabled ? { backgroundColor } : undefined,
  };
};

export const TagContainer = styled(PillContainer, {
  name: TAG_CONTAINER_NAME,
  context: TagStyledContext,

  backgroundColor: '$onSurface.neutral.container',
  borderStyle: 'solid',
  borderColor: 'transparent',
  outlineStyle: 'none',
  overflow: 'hidden',

  style: {
    // The group property changes the container to have container-type: inline-size which collapses the Tag, so we need to set it
    // specifically to normal.
    containerType: 'normal',
  },

  variants: {
    pressable: getTagContainerPressableProps,
    variant: getTagContainerVariantProps,
  } as const,
});

export type TagContainerProps = GetProps<typeof TagContainer>;
