import { styled } from '@tamagui/core';

import { Skeleton, Stack } from '../../atoms';

import { DATA_POINT_PILL_NAME } from './constants';
import { DataPointStyledContext } from './DataPointStyledContext';

const PillStyled = styled(Stack, {
  name: DATA_POINT_PILL_NAME,

  context: DataPointStyledContext,

  br: '$0.5',
  h: '$2',
  w: '$2',
  overflow: 'hidden',

  variants: {
    inline: {
      true: {
        display: 'none',
      },
    },
  } as const,
});

const PillSkeleton = styled(Skeleton.Rect, {
  w: '$2',
  h: '$2',
  br: '$0.5',
});

export const Pill = PillStyled.styleable((props, ref) => {
  const { loading } = DataPointStyledContext.useStyledContext();

  return loading ? <PillSkeleton /> : <PillStyled ref={ref} {...props} />;
});
