export default {
  'landing-page.investor-since': 'Investor since {date}',

  'landing.arrived-vs.header': 'Fortify your portfolio with residential properties',
  'landing.arrived-vs.content':
    "Single family residential properties can be a smart investment due to their potential for steady income and property value growth. Long-term tenants can help ensure stable cash flow, and real estate's potential low correlation with the stock market can help improve overall portfolio diversification.",
  'landing.arrived-vs.disclaimer':
    'Arrived performance is based on a portfolio of all Arrived SFR properties since inception. [Stocks source](https://ycharts.com/indices/%5ESPXTR), [REITs source](https://www.reit.com/data-research/reit-indexes/monthly-index-values-returns)',

  'landing.faqs.how-does-arrived-make-money.question': 'How does Arrived make money?',
  'landing.faqs.how-does-arrived-make-money.answer':
    "Arrived charges different fees for each type of investment product, detailed on the respective investment page under 'Fees.' These fees are generally categorized into one-time and recurring fees, varying according to the product type. Additionally, Arrived receives agent rebates from sellers during property purchases.",
  'landing.faqs.how-is-my-investment-protected.question': 'How is my investment protected?',
  'landing.faqs.how-is-my-investment-protected.answer':
    'Investments made through Arrived are safeguarded by thorough property vetting, insurance, and professional management. While the direct protection of capital is not guaranteed, as real estate values can fluctuate, these measures aim to minimize risks and enhance the potential for positive returns. The risks associated with each offering are explained in the relevant offering documents.',
  'landing.faqs.what-are-the-risks.question': 'What are the risks of investing?',
  'landing.faqs.what-are-the-risks.answer':
    'As with any investment, Arrived investments come with certain risks. These risks include market risk, where property values may decline due to economic factors; liquidity risk, as real estate, is not a highly liquid asset and selling shares may not be immediate; and leverage risk, which may amplify losses if properties are purchased with debt. The risks associated with each offering are explained in the relevant offering documents. Please read them before making any decisions to invest.',
  'landing.faqs.choose-properties.question': 'How does Arrived choose properties?',
  'landing.faqs.choose-properties.answer':
    'Our investment team is responsible for selecting each Arrived rental property, and they remain involved throughout the entire life cycle of the home. The markets we choose are based on a series of metrics, including potential for population growth, job opportunities, and emerging single-family home trends. We focus on single family homes that are newly built and located in newer developments. To ensure that we select properties that will maximize our investor returns over the long term, we follow a 6-step process during acquisition.Identify the most lucrative markets\n1) Identify the most lucrative markets\n2) Narrow down the top neighborhoods\n3) Define the ideal home purchasing criteria\n4) Source deals to find the right rental propertie\n5) Understand the property value & make the right offer\n6) Invest in renovation strategically',
  'landing.faqs.differ-from-traditional-investing.question':
    'How does investing with Arrived differ from traditional real estate investing?',
  'landing.faqs.differ-from-traditional-investing.answer':
    'Arrived is a platform that enables investors to purchase fractional ownership in individual properties. This is different from traditional real estate investing, where investors typically have to buy an entire property, requiring more capital and direct management of the property. Arrived takes care of property management and operations, making real estate investment more accessible and less burdensome for individual investors.',
  'landing.faqs.how-experienced-is-the-team.question': 'How experienced is the Arrived team?',
  'landing.faqs.how-experienced-is-the-team.answer':
    "The Arrived team is comprised of professionals with diverse backgrounds in real estate, technology, and finance. This diverse team brings a wealth of experience and knowledge to the table, enabling them to identify promising properties and manage investments effectively. The team's combined expertise supports Arrived's overall functionality, ensuring a professional approach to property management and investor relations.",
  'landing.faqs.jeff-bezos.question': 'How is Jeff Bezos involved?',
  'landing.faqs.jeff-bezos.answer':
    "Jeff Bezos invested in Arrived through his company, Bezos Expeditions. This investment is a significant endorsement and financial support for Arrived. However, it's important to note that Bezos does not play a direct role in the company's day-to-day operations or decision-making.",

  'landing.header.main-title': '<gradient>Hassle-free</gradient> real estate ownership',
  'landing.header.content':
    'Earn rental income, grow your equity, and diversify your portfolio effortlessly with Arrived.',
  'landing.header.cta': 'Start Investing',

  'landing.nationwide-highlight.title': 'Nationwide investments picked by experts',
  'landing.nationwide-highlight.content':
    'Invest with confidence in high-quality properties carefully selected and vetted by our expert team for their strong appreciation potential and reliable rental yields.',
  'landing.low-minimums-highlight.title': 'Low minimums, no accreditation required',
  'landing.low-minimums-highlight.content':
    'Invest in fractional shares of diverse properties to spread risk and enhance portfolio stability without the high capital requirements of traditional real estate.',
  'landing.passive-income-highlight.title': 'Passive income without tenant stress',
  'landing.passive-income-highlight.content':
    'Enjoy seamless passive income as Arrived Homes takes care of all property management duties from tenant interactions to ongoing maintenance.',

  'landing.kpis.dividends-paid': 'Dividends paid',
  'landing.kpis.registered-investors': 'Registered investors',
  'landing.kpis.assets-under-management': 'Assets under management',
  'landing.kpis.occupancy-rate': 'Occupancy rate',
  'landing.kpis.occupancy-rate.content': 'Occupancy percentage of all stabilized Arrived single family rentals',

  'landing.steps.step-number': 'Step {stepNumber}',
  'landing.steps.title': 'Buy your first home in under 3 minutes',
  'landing.steps.step-1.title': 'Browse and choose investments',
  'landing.steps.step-1.content':
    'Begin your investment journey with Arrived by exploring a curated selection of diverse investment opportunities.',
  'landing.steps.step-2.title': 'Buy shares',
  'landing.steps.step-2.content':
    'Take the next step towards ownership without the hassle. Decide on an investment amount that fits your budget and buy shares.',
  'landing.steps.step-3.title': 'Earn rental income and appreciation',
  'landing.steps.step-3.content':
    'Watch your investment work for you. Arrived handles property management, giving you the benefits of property ownership without all the work.',

  'landing.start.pcf.initialInvestment': 'Initial Investment',
  'landing.start.pcf.annualDividend': 'Annual Dividend at {percentage}%*',
  'landing.start.pcf.payoutHeader':
    "The Private Credit Fund's has historically paid an annualized dividend yield of {percentage}%*",
  'landing.start.pcf.returnsLegalDisclaimer':
    '*Estimated monthly dividends based on historical annualized dividend rates. Past performance is not an indicator of future returns. Dividends may fluctuate based on multiple factors which cannot be predicted. Historically, rates for these loans remain largely independent from the Fed’s interest rate targets.',
} as const;
