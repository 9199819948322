import { useMemo } from 'react';

import { Auth0UserPermissions, useArrivedAuth0 } from '@arrived/arrived-auth0';
import { usePermissions } from '@arrived/hooks';
import { useGetCurrentUserQuery, useRegulationAccessSummaryQuery } from '@arrived/queries';

export const useShouldShowBanner = ({
  title,
  description,
  targetAccredited,
  targetAuthenticated,
  targetUserIds,
  targetAdmins,
}: {
  title: string;
  description: string;
  targetAccredited: boolean;
  targetAuthenticated: boolean;
  targetUserIds: number[];
  targetAdmins: boolean;
}) => {
  const { isAuthenticated } = useArrivedAuth0();
  const regDAccessState = useRegulationAccessSummaryQuery();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { hasPermission } = usePermissions();
  const isAdmin = currentUser?.admin || hasPermission(Auth0UserPermissions.ALL);

  const shouldShowBanner = useMemo(() => {
    if (targetAuthenticated && !isAuthenticated) {
      return false;
    }

    if (targetAccredited && !regDAccessState.data?.canTradeRegDOffering) {
      return false;
    }

    if (targetUserIds.length > 0 && (!currentUser || !currentUser.id || targetUserIds.indexOf(currentUser.id) === -1)) {
      return false;
    }

    if (targetAdmins && !isAdmin) {
      return false;
    }

    const descriptionHasCurlyBraces = description?.includes('{') || description?.includes('}');
    if (descriptionHasCurlyBraces) {
      return false;
    }

    const titleHasCurlyBraces = title?.includes('{') || title?.includes('}');
    if (titleHasCurlyBraces) {
      return false;
    }

    return true;
  }, [
    targetAccredited,
    targetAuthenticated,
    targetUserIds,
    targetAdmins,
    isAuthenticated,
    regDAccessState.data?.canTradeRegDOffering,
    currentUser,
    isAdmin,
    description,
    title,
  ]);

  return useMemo(() => shouldShowBanner, [shouldShowBanner]);
};
