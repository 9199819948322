import { isWeb } from '@arrived/bricks';

if (__DEV__ && !isWeb && require('expo-constants').default?.expoConfig?.extra?.expo) {
  const { registerRootComponent } = require('expo');
  const { App } = require('./app');

  registerRootComponent(App);
}

export * from './content';
export * from './header';
export * from './hooks';
export * from './impl';
export * from './location';
export * from './productHeader';
export * from './productInfoBox';
export * from './productListItem';
export * from './productWidget';
export * from './returns';
export * from './video';
export * from './hooks';
