export interface BaseWordpressApiArguments {
  page?: number;
  per_page?: number;
  order?: 'asc' | 'desc';
}

interface WordpressMediaContent extends Record<string, unknown> {
  'wp:featuredmedia': {
    source_url: string;
  }[];
}

/**
 * The original Wordpress API response for a blog post.
 */
export interface WordpressPost {
  id: number;
  date: string;
  slug: string;
  link: string;
  yoast_head_json: {
    [key: string]: unknown;
    twitter_misc: {
      'Est. reading time': string;
    };
    og_image: {
      url: string;
    }[];
  };
  _embedded: WordpressMediaContent;
  _links: WordpressMediaContent;
  excerpt: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  title: {
    rendered: string;
  };
}

/**
 * The unwrapped learn blog post, this is derived from the WordpressPost
 * to make it easier to work with.
 */
export interface LearnBlogPost {
  id: number;
  date: string;
  content?: string;
  title: string;
  slug: string;
  link: string;
  excerpt: string;
  readingTime?: string;
  featuredImage: string;
}

export interface ArticlePost {
  title: string;
  slug: string;
  date: string;
  excerpt: string;
  readingTime?: string;
  featuredImage: string;
  content: string;
}

/**
 * Available search params when fetching & filtering blog posts.
 * https://developer.wordpress.org/rest-api/reference/posts/#example-request
 */
export interface WordpressPostSearchOptions {
  /**
   * Limit results to any number of categories.
   */
  categories?: number[];

  /**
   * Exclude results from any number of categories.
   */
  categories_exclude?: number[];

  /**
   * Determines order of results within other params.
   */
  order?: 'desc' | 'asc';

  /**
   * Sort items by object attribute.
   */
  orderby?:
    | 'author'
    | 'date'
    | 'id'
    | 'include'
    | 'modified'
    | 'parent'
    | 'relevance'
    | 'slug'
    | 'include_slugs'
    | 'title';

  /**
   * Current page of the collection. Defaults to 1.
   */
  page?: number;

  /**
   * Max number of items per pageset. Defaults to 10.
   */
  per_page?: number;

  /**
   * Limit results to those matching a string.
   */
  search?: string;

  /**
   * Limit results to whether the post is "sticky" or not. Used for featured articles.
   */
  sticky?: boolean;
}

/**
 * Enums for the categories our blog posts are grouped by.
 */
export const WordpressPostCategory = {
  REAL_ESTATE_INVESTING: {
    filterParams: { categories: [2] },
    displayName: 'Real Estate Investing',
  },
  INVESTING_ON_ARRIVED: {
    filterParams: { categories: [17] },
    displayName: 'Investing on Arrived',
  },
  MARKET_SPOTLIGHT: {
    filterParams: { categories: [20] },
    displayName: 'Market Spotlights',
  },
  VACATION_RENTALS: {
    filterParams: { categories: [21] },
    displayName: 'Vacation Rentals',
  },
  TAXES: {
    filterParams: { categories: [19] },
    displayName: 'Taxes',
  },
  FEATURED: {
    filterParams: { sticky: true },
    displayName: 'Taxes',
  },
} as const;

export type WordpressPostCategory = (typeof WordpressPostCategory)[keyof typeof WordpressPostCategory];
