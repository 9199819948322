import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';
import { Offering } from '@arrived/models';

import { PreFormattedDataPointProps } from './types';

export type PurchasePriceDataPointProps = PreFormattedDataPointProps & { offering: Offering };

export const PurchasePriceDataPoint = ({ offering, ...rest }: PurchasePriceDataPointProps) => (
  <DataPoint
    colors="dark"
    variant="minimized"
    tooltip={{
      content: <FormattedMessage id="product-details.purchase-price.tooltip" />,
      title: <FormattedMessage id="product-details.purchase-price" />,
    }}
    {...rest}
  >
    <DataPoint.Value>
      <FormattedNumber currency="USD" style="currency" maximumFractionDigits={0} value={offering.totalPurchasePrice} />
    </DataPoint.Value>
    <DataPoint.Label>
      <FormattedMessage id="product-details.purchase-price" />
    </DataPoint.Label>
  </DataPoint>
);
