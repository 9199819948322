import { Image, Svg } from 'react-native-svg';

import { BricksBuilderIcons, GenericBuilderIcons, RegisteredComponent } from '../../sdk';

export const Icon = ({ icon, attributes }: { attributes?: Record<string, string>; icon: string }) => {
  return (
    <Svg width={24} height={24} {...attributes}>
      <Image href={icon} />
    </Svg>
  );
};

const IconEnum = Object.entries(BricksBuilderIcons).map(([label, value]) => ({
  label,
  value,
}));

Icon.registerComponent = {
  component: Icon,
  name: 'Bricks:Icon',
  image: GenericBuilderIcons.Info,
  friendlyName: 'Icon',
  description: 'An icon from the Bricks icon set, still a work in progress',
  docsLink: 'https://www.figma.com/design/AuxJ1Yte5IW5FpSYSmSDeV/%F0%9F%94%A9-Icons-%26-Illustration?node-id=32-190',
  canHaveChildren: false,
  static: true,
  noWrap: true,
  inputs: [
    {
      name: 'icon',
      friendlyName: 'Icon',
      type: 'enum',
      required: true,
      defaultValue: IconEnum[0].value,
      enum: IconEnum,
    },
  ],
} satisfies RegisteredComponent;
