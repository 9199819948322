import { GetProps, InfoBox, styled } from '@arrived/bricks';

export const StyledInfoBox = styled(InfoBox, {
  cursor: 'pointer',
  row: true,
  gap: '$2',
  style: {
    textDecoration: 'none',
  },
});

export type StyledInfoBoxProps = GetProps<typeof StyledInfoBox>;
