import { Market, MarketCreateRequest, MarketId, MarketROI, MarketUpdateRequest } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getMarkets = () =>
  createQuery<Market[]>({
    apiInstance: api,
    method: 'get',
    url: '/markets',
  });

export const postMarket = (request: MarketCreateRequest) =>
  createQuery<Market>({
    apiInstance: api,
    method: 'post',
    url: '/markets',
    requestData: request,
  });

export const getMarket = (marketId: MarketId) =>
  createQuery<Market>({
    apiInstance: api,
    method: 'get',
    url: `/markets/${marketId}`,
  });

export const getMarketRoi = (marketId: MarketId) =>
  createQuery<MarketROI>({
    apiInstance: api,
    method: 'get',
    url: `/markets/${marketId}/roi`,
  });

export const patchMarket = (request: MarketUpdateRequest) =>
  createQuery<Market>({
    apiInstance: api,
    method: 'patch',
    url: `/markets/${request.id}`,
    requestData: request,
  });

export const deleteMarket = (marketId: MarketId) =>
  createQuery<Market>({
    apiInstance: api,
    method: 'delete',
    url: `/markets/${marketId}`,
  });
