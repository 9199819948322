import { AccountId } from './account';
import { UserId } from './users';

export class RewardsException extends Error {
  public code: Rewards.REWARDS_CLIENT_ERRORS;

  constructor(code: Rewards.REWARDS_CLIENT_ERRORS, msg: string) {
    super(msg);
    this.code = code;
    Object.setPrototypeOf(this, RewardsException.prototype);
  }
}

export const RewardsVoucherState = {
  CREATED: 'CREATED',
  APPLIED: 'APPLIED',
  REVOKED: 'REVOKED',
} as const;

export type RewardsVoucherState = (typeof RewardsVoucherState)[keyof typeof RewardsVoucherState];

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Rewards {
  export enum REWARDS_CLIENT_ERRORS {
    NO_ACCOUNT = 'rewards.error.no_account',
    INVALID_PROMOTION = 'rewards.error.invalid_promotion',
  }

  export enum REWARDS_SERVER_ERRORS {
    VOUCHER_VALUE_EXCEEDS_REDEMPTION = 'voucherValueExceedsRedemption',
    VOUCHER_STATE_NOT_REDEEMABLE = 'invalidVoucherStateForRedemption',
    VOUCHER_NOT_STACKABLE = 'invalidVoucherStackingForRedemption',
    INVALID_VOUCHER_PROMOTION_STACKING = 'invalidPercentOffVoucherStackingWithPromotion',
  }

  export interface PromotionFilters {
    code?: string;
    enabled?: string;
  }

  export interface AccountFilters {
    brokerageAccountId?: string;
  }

  /* tslint:disable */
  /* eslint-disable */
  // Generated using typescript-generator version 2.36.1070 on 2022-12-14 11:30:59.

  export interface AccountCreationRequest {
    brokerageAccountId: number;
  }
  export interface Account {
    uuid: string;
    brokerageAccountId: number;
  }
  export interface AccountSearchResult {
    accounts: Account[];
  }
  export interface PromotionCreationRequest {
    name: string;
    description: string | null;
    currency: SupportedCurrency;
    startAt: string | null;
    endAt: string | null;
    accountRedemptionCountLimit: number | null;
    promotionRedemptionCountLimit: number | null;
    discountPercentage: string | null;
    maxDiscountPerTransactionAmount: string | null;
    requireFirstTimeTrader: boolean | null;
    enabled: boolean | null;
    codes: string[];
  }
  export interface Promotion {
    uuid: string;
    name: string;
    description: string | null;
    currency: SupportedCurrency;
    startAt: string | null;
    endAt: string | null;
    accountRedemptionCountLimit: number | null;
    promotionRedemptionCountLimit: number | null;
    discountPercentage: string | null;
    maxDiscountPerTransactionAmount: string | null;
    requireFirstTimeTrader: boolean | null;
    enabled: boolean;
  }
  export interface PromotionSearchResult {
    promotions: Promotion[];
  }
  export interface PromotionUsage {
    promotionUuid: string;
    redemptionCount: number;
    totalRedemptionValue: string;
  }
  export interface PromotionUpdateRequest {
    name: string;
    description: string | null;
    startAt: string | null;
    endAt: string | null;
    accountRedemptionCountLimit: number | null;
    promotionRedemptionCountLimit: number | null;
    discountPercentage: string | null;
    maxDiscountPerTransactionAmount: string | null;
    requireFirstTimeTrader: boolean | null;
    enabled: boolean;
  }
  export interface PromotionRedemptionRequest {
    promotionUuid: string;
    accountUuid: string;
    code: string;
    totalAmount: string;
    currency: SupportedCurrency;
    note: string | null;
  }

  export interface PromotionCancelRedemptionRequest {
    note: string | null;
  }
  export interface PromotionRedemption {
    uuid: string;
    promotionUuid: string;
    accountUuid: string;
    state: PromotionRedemptionState;
    codeUsed: string | null;
    totalAmount: string;
    purchaseAmount: string;
    discountAmount: string;
    currency: SupportedCurrency;
    notes: PromotionRedemptionNote[];
    projections: PromotionRedemptionProjections | null;
  }

  export interface PromotionRedemptionSearchResult {
    redemptions: PromotionRedemption[];
  }
  export interface PromotionReapplyRedemptionRequest {
    note: string | null;
  }
  export interface PromotionCode {
    code: string;
  }
  export interface PromotionCodesSearchResponse {
    codes: PromotionCode[];
  }

  export interface RewardRedemption {
    uuid: string;
    promotionRedemptionUuid: string | null;
    promotionUuid: string | null;
    codeUsed: string | null;
    voucherUuids: string[];
    accountUuid: string;
    state: RewardRedemptionState;
    totalAmount: string;
    purchaseAmount: string;
    discountAmount: string;
    redeemedAt: string;
    currency: SupportedCurrency;
    notes: RewardRedemptionNote[];
    projections: RewardRedemptionProjections | null;
  }

  export interface RewardRedemptionRequest {
    accountUuid: string;
    promotionUuid: string | null;
    code: string | null;
    voucherUuids: string[];
    totalAmount: string;
    currency: SupportedCurrency;
    note: string | null;
  }

  export interface RewardRedemptionProjections {
    promotion: Promotion | null;
    promotionRedemption: PromotionRedemption | null;
    vouchers: Voucher[] | null;
  }

  export interface RewardRedemptionProjectionsRequest {
    includePromotion: boolean | null;
    includePromotionRedemption: boolean | null;
    includeVouchers: boolean | null;
  }

  export interface Voucher {
    uuid: string;
    accountUuid: string;
    description: string | null;
    currency: SupportedCurrency;
    amount: string | null;
    discountPercentage: string | null;
    voucherDiscountType: string | null;
    maxDiscountAmount: string | null;
    state: VoucherState;
    stackable?: boolean;
    issuerUuid: string;
    issuedAt: string;
    redeemedAt: string | null;
    notes: VoucherNote[];
  }

  export interface VoucherRedemption {
    vouchers: Voucher[];
    totalAmount: string;
    purchaseAmount: string;
    discountAmount: string;
    currency: SupportedCurrency;
  }

  export interface VoucherRedemptionRequest {
    accountUuid: string;
    uuids: string[];
    totalAmount: string;
    currency: SupportedCurrency;
    note: string | null;
  }

  export interface VoucherCreationRequest {
    accountUuid: string;
    description?: string | null;
    currency: SupportedCurrency | string;
    amount: number;
    stackable?: boolean;
    note?: string | null;
  }

  export interface PromotionRedemptionNote {
    timestamp: string;
    state: PromotionRedemptionState;
    note: string | null;
  }

  export interface PromotionRedemptionProjections {
    promotion: Promotion | null;
  }

  export interface RewardRedemptionNote {
    timestamp: string;
    state: RewardRedemptionState;
    note: string | null;
  }

  export interface VoucherNote {
    timestamp: string;
    state: VoucherState;
    note: string | null;
  }

  export interface VoucherSearchResponse {
    vouchers: Voucher[];
  }

  export type SupportedCurrency = 'USD';

  export type PromotionRedemptionState = 'APPLIED' | 'CANCELED';

  export type RewardRedemptionState = 'APPLIED' | 'CANCELED';

  export type VoucherState = RewardsVoucherState;

  export interface RewardsUserInfo {
    accountId?: AccountId;
    userId?: UserId;
  }
}
