import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postOverrideEmailVerification } from '@arrived/api_v2';

import { adminUserEmailVerificationKeyFn } from './users.keys';

export function useAdminPostOverrideEmailVerificationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postOverrideEmailVerification,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: adminUserEmailVerificationKeyFn(variables.userId) });
    },
  });
}
