/**
 * Shared, cross-platform routes that are consumed and supported in both mobile and web routers.
 *
 * Most are joining web app and mobile app, and not the NextJs pages.
 */

export const REDEMPTIONS_ROOT = '/properties/:idOrShortName/redemptions';

const REDEMPTIONS_ROUTES = {
  SheetRoot: REDEMPTIONS_ROOT,
  Intro: `${REDEMPTIONS_ROOT}/intro`,
  Fees: `${REDEMPTIONS_ROOT}/fees`,
  Ineligible: `${REDEMPTIONS_ROOT}/ineligible`,
  ExistingRedemption: `${REDEMPTIONS_ROOT}/existing`,
  Blocked: `${REDEMPTIONS_ROOT}/blocked`,
  ReviewPeriod: `${REDEMPTIONS_ROOT}/review-period`,
  ShareSelection: `${REDEMPTIONS_ROOT}/select-shares`,
  ReasonSelection: `${REDEMPTIONS_ROOT}/reason`,
  Approval: `${REDEMPTIONS_ROOT}/approval`,
  Success: `${REDEMPTIONS_ROOT}/success`,
} as const;
export type REDEMPTIONS_ROUTE_KEY = keyof typeof REDEMPTIONS_ROUTES;
export type REDEMPTIONS_ROUTE = (typeof REDEMPTIONS_ROUTES)[REDEMPTIONS_ROUTE_KEY];

/**
 * React Navigation types for path params
 */
export type RedemptionsStackParams = {
  [REDEMPTIONS_ROUTES.SheetRoot]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.Intro]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.Fees]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.Ineligible]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.ExistingRedemption]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.Blocked]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.ReviewPeriod]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.ShareSelection]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.ReasonSelection]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.Approval]: { idOrShortName: string };
  [REDEMPTIONS_ROUTES.Success]: { idOrShortName: string };
};

/**
 * Collection of cross-platform routes.
 */
export const SHARED_ROUTES = {
  redemptions: REDEMPTIONS_ROUTES,
};
