import { isAfter, isEqual, lastDayOfMonth } from 'date-fns';
import _cloneDeep from 'lodash/cloneDeep';

import { OfferingDividend, OfferingSharePrices } from '@arrived/models';

import { DividendPeriodSharePriceData } from './DividendPeriodSharePriceData';

export interface MergeOfferingDividendsAndSharePricesArgs {
  dividends?: OfferingDividend[];
  sharePrices?: OfferingSharePrices[];
}

export type MergeOfferingDividendsAndSharePricesReturn = OfferingDividend & DividendPeriodSharePriceData;

/**
 * This function ties together OfferingDividend entries with OfferingSharePrices entries such that
 * the end result is a list of OfferingDividends, each entry representing a specific dividend
 * period, that are adorend with two share price properties. One that is a copy of the share price
 * object of the Offering during that dividend period (`sharePriceDuringPeriod`) and another that
 * is the share price of the Offering at the time that the dividend was posted at.
 *
 * This function does not verify that the provided dividends and sharePrices arrays are for the
 * same Offering. The length of the returned array will be the length of the provided dividends
 * array regardless of the length of the provided sharePrices array.
 *
 * This function makes no assumptions about the sorting of the provided arrays.
 */
export function mergeOfferingDividendsAndSharePrices({
  dividends: dividendsIn,
  sharePrices: sharePricesIn,
}: MergeOfferingDividendsAndSharePricesArgs): MergeOfferingDividendsAndSharePricesReturn[] {
  if (!dividendsIn) {
    return [];
  } else if (!sharePricesIn) {
    return _cloneDeep(dividendsIn);
  }

  // Sort dividends in order such that the least recent is first and the most recent is last.
  const dividends = _cloneDeep(dividendsIn).sort(({ postedAt: postedAtA }, { postedAt: postedAtB }) =>
    isAfter(new Date(postedAtA), new Date(postedAtB)) ? 1 : -1,
  );
  // Sort sharePrices in reverse order such that the most recent is first and least recent is last.
  const sharePrices = _cloneDeep(sharePricesIn).sort(({ postedAt: postedAtA }, { postedAt: postedAtB }) =>
    isAfter(new Date(postedAtA), new Date(postedAtB)) ? -1 : 1,
  );

  return dividends.map((dividend) => {
    const sharePriceDuringPeriod = sharePrices.find(
      ({ postedAt }) =>
        isAfter(new Date(dividend.startDate), new Date(postedAt)) ||
        isEqual(new Date(dividend.startDate), new Date(postedAt)),
    );
    const sharePriceEOM = sharePrices.find(({ postedAt }) => {
      const postedAtLastDayOfMonth = lastDayOfMonth(new Date(dividend.postedAt));
      return isAfter(postedAtLastDayOfMonth, new Date(postedAt)) || isEqual(postedAtLastDayOfMonth, new Date(postedAt));
    });

    return {
      ...dividend,
      sharePriceDuringPeriod: _cloneDeep(sharePriceDuringPeriod),
      sharePriceEOM: _cloneDeep(sharePriceEOM),
    };
  });
}
