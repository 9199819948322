import {
  Feature,
  FeatureCategory,
  FeatureCategoryId,
  FeatureCategoryPostRequest,
  FeaturePatchRequest,
  FeaturePostRequest,
  PropertyFeatureId,
  PropertyFeatures,
  PropertyId,
  PropertySlug,
} from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getPropertyFeatures = (propertyId: PropertyId) =>
  createQuery<PropertyFeatures>({
    apiInstance: api,
    method: 'get',
    url: `/properties/${propertyId}/features`,
  });

export const getListedPropertyFeatures = (slug: PropertySlug) =>
  createQuery<PropertyFeatures>({
    apiInstance: api,
    method: 'get',
    url: `/properties/listed/${slug}/features`,
  });

export const getAllPropertyFeatures = () =>
  createQuery<Feature[]>({
    apiInstance: api,
    method: 'get',
    url: '/properties/features',
  });

export const postPropertyFeature = (requestData: FeaturePostRequest) =>
  createQuery<Feature>({
    apiInstance: api,
    method: 'post',
    url: '/properties/features',
    requestData,
  });

export const patchPropertyFeature = (requestData: FeaturePatchRequest) =>
  createQuery<Feature>({
    apiInstance: api,
    method: 'patch',
    url: `/properties/features/${requestData.id}`,
    requestData,
  });

export const deletePropertyFeature = (featureId: PropertyFeatureId) =>
  createQuery<Feature>({
    apiInstance: api,
    method: 'delete',
    url: `/properties/features/${featureId}`,
  });

export const getPropertyFeatureCategories = () =>
  createQuery<FeatureCategory[]>({
    apiInstance: api,
    method: 'get',
    url: '/properties/features/categories',
  });

export const postPropertyFeatureCategory = (requestData: FeatureCategoryPostRequest) =>
  createQuery<FeatureCategory>({
    apiInstance: api,
    method: 'post',
    url: '/properties/features/categories',
    requestData,
  });

export const patchPropertyFeatureCategory = (requestData: FeatureCategory) =>
  createQuery<FeatureCategory>({
    apiInstance: api,
    method: 'patch',
    url: `/properties/features/categories/${requestData.id}`,
    requestData,
  });

export const deletePropertyFeatureCategory = (featureCategoryId: FeatureCategoryId) =>
  createQuery<FeatureCategory>({
    apiInstance: api,
    method: 'delete',
    url: `/properties/features/categories/${featureCategoryId}`,
  });

export const addFeatureToProperty = (propertyId: PropertyId, featureId: PropertyFeatureId) =>
  createQuery<PropertyFeatures>({
    apiInstance: api,
    method: 'post',
    url: `/properties/${propertyId}/features/${featureId}`,
  });

export const deleteFeatureFromProperty = (propertyId: PropertyId, featureId: PropertyFeatureId) =>
  createQuery<PropertyFeatures>({
    apiInstance: api,
    method: 'delete',
    url: `/properties/${propertyId}/features/${featureId}`,
  });
