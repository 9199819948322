import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { UseQueryResult } from '@tanstack/react-query';

import { Account } from '@arrived/models';
import { MAX_STALE_TIME, useGetPrimaryAccountQuery } from '@arrived/queries';

export const useExternalAccountName = (queryOverride?: UseQueryResult<Account>) => {
  const intl = useIntl();

  const primaryAccountState = useGetPrimaryAccountQuery({ enabled: !queryOverride, staleTime: MAX_STALE_TIME });

  const query = queryOverride ?? primaryAccountState;

  return useMemo(() => {
    if (query.data?.externalAccount?.verified === 'NOT_VERIFIED') {
      return intl.formatMessage({ id: 'checkout.summary.unknownBank' });
    }
    return query.data?.externalAccount
      ? `${query.data?.externalAccount?.accountNickname} - ${query.data?.externalAccount?.accountNumberLastFour}`
      : intl.formatMessage({ id: 'checkout.summary.unknownBank' });
  }, [query.data, intl]);
};
