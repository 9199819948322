import { Divider, Stack, styled, withStaticProperties } from '@arrived/bricks';

export const InfoBoxRow = withStaticProperties(
  styled(Stack, {
    gap: '$6',

    $gtXxs: {
      alignItems: 'center',
      row: true,
    },
  }),
  {
    Divider: styled(Divider.Vertical, {
      h: 36,
      display: 'none',
      solid: true,
      alt: true,

      $gtXxs: {
        display: 'block',
      },
    }),
  },
);
