import { type ArrivedEnvironmentVariableKeys } from './environment-variables';
import { getEnvKey } from './get-env-key';

/**
 * Get the environment variable from the environment variables object.
 * On native, we use `expo-constants` to get the environment variable.
 *
 * @returns The environment variable value
 * @default 'ERROR'
 */
export const getEnvVariable = (key: ArrivedEnvironmentVariableKeys) => {
  const envKey = getEnvKey(key);
  const envValue = envKey ?? 'ERROR';

  if (envValue === 'ERROR') {
    console.warn(`Missing env variable: ${key}`);
  }

  return envValue;
};
