/**
 * Routes for the mobile app
 * Each section of entries correlates to a navigation stack, each needing a base path.
 *
 * This is split up into two items for easier understanding.
 *
 * Stacks -> Routes
 *
 * Stacks are the umbrella of other routes that can be navigated to, in order
 * to navigate to a specific screen you must specify the stack that relies on it.
 *
 * Routes are the individual screens that can be navigated to. There are no nested properties
 * here and we use a url format to help debug the routes.
 *
 * [See here for adding the proper values to React Navigation](apps/mobile-apps/src/routes/RouteParams.ts)
 */

const AuthRoutes = {
  AuthStack: 'AuthStack',
  AuthLogin: '/auth/login',
  AuthSignup: '/auth/signup',
  AuthForgotPassword: '/auth/forgot-password',
  AuthOnboarding: '/auth/onboarding',
  AuthMFA: '/auth/mfa',
} as const;

const BlogRoutes = {
  BlogStack: 'BlogStack',
  Blog: '/blog',
  BlogSearch: '/blog/search',
  BlogArticle: '/blog/:slug',
  BlogCategory: '/blog/category',
  BlogCategorySlug: '/blog/category/:categorySlug',
  BlogCategorySlugPage: '/blog/category/:categorySlug/:pageNum',
} as const;

const WebinarRoutes = {
  WebinarStack: 'WebinarStack',
  Webinar: '/webinar',
} as const;

const IntroRoutes = {
  InvestorsLikeYou: '/intro/investorsLikeYou',
  DiversifyWithFunds: '/intro/diversifyWithFunds',
  PCFFundPromo: '/intro/pcfFundPromo',
  InvestorGoals: '/intro/investorGoals',
} as const;

const ProductRoutes = {
  ProductStack: 'ProductStack',
  Properties: '/properties',
  ProductDetails: '/properties/:offeringId',
  FundDetails: '/properties/:offeringId/fund/:propertyId',
  HistorySheet: '/properties/:offeringId/history',
} as const;

const TransactionRoutes = {
  TransactionStack: 'TransactionStack',
  Transaction: '/transaction',
  TransactionDetails: '/transaction/details',
  TransactionDocuments: '/transaction/documents',
  TransactionSuccess: '/transaction/success',
} as const;

const PortfolioRoutes = {
  Portfolio: '/portfolio',
  PortfolioDetails: '/portfolio/details',
} as const;

const AccountRoutes = {
  AccountStack: 'AccountStack',
  /**
   * @deprecated Deprecated Route due to portfolio page rework. Kept to keep redirects as of 06/20/2024
   */
  PortfolioNew: '/account/portfolio',
  /**
   * @deprecated Deprecated Route due to portfolio page rework. Kept to keep redirects as of 06/20/2024
   */
  Wallet: '/account/wallet',
  TransactionSheet: '/account/portfolio/transaction',
  TransactionSignDocuments: '/account/portfolio/transaction/sign',
  SaleTradeDetailsSheet: '/account/portfolio/saleTrade',
  Settings: '/account/settings',
} as const;

const AccountSettingsRoutes = {
  AccreditationStatus: `${AccountRoutes.Settings}/accreditation-status`,
} as const;

const ProfileRoutes = {
  Account: '/account',
  AccountAbout: '/account/about',
  AccountBasicInfo: '/account/profile/details',
  AccountDocuments: '/account/documents',
  AccountDocumentsUpload: '/account/documents/upload',
  AccountInvestmentAccountDetails: '/account/brokerage',
  AccountLearningCenter: '/account/learning-center',
  AccountLinkedBankAccount: '/account/bank',
  AccountLiveHelp: '/account/live-help',
  AccountNotificationSettings: '/account/notifications',
  AccountSecurity: '/account/security',
  AccountProfile: '/account/profile',
  AccountSupport: '/account/support',
  AccountTransactionHistory: '/account/transaction-history',
  AccountTransactionDetails: '/account/transaction/details',
} as const;

// Generic screens that live under the root stack. Primarily universal modals.
const UtilityRoutes = {
  TabsStack: 'TabsStack',
  MoveMoney: '/move-money',
  InvestCash: '/invest-cash',
  CashAccountActions: '/cash-account-actions',
  LearnHowItWorks: '/learn-how-it-works',
  MediaViewer: '/view-document',
  Onboarding: '/onboarding',
  BankLinking: '/bank-linking',
  Questionnaire: '/questionnaire',
  VerifySSN: '/verify-ssn',
  Welcome: '/welcome',
  BiometricPrompt: '/biometric-prompt',
  BuilderIoSandbox: '/builder-io-sandbox',
  Webinar: '/webinar',
  Earn: '/earn',
  NotFound: 'NotFound',
  OfferingHistorySheet: '/properties/:offeringId/history',
} as const;

export const MobileRoutes = {
  ...AuthRoutes,
  ...BlogRoutes,
  ...IntroRoutes,
  ...ProductRoutes,
  ...TransactionRoutes,
  ...PortfolioRoutes,
  ...AccountRoutes,
  ...ProfileRoutes,
  ...UtilityRoutes,
  ...AccountSettingsRoutes,
  ...WebinarRoutes,
} as const;

/**
 * Breakdown specific routes for typing references
 */

export type MobileRoutes = typeof MobileRoutes;
export type MobileRoutesKeys = keyof MobileRoutes;
export type MobileRoute = MobileRoutes[MobileRoutesKeys];

export type BlogRoutes = typeof BlogRoutes;
export type IntroRoutes = typeof IntroRoutes;
export type ProductRoutes = typeof ProductRoutes;
export type TransactionRoutes = typeof TransactionRoutes;
export type PortfolioRoutes = typeof PortfolioRoutes;
export type ProfileRoutes = typeof ProfileRoutes;
export type UtilityRoutes = typeof UtilityRoutes;
export type AccountRoutes = typeof AccountRoutes;
export type WebinarRoutes = typeof WebinarRoutes;
