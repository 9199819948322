import { ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

import { TransactionStatus } from '@arrived/models';

export const TRANSACTION_STATUS_INTL_MAP: Record<
  TransactionStatus,
  { label: NonNullable<ReactNode>; description: NonNullable<ReactNode> }
> = {
  [TransactionStatus.CREATED]: {
    label: <FormattedMessage id="trade-status-created-pending-label" defaultMessage="Transaction in Progress" />,
    description: (
      <FormattedMessage
        id="trade-status-created-pending-description"
        defaultMessage="It can take up to 5 business days for a transaction to be funded from your bank account, and 7-10 days to move from Processing to Posted."
      />
    ),
  },
  [TransactionStatus.CANCELLED]: {
    label: <FormattedMessage id="trade-status-cancelled-label" defaultMessage="Cancelled" />,
    description: (
      <FormattedMessage
        id="trade-status-cancelled-description"
        defaultMessage="Funds were never received in escrow and/or the trade has been cancelled."
      />
    ),
  },
  [TransactionStatus.CANCELLED_PENDING]: {
    label: <FormattedMessage id="trade-status-cancelled-label" defaultMessage="Cancelled" />,
    description: (
      <FormattedMessage
        id="trade-status-cancelled-description"
        defaultMessage="Funds were never received in escrow and/or the trade has been cancelled."
      />
    ),
  },
  [TransactionStatus.FUNDED]: {
    label: <FormattedMessage id="trade-status-funded-label" defaultMessage="Funded" />,
    description: (
      <FormattedMessage id="trade-status-funded-description" defaultMessage="Investment funds have been received." />
    ),
  },
  [TransactionStatus.SETTLED]: {
    label: <FormattedMessage id="trade-status-settled-label" defaultMessage="Settled" />,
    description: (
      <FormattedMessage
        id="trade-status-settled-description"
        defaultMessage="The investment has been accepted by Arrived and funds have been released from escrow."
      />
    ),
  },
  [TransactionStatus.UNWIND_PENDING]: {
    label: <FormattedMessage id="trade-status-unwind-pending-label" defaultMessage="Processing Refund" />,
    description: (
      <FormattedMessage
        id="trade-status-unwind-pending-description"
        defaultMessage="The funds have been requested to be returned to the investor and a refund is processing."
      />
    ),
  },
  [TransactionStatus.UNWIND_SETTLED]: {
    label: <FormattedMessage id="trade-status-unwind-settled-label" defaultMessage="Refund Completed" />,
    description: (
      <FormattedMessage
        id="trade-status-unwind-settled-description"
        defaultMessage="The funds have been received in escrow then returned back to the investor."
      />
    ),
  },
} as const;
