import { FormattedMessage } from 'react-intl';

import { Button, ButtonProps } from '@arrived/bricks';
import { notificationService } from '@arrived/features-toasty';
import { UserId } from '@arrived/models';
import { usePostChangePasswordMutation } from '@arrived/queries';

interface ChangePasswordProps extends ButtonProps {
  useClipboard?: boolean;
  userId: UserId;
}

export const ChangePassword = ({ variant, useClipboard, userId, ...buttonProps }: ChangePasswordProps) => {
  const { mutateAsync: postChangePassword } = usePostChangePasswordMutation();

  const handleOnClick = async () => {
    const passwordUrl = await postChangePassword(userId);

    if (useClipboard) {
      notificationService.success('Password reset has been copied to your clipboard');
      await navigator.clipboard.writeText(passwordUrl);
    } else {
      window.open(passwordUrl, '_blank');
    }
  };

  return (
    <Button variant={variant} onPress={handleOnClick} {...buttonProps}>
      <FormattedMessage id="user-profile-page-change-password-label" defaultMessage="Change Password" />
    </Button>
  );
};
