import { IntlShape } from 'react-intl';

import { calculatorAppreciationToEquity, getProjectedAppreciationRange } from '@arrived/common';
import {
  MarketROI,
  Offering,
  OfferingDividend,
  OfferingTransaction,
  ZillowZipCodeAppreciation,
  getLeaseStatusMessageDisplayText,
} from '@arrived/models';

import { DocumentSignedEventProperties } from '../EventProperties';

import { getHistoricalReturnRange } from './getHistoricalReturnRange';

interface GetDocumentsSignedProperties {
  offering: Offering;
  offeringTransaction: OfferingTransaction;
  offeringDividends?: OfferingDividend[];
  marketROI?: MarketROI;
  zipCodeAppreciation?: ZillowZipCodeAppreciation;
  extraFields?: Partial<DocumentSignedEventProperties>;
  intl: IntlShape;
}

export const getDocumentsSignedProperties = ({
  offering,
  offeringTransaction,
  offeringDividends,
  marketROI,
  zipCodeAppreciation,
  extraFields,
  intl,
}: GetDocumentsSignedProperties): Partial<DocumentSignedEventProperties> => {
  const property = offering.properties[0];

  const [projectedAppreciationLower, projectedAppreciationUpper] = getProjectedAppreciationRange(zipCodeAppreciation);

  return {
    ...extraFields,
    asset_type: offering?.assetType,
    transaction_id: offeringTransaction.id,
    total_transaction_amount: offeringTransaction.totalAmount,
    revenue: offeringTransaction.totalAmount,
    currency: 'USD', // Need to change to not be hardcoded later
    num_shares_purchased: offeringTransaction.sharesCount,
    beds: property?.bedrooms,
    baths: (property?.fullBathrooms ?? 0) + (property?.halfBathrooms ?? 0),
    sq_ft: property?.squareFootage,
    year_built: property?.yearBuilt,
    number_of_investors: offering?.investorsCount,
    property_purchase_price: offering?.totalPurchasePrice,
    monthly_rent: property?.rent,
    offering_id: offering?.id,
    property_name: offering?.name.toLowerCase(),
    perc_purchased_status: offering?.fundedPercent,
    property_rental_status: getLeaseStatusMessageDisplayText(property?.leaseStatus, intl),
    first_dividend_date: offering?.projectedFirstDividendDate ?? undefined,
    first_dividend_yield: offering?.projectedAnnualDividendYield ?? undefined,
    ...getHistoricalReturnRange(
      offering,
      {
        lower: calculatorAppreciationToEquity(projectedAppreciationLower, offering),
        upper: calculatorAppreciationToEquity(projectedAppreciationUpper, offering),
      },
      offeringDividends,
    ),
    historical_rental_dividends: marketROI?.averageDividend,
    property_value_growth: projectedAppreciationLower ?? undefined,
  };
};
