import { ComponentProps } from 'react';

import { FormattedNumber } from 'react-intl';

import { Typography, TypographyProps } from '@mui/material';

export interface LargePercentDisplayProps extends ComponentProps<typeof FormattedNumber> {
  TypographyProps?: TypographyProps;
}

export const LargePercentDisplay = ({ TypographyProps, ...rest }: LargePercentDisplayProps) => (
  <Typography
    variant="h5"
    fontWeight="semibold"
    component="span"
    color={({ palette }) => palette.neutrals.white}
    {...TypographyProps}
  >
    <FormattedNumber style="percent" maximumFractionDigits={1} minimumFractionDigits={1} {...rest} />
  </Typography>
);
