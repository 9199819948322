import { createContext } from 'react';

import { stub } from '@arrived/common';

export interface PortfolioPrivacyContextType {
  isPrivacyModeEnabled: boolean;
  togglePrivacyMode: () => void;
}

export const PortfolioPrivacyContext = createContext<PortfolioPrivacyContextType>({
  isPrivacyModeEnabled: false,
  togglePrivacyMode: stub,
});
