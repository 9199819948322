import { FormattedMessage } from 'react-intl';

import { ExternalAccount, ExternalAccountAccountType } from '@arrived/models';

export function getExternalAccountName({ type, accountNumberLastFour, accountNickname }: ExternalAccount) {
  if (!type) {
    return null;
  }

  const accountIdentifier = accountNumberLastFour ?? accountNickname;

  const accountName = {
    [ExternalAccountAccountType.CHECKING]: (
      <FormattedMessage id="checking" defaultMessage="Checking ({accountIdentifier})" values={{ accountIdentifier }} />
    ),
    [ExternalAccountAccountType.SAVINGS]: (
      <FormattedMessage id="savings" defaultMessage="Savings ({accountIdentifier})" values={{ accountIdentifier }} />
    ),
  }[type];

  if (!accountName) {
    throw new Error(`Unexpected external account type: ${type}`);
  }

  return accountName;
}
