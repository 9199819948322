import { Dispatch, ReactElement, ReactNode, SetStateAction, useContext } from 'react';
import { LayoutRectangle } from 'react-native';

import { createStyledContext } from '@tamagui/core';

/**
 * This context is used to manage the imperative actions of the sheet.
 * This should not interact with any `styled` variants of the sheet and should
 * just handle the general state of the sheet.
 *
 * Again, no idea why I couldn't get one context working with variants and
 * imperative actions.
 */
export type SheetImperativeContext = {
  /**
   * @refs
   */

  /**
   * The height of the footer. We use `ref` instead of `state`
   * as we will never display the value directly but instead
   * use it for other calculations (e.g. the `maxHeight` of the
   * sheet content or animations that depend on the footer height).
   */
  footerDimensions: LayoutRectangle;

  /**
   * The height of the header. We use `ref` instead of `state`
   * as we will never display the value directly but instead
   * use it for other calculations (e.g. the `maxHeight` of the
   * sheet content or animations that depend on the header height).
   */
  headerDimensions: LayoutRectangle;

  /**
   * INTERNAL
   */

  /**
   * @hidden
   */
  setFooterDimensions?: Dispatch<SetStateAction<LayoutRectangle>>;

  /**
   * @hidden
   */
  setHeaderDimensions?: Dispatch<SetStateAction<LayoutRectangle>>;

  /**
   * @actions
   */

  /**
   * Open the sheet.
   */
  onOpen: () => void;

  /**
   * Close the sheet.
   */
  onClose: () => void;

  /**
   * Handle on press of the back button.
   */
  onBack?: () => void;

  /**
   * Toggle the sheet.
   */
  onToggle: () => void;

  /**
   * Handle the backdrop press.
   */
  onBackdropPress: () => void;

  /**
   * Wrapper component around the sheet content
   */
  Wrapper: (props: { children: ReactNode }) => ReactElement;

  /**
   * @state
   */

  /**
   * Determines if the sheet is open or not.
   */
  open: boolean;
  /**
   * Allows for rendering the sheet content outside of a bottom sheet.
   * Specifically useful on native when the bottom sheet functionality results
   * in buggy behavior.
   */
  isNotBottomSheet?: boolean;
};

export const SheetImperativeContext = createStyledContext<SheetImperativeContext>({} as never);

export const useSheetImperativeContext = () => {
  const context = useContext<SheetImperativeContext>(SheetImperativeContext);

  if (!context) {
    throw new Error('useSheetImperativeContext must be used within a SheetImperativeContextProvider');
  }

  return context;
};
