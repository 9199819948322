import { tokens } from '../tokens';
import { Join, Leaves, setColorAlpha } from '../utils';

export interface BricksTheme {
  /**
   * @name interactive
   * They are used in interactive components and icons.
   * Primary colours use the core brand colours to stand out.
   * The neutral have less emphasizes and meant to support the
   * visual hierarchy of the screen.
   */
  interactive: {
    neutral: {
      rested: unknown;
      restedAlt: unknown;
      hovered: unknown;
      disabled: unknown;
      focus: unknown;

      restedInverted: unknown;
      hoveredInverted: unknown;
      hoveredInvertedAlt: unknown;
      disabledInverted: unknown;
      focusInverted: unknown;

      hoveredAlt: unknown;
      disabledAlt: unknown;
    };

    primary: {
      rested: unknown;
      restedAlt: unknown;
      hovered: unknown;
      hoveredAlt: unknown;
      disabled: unknown;
      focus: unknown;
    };

    negative: {
      disabled: unknown;
      rested: unknown;
      hovered: unknown;
    };

    positive: {
      disabled: unknown;
      rested: unknown;
      hovered: unknown;
    };
  };

  /**
   * @name OnSurface
   * Use to emphasize content that is on surface and enable a
   * clear visual hierarchy between element that are place on
   * surface such as text, outline, container and other elements.
   */
  onSurface: {
    neutral: {
      default: unknown;
      muted: unknown;
      outline: unknown;
      zebra: unknown;
      container: unknown;
      tooltip: unknown;

      defaultAlt: unknown;
      outlineAlt: unknown;
      zebraAlt: unknown;
      containerAlt: unknown;

      defaultInverted: unknown;
      outlineInverted: unknown;
    };

    primary: {
      default: unknown;

      decorative: unknown;
      decorativeAlt: unknown;

      outline: unknown;
      outlineAlt: unknown;

      light: unknown;
    };

    secondary: {
      default: unknown;

      low: unknown;

      high: unknown;
    };

    positive: {
      default: unknown;
      defaultAlt: unknown;

      decorative: unknown;
    };

    negative: {
      default: unknown;
      defaultAlt: unknown;
      outline: unknown;
    };
  };

  /**
   * @name Surface
   * Use for background colour that rest on our
   * foundation layer. Meant to provide soft or
   * bold contrast to support our visual hierarachy.
   */
  surface: {
    neutral: {
      default: unknown;
      /**
       * This is a gradient with a grain texture, no idea how to
       * implement this in a theme.
       *
       * @see {@link https://www.figma.com/file/peJR1hwp6mPRyVwB6k2iAw/%F0%9F%94%A9-Colors?node-id=258-730&t=IbRYPFf8KcHgCrj1-4}
       */
      yin: unknown;
    };

    primary: {
      default: unknown;
    };

    positive: {
      default: unknown;
      defaultAlt: unknown;
    };

    negative: {
      default: unknown;
      defaultAlt: unknown;
    };

    artifactInlet: {
      default: unknown;
    };

    artifactLilac: {
      default: unknown;
    };

    artifactMusty: {
      default: unknown;
    };
  };

  /**
   * @name Foundation
   * It’s the level zero. The lowest level background used in most screens.
   */
  foundation: {
    neutral: {
      yang: unknown;
    };
  };
}

export type BricksThemeLeaves = Leaves<BricksTheme>;

/**
 * TODO: This is NOT COMPLETE or ready for extensive use. The only colors that have been changed from the light theme are:
 *
 * - onSurface.neutral.default
 * - onSurface.neutral.defaultInverted
 * - onSurface.neutral.outlineAlt
 */
export const darkTheme = {
  interactive: {
    neutral: {
      rested: tokens.color['neutral.light.1000'].val,
      restedAlt: setColorAlpha(tokens.color['neutral.light.1000'].val, 0.1),
      hovered: tokens.color['neutral.light.920'].val,
      disabled: tokens.color['neutral.light.500'].val,
      focus: tokens.color['neutral.light.800'].val,

      restedInverted: tokens.color['neutral.light.0'].val,
      hoveredInverted: tokens.color['neutral.light.40'].val,
      disabledInverted: tokens.color['neutral.light.400'].val,
      focusInverted: tokens.color['neutral.light.80'].val,

      hoveredInvertedAlt: setColorAlpha(tokens.color['neutral.light.40'].val, 0.05),
      hoveredAlt: setColorAlpha(tokens.color['neutral.light.920'].val, 0.05),
      disabledAlt: setColorAlpha(tokens.color['neutral.light.40'].val, 0.05),
    },

    primary: {
      rested: tokens.color['primary.light.800'].val,
      restedAlt: tokens.color['primary.light.960'].val,
      hovered: tokens.color['primary.light.920'].val,
      hoveredAlt: tokens.color['primary.light.900'].val,
      disabled: tokens.color['primary.light.400'].val,
      focus: tokens.color['primary.light.960'].val,
    },

    negative: {
      disabled: tokens.color['negative.light.400'].val,
      rested: tokens.color['negative.light.900'].val,
      hovered: tokens.color['negative.light.940'].val,
    },

    positive: {
      disabled: tokens.color['positive.light.400'].val,
      rested: tokens.color['positive.light.920'].val,
      hovered: tokens.color['positive.light.940'].val,
    },
  },

  onSurface: {
    neutral: {
      default: tokens.color['neutral.light.0'].val,
      outline: tokens.color['neutral.light.300'].val,
      zebra: tokens.color['neutral.light.20'].val,
      container: setColorAlpha(tokens.color['neutral.light.1000'].val, 0.35),
      tooltip: setColorAlpha(tokens.color['neutral.light.1000'].val, 0.85),

      defaultAlt: tokens.color['neutral.light.600'].val,
      outlineAlt: tokens.color['neutral.light.900'].val,
      zebraAlt: tokens.color['neutral.light.40'].val,
      containerAlt: setColorAlpha(tokens.color['neutral.light.1000'].val, 0.5),

      defaultInverted: tokens.color['neutral.light.1000'].val,
      outlineInverted: tokens.color['neutral.light.500'].val,

      muted: tokens.color['neutral.light.500'].val,
    },

    primary: {
      default: tokens.color['primary.light.1000'].val,

      decorative: tokens.color['primary.light.800'].val,
      decorativeAlt: tokens.color['primary.light.400'].val,

      outline: tokens.color['primary.light.960'].val,
      outlineAlt: tokens.color['primary.light.900'].val,

      light: tokens.color['primary.light.0'].val,
    },

    secondary: {
      default: tokens.color['secondary.light.400'].val,
      low: tokens.color['secondary.light.300'].val,
      high: tokens.color['secondary.light.500'].val,
    },

    positive: {
      default: tokens.color['positive.light.1000'].val,
      defaultAlt: tokens.color['positive.light.940'].val,

      decorative: tokens.color['positive.light.900'].val,
    },

    negative: {
      default: tokens.color['negative.light.1000'].val,
      defaultAlt: tokens.color['negative.light.900'].val,
      outline: tokens.color['negative.light.500'].val,
    },
  },

  surface: {
    neutral: {
      default: tokens.color['neutral.light.60'].val,
      /**
       * This is a gradient with a grain texture, no idea how to
       * implement this in a theme.
       *
       * @see {@link https://www.figma.com/file/peJR1hwp6mPRyVwB6k2iAw/%F0%9F%94%A9-Colors?node-id=258-730&t=IbRYPFf8KcHgCrj1-4}
       */
      yin: tokens.color['neutral.light.800'].val,
    },

    primary: {
      default: tokens.color['primary.light.100'].val,
    },

    positive: {
      default: tokens.color['positive.light.600'].val,
      defaultAlt: tokens.color['positive.light.0'].val,
    },

    negative: {
      default: tokens.color['negative.light.500'].val,
      defaultAlt: tokens.color['negative.light.200'].val,
    },

    artifactInlet: {
      default: tokens.color['artifact.inlet.light.300'].val,
    },

    artifactLilac: {
      default: tokens.color['artifact.lilac.light.300'].val,
    },

    artifactMusty: {
      default: tokens.color['artifact.musty.light.300'].val,
    },
  },

  foundation: {
    neutral: {
      yang: tokens.color['neutral.light.0'].val,
    },
  },
} as const;

export const lightTheme = {
  interactive: {
    neutral: {
      rested: tokens.color['neutral.light.1000'].val,
      restedAlt: setColorAlpha(tokens.color['neutral.light.1000'].val, 0.1),
      hovered: tokens.color['neutral.light.920'].val,
      disabled: tokens.color['neutral.light.500'].val,
      focus: tokens.color['neutral.light.800'].val,

      restedInverted: tokens.color['neutral.light.0'].val,
      hoveredInverted: tokens.color['neutral.light.40'].val,
      disabledInverted: tokens.color['neutral.light.400'].val,
      focusInverted: tokens.color['neutral.light.80'].val,

      hoveredInvertedAlt: setColorAlpha(tokens.color['neutral.light.40'].val, 0.05),
      hoveredAlt: setColorAlpha(tokens.color['neutral.light.920'].val, 0.05),
      disabledAlt: setColorAlpha(tokens.color['neutral.light.40'].val, 0.05),
    },

    primary: {
      rested: tokens.color['primary.light.800'].val,
      restedAlt: tokens.color['primary.light.960'].val,
      hovered: tokens.color['primary.light.920'].val,
      hoveredAlt: tokens.color['primary.light.900'].val,
      disabled: tokens.color['primary.light.400'].val,
      focus: tokens.color['primary.light.960'].val,
    },

    negative: {
      disabled: tokens.color['negative.light.400'].val,
      rested: tokens.color['negative.light.900'].val,
      hovered: tokens.color['negative.light.940'].val,
    },

    positive: {
      disabled: tokens.color['positive.light.400'].val,
      rested: tokens.color['positive.light.920'].val,
      hovered: tokens.color['positive.light.940'].val,
    },
  },

  onSurface: {
    neutral: {
      default: tokens.color['neutral.light.1000'].val,
      outline: tokens.color['neutral.light.300'].val,
      zebra: tokens.color['neutral.light.20'].val,
      container: setColorAlpha(tokens.color['neutral.light.1000'].val, 0.35),
      tooltip: setColorAlpha(tokens.color['neutral.light.1000'].val, 0.85),

      defaultAlt: tokens.color['neutral.light.600'].val,
      outlineAlt: tokens.color['neutral.light.40'].val,
      zebraAlt: tokens.color['neutral.light.40'].val,
      containerAlt: setColorAlpha(tokens.color['neutral.light.1000'].val, 0.5),

      defaultInverted: tokens.color['neutral.light.0'].val,
      outlineInverted: tokens.color['neutral.light.500'].val,

      muted: tokens.color['neutral.light.500'].val,
    },

    primary: {
      default: tokens.color['primary.light.1000'].val,

      decorative: tokens.color['primary.light.800'].val,
      decorativeAlt: tokens.color['primary.light.400'].val,

      outline: tokens.color['primary.light.960'].val,
      outlineAlt: tokens.color['primary.light.900'].val,

      light: tokens.color['primary.light.0'].val,
    },

    secondary: {
      default: tokens.color['secondary.light.400'].val,
      low: tokens.color['secondary.light.300'].val,
      high: tokens.color['secondary.light.500'].val,
    },

    positive: {
      default: tokens.color['positive.light.1000'].val,
      defaultAlt: tokens.color['positive.light.940'].val,

      decorative: tokens.color['positive.light.900'].val,
    },

    negative: {
      default: tokens.color['negative.light.1000'].val,
      defaultAlt: tokens.color['negative.light.900'].val,
      outline: tokens.color['negative.light.500'].val,
    },
  },

  surface: {
    neutral: {
      default: tokens.color['neutral.light.60'].val,
      /**
       * This is a gradient with a grain texture, no idea how to
       * implement this in a theme.
       *
       * @see {@link https://www.figma.com/file/peJR1hwp6mPRyVwB6k2iAw/%F0%9F%94%A9-Colors?node-id=258-730&t=IbRYPFf8KcHgCrj1-4}
       */
      yin: tokens.color['neutral.light.800'].val,
    },

    primary: {
      default: tokens.color['primary.light.100'].val,
    },

    positive: {
      default: tokens.color['positive.light.600'].val,
      defaultAlt: tokens.color['positive.light.0'].val,
    },

    negative: {
      default: tokens.color['negative.light.500'].val,
      defaultAlt: tokens.color['negative.light.200'].val,
    },

    artifactInlet: {
      default: tokens.color['artifact.inlet.light.300'].val,
    },

    artifactLilac: {
      default: tokens.color['artifact.lilac.light.300'].val,
    },

    artifactMusty: {
      default: tokens.color['artifact.musty.light.300'].val,
    },
  },

  foundation: {
    neutral: {
      yang: tokens.color['neutral.light.0'].val,
    },
  },
} as const;

export type LightTheme = typeof lightTheme;
export type ThemeKeys = Leaves<LightTheme>;

export type LeavesOut<T> = T extends object
  ? T extends string
    ? { [K in keyof T]-?: Join<K, LeavesOut<T[K]>> }[keyof T]
    : string
  : never;

const flattenTheme = (
  theme: BricksTheme | keyof BricksTheme,
  prefix?: string,
): {
  [K in BricksThemeLeaves]: LeavesOut<BricksTheme>;
} =>
  Object.entries(theme).reduce((acc, [key, value]) => {
    const genPrefix = prefix ? `${prefix}.${key}` : `${key}`;

    if (typeof value === 'object' && !value.isVar) {
      return {
        ...acc,
        ...flattenTheme(value, genPrefix),
      };
    } else {
      return {
        ...acc,
        [genPrefix]: value,
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);

const flatLight = flattenTheme(lightTheme);
const flatDark = flattenTheme(darkTheme);

const light = { ...flatLight };
const dark = { ...flatDark };

export type SubTheme = typeof light;

const baseThemes: {
  light: SubTheme;
  dark: SubTheme;
} = {
  light,
  dark,
};

export const themes = {
  // bring back the full type, the rest use a subset to avoid clogging up ts,
  // tamagui will be smart and use the top level themes as the type for useTheme() etc
  light: baseThemes.light,
  dark: baseThemes.dark,
};

export default themes;
