import { forwardRef } from 'react';

import Link, { LinkProps } from 'next/link';

import { UtilityText, UtilityTextProps } from '@arrived/bricks';

export type NextLinkProps = UtilityTextProps & Pick<LinkProps, 'href' | 'as' | 'prefetch'>;

export const NextLink = forwardRef<HTMLAnchorElement, NextLinkProps>(
  ({ href, as, prefetch, children, ...props }, ref) => {
    const content = props.className ? (
      // @ts-expect-error TODO: This is work around to handle some lingering MUI components using this. mostly buttons on the homepage.
      <span {...props}>{children}</span>
    ) : (
      <UtilityText tag="span" token="utility.hyperlink.medium" textDecorationLine="none" {...props}>
        {children}
      </UtilityText>
    );
    return (
      <Link href={href} as={as} prefetch={prefetch} ref={ref}>
        {content}
      </Link>
    );
  },
);
