import { useQuery } from '@tanstack/react-query';

import { getAllPropertyFeatures } from '@arrived/api_v2';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '@arrived/queries';

import { featuresQueryKey } from './features.keys';

export function useGetFeaturesQuery(options?: UseAwaitedQueryOptions<typeof getAllPropertyFeatures>) {
  return useQuery({
    queryKey: featuresQueryKey,
    queryFn: () => getAllPropertyFeatures(),
    ...options,
    staleTime: options?.staleTime ?? MAX_STALE_TIME,
  });
}
