export const UserOfferingTransactState = {
  NEEDS_LOGIN: 'NEEDS_LOGIN',
  LOADING: 'LOADING',
  SETUP_ACCOUNT: 'SETUP_ACCOUNT',
  VERIFICATION_REQUIRED: 'VERIFICATION_REQUIRED',
  BLOCKED: 'BLOCKED',
  FUNDED: 'FUNDED',
  MAXED_OUT: 'MAXED_OUT',
  PRE_LAUNCH: 'PRE_LAUNCH',
  COMING_SOON: 'COMING_SOON',
  TRANSACT: 'TRANSACT',
  PAUSED: 'PAUSED',
  UNKNOWN: 'UNKNOWN',
  UNACCREDITED: 'UNACCREDITED',
  /**
   * This state is when the user is viewing a Reg D Offering (so we assume they have Reg D view
   * access), but does not have the access to transact. This means we need an update of their
   * accreditation status to validate that they are still able to access and transact the Reg D
   * Offering.
   */
  ACCREDITATION_STATUS_UPDATE_REQUIRED: 'ACCREDITATION_STATUS_UPDATE_REQUIRED',
} as const;

export type UserOfferingTransactState = (typeof UserOfferingTransactState)[keyof typeof UserOfferingTransactState];
