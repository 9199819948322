import { ChangeEvent, useCallback } from 'react';

import { List, ListItemButtonProps, ListProps, RadioGroup } from '@mui/material';

import { UserDocumentType } from '@arrived/models';

import { TypeSelectListItem } from './TypeSelectListItem';

export interface TypeSelectListProps extends Omit<ListProps, 'onChange'> {
  documentType?: UserDocumentType;
  setDocumentType: (documentType: UserDocumentType) => void;
  types?: UserDocumentType[];
  listItemProps?: Omit<ListItemButtonProps, 'onClick'>;
}

export const TypeSelectList = ({
  documentType,
  setDocumentType,
  types = Object.keys(UserDocumentType) as UserDocumentType[],
  listItemProps,
  sx,
  ...rest
}: TypeSelectListProps) => {
  const handleOnChange = useCallback((_event: ChangeEvent<HTMLInputElement>, value: string) => {
    setDocumentType(value as UserDocumentType);
  }, []);

  return (
    <List
      sx={{
        '& .MuiFormGroup-root': { display: 'flex', flexDirection: 'column', gap: ({ spacing }) => spacing(1) },
        ...sx,
      }}
      {...rest}
    >
      <RadioGroup onChange={handleOnChange} value={documentType ?? null}>
        {types.map((type) => (
          <TypeSelectListItem documentType={type} setDocumentType={setDocumentType} key={type} {...listItemProps} />
        ))}
      </RadioGroup>
    </List>
  );
};
