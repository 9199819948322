import { AccreditedStatus } from '@arrived/models';

export const AccreditedStatusTranslations = {
  [AccreditedStatus.PENDING]: 'Pending',
  [AccreditedStatus.SELF_ACCREDITED]: 'Self Accredited',
  [AccreditedStatus.VERIFIED_ACCREDITED]: 'Verified Accredited',
  [AccreditedStatus.NOT_ACCREDITED]: 'Not Accredited',
} as const;

export const formatAccreditedStatus = (status?: AccreditedStatus) =>
  (status && AccreditedStatusTranslations[status]) ?? 'N/A';
