import { useId } from 'react';

import { Stack, styled } from '@tamagui/core';

import { useSheetImperativeContext } from './SheetImperativeContext';

export const SHEET_BACKGROUND_NAME = 'SheetBackground';

const SheetBackgroundFrame = styled(Stack, {
  name: SHEET_BACKGROUND_NAME,

  position: 'absolute',
  h: '100%',
  left: 0,
  top: 0,
  w: '100%',
});

/**
 * This is a funky issue, basically if you use a `BlurView` for a background,
 * it will not render the first time on native. This should trigger a proper
 * re-render when the sheet is open.
 *
 * Theory is because `BlurView` is expensive to use, it is doing some pre-optimizations
 * that are causing it to not render the first time. Does not happen with web though.
 */
export const SheetBackground = SheetBackgroundFrame.styleable((props, ref) => {
  const id = useId();
  const { open } = useSheetImperativeContext();

  return <SheetBackgroundFrame key={`${id}-${open}`} ref={ref} {...props} />;
});
