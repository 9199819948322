import { FC, useMemo } from 'react';

import { getTokens, getVariableValue, useProps } from '@tamagui/core';

import { IndicatorProps } from './IndicatorProps';

/* eslint-disable react-hooks/rules-of-hooks */

export const getLoadingColors = (variant: IndicatorProps['variant'] = 'default') => {
  const colorTokens = getTokens().color;

  const colors = {
    default: Array(4).fill('white') as ['white', 'white', 'white', 'white'],
    inverted: Array(4).fill('black') as ['black', 'black', 'black', 'black'],
    colored: [
      colorTokens['primary.light.200'].val,
      colorTokens['primary.light.400'].val,
      colorTokens['primary.light.600'].val,
      colorTokens['primary.light.800'].val,
    ] as [string, string, string, string],
  } as const;

  return colors[variant];
};

/**
 * This adds a custom layer of theming to the icons that allow
 * us to use custom tokens for the color and size.
 */
export function withIndicatorTheme<A extends FC<IndicatorProps>>(Component: A) {
  const wrapped = (propsIn: IndicatorProps) => {
    const props = useProps(propsIn);

    const sizeTokens = getTokens().size;

    const size = useMemo(() => {
      let _size;

      if (typeof props.size === 'string') {
        _size = getVariableValue(sizeTokens[props.size as keyof typeof sizeTokens] || props.size);
      }

      return _size ?? props.size ?? sizeTokens['$12'].val;
    }, [props.size, sizeTokens]);

    // @ts-ignore
    return <Component {...props} size={size} />;
  };

  return wrapped as unknown as A;
}
