import { FixedRateInvestment, FixedRateInvestmentRequest } from '@arrived/models';

import { api } from './api';
import { createQuery } from './create-query';

export const getFixedRateInvestments = () =>
  createQuery<FixedRateInvestment[]>({
    apiInstance: api,
    method: 'get',
    url: '/fixed-rate-investments',
  });

export const getFixedRateInvestment = (cid: FixedRateInvestment['cid']) =>
  createQuery<FixedRateInvestment>({
    apiInstance: api,
    method: 'get',
    url: `/fixed-rate-investments/${cid}`,
  });

export const createFixedRateInvestment = (
  requestData: Omit<FixedRateInvestmentRequest, 'cid' | 'createdAt' | 'updatedAt' | 'active'>,
) =>
  createQuery<FixedRateInvestment>({
    apiInstance: api,
    method: 'post',
    url: '/fixed-rate-investments',
    requestData,
  });

export const patchFixedRateInvestment = ({
  cid,
  ...requestData
}: Omit<Partial<FixedRateInvestmentRequest>, 'createdAt' | 'updatedAt'>) =>
  createQuery<FixedRateInvestment>({
    apiInstance: api,
    method: 'patch',
    url: `/fixed-rate-investments/${cid}`,
    requestData,
  });

export const deleteFixedRateInvestment = (cid: FixedRateInvestment['cid']) =>
  createQuery<FixedRateInvestment>({
    apiInstance: api,
    method: 'delete',
    url: `/fixed-rate-investments/${cid}`,
  });
