import {
  Offering,
  OfferingStatus,
  OfferingTransaction,
  OfferingTransactionDocument,
  SignedStatus,
  TransactionStatus,
} from '@arrived/models';

export const isTradeNeedsDocumentsSignedForTransaction = (
  transaction: OfferingTransaction,
  offering: Offering,
): boolean => {
  return isNeedsTradeDocumentsSigned({
    transactionStatus: transaction.status,
    documentsSignedStatus: transaction.documentsSignedStatus,
    offeringStatus: offering.status,
  });
};

export const isTradeNeedsDocumentsSignedForTradeDocument = (document: OfferingTransactionDocument): boolean => {
  return isNeedsTradeDocumentsSigned({
    transactionStatus: document.transaction.status,
    documentsSignedStatus: document.status,
    offeringStatus: document.offering.status,
  });
};

export const isTradeDocumentSignedOrSignable = (document: OfferingTransactionDocument): boolean => {
  return document.status === SignedStatus.SIGNED || isTradeNeedsDocumentsSignedForTradeDocument(document);
};

const isNeedsTradeDocumentsSigned = ({
  transactionStatus,
  documentsSignedStatus,
  offeringStatus,
}: {
  transactionStatus: TransactionStatus;
  documentsSignedStatus: SignedStatus;
  offeringStatus: OfferingStatus;
}) => {
  return (
    (transactionStatus === TransactionStatus.CREATED ||
      transactionStatus === TransactionStatus.FUNDED ||
      transactionStatus === TransactionStatus.SETTLED) &&
    documentsSignedStatus === SignedStatus.NOTSIGNED &&
    (offeringStatus === OfferingStatus.TRANSACT_ALL || offeringStatus === OfferingStatus.TRANSACT_EARLY_ACCESS)
  );
};
