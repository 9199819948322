import { Fragment, useContext } from 'react';

import {
  BodyText,
  LinearGradient,
  createStyledContext,
  gradients,
  Stack,
  Image,
  styled,
  TitleText,
  withStaticProperties,
  ValueText,
  Divider,
  useMedia,
  StarIcon,
} from '@arrived/bricks';

import { BuilderDataProps, GenericBuilderIcons, RegisteredComponent } from '../../sdk';

const TestimonialGridStyledContext = createStyledContext({
  variant: 'default',
});

export const useTestimonialGridStyledContext = () => useContext(TestimonialGridStyledContext);

const TestimonialGridFrame = styled(Stack, {
  name: 'TestimonialGrid',
  context: TestimonialGridStyledContext,
  flex: 1,
  gap: '$8',
  centered: true,
  p: '$4',

  $gtSm: {
    px: '$20',
    py: '$12',
  },
});

const TestimonialGridContainer = TestimonialGridFrame.styleable((props) => {
  const { children, ...rest } = props;
  // Just a heads up, `default` means a white background!
  const { variant } = useTestimonialGridStyledContext();

  return (
    <Stack overflow="hidden" flex={1} w="100%" borderRadius="$4">
      {variant === 'yin' && <LinearGradient fullscreen w="100%" h="100%" colors={gradients['gradient.neutral.yin']} />}
      {variant === 'light' && (
        <LinearGradient fullscreen w="100%" h="100%" colors={gradients['gradient.neutral.light']} />
      )}
      <TestimonialGridFrame {...rest}>{children}</TestimonialGridFrame>
    </Stack>
  );
});

const TestimonialGridHeading = styled(TitleText, {
  name: 'TestimonialGridHeading',
  context: TestimonialGridStyledContext,

  token: 'title.heading.xlarge',

  $gtSm: {
    token: 'title.display.small',
  },

  variants: {
    variant: {
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
  },
});

const TestimonialGridContent = styled(BodyText, {
  name: 'TestimonialGridContent',
  context: TestimonialGridStyledContext,

  token: 'body.default.large',

  variants: {
    variant: {
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
  },
});

const TestimonialGridCard = styled(Stack, {
  name: 'TestimonialGridCard',
  context: TestimonialGridStyledContext,

  centered: true,
  flex: 1,
  gap: '$4',
});

const TestimonialGridCardQuoteFrame = styled(TitleText, {
  name: 'TestimonialGridCardQuote',
  context: TestimonialGridStyledContext,

  token: 'title.heading.medium',

  variants: {
    variant: {
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
  },
});

const TestimonialGridCardQuote = TestimonialGridCardQuoteFrame.styleable(({ children, ...rest }) => (
  <TestimonialGridCardQuoteFrame {...rest}>
    {'“'}
    {children}
    {'”'}
  </TestimonialGridCardQuoteFrame>
));

const TestimonialGridCardValue = styled(ValueText, {
  name: 'TestimonialGridValue',
  context: TestimonialGridStyledContext,

  token: 'value.medium',
  color: '$onSurface.primary.decorative',
});

const TestimonialGridCardLabel = styled(BodyText, {
  name: 'TestimonialGridLabel',
  context: TestimonialGridStyledContext,

  token: 'body.default.medium',

  variants: {
    variant: {
      yin: {
        color: '$onSurface.neutral.defaultInverted',
      },
    },
  },
});

const TestimonialGridCardImage = styled(Stack, {
  name: 'TestimonialGridCardImage',
  context: TestimonialGridStyledContext,
  borderRadius: '$full',
  w: 80,
  h: 80,
  overflow: 'hidden',
});

const TestimonialGridComponent = withStaticProperties(TestimonialGridContainer, {
  Heading: TestimonialGridHeading,
  Content: TestimonialGridContent,
  Card: withStaticProperties(TestimonialGridCard, {
    Image: TestimonialGridCardImage,
    Quote: TestimonialGridCardQuote,
    Value: TestimonialGridCardValue,
    Label: TestimonialGridCardLabel,
  }),
});

type TestimonialGridProps = {
  heading?: string;
  content?: string;
  testimonials?: {
    image: string;
    starRating: number;
    quote: string;
    value: string;
    label: string;
  }[];
} & BuilderDataProps;

export const TestimonialGrid = ({ heading, content, testimonials, ...props }: TestimonialGridProps) => {
  const media = useMedia();

  return (
    <TestimonialGridStyledContext.Provider {...props}>
      <Stack $gtSm={{ m: '$8' }} m="$4">
        <TestimonialGridComponent>
          {Boolean(heading) && <TestimonialGridComponent.Heading>{heading}</TestimonialGridComponent.Heading>}

          {Boolean(content) && <TestimonialGridComponent.Content>{content}</TestimonialGridComponent.Content>}

          <Stack $gtSm={{ row: true, gap: '$8' }} alignItems="center" justifyContent="space-between" width="100%">
            {Array.isArray(testimonials) &&
              Boolean(testimonials.length) &&
              testimonials.map(({ starRating, image, quote, value, label }, idx) => (
                <Fragment key={`testimonial-card-${starRating}-${idx}`}>
                  <TestimonialGridComponent.Card>
                    {Boolean(image) && (
                      <TestimonialGridComponent.Card.Image>
                        <Image source={{ uri: image }} />
                      </TestimonialGridComponent.Card.Image>
                    )}

                    {starRating > 0 && (
                      <Stack gap="$1" centered row>
                        {Array.from({ length: starRating }).map((_, idx) => (
                          <StarIcon key={idx} color="$secondary.light.200" size="$3" />
                        ))}
                      </Stack>
                    )}

                    {Boolean(quote) && (
                      <TestimonialGridComponent.Card.Quote>{quote}</TestimonialGridComponent.Card.Quote>
                    )}

                    <Stack gap="$2" centered>
                      {Boolean(value) && (
                        <TestimonialGridComponent.Card.Value>{value}</TestimonialGridComponent.Card.Value>
                      )}
                      {Boolean(label) && (
                        <TestimonialGridComponent.Card.Label>{label}</TestimonialGridComponent.Card.Label>
                      )}
                    </Stack>
                  </TestimonialGridComponent.Card>
                  {idx !== testimonials.length - 1 && (
                    <>
                      {media.gtSm && (
                        <Divider.Vertical
                          alt
                          py="$4"
                          $gtSm={{
                            px: '$8',
                          }}
                        />
                      )}

                      {!media.gtSm && (
                        <Divider
                          alt
                          py="$4"
                          $gtSm={{
                            px: '$8',
                          }}
                        />
                      )}
                    </>
                  )}
                </Fragment>
              ))}
          </Stack>
        </TestimonialGridComponent>
      </Stack>
    </TestimonialGridStyledContext.Provider>
  );
};

TestimonialGrid.registerComponent = {
  component: TestimonialGrid,
  name: 'Bricks:TestimonialGrid',
  friendlyName: 'Testimonial Grid',
  description:
    'A component that displays an investors testimonial. This can have a max or two rows or columns depending on the viewport of the screen.',
  docsLink: 'https://www.figma.com/design/pOLoKPNXzfRiDTqAeP9YBr/%F0%9F%A7%B1-Builder.io-Blocks?node-id=6205-219144',
  image: GenericBuilderIcons.DoubleColumn,
  canHaveChildren: false,
  shouldReceiveBuilderProps: {
    builderComponents: true,
  },
  inputs: [
    {
      name: 'heading',
      friendlyName: 'Heading',
      helperText: 'The title of the testimonial.',
      broadcast: true,
      type: 'longText',
      defaultValue: 'Heading',
    },
    {
      name: 'content',
      friendlyName: 'Content',
      helperText: 'The content underneath the heading.',
      broadcast: true,
      type: 'longText',
      defaultValue: 'Content',
    },
    {
      name: 'variant',
      friendlyName: 'Variant',
      type: 'enum',
      defaultValue: 'default',
      enum: [
        {
          value: 'default',
          label: 'Default',
        },
        {
          value: 'light',
          label: 'Light',
        },
        {
          value: 'yin',
          label: 'Yin',
        },
      ],
    },
    {
      name: 'testimonials',
      friendlyName: 'Testimonials',
      helperText: 'This should have a max of 2 even though it can have more!',
      type: 'array',
      broadcast: true,
      subFields: [
        {
          name: 'image',
          friendlyName: 'Image',
          broadcast: true,
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
          required: true,
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2Fe2f096ba17f74dfd9c140ac050f349a4%2F8db6e671377c4040af9e9d92b4f7b260',
        },
        {
          name: 'starRating',
          friendlyName: 'Star Rating',
          type: 'enum',
          defaultValue: 5,
          enum: [
            {
              value: 1,
              label: '1',
            },
            {
              value: 2,
              label: '2',
            },
            {
              value: 3,
              label: '3',
            },
            {
              value: 4,
              label: '4',
            },
            {
              value: 5,
              label: '5',
            },
          ],
        },
        {
          name: 'quote',
          friendlyName: 'Quote',
          type: 'longText',
          defaultValue: 'Quote',
        },
        {
          name: 'value',
          friendlyName: 'Value',
          type: 'longText',
          defaultValue: 'VALUE',
        },
        {
          name: 'label',
          friendlyName: 'Label',
          type: 'longText',
          defaultValue: 'Label',
        },
      ],
    },
  ],
} satisfies RegisteredComponent;
