import { useIntl } from 'react-intl';

import { Markdown, MarkdownProps } from '@arrived/bricks-common';
import { enUS } from '@arrived/common';
import { Offering } from '@arrived/models';

import { ProductDetailContent } from '../../../../content';

import { LeverageAmount } from './LeverageAmount';
import { useLeverageAmount } from './useLeverageAmount';

const LeverageAmountMessageId: Record<(typeof LeverageAmount)[keyof typeof LeverageAmount], keyof typeof enUS> = {
  [LeverageAmount.LOW]: 'product-details.property-equity-volatility.low',
  [LeverageAmount.AVERAGE]: 'product-details.property-equity-volatility.average',
  [LeverageAmount.HIGH]: 'product-details.property-equity-volatility.high',
};

type LeverageAmountTextProps = Omit<MarkdownProps, 'children' | 'Text'> & { offering: Offering };

export const LeverageAmountText = ({ offering, ...rest }: LeverageAmountTextProps) => {
  const intl = useIntl();

  const leverageAmount = useLeverageAmount(offering);

  return (
    <Markdown Text={ProductDetailContent.Text} {...rest}>
      {intl.formatMessage({ id: LeverageAmountMessageId[leverageAmount] })}
    </Markdown>
  );
};
