import { Rewards } from './rewards';

export type ReferralCode = string;

export type ReferralCodeEntity = {
  id: number;
  userId: number;
  referralCode: ReferralCode;
  active: boolean;
  activeStatus: ReferralCodeStatuses;
  createdAt: string;
  updatedAt: string;
};

export enum RedeemCodeResponseState {
  INVALID = 'INVALID',
  SIGNED_UP = 'SIGNED_UP',
  LINKED_BANK = 'LINKED_BANK',
  REWARD_ISSUED = 'REWARD_ISSUED',
  DISQUALIFIED = 'DISQUALIFIED',
}

export type RedeemCodeResponse = {
  success: boolean;
  state: RedeemCodeResponseState;
};

export type ReferralStatus = {
  id: number;
  email?: string;
  state: string;
  referrerVoucherUuid?: string;
  nudgable: boolean;
  updatedAt: string;
  referee: ReferralUser;
  referrer: ReferralUser;
  voucherAmount?: string | null;
  voucherState?: Rewards.VoucherState;
};

export type ReferralUser = {
  id: number;
  firstName: string;
  lastInitial: string;
};

export type ReferralCodeStatuses = 'ACTIVE' | 'DISABLED' | 'REFEREE_ONLY';

export interface PatchReferralCodeRequest {
  id: number;
  activeStatus?: ReferralCodeStatuses;
}
