import { ComponentType, useCallback, useState } from 'react';

import Animated, { useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';

import {
  AnimateHeight,
  CaretDownIcon,
  Stack,
  StackProps,
  smoothSpring,
  styled,
  tokens,
  withStaticProperties,
} from '@arrived/bricks';

export type AccordionListItemProps = StackProps & {
  /**
   * Required, this will be the component that renders inside the pressable element of the item.
   */
  Content: ComponentType<{ expanded?: boolean }>;
  /**
   * Sets the initial expansion state.
   */
  defaultExpanded?: boolean;
  /**
   * Optional, by default a caret that animates to be pointed downwards when the item is expanded
   * will be rendered, however, if `false` is passed no icon will be rendered. Alternatively you
   * can provide your own custom Icon that receives an `expanded` property indicating when the item
   * is expanded.
   */
  Icon?: false | ComponentType<{ expanded?: boolean }>;
  /**
   * Optional, a default wrapper will be rendered, if a custom implementation is provided it needs
   * to take a `onPress` handler which should be invoked when the element is pressed to toggle the
   * expanded state of the item.
   */
  Wrapper?: ComponentType<StackProps & { expanded?: boolean; onPress?: StackProps['onPress'] }>;
};

const AnimatedStack = Animated.createAnimatedComponent(Stack);

const DefaultIcon = ({ expanded }: { expanded?: boolean }) => {
  const iconRotation = useDerivedValue(() => withSpring(expanded ? -180 : 0, smoothSpring), [expanded]);

  const iconStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          rotate: `${iconRotation.value}deg`,
        },
      ],
    }),
    [iconRotation],
  );

  return (
    <AnimatedStack row style={iconStyle}>
      <CaretDownIcon />
    </AnimatedStack>
  );
};

const defaultWrapperHorizontalSpacing = tokens.space['4'].val;

const DefaultWrapper = styled(Stack, {
  animation: 'quick',
  row: true,
  button: true,
  cursor: 'pointer',
  px: defaultWrapperHorizontalSpacing,
  py: '$3',
  gap: '$2',
  $xxs: {
    mx: -defaultWrapperHorizontalSpacing,
  },
  style: {
    transition: 'all 150ms',
  },

  hoverStyle: {
    bg: '$onSurface.neutral.outlineAlt',
  },

  pressStyle: {
    bg: '$onSurface.neutral.outlineAlt',
  },
});

const ChildrenWrapper = styled(Stack, {
  pb: '$6',
  pt: '$2',
  gap: '$4',
  $gtXxs: {
    p: '$4',
  },
});

/**
 * An AccordionListItem is one of the individual items with an AccordionList which have two basic
 * parts:
 * - Content: This is the content of the item which goes inside the pressable area. This is a
 *   required prop and should be a component type which can take an `expanded` prop to
 *   conditionally style when the item is expanded.
 * - Wrapper: This component will surround the entire expandable row and should be a button, there
 *   is a default wrapper that is used if no custom wrapper is provided. All Wrappers are passed
 *   `justifyContent: 'space-between'` and `alignItems: 'center'`. The wrapper should also be able
 *   to take an `onPress` handler which when invoked will toggle the expanded state of the item.
 * - Icon: By default this will be a caret icon which animates 180deg to be upside down when the
 *   item is in an expanded state, but you can pass a custom Icon component which takes an
 *   `expanded` prop when the item is in the expanded state.
 * - children: The children passed to this component are what will be shown when the item is in an
 *   expanded state.
 *
 * If you would like to use or customize the default wrapper it is exposed via
 * `AccordionListItem.Wrapper`. There is also a default wrapper than can be placed around the
 * `children` passed to this component which can be accessed via `AccordionListItem.Children`.
 */
export const AccordionListItem = withStaticProperties(
  Stack.styleable<AccordionListItemProps>(
    ({ children, Content, defaultExpanded, Icon = DefaultIcon, Wrapper = DefaultWrapper, ...rest }, ref) => {
      const [expanded, setExpanded] = useState(defaultExpanded ?? false);

      const handleOnPress = useCallback(() => setExpanded((expanded) => !expanded), []);

      return (
        <Stack ref={ref} {...rest}>
          <Wrapper expanded={expanded} onPress={handleOnPress} justifyContent="space-between" alignItems="center">
            <Stack flex={1}>
              <Content expanded={expanded} />
            </Stack>
            {!!Icon && <Icon expanded={expanded} />}
          </Wrapper>
          <AnimateHeight expand={expanded}>{children}</AnimateHeight>
        </Stack>
      );
    },
  ),
  {
    Children: ChildrenWrapper,
    Wrapper: DefaultWrapper,
  },
);
