import { FC } from 'react';

import { GetProps, IconProps, Stack, TitleText, TitleTextProps, styled } from '@arrived/bricks';

import { SubHeaderName } from './constants';

const SubHeaderFrame = styled(Stack, {
  name: SubHeaderName,

  gap: '$2',
  flexDirection: 'row',
  alignItems: 'center',
});

const SubHeaderText = styled(TitleText, {
  token: 'title.heading.small',
});

export const SubHeader = SubHeaderFrame.styleable<
  GetProps<typeof SubHeaderFrame> & { Icon?: FC<IconProps>; TextProps?: TitleTextProps }
>(({ children, Icon, TextProps, ...rest }, ref) => (
  <SubHeaderFrame ref={ref} {...rest}>
    {Icon && <Icon />}
    <SubHeaderText {...TextProps}>{children}</SubHeaderText>
  </SubHeaderFrame>
));
