import { Line, Svg } from 'react-native-svg';

import { GetProps, Stack, ThemeParsed, styled, useTheme, withStaticProperties } from '@tamagui/core';

export interface DividerExtraProps {
  solid?: boolean;
  alt?: boolean;

  active?: boolean;

  color?: keyof ThemeParsed;
}

const DIVIDER_CONTAINER_NAME = 'DividerContainer';

const DividerContainer = styled(Stack, {
  name: DIVIDER_CONTAINER_NAME,
});

const DividerTimeContainer = styled(Stack, {
  w: '100%',
  px: '$6',
  py: '$3',
  backgroundColor: '$onSurface.neutral.zebra',

  variants: {
    active: {
      true: {
        backgroundColor: '$onSurface.primary.decorative',
      },
    },
  },
});

export type DividerProps = DividerExtraProps & GetProps<typeof DividerContainer>;

const DividerHorizontal = DividerContainer.styleable<DividerProps>(({ solid, alt, color, ...rest }, ref) => {
  const theme = useTheme();

  return (
    <DividerContainer ref={ref} {...rest}>
      <Svg height={1} width="100%">
        <Line
          stroke={
            color ? theme[color].val : theme[alt ? 'onSurface.neutral.outlineAlt' : 'onSurface.neutral.outline'].val
          }
          strokeWidth={1}
          strokeDasharray={solid ? undefined : '1, 4'}
          strokeLinecap="round"
          x1="0"
          y1="0.5"
          x2="100%"
          y2="0.5"
        />
      </Svg>
    </DividerContainer>
  );
});

const DividerVertical = DividerContainer.styleable<DividerProps>(({ alt, color, solid, ...rest }, ref) => {
  const theme = useTheme();

  return (
    <DividerContainer ref={ref} {...rest}>
      <Svg width={1} height="100%">
        <Line
          stroke={
            color ? theme[color].val : theme[alt ? 'onSurface.neutral.outlineAlt' : 'onSurface.neutral.outline'].val
          }
          strokeWidth={1}
          strokeDasharray={solid ? undefined : '1, 4'}
          strokeLinecap="round"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="100%"
        />
      </Svg>
    </DividerContainer>
  );
});

const DividerTime = DividerTimeContainer.styleable<DividerProps>(({ children, ...rest }, ref) => (
  <DividerTimeContainer ref={ref} {...rest} px="$6" py="$4">
    {children}
  </DividerTimeContainer>
));

export const Divider = withStaticProperties(DividerHorizontal, { Vertical: DividerVertical, Time: DividerTime });
