import { useMutation, useQueryClient } from '@tanstack/react-query';

import { offeringHistoryKey } from '@arrived/admin-queries';
import { patchOfferingHistory } from '@arrived/api_v2';
import { UseAwaitedMutationOptions } from '@arrived/queries';

export function usePatchOfferingHistoryMutation(options?: UseAwaitedMutationOptions<typeof patchOfferingHistory>) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchOfferingHistory,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: offeringHistoryKey });
      options?.onSuccess?.(data, variables, context);
    },
  });
}
