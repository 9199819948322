import * as yup from 'yup';

import { AccountsBasicInfoSchema } from './accountsBasicInfo.schema';

const AccountsBasicInfoSignUpAddedFieldsSchema = yup
  .object({
    email: yup.string().trim().required('user.email.required'),
    password: yup
      .string()
      .trim()
      .required('user.password.required')
      .min(8, 'user.password.length')
      .matches(/^(.*[a-z].*)$/, 'user.password.lowerCase')
      .matches(/^(.*[A-Z].*)$/, 'user.password.upperCase')
      .matches(/^(.*\d.*)$/, 'user.password.number')
      .matches(/^.*[!@#$%^&*].*$/, 'user.password.specialCharacter'),
  })
  .defined();

export const AccountsBasicInfoSignUpSchema = AccountsBasicInfoSchema.concat(AccountsBasicInfoSignUpAddedFieldsSchema);

export type AccountsBasicInfoSignUpSchema = yup.InferType<typeof AccountsBasicInfoSignUpSchema>;
