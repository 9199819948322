import { useQuery } from '@tanstack/react-query';

import { postIntercomIdentityToken } from '@arrived/api_v2';
import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { IntercomIdentityTokenRequest } from '@arrived/models';

import { useGetCurrentUserQuery } from '../users';
import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { intercomIdentityTokenQueryKeyFn } from './intercom.keys';

export function usePostIntercomIdentityToken(
  request: IntercomIdentityTokenRequest,
  options?: UseAwaitedQueryOptions<typeof postIntercomIdentityToken>,
) {
  const currentUserState = useGetCurrentUserQuery();
  const { isAuthenticated } = useArrivedAuth0();

  return useQuery({
    queryKey: intercomIdentityTokenQueryKeyFn(request),
    queryFn: () => postIntercomIdentityToken(request),
    // Defaulting to max stale time; tokens currently do not change during the lifespan of the user
    staleTime: MAX_STALE_TIME,
    ...options,
    // We only want to be enabled if we are authenticated AND the user has an ID from
    // the `/users` endpoint. We are avoiding this request erroring out due to a user
    // not completing the onboarding flow.
    enabled: (options?.enabled ?? true) && isAuthenticated && currentUserState.data?.id != null,
  });
}
