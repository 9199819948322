import { GetProps, styled } from '@tamagui/core';

import { UTILITY_VARIANTS } from './mappings/utility';
import { RootText } from './RootText';

export const UtilityText = styled(RootText, {
  name: 'UtilityText',
  userSelect: 'auto',

  variants: {
    token: UTILITY_VARIANTS,
  } as const,

  defaultVariants: {
    token: 'utility.label.medium',
  },
});

export type UtilityTextProps = GetProps<typeof UtilityText>;
