import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postChangeEmail } from '@arrived/api_v2';

import { adminUserEmailVerificationKeyFn, adminUserKeyFn } from './users.keys';

export function useAdminPostChangeEmailMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postChangeEmail,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: adminUserKeyFn(variables.id) });
      queryClient.invalidateQueries({ queryKey: adminUserEmailVerificationKeyFn(variables.id) });
    },
  });
}
