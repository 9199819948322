import { memo, useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint } from '@arrived/bricks';

import { useProductWidgetContext } from './ProductWidgetContext';
import { useProductWidgetStyledContext } from './ProductWidgetStyledContext';

export const PropertiesDataPoint = memo(() => {
  const { offering } = useProductWidgetContext();
  const { condensed } = useProductWidgetStyledContext();

  const propertyCount = useMemo(
    () => <FormattedNumber value={offering.properties.length} />,
    [offering.properties.length],
  );

  return (
    <DataPoint variant="minimized" colors="muted" alignment={condensed ? 'right' : 'left'}>
      <DataPoint.Value>{propertyCount}</DataPoint.Value>
      <DataPoint.Label>
        <FormattedMessage id="product-details.properties" />
      </DataPoint.Label>
    </DataPoint>
  );
});
