import { ArrivedBuilderModels } from '../models';

import { BuilderContent, GetContentOptions } from './builderio-sdk';

export * from './builderio-sdk';
export * from './icons';

/**
 * All available input types for Builder.io `Input` components
 */
export const InputTypes = {
  /**
   * Generic string input, no special formatting or multi-line support.
   */
  string: 'string',

  /**
   * Generic number input, can be supported with `min`, `max`, and `step` properties.
   */
  number: 'number',

  /**
   * Generic boolean input.
   */
  boolean: 'boolean',

  /**
   * An alternative to `text` or `string` that allows for multi-line input.
   */
  longText: 'longText',

  /**
   * A rich text editor that allows for HTML formatting and input.
   * This SHOULD be used with the `Text` element from `@arrived/builder.io`.
   * This will use the `htmlParser` from `@arrived/bricks-common` to render the HTML on
   * web or native.
   *
   * @see [text.tsx](../components/atoms/text.tsx) for an example of how to use this and how it will be rendered.
   */
  richText: 'richText',

  /**
   * Allows for selection of an Builder.io Asset or file
   * and returns the URL of the asset.
   *
   * Can be used in conjunction with `allowedFileTypes` to restrict
   * the types of files that can be uploaded.
   */
  file: 'file',

  /**
   * Displays a multi-color picker that will return a hex or rgb value.
   */
  color: 'color',

  /**
   * Displays a datepicker that will return a unix value for the date.
   */
  date: 'date',

  /**
   * A generic input type that will format for an email address.
   */
  email: 'email',

  /**
   * A generic input type that allows for a key-value pair.
   *
   * This is good for nesting inputs within inputs. Say
   * you would want to have a `title` and `description` input live
   * within a `content` input, you would use this type.
   *
   * ```tsx
   * const MyComponent = ({ content }: { content: { title: string; description: string } }) => {
   *   return (
   *     <Stack>
   *       <Text>{content.title}</Text>
   *       <Text>{content.description}</Text>
   *     </Stack>
   *   );
   * };

   * MyComponent.registerComponent = {
   *   component: MyComponent,
   *   name: 'MyComponent',
   *   inputs: [
   *     {
   *       name: 'content',
   *       type: 'object',
   *       subFields: [
   *         { name: 'title', type: 'text' },
   *         { name: 'description', type: 'text' },
   *       ],
   *     },
   *   ],
   * };
   * ```
   */
  object: 'object',

  /**
   * A generic input type that allows for a list of items.
   * This will display a list of inputs that can
   * be reordered, removed, or added to.
   * @alias `array`
   */
  list: 'list',

  /**
   * A builder.io specific input type that allows you to use a reference
   * to another Builder.io model.
   *
   * Displays a content entry picker to reference. In use with the
   * `model` property. Use optionally with inputs of type reference.
   * `model` will restrict the content entry picker to a specific model by name.
   */
  reference: 'reference',

  /**
   * A deprecated version of `string`, use `string` or `longText` instead.
   * @deprecated
   */
  text: 'text',
} as const;

export type InputType = keyof typeof InputTypes;

declare module '@builder.io/sdk-react/types/types/input' {
  export interface Input {
    /**
     * ---
     * The above comment is from builder.io, reference below for updated typings.
     *
     * The type of input to use, such as 'string'. See all available inputs [on builder.io here](https://www.builder.io/c/docs/custom-components-input-types).
     *
     * See our detailed definitions and what we use here! {@link InputTypes}
     */
    type: InputType | string;
  }
}

/**
 * Was tired of the games with the Builder.io SDK types, so I just overwrote them to allow
 * for us to pass in a generic type to the `fetchEntries` and `fetchOneEntry` functions.
 *
 * This would be a great starting point to allow for generic types when working with
 * builder.io moving forward, and would love to see them use more type inference in their
 * SDK.
 */
declare module '@builder.io/sdk-react' {
  /**
   * All of the models that get called from Builder.io have
   * these fields.
   */
  type BaseBuilderContent = Omit<BuilderContent, 'data'> & {
    createdDate: number;
    id: string;
    modelId: string;
    name: string;
    query: string[];
    rev: string;
    data: {
      /**
       * The SEO description of the content
       */
      description: string;
    } & BuilderContent['data'];
  };

  type ModelOverrides = {
    [ArrivedBuilderModels.SYMBOL]: {
      createdBy: string;
      firstPublished: number;
      lastUpdated: number;
      lastUpdatedBy: string;
      meta: {
        breakpoints: {
          small: number;
          medium: number;
          large: number;
        };
        hasLinks: boolean;
        kind: string;
      };
    };
  };

  type ApplyModelOverrides<T, U> = U extends keyof ModelOverrides ? T & ModelOverrides[U] : T;

  export interface GetArrivedContentOptions<U extends ArrivedBuilderModels> extends Omit<GetContentOptions, 'model'> {
    model: U;
  }

  /**
   * A generic that fills in a Builder.io Content object. This
   * is the base object used to when a call is returned from `fetchOneEntry`
   * or `fetchEntries`.
   */
  export type GenericBuilderContent<
    Data,
    Model extends ArrivedBuilderModels = ArrivedBuilderModels,
  > = BaseBuilderContent & {
    model: Model;
    data?: Data extends undefined ? BuilderContent['data'] : Data & BuilderContent['data'];
  } & ApplyModelOverrides<BaseBuilderContent, Model>;

  /**
   * A generic that fills in a Block element from Builder.io. Mainly
   * used to fill out props for `registerComponent` in Builder.io.
   * Generally mapping from the components `inputs` field to the
   * relevant props when the `type` is `reference`.
   *
   * [You can read more about `type` here](https://www.builder.io/c/docs/custom-components-intro?_host=www.builder.io#input-types)
   *
   * ```tsx
   *  type MyComponentProps = {
   *   title: string;
   *   body: GenericBuilderBlock<{
   *    posts: string[];
   *   }>
   * };
   *
   *
   * const MyComponent = ({ title, body }: MyComponentProps) => {
   *  return (
   *   <Stack><BodyText>{title}</BodyText><BodyText>{body}</BodyText></Stack>
   *  );
   * };
   *
   * MyComponent.registerComponent = {
   * component: MyComponent,
   * name: 'MyComponent',
   * inputs: [
   *  { name: 'title', type: 'text' },
   *  { name: 'body', type: 'text' },
   * ],
   * };
   * ```
   */
  export type GenericBuilderBlockReference<Data> = {
    '@type': string;
    id: string;
    model: string;
    value: GenericBuilderContent<Data, ArrivedBuilderModels>;
  };

  /**
   * Fetches multiple entries from Builder.io, will always a return an array of the
   * generic type passed in as `GenericBuilderContent<ResponseData>[]`.
   *
   * ```tsx
   * const entry = await fetchEntries<{ title?: string; isMagic?: boolean }>({
   *   model: ArrivedBuilderModels.BLOG_ARTICLE,
   *   apiKey: 'YOUR_API_KEY',
   *   ...FetchEntriesParams,
   * });
   * ```
   */
  export function fetchEntries<
    ResponseData extends { [key: string]: unknown },
    Model extends ArrivedBuilderModels = ArrivedBuilderModels,
  >(options: GetArrivedContentOptions<Model>): Promise<GenericBuilderContent<ResponseData, Model>[]>;

  export type FetchEntriesParams = Omit<Parameters<typeof fetchEntries>[0], 'apiKey' | 'model'>;

  /**
   * Fetches a single entry from Builder.io, will always return a single object of the
   * generic type passed in as `GenericBuilderContent<ResponseData>`.
   *
   * ```tsx
   * const entry = await fetchOneEntry<{ title?: string; isMagic?: boolean }>({
   *   model: ArrivedBuilderModels.BLOG_ARTICLE,
   *   apiKey: 'YOUR_API_KEY',
   *   ...FetchOneEntryParams,
   * });
   * ```
   */
  export function fetchOneEntry<
    ResponseData extends { [key: string]: unknown },
    Model extends ArrivedBuilderModels = ArrivedBuilderModels,
  >(options: GetArrivedContentOptions<Model>): Promise<GenericBuilderContent<ResponseData, Model>>;

  export type FetchOneEntryParams = Omit<Parameters<typeof fetchOneEntry>[0], 'apiKey' | 'model'>;

  /**
   * This overrides the `RegisteredComponent` from Builder.io
   * Their docs are confusing and I'm not totally sure what all properties
   * mean according to it. This adds examples and cleans up the typing more.
   */
  export interface ComponentInfo {
    /**
     * Prevents wrapping the components in another view layer when applying
     * Builder layouts. This can be useful for smaller elements like `Text`, `Button`
     * or if you just have styling issues with the wrapping view.
     *
     * When using this, be sure to spread the `attributes` property onto the
     * rendered component, this ensures that Builder.io editor will still
     * be able to interact with the component.
     *
     * ```tsx
     * const MyComponent = ({ text, attributes }: MyComponentProps) => {
     *   return (
     *     <Text {...attributes}>{text}</Text>
     *   );
     * };
     * ```
     *
     * @default false;
     */
    noWrap?: boolean;

    /**
     * The rendered image for the component within the Builder.io editor.
     * We use the `GenericBuilderIcons` or `BricksBuilderIcons` to provide
     * a different icon for the component instead of the default React logo.
     */
    image?: string;

    /**
     * Link to the documentation for the component, usually the Figma
     * doc. This will show as a tooltip when hovering the component,
     * and is near impossible to click on...
     */
    docsLink?: string;
  }
}
