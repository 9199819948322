export const BreadcrumbCategories = {
  AppState: 'AppState',
  Auth: 'Auth',
  BiometricsSettings: 'BiometricsSettings',
  BiometricsOverlay: 'BiometricsOverlay',
  Errors: 'Errors',
  Onboarding: 'Onboarding',
  Plaid: 'Plaid',
  TradeState: 'TradeState',
  DeepLink: 'DeepLink',
  Documents: 'Documents',
  Feedback: 'Feedback',
  Sardine: 'Sardine',
  Verification: 'Verification',
  PushNotifications: 'PushNotifications',
} as const;

export type BreadcrumbCategories = (typeof BreadcrumbCategories)[keyof typeof BreadcrumbCategories];
