import { Box, Stack, StackProps, alpha } from '@mui/material';

import { SocialShareButtonsConfig } from './SocialShareButtonConfigs';

interface SocialShareButtonsProps extends StackProps {
  buttonConfig: SocialShareButtonsConfig[];
  iconSize: number;
  url: string;
}

export const SocialShareButtons = ({ buttonConfig, iconSize, url, ...rest }: SocialShareButtonsProps) => (
  <Stack direction={{ xs: 'column', md: 'row' }} {...rest}>
    {buttonConfig.map((config, index) => {
      const { ButtonComponent, buttonProps, IconComponent, isRendered = true } = config;

      return isRendered ? (
        <Box
          px={1}
          py={1}
          key={index}
          lineHeight={0}
          sx={{ '&:hover': { bgcolor: ({ palette }) => alpha(palette.neutrals.milk, 0.75) } }}
        >
          <ButtonComponent url={url} {...buttonProps}>
            <IconComponent size={iconSize} round={true} />
          </ButtonComponent>
        </Box>
      ) : null;
    })}
  </Stack>
);
