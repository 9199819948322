import { useQuery } from '@tanstack/react-query';

import { getAccountBeneficiaries } from '@arrived/api_v2';
import { AccountId } from '@arrived/models';
import { UseAwaitedQueryOptions } from '@arrived/queries';

import { adminBeneficiariesKeyFn } from './users.keys';

export function useAdminGetUserBeneficiaries(
  accountId?: AccountId,
  options?: UseAwaitedQueryOptions<typeof getAccountBeneficiaries>,
) {
  return useQuery({
    queryKey: adminBeneficiariesKeyFn(accountId!),
    queryFn: () => getAccountBeneficiaries(accountId!),
    ...options,
    enabled: (options?.enabled ?? true) && accountId != null,
  });
}
