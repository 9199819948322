import { useQuery } from '@tanstack/react-query';

import { getVideoMetadataByOfferingId } from '@arrived/api_v2';
import { AssetType, OfferingId } from '@arrived/models';

import { MAX_STALE_TIME, UseAwaitedQueryOptions } from '../utils';

import { offeringVideoMetadataKey } from './assetMetadata.keys';

export function useGetVideoMetadataByOfferingId(
  offeringId?: OfferingId,
  options?: UseAwaitedQueryOptions<typeof getVideoMetadataByOfferingId>,
) {
  return useQuery({
    queryKey: offeringVideoMetadataKey(offeringId!, AssetType.STR),
    queryFn: () => getVideoMetadataByOfferingId(offeringId!),
    staleTime: MAX_STALE_TIME,
    ...options,
    enabled: (options?.enabled ?? true) && offeringId != null,
  });
}
