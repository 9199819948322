import { forwardRef, useMemo } from 'react';

import {
  BodyText,
  GetProps,
  Image,
  Stack,
  TamaguiElement,
  ValueText,
  styled,
  useMedia,
  withStaticProperties,
} from '@arrived/bricks';
import { ikClient } from '@arrived/imagekit';

import { VIDEO_IMAGE_FRAME_NAME, VIDEO_SUB_TITLE_TEXT_NAME, VIDEO_TITLE_TEXT_NAME } from './constants';
import { VideoPlayer } from './VideoPlayer';

const VideoTitleText = styled(ValueText, {
  name: VIDEO_TITLE_TEXT_NAME,

  token: 'value.medium',
  color: '$onSurface.neutral.defaultInverted',

  $gtXxs: {
    ellipse: true,
  },
});

const VideoSubTitleText = styled(BodyText, {
  name: VIDEO_SUB_TITLE_TEXT_NAME,

  token: 'body.default.small',
  color: '$onSurface.neutral.outline',
  display: 'none',

  $gtXxs: {
    ellipse: true,
    display: 'inline',
  },
});

const VideoImageFrame = styled(Stack, {
  name: VIDEO_IMAGE_FRAME_NAME,

  alignSelf: 'flex-end',
  height: 60,
  width: 55.5,
  $gtXxs: { height: 80, width: 74 },
});

const VideoImage = VideoImageFrame.styleable<GetProps<typeof VideoImageFrame> & { imageUri: string }>(
  ({ imageUri, ...rest }, ref) => {
    const media = useMedia();

    const source = ikClient.url({ path: imageUri, transformation: [{ width: '148', height: '160' }] });

    const dimensions = useMemo(
      () => (media.gtXs ? { width: 74, height: 80 } : { width: 55.5, height: 60 }),
      [media.gtXs],
    );

    return (
      <VideoImageFrame ref={ref} {...rest}>
        <Image contentFit="cover" source={{ uri: source, ...dimensions }} />
      </VideoImageFrame>
    );
  },
);

export const Video = withStaticProperties(VideoPlayer, {
  Image: withStaticProperties(VideoImage, {
    Cameron: forwardRef<TamaguiElement, GetProps<typeof VideoImageFrame>>((props, ref) => (
      <VideoImage imageUri="/features/product-details/cameron.png" ref={ref} {...props} />
    )),
  }),
  Title: VideoTitleText,
  SubTitle: VideoSubTitleText,
});
