import { useContext, useEffect, useState } from 'react';

import _isEmpty from 'lodash/isEmpty';

import { AnalyticsContext } from './AnalyticsContext';
import { useUserTraits } from './hooks';

export function useHasAnonymousUserLoggedIn() {
  const { isLoaded } = useContext(AnalyticsContext);
  const [hasAnonymousUserLoggedIn, setHasAnonymousUserLoggedIn] = useState<boolean | undefined>(undefined);

  const traits = useUserTraits();

  useEffect(() => {
    if (isLoaded) {
      traits().then((traits) => {
        setHasAnonymousUserLoggedIn(
          traits?.['user_account_login_created'] === true ||
            traits?.['has_had_user_account_login_created'] === true ||
            traits?.['user_account_login_created_at'] != null ||
            traits?.['logged_in'] === true,
        );
      });
    }
  }, [isLoaded]);

  return hasAnonymousUserLoggedIn;
}

export function useHasAnonymousUserLeadCaptured() {
  const { isLoaded } = useContext(AnalyticsContext);
  const [hasAnonymousUserLeadCaptured, setHasAnonymousUserLeadCaptured] = useState<boolean | undefined>(undefined);

  const traits = useUserTraits();

  useEffect(() => {
    if (isLoaded) {
      traits().then((traits) => {
        setHasAnonymousUserLeadCaptured(!_isEmpty(traits?.['email']) || !_isEmpty(traits?.['lead_created_at']));
      });
    }
  }, [isLoaded]);

  return hasAnonymousUserLeadCaptured;
}
