import { ReactNode } from 'react';

import { MdExpandMore } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { Offering } from '@arrived/models';

import { Calculator, CalculatorProps } from './calculator';
import { DisclaimerPartTwo } from './calculator/text';
import { ReturnsSectionHeaderTypography } from './components';

export interface ReturnsCalculatorSectionProps extends Omit<AccordionProps, 'children'> {
  offering: Offering;
  /**
   * Required for web-app.
   */
  TableComponent: CalculatorProps['TableComponent'];
}

export const ReturnsCalculatorSection = ({ offering, sx, TableComponent, ...rest }: ReturnsCalculatorSectionProps) => {
  const { palette } = useTheme();

  return (
    <Accordion
      defaultExpanded
      sx={{
        bgcolor: ({ palette }) => palette.darkened.steel,
        ...sx,
      }}
      {...rest}
    >
      <AccordionSummary
        id="property-details.calculator.header"
        aria-controls="property-details.calculator.content"
        expandIcon={<MdExpandMore color={palette.neutrals.white} size={32} />}
        sx={{
          minHeight: 'unset',
          px: 0,
          '& .MuiAccordionSummary-content': { my: 0 },
        }}
      >
        <ReturnsSectionHeaderTypography color={({ palette }) => palette.neutrals.white}>
          <FormattedMessage id="common.returns-calculator" />
        </ReturnsSectionHeaderTypography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 0, pt: 4 }}>
        <Calculator offering={offering} TableComponent={TableComponent} />
        <Stack gap={1}>
          <Typography variant="body2" color={({ palette }) => palette.neutrals.white}>
            <FormattedMessage
              id="property-details.calculator.disclaimer-part-one"
              values={{
                emphasized: (parts: ReactNode[]) => (
                  <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>{parts}</span>
                ),
              }}
            />
          </Typography>
          <Typography variant="body2" color={({ palette }) => palette.neutrals.white}>
            <DisclaimerPartTwo offering={offering} />
          </Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
