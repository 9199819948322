import { useMemo } from 'react';

import Animated from 'react-native-reanimated';

import { Ratio, styled, useProgressBarAnimation, withStaticProperties } from '@arrived/bricks';
import { OfferingTransactState, getPercentFunded } from '@arrived/common';
import { useOfferingTransactState } from '@arrived/hooks';

import { PRODUCT_WIDGET_PROGRESS_NAME } from './constants';
import { useProductWidgetContext } from './ProductWidgetContext';
import { ProductWidgetStyledContext } from './ProductWidgetStyledContext';

const StyledRatioMain = styled(Ratio, {
  name: PRODUCT_WIDGET_PROGRESS_NAME,
  context: ProductWidgetStyledContext,

  borderRadius: '$1',
  bg: '$onSurface.primary.outline',

  w: '100%',
  variants: {
    condensed: {
      true: {
        h: '$1',
        borderRadius: 0,
      },
    },
  } as const,
});

const StyledRatio = withStaticProperties(StyledRatioMain, { Fill: Ratio.Fill, Inner: Ratio.Inner });

const AnimatedRatioFill = Animated.createAnimatedComponent(StyledRatio.Fill);

export const Progress = () => {
  const { offering } = useProductWidgetContext();
  const offeringTransactState = useOfferingTransactState(offering);

  const progressStatus = useMemo(() => getPercentFunded(offering), [offering]);

  const [progressAnimationStyles, setProgressContainerWidth] = useProgressBarAnimation(progressStatus);

  const bg = useMemo(() => {
    switch (offeringTransactState) {
      case OfferingTransactState.CLOSED:
        return '$onSurface.positive.decorative';
      case OfferingTransactState.IN_PROGRESS:
        return '$interactive.primary.rested';
      default:
        return;
    }
  }, [offeringTransactState]);

  return (
    <StyledRatio>
      <StyledRatio.Inner m={0} h="100%" onLayout={setProgressContainerWidth}>
        <AnimatedRatioFill bg={bg} style={progressAnimationStyles} />
      </StyledRatio.Inner>
    </StyledRatio>
  );
};
