import _isEmpty from 'lodash/isEmpty';

import {
  MarketId,
  OfferingCid,
  OfferingId,
  OfferingVideoMetadata,
  PrivateCreditFundMetadata,
  RegDIPOMetadata,
  SFRFundData,
  STNMetadata,
  STRMetadata,
} from '@arrived/models';

import { assetMetadataApi } from './api';
import { createQuery } from './create-query';

/**
 * Grab data related to an STR offering
 */
export const getSTRMetadata = (offeringId: OfferingId) =>
  createQuery<STRMetadata>({
    apiInstance: assetMetadataApi,
    method: 'get',
    url: `/metadata/str/${offeringId}`,
  });

/**
 * Grab data related to an STN offering
 */
export const getSTNMetadata = (offeringId: OfferingId) =>
  createQuery<STNMetadata>({
    apiInstance: assetMetadataApi,
    method: 'get',
    url: `/metadata/stn/${offeringId}`,
  });

export const getRegDIPOMetadata = (offeringCid: OfferingCid) =>
  createQuery<RegDIPOMetadata>({
    apiInstance: assetMetadataApi,
    method: 'get',
    url: `/metadata/regDIPO/${offeringCid}`,
  });

export const getSFRFundMetadata = (offeringCid: OfferingCid) =>
  createQuery<SFRFundData>({
    apiInstance: assetMetadataApi,
    method: 'get',
    url: `/metadata/sfrfund/${offeringCid}`,
  });

export const getPrivateCreditFundMetadata = (offeringCid: OfferingCid) =>
  assetMetadataApi
    .get<PrivateCreditFundMetadata>(`/metadata/privateCreditFund/${offeringCid}`)
    .then((response) => response.data);

export const getVideoMetadataByOfferingId = (offeringId: OfferingId) =>
  createQuery<OfferingVideoMetadata[] | null>({
    apiInstance: assetMetadataApi,
    method: 'get',
    url: `/video/offering/${offeringId}`,
  }).then((offeringVideoMetadata) => {
    if (!offeringVideoMetadata) {
      return Promise.reject(`No video metadata for offering ${offeringId}`);
    }
    return offeringVideoMetadata;
  });

export const getVideoMetadataByMarketId = (marketId: MarketId) =>
  createQuery<string | null>({
    apiInstance: assetMetadataApi,
    method: 'get',
    url: `/video/market/${marketId}`,
  }).then((youtubeVideoId) => {
    if (!youtubeVideoId) {
      return Promise.reject(`No video metadata for market ${marketId}`);
    }
    return youtubeVideoId;
  });
