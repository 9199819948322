import { useCallback, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { useInterval } from 'usehooks-ts';

import { notificationService } from '@arrived/features-toasty';
import { EMAIL_VERIFICATION_RESEND_STATUS, UserId } from '@arrived/models';
import { useGetCurrentUserQuery, useResendEmailVerificationMutation } from '@arrived/queries';

const BLOCKED_SECONDS = 60;

export const useResendEmailVerification = (userId?: UserId) => {
  const [isBlockedEmailVerification, setIsBlockedEmailVerification] = useState(false);
  const [blockedCounter, setBlockedCounter] = useState(BLOCKED_SECONDS);
  const currentUserState = useGetCurrentUserQuery({ enabled: !userId });
  const { mutate: resendEmail } = useResendEmailVerificationMutation();

  const handleResendEmail = useCallback(() => {
    let passedUserId = userId;

    if (!passedUserId && currentUserState.isSuccess) {
      passedUserId = currentUserState.data.id;
    }

    if (passedUserId) {
      setIsBlockedEmailVerification(true);
      setBlockedCounter(BLOCKED_SECONDS);
      resendEmail(passedUserId, {
        onSuccess: (response) => {
          if (response.status === EMAIL_VERIFICATION_RESEND_STATUS.SUCCESS) {
            notificationService.success(<FormattedMessage id="emailVerificationComponent.resend.success" />);
          }
        },
        onError: () => {
          notificationService.error(<FormattedMessage id="emailVerificationComponent.resend.failed" />);
        },
      });
    }
  }, [currentUserState, resendEmail, userId]);

  useInterval(
    () => {
      setBlockedCounter(blockedCounter - 1);
    },
    isBlockedEmailVerification ? 1000 : null,
  );

  useEffect(() => {
    if (blockedCounter <= 0) {
      setBlockedCounter(BLOCKED_SECONDS);
      setIsBlockedEmailVerification(false);
    }
  }, [blockedCounter]);

  return { handleResendEmail, isBlocked: isBlockedEmailVerification, counter: blockedCounter };
};
