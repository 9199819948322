import { FormattedMessage } from 'react-intl';

import { ROUTES } from '@arrived/common';

import { NAV_TYPE, NavItemProps } from '../../navigation.types';

import {
  aboutUsNavItem,
  performanceNavItem,
  pressNavItem,
  stakeHolderCommitmentsNavItem,
  vacationRentalsNavItem,
} from './AboutDropdown';

const learningCenterNavItem: NavItemProps = {
  isExternal: true,
  shouldFollow: true,
  path: ROUTES.static.learning,
  text: <FormattedMessage id="learning-center-nav-item" defaultMessage="Learning Center" />,
};

const helpFaqNavItem: NavItemProps = {
  isExternal: true,
  path: ROUTES.static.help,
  text: <FormattedMessage id="common.help-and-faq" />,
};

const howItWorksNavItem: NavItemProps = {
  isExternal: true,
  shouldFollow: true,
  path: ROUTES.static.howItWorks,
  text: <FormattedMessage id="how-it-works-nav-item" defaultMessage="How It Works" />,
};

const webinarNavItem: NavItemProps = {
  isExternal: true,
  shouldFollow: true,
  path: ROUTES.static.webinar,
  text: <FormattedMessage id="webinars" defaultMessage="Webinars" />,
};

const blogNavItem: NavItemProps = {
  isExternal: true,
  shouldFollow: true,
  path: ROUTES.static.blog,
  text: <FormattedMessage id="common.blog" />,
};

export const learnNavPublicMultipleItem: NavItemProps = {
  text: <FormattedMessage id="learn-nav-item" defaultMessage="Learn" />,
  type: NAV_TYPE.MULTIPLE,
  subItems: [learningCenterNavItem, helpFaqNavItem, howItWorksNavItem, webinarNavItem, blogNavItem],
};

export const learnNavPrivateMultipleItem: NavItemProps = {
  text: <FormattedMessage id="learn-nav-item" defaultMessage="Learn" />,
  type: NAV_TYPE.MULTIPLE,
  subItems: [
    aboutUsNavItem,
    pressNavItem,
    vacationRentalsNavItem,
    performanceNavItem,
    learningCenterNavItem,
    stakeHolderCommitmentsNavItem,
    helpFaqNavItem,
    howItWorksNavItem,
    webinarNavItem,
  ],
};
