import { MutableRefObject, createContext, useContext } from 'react';

import { TamaguiElement } from '@tamagui/core';

interface ChoiceContainerContextProps {
  defaultIndex?: number;
  index?: number;
  setIndex: (newIndex?: number) => void;
  itemRefs?: MutableRefObject<(TamaguiElement | null)[]>;
}

export const ControlledIndexContext = createContext<ChoiceContainerContextProps>({
  defaultIndex: 0,
  index: 0,
  setIndex: () => null,
  itemRefs: undefined,
});
export const useControlledIndexContext = () => useContext(ControlledIndexContext);

export interface ControlledIndexOverrideProps {
  defaultIndex?: number;
  onIndexChange?: (newIndex?: number) => void;
  controlledIndex?: number | null;
}
