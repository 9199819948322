import { useIntl } from 'react-intl';

import { BodyText, BodyTextProps, styled } from '@arrived/bricks';
import { enUS } from '@arrived/common';

export type QuestionBodyTextProps = Omit<BodyTextProps, 'children' | 'id'>;
export type QuestionProps = QuestionBodyTextProps & { id: keyof typeof enUS };

/**
 * This component can be used in conjunction with the `CommonQuestions` component to get a hover
 * behavior on the text where it will be colored to `$onSurface.primary.decorative` when hovered.
 * If you would like more specificity in designing an list of drop downs, look at the
 * `AccordionList` component in @arrived/bricks-common.
 */
export const QuestionTitle = styled(BodyText, {
  token: 'body.default.medium',

  style: {
    transition: 'color 150ms',
  },

  // @ts-expect-error See explanation above for why 'CommonQuestion' does not type check.
  '$group-CommonQuestion-hover': {
    color: '$onSurface.primary.decorative',
  },
});

export const Question = ({ id, ...rest }: QuestionProps) => {
  const intl = useIntl();

  return <QuestionTitle {...rest}>{intl.formatMessage({ id })}</QuestionTitle>;
};
