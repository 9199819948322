import { Address } from './addresses';

export type UserId = number;

export enum Domicile {
  NONE = 'NONE',
  US_CITIZEN = 'US_CITIZEN',
  /**
   * @deprecated as of August 6th, we are changing the language displaying to the user from:
   *
   * "U.S. Resident - I have a U.S. Tax ID (including work visa holders who intend to stay in the
   * US for the full investment period)"
   * to
   * "U.S. Green Card Holder"
   *
   * As such, we are distinguishing users who select the new option which corresponds to the
   * `US_PERMANENT_RESIDENT` option below.
   */
  US_RESIDENT = 'US_RESIDENT',
  US_PERMANENT_RESIDENT = 'US_PERMANENT_RESIDENT',
  NON_RESIDENT = 'NON_RESIDENT',
}

export const PlannedInvestmentAmount = {
  R100_2000: 'R100_2000',
  R2000_5000: 'R2000_5000',
  R5000_25000: 'R5000_25000',
  R25000_100000: 'R25000_100000',
  R100000_OR_MORE: 'R100000_OR_MORE',
} as const;

export type PlannedInvestmentAmount = (typeof PlannedInvestmentAmount)[keyof typeof PlannedInvestmentAmount];

export const PlannedInvestmentAmountValues = {
  [PlannedInvestmentAmount.R100_2000]: [100, 2000],
  [PlannedInvestmentAmount.R2000_5000]: [2000, 5000],
  [PlannedInvestmentAmount.R5000_25000]: [5000, 25000],
  [PlannedInvestmentAmount.R25000_100000]: [25000, 100000],
  [PlannedInvestmentAmount.R100000_OR_MORE]: [100000],
} as const;

export interface User {
  cid: string;
  id: UserId;
  email: string;
  auth0UserId: string;
  firstName?: string;
  lastName?: string;
  domicile?: Domicile;
  profilePictureUrl?: string;
  primaryResidenceAddress?: Address;
  mailingAddress?: Address;
  phoneNumber?: string;
  plannedInvestmentAmount?: PlannedInvestmentAmount;
  birthDate?: string;
  createdAt: string;
  updatedAt?: string;
  accreditedInvestor: boolean;
  tosAgreed: boolean;
  privacyAgreed: boolean;
  admin: boolean;
  active: boolean;
}

export interface UserRequest extends Partial<User> {
  id: UserId;
}

export interface UserEmailChangeRequest {
  id: UserId;
  newEmail: string;
}

export interface UserQueryParams {
  sort?: UserQuerySort;
  /**
   * a general search query that spans the following fields:
   *
   * id         | exact match
   * email      | match if starts with query
   * first name | match if starts with query
   * last name  | match if starts with query
   */
  q?: string;

  firstName?: string;
  lastName?: string;
  email?: string;
  id?: string;

  /**
   * page | [1, max page]
   * size | > 0
   */
  page?: number;
  size?: number;
}

export const USER_SORT_ID_ASC = 'id:asc';
export const USER_SORT_ID_DESC = 'id:desc';
export type UserQuerySort = typeof USER_SORT_ID_ASC | typeof USER_SORT_ID_DESC;

export const UserTradesStatus = {
  NO_TRADES: 'NO_TRADES',
  PENDING_TRADES: 'PENDING_TRADES',
  ONLY_SETTLED_TRADES: 'ONLY_SETTLED_TRADES',
} as const;
export type UserTradesStatus = (typeof UserTradesStatus)[keyof typeof UserTradesStatus];
